import React from "react";

import { useLocation } from "react-router";

import { UPGRADE } from "routes/route_constants";
import styled from "@emotion/styled";
import { TEACHER_COST } from "redux/auth/roles/roles_consts";
import { SignUpButton } from "./button_sign_up";

interface IDescriptionTeacherPlan {
  header: string;
  description: string;
  costValue: string;
  costDescription: string;
  onClick: () => void;
}
export const DescriptionTeacherPlan = ({
  header,
  description,
  costValue,
  costDescription,
  onClick,
}: IDescriptionTeacherPlan) => {
  const { pathname } = useLocation();
  const color =
    costValue === TEACHER_COST.PAID_TEACHER_ACCOUNT ? "#FFBA10" : "#6775DE";
  return (
    <Description>
      <DescriptionHeader>{header}</DescriptionHeader>
      <DescriptionText>{description}</DescriptionText>
      <Container>
        <DescriptionPay color={color}>
          $ <span>{costValue}</span> {costDescription}
        </DescriptionPay>
        <ChildrenButton>
          {costValue === TEACHER_COST.PAID_TEACHER_ACCOUNT ? (
            <ButtonContainerSchool>
              <SignUpButton onClick={onClick} backgroundColor={color}>
                Select
              </SignUpButton>
            </ButtonContainerSchool>
          ) : (
            <ButtonContainer isPaidSchool>
              <SignUpButton onClick={onClick} backgroundColor={color}>
                {pathname === UPGRADE ? "Keep this plan" : "Select"}
              </SignUpButton>
            </ButtonContainer>
          )}
        </ChildrenButton>
      </Container>
    </Description>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  font-size: 0.95em;
  color: #818181;
  padding: 2em 0.5em 0;
`;

const DescriptionHeader = styled.div`
  color: #190067;
  font-weight: 600;
  font-size: 1.25em;
`;

const DescriptionText = styled.div`
  margin-top: 1.5em;
  font-size: 0.9em;
  color: #818181;
`;

const DescriptionPay = styled.div<{ color?: string }>`
  white-space: break-spaces;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  color: ${({ color = "#6772e5" }) => color};
  justify-content: center;
  flex-grow: 1;
  font-size: 0.9em;
  align-items: center;
  > span {
    font-size: 2.5em;
    font-weight: 500;
  }
`;

const ChildrenButton = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div<{ isPaidSchool?: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin: 2em 0.5em 4em;

  > button {
    align-self: initial;
  }
`;

const ButtonContainerSchool = styled(ButtonContainer)`
  position: relative;
  :after {
    position: absolute;
    content: "Pay via PO OR credit card";
    top: 50px;
  }
`;
