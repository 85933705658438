import React, { Dispatch } from "react";
import styled from "@emotion/styled";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import { InputContainer } from "../steps/emotion.styles";

interface ICreateClassTab {
  input: {
    courseName: string;
    setCourseName: Dispatch<string>;
    endDate: Date | null;
    setEndDate: Dispatch<Date | null>;
    startDate: Date | null;
    setStartDate: Dispatch<Date | null>;
  };
}

export const CreateClassInputsContainer = ({ input }: ICreateClassTab) => {
  const {
    courseName,
    setCourseName,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
  } = input;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseName(event.target.value);
  };
  return (
    <StyledInputContainer>
      <StyledTextField
        onChange={handleChange}
        value={courseName}
        label="Class Name"
        variant="outlined"
        required
      />

      <InputDateContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StyledKeyboardDatePicker
            disableToolbar
            className="create-class-date-input"
            inputVariant="outlined"
            format="MMM d yyyy"
            label="Start Date"
            margin="normal"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <StyledKeyboardDatePicker
            disableToolbar
            className="create-class-date-input"
            inputVariant="outlined"
            format="MMM d yyyy"
            label="End Date"
            margin="normal"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </InputDateContainer>

      <Description>
        {"Start Date is beginning of school year.\n"}
        End Date is when this school year ends.
      </Description>
    </StyledInputContainer>
  );
};

const StyledInputContainer = styled(InputContainer)`
  max-width: 400px;
  padding-bottom: 1em;
`;

const StyledTextField = styled(TextField)`
  align-self: stretch;
  background-color: #f3f3f3;
`;
const InputDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  background-color: #f3f3f3;
  width: 48%;
  border-radius: 5px;
`;

const Description = styled.div`
  text-align: start;
  align-self: flex-start;
  white-space: break-spaces;
  color: #818181;
  font-size: 0.9em;
  margin-top: 1.1em;
`;
