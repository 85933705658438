import { Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { setFeedbackInfo } from "redux/feedback/feedback_slice";
import { IFeedbackBase } from "redux/feedback/feedback_types";
import { setMediaRecordsStateStart } from "redux/media_recordings/media_recordings_slice";
import note_image from "../images/Note.svg";
import video_image from "../images/Video.svg";
import css from "./select_feedback_popover.module.scss";

interface ISelectFeedbackPopover {
  feedbackPopoverAnchor: Element | null;
  closeFeedbackPopover: (e: React.MouseEvent) => void;
  openNoteFeedbackPopover: MouseEventHandler<Element>;
  feedbackBase: IFeedbackBase;
}

export const SelectFeedbackPopover = ({
  feedbackPopoverAnchor,
  closeFeedbackPopover,
  openNoteFeedbackPopover,
  feedbackBase,
}: ISelectFeedbackPopover) => {
  const dispatch = useDispatch();

  const openVideoFeedback = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setFeedbackInfo(feedbackBase));
    dispatch(setMediaRecordsStateStart());
    closeFeedbackPopover(e);
  };

  return (
    <Popover
      anchorEl={feedbackPopoverAnchor}
      keepMounted
      open={Boolean(feedbackPopoverAnchor)}
      onClose={closeFeedbackPopover}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <div className={css.feedback_container}>
        <div className={css.feedback_text}>Choose Feedback Type</div>
        <button
          onClick={openNoteFeedbackPopover}
          className={css.feedback_button}
          type="button"
        >
          <img alt="note" src={note_image} />
          <div className={css.button_text}>Note</div>
        </button>
        <button
          onClick={openVideoFeedback}
          className={css.feedback_button}
          type="button"
        >
          <img alt="note" src={video_image} />
          <div className={css.button_text}>Video</div>
        </button>
        <button
          type="button"
          className={css.close_icon}
          onClick={closeFeedbackPopover}
        >
          <CloseIcon />
        </button>
      </div>
    </Popover>
  );
};
