import styled from "@emotion/styled/macro";
import React from "react";

interface ICardHoverLessonSkill {
  text: string;
  value?: string;
  icon?: React.ReactNode;
}
export const CardHoverLessonSkill = ({
  text,
  value,
  icon,
}: ICardHoverLessonSkill) => {
  return (
    <InfoContainer>
      <IconContainer>{icon}</IconContainer>
      <InfoText>{text}</InfoText>
      <InfoValue>{value}</InfoValue>
    </InfoContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #f3f3f3;
  border-radius: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  margin: 14px 0;
`;

const InfoText = styled.div`
  width: 120px;
  font-weight: 500;
  font-size: 16px;
  color: #130863;
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const InfoValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #6775de;
  display: flex;
  align-items: center;
`;
