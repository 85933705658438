import { RecorderButtonProps } from "types/recorder";
import { Button } from "@material-ui/core";

import cnn from "classnames";
import css from "./record_button.module.scss";

export const RecordButton = ({
  recorderState,
  recordButtonActive,
  processClick,
}: RecorderButtonProps) => {
  const { initRecording } = recorderState;

  const getRecordButtonClassName = () => {
    if (recordButtonActive && !initRecording) {
      return css.icon_recording_inner_loading;
    }
    if (recordButtonActive) {
      return css.icon_recording_inner_active;
    }
    return css.icon_recording_inner_inactive;
  };

  return (
    <Button
      aria-label="recording"
      disabled={recordButtonActive && !initRecording}
      onClick={processClick}
      title={`${initRecording ? "Stop Recording" : "Start Recording"}`}
      disableRipple
    >
      <div className={css.icon_recording_outer}>
        <div
          className={cnn(css.icon_recording_inner, getRecordButtonClassName())}
        />
      </div>
    </Button>
  );
};
