import classnames from "classnames";
import css from "./subscription_plan.module.scss";

export const FreePlanPrice = () => {
  const price = 0;

  return (
    <div className={classnames(css.price_block_wrapper, css.free_plan)}>
      <div className={css.price_block}>
        <div className={css.currency}>$</div>
        <div className={css.price}>{price}</div>
        <div className={css.length}>/mo</div>
      </div>
    </div>
  );
};
