import styled from "@emotion/styled";
import { TABLET, DESKTOP } from "scss/emotion_consts";

export const MainContainer = styled.div<{ isAuth: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: white;
  padding: 0 5%;
  box-sizing: border-box;
  padding-top: 100px;
  @media ${TABLET} {
    background: ${(props) =>
      props.isAuth === true
        ? "linear-gradient(to bottom, white 450px, #f8f8fb 450px)"
        : "linear-gradient(to bottom, white 120px, #f8f8fb 120px)"};

    padding-top: 40px;
  }
`;

export const InstrumentName = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: #6775de;
  line-height: 35px;
  margin: 30px 0px 38px 0px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #190067;
`;

export const CategoryContainer = styled.div<{ wide: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 60px;
  box-sizing: border-box;
  @media ${TABLET} {
    width: ${(props) => (props.wide === true ? "100%" : "49.8%")};
  }
`;

export const CategoryName = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 45px;
`;

export const SeeAll = styled.div`
  align-self: flex-end;
  position: absolute;
  right: 20px;
  top: 25px;
  font-size: 14px;
  font-weight: 800;
  white-space: nowrap;
  width: min-content;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 16px;
`;

export const LessonsContainer = styled.div<{ wide: boolean }>`
  display: flex;
  width: 90%;
  height: auto;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  border: none;
  border-radius: 4px;
  @media ${TABLET} and ${DESKTOP} {
    width: ${(props) => (props.wide === true ? "97%" : "90%")};
  }
  @media ${TABLET} {
    border: 1px solid ${(props) => (props.wide === true ? "#F4F4F4" : "none")};
    flex-direction: ${(props) => (props.wide === true ? "row" : "column")};
  }
`;

export const VerticalBorder = styled.div<{ isWide: boolean; isLast: boolean }>`
  display: none;
  border-left: ${(props) =>
    props.isLast === true ? "none" : "1px solid rgba(0, 0, 0, 0.1)"};
  height: 60%;
  align-self: center;
  @media ${TABLET} {
    display: ${(props) => (props.isWide === true ? "block" : "none")};
  }
`;

export const BrowseAllSongs = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 16.5px;
  cursor: pointer;
  color: #190067;
  align-self: center;
  margin: 15px 0px 80px 0px;
`;

export const SongCardContent = styled.div<{ isWide: boolean | undefined }>`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  @media ${TABLET} {
    flex-direction: ${(props) => (props.isWide === true ? "column" : "row")};
  }
`;

export const SongCardWrapper = styled.div<{ isWide: boolean | undefined }>`
  min-height: 68px;
  display: flex;
  flex-direction: column;
  @media ${TABLET} {
    min-height: ${(props) => (props.isWide === true ? "155px" : "68px")};
  }
`;

export const SongTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 23.5px;
  margin-left: 10px;
`;

export const SongName = styled.div`
  color: #333333;
  font-weight: 700;
`;

export const SongArtist = styled.div`
  color: #818181;
  font-weight: 400;
`;

export const SkillTypes = styled.div<{
  isFinished: boolean;

  isWide: boolean | undefined;
}>`
  display: flex;
  align-self: flex-start;
  margin-bottom: ${(props) => (props.isWide ? "20px" : 0)}; ;
`;

export const SkillType = styled.div`
  height: 31px;
  width: 31px;
  background-color: #f3f3f3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
`;

export const SongProgressInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const SongProgressBackLine = styled.div`
  background: rgba(41, 187, 156, 0.3);
  position: relative;
  height: 4px;
  width: 70%;
  border-radius: 100px;
`;
export const SongProgressFrontLine = styled.div<{ progress: string }>`
  height: 100%;
  background: #29bb9c;
  border-radius: 100px;
  width: ${(props) => `${props.progress}%`};
`;
export const SongProgressStatus = styled.div<{ isFinished: boolean }>`
  color: black;
  margin: 0 12px 0 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  font-style: italic;
  color: ${(props) => (props.isFinished ? "#27AE60" : "#190067")};
`;
