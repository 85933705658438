import classNames from "classnames";
import { GUITAR, Instrument, UKULELE } from "constants/skills_constants";
import React from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as GuitarIcon } from "../assets/guitar_icon.svg";
import { ReactComponent as UkuleleIcon } from "../assets/ukulele_icon.svg";
import { GuitarGrid } from "../guitar_grid/guitar_grid";
import { TabButton } from "../tab_button/tab_button";
import { Tuner } from "../tuner/_tuner";
import css from "../tuner_layout.module.scss";
import { UkuleleGrid } from "../ukulele_grid/ukulele_grid";

interface ITunerMobileLayout {
  currentLayout: Instrument;
  setCurrentLayout: React.Dispatch<React.SetStateAction<Instrument>>;
  activeNoteFrequency: string;
  activeNoteName: string;
  setActiveNoteFrequency: React.Dispatch<React.SetStateAction<string>>;
  setActiveNoteName: React.Dispatch<React.SetStateAction<string>>;
  GuitarNoteList: React.ReactElement[];
  UkuleleNoteList: React.ReactElement[];
}

export const TunerMobileLayout = ({
  currentLayout,
  setCurrentLayout,
  activeNoteFrequency,
  setActiveNoteFrequency,
  GuitarNoteList,
  setActiveNoteName,
  UkuleleNoteList,
  activeNoteName,
}: ITunerMobileLayout) => {
  return (
    <Box className={css.main_wrapper}>
      <Box className={css.tuner_box}>
        <Box className={css.title_box}>
          <h1 className={css.heading}>Tuner</h1>
          <p className={css.subtext}>Choose a string to start tuning</p>
        </Box>
        <Box className={css.instrument_box_wrapper}>
          <Box className={css.button_box}>
            <TabButton
              type={GUITAR}
              currentLayout={currentLayout}
              setCurrentLayout={setCurrentLayout}
              setActiveNoteFrequency={setActiveNoteFrequency}
              setActiveNoteName={setActiveNoteName}
              title="Guitar"
              icon={
                <GuitarIcon
                  className={classNames({
                    [css.active_icon]: currentLayout === GUITAR,
                  })}
                />
              }
            />
            <TabButton
              type={UKULELE}
              currentLayout={currentLayout}
              setCurrentLayout={setCurrentLayout}
              setActiveNoteFrequency={setActiveNoteFrequency}
              setActiveNoteName={setActiveNoteName}
              title="Ukulele"
              icon={
                <UkuleleIcon
                  className={classNames({
                    [css.active_icon]: currentLayout === UKULELE,
                  })}
                />
              }
            />
          </Box>
          <Box
            className={
              currentLayout === GUITAR
                ? css.instrument_wrapper_guitar
                : css.instrument_wrapper_ukulele
            }
          >
            {currentLayout === GUITAR ? (
              <GuitarGrid
                setActiveNoteName={setActiveNoteName}
                setActiveNoteFrequency={setActiveNoteFrequency}
                activeNoteFrequency={activeNoteFrequency}
              />
            ) : (
              <UkuleleGrid
                setActiveNoteName={setActiveNoteName}
                setActiveNoteFrequency={setActiveNoteFrequency}
                activeNoteFrequency={activeNoteFrequency}
              />
            )}
          </Box>
        </Box>
        <Box className={css.tuner_layout_wrapper}>
          <Tuner
            currentLayout={currentLayout}
            frequency={activeNoteFrequency}
            selectedNote={activeNoteName}
          />
        </Box>
      </Box>
      <Box className={css.note_list}>
        {currentLayout === GUITAR ? GuitarNoteList : UkuleleNoteList}
      </Box>
    </Box>
  );
};
