import styled from "@emotion/styled";
import { Dialog, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, updateUserPrimaryEmail } from "redux/auth/auth_slice";
import { closeChangeEmailModal } from "redux/ui/modal/modal_slice";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { getUserSelector } from "redux/auth/user_selectors";

interface Form {
  password: string;
}

export const ChangeEmailDialog = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<{ password: string }>();
  const user = useSelector(getUserSelector);

  const handleCloseChangeEmailDialog = () => {
    dispatch(closeChangeEmailModal());
  };

  const email = useSelector(getModalDataSelector).changeEmail;

  const onSubmit = async ({ password }: Form) => {
    handleCloseChangeEmailDialog();
    if (user) {
      await dispatch(updateUserPrimaryEmail(user.id, email));
    }
    dispatch(handleLogin({ email, password }));
  };

  return (
    <Dialog open={!!email} onClose={handleCloseChangeEmailDialog}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <TextField
          autoFocus
          {...register("password")}
          type="password"
          label="password"
          variant="outlined"
          required
        />
        <Submit type="submit">Confirm</Submit>
      </FormContainer>
    </Dialog>
  );
};

const FormContainer = styled.form`
  display: flex;
  padding: 20px;
  flex-direction: column;
`;

const Submit = styled.button`
  margin: 8px auto 0;
`;
