/* eslint-disable no-unused-vars */
import { Children, MouseEvent, ReactNode } from "react";
import cn from "classnames";

import css from "./tabs.module.scss";

interface ITabs {
  children: ReactNode;
  selectedTab: number;
  setTab: (tabNumber: number) => void;
}

export const Tabs = (props: ITabs) => {
  const { children, selectedTab, setTab } = props;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const attribute = (
      (event?.target as HTMLElement)?.parentNode as HTMLElement
    )?.getAttribute("data-number");

    if (attribute !== null) {
      setTab(+attribute);
    } // is there better way?
  };

  return (
    <div className={css.tabs}>
      {Children.map(children, (child, i) => {
        const tabClass = cn(css.tab, { [css.active]: selectedTab === i });

        return (
          <button
            type="button"
            onClick={handleClick}
            data-number={i}
            className={tabClass}
          >
            {child}
          </button>
        );
      })}
    </div>
  );
};
