import cn from "classnames";
import css from "../line_dropdown.module.scss";

interface IDropDown {
  text: string[];
  tabNumber: number;
}

export const DropDown = (props: IDropDown) => {
  const { text, tabNumber } = props;
  return (
    <div className={cn(css.dropdown, css[`dropdown_${tabNumber}`])}>
      <ul>
        {text.map((listItem, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={cn(css.li)} key={i}>
            {listItem}
          </li>
        ))}
      </ul>
    </div>
  );
};
