import cn from "classnames";
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { getClassModalInfoDisabled } from "redux/ui/modal/modal_selectors";
import { ADD_STUDENTS, INFO } from "../../organization_constants";
import css from "./class_actions_tabs.module.scss";

interface IClassActionsTabs {
  setTab: Dispatch<number>;
  tab: number;
}

export const ClassActionsTabs = ({ setTab, tab }: IClassActionsTabs) => {
  const infoDisabled = useSelector(getClassModalInfoDisabled);

  return (
    <div className={css.tabsContainer}>
      <button
        type="button"
        onClick={() => (infoDisabled ? {} : setTab(INFO))}
        className={css.tab}
      >
        <div
          className={cn(css.topLine, tab === INFO ? css.tabActiveLine : null)}
        />
        <div
          className={cn(css.tabText, tab === INFO ? css.tabActiveText : null)}
        >
          Info
        </div>
      </button>
      <button
        type="button"
        onClick={() => setTab(ADD_STUDENTS)}
        className={css.tab}
      >
        <div
          className={cn(
            css.topLine,
            tab === ADD_STUDENTS ? css.tabActiveLine : null,
          )}
        />
        <div
          className={cn(
            css.tabText,
            tab === ADD_STUDENTS ? css.tabActiveText : null,
          )}
        >
          Add students
        </div>
      </button>
    </div>
  );
};
