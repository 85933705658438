import css from "./chords_with_strumming.module.scss";
import { ImgChordsWithStrumming } from "./img_chords_with_strumming/img_chords_with_strumming";

const ONE_CHORD = 1;

interface IChordStrummingImg {
  chords: string[];
  strummingUrl: string;
}

const ChordStrummingImg = (props: IChordStrummingImg) => {
  const { chords, strummingUrl } = props;
  return (
    <div className={css.wrapper}>
      {chords.map((chord, i) => (
        <ImgChordsWithStrumming
          chord={chord}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          strummingUrl={strummingUrl}
          isOnlyOneChord={chords.length === ONE_CHORD}
        />
      ))}
    </div>
  );
};

export default ChordStrummingImg;
