import React, { ReactNode } from "react";
import { Button, Theme, withStyles } from "@material-ui/core";

interface ISignUpButton {
  formId?: string;
  onClick?: () => void | Promise<void>;
  children: ReactNode;
  type?: "submit" | "reset" | "button";
  margin?: string;
  padding?: string;
  backgroundColor?: string;
  disabled?: boolean;
}

export const SignUpButton = ({
  disabled,
  onClick,
  children,
  type,
  padding,
  formId,
  margin,
  backgroundColor = "#6775DE",
}: ISignUpButton) => {
  const StyledButton = withStyles((theme: Theme) => ({
    root: {
      color: "white",
      textTransform: "initial",
      alignSelf: "stretch",
      padding: "0.5em 2.3em",
      fontWeight: 700,
      backgroundColor,
      maxWidth: "210px",
      width: "100%",
      "&:hover": {
        backgroundColor,
      },
    },
  }))(Button);

  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      form={formId}
      style={{ margin, padding }}
    >
      {children}
    </StyledButton>
  );
};
