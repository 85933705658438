import { Dispatch, MouseEvent, RefObject, useEffect } from "react";

/** Hook that helps control dropdowns;
 * set dropdown status to 0 if user clicked not on `dropDownRef` element;
 * Returns a onClick function, which change dropdown status;
 *
 * @param isOpened Current state of dropdown
 * @param setOpened Functon that set isOpened (setOpenedValue / 0)
 * @param setOpenedValue Value that hook should pass into setOpened
 * @param dropDownRef Element, click on which should not close the dropdown
 */

export const useDropdown = (
  isOpened: boolean,
  setOpened: Dispatch<number>,
  setOpenedValue: number,
  dropDownRef: RefObject<HTMLButtonElement>,
) => {
  const handleTabClick = () => {
    // if user clicks on dropdown icon, but some other tab is opened
    // we need to close previous tab (that does `handleClick`)
    // and only then set new tab so we use setTImeout
    setTimeout(() => setOpened(isOpened ? 0 : setOpenedValue), 0);
  };

  // when user clicked not on a dropdown
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as any) &&
      isOpened === true
    ) {
      setOpened(0);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick as any);
    return () => document.removeEventListener("click", handleClick as any);
  });

  return handleTabClick;
};
