import Media from "react-media";
import NavDesktop from "./desktop/nav_desktop";
import { NavMobile } from "./mobile/nav_mobile";
import css from "./nav.module.scss";

const Nav = () => {
  return (
    <div className={css.main}>
      <Media query={{ minWidth: 660 }}>
        {(matches) => (matches ? <NavDesktop /> : <NavMobile />)}
      </Media>
    </div>
  );
};

export default Nav;
