import { ThemeProvider } from "@emotion/react";
import { Box, createTheme, Slider } from "@material-ui/core";
import { useCallback } from "react";
import { SongPlayersControlsButtons } from "../song_player_controls_buttons/song_player_controls_buttons";
import css from "./song_player_controls.module.scss";

interface ISongPlayerControls {
  isPlaying: boolean;
  songProgress: number;
  playButtonHandler: () => void;
  onScrubEnd: () => void;
  audioUrl: string;
  duration: number;
  // eslint-disable-next-line no-unused-vars
  onScrub: (_: any, value: number | number[]) => void;
}

const sliderTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#8F8E93",
      },
      track: {
        color: "#D3D3D3",
      },
    },
  },
});

export const SongPlayerControls = ({
  isPlaying,
  audioUrl,
  songProgress,
  onScrub,
  onScrubEnd,
  playButtonHandler,
  duration,
}: ISongPlayerControls) => {
  const formatDuration = useCallback((duration: number) => {
    const minute = Math.floor(duration / 60);
    const secondLeft = Math.floor(duration - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }, []);

  return (
    <>
      <SongPlayersControlsButtons
        isPlaying={isPlaying}
        audioUrl={audioUrl}
        playButtonHandler={playButtonHandler}
        isLoaded={!!duration}
      />
      <Box className={css.progress_bar_wrapper}>
        <Box className={css.inner_container}>
          <Box width="100%">
            <ThemeProvider theme={sliderTheme}>
              <Slider
                disabled={!duration}
                className={css.slider}
                aria-label="time-indicator"
                value={songProgress}
                min={0}
                step={1}
                onMouseUp={onScrubEnd}
                onKeyUp={onScrubEnd}
                max={Number(duration)}
                onChange={onScrub}
              />
            </ThemeProvider>
          </Box>
          <Box className={css.duration_wrapper}>
            <p>{formatDuration(songProgress)}</p>
            <p>-{formatDuration(Number(duration) - songProgress)}</p>
          </Box>
        </Box>
      </Box>
    </>
  );
};
