import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transitionsApi } from "api/transitions_api";
import { ISkill } from "types/models/skill";

interface ITransitionsState {
  fetching: boolean;
  guitar: ISkill[];
  ukulele: ISkill[];
}

const initialState: ITransitionsState = {
  fetching: true,
  guitar: [],
  ukulele: [],
};

export const fetchTransitions = createAsyncThunk(
  "transitions/fetchTransitions",
  async () => {
    const { data: transitions } = await transitionsApi.fetchTransitions();
    return transitions;
  },
);

const transitionsSlice = createSlice({
  name: "transitions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransitions.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchTransitions.fulfilled, (state, action) => {
      state.guitar = action.payload.filter(
        (transition) => transition.skill?.skill?.name === "Guitar",
      );
      state.ukulele = action.payload.filter(
        (transition) => transition.skill?.skill?.name === "Ukulele",
      );
      state.fetching = false;
    });
    builder.addCase(fetchTransitions.rejected, (state, action) => {
      state.fetching = false;
      console.error("fetch transitions error", action.error);
    });
  },
});

export const { reducer: transitionsReducer } = transitionsSlice;
