/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

export const useGapi = () => {
  const [gapi, setGapi] = useState(window.gapi);

  useEffect(() => {
    const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");
    if (!gapi && !iOSApp) {
      if (window.gapi) {
        setGapi(window.gapi);
      } else {
        document
          .querySelector("#google-api-js")
          ?.addEventListener("load", () => {
            setGapi(window.gapi);
          });
      }
    }
  }, []);

  return gapi;
};
