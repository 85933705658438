import styled from "@emotion/styled";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { VisibilityOffIcon } from "img/icon/visibility_off_icon";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCourses } from "redux/courses/courses_selectors";
import { SETTINGS_HIDDEN_SONGS } from "routes/route_constants";
import { CourseSwitch } from "../course_checkbox/course_switch";

interface ILessonPrivacy {
  amountHiddenSongs: number;
}

export const LessonPrivacy = ({ amountHiddenSongs }: ILessonPrivacy) => {
  const courses = useSelector(getCourses);

  return (
    <Container>
      <Header>Lesson Privacy</Header>
      <Explicit>Hide Explicit Songs</Explicit>

      {courses.map((course) => (
        <Line key={course.name}>
          <Course>
            {course.name} | &nbsp;<span>{course.users.length} students</span>
          </Course>
          <CourseSwitch course={course} />
        </Line>
      ))}

      <Line>
        <Visibility>
          <VisibilityOffIcon />
          <div>Hidden Songs | {amountHiddenSongs} Songs Hidden Globally</div>
        </Visibility>

        <Link to={SETTINGS_HIDDEN_SONGS}>
          <Hide>
            <span>HIDE SPECIFIC SONGS</span>
            <StyledArrowDownwardIcon />
          </Hide>
        </Link>
      </Line>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #818181;
`;

const Explicit = styled.div`
  text-align: end;
  border-bottom: 1px solid #cccccc;
  padding: 0 2em 1.4em 0;
`;

const Header = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #818181;
  padding: 2em 0 0 0;
  border-top: 1px solid #cccccc1a;
  margin-top: 1.6em;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.8em 2em 1.4em 0;
  border-bottom: 1px solid #cccccc;
`;

const Course = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;

  span {
    font-weight: normal;
  }
`;

const Visibility = styled.div`
  display: flex;
  align-items: center;
  div {
    padding-left: 1em;
  }
`;
const Hide = styled(Visibility)`
  font-size: 1.2em;
  color: #190067;
  font-weight: 600;
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
  transform: rotate(-90deg);
  padding: 0 0.5em;
`;
