import "react-toastify/dist/ReactToastify.css";

import { API_KEY, CLIENT_ID, SCOPES } from "constants/keys";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constants/message_constants";
import { ContextWrapper } from "context/context_wrapper";
import { useTimerActions } from "hooks/use_timer_actions";
import { useGapi } from "hooks/useGapi";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  fetchPermissions,
  googleInitialized,
  handleLogout,
  oauthLogin,
  validateToken,
} from "redux/auth/auth_slice";
import { userApi } from "api/user_api";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { ROLES } from "redux/auth/roles/roles_consts";
import {
  gapiInitializedSelector,
  getUserEmailSelector,
} from "redux/auth/user_selectors";
import { fetchDecades } from "redux/decades/decades_slice";
import {
  fetchAmountLessons,
  fetchLessonKeys,
  fetchSongKeys,
  searchLessons,
} from "redux/entities/lessons/lessons_slice";
import { fetchProgressions } from "redux/entities/progressions/progressions_slice";
import { fetchTransitions } from "redux/entities/transitions/transitions_slice";
import { fetchFreeLessonsCount } from "redux/free_user_lessons/free_user_lessons_slice";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import { getUserFetchedSelector } from "redux/ui/filters/filters_selectors";
import { changeFilter } from "redux/ui/filters/filters_slice";
import { WithAccessRoute } from "routes/with_access_route";
import { closeModal } from "redux/ui/modal/modal_slice";

import { fetchChords } from "../redux/entities/chords/chords_slice";
import { SIGNUP, UPGRADE } from "../routes/route_constants";
import { ChordLibrary } from "./chord_library";
import { Loader } from "./loading/loader";
import Spinner from "./loading/spinner";
import { MainContent } from "./MainContent";
import { Modal } from "./modal/modal";
import { AddClassModal } from "./organization/class_actions_modal/class_actions_modal";
import { SignUpSteps } from "./sign_up/newSignUp";
import { StudentConversationDialog } from "./student_conversation";
import { ArchivedDialog } from "./student_conversation/archived_dialog/archived_dialog";

const App = () => {
  const dispatch = useDispatch();

  const userFetched = useSelector(getUserFetchedSelector);
  const gapiInitialized = useSelector(gapiInitializedSelector);

  const [fetchingData, setFetchingData] = useState(true);
  const gapi = useGapi();

  const userEmail = useSelector(getUserEmailSelector);

  const dispatchUserClicked = useCallback(() => {
    dispatch(changeFilter({ filter: "userClicked", value: true }));
    document.removeEventListener("click", dispatchUserClicked);
  }, [dispatch]);

  useTimerActions();

  useEffect(() => {
    function updateSignInStatus(isSignedIn: boolean) {
      if (isSignedIn) {
        const GoogleAuth = gapi.auth2.getAuthInstance();
        const token = GoogleAuth.currentUser.get().getAuthResponse().id_token;
        dispatch(oauthLogin({ token, provider: "google" }));
      } else {
        dispatch(handleLogout({}));
      }
    }

    function initClient() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          // discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          const GoogleAuth = gapi.auth2.getAuthInstance();
          dispatch(googleInitialized({ gapi }));
          // Listen for sign-in state changes.
          GoogleAuth.isSignedIn.listen(updateSignInStatus);
        })
        .catch((err) => {
          console.error(err);
          dispatch(googleInitialized({}));
        });
    }
    if (gapi) {
      gapi.load("client:auth2", initClient);
    }
  }, [gapi, dispatch]);

  const delayDebounce = 500;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce_fun = useCallback(
    _.debounce(() => {
      dispatch(searchLessons({ resetLessons: true }));
    }, delayDebounce),
    [],
  );
  const songFilters = useSelector(getSongFilters);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      debounce_fun();
    }
  }, [dispatch, songFilters, debounce_fun]);

  useEffect(() => {
    // bootstrap user (check to see if they are logged in)
    dispatch(validateToken());

    const firstReqSearchLessons = () => {
      if (isFirstRender.current) {
        dispatch(searchLessons({ resetLessons: true }));
        isFirstRender.current = false;
      }
    };

    // @ts-ignore
    Promise.all([
      dispatch(fetchFreeLessonsCount()),
      dispatch(fetchDecades()),
      dispatch(fetchChords()),
      dispatch(fetchTransitions()),
      dispatch(fetchProgressions()),
      dispatch(fetchSongKeys()),
      dispatch(fetchLessonKeys()),
      firstReqSearchLessons(),
      dispatch(fetchAmountLessons()),
    ])
      .then(() => setFetchingData(false))
      .catch(() => {
        toast.error(SOMETHING_WENT_WRONG_MESSAGE);
      });

    // This is for the tuner page, so the tuner starts automatically if the user has interacted with the site.
    document.addEventListener("click", dispatchUserClicked);
    // don't add user to dependencies because of infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dispatchUserClicked]);

  useEffect(() => {
    type SocialAuthEventDetail = {
      accessToken: string;
      provider: string;
      email: string;
    };

    type InAppPurchaseDetail = {
      status: string;
    };

    // Called from the iOS app after it authenticates with the social provider
    const handleExternalSocialAuth = (
      event: CustomEvent<SocialAuthEventDetail>,
    ) => {
      const { accessToken, provider } = event.detail;
      dispatch(oauthLogin({ token: accessToken, provider }));
    };

    window.addEventListener(
      "external-social-auth",
      handleExternalSocialAuth as (event: Event) => void,
    );

    const handleExternalInAppPurchase = async (event: Event) => {
      const customEvent = event as CustomEvent<InAppPurchaseDetail>;
      if (userEmail) {
        await userApi.updateUserRole({
          email: userEmail,
          roleName:
            customEvent.detail.status === "success"
              ? ROLES.PAID_SONGWRITING
              : ROLES.FREE_SONGWRITING,
        });
        dispatch(fetchPermissions());
        dispatch(closeModal());
      }
    };

    window.addEventListener(
      "external-in-app-purchase",
      handleExternalInAppPurchase as (event: Event) => void,
    );

    return () =>
      window.removeEventListener(
        "external-social-auth",
        handleExternalSocialAuth as (event: Event) => void,
      );
  }, [dispatch, userEmail]);

  if (fetchingData || !userFetched || !gapiInitialized) {
    return (
      <>
        <Loader spinner={Spinner} />
        <ToastContainer />
      </>
    );
  }

  return (
    <div id="app" className="app">
      <ContextWrapper>
        <Modal />
        <Switch>
          <WithAccessRoute
            path={UPGRADE}
            component={SignUpSteps}
            permission={PERMISSIONS.ACCESS_TO_GUITAR}
            role={ROLES.FREE_TEACHER_ACCOUNT}
          />
          <Route path={SIGNUP} component={SignUpSteps} />
          <Route component={MainContent} />
        </Switch>
        <AddClassModal />
        <ToastContainer />
        <ChordLibrary />
        <StudentConversationDialog />
        <ArchivedDialog />
        {/* <StudentChoosePlan /> */}
      </ContextWrapper>
    </div>
  );
};

export default withRouter(App);
