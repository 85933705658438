import styled from "@emotion/styled/macro";
import { eventHandler } from "hooks/use_popover";
import { Popover } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import { ILesson } from "api/lesson_api";
import { ISkill } from "types/models/skill";
import { CardHoverLessonSkill } from "./CardHoverLessonSkill";
import Chords from "./images/Chords.svg";
import Difficulty from "./images/Difficulty.svg";
import Melody from "./images/Melody.svg";
import Song_Key from "./images/Song_Key.svg";
import Strumming from "./images/Strumming.svg";
import Tempo from "./images/Tempo.svg";

interface ICardHoverPopover {
  lesson: ILesson;
  songInfoPopover: HTMLElement | null;
  closeSongInfoPopover: eventHandler;
}

// todo: check performance
export const CardHoverPopover = ({
  lesson,
  songInfoPopover,
  closeSongInfoPopover,
}: ICardHoverPopover) => {
  const [skills, setSkills] = useState<React.ReactNode[]>([]);

  useLayoutEffect(() => {
    const newSkills = [];

    if (
      lesson.skills.some((skillType: { name: string }) =>
        [
          "Basic Chords",
          "Barre Chords",
          "Chord Transitions",
          "Chord Progressions",
        ].includes(skillType.name),
      )
    ) {
      const chords = lesson.skills.filter(
        (skill: ISkill) =>
          skill.skill?.name === "Basic Chords" ||
          skill.skill?.name === "Barre Chords",
      );
      newSkills.push(
        <CardHoverLessonSkill
          key="Chords"
          text={`${chords.length} Chords`}
          value={chords.map((chord: { name: string }) => chord.name).join(", ")}
          icon={<img src={Chords} alt="chords" />}
        />,
      );
    }

    if (
      lesson.skills.some(
        (skillType: { name: string }) => skillType.name === "Strumming",
      )
    ) {
      const strumming = lesson.skills.find(
        (skill: ISkill) => skill.skill?.name === "Strumming",
      );

      newSkills.push(
        <CardHoverLessonSkill
          key="Strumming"
          text="Strumming"
          // because we  have legacy multiple strummings for each song
          value={strumming?.name.split(" ")[0]}
          icon={<img src={Strumming} alt="Strumming" />}
        />,
      );
    }

    if (
      lesson.skills.some(
        (skillType: { name: string }) => skillType.name === "Melody",
      )
    ) {
      newSkills.push(
        <CardHoverLessonSkill
          key="Melody"
          text="Melody"
          icon={<img src={Melody} alt="Melody" />}
        />,
      );
      let difficulty = "Easy";
      switch (lesson.difficulty_level) {
        case "medium":
          difficulty = "Medium";
          break;
        case "hard":
          difficulty = "Hard";
          break;
        default:
          difficulty = "Easy";
          break;
      }

      newSkills.push(
        <CardHoverLessonSkill
          key="Difficulty"
          text="Difficulty"
          value={difficulty}
          icon={<img src={Difficulty} alt="chords" />}
        />,
      );
    }

    newSkills.push(
      <CardHoverLessonSkill
        key="Lesson Key"
        text="Lesson Key"
        value={lesson.lesson_key}
        icon={<img src={Song_Key} alt="chords" />}
      />,
    );
    newSkills.push(
      <CardHoverLessonSkill
        key="Song Key"
        text="Song Key"
        icon={<img src={Song_Key} alt="chords" />}
        value={lesson.song.song_key}
      />,
    );
    newSkills.push(
      <CardHoverLessonSkill
        key="Tempo"
        text="Tempo (bpm)"
        value={`${lesson.song.tempo}`}
        icon={<img src={Tempo} alt="chords" />}
      />,
    );
    newSkills.push(
      <CardHoverLessonSkill
        key="Time signature"
        text="Time signature"
        value={`${lesson.song.time_signature}`}
        icon={<img src={Tempo} alt="chords" />}
      />,
    );

    setSkills(newSkills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.id]);

  return (
    <Popover
      disableScrollLock
      anchorEl={songInfoPopover}
      keepMounted
      open={Boolean(songInfoPopover)}
      onClose={closeSongInfoPopover}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <HoverContainer>
        <SongName>{lesson.song.song_name}</SongName>
        {skills}
      </HoverContainer>
    </Popover>
  );
};

const SongName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #130863;
  margin-bottom: 28px;
`;

const HoverContainer = styled.div`
  padding: 16px;
`;
