import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getUserMediaRecordsWithFeedback = (state: IState) =>
  state.feedback.feedback;

export const getFeedbackInfo = (state: IState) => state.feedback.info;

export const getNewUserFeedbackCount = createSelector(
  getUserMediaRecordsWithFeedback,
  (mediaRecordsWithFeedbacks) => {
    return mediaRecordsWithFeedbacks.filter(
      (mediaRecordWithFeedback: IMediaRecord) =>
        doesFeedbackExist(mediaRecordWithFeedback) &&
        !doesFeedbackViewed(mediaRecordWithFeedback),
    ).length;
  },
);

const doesFeedbackExist = (mediaRecord: IMediaRecord) => {
  return !!mediaRecord.feedbacks.length;
};

const doesFeedbackViewed = (mediaRecord: IMediaRecord) =>
  mediaRecord.feedbacks[0]?.viewed;
