// @ts-nocheck

import { googleTagManagerUtil } from "./google_tag_mananger_util";
import { gaUtil } from "./ga_util";

class Analytic {
  pageView() {
    gaUtil.pageView();
    googleTagManagerUtil.pageView();
  }

  sendSignUpEvent() {
    googleTagManagerUtil.sendSignUpEvent();
  }

  sendSubscribeEvent() {
    googleTagManagerUtil.sendSubscribeEvent();
  }
}

export const analyticUtil = new Analytic();
