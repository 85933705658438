import React from "react";
import css from "../../../view_song_modal.module.scss";

interface IViewLyricRow {
  row: number;
  song_line: string;
}

export const ViewLyricRow: React.FC<IViewLyricRow> = ({ row, song_line }) => {
  const rowKey = `lyricsRowKey(${row})`;
  const lyricFullRowKey = `lyricsRowKey(${row})(0)`;

  return (
    <tr className={css.lyricRow} key={rowKey}>
      <td colSpan={50} className={css.lyrics} key={lyricFullRowKey}>
        <input className={css.lyrics_input} value={song_line} />
      </td>
    </tr>
  );
};
