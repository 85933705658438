import React from "react";
import { Box, Link } from "@material-ui/core";
import css from "./listen_on_spotify.module.scss";

interface IListenOnSpotify {
  spotifyId: string;
}
export const ListenOnSpotify = ({ spotifyId }: IListenOnSpotify) => {
  const spotifyLink = `https://open.spotify.com/track/${spotifyId}`;

  return (
    <Box className={css.inner_box}>
      <Box className={css.divider} />
      <Link href={spotifyLink} className={css.link_styles} target="_blank">
        {" "}
        <img
          className={css.icon_styles}
          src="https://img.icons8.com/fluency/48/000000/spotify.png"
          alt=""
        />
        <span className={css.span_styles}>Listen on spotify</span>
      </Link>
    </Box>
  );
};
