import { DESKTOP } from "scss/emotion_consts";
import styled from "@emotion/styled";

export const StretchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  min-height: 100vh;
  max-width: 98vw;

  a {
    color: #76b3f3;
    font-weight: 800;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  max-width: 1980px;
`;

export const Header = styled.div`
  max-width: 600px;
  font-weight: 700;
  font-size: 2em;
  text-align: center;
  color: #190067;
  margin: 1em;

  @media ${DESKTOP} {
    margin-top: 2em;
  }
`;

export const SignUpCheck = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledParagraph = styled.p`
  font-size: 16px;
`;

export const TextLink = styled.button`
  color: #8564f1;
  cursor: pointer;
  font-size: 17px;
  text-align: center;
  font-weight: 900;
  margin-left: 3px;
  &:hover {
    color: #190067;
  }
`;

export const StyledInput = styled.input`
  padding: 15px 0px 14px 16px;
  border-radius: 4px;
  background: #f3f3f3;
  color: #818181;
  font-size: 16px;
  line-height: 19px;
  min-width: 100px;
  text-align: start;
  width: 400px;
  @media (max-width: 900px) {
    width: initial;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-self: center;
  text-align: start;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: stretch;
`;

export const CircleBackground = styled.div`
  font-family: arial;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #6775de;
  color: white;
  text-align: center;
  font-size: 2em;
`;

export const StyledButtonBack = styled.button`
  align-self: flex-start;
`;
