import React from "react";
import { SONG } from "redux/songwriting/songwriting_consts";
import { useFindActiveCellIndex } from "./use_find_active_index_of_inputs";

interface IUseClipboardHandlers {
  editableCellRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  pasteRows: (_: number, _array: any) => void;
}

export const usePasteHandler = ({
  editableCellRefs,
  pasteRows,
}: IUseClipboardHandlers) => {
  const { findActiveIndex } = useFindActiveCellIndex({
    cellRefs: editableCellRefs,
  });

  const handlePaste = (e: any) => {
    const index = findActiveIndex();
    const pasteArray: [string] = e.clipboardData
      .getData("text")
      .split("\n")
      .filter((element: string) => element);
    const cursorAt = e.target.selectionStart;
    if (index) {
      const currentValue = editableCellRefs.current[index]?.value;
      const stringStart = currentValue?.substring(0, cursorAt);
      const stringEnd = currentValue?.substring(cursorAt);

      // preserve any current text on the row being pasted into
      pasteArray[0] = [stringStart, pasteArray[0]].join("");
      pasteArray[pasteArray.length - 1] = [
        pasteArray[pasteArray.length - 1],
        stringEnd,
      ].join("");

      // ensure the final array fits within the defined line size
      const sizedPasteArray: string[] = [];
      pasteArray.forEach((value: string) => {
        while (value.length > SONG.MAX_LENGTH_EDITABLE_CELL) {
          sizedPasteArray.push(
            value.substring(0, SONG.MAX_LENGTH_EDITABLE_CELL),
          );
          value = value.substring(SONG.MAX_LENGTH_EDITABLE_CELL, value.length);
        }
        sizedPasteArray.push(value);
      });

      pasteRows(index, sizedPasteArray);
    }
    e.preventDefault();
  };

  return handlePaste;
};
