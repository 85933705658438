/* eslint-disable no-magic-numbers */
import { ReactNode } from "react";
import css from "./button.module.scss";

interface IOauthButton {
  width: number;
  children: ReactNode;
  provider: string;
}

export const OauthButton = ({ provider, width, children }: IOauthButton) => {
  return (
    <svg
      className={css.oauth_button}
      width={`${width}px`}
      height="46px"
      viewBox={`0 0 ${width} 46`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>btn_oauth_light_normal_ios_{provider}</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <filter
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
          filterUnits="objectBoundingBox"
          id={`filter-${provider}`}
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.168 0"
            in="shadowBlurOuter1"
            type="matrix"
            result="shadowMatrixOuter1"
          />
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter2"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.084 0"
            in="shadowBlurOuter2"
            type="matrix"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <rect
          id={`path-${provider}`}
          x="0"
          y="0"
          width={width - 6}
          height="40"
          rx="2"
        />
      </defs>
      <g
        id="Oauth-Button"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="9-PATCH" transform="translate(-608.000000, -160.000000)" />
        <g
          id="btn_oauth_light_normal"
          transform="translate(-1.000000, -1.000000)"
        >
          <g
            id="button"
            transform="translate(4.000000, 4.000000)"
            filter={`url(#filter-${provider})`}
          >
            <g id="button-bg">
              <use
                fill="#FFFFFF"
                fillRule="evenodd"
                xlinkHref={`#path-${provider}`}
              />
              <use fill="none" xlinkHref={`#path-${provider}`} />
              <use fill="none" xlinkHref={`#path-${provider}`} />
              <use fill="none" xlinkHref={`#path-${provider}`} />
            </g>
          </g>
          <g id="logo_oauth_48dp" transform="translate(15.000000, 15.000000)">
            {children}
          </g>
          <g id="handles_square" />
        </g>
        <text
          className={css.oauth_text}
          x="50px"
          y="27px"
          fill="rgba(0,0,0,0.64)"
        >
          Log in with {provider}
        </text>
      </g>
    </svg>
  );
};
