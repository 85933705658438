import { Box, SvgIconTypeMap, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

interface IButtonDisplay {
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  text: string;
}

export const ButtonDisplay = ({ Icon, text }: IButtonDisplay) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" color="#fff">
      <Icon />
      <Typography style={{ textTransform: "none", fontFamily: "Raleway" }}>
        {text}
      </Typography>
    </Box>
  );
};
