import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { addWeeks, differenceInWeeks } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { getUserCourses } from "redux/user_courses/user_courses_selectors";
import {
  closeConversionlModal,
  openConversionlModal,
} from "redux/ui/modal/modal_slice";
import { PERMISSIONS } from "../../../redux/auth/permissions/permissions_consts";

const NOTIFICATION_WEEKS_MIN_VALUE = 8;

const cookies = new Cookies();

export const useStudentConversationDialog = () => {
  const courses = useSelector(getUserCourses);
  const endDateString = courses[0]?.end_date;

  const end_date = useMemo(() => {
    return new Date(endDateString);
  }, [endDateString]);

  const dispatch = useDispatch();
  const conversationDialog = useSelector(getModalDataSelector)
    .studentConversion;

  const userId = useSelector(getUserIdSelector);
  const accessStudent = useSelector(accessByPermissionSelector(PERMISSIONS.STUDENT_ACCESS));

  const closeStudentConversationDialog = () => {
    dispatch(closeConversionlModal());
  };

  const openStudentConversationDialog = useCallback(() => {
    dispatch(openConversionlModal());
  }, [dispatch]);

  useEffect(() => {
    if (!userId || accessStudent || !courses[0]) {
      return;
    }
    const weeksCheck =
      differenceInWeeks(new Date(end_date), new Date()) <
      NOTIFICATION_WEEKS_MIN_VALUE;
    const seenValue = cookies.get("studentSaveEmail");
    if (weeksCheck && !seenValue) {
      openStudentConversationDialog();
      cookies.set("studentSaveEmail", "seen", {
        expires: addWeeks(new Date(), 1),
      });
    }
  }, [openStudentConversationDialog, end_date, userId, accessStudent, courses]);

  return {
    conversationDialog,
    closeStudentConversationDialog,
  };
};
