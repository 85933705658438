import { lessonApi } from "api/lesson_api";
import { push } from "connected-react-router";
import { getLessonUrl } from "util/url_util";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constants/message_constants";
import { receiveUserLesson } from "redux/entities/user_lessons/user_lessons_actions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IState } from "redux/store";
import { closeModal } from "redux/ui/modal/modal_slice";

export const fetchFreeLessonsCount = createAsyncThunk<
  number,
  void,
  { state: IState }
>("auth/handleLogin", async () => {
  return lessonApi.fetchMaxFreeLessons().then((payload) => {
    const {
      data: { maxFreeLessons },
    } = payload;

    return maxFreeLessons;
  });
});

interface IPurchaseLessonForFree {
  lesson_id: number;
}
export const purchaseLessonForFree = createAsyncThunk<
  void,
  IPurchaseLessonForFree,
  { state: IState }
>("auth/handleLogin", async ({ lesson_id }, { dispatch }) => {
  const result = lessonApi
    .purchaseLessonForFree(lesson_id)
    .then((payload) => {
      dispatch(receiveUserLesson(payload.data));
      dispatch(closeModal());
      dispatch(push(getLessonUrl(lesson_id)));
    })
    .catch((err) => {
      toast.error(SOMETHING_WENT_WRONG_MESSAGE);
      return Promise.reject(err);
    });
  return result;
});

const initialState = {
  maxFreeLessonsCount: 0,
  fetching: false,
};

const freeUserLessons = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFreeLessonsCount.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchFreeLessonsCount.fulfilled, (state, action) => {
      if (action.payload) {
        state.maxFreeLessonsCount = action.payload;
      }
      state.fetching = false;
    });
    builder.addCase(fetchFreeLessonsCount.rejected, (state) => {
      state.fetching = false;
    });
  },
});

export const { reducer: freeUserLessonsReducer } = freeUserLessons;
