/* eslint-disable jsx-a11y/no-autofocus */
import { useDispatch, useSelector } from "react-redux";
import { changeAssetTitle } from "redux/songwriting/songwriting_slice";
import { changeAudioSeedTitle } from "redux/song_seeds/song_seeds_slice";
import { getCurrentSeedType } from "redux/song_seeds/song_seed_selector";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import css from "./editable_recording_title.module.scss";

interface IEditableRecordingTitle {
  id: number;
  title: string;
  defaultTitle: boolean;
}

export const EditableRecordingTitle: React.FC<IEditableRecordingTitle> = ({
  id,
  title,
  defaultTitle,
  children,
}) => {
  const [currentTitle, setCurrentTitle] = useState(title);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const currentSeedType = useSelector(getCurrentSeedType);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setCurrentTitle(e.target.value);
    setIsEditing(false);
    if (title === e.target.value) {
      return;
    }
    if (e.target.value.length === 0) {
      setCurrentTitle(title);
      return;
    }

    if (currentSeedType === "audio") {
      dispatch(changeAudioSeedTitle({ id, title: e.target.value }));
    } else {
      dispatch(changeAssetTitle({ id, title: e.target.value }));
    }
  };

  const handleCancel = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentTitle(title);
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && defaultTitle) {
      inputRef.current?.select();
    }
  }, [isEditing, defaultTitle]);

  return isEditing ? (
    <div style={{ display: "flex" }}>
      <input
        autoFocus
        onBlur={handleBlur}
        defaultValue={currentTitle}
        className={css.song_title_input}
        enterKeyHint="enter"
        ref={inputRef}
      />
      <div className={css.icons_wrapper}>
        <CheckIcon className={css.icon} />
        <CloseIcon className={css.icon} onMouseDown={handleCancel} />
      </div>
    </div>
  ) : (
    <div role="presentation" onClick={handleClick} className={css.song_title}>
      {children}
    </div>
  );
};
