import React from "react";
import { Row, Cell } from "react-table";
import { RowType } from "redux/songwriting/songwriting_interfaces";
import { EditableCell } from "../editable_cell/editable_cell";

interface IEditableRowSongLyrics {
  row: Row<RowType>;
  title: string;
  editableCellRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  index: number;
  songUnitId: number;
  handlePaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const EditableRowSongLyrics = ({
  row,
  editableCellRefs,
  handleKeyDown,
  title,
  index,
  songUnitId,
  handlePaste,
}: IEditableRowSongLyrics) => {
  return (
    <>
      {row.cells.map(
        (cell: Cell<any>) =>
          cell.value !== undefined && (
            <EditableCell
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              key={`key-${title}-${cell.value}-${cell.row}`}
              // eslint-disable-next-line no-return-assign
              ref={(el) => (editableCellRefs.current[index] = el)}
              value={row.values.c0}
              index={index}
              songUnitId={songUnitId}
            />
          ),
      )}
    </>
  );
};
