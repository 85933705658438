import { getMediaRecordingsRecords } from "redux/media_recordings/media_recordings_selectors";
import { createSelector } from "reselect";
import _ from "lodash";
import { IState } from "redux/store";
import { TAssessmentSortTypes } from "./constants";

const getAssessmentsSelector = (state: IState) => state.assessments;

const getAssessmentsSkillType = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.skillType,
);

const getAssessmentsDateRange = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.dateRange,
);

const getAssessmentsClass = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.class,
);

const getAssessmentsStudentName = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.name,
);

export const getAssessmentsSort = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.sort,
);

export const getAssessmentsFilteredRecords = createSelector(
  [
    getMediaRecordingsRecords,
    getAssessmentsSkillType,
    getAssessmentsDateRange,
    getAssessmentsClass,
    getAssessmentsStudentName,
    getAssessmentsSort,
  ],
  (
    records,
    skillTypeFilter,
    dateRangeFilter,
    classFilter,
    nameFilter,
    sort,
  ) => {
    let finalRecords = [...records];

    if (skillTypeFilter.active) {
      finalRecords = finalRecords.filter((record) =>
        skillTypeFilter.filteredRecords.some(
          (filteredRecord) => filteredRecord.id === record.id,
        ),
      );
    }

    if (dateRangeFilter.active) {
      finalRecords = finalRecords.filter((record) =>
        dateRangeFilter.filteredRecords.some(
          (filteredRecord) => filteredRecord.id === record.id,
        ),
      );
    }

    if (classFilter.active) {
      finalRecords = finalRecords.filter((record) =>
        classFilter.filteredRecords.some(
          (filteredRecord) => filteredRecord.id === record.id,
        ),
      );
    }

    if (nameFilter.active) {
      finalRecords = finalRecords.filter((record) =>
        nameFilter.filteredRecords.some(
          (filteredRecord) => filteredRecord.id === record.id,
        ),
      );
    }

    let sortBy = null;
    switch (sort.type) {
      case TAssessmentSortTypes.NAME:
        sortBy = "user.fname";
        break;
      case TAssessmentSortTypes.SKILL:
        sortBy = "skill.name";
        break;
      case TAssessmentSortTypes.SKILL_TYPE:
        sortBy = "skill.skill.name";
        break;
      case TAssessmentSortTypes.SONG_LESSON:
        sortBy = "lesson.song_name";
        break;
      case TAssessmentSortTypes.SUBMISSION:
        sortBy = "feedbacks[0]";
        break;
      case TAssessmentSortTypes.SUBMISSION_DATE:
        sortBy = "createdAt";
        break;
      default:
        sortBy = "user.name";
        break;
    }
    finalRecords = _.orderBy(finalRecords, sortBy, sort.direction);

    return {
      finalRecords,
      isFiltered:
        skillTypeFilter.active ||
        dateRangeFilter.active ||
        classFilter.active ||
        nameFilter.active,
    };
  },
);

export const getSelectedAssessments = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.selectedAssessments,
);

export const getDropAssessmentsSelection = createSelector(
  getAssessmentsSelector,
  (assessments) => assessments.dropSelection,
);
