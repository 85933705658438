/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from "react";

/**
 * Controls metronome sound
 * @param {function} disableScroll
 * @param {boolean} scrollActive
 * @param {number} tempo
 * @param {Object} audioRef
 */
export const useMetronome = (disableScroll, scrollActive, tempo, audioRef) => {
  const [metronomeTimerId, setMetronomeTimerId] = useState(0);

  const scrollRef = useRef({ scroll: scrollActive });
  scrollRef.current.scroll = scrollActive;

  const toggleMetronome = () => {
    disableScroll();
    if (metronomeTimerId) {
      clearInterval(metronomeTimerId);
      setMetronomeTimerId(0);
    } else {
      turnOnMetronome();
    }
  };
  const turnOffMetronome = () => {
    if (metronomeTimerId) {
      clearInterval(metronomeTimerId);
      setMetronomeTimerId(0);
    }
  };

  const turnOnMetronome = () => {
    const interval = DEFAULT_TIME / tempo;
    const metronomeId = window.setInterval(() => {
      if (scrollRef.current.scroll) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    }, interval);
    setMetronomeTimerId(metronomeId);
  };

  const refreshMetronome = () => {
    turnOffMetronome();
    const interval = DEFAULT_TIME / tempo;
    const metronomeId = window.setInterval(() => {
      if (scrollRef.current.scroll) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    }, interval);
    setMetronomeTimerId(metronomeId);
  };

  useEffect(() => {
    if (metronomeTimerId) {
      const interval = DEFAULT_TIME / tempo;
      clearInterval(metronomeTimerId);

      const metronomeId = window.setInterval(() => {
        if (scrollRef.current.scroll) {
          audioRef.current.currentTime = 0;
          audioRef.current.play();
        }
      }, interval);

      setMetronomeTimerId(metronomeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempo]);
  return {
    toggleMetronome,
    metronomeTimerId,
    turnOffMetronome,
    refreshMetronome,
  };
};

const DEFAULT_TIME = 60000;
