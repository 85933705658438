import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOrganizationForTeacher as createOrganizationTeacherSignUp } from "redux/organizations/organization_slice";
import { getUserEmailSelector } from "redux/auth/user_selectors";
import {
  getPricePerStudent,
  getProductQuantity,
} from "redux/entities/stripe/stripe_selectors";
import { UPGRADE } from "routes/route_constants";
import {
  ROLES,
  PAID_TEACHER_SUBSCRIPTION,
} from "redux/auth/roles/roles_consts";

import { getStudentsNumber } from "redux/organizations/organization_selectors";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  setStripeProductQuantity,
  setPricePerStudent,
  setProductId,
} from "redux/entities/stripe/stripe_slice";
import { useLocation } from "react-router";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import {
  EmotionInput,
  InputError,
} from "components/sign_up/reusable/styled_input";
import { fetchPermissions, updateUserRole } from "redux/auth/auth_slice";
import { getSignUpStepsUserDataSelector } from "redux/sign_up_steps/sign_up_steps_selectors";
import { Header, StretchContainer, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { FooterSignUp } from "../../reusable/footer_sign_up";
import { LogoWithBackground } from "../../reusable/background_image";
import { CloseStepsButton } from "../../reusable/close_steps_button";

import defaultAvatar from "../../images/default-avatar.svg";
import { stepsId } from "../../newSignUp";
import {
  ErrorContainer,
  AmountInputContainer,
  StyledForm,
  AmountStudentsHeader,
  ContainerAmountStudents,
  AmountStudents,
  AmountStudentsInput,
  StyledContainer,
  Example,
} from "./create_organization.styled";

const DEFAULT_AMOUNT_STUDENTS = 30;
const FREE_TEACHER_AMOUNT_STUDENTS = 100;

interface IFormInputs {
  orgName: string;
  amountStudents: number;
}

export const CreateOrganization = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentNumberOfStudents = useSelector(getStudentsNumber);
  const userEmail = useSelector(getUserEmailSelector);
  const role = useSelector(getRoleSelector);
  const idFormCreateOrg = "create_organization_teacher_signUp_form";
  const isSoloTeacher = useSelector(getRoleSelector) === ROLES.SOLO_TEACHER;
  const pricePerStudent = useSelector(getPricePerStudent);
  const signUpRole = useSelector(getSignUpStepsUserDataSelector)?.role || null;

  useEffect(() => {
    if (userEmail && signUpRole) {
      dispatch(updateUserRole({ email: userEmail, roleName: signUpRole }));
      dispatch(fetchPermissions());
    }
  }, [dispatch, signUpRole, userEmail]);

  useEffect(() => {
    if (role !== null && isSoloTeacher) {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.signUpStepList,
          stepId: stepsId.choosePayment,
        }),
      );
    }
  }, [dispatch, isSoloTeacher, role]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInputs>();

  const watchAllFields = watch();
  const productQuantity = useSelector(getProductQuantity);

  const studentsAmount = useMemo(
    () => watchAllFields.amountStudents,
    [watchAllFields.amountStudents],
  );
  // TODO: create a function
  useEffect(() => {
    if (studentsAmount && productQuantity !== studentsAmount) {
      dispatch(setStripeProductQuantity(studentsAmount));

      if (studentsAmount < PAID_TEACHER_SUBSCRIPTION.COST_0_99.LIMIT) {
        dispatch(setPricePerStudent(PAID_TEACHER_SUBSCRIPTION.COST_0_99.PRICE));
        dispatch(setProductId(PAID_TEACHER_SUBSCRIPTION.COST_0_99.PRICE_ID));
      } else if (
        studentsAmount >= PAID_TEACHER_SUBSCRIPTION.COST_100_499.LIMIT
      ) {
        dispatch(
          setPricePerStudent(PAID_TEACHER_SUBSCRIPTION.COST_MORE_500.PRICE),
        );
        dispatch(
          setProductId(PAID_TEACHER_SUBSCRIPTION.COST_MORE_500.PRICE_ID),
        );
      } else {
        dispatch(
          setPricePerStudent(PAID_TEACHER_SUBSCRIPTION.COST_100_499.PRICE),
        );
        dispatch(setProductId(PAID_TEACHER_SUBSCRIPTION.COST_100_499.PRICE_ID));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields, dispatch]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (userEmail) {
      let amountStudents = 0;

      if (role === ROLES.PAID_TEACHER_ACCOUNT || pathname === UPGRADE) {
        amountStudents = data.amountStudents;
      } else if (role === ROLES.FREE_TEACHER_ACCOUNT) {
        amountStudents = FREE_TEACHER_AMOUNT_STUDENTS;
      }

      dispatch(
        createOrganizationTeacherSignUp({
          email: userEmail,
          orgName: data.orgName,
          max_seats: amountStudents,
        }),
      );
    }
  };

  const studentNumberObj = {
    value: currentNumberOfStudents || 1,
    errorMessage: currentNumberOfStudents
      ? "The number of students to upgrade cant be less than current number of students"
      : "The number of students must be a positive number.",
  };

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={45} />
        <CloseStepsButton />
      </Top>
      <StyledContainer>
        <Header>
          {pathname === UPGRADE
            ? "Number of students to upgrade "
            : "Name Your Account"}
        </Header>
        <LogoWithBackground
          src={defaultAvatar}
          padding="33px"
          margin="2em 1em 4em"
        />
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={idFormCreateOrg}>
          {pathname !== UPGRADE && (
            <>
              <EmotionInput
                hookFormErr={errors}
                placeholder="School Account Name"
                {...register("orgName", {
                  required: "Enter organization name",
                  minLength: {
                    value: 6,
                    message:
                      "Organization name must contain more than 6 symbols",
                  },
                })}
              />

              <Example>
                {"i.e. Exeter High Guitar, Mrs. Green Ukulele, Blue Hawk \n"}
                Music We’ll set up your classes / periods later.
              </Example>
            </>
          )}

          {(role === ROLES.PAID_TEACHER_ACCOUNT || pathname === UPGRADE) && (
            <>
              <AmountStudentsHeader>
                Enter the number of students
              </AmountStudentsHeader>

              <ContainerAmountStudents>
                <AmountInputContainer>
                  <AmountStudentsInput
                    defaultValue={DEFAULT_AMOUNT_STUDENTS}
                    {...register("amountStudents", {
                      valueAsNumber: true,
                      required: "required",
                      min: {
                        value: studentNumberObj.value,
                        message: studentNumberObj.errorMessage,
                      },
                    })}
                  />
                  <ErrorContainer>
                    <ErrorMessage
                      errors={errors}
                      name="amountStudents"
                      render={({ message }) => (
                        <InputError width="400px">{message}</InputError>
                      )}
                    />
                  </ErrorContainer>
                </AmountInputContainer>

                <AmountStudents>
                  <div>
                    $<span>{pricePerStudent}</span>
                    /student
                  </div>
                </AmountStudents>
              </ContainerAmountStudents>
            </>
          )}
        </StyledForm>
      </StyledContainer>
      <FooterSignUp type="submit" form={idFormCreateOrg} textButton="CONTINUE">
        Want to talk to a real human? Email{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:help@moosiko.com"
        >
          help@moosiko.com
        </a>{" "}
        or book a time directly on our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://meetings.hubspot.com/dan613"
        >
          calendar
        </a>
      </FooterSignUp>
    </StretchContainer>
  );
};
