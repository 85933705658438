import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCarouselTeachPluckModalOpen } from "redux/carousel/carousel_selectors";
import { setCarouselTeachPluckModalOpen } from "redux/carousel/carousel_slice";
import { getLessonFromLocationSelector } from "redux/entities/lessons/lesson_selectors";
import { ISlide } from "api/lesson_api";
import { IState } from "redux/store";
import { AudioPlayer } from "../../audioplayer/AudioPlayer";
import { MoreInfoSlide } from "./more_info_slide";
import { chordsApi, IChordText } from "../../../api/chords_api";
import { chords } from "../../../resources/chords";
import css from "./slide_types.module.scss";

const FIRST_AUDIO_ASSET_NUMBER = 2;
const SECOND_AUDIO_ASSET_NUMBER = 3;

interface ITeachPluck {
  slide: ISlide;
  isSlideActive: boolean;
}

const TeachPluck = (props: ITeachPluck) => {
  const {
    slide: { text_top, text_bottom, assets = [], chord },
    isSlideActive,
  } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { instrument } = useSelector((state: IState) =>
    getLessonFromLocationSelector(state, pathname),
  );

  const modalOpen = useSelector(getCarouselTeachPluckModalOpen);

  const setModal = useCallback(
    (value) => {
      dispatch(setCarouselTeachPluckModalOpen(value));
    },
    [dispatch],
  );

  const [isSwitched, setSwitch] = useState(true);
  const [clickCoords, setClickCoords] = useState({ x: 0, y: 0 });

  const [chordInfo, setChordInfo] = useState<{
    name: string;
    chordText: IChordText | null;
  }>({ name: chord, chordText: null });

  useEffect(() => {
    (async () => {
      const chordText = await chordsApi.fetchChordText({
        chordName: chordInfo.name,
        instrument,
      });
      setChordInfo({ ...chordInfo, chordText: chordText.data });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSlideActive) {
      setModal(false);
    }
  }, [isSlideActive, setModal]);

  const closeSlide = () => {
    setModal(false);
  };

  const handleMouseUp = (event: React.MouseEvent) => {
    if (clickCoords.y === event.clientY && clickCoords.x === event.clientX) {
      setModal(true);
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    setClickCoords({ y: event.clientY, x: event.clientX });
  };

  const imgSrc = chords[instrument][chord].diagram_top_level;

  return !modalOpen ? (
    <div className={css.main}>
      <div className={css.text_top}>{text_top}</div>
      <div className={css.mid}>
        <div className={css.img_audio}>
          <button
            className={css.info_img}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            type="button"
          >
            <div className={css.chord_diagram_wrapper}>
              <img className={css.pluck_img} src={imgSrc} alt="" />
            </div>

            <div className={css.tap_for_more}>tap for more</div>
          </button>
          <div className={css.tp_audio}>
            <div className={css.correct}>Correct - clean sound</div>
            <AudioPlayer src={assets[FIRST_AUDIO_ASSET_NUMBER].url} />
            <div className={css.incorrect}>Incorrect - muffled strings</div>
            <AudioPlayer src={assets[SECOND_AUDIO_ASSET_NUMBER].url} />
          </div>
        </div>
      </div>
      <div className={css.text_bot}>{text_bottom}</div>
    </div>
  ) : (
    <MoreInfoSlide
      isSwitched={isSwitched}
      setSwitch={setSwitch}
      closeInfoSlide={closeSlide}
      chord={chordInfo}
    />
  );
};

export default TeachPluck;
