import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { chords } from "resources/chords";
import { getUserChordSkillsSelector } from "redux/entities/user_skills/user_skills_selector";
import CloseIcon from "@material-ui/icons/Close";
import React, { ChangeEvent, useEffect, useState } from "react";
import { getChordLibraryModalStatus } from "redux/ui/modal/modal_selectors";
import { Dialog } from "@material-ui/core";
import {
  closeChordLibraryModal,
  GUITAR_CHORD_LIBRARY_TYPE,
  openChordLibraryModal,
  UKULELE_CHORD_LIBRARY_TYPE,
} from "redux/ui/modal/modal_slice";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import css from "./chord_library.module.scss";
import { ChordLibraryChord } from "./chord_library_chord/chord_library_chord";

const guitarChordsArray = Object.entries(chords.Guitar);
const ukuleleChordsArray = Object.entries(chords.Ukulele);

const CHORD_KEY_NAME = 0;

export const ChordLibrary = () => {
  const userChords = useSelector(getUserChordSkillsSelector);
  const dialogType = useSelector(getChordLibraryModalStatus);

  const chordsArray =
    dialogType === UKULELE_CHORD_LIBRARY_TYPE
      ? ukuleleChordsArray
      : guitarChordsArray;

  const [finalChords, setFinalChords] = useState(chordsArray);

  const [search, setSearch] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    setFinalChords(
      chordsArray.filter((chord) =>
        chord[CHORD_KEY_NAME].toUpperCase().includes(search.toUpperCase()),
      ),
    );
  }, [chordsArray, search]);

  const handleClearInput = () => {
    setSearch("");
  };

  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeChordLibraryModal());

  const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: string) => {
    switch (newValue) {
      case UKULELE_CHORD_LIBRARY_TYPE:
        dispatch(openChordLibraryModal(UKULELE_CHORD_LIBRARY_TYPE));
        break;
      case GUITAR_CHORD_LIBRARY_TYPE:
      default:
        dispatch(openChordLibraryModal(GUITAR_CHORD_LIBRARY_TYPE));
    }
  };

  return (
    <Dialog open={!!dialogType} fullScreen>
      <Tabs
        value={dialogType}
        onChange={handleTabChange}
        indicatorColor="primary"
        centered
      >
        <Tab label="Guitar" value={GUITAR_CHORD_LIBRARY_TYPE} />
        <Tab label="Ukulele" value={UKULELE_CHORD_LIBRARY_TYPE} />
      </Tabs>
      <div className={css.container}>
        <div className={css.input_container}>
          <input
            onChange={handleChange}
            value={search}
            placeholder="Search Chord..."
            className={css.input}
          />
          {search && (
            <button
              className={css.clearButton}
              type="button"
              onClick={handleClearInput}
            >
              <CloseIcon fontSize="small" />
            </button>
          )}
        </div>
        <div className={css.known_chords}>
          <CheckCircleOutlineIcon
            style={{ color: "#29bb9c", marginRight: "8px" }}
          />
          Known Chords
        </div>
        <div className={css.chords}>
          {finalChords.map((chord) => (
            <ChordLibraryChord
              alreadyKnow={userChords.includes(chord[0])}
              key={chord[0]}
              chord={chord}
            />
          ))}
        </div>
        <button type="button" onClick={handleClose} className={css.close}>
          <CloseIcon fontSize="large" />
        </button>
      </div>
    </Dialog>
  );
};
