import { useCallback, useEffect, useRef, useState } from "react";
import SmoothScroll from "smooth-scroll";
import { useScrollPercentage } from "./use_scroll_percentage";

/**
 * Scrolls page from current position to bottom with given parameters
 *
 * @param {number} lessonDuration
 * @param {number} speedModifier
 */
export const useScroll = (lessonDuration, speedModifier) => {
  const reScrollRef = useRef({ timerId: null });
  const [scrollActive, setScrollActive] = useState(false);

  const scrollPercentage = useScrollPercentage();
  const durationInMS = Math.ceil(
    lessonDuration * ONE_SECOND_IN_MS * speedModifier * (1 - scrollPercentage),
  );

  /**
   * Stops scrolling when page is scrolled to bottom
   */
  useEffect(() => {
    if (scrollPercentage === 1) {
      setScrollActive(false);
    }
  }, [scrollPercentage, setScrollActive]);

  /**
   * Scroll executes with delay, so this variable shows is scroll REQUESTED but NOT EXECUTED at the moment
   */
  const [scrollRequested, setScrollRequested] = useState(false);

  /**
   * Executes Scroll
   * (duration is stored in scroll object)
   */
  const executeScroll = useCallback(() => {
    const height = Math.max(
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );
    scrollRef.current.scroll.animateScroll(height - window.innerHeight);
  }, []);

  /**
   * Updates scrollActive status to true & executes scroll
   */
  const enableScroll = useCallback(() => {
    setScrollActive(true);
    executeScroll();
  }, [setScrollActive, executeScroll]);

  /*
   * Updates scrollActive status to false & dispatches native "cancelScroll" custom event
   */
  const disableScroll = useCallback(() => {
    setScrollActive(false);
    const event = new CustomEvent("cancelScroll");
    document.dispatchEvent(event);
  }, []);

  /**
   * toggles scrolling
   */
  const toggleScroll = () => {
    if (scrollActive) {
      disableScroll();
    } else if (scrollRef.current.status === "set") {
      enableScroll();
    } else {
      setTimeout(() => {
        enableScroll();
      }, SCROLL_DELAY_IN_MS);
    }
  };

  /**
   * Used to re-create SmoothScroll object to change scroll duration
   */
  const [updateScrollObject, setUpdateScrollObject] = useState(0);

  /**
   * Create SmoothScroll library object
   */
  const [smoothScroll, setSmoothScroll] = useState(
    new SmoothScroll("window", {
      speed: durationInMS,
      speedAsDuration: true,
      easing: "Linear",
      emitEvents: false,
    }),
  );

  /**
   * Stop scroll when user leaves the page
   */
  useEffect(() => {
    const { current } = scrollRef;
    return () => {
      current.scroll.cancelScroll();
    };
  }, []);

  const scrollRef = useRef({
    status: "set",
    scroll: smoothScroll,
    timeoutId: 0,
    scrollEnabled: scrollActive,
  });
  scrollRef.current.scroll = smoothScroll;
  scrollRef.current.scrollEnabled = scrollActive;
  useEffect(() => {
    scrollRef.current.scroll.cancelScroll();
    scrollRef.current.status = "changing";
    clearTimeout(scrollRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      scrollRef.current.scroll.cancelScroll(true);
      scrollRef.current.status = "set";
      // we need to recreate this object because of speed change
      const newScrollObject = new SmoothScroll("window", {
        speed: durationInMS,
        speedAsDuration: true,
        easing: "Linear",
        emitEvents: false,
      });
      setSmoothScroll(newScrollObject);
      scrollRef.current.scroll = newScrollObject;
      scrollRef.current.timeoutId = null;
    }, SCROLL_DELAY_IN_MS);
    // @ts-ignore
    scrollRef.current.timeoutId = timeoutId;
    // do not add duration to dependencies, updateScrollObject plays this role
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speedModifier, updateScrollObject, setSmoothScroll]);

  /**
   * Stops scroll when cancelScroll event is fired
   * (it is fired manually by new CustomEvent("cancelScroll") )
   */
  useEffect(() => {
    const scrollStop = event => {
      setUpdateScrollObject(updateScrollObject => updateScrollObject + 1);
    };
    document.addEventListener("cancelScroll", scrollStop);
    return () => {
      document.removeEventListener("cancelScroll", scrollStop);
    };
  }, []);

  /**
   * Continues scroll after delay if user scrolls the page
   */
  useEffect(() => {
    const readjustScroll = () => {
      if (scrollRef.current.scrollEnabled) {
        disableScroll();
        clearTimeout(reScrollRef.current.timerId);
        setScrollRequested(true);
        const setTimeoutCallback = () =>
          setTimeout(() => {
            if (scrollRef.current.status === "set") {
              enableScroll();
              reScrollRef.current.timerId = null;
              setScrollRequested(false);
            } else {
              reScrollRef.current.timerId = setTimeoutCallback();
            }
          }, 100);
        reScrollRef.current.timerId = setTimeoutCallback();
      } else {
        disableScroll();
      }
    };
    document.addEventListener("wheel", readjustScroll);
    document.addEventListener("touchmove", readjustScroll);
    return () => {
      document.removeEventListener("wheel", readjustScroll);
      document.removeEventListener("touchmove", readjustScroll);
    };
  }, [disableScroll, enableScroll]);

  return { disableScroll, scrollActive, toggleScroll, scrollRequested };
};

const SCROLL_DELAY_IN_MS = 700;
const ONE_SECOND_IN_MS = 1000;
