import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserSession, userTimeApi } from "api/user_session_api";
import {
  DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART,
  mondayNweeksEarlier,
  TODAY,
} from "constants/date_fns_constants";
import { fetchUserCourses } from "redux/user_courses/user_courses_slice";
import { IState } from "redux/store";
import { fetchUserSkillsAction } from "../user_skills/user_skills_slice";

interface IFetchMyJourneyData {
  userId: number;
}

export const fetchMyJourneyData = createAsyncThunk<
  IUserSession[],
  IFetchMyJourneyData,
  { state: IState }
>("fetchMyJourneyData", async ({ userId }, { dispatch }) => {
  dispatch(fetchUserSkillsAction(true));
  dispatch(fetchUserCourses({ userId }));
  const { data: userTime } = await userTimeApi.getTime(
    userId,
    mondayNweeksEarlier(DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART),
    TODAY,
  );
  return userTime;
});

const initialState: IMyJourneyState = {
  minutesPerDay: [],
  timer: null,
};

interface IMyJourneyState {
  minutesPerDay: IUserSession[];
  timer: number | null;
}

const myJourneySlice = createSlice({
  name: "myJourney",
  initialState,
  reducers: {
    setTimerAction: (state, action: PayloadAction<number>) => {
      state.timer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyJourneyData.fulfilled, (state, { payload }) => {
      state.minutesPerDay = payload;
    });
  },
});

export const {
  reducer: myJourneyReducer,
  actions: { setTimerAction },
} = myJourneySlice;
