import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logout } from "redux/auth/auth_actions";
import { IState } from "redux/store";
import { ICourseWithTime, userCoursesApi } from "./user_courses_api";

export interface IUserCoursesState {
  courses: ICourseWithTime[];
}

const initialState: IUserCoursesState = {
  courses: [],
};

interface IFetchUserCourses {
  userId: number;
}

export const fetchUserCourses = createAsyncThunk<
  ICourseWithTime[],
  IFetchUserCourses,
  { state: IState }
>("fetchUserCourses", async ({ userId }) => {
  const courses = await userCoursesApi.fetchUserCourses(userId);
  return courses;
});

const userCoursesSlice = createSlice({
  name: "chords",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserCourses.fulfilled, (state, action) => {
      state.courses = action.payload;
    });
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { reducer: userCoursesReducer } = userCoursesSlice;
