import Button, { BTN_TYPE } from "components/reusable/buttons/button";
import css from "../recommendation_modal.module.scss";

interface IUpgradeBtn {
  onClick: () => void;
}

export const UpgradeBtn = (props: IUpgradeBtn) => {
  return (
    <div className={css.teaser_upgrade}>
      <Button
        classes={{
          btn: css.upgrade_btn,
        }}
        type={BTN_TYPE.PRIMARY}
        {...props}
      >
        Upgrade
      </Button>

      <div className={css.upgrade_text}>
        Upgrade to Member to view your song recommendations
      </div>
    </div>
  );
};
