import { useCallback } from "react";

import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";
import { Box, createTheme, Slider } from "@material-ui/core";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import css from "./main_page_song_player_controls.module.scss";

interface ISongPlayerControls {
  isPlaying: boolean;
  songProgress: number;
  playButtonHandler: () => void;
  onScrubEnd: () => void;
  duration: number;
  // eslint-disable-next-line no-unused-vars
  onScrub: (_: any, value: number | number[]) => void;
}

const sliderTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#8F8E93",
      },
      track: {
        color: "#D3D3D3",
      },
    },
  },
});

export const SongPlayerControls = ({
  isPlaying,
  songProgress,
  onScrub,
  onScrubEnd,
  playButtonHandler,
  duration,
}: ISongPlayerControls) => {
  const formatDuration = useCallback((duration: number) => {
    const minute = Math.floor(duration / 60);
    const secondLeft = Math.floor(duration - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }, []);

  const mobile = useSelector(getUsingMobileAppFlag);

  return (
    <>
      {isPlaying ? (
        <PauseCircleOutline
          onClick={playButtonHandler}
          className={duration ? css.active_icon : css.disabled_icon}
        />
      ) : (
        <PlayCircleOutlineIcon
          onClick={playButtonHandler}
          className={duration ? css.active_icon : css.disabled_icon}
        />
      )}

      <Box
        className={
          mobile ? css.progress_bar_wrapper_mobile : css.progress_bar_wrapper
        }
      >
        <Box className={css.inner_container}>
          <Box width="100%">
            <ThemeProvider theme={sliderTheme}>
              <Slider
                disabled={!duration}
                className={css.slider}
                aria-label="time-indicator"
                value={songProgress}
                min={0}
                step={1}
                onMouseUp={onScrubEnd}
                onKeyUp={onScrubEnd}
                max={Number(duration)}
                onChange={onScrub}
              />
            </ThemeProvider>
          </Box>
          <Box className={css.duration_wrapper}>
            <p>{formatDuration(songProgress)}</p>
            <p>-{formatDuration(Number(duration) - songProgress)}</p>
          </Box>
        </Box>
      </Box>
    </>
  );
};
