import React from "react";

import css from "../../../view_song_modal.module.scss";

interface IChordInfo {
  title: string;
  icon: React.ReactElement;
}

interface IViewChordRow {
  row: number;
  songChords: [{ chord: string }];
  chordLazyImages: IChordInfo[];
}

export const ViewChordRow: React.FC<IViewChordRow> = ({
  row,
  songChords,
  chordLazyImages,
}) => {
  const rowKey = `chordsRowKey(${row})`;
  const chordFullRowKey = `chordRowKey(${row})(0)`;

  return (
    <tr className={css.chordRow} key={rowKey}>
      {songChords.length > 0 ? (
        Array.from({ length: 50 }).map((_, col) => {
          const foundChordForPos = songChords.find((entry: any) => {
            return (
              Number(entry.column) === col && Number(entry.row) === row * 2
            );
          });

          const chordColKey = `chordRowKey(${row})(${col})`;

          if (foundChordForPos) {
            const foundEntry = chordLazyImages.find(
              (entry: IChordInfo) => entry.title === foundChordForPos.chord,
            );
            if (foundEntry) {
              return (
                <td className={css.chordCell} key={chordColKey}>
                  <img
                    src={foundEntry.icon.props.src}
                    alt={foundEntry.icon.props.alt}
                    className={css.chord}
                  />
                </td>
              );
            }
          }

          return <td className={css.chordCell} key={chordColKey} />;
        })
      ) : (
        <td colSpan={50} key={chordFullRowKey} />
      )}
    </tr>
  );
};
