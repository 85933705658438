/* eslint-disable no-unused-vars */
import { Box, Divider } from "@material-ui/core";
import { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { DragOverlay } from "@dnd-kit/core";
import { getDraggableItem } from "redux/songwriting/songwriting_selectors";
import { BuildSongBar } from "./build_song_bar/build_song_bar";
import { IdeasBar } from "./ideas_bar/ideas_bar";
import { RecordBar } from "./record_bar/record_bar";
import css from "./song_builder_sidebar.module.scss";
import { DraggableItem } from "./song_items_grid/grid_item/draggable_item/draggable_item";

enum SideBarTabs {
  BUILD_SONG,
  RECORD,
  IDEAS,
}

const HEADER_BUTTONS = [
  { title: "BUILD SONG", tab: SideBarTabs.BUILD_SONG },
  { title: "RECORD", tab: SideBarTabs.RECORD },
  { title: "TIPS & IDEAS", tab: SideBarTabs.IDEAS },
];

export const SongBuilderSideBar = () => {
  const [activeTab, setActiveTab] = useState<SideBarTabs>(
    SideBarTabs.BUILD_SONG,
  );

  const draggableItem = useSelector(getDraggableItem);

  const renderCurrentTab = (param: SideBarTabs) => {
    switch (param) {
      case SideBarTabs.BUILD_SONG: {
        return <BuildSongBar />;
      }
      case SideBarTabs.IDEAS: {
        return <IdeasBar />;
      }
      case SideBarTabs.RECORD: {
        return <RecordBar />;
      }
      default: {
        console.error("No tab found.");
        return null;
      }
    }
  };

  return (
    <Box className={css.main_wrapper}>
      <Box className={css.side_bar_header}>
        <ul role="menu" className={css.button_list}>
          {HEADER_BUTTONS.map(({ title, tab }, index) => (
            <li
              tabIndex={index}
              role="tab"
              onClick={() => setActiveTab(tab)}
              key={title}
            >
              <p
                className={classNames(css.button_title, {
                  [css.button_title_active]: activeTab === tab,
                })}
              >
                {title}
              </p>
            </li>
          ))}
        </ul>
        <Divider />
      </Box>
      <Box className={css.wrapper_content_sidebar}>
        {renderCurrentTab(activeTab)}
      </Box>
      {draggableItem ? (
        <DragOverlay className={css.drag_overlay}>
          <DraggableItem item={draggableItem} />
        </DragOverlay>
      ) : null}
    </Box>
  );
};
