import { useEffect, useCallback, ReactNode } from "react";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchingMoreSelector,
  getSkillTypeSelector,
  getSkillNameSelector,
  getRecommendedLessonsCountSelector,
  getSkillIdSelector,
  getSkillInstrumentSelector,
} from "redux/entities/recommendation/recommendation_selectors";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import {
  fetchMoreRecommendedLessons,
  fetchRecommendedLessons,
  onRecommendedSongClick,
  resetRecommendedLessonsAction,
} from "redux/entities/recommendation/recommendation_slice";
import { openPayForSubscriptionModal } from "redux/ui/modal/modal_slice";
import { RecommendedLesson } from "./recommended_lesson";
import { ModalContainer } from "../modal_container";
import css from "./recommendation_modal.module.scss";
import modalCSS from "../modal.module.scss";
import { UploadMoreBtn } from "./buttons/upload_more_button";
import { UpgradeBtn } from "./buttons/upgrade_button";

export const noop = () => {};

const PAGINATION = 4;

interface IRecommendationModalBase {
  title?: ReactNode;
  teaser?: boolean | null;
  lessons?: {
    id: number;
    artist: string;
    song_name: string;
    unknownChords: string[];
    instrument: string;
    unknownTransitions?: string[];
    unknownProgressions?: string[];
    unknownStrummings?: string[];
  }[];
}

export const RecommendationModalBase = (props: IRecommendationModalBase) => {
  const {
    title = "Songs to Learn Next.",
    teaser = false,
    lessons = [],
  } = props;

  const { title: modalTitle } = useSelector(getModalDataSelector);
  const skillType = useSelector(getSkillTypeSelector);
  const instrument = useSelector(getSkillInstrumentSelector);
  const skillName = useSelector(getSkillNameSelector);
  const skillId = useSelector(getSkillIdSelector);
  const { lessonsCount, start } = useSelector(
    getRecommendedLessonsCountSelector,
  );
  const dispatch = useDispatch();
  const fetchingMore = useSelector(fetchingMoreSelector);

  useEffect(() => {
    dispatch(
      fetchRecommendedLessons({
        type: skillType,
        skillName,
        skillId,
        instrument,
      }),
    );

    return () => {
      dispatch(resetRecommendedLessonsAction());
    };
  }, [dispatch, skillType, skillName, skillId, instrument]);
  const fetchMoreLessons = useCallback(() => {
    dispatch(
      fetchMoreRecommendedLessons({
        type: skillType,
        skillName,
        skillId,
        instrument,
      }),
    );
  }, [dispatch, skillType, skillName, skillId, instrument]);

  const upgradeCallback = useCallback(() => {
    dispatch(openPayForSubscriptionModal());
  }, [dispatch]);
  const areAllLessonsLoaded = () => {
    return start + PAGINATION - lessonsCount < 0;
  };

  return (
    <ModalContainer classes={{ main: css.modal }}>
      <div className={css.title_background}>
        <div className={cn(modalCSS.title, css.title)}>
          {modalTitle || title}
        </div>
      </div>

      {!!lessons.length && (
        <div className={css.recommendation_wrapper}>
          {lessons.map((song) => {
            const {
              id,
              artist,
              song_name,
              unknownChords,
              unknownTransitions = [],
              unknownProgressions = [],
              unknownStrummings = [],
              instrument,
            } = song;

            return (
              <RecommendedLesson
                key={id}
                artist={artist}
                song={song_name}
                newChords={unknownChords}
                newTransitions={format(unknownTransitions)}
                newProgressions={format(unknownProgressions)}
                newStrummings={unknownStrummings}
                instrument={instrument}
                teaser={teaser || false}
                onClick={
                  teaser
                    ? noop
                    : () => dispatch(onRecommendedSongClick({ lessonId: id }))
                }
              />
            );
          })}
        </div>
      )}

      <div className={cn({ [css.content_offset]: !!lessons.length })}>
        {!teaser && areAllLessonsLoaded() && (
          <UploadMoreBtn
            fetchMoreLessons={fetchMoreLessons}
            fetchingMore={fetchingMore}
          />
        )}
        {teaser && <UpgradeBtn onClick={upgradeCallback} />}
      </div>
    </ModalContainer>
  );
};

const format = (jsonArray: string[]) => {
  return jsonArray.map((skill) => JSON.parse(skill).join("-"));
};
