import { MOBILE_WIDTH, useWindowWidth } from "hooks/use_width";
import downloadImage from "img/songs/download.svg";
import moment from "moment";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { coursesApi } from "redux/courses/courses_api";
import {
  getCourseById,
  getCourseCSVData,
  getCoursesEntity,
} from "redux/courses/courses_selectors";
import { setCourseCSVData } from "redux/courses/courses_slice";
import { IStudentWithSkills } from "types/models/student_with_skills";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { SortOrder } from "constants/sort_order";
import { StudentRow } from "../student_row";
import { StudentRowMobile } from "../student_row_mobile";
import css from "./students_table.module.scss";

interface IStudentsTable {
  students: IStudentWithSkills[];
}

const FIRST_NAME = 0;
const SKILLS = 3;
const SONGS_PRACTICED = 4;
const PRACTICE_TIME = 5;

const headers = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Email", key: "email" },
  { label: "Skills Learned", key: "skills_number" },
  { label: "Songs Learned", key: "lessons_number" },
  { label: "Practice Time (minutes)", key: "practice_time_mins" },
];

type StudentKeys =
  | "first_name"
  | "last_name"
  | "email"
  | "skills_number"
  | "lessons_number"
  | "practice_time_mins";

export const StudentsTable = (props: IStudentsTable) => {
  const dispatch = useDispatch();
  const { students } = props;
  const width = useWindowWidth();

  const courseStats = useSelector(getCourseCSVData);
  const courseId = Number(useParams<{ courseId: string }>().courseId);
  const dateFormat = "MMMM DD YYYY";

  const [sortOrder, setSortOrder] = useState<SortOrder.ASC | SortOrder.DESC>(
    SortOrder.ASC,
  );
  const [sortBy, setSortBy] = useState("first_name");

  const courseInfo = useSelector(getCourseById(courseId));

  const { startDate, endDate } = useSelector(getCoursesEntity);
  const modifiedEndDate = useMemo(
    () => endDate || moment().toISOString(),
    [endDate],
  );

  const onDownloadCsvClick = useCallback(async () => {
    const dateRange = {
      startDate,
      endDate: modifiedEndDate,
    };
    const fetchedCourseStats = (
      await coursesApi.fetchCourseStats(courseId, dateRange, sortBy, sortOrder)
    ).data.courseStats;
    dispatch(setCourseCSVData(fetchedCourseStats));
  }, [courseId, modifiedEndDate, startDate, dispatch, sortBy, sortOrder]);

  const formattedStartDate = moment(startDate).format(dateFormat);
  const formattedEndDate = moment(modifiedEndDate).format(dateFormat);

  useEffect(() => {
    onDownloadCsvClick();
  }, [onDownloadCsvClick]);

  const csvReport = {
    filename: `${courseInfo?.name} ${formattedStartDate} to ${formattedEndDate}`,
    headers,
    data: courseStats,
    asyncOnClick: true,
  };

  const sortIcon =
    sortOrder === SortOrder.ASC ? <ArrowUpward /> : <ArrowDownward />;

  const sortHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name } = e.currentTarget;
    setSortBy(name);
    setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
  };

  return width > MOBILE_WIDTH ? (
    <div className={css.table}>
      <CSVLink {...csvReport}>
        <img
          role="presentation"
          className={css.download}
          src={downloadImage}
          alt="download"
        />
      </CSVLink>

      <div className={css.tr}>
        <button
          type="button"
          className={css.th}
          name={headers[FIRST_NAME].key as StudentKeys}
          onClick={sortHandler}
        >
          <span className={css.header_cell}>Name</span>
          <div
            className={cn({
              [css.low_opacity]: sortBy !== headers[FIRST_NAME].key,
              [css.prominent]: sortBy === headers[FIRST_NAME].key,
              [css.sort_icon]: true,
            })}
          >
            {sortIcon}
          </div>
        </button>
        <button
          name={headers[SKILLS].key as StudentKeys}
          type="button"
          className={css.th}
          onClick={sortHandler}
        >
          <span className={css.header_cell}>Skills Learned</span>
          <div
            className={cn({
              [css.low_opacity]: sortBy !== headers[SKILLS].key,
              [css.prominent]: sortBy === headers[SKILLS].key,

              [css.sort_icon]: true,
            })}
          >
            {sortIcon}
          </div>
        </button>
        <button
          name={headers[SONGS_PRACTICED].key as StudentKeys}
          type="button"
          className={css.th}
          onClick={sortHandler}
        >
          <span className={css.header_cell}>Songs Learned</span>
          <div
            className={cn({
              [css.sort_icon]: true,
              [css.low_opacity]: sortBy !== headers[SONGS_PRACTICED].key,
              [css.prominent]: sortBy === headers[SONGS_PRACTICED].key,
            })}
          >
            {sortIcon}
          </div>
        </button>
        <button
          name={headers[PRACTICE_TIME].key as StudentKeys}
          type="button"
          className={css.th}
          onClick={sortHandler}
        >
          <span className={css.header_cell}>Practice Time</span>
          <div
            className={cn({
              [css.sort_icon]: true,
              [css.prominent]: sortBy === headers[PRACTICE_TIME].key,
              [css.low_opacity]: sortBy !== headers[PRACTICE_TIME].key,
            })}
          >
            {sortIcon}
          </div>
        </button>
        <div className={css.th}>
          <span className={css.header_cell}>Status</span>
        </div>
      </div>

      {courseStats.map((studentStat) => {
        return (
          <StudentRow
            key={studentStat.email}
            // I guess we will always find a student with the email in the course stats
            student={
              students.find(({ email }) => email === studentStat.email) ||
              ({} as IStudentWithSkills)
            }
            studentCSVData={studentStat}
          />
        );
      })}
    </div>
  ) : (
    <div className={css.mobile_container}>
      {students.map((student) => (
        <StudentRowMobile key={student.email} student={student} />
      ))}
    </div>
  );
};
