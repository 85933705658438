import { useCallback, useEffect, useState } from "react";

export const useLoadSongAfterGettingDuration = (audio: HTMLAudioElement) => {
  const [duration, setDuration] = useState<number | undefined>(undefined);

  const getDuration = useCallback(() => {
    audio.currentTime = 0;
    audio.removeEventListener("timeupdate", getDuration);
    setDuration(audio.duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      //  TODO: resolve this discrepancy:
      //       audio.duration only exists here for mp3 files, not for legacy file types
      //           duration is set in getDuration for legacy file types
      //           mp3 files never trigger getDuration, why?
      //
      setDuration(audio.duration);
      if (audio.duration === Infinity) {
        audio.currentTime = 1e101;
        audio.addEventListener("timeupdate", getDuration);
      }
    };

    audio.load();
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("timeupdate", getDuration);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  return {
    duration: duration === Infinity ? audio.duration : duration,
  };
};
