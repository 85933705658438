import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  acceptSongSharingInvitation,
  setUsingMobileAppFlag,
} from "redux/songwriting/songwriting_slice";
import { Box } from "@material-ui/core";
import queryString from "query-string";
import { ExistingSongs } from "./existing_songs/existing_songs";
import css from "./songwriting.module.scss";
import { StartNewSong } from "./start_new_song/start_new_song";

export const SongWriting = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { url_token } = queryString.parse(window.location.hash);
    const invitationToken = url_token ? String(url_token) : null;

    if (invitationToken) {
      dispatch(acceptSongSharingInvitation({ invitationToken, history }));
    }
    localStorage.removeItem("redirect_url");
    localStorage.removeItem("url_token");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

    dispatch(setUsingMobileAppFlag(!!mobile));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={css.wrapper}>
      <StartNewSong />
      <ExistingSongs />
    </Box>
  );
};
