import { createSelector } from "reselect";
import { IState } from "redux/store";
import { getCustomSongs } from "redux/songwriting/songwriting_selectors";
import { getPurchasedForFreeLessonsSelectors } from "../entities/lessons/lesson_selectors";

export const getFreeSongsCountSelector = createSelector(
  [
    (state: IState) => state.freeUserLessons.maxFreeLessonsCount,
    getPurchasedForFreeLessonsSelectors,
    getCustomSongs,
  ],
  (maxFreeLessons, purchasedForFreeLessons, customSongs) => {
    return maxFreeLessons - purchasedForFreeLessons.length - customSongs.length;
  },
);

export const areFreeCreditsAvailableSelector = createSelector(
  [getFreeSongsCountSelector],
  (freeSongsCount) => {
    return freeSongsCount > 0;
  },
);

export const maxFreeLessonsCountFetchingSelector = createSelector(
  [(state: IState) => state.freeUserLessons.fetching],
  (fetching) => fetching,
);
