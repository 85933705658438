import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { getFirstOrganization } from "redux/organizations/organization_selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "./StyledLoader";
import css from "../../audioplayer/audio.module.scss"
// import { AlphaTabApi } from "@coderline/alphatab";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AudioContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlphaText = styled.div`
  width: 100%;
  height: ${(props) => (props.sheetLoaded ? "initial" : "0px")};
  visibility: ${(props) => (props.sheetLoaded ? "visible" : "hidden")};
`;

const StyledLoader = styled(Loader)`
  margin: 130px 0;
`;

const Icon = styled(FontAwesomeIcon)`
  /* margin-top: 14px; */
  font-size: 32px;
  padding: 8px;
  cursor: pointer;
`;

const DEFAULT_TEMPO = 120;

const LEFT_SPACE = 54;
const RIGHT_SPACE = 40;
const RECT_WIDTH = 4;
const SPACING = LEFT_SPACE + RECT_WIDTH + RIGHT_SPACE;

const PLAY = "play";
const PAUSE = "pause";

// https://alphatab.net/docs/getting-started/installation-web
export const AlphaTab = ({
  textCode = "",
  tempo = DEFAULT_TEMPO,
  timeSig = "4/4",
  condition = true,
  tuning = "",

}) => {
  const orgInfo = useSelector(getFirstOrganization);

  const [isLooping, setIsLooping] = useState(false);


  const loopIcon = isLooping ? "green" : "gray"
  const texRef = useRef(null);
  const apiRef = useRef(null);
  const destroyRef = useRef(false);
  const firstRender = useRef(true);
  const widthSet = useRef(false);

  let staveProfile = "Default"
  
  const loopAudio = () => {
    setIsLooping(!isLooping)
    if (apiRef.current) {
      apiRef.current.isLooping = !isLooping;
    }
  };

  if (orgInfo) {
    if (orgInfo.tab && !orgInfo.staff) {
      staveProfile = "Tab";
    } else if (!orgInfo.tab && orgInfo.staff) {
      staveProfile = "Score";
    }
  }
    
  const [soundFontLoaded, setSoundFontLoaded] = useState(false);
  const [sheetLoaded, setSheetLoaded] = useState(false);
  const [playIcon, setPlayIcon] = useState(PLAY);

    const tSig = timeSig.split("/").join(" ");

    const setMaxWidth = ()=> {
      const rects = texRef.current.querySelectorAll("rect");
      let leftMostVal = 9999999;
      let rightMostVal = 0;

      for (const rect of Array.from(rects)) {
        const val = rect.x.baseVal.value;
        if (val < leftMostVal) {
          leftMostVal = val;
        }
        if (val > rightMostVal) {
          rightMostVal = val;
        }
      }

      texRef.current.style.setProperty(
        "max-width",
        `${rightMostVal - leftMostVal + SPACING}px`,
      );

      apiRef.current.triggerResize();
      widthSet.current = true;
    }

    useEffect(() => {
      if (condition && !apiRef.current) {
        apiRef.current = new window.alphaTab.AlphaTabApi(texRef.current, {
          core: {
            tex: true,
          },
          display: {
            // staveProfile: "Default",
            staveProfile,
            resources: {
              // staffLineColor: "rgb(200, 10, 110)",
            },
          },
          notation: {
            elements: {
              effectTempo: false,
              guitarTuning: false,
              effectDynamics: false,
            },
          },
          player: {
            scrollMode: "off",
            enablePlayer: true,
            enableUserInteraction: false,
            enableCursor: true,
            soundFont: `${process.env.PUBLIC_URL}/sound_fonts/guitar_nylon.sf2`,
          },
        });

        apiRef.current.soundFontLoaded.on(() => {
          setSoundFontLoaded(true);
        });


        apiRef.current.renderFinished.on(() => { });

        apiRef.current.postRenderFinished.on(() => {
          if (firstRender.current) {
            firstRender.current = false;
            setMaxWidth();
          } else if (widthSet.current) {
            widthSet.current = false;
            setSheetLoaded(true);
          }
        });

        apiRef.current.resize.on((_) => { });

        apiRef.current.playerStateChanged.on((e) => {
          const playerStarted = e.state === 1;
          if (playerStarted) {
            setPlayIcon(PAUSE);
          } else if (destroyRef.current) {
            apiRef.current.destroy();
          } else {
            setPlayIcon(PLAY);
          }
        });
      }
      return (() => {

        apiRef.current && apiRef.current.stop()
      })
    }, [condition, staveProfile, textCode]);

    useEffect(() => {
      return () => {
        if (apiRef.current) {
          const playerPaused = apiRef.current.playerState === 0;
          if (playerPaused) {
            apiRef.current.destroy();
          } else {
            // need to do this hack because the audio glitches out if you
            // try to stop and destroy alphaTab at the same time.
            destroyRef.current = true;
            apiRef.current.stop();
          }
        }
      };
    }, [textCode]);

    return (
      <Container>
        <AudioContainer>
          <Icon
            size="lg"
            title="Toggle Play"
            onClick={() => {
              apiRef.current.playPause();
            }}
            disabled={!soundFontLoaded}
            icon={playIcon}
          />
          <FontAwesomeIcon
            size="lg"
            color={loopIcon}
            className={css.icon}
            onClick={loopAudio}
            icon="redo-alt"
          />
        </AudioContainer>
 
        <StyledLoader isLoading={!sheetLoaded} />
        <AlphaText sheetLoaded={sheetLoaded} ref={texRef}>
          \tempo {tempo}
          {tuning && `\\tuning ${tuning}`}. \ts {tSig} {textCode}
        </AlphaText>
      </Container>
    );
  }

