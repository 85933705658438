import axios, { AxiosError } from "axios";
import { getNodeApiBaseUrl } from "api/base_api";
import { toast } from "react-toastify";

export interface IUserEmail {
  id: number;
  user_id?: number;
  email: string;
}

export const userEmailApi = {
  async addEmail(userId: number, email: string) {
    const url = `${getNodeApiBaseUrl()}/user-email/${userId}`;
    try {
      await axios.post(url, {
        email,
      });
      toast.success("Email added, skills saved.");
    } catch (e) {
      toast.error("Email wasn't added");
      throw e;
    }
  },

  async fetchEmails(userId: number) {
    const url = `${getNodeApiBaseUrl()}/user-email/${userId}`;
    try {
      const result = await axios.get<{ emails: IUserEmail[] }>(url);
      return result;
    } catch (e) {
      toast.error(
        (e as AxiosError).response?.data?.message || "Email fetch error",
      );
      throw e;
    }
  },

  async updatePrimaryEmail(userId: number, email: string) {
    const url = `${getNodeApiBaseUrl()}/user-email/${userId}/primary`;
    try {
      const result = await axios.post(url, {
        email,
      });
      return result.data;
    } catch (e) {
      toast.error("Email fetch error");
      throw e;
    }
  },
};
