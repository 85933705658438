import { SharedSongList } from "../shared_song_list/shared_song_list";
import { OwnedSongList } from "../owned_song_list/owned_song_list";

interface ITabSongContent {
  tabValue: number;
}

export const TabSongContent = ({ tabValue }: ITabSongContent) => {
  return tabValue === 0 ? <OwnedSongList /> : <SharedSongList />;
};
