import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import { MIN_NUMBER_OF_LESSONS } from "components/my_journey/user_skills";
import { useSelector } from "react-redux";
import { useGetStrummingsQuery } from "redux/skills/skills_service";
import { getCurrentInstrument } from "redux/entities/user_skills/user_skills_selector";
import { capitalize } from "lodash";
import { TopSkillInfo } from "../top_skill_info/top_skill_info";
import { ProgressSection } from "../progress_section/progress_section";
import css from "../skill_view.module.scss";

interface IStrummingView {
  songs: IUserStrummingLessons;
  progress: number;
  skills: number;
}

export const StrummingView = (props: IStrummingView) => {
  const { songs, progress, skills } = props;

  const instrument = useSelector(getCurrentInstrument);

  const { data: strummings = [] } = useGetStrummingsQuery({
    instrument: capitalize(instrument),
  });

  const skillsList = [
    {
      name: "FOLK",
      learnedCount: songs.folk.length,
      skill: strummings.find((strumming) => strumming.name === "Folk"),
    },
    {
      name: "BACK BEAT",
      learnedCount: songs.backbeat.length,
      skill: strummings.find((strumming) => strumming.name === "Backbeat"),
    },
    {
      name: "DRIVING FOLK",
      learnedCount: songs["driving folk"].length,
      skill: strummings.find((strumming) => strumming.name === "Driving Folk"),
    },
    {
      name: "3/4 FOLK",
      learnedCount: songs["3/4 folk"].length,
      skill: strummings.find((strumming) => strumming.name === "34folk"),
    },
    {
      name: "3/4 BACK BEAT",
      learnedCount: songs["3/4 backbeat"].length,
      skill: strummings.find((strumming) => strumming.name === "34backbeat"),
    },
  ];

  return (
    <div className={css.skill_container}>
      <TopSkillInfo
        skillName="Strumming"
        skillsNumber={skills}
        progress={progress}
        skillsMinNumber={MIN_NUMBER_OF_LESSONS}
      />
      <ProgressSection
        skillsList={skillsList}
        lessonsNumber={MIN_NUMBER_OF_LESSONS}
      />
    </div>
  );
};
