// TODO: Verify that this is no longer needed and remove it

import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { useDropdown } from "hooks/use_dropdown";
import styled from "@emotion/styled";
import { IInstruments } from "redux/entities/user_skills/user_skills_slice";
import { TEXT_QUESTION } from "../line_dropdown/dropdown_texts";
import { ProgressLine } from "../progress_line/progress_line";
import css from "./you_vs_best_player.module.scss";

export const YouVsBestPlayer = () => {
  const [isOpen, setOpen] = useState(0);
  const dropDown = useRef<HTMLButtonElement>(null);
  const [instrument, setInstrument] = useState<IInstruments>("guitar");
  const handleClick = useDropdown(isOpen === 1, setOpen, 1, dropDown);

  return (
    <div className={css.you_vs_best_player}>
      <div className={css.top_text}>
        <div>
          {/* You<span className={css.vs}> VS </span>Best Guitar Player Ever */}
          My Journey
        </div>
        <button
          type="button"
          onClick={handleClick}
          onMouseEnter={handleClick}
          onMouseLeave={() => setOpen(0)}
          ref={dropDown}
          className={cn(css.dropdown_wrapper)}
        >
          <FontAwesomeIcon
            icon="question-circle"
            className={css.question_icon}
          />
        </button>
        {isOpen ? (
          <div className={cn(css.dropdown)}>{TEXT_QUESTION}</div>
        ) : null}
      </div>
      <Instruments>
        <InstrumentGuitar
          instrument={instrument}
          onClick={() => setInstrument("guitar")}
        >
          Guitar
        </InstrumentGuitar>
        <InstrumentUkulele
          instrument={instrument}
          onClick={() => setInstrument("ukulele")}
        >
          Ukulele
        </InstrumentUkulele>
      </Instruments>
      <ProgressLine instrument={instrument} />
    </div>
  );
};

const Instruments = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin: 2.5em 5% 4em;
  font-weight: 600;
  color: #818181;
`;

const Instrument = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const InstrumentGuitar = styled(Instrument)<{ instrument: IInstruments }>`
  color: ${(props) => props.instrument === "guitar" && "#6775DE"};
  text-decoration: ${(props) => props.instrument === "guitar" && "underline"};
`;
const InstrumentUkulele = styled(Instrument)<{ instrument: IInstruments }>`
  color: ${(props) => props.instrument === "ukulele" && "#6775DE"};
  text-decoration: ${(props) => props.instrument === "ukulele" && "underline"};
`;
