import styled from "@emotion/styled";
import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";
import { CheckoutForm } from "./checkout_form";
import logoImg from "./logo.png";
import studentsImg from "./students.png";
import { studentProducts } from "./products";
import { IStripeProduct } from "./types";

export const StripeCheckout = () => {
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setChecked(checked);
  };
  const product: IStripeProduct = checked
    ? studentProducts.year
    : studentProducts.month;

  return (
    <Container>
      <InfoContainer>
        <img alt="logo" src={logoImg} />
        <H1>Student Discount Member Plan</H1>
        <Description>
          Continue your Moosiko membership with special pricing for students
          completing a school music program (compared to regular plans priced at
          $15/mo or $110/yr). Special student pricing available until 8/1/2021.
        </Description>
        <SwitchText>
          <span>Yearly (save 30%)</span>
          <Switch checked={checked} onChange={handleSwitchChange} />
          Switch
        </SwitchText>
        <PricesContainer>
          <PriceCurrency>$</PriceCurrency>
          <Price>{product.price}</Price>
          <PriceDuration>/{product.postfix}</PriceDuration>
        </PricesContainer>
        <Cancel> Cancel Anytime</Cancel>
        <Img alt="Happy guitar stydents" src={studentsImg} />
        <ul>
          <Li>Access to all song lessons</Li>
          <Li>New recommendations</Li>
          <Li>Personalized song suggestions</Li>
          <Li>Video recording</Li>
          <Li>Request new songs</Li>
          <Li>My journey progress</Li>
        </ul>
      </InfoContainer>
      <CheckoutForm product={product} />
    </Container>
  );
};

const Container = styled.article`
  display: flex;
  z-index: 200;
  position: absolute;
  width: 100%;
  top: 0;
  min-height: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const InfoContainer = styled.section`
  width: 50%;
  background: #6775de;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  min-height: 100%;
  z-index: 200;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const H1 = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  max-width: 540px;
  text-align: center;
`;

const SwitchText = styled.section`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const PricesContainer = styled.section`
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

const Price = styled.strong`
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
`;

const PriceCurrency = styled.div`
  align-self: top;
`;

const PriceDuration = styled.div`
  align-self: bottom;
`;

const Cancel = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`;

const Li = styled.li`
  font-size: 18px;
  line-height: 30px;
  list-style: disc;
`;

const Img = styled.img`
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  max-width: 476px;
`;
