import axios from "axios";

import { getNodeApiBaseUrl } from "./base_api";

export interface IUserSession {
  day: string; // "2021-06-06";
  minutes: number;
  user_id: number;
}

export const userTimeApi = {
  async getTime(userId: number, startDate: Date, endDate: Date) {
    const url = `${getNodeApiBaseUrl()}/user-session/${userId}`;
    const result = await axios.get<IUserSession[]>(url, {
      params: {
        startDate,
        endDate,
      },
    });
    return result;
  },

  addTodayTime(userId: number, day: Date, minutes: number) {
    const url = `${getNodeApiBaseUrl()}/user-session/${userId}`;
    return axios.post(url, {
      month: day.getMonth(),
      date: day.getDate(),
      year: day.getFullYear(),
      minutes,
    });
  },
};
