import styled from "@emotion/styled";
import { withStyles } from "@material-ui/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

export const StyledCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#190067",
    },
    "&$disabled": {
      color: "#19006766",
    },
  },
  disabled: {},
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const Container = styled.div`
  color: #818181;
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
`;

export const Cell = styled.div<{ lastCell?: boolean }>`
  border-bottom: 1px solid #cccccc;
  padding: 1.8em 0 1.4em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${(props) => (props.lastCell ? "2em" : "0")};
`;

export const Header = styled(Cell)`
  font-size: 1.2em;
  padding: 4em 0 2.5em;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: bold;
`;

export const Row = styled(Cell)`
  font-weight: bold;
  justify-content: start;
  span {
    font-weight: normal;
  }
`;

export const ColumnName = styled(Cell)<{ lastCell?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-right: ${(props) => (props.lastCell ? "2em" : "0")};
`;
