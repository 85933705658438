import { createSelector } from "reselect";
import { IState } from "redux/store";

const getSlidesStateSelector = (state: IState) => state.entities.slides;

export const getSlides = createSelector(
  getSlidesStateSelector,
  (slides) => slides.slides,
);

export const getSliderInfo = createSelector(
  getSlidesStateSelector,
  (slides) => slides.slider,
);

export const getSlideNumber = createSelector(
  getSlidesStateSelector,
  (slides) => slides.slider.slideNumber,
);

export const getSongId = createSelector(
  getSlidesStateSelector,
  (slides) => slides.slider.lessonId,
);
