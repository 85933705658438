import React, { useState } from "react";
import { ROLES } from "redux/auth/roles/roles_consts";
import {
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import FlareIcon from "@material-ui/icons/Flare";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "redux/ui/modal/modal_slice";
import { setShowSelectSongOverlay } from "redux/songwriting_mobile/songwriting_mobile_slice";
import {
  getCurrentSeed,
  getSeedText,
  getSeedTitle,
  getSeedType,
} from "redux/song_seeds/song_seed_selector";
import { getAuthSelector } from "redux/auth/user_selectors";
import { useCreateNewSong } from "hooks/use_create_new_song";
import {
  setCurrentSeed,
  updateSongSeed,
} from "redux/song_seeds/song_seeds_slice";
import { bedrockAiApi } from "api/bedrock_ai_api";
import mixpanel from "mixpanel-browser";
import EditableTitle from "./editable_title/editable_title";
import EditableSeedNote from "./editable_seed_note/editable_seed_note";
import SelectSongOverlay from "../select_song_overlay/select_song_overlay";
import { StyledMenu } from "../styled_menu/styled_menu";
import AiExpandSeedNote from "./ai_expand_note/ai_expand_note";

export interface IAiResultSong {
  title: string;
  description: string;
}

export interface IAiResults {
  Songs: IAiResultSong[];
}

const SeedNote = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addMenuActive, setAddMenuActive] = useState<boolean>(false);
  const [deleteMenuActive, setDeleteMenuActive] = useState<boolean>(false);
  const menuVerticalOffset = -75;
  const currentSeed = useSelector(getCurrentSeed);
  const currentSeedType = useSelector(getSeedType(currentSeed));
  const currentSeedTitle = useSelector(getSeedTitle(currentSeed));
  const currentSeedText = useSelector(getSeedText(currentSeed));

  const [drawerState, setDrawerState] = useState(false);

  // TODO: Consolidate all draw styles into a single file, you can find the exact code below elsewhere in the codebase
  const useStyles = makeStyles({
    paper: {
      background: "#F8F9FA",
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
      height: "80vh",
    },
  });
  const classes = useStyles();

  const [aiResults, setAiResults] = useState<IAiResults | null>(null);

  const { role } = useSelector(getAuthSelector);

  const freeUserPlan = [
    ROLES.FREE_USER,
    ROLES.FREE_TEACHER_STUDENT,
    ROLES.FREE_TEACHER_ACCOUNT,
    ROLES.FREE_SONGWRITING,
  ].includes(role as any);

  const { createSongHandler } = useCreateNewSong();

  const closeNoteWindow = () => {
    dispatch(setCurrentSeed({ id: null }));
    dispatch(closeModal());
  };

  const handleClickLinkSongMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setAddMenuActive(true);
  };

  const handleCloseLinkSongMenu = () => {
    setAnchorEl(null);
    setAddMenuActive(false);
  };

  const linkToExistingSong = () => {
    handleCloseLinkSongMenu();
    dispatch(setShowSelectSongOverlay(true));
  };

  const invokeAi = async () => {
    mixpanel.track("Song Idea genAI Button");

    let aiResponse;

    const aiQuestionString = `Give me three original song ideas based on the title '${currentSeedTitle}'
        Incorporating themes from these notes: '${currentSeedText}'.
        Each idea should have a title and a brief description.
        Each idea and description should be significantly different from the others.
        In the description, focus on how the listener should feel and mention an appropriate genre for that idea.
        It is ok to mention the notes in the description but expand upon them in relevant ways when appropriate.
        Return the results in JSON using this format:
        {"Songs": [{"title": songTitle, "description": songDescription}...]}.
        The reply must use the JSON format provided and MUST use the keys provided exactly as they are written.
        Reply with only the answer in JSON form and include no other commentary.
        Never return the value as a string.`;

    do {
      // eslint-disable-next-line no-await-in-loop
      aiResponse = await bedrockAiApi.invokeAi(aiQuestionString);

      if (typeof aiResponse === "string") {
        try {
          aiResponse = JSON.parse(aiResponse);
        } catch (error) {
          console.warn("Invalid JSON format - regenerating:", aiResponse);
        }
      }
    } while (typeof aiResponse !== "object");

    setAiResults(aiResponse);
  };

  const linkToNewSong = () => {
    handleCloseLinkSongMenu();
    createSongHandler();
  };

  const handleClickDeleteSeed = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setDeleteMenuActive(true);
  };

  const handleCloseDeleteSeedMenu = () => {
    setAnchorEl(null);
    setDeleteMenuActive(false);
  };

  const deleteSeed = () => {
    if (freeUserPlan) {
      dispatch(openModal({ type: "payForSubscription" }));
    } else {
      dispatch(updateSongSeed({ status: "Deleted" }));
      closeNoteWindow();
    }
  };

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={closeNoteWindow}
          style={{ cursor: "pointer" }}
        >
          <IconButton aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#6b6868",
              fontFamily: "Raleway",
            }}
          >
            {`${currentSeedType === "song" ? "Song" : "Lyric"} Ideas`}
          </Typography>
        </Box>

        <Box>
          <IconButton
            aria-label="Expand Your Idea with AI"
            onClick={() => {
              setDrawerState(true);
              invokeAi();
            }}
          >
            <FlareIcon />
          </IconButton>
          <IconButton
            aria-label={`delete ${
              currentSeedType === "song" ? "Song" : "Lyric"
            } Ideas`}
            onClick={handleClickDeleteSeed}
          >
            <DeleteOutlineIcon />
          </IconButton>
          <IconButton
            aria-label="link to song"
            onClick={handleClickLinkSongMenu}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" style={{ gap: 5 }} marginX={2}>
        {currentSeedType === "song" && <EditableTitle />}
        <EditableSeedNote />
      </Box>
      <SelectSongOverlay />
      <StyledMenu
        id="linkSongMenu"
        anchorEl={anchorEl}
        keepMounted
        open={addMenuActive}
        onClose={handleCloseLinkSongMenu}
        vertical={menuVerticalOffset}
      >
        <MenuItem onClick={linkToExistingSong}>
          <Box display="flex" style={{ gap: "10px" }}>
            <MergeTypeIcon style={{ transform: "rotate(90deg)" }} />{" "}
            <Typography>Add to Existing Song</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={linkToNewSong}>
          <Box display="flex" style={{ gap: "10px" }}>
            <ControlPointIcon />
            <Typography>Create New Song</Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
      <StyledMenu
        id="linkSongMenu"
        anchorEl={anchorEl}
        keepMounted
        open={deleteMenuActive}
        onClose={handleCloseDeleteSeedMenu}
        vertical={menuVerticalOffset}
      >
        <MenuItem onClick={deleteSeed}>
          <Box display="flex" style={{ gap: "10px" }}>
            <DeleteOutlineIcon />
            <Typography>{`Delete ${
              currentSeedType === "song" ? "Song" : "Lyric"
            } Idea`}</Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
      <Drawer
        anchor="bottom"
        open={drawerState}
        onClose={() => {
          setDrawerState(false);
          setAiResults(null);
        }}
        PaperProps={{ square: false }}
        classes={{ paper: classes.paper }}
      >
        <AiExpandSeedNote aiResults={aiResults} />
      </Drawer>
    </Box>
  );
};

export default SeedNote;
