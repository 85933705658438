// Client ID and API key from the Developer Console
export const CLIENT_ID =
  "274429121108-o5oqhluvj5fuds6v247qvep20e3lmjli.apps.googleusercontent.com";

export const API_KEY = "AIzaSyCx_31_SxtUTV0lFRGBIQuFTjNfP2BGH3s";
export const API_KEY_PF = "1jdfWaTyNCef/FyUd0VWfA==luf37veruvyFsvpg";

export const DISCOVERY_DOC = "https://classroom.googleapis.com/$discovery/rest";

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const SCOPES = "https://www.googleapis.com/auth/userinfo.profile";
export const SENSITIVE_SCOPES =
  "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.profile.emails";
