import axios from "axios";
import { toast } from "react-toastify";
import { IRolePermissions } from "../redux/auth/auth_slice";
import { IRoles } from "../redux/auth/roles/roles_consts";
import { getNodeApiBaseUrl } from "./base_api";

export const userApi = {
  async updateName(id: number, fname: string, lname: string, email: string) {
    try {
      toast.success("Info updated");
      const url = `${getNodeApiBaseUrl()}/user/${id}`;
      const result = await axios.post(url, { fname, lname, email });
      return result;
    } catch (e) {
      toast.error("Info wasn't updated");
    }
  },

  fetchPermissions: () => {
    const url = `${getNodeApiBaseUrl()}/user/permissions`;
    return axios.get<IRolePermissions>(url);
  },

  fetchPermissionsByRole: (role_id: number) => {
    const url = `${getNodeApiBaseUrl()}/user/emulated-permissions/${role_id}`;
    return axios.get<IRolePermissions>(url, { params: { role_id } });
  },

  resendConfirmation: (email: string) => {
    const url = "api/resend-confirmation";
    return axios.post(url, { email });
  },

  updateUserRole: ({
    email,
    roleName,
  }: {
    email?: string;
    roleName: IRoles;
  }) => {
    const url = `${getNodeApiBaseUrl()}/user/role`;
    return axios.patch<IRolePermissions>(url, { email, roleName });
  },

  deleteUserProfile: ({ user_id }: { user_id: number }) => {
    const url = `${getNodeApiBaseUrl()}/user/delete/${user_id}`;
    return axios.delete(url);
  },

  fetchUserFromEmail: (email: string) => {
    const url = `${getNodeApiBaseUrl()}/user_public/user_from_email/${email}`;
    const result = axios.get(url);
    return result;
  },
};
