import React, { useState, useEffect, ChangeEvent } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { formSelector } from "redux/ui/form/form_selectors";
import { clearErrors } from "redux/errors/session/session_errors_actions";
import { resetPW, setPasswordTeacherSignUp } from "redux/auth/auth_slice";
import { resetPWMessage } from "redux/ui/form/form_slice";
import { getErrors } from "redux/errors/errors_selectors";

import css from "../modal.module.scss";

export const FORM_RESET_PASSWORD = "form_reset_password";
interface IResetPasswordForm {
  isTeacherSignUp?: boolean;
}

export const ResetPasswordForm = ({ isTeacherSignUp }: IResetPasswordForm) => {
  const [passwordData, setPasswordData] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const dispatch = useDispatch();
  const errors = useSelector(getErrors).session.resetPW;
  const confirm = useSelector(formSelector).resetPW;

  useEffect(() => {
    return () => {
      if (errors) {
        dispatch(clearErrors());
      }
      if (confirm) {
        dispatch(resetPWMessage(null));
      }
    };
  }, [dispatch, confirm, errors]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { password, passwordConfirmation } = passwordData;

    if (isTeacherSignUp) {
      dispatch(setPasswordTeacherSignUp({ password, passwordConfirmation }));
    } else {
      dispatch(resetPW({ password, passwordConfirmation }));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const pwError = errors?.password && `Password ${errors.password}`;
  const pwConfErr =
    errors?.password_confirmation &&
    `Password confirmation ${errors.password_confirmation}`;

  return (
    <form
      style={{ paddingBottom: "30px" }}
      onSubmit={handleSubmit}
      className={css.form}
      id={FORM_RESET_PASSWORD}
    >
      <input
        type="text"
        name="email"
        defaultValue={axios.defaults.headers.common.uid}
        autoComplete="username email"
        style={{ display: "none" }}
      />
      <input
        onChange={handleChange}
        className={`${css.input}  ${pwError ? css.input_error : null}`}
        value={passwordData.password}
        id="password"
        type="password"
        placeholder="New Password"
        autoComplete="new-password"
      />
      <div className={pwError ? `${css.error} fadeIn animated2` : undefined}>
        {pwError}
      </div>
      <input
        onChange={handleChange}
        className={`${css.input}  ${pwConfErr ? css.input_error : null}`}
        value={passwordData.passwordConfirmation}
        id="passwordConfirmation"
        type="password"
        placeholder="Confirm Password"
        autoComplete="new-password"
      />
      <div className={pwConfErr ? `${css.error} fadeIn animated2` : undefined}>
        {pwConfErr}
      </div>
    </form>
  );
};
