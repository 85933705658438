import { Video } from "components/video/video";
import { simpleDecrypt } from "helpers/encryption";
import { useGetVideoWithAccess } from "hooks/use_get_video";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mediaRecordingsApi } from "redux/media_recordings/media_recordings_api";
import css from "./video_page.module.scss";

export const VideoPage = () => {
  const { id } = useParams<{ id: string }>();

  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const encryptedId = simpleDecrypt(id);
    mediaRecordingsApi.fetchMediaRecordById(encryptedId).then((response) => {
      const {
        data: { mediaRecord },
      } = response;
      setUrl(
        mediaRecord?.video?.encoded_video_url || mediaRecord?.encoded_video,
      );
    });
  }, [id]);

  const videoSrc = useGetVideoWithAccess(url);

  return (
    <div className={css.container}>
      <div className={css.video_wrapper}>
        <Video src={videoSrc} />
      </div>
    </div>
  );
};
