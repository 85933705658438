/* eslint-disable no-unused-vars */
export const BASIC_CHORD_SKILL_TYPE = "Basic Chords";
export const BARRE_CHORD_SKILL_TYPE = "Barre Chords";
export const SKILL_TYPES = [
  BASIC_CHORD_SKILL_TYPE,
  "Strumming",
  BARRE_CHORD_SKILL_TYPE,
  "Melody",
  "Scales",
];

export const INSTRUMENTS = ["Guitar", "Ukulele"];

export enum SpecificCategories {
  RecommendedForYou = "Recommended for You",
  MostPopular = "Most Popular",
  RecentlyAdded = "Recently Added",
}
