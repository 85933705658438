import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { getCourses } from "redux/courses/courses_selectors";
import classNames from "classnames";
import { IState } from "redux/store";
import css from "./select_class.module.scss";

interface ISelectClass {
  // getRecordsFunction will return either IMediaRecord[] or IOrgSongs[]
  getRecordsFunction: (_: IState) => any[];
  setClassFunction: (_: { active: boolean; filteredRecords: any[] }) => void;
  dropSelectionsFunction: (_: IState) => void;
}

export const SelectClass: React.FC<ISelectClass> = ({
  getRecordsFunction,
  setClassFunction,
  dropSelectionsFunction,
}) => {
  const dispatch = useDispatch();
  const records = useSelector(getRecordsFunction);

  const [selectedCourse, setSelectedCourse] = useState<null | number>(null);
  const courses = useSelector(getCourses);
  const active = !!selectedCourse;

  const shouldDropSelection = useSelector(dropSelectionsFunction);
  useEffect(() => {
    setSelectedCourse(null);
  }, [shouldDropSelection]);

  useEffect(() => {
    dispatch(
      setClassFunction({
        active,
        filteredRecords: active
          ? records.filter((record) => {
              return (
                record.user.courses.length &&
                record.user.courses[0].id === selectedCourse
              );
            })
          : [],
      }),
    );
  }, [active, dispatch, records, selectedCourse, setClassFunction]);

  const [selectSkillTypePopover, setSelectSkillTypePopover] =
    useState<HTMLButtonElement | null>(null);
  const handleOpenSelectSkillType = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSelectSkillTypePopover(event.currentTarget);
  };
  const handleCloseSelectSkillType = () => {
    setSelectSkillTypePopover(null);
  };

  const clickHandle = (courseId: number) => {
    if (selectedCourse === courseId) {
      setSelectedCourse(null);
    } else {
      setSelectedCourse(courseId);
    }
  };
  return (
    <>
      <button
        onClick={handleOpenSelectSkillType}
        className={classNames(css.sort_button, {
          [css.sort_button_active]: active,
        })}
        type="button"
      >
        Class
      </button>
      <Popover
        anchorEl={selectSkillTypePopover}
        keepMounted
        open={Boolean(selectSkillTypePopover)}
        onClose={handleCloseSelectSkillType}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.filtering_container}>
          {courses.map((course) => {
            const value = course.id === selectedCourse;
            return (
              <div key={course.id} className={css.checkbox_container}>
                <Checkbox
                  value={value}
                  checked={value}
                  className={css.checkbox}
                  onClick={() => clickHandle(course.id)}
                />
                <div>{course.name}</div>
              </div>
            );
          })}
        </div>
      </Popover>
    </>
  );
};
