import { IState } from "redux/store";

export const getClassroomCourses = (state: IState) =>
  state.classroomImport.courses;

export const getClassroomStudents = (state: IState) =>
  state.classroomImport.students;

export const getClassroomFetchingData = (state: IState) =>
  state.classroomImport.isFetchingData;
