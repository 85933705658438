import axios from "axios";
import { getNodeApiBaseUrl } from "api/base_api";

export interface IUserLesson {
  id: number;
  progress: number;
  lesson_id: number;
  user_id: number;
  subscription: boolean;
  free: boolean;
  updated_at: string;
}

export const userLessonApi = {
  fetchUserLessons: () => {
    const url = `${getNodeApiBaseUrl()}/lesson/usersLessons`;

    return axios.get<{
      lessons: IUserLesson[];
    }>(url);
  },

  createUserLesson: ({
    userId,
    lessonId,
  }: {
    userId: number;
    lessonId: number;
  }) => {
    return axios.post<IUserLesson>("/api/user_lessons", {
      user_id: userId,
      lesson_id: lessonId,
    });
  },
};
