import { combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import { mediaRecordingsReducer } from "redux/media_recordings/media_recordings_slice";
import { feedbackReducer } from "redux/feedback/feedback_slice";
import { assessmentsReducer } from "redux/assessments/assessments_slice";
import { carouselReducer } from "redux/carousel/carousel_slice";
import { userCoursesReducer } from "redux/user_courses/user_courses_slice";
import { songFilterReducer } from "redux/songs_filter/song_filter_slice";
import { freeUserLessonsReducer } from "redux/free_user_lessons/free_user_lessons_slice";
import { songwritingAssessmentsReducer } from "./songwriting_assessments/songwriting_assessments_slice";
import { authReducer } from "./auth/auth_slice";
import { entitiesReducer } from "./entities/entities_reducer";
import errorsReducer from "./errors/errors_reducer";
import uiReducer from "./ui/ui_reducer";
import { signUpStepsReducer } from "./sign_up_steps/sign_up_steps_slice";
import { myJourneyReducer } from "./entities/my_journey/my_journey_slice";
import { stepListReducer } from "./step_list/stepListSlice";
import { skillsApi } from "./skills/skills_service";
import { classroomImportReducer } from "./import_students/google_classroom/google_classroom_students_slice";
import { msTeamsImportReducer } from "./import_students/microsoft_teams/microsoft_teams_students_slice";
import { songwritingReducer } from "./songwriting/songwriting_slice";
import { songSeedReducer } from "./song_seeds/song_seeds_slice";
import { songwritingMobileReducer } from "./songwriting_mobile/songwriting_mobile_slice";

export const createReducer = (history: History) =>
  combineReducers({
    [skillsApi.reducerPath]: skillsApi.reducer,
    auth: authReducer,
    userCourses: userCoursesReducer,
    router: connectRouter(history),
    signUpSteps: signUpStepsReducer,
    entities: entitiesReducer,
    errors: errorsReducer,
    ui: uiReducer,
    freeUserLessons: freeUserLessonsReducer,
    myJourney: myJourneyReducer,
    mediaRecordings: mediaRecordingsReducer,
    feedback: feedbackReducer,
    assessments: assessmentsReducer,
    songwritingAssessments: songwritingAssessmentsReducer,
    carousel: carouselReducer,
    songFilter: songFilterReducer,
    steps: stepListReducer,
    classroomImport: classroomImportReducer,
    msTeamsImport: msTeamsImportReducer,
    songwriting: songwritingReducer,
    songSeeds: songSeedReducer,
    songwritingMobile: songwritingMobileReducer,
  });
