const MONTHLY_MEMBER_PRICE = "3.99";
const YEARLY_MEMBER_PRICE = "38.99";

export const storeKitProducts = {
  month: {
    id: "",
    price: "",
    postfix: "month",
  },
  year: {
    id: "",
    price: "",
    postfix: "year",
  },
  songwritingMonth: {
    id: "SW3.99mo",
    price: MONTHLY_MEMBER_PRICE,
    postfix: "month",
  },
  songwritingYear: {
    id: "SW38.99year",
    price: YEARLY_MEMBER_PRICE,
    postfix: "year",
  },
};
