import React from "react";

interface IUseFindActiveCellIndex {
  cellRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
}

export const useFindActiveCellIndex = ({
  cellRefs,
}: IUseFindActiveCellIndex) => {
  const findActiveIndex = () =>
    document.activeElement
      ? cellRefs.current.findIndex(
          (element) => element === document.activeElement,
        )
      : null;
  return { findActiveIndex };
};
