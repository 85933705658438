import React from "react";
import cn from "classnames";
import { CSS } from "@dnd-kit/utilities";
import { EnumDragAndDropActions } from "redux/songwriting/songwriting_interfaces";
import { useDraggable } from "@dnd-kit/core";

import css from "../droppable_cell/droppable_cell.module.scss";

interface IDraggableChord {
  icon: React.ReactNode;
  title: string;
  row: string;
  column: string;
  songUnitId: number;
}

export const DraggableChord = ({
  title,
  icon,
  row,
  column,
  songUnitId,
}: IDraggableChord) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable/-${songUnitId}/-${title}/-${row}/-${column}`,
    data: {
      typeDragAndDropAction: EnumDragAndDropActions.replaceChordInStructure,
      title,
      structureId: songUnitId,
      rowId: row,
      columnId: column,
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: 1,
  };

  return (
    <div
      className={cn(css.cell, css.chord)}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {icon}
    </div>
  );
};
