import { useDispatch, useSelector } from "react-redux";

import {
  updateTipNote,
  updateCustomSong,
} from "redux/songwriting/songwriting_slice";
import {
  getCustomSongTipNote,
  getCustomSongId,
  getUsingMobileAppFlag,
} from "redux/songwriting/songwriting_selectors";

import { TextareaAutosize } from "@material-ui/core";

export const NotesAndIdeas = () => {
  const dispatch = useDispatch();
  const mobile = useSelector(getUsingMobileAppFlag);
  const songId = useSelector(getCustomSongId);
  let tipNote = useSelector(getCustomSongTipNote(songId));
  const textBoxViewHeight = mobile ? "100%" : "120px";
  const textBoxViewWidth = mobile ? "95%" : "90%";

  if (!tipNote) {
    tipNote = " ";
  }

  const onChangeTipNote = (tipNoteValue: string) => {
    dispatch(updateTipNote({ tipNoteValue, songId }));
  };

  const onBlurTipNote = (tipNoteValue: string) => {
    dispatch(updateCustomSong({ note: tipNoteValue }));
  };

  return (
    <TextareaAutosize
      style={{
        width: `${textBoxViewWidth}`,
        backgroundColor: "#fff",
        padding: "10px",
        resize: "vertical",
        height: `${textBoxViewHeight}`,
      }}
      placeholder="Add a note..."
      value={tipNote}
      onChange={(e) => onChangeTipNote(e.target.value)}
      onBlur={(e) => onBlurTipNote(e.target.value)}
      autoFocus
      onFocus={(e) => {
        e.target.selectionEnd = e.target.value.length;
        e.target.selectionStart = e.target.value.length;
      }}
    />
  );
};
