import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { applyPromoRole } from "redux/auth/auth_slice";
import { promoApi } from "api/promo_api";
import { UPGRADE } from "routes/route_constants";
import { ROLES } from "redux/auth/roles/roles_consts";
import { setStripeProduct } from "redux/entities/stripe/stripe_slice";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { products } from "redux/entities/stripe/stripe_consts";
import { getStripeProduct } from "redux/entities/stripe/stripe_selectors";
import { StretchContainer, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { SignUpButton } from "../../reusable/button_sign_up";
import { CloseStepsButton } from "../../reusable/close_steps_button";
import { stepsId } from "../../newSignUp";
import {
  StyledHeader,
  StyledLogoWithBackground,
  StyledContainer,
  StyledButton,
  Content,
  CardContainer,
  Card,
  CardHeader,
  HowPay,
  Dollar,
  Description,
} from "./choose_payment.styled";
import choosePay from "../../images/choose-pay-logo.svg";

export const ChoosePayment = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const totalPrice = useSelector(getStripeProduct).price;
  const [promoRole, setPromoRole] = useState<number>(0);

  const handleChooseHowPay = (stepId: number) => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId,
      }),
    );
  };

  const userRole = useSelector(getRoleSelector);

  (async () => {
    const { promo } = queryString.parse(window.location.search);
    const promoData = await promoApi.fetchRole(promo);
    promoData && dispatch(applyPromoRole(promoData.data.roles_id));
    promoData && setPromoRole(promoData.data.roles_id);
  })();

  useEffect(() => {
    if (
      userRole === ROLES.FREE_TEACHER_ACCOUNT &&
      location.pathname !== UPGRADE
    ) {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.signUpStepList,
          stepId: stepsId.createClass,
        }),
      );
    } else if (
      (userRole === ROLES.PAID_TEACHER_ACCOUNT ||
        userRole === ROLES.SOLO_TEACHER) &&
      promoRole
    ) {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.signUpStepList,
          stepId: stepsId.createClass,
        }),
      );
    } else if (userRole === ROLES.SOLO_TEACHER) {
      dispatch(
        setStripeProduct({
          id: products[userRole].id,
          price: products[userRole].price,
          postfix: products[userRole].postfix,
        }),
      );
    }
  }, [dispatch, userRole, location.pathname, promoRole]);

  const cards = [
    {
      name: "Credit or Debit Card",
      description:
        "Pay online with credit card, we will send receipt for reimburesment.",
      buttonText: "Credit or Debit",
      handleClick: () => handleChooseHowPay(stepsId.payByCard),
    },
    {
      name: "Purchase Order",
      description: "We will issue quote, School/district will issue check.",
      buttonText: "Purchase Order",
      handleClick: () => handleChooseHowPay(stepsId.getQuote),
    },
  ];
  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={54} />
        <CloseStepsButton />
      </Top>
      <StyledContainer>
        <Content>
          <StyledHeader>How Would You Like To Pay?</StyledHeader>
          <StyledLogoWithBackground
            src={choosePay}
            alt="defaultAvatar-plan-logo"
          />

          <HowPay>
            Total:&nbsp;<Dollar>$</Dollar>&nbsp;<span>{totalPrice}</span>
          </HowPay>
        </Content>

        <CardContainer>
          {cards.map((card) => (
            <Card key={card.name}>
              <CardHeader>{card.name}</CardHeader>
              <span>{card.description}</span>
              <StyledButton>
                <SignUpButton onClick={card.handleClick}>
                  {card.buttonText}
                </SignUpButton>
              </StyledButton>
            </Card>
          ))}
        </CardContainer>
      </StyledContainer>
      <Description>
        Need Help? Email{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:help@moosiko.com"
        >
          help@moosiko.com
        </a>{" "}
        or book a time directly on our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://meetings.hubspot.com/dan613"
        >
          calendar
        </a>
      </Description>
    </StretchContainer>
  );
};
