import { useDispatch, useSelector } from "react-redux";
import {
  changeViewedAtStatus,
  clearCurrentSong,
  setIsDraggedChord,
  clearTableData,
  fetchCustomSongs,
} from "redux/songwriting/songwriting_slice";
import { EnumDragAndDropActions } from "redux/songwriting/songwriting_interfaces";
import { closeModal } from "redux/ui/modal/modal_slice";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";
import {
  getReplacedSongUnit,
  getUsingMobileAppFlag,
  getTypeOfDragAndDropAction,
} from "redux/songwriting/songwriting_selectors";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { Box } from "@material-ui/core";
import { SongBuilderHeader } from "./songbuilder_header/songbuilder_header";
import { SongBuilderMainSection } from "./songbuilder_main_section/songbuilder_main_section";
import { useHandleOnDragEnd, useHandleOnDragStart } from "../../../hooks";
import { SongBuilderMobileFooter } from "./songbuilder_mobile_footer/songbuilder_mobile_footer";
import { SongBuilderMobileSidebar } from "./songbuilder_mobile_sidebar/songbuilder_mobile_sidebar";
import { SongBuilderMobileHeader } from "./songbuilder_mobile_header/songbuilder_mobile_header";
import { SongbuilderChordDeleteFooter } from "./songbuilder_header/songbuilder_chord_delete_footer";

export const SongBuilder = () => {
  const mobile = useSelector(getUsingMobileAppFlag);

  const dispatch = useDispatch();

  const typeOfDragAndDropAction = useSelector(getTypeOfDragAndDropAction);
  const replacedSongUnit = useSelector(getReplacedSongUnit);
  const userId = useSelector(getUserIdSelector);

  const handleReturn = () => {
    setTimeout(() => {
      userId &&
        dispatch(
          changeViewedAtStatus({ user_id: userId, viewed_at: new Date() }),
        );
      dispatch(fetchCustomSongs());
      dispatch(closeModal());
      dispatch(clearCurrentSong());
      dispatch(setIsDraggedChord(false));
      dispatch(clearTableData());
    }, 0);
  };
  const { handleDragStart } = useHandleOnDragStart();
  const { handleDragEnd } = useHandleOnDragEnd();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <Box>
      {mobile ? (
        <SongBuilderMobileHeader returnHandler={handleReturn} />
      ) : (
        <SongBuilderHeader returnHandler={handleReturn} />
      )}
      <Box position="relative" zIndex={0}>
        <DndContext
          sensors={replacedSongUnit ? undefined : sensors}
          //  This allows a song structure to be added just by clicking on it
          collisionDetection={
            typeOfDragAndDropAction === EnumDragAndDropActions.createStructure
              ? closestCenter
              : undefined
          }
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          autoScroll={false}
        >
          <SongBuilderMainSection />
          {!mobile && <SongbuilderChordDeleteFooter />}
        </DndContext>
      </Box>
      {mobile && <SongBuilderMobileFooter />}
      {mobile && <SongBuilderMobileSidebar />}
    </Box>
  );
};
