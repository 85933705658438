import { Box, Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAudioComponentExpandedId } from "redux/ui/components/components_slice";
import { getAudioSaveInProgressFlag } from "redux/ui/components/components_selectors";
import useRecordingsList from "hooks/use_recording_list";
import { RecordItem } from "./record_item/record_item";
import { RecordItemSkeleton } from "./record_item_skeleton/record_item_skeleton";

export type IRecord = {
  id: number;
  title: string;
  length: number;
  createdAt: Date | string;
};

export const RecordsList = () => {
  const { recordings } = useRecordingsList();
  const dispatch = useDispatch();

  const audioSaveInProgress = useSelector(getAudioSaveInProgressFlag);

  useEffect(() => {
    return () => {
      dispatch(setAudioComponentExpandedId(null));
    };
  }, [recordings, dispatch]);

  return (
    <Box width="100%">
      {audioSaveInProgress && (
        <>
          <RecordItemSkeleton />
          <Divider />
        </>
      )}
      {recordings.length > 0 ? (
        recordings.map((record, index) => (
          <React.Fragment key={record.key}>
            <RecordItem id={index} record={record} />
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography
            style={{ color: "rgba(95, 99, 103, 1)", fontWeight: "500" }}
          >
            No Audio Recordings
          </Typography>
        </Box>
      )}
    </Box>
  );
};
