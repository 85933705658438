import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getMemberPlanDescriptionItems,
  getSongwritingPlanDescriptionItems,
} from "redux/general_selectors/subscription_plan_selectors";
import { getStripeProduct } from "redux/entities/stripe/stripe_selectors";
import { setStripeProduct } from "redux/entities/stripe/stripe_slice";
import { getStoreKitProduct } from "redux/entities/store_kit/store_kit_selectors";
import { setStoreKitProduct } from "redux/entities/store_kit/store_kit_slice";
import { openPayForSubscriptionModal } from "redux/ui/modal/modal_slice";
import { getAuthSelector } from "redux/auth/user_selectors";
import { SubscriptionPlan } from "./subscription_plan";
import { MemberPlanPrice } from "./member_plan_price";
import css from "./subscription_plan.module.scss";

interface IMemberPlan {
  products: {
    month: {
      id: string;
      price: string;
      postfix: string;
    };
    year: {
      id: string;
      price: string;
      postfix: string;
    };
    songwritingMonth: {
      id: string;
      price: string;
      postfix: string;
    };
    songwritingYear: {
      id: string;
      price: string;
      postfix: string;
    };
  };
  rewriteOnSubmit?: () => void;
  showSubmitBtn?: boolean;
  subscriptionEnd?: string | null;
}

export const MemberPlan = (props: IMemberPlan) => {
  const { products, rewriteOnSubmit, ...otherProps } = props;

  const dispatch = useDispatch();

  const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");
  const memberItems = useSelector(getMemberPlanDescriptionItems);
  const songwritingItems = useSelector(getSongwritingPlanDescriptionItems);
  const [checked, setChecked] = useState(true);

  const { role } = useSelector(getAuthSelector);
  const songwriting = role === "FREE_SONGWRITING";

  const handleSwitchChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setChecked(checked);
  };

  const stripeProduct = useSelector(getStripeProduct);
  const storeKitProduct = useSelector(getStoreKitProduct);

  useEffect(() => {
    const month = songwriting ? products.songwritingMonth : products.month;
    const year = songwriting ? products.songwritingYear : products.year;
    const newProduct = checked ? month : year;
    if (iOSApp) {
      dispatch(setStoreKitProduct(newProduct));
    } else {
      dispatch(setStripeProduct(newProduct));
    }
  }, [checked, dispatch, products, iOSApp, songwriting]);

  const onSubmit = useCallback(() => {
    dispatch(openPayForSubscriptionModal());
  }, [dispatch]);

  return (
    <SubscriptionPlan
      product={iOSApp ? storeKitProduct : stripeProduct}
      checked={checked}
      handleSwitchChange={handleSwitchChange}
      title={songwriting ? "Upgrade to Premium Member" : "MEMBER PLAN"}
      priceBlock={
        <MemberPlanPrice product={iOSApp ? storeKitProduct : stripeProduct} />
      }
      listItems={songwriting ? songwritingItems : memberItems}
      submitBtnText="Choose Member"
      classes={{ submit_btn: css.member_plan_submit_btn }}
      onSubmit={rewriteOnSubmit || onSubmit}
      {...otherProps}
    />
  );
};
