import { getNodeApiBaseUrl } from "api/base_api";
import axios from "axios";
import { Subset } from "types/redux_";
import { ISongSeed, ICreateSongSeed } from "./song_seed_interfaces";

export const songSeedApi = {
  createSongSeed(data: ICreateSongSeed) {
    const url = `${getNodeApiBaseUrl()}/song-seeds/`;
    return axios.post(url, data);
  },

  fetchUsersSongSeeds(userId: number) {
    const url = `${getNodeApiBaseUrl()}/song-seeds/user-seeds`;
    return axios.get<ISongSeed[]>(url, {
      params: { user_id: userId },
    });
  },

  updateSongSeed(songSeedId: number, data: Subset<ISongSeed>) {
    const url = `${getNodeApiBaseUrl()}/song-seeds/song-seed`;
    return axios.patch<ISongSeed>(url, data, { params: { id: songSeedId } });
  },
};
