/* eslint-disable react/no-multi-comp */
import { Typography } from "@material-ui/core";
import cn from "classnames";

interface IResultsSection {
  title: string;
  words: string[];
  css: {
    readonly [key: string]: string;
  };
}

interface INoResultsSection extends Omit<IResultsSection, "words"> {}
let keyId = 0;

export const ResultsSection = ({ title, words, css }: IResultsSection) => (
  <div className={css.outer_wrapper}>
    <Typography className={css.word}>{title}</Typography>
    <div className={css.word_grid}>
      {words.map((word) => (
        <Typography key={`${word}-${keyId++}`} className={css.word}>
          {word}
        </Typography>
      ))}
    </div>
  </div>
);

export const NoResultsSection = ({ title, css }: INoResultsSection) => (
  <div className={cn(css.outer_wrapper, css.flex_center)}>
    <Typography className={css.word}>{title}</Typography>
  </div>
);
