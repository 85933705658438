import axios from "axios";

import { getNodeApiBaseUrl } from "./base_api";

interface IFetchLesson {
  limit?: number;
  start: number;
  type?: string;
  instrument?: string;
  skillName?: string;
  skillId?: number;
}

export interface ILessonWithUnknownSkills {
  id: number;
  unknownChords: string[];
  instrument: string;
  unknownTransitions: string[];
  unknownProgressions: string[];
}

export const recommendationApi = {
  fetchRecommendedLessons(options: IFetchLesson) {
    const url = `${getNodeApiBaseUrl()}/recommendation`;
    return axios.get<{
      lessons: ILessonWithUnknownSkills[];
      lessonsCount: number;
    }>(url, {
      params: options,
    });
  },
};
