/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useState } from "react";

export type dialogEvent = React.MouseEvent | undefined | {};
type eventHandler = (event?: dialogEvent) => void;

interface IUseDialog {
  onOpen?: Function;
  onClose?: Function;
  startState?: boolean;
}

type IUseDialogReturn = [boolean, eventHandler, eventHandler];

/**
 * Used to handle dialogs
 * @returns [status, closeDialog(), openDialog()]
 */
export const UseDialog = ({
  onOpen,
  onClose,
  startState,
}: IUseDialog = {}): IUseDialogReturn => {
  const [dialog, setDialog] = useState(startState || false);

  const closeDialog = useCallback(
    (event: dialogEvent) => {
      setDialog(false);
      if (onClose && typeof onClose === "function") {
        onClose(event);
      }
    },
    [onClose],
  );

  const openDialog = useCallback(
    (event: dialogEvent) => {
      setDialog(true);

      if (typeof onOpen === "function") {
        onOpen(event);
      }
    },
    [onOpen],
  );

  return [dialog, closeDialog, openDialog];
};
