import { useCallback } from "react";
import { SideBar } from "components/sidebar/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { MediaRecorder } from "components/slides/media-recorder";
import { sendVideoFeedback } from "redux/feedback/feedback_slice";
import { getMediaRecordingsRecords } from "redux/media_recordings/media_recordings_selectors";
import {
  setAssessmentsStudentName,
  setAssessmentsClass,
  setAssessmentsSkillType,
  clearAssessmentsFilter,
} from "redux/assessments/assessments_slice";
import {
  getAssessmentsFilteredRecords,
  getDropAssessmentsSelection,
} from "redux/assessments/assessments_selectors";
import { fetchMediaRecordings } from "redux/media_recordings/media_recordings_slice";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { toast } from "react-toastify";
import css from "./assessment.module.scss";
import { AssessmentRow } from "./assessment_row";
import { AssessmentTableHeader } from "./assessment_row/table_header";
import img from "./images/no_assessments.svg";
import { AssessmentSkeletonRow } from "./assessment_row/assessment_skeleton_row";
import { SelectSkillTypePopover } from "./select_skill_type_popover";
import { SelectClass } from "./select_class";
import { SelectDateRange } from "./select_date_range";
import { ClearSelection } from "./clear_selection";
import { SelectByName } from "./select_by_name";

export const Assessment = () => {
  const { finalRecords: records, isFiltered } = useSelector(
    getAssessmentsFilteredRecords,
  );
  const dispatch = useDispatch();
  const orgId = useSelector(getOrganizationId);

  // useCallback to remove MediaRecorder re-renders
  const handleUpload = useCallback(
    async (file) => {
      if (orgId) {
        await dispatch(sendVideoFeedback({ file }));
        dispatch(fetchMediaRecordings({ orgId }));
      } else {
        toast.error("Organization not found");
      }
    },
    [dispatch, orgId],
  );

  return (
    <div className={css.container}>
      <MediaRecorder fileName="feedback" handleUpload={handleUpload} />
      <SideBar />
      <div className={css.organization_container}>
        <h2 className={css.h2}>Assessment</h2>
        {records.length || isFiltered ? (
          <>
            <div className={css.sort_buttons}>
              <SelectByName
                getRecordsFunction={getMediaRecordingsRecords}
                setNameFunction={setAssessmentsStudentName}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <SelectDateRange
                getRecordsFunction={getMediaRecordingsRecords}
                setDateFunction={setAssessmentsStudentName}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <SelectSkillTypePopover
                getRecordsFunction={getMediaRecordingsRecords}
                setSkillFunction={setAssessmentsSkillType}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <SelectClass
                getRecordsFunction={getMediaRecordingsRecords}
                setClassFunction={setAssessmentsClass}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <ClearSelection clearFunction={clearAssessmentsFilter} />
            </div>
            <hr className={css.line} />
            <div className={css.table}>
              <AssessmentTableHeader />
              {records.map((record) => (
                <AssessmentRow key={record.id} record={record} />
              ))}
            </div>
          </>
        ) : (
          <div className={css.no_assessments_container}>
            <img
              src={img}
              alt="no assessments"
              className={css.no_assessments_img}
            />
            <div className={css.no_assessments_text}>
              Your Students Recordings Will Appear Here
            </div>
            <AssessmentTableHeader />
            <AssessmentSkeletonRow />
            <AssessmentSkeletonRow />
          </div>
        )}
      </div>
    </div>
  );
};
