/* eslint-disable no-unused-vars */
import { msalInstance } from "components/sign_up/reusable/SSHLogIn";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMsTeamsFetchingData,
  getMsTeamsGroups,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_selectors";
import {
  listMSTeamsGroups,
  setMsAccessToken,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_slice";
import { ExternalResource } from "../add_from_external_resources";
import { CourseList } from "../course_list/course_list";
import { CourseStudentsList } from "../course_students_table/course_students_table";

export enum MicrosoftTeamsSteps {
  COURSE_LIST = 1,
  STUDENTS_LIST = 2,
}

export const AddFromMicrosoftTeams = () => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<MicrosoftTeamsSteps>(
    MicrosoftTeamsSteps.COURSE_LIST,
  );

  useEffect(() => {
    (async () => {
      const login = await msalInstance.loginPopup({
        scopes: [
          "TeamSettings.Read.All",
          "Group.Read.All",
          "TeamMember.Read.All",
        ],
      });
      dispatch(setMsAccessToken(login.accessToken));
      dispatch(listMSTeamsGroups({}));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentStep === MicrosoftTeamsSteps.COURSE_LIST ? (
    <CourseList
      type={ExternalResource.MS_TEAMS}
      setCurrentStep={setCurrentStep}
    />
  ) : (
    <CourseStudentsList
      type={ExternalResource.MS_TEAMS}
      setCurrentStep={setCurrentStep}
    />
  );
};
