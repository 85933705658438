import axios from "axios";
import { IStudentWithSkills } from "types/models/student_with_skills";
import { ICourseDateFilter } from "./courses_slice";
import { getNodeApiBaseUrl } from "../../api/base_api";

type ICourseUpdateParams = {
  name?: string;
  guitar_songs?: boolean;
  ukulele_songs?: boolean;
  explicit?: boolean;
};

export interface ICourseUsers {
  id: number;
}

export interface ICreateCourse {
  id: number;
  name: string;
  guitar_songs: boolean;
  ukulele_songs: boolean;
  explicit: boolean;
  organization_id: number;
  start_date: string;
  end_date: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface ICourse extends ICreateCourse {
  users: ICourseUsers[];
  summarizedData?: ISummarizedStudentData;
  studentsWithSkills: IStudentWithSkills[];
}

export interface ISummarizedStudentData {
  studentsCount: number;
  studentsPracticeTime: number;
  studentsSkills: number;
  studentsSongsLearned: number;
}

export interface ICourseStats {
  email?: string;
  first_name?: string;
  last_name?: string;
  lessons_number?: number;
  practice_time_mins?: number;
  skills_number?: number;
}

// todo: migrate from passing dateRange as Moment objects to plain ISO 8601 strings

export const coursesApi = {
  fetchOrganizationCourses(orgId: number) {
    const url = `${getNodeApiBaseUrl()}/courses/`;
    return axios.get<{ courses: ICourse[] }>(url, { params: { orgId } });
  },
  fetchCourseStudents(courseId: number, dateRange?: ICourseDateFilter) {
    const url = `${getNodeApiBaseUrl()}/courses/${courseId}/users`;
    return axios.get<{ usersWithSkills: IStudentWithSkills[] }>(url, {
      params: { dateRange },
    });
  },
  fetchSummarizedStudentsData(courseId: number, dateRange: ICourseDateFilter) {
    const url = `${getNodeApiBaseUrl()}/courses/${courseId}/summarized-students-data`;
    return axios.get<{ summarizedStudentsData: ISummarizedStudentData }>(url, {
      params: { dateRange },
    });
  },

  fetchCourseStats(
    courseId: number,
    { startDate, endDate }: ICourseDateFilter,
    sortBy: string,
    sortOrder: string,
  ) {
    const url = `${getNodeApiBaseUrl()}/courses/${courseId}/stats`;

    return axios.get<{ courseStats: ICourseStats[] }>(url, {
      params: {
        startDate,
        endDate,
        sortBy,
        sortOrder,
      },
    });
  },

  createCourse(orgId: number, name: string, startDate: Date, endDate: Date) {
    const url = `${getNodeApiBaseUrl()}/courses/`;
    return axios.post<{ course: ICreateCourse }>(url, {
      orgId,
      name,
      startDate,
      endDate,
    });
  },
  deleteCourse(courseId: number) {
    const url = `${getNodeApiBaseUrl()}/courses/${courseId}`;
    return axios.delete(url);
  },
  updateCourse(courseId: number, params: ICourseUpdateParams) {
    const url = `${getNodeApiBaseUrl()}/courses/${courseId}`;
    return axios.post(url, params);
  },
  resetPassword(studentId: number, password: string) {
    const url = `api/students/${studentId}/reset-password`;
    return axios.post(url, { password });
  },
  resendInvitation(studentId: number, orgId: number, instructorId: number) {
    const url = `api/students/${studentId}/resend-invitation`;
    return axios.post(url, { orgId, instructorId });
  },
  moveStudent(courseId: number, userId: number, newCourseId: number) {
    const url = `${getNodeApiBaseUrl()}/courses/${courseId}/users/${userId}/move`;
    return axios.post(url, { newCourseId });
  },
};
