import { Box, Button, Typography } from "@material-ui/core";
import { useSongUnitTableContent } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  EnumDragAndDropActions,
  ICustomSongLyrics,
} from "redux/songwriting/songwriting_interfaces";
import {
  getActualTableDataById,
  getCurrentCustomSong,
  getCurrentCustomSongUnit,
} from "redux/songwriting/songwriting_selectors";
import {
  deleteCustomSongUnit,
  updateCustomSong,
} from "redux/songwriting/songwriting_slice";

interface ISongUnitMobileMenu {
  songUnitId: number;
}

export const SongUnitMobileMenu = ({ songUnitId }: ISongUnitMobileMenu) => {
  // TODO: Consolidate this and code in song_unit_duplicte_icon.tsx

  const dispatch = useDispatch();
  const { collectAndSaveAllUnits, getPreparedSongLyricsData } =
    useSongUnitTableContent();
  const currentSong = useSelector(getCurrentCustomSong);
  const currentSongLyrics = useSelector(
    getCurrentCustomSongUnit(songUnitId as number),
  );
  const actualSongLyrics = useSelector(getActualTableDataById(songUnitId));
  const preparedActualSongLyrics = getPreparedSongLyricsData(
    actualSongLyrics || [],
  );

  const currentSong_Structure = currentSongLyrics?.song_structure;
  const currentSong_Chords = currentSongLyrics?.song_chords;

  const copyHandler = () => {
    const newSongUnit: ICustomSongLyrics = {
      song_chords: currentSong_Chords !== undefined ? currentSong_Chords : "",
      song_lyrics:
        preparedActualSongLyrics !== undefined ? preparedActualSongLyrics : "",
      song_structure:
        currentSong_Structure !== undefined
          ? `${currentSong_Structure} copy`
          : "",
      position: currentSong.custom_song_lyrics.length,
    };

    dispatch(
      updateCustomSong({
        custom_song_lyrics: [
          {
            ...newSongUnit,
            type: EnumDragAndDropActions.createStructure,
          },
        ],
      }),
    );
    collectAndSaveAllUnits();
  };

  const deleteHandler = () => {
    dispatch(deleteCustomSongUnit({ unit_id: songUnitId }));
  };

  return (
    <Box
      position="absolute"
      height="57px"
      borderRadius="16px"
      style={{ backgroundColor: "#2C2C2C", color: "#fff", gap: "5px" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="0 10px"
      top="40px"
      zIndex="9"
    >
      <Button onMouseDown={deleteHandler}>
        <Typography
          style={{
            textTransform: "none",
            fontFamily: "Raleway",
            color: "#fff",
          }}
        >
          {`Delete ${currentSong_Structure}`}
        </Typography>
      </Button>
      <Box borderRight="1px solid #fff" height="100%" />
      <Button onMouseDown={copyHandler}>
        <Typography
          style={{
            textTransform: "none",
            fontFamily: "Raleway",
            color: "#fff",
          }}
        >
          Duplicate
        </Typography>
      </Button>
    </Box>
  );
};
