import styled from "@emotion/styled";

export const ErrorContainer = styled.div`
  position: relative;
  align-self: stretch;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBytton = styled.button`
  color: #76b3f3;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;

export const Description = styled.div`
  align-self: center;
  max-width: 700px;
  white-space: break-spaces;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  > span {
    font-weight: 700;
  }
`;
