import format from "date-fns/format";
import {
  Document,
  Image,
  Page,
  Text,
  View,
  Svg,
  Line,
  Rect,
} from "@react-pdf/renderer";
import SongbuilderPdf from "../songbuilder_pdf/songbuilder_pdf";
import CertBlueRibbon from "./protecton_ribbon_image";
import moosikoFooterImage from "../songbuilder_pdf/moosiko_footer_image";
import { certificate_styles } from "../songbuilder_pdf/songbuilder_pdf_styles";

const dateDisplay = (dateToFormat: String) => {
  return format(new Date(dateToFormat.replace(" ", "T")), "MMMM do, yyyy");
};

const svgContentDivider = () => {
  return (
    <Svg height="10" width="142">
      <Line
        x1="0"
        y1="5"
        x2="142"
        y2="5"
        strokeWidth={2}
        stroke="rgb(0,0,0)"
        opacity="50%"
      />
    </Svg>
  );
};

interface IProtectionDialogCertificatePdfProps {
  currentSong: any;
  certNumber: string;
  protectedStatus: boolean;
}

const ProtectionDialogCertificatePdf = ({
  currentSong,
  certNumber,
  protectedStatus,
}: IProtectionDialogCertificatePdfProps) => {
  return (
    <Document>
      <Page size="A4" orientation="portrait" style={certificate_styles.page}>
        <View style={certificate_styles.section}>
          <Text style={certificate_styles.certTitle}>
            CERTIFICATE OF SONG OWNERSHIP
          </Text>
          <Text style={certificate_styles.songTitle}>
            {currentSong.song_name}
          </Text>

          <Text style={certificate_styles.songData}>{currentSong.artist}</Text>
          {svgContentDivider()}
          <Text style={certificate_styles.songLabel}>Author</Text>

          <Text style={certificate_styles.songData}>
            {dateDisplay(currentSong.createdAt)}
          </Text>
          {svgContentDivider()}
          <Text style={certificate_styles.songLabel}>Date Created</Text>

          <Text style={certificate_styles.songData}>
            {dateDisplay(currentSong.updatedAt)}
          </Text>
          {svgContentDivider()}
          <Text style={certificate_styles.songLabel}>Last Updated</Text>

          <Text style={certificate_styles.songData}>{certNumber}</Text>
          {svgContentDivider()}
          <Text style={certificate_styles.songLabel}>Certification Number</Text>
        </View>
        <View fixed style={certificate_styles.footer}>
          <Image
            style={certificate_styles.footerImage}
            src={moosikoFooterImage}
          />
        </View>
      </Page>
      <View style={certificate_styles.blueRibbonView}>
        <View>
          <Svg
            style={certificate_styles.blueRibbonBackground}
            width="50"
            height="50"
            viewBox="0 0 50 50"
          >
            <Rect x="0" y="0" width="50" height="4" fill="#ffffff" />
          </Svg>
        </View>
        <Image
          style={certificate_styles.blueRibbonImage}
          src={CertBlueRibbon}
        />
      </View>
      <SongbuilderPdf
        customSong={currentSong}
        protectedStatus={protectedStatus}
      />
    </Document>
  );
};

export default ProtectionDialogCertificatePdf;
