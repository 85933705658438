import classNames from "classnames";
import { ExternalResource } from "components/organization/add_from_external_resources/add_from_external_resources";
import { LogoWithBackground } from "components/sign_up/reusable/background_image";
import { SignUpButton } from "components/sign_up/reusable/button_sign_up";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IInviteStudent } from "redux/auth/auth_api";
import { getCourses } from "redux/courses/courses_selectors";
import { inviteStudents } from "redux/courses/courses_slice";
import {
  getClassroomFetchingData,
  getClassroomStudents,
} from "redux/import_students/google_classroom/google_classroom_students_selectors";
import {
  getMsTeamsFetchingData,
  getMsTeamsParticipants,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_selectors";
import { IClassroomStudent } from "types/helpers/classroomTypes";
import { IMsTeamsParticipant } from "types/helpers/msTeamsTypes";

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import addStudent from "../../../images/add-student-logo.svg";
import { Container, Header } from "../../emotion.styles";
import { StyledButton } from "../bulk_import_from_google_classroom/bulk_import_from_google_classroom.styled";
import css from "./bulk_import_student_list.module.scss";

interface IBulkImportStudentList {
  type: ExternalResource;
}

export const BulkImportStudentList = ({ type }: IBulkImportStudentList) => {
  const history = useHistory();

  const classroomStudents = useSelector(getClassroomStudents);
  const isFetchingClassroomData = useSelector(getClassroomFetchingData);

  const courseId = useSelector(getCourses)[0].id;

  const groupParticipants = useSelector(getMsTeamsParticipants);
  const isFetchingMsTeamsData = useSelector(getMsTeamsFetchingData);

  const dispatch = useDispatch();

  const submitInvitation = async () => {
    const students: IInviteStudent[] = [];
    if (type === ExternalResource.GOOGLE_CLASSROOM) {
      classroomStudents.forEach((elem) => {
        const student: IInviteStudent = {
          firstName: elem.profile.name.givenName,
          lastName: elem.profile.name.familyName,
          email: elem.profile.emailAddress,
        };
        students.push(student);
      });
    }
    if (type === ExternalResource.MS_TEAMS) {
      groupParticipants.forEach((elem) => {
        const student: IInviteStudent = {
          firstName: elem.displayName.split(" ")[0],
          lastName: elem.displayName.split(" ")[1],
          email: elem.email,
        };
        students.push(student);
      });
    }

    await dispatch(inviteStudents({ students, courseId }));

    history.push("/");
  };

  const isFetchingData =
    type === ExternalResource.MS_TEAMS
      ? isFetchingMsTeamsData
      : isFetchingClassroomData;

  const studentList =
    type === ExternalResource.GOOGLE_CLASSROOM
      ? classroomStudents.map((student: IClassroomStudent, index: number) => (
          <TableRow key={student.userId}>
            <TableCell>{index + 1}</TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.profile.name.fullName}
            </TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.profile.emailAddress}
            </TableCell>
          </TableRow>
        ))
      : groupParticipants.map((student: IMsTeamsParticipant, index: number) => (
          <TableRow key={student.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.displayName}
            </TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.email}
            </TableCell>
          </TableRow>
        ));

  return (
    <Container>
      <Header>Course Students:</Header>
      <LogoWithBackground src={addStudent} padding="28px" margin="2em" />
      {!isFetchingData ? (
        <Box className={css.wrapper}>
          <Table className={css.table} stickyHeader>
            <TableHead>
              <TableCell className={css.table_head_cell} />
              <TableCell
                className={classNames(
                  css.table_cell_secondary,
                  css.table_head_cell,
                )}
              >
                Name
              </TableCell>
              <TableCell
                className={classNames(
                  css.table_cell_secondary,
                  css.table_head_cell,
                )}
              >
                Email
              </TableCell>
            </TableHead>
            <TableBody>{!isFetchingData && studentList}</TableBody>
          </Table>
        </Box>
      ) : (
        <CircularProgress />
      )}
      {!isFetchingData && classroomStudents.length < 0 ? (
        <Typography>This course is Empty.</Typography>
      ) : (
        <StyledButton>
          <SignUpButton
            type="button"
            disabled={isFetchingData}
            onClick={submitInvitation}
          >
            Add Students({isFetchingData ? 0 : studentList.length})
          </SignUpButton>
        </StyledButton>
      )}
    </Container>
  );
};
