import { useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import css from "components/my_journey/my_journey_cards/my_journey_cards.module.scss";
import { UserSkills } from "components/my_journey/cards_content/user_skills";
import { CHORD, TRANSITION, PROGRESSION } from "constants/skills_constants";
import {
  ChordsCard,
  isOtherActive,
} from "components/reusable/skill_cards/skills/chords_card";
import { getUserCompletedLessons } from "redux/entities/lessons/lesson_selectors";
import { IUserStrummingInstruments } from "types/helpers/strummingLessonSkills";
import { StrummingSongs } from "components/my_journey/cards_content/strumming_songs";
import { UserSongs } from "components/my_journey/cards_content/user_songs";
import { TransitionsCard } from "components/reusable/skill_cards/skills/transitions_card";
import { ProgressionCard } from "components/reusable/skill_cards/skills/progressions_card";
import { IInstruments } from "redux/entities/user_skills/user_skills_slice";
import {
  Card,
  NO_CARD,
  CHORDS_CARD,
  TRANSITIONS_CARD,
  PROGRESSIONS_CARD,
  SONGS_CARD,
  STRUMMING_GUITAR_CARD,
  STRUMMING_UKULELE_CARD,
} from "../reusable/skill_cards/card";

export interface IUserSkillsShape {
  chords: string[];
  progressions: string[];
  transitions: string[];
  userChords: string[];
  userProgressions: string[];
  userTransitions: string[];
  user_strumming_lessons: IUserStrummingInstruments;
}

interface ICourseCards {
  fname?: string;
  userSkills: IUserSkillsShape;
  instrument: IInstruments;
}

export const CourseCards = ({ fname, instrument, ...props }: ICourseCards) => {
  const {
    userSkills: {
      chords,
      progressions,
      transitions,
      userChords,
      userProgressions,
      userTransitions,
      user_strumming_lessons,
    },
  } = props;
  const userCompletedSongs = useSelector(getUserCompletedLessons);

  const [currentCard, setCurrentCard] = useState(NO_CARD);

  const handleClickOpen = (cardNumber: number) => {
    setCurrentCard(cardNumber);
  };

  const handleClickClose = () => {
    setTimeout(() => setCurrentCard(NO_CARD));
  };

  return (
    <div
      className={cn(css.cards, currentCard === NO_CARD ? "" : css.cards_active)}
    >
      <ChordsCard
        handleClickOpen={handleClickOpen}
        handleClickClose={handleClickClose}
        active={currentCard === CHORDS_CARD}
        isOtherActive={isOtherActive(currentCard, CHORDS_CARD)}
        chordsCount={chords.length}
        userChordsCount={userChords.length}
        profileLinkText=""
      >
        <UserSkills
          userSkills={userChords}
          allSkills={chords}
          topText={`Chords ${fname} know how to play`}
          bottomText={`Chords ${fname} don't know how to play`}
          learnText=""
          skillType={CHORD}
          clickable={false}
        />
      </ChordsCard>
      <TransitionsCard
        handleClickOpen={handleClickOpen}
        handleClickClose={handleClickClose}
        active={currentCard === TRANSITIONS_CARD}
        isOtherActive={isOtherActive(currentCard, TRANSITIONS_CARD)}
        transitionsCount={transitions.length}
        userTransitionsCount={userTransitions.length}
        profileLinkText=""
      >
        <UserSkills
          userSkills={userTransitions}
          allSkills={transitions}
          topText={`Transitions ${fname} know how to play`}
          bottomText={`Transitions ${fname} don't know how to play`}
          learnText=""
          skillType={TRANSITION}
          clickable={false}
        />
      </TransitionsCard>
      <ProgressionCard
        handleClickOpen={handleClickOpen}
        handleClickClose={handleClickClose}
        active={currentCard === PROGRESSIONS_CARD}
        isOtherActive={isOtherActive(currentCard, PROGRESSIONS_CARD)}
        progressionsCount={progressions.length}
        userProgressionsCount={userProgressions.length}
        profileLinkText=""
      >
        <UserSkills
          userSkills={userProgressions}
          allSkills={progressions}
          topText={`Progressions ${fname} know how to play`}
          bottomText={`Progressions ${fname} don't know how to play`}
          learnText=""
          skillType={PROGRESSION}
          clickable={false}
        />
      </ProgressionCard>
      {instrument === "guitar" ? (
        <Card
          measurementValue={user_strumming_lessons.guitar.count}
          text="Strumming"
          active={currentCard === STRUMMING_GUITAR_CARD}
          handleClick={() => handleClickOpen(STRUMMING_GUITAR_CARD)}
          handleClickClose={() => handleClickClose()}
          isOtherActive={isOtherActive(currentCard, STRUMMING_GUITAR_CARD)}
          line={false}
        >
          <StrummingSongs songs={user_strumming_lessons.guitar} />
        </Card>
      ) : (
        <Card
          measurementValue={user_strumming_lessons.ukulele.count}
          text="Strumming"
          active={currentCard === STRUMMING_UKULELE_CARD}
          handleClick={() => handleClickOpen(STRUMMING_UKULELE_CARD)}
          handleClickClose={() => handleClickClose()}
          isOtherActive={isOtherActive(currentCard, STRUMMING_UKULELE_CARD)}
          line={false}
        >
          <StrummingSongs songs={user_strumming_lessons.ukulele} />
        </Card>
      )}
      <Card
        songCard
        measurementValue={userCompletedSongs.length}
        text="Song Lesson Completed"
        active={currentCard === SONGS_CARD}
        handleClick={() => handleClickOpen(SONGS_CARD)}
        handleClickClose={() => handleClickClose()}
        isOtherActive={isOtherActive(currentCard, SONGS_CARD)}
        line={false}
        button={false}
      >
        <UserSongs
          songs={userCompletedSongs}
          topText={`Songs ${fname} know how to play`}
        />
      </Card>
    </div>
  );
};
