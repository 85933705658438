import cn from "classnames";

interface ISpinner {
  skChildClassName?: string;
  skCircleClassName?: string;
}

// http://tobiasahlin.com/spinkit/
export const Spinner = (props: ISpinner) => {
  const { skChildClassName = "", skCircleClassName = "" } = props;
  return (
    <div className={cn(" sk-circle spinner_circle", skCircleClassName)}>
      <div className={cn("sk-circle1 sk-child", skChildClassName)} />
      <div className={cn("sk-circle2 sk-child", skChildClassName)} />
      <div className={cn("sk-circle3 sk-child", skChildClassName)} />
      <div className={cn("sk-circle4 sk-child", skChildClassName)} />
      <div className={cn("sk-circle5 sk-child", skChildClassName)} />
      <div className={cn("sk-circle6 sk-child", skChildClassName)} />
      <div className={cn("sk-circle7 sk-child", skChildClassName)} />
      <div className={cn("sk-circle8 sk-child", skChildClassName)} />
      <div className={cn("sk-circle9 sk-child", skChildClassName)} />
      <div className={cn("sk-circle10 sk-child", skChildClassName)} />
      <div className={cn("sk-circle11 sk-child", skChildClassName)} />
      <div className={cn("sk-circle12 sk-child", skChildClassName)} />
    </div>
  );
};
