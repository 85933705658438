import React, { useEffect, useState } from "react";
import cn from "classnames";
import { SideBar } from "components/sidebar/sidebar";
import SearchIcon from "@material-ui/icons/Search";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { searchOrganizationStudents } from "redux/organizations/organization_slice";
import { getUserSkillsForStudentsTable } from "redux/courses/courses_selectors";
import {
  getOrganizationId,
  getOrganizationSearchStudents,
  getStudentsCount,
  getStudentsNumber,
  getOrganizationMaxSeats,
} from "redux/organizations/organization_selectors";

import { StudentsTable } from "components/course/students_table";
import { fetchUserOrganizations } from "redux/organizations/organization_courses_common_actions";
import Add from "@material-ui/icons/Add";
import { openClassModal } from "redux/ui/modal/modal_slice";
import css from "./organization.module.scss";
import { CourseBlocks } from "./course_blocks/course_blocks";

const useStyles = makeStyles(() => ({
  margin: {
    margin: "4px",
  },
  textField: {
    width: "480px",
    marginLeft: "10px",
  },
  "@media (max-width: 600px)": {
    textField: {
      width: 280,
    },
  },
}));

export const Organization = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userId = useSelector(getUserIdSelector);
  const orgId = useSelector(getOrganizationId);
  const studentsSearch = useSelector(getOrganizationSearchStudents);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (orgId) {
      dispatch(searchOrganizationStudents({ orgId, name: searchValue }));
    }
  }, [dispatch, searchValue, orgId]);

  const studentsWithSkills = useSelector(
    getUserSkillsForStudentsTable(studentsSearch),
  );

  const handleOpenAddClassModal = () => {
    dispatch(openClassModal());
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserOrganizations({ userId }));
    }
  }, [dispatch, userId]);

  const studentsCount = useSelector(getStudentsCount);
  const organizationMaxSeats = useSelector(getOrganizationMaxSeats);
  const studentsNumber = useSelector(getStudentsNumber);
  return (
    <div className={css.container}>
      <SideBar />
      <div className={css.organization_container}>
        <h2 className={css.h2}>Classes</h2>
        <div className={css.wrapper}>
          <div className={css.students_container}>
            <FormControl
              disabled={!studentsCount}
              className={cn(classes.margin, classes.textField)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Search student...
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                onChange={handleSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      disabled={!studentsCount}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={125}
              />
            </FormControl>
            <p className={css.students_seats}>
              {studentsNumber} students of {organizationMaxSeats} seats used.
            </p>
          </div>
          <Button
            startIcon={<Add />}
            classes={{
              root: css.add_class_button_root,
              outlined: css.add_class_button_outlined,
            }}
            className={css.add_class_button}
            variant="outlined"
            onClick={handleOpenAddClassModal}
          >
            Add Class
          </Button>
        </div>

        {searchValue ? (
          <div className={css.table_container}>
            <StudentsTable students={studentsWithSkills} />
          </div>
        ) : (
          <CourseBlocks />
        )}
      </div>
    </div>
  );
};
