import styled from "@emotion/styled";
import { useHistory } from "react-router";
import { CloseButton } from "../reusable/cross_button";

export const CloseStepsButton = () => {
  const history = useHistory();
  const handleClose = () => {
    history.push("/");
  };
  return (
    <ContainerButton onClick={handleClose}>
      <CloseButton />
    </ContainerButton>
  );
};

const ContainerButton = styled.div`
  position: absolute;
  right: 0;
  top: 15px;
`;
