import { ModalContainer } from "./modal_container";

import css from "./modal.module.scss";

export const RunTunerModal = () => {
  return (
    <ModalContainer
      classBG={`${css.background} ${css.tuner_modal}`}
      classMain={`${css.main} ${css.tuner_main} fadeIn animated`}
      noCloseButton
      propagate
    >
      <div className={css.click_tuner}>Press anywhere to start the tuner</div>
    </ModalContainer>
  );
};
