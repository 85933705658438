import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { useSelector } from "react-redux";
import css from "./profile_nav.module.scss";

export const ProfileNav = () => {
  const { pathname } = useLocation();
  const accessStudent = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_ACCESS),
  );

  return (
    <div className={css.container}>
      <h2 className={css.profile_header}>
        {pathname === "/profile" ? "Profile" : "Organization"}
      </h2>
      <nav className={css.nav}>
        {accessStudent ? (
          <Link
            className={cn(
              css.link,
              pathname === "/organization" ? css.link_active : null,
            )}
            to="/organization"
          >
            Students
          </Link>
        ) : null}
        <Link
          className={cn(
            css.link,
            pathname === "/profile" ? css.link_active : null,
          )}
          to="/profile"
        >
          My Profile
        </Link>
      </nav>
    </div>
  );
};
