import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IInviteStudent } from "redux/auth/auth_api";
import { inviteStudents } from "redux/courses/courses_slice";
import {
  getClassroomFetchingData,
  getClassroomStudents,
} from "redux/import_students/google_classroom/google_classroom_students_selectors";
import {
  getMsTeamsFetchingData,
  getMsTeamsParticipants,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_selectors";
import { getClassModalCourseId } from "redux/ui/modal/modal_selectors";
import { closeClassModal } from "redux/ui/modal/modal_slice";
import { IClassroomStudent } from "types/helpers/classroomTypes";
import classNames from "classnames";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { IMsTeamsParticipant } from "types/helpers/msTeamsTypes";
import { ExternalResource } from "../add_from_external_resources";
import { GoogleClassroomSteps } from "../add_from_google_classroom/add_from_google_classroom";
import css from "./course_students.module.scss";

interface ICourseStudentsList {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  type: ExternalResource;
}

export const CourseStudentsList = ({
  type,
  setCurrentStep,
}: ICourseStudentsList) => {
  const classroomStudents = useSelector(getClassroomStudents);
  const isFetchingClassroomData = useSelector(getClassroomFetchingData);

  const groupParticipants = useSelector(getMsTeamsParticipants);
  const isFetchingMsTeamsData = useSelector(getMsTeamsFetchingData);

  const dispatch = useDispatch();
  const courseId = useSelector(getClassModalCourseId);

  const returnHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentStep(GoogleClassroomSteps.COURSE_LIST);
  };
  const submitInvitation = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const students: IInviteStudent[] = [];
    if (type === ExternalResource.GOOGLE_CLASSROOM) {
      classroomStudents.forEach((elem) => {
        const student: IInviteStudent = {
          firstName: elem.profile.name.givenName,
          lastName: elem.profile.name.familyName,
          email: elem.profile.emailAddress,
        };
        students.push(student);
      });
    }
    if (type === ExternalResource.MS_TEAMS) {
      groupParticipants.forEach((elem) => {
        const student: IInviteStudent = {
          firstName: elem.displayName.split(" ")[0],
          lastName: elem.displayName.split(" ")[1],
          email: elem.email,
        };
        students.push(student);
      });
    }

    await dispatch(inviteStudents({ students, courseId }));

    dispatch(closeClassModal());
  };

  const isFetchingData =
    type === ExternalResource.MS_TEAMS
      ? isFetchingMsTeamsData
      : isFetchingClassroomData;

  const studentCount =
    type === ExternalResource.MS_TEAMS
      ? groupParticipants.length
      : classroomStudents.length;

  const studentList =
    type === ExternalResource.GOOGLE_CLASSROOM
      ? classroomStudents.map((student: IClassroomStudent, index: number) => (
          <TableRow key={student.userId}>
            <TableCell>{index + 1}</TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.profile.name.fullName}
            </TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.profile.emailAddress}
            </TableCell>
          </TableRow>
        ))
      : groupParticipants.map((student: IMsTeamsParticipant, index: number) => (
          <TableRow key={student.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.displayName}
            </TableCell>
            <TableCell className={css.table_cell_secondary}>
              {student.email}
            </TableCell>
          </TableRow>
        ));

  return (
    <>
      {isFetchingData ? (
        <Box className={css.loader_wrapper}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={css.wrapper}>
            <Table className={css.table} stickyHeader>
              <TableHead>
                <TableCell className={css.table_head_cell} />
                <TableCell
                  className={classNames(
                    css.table_cell_secondary,
                    css.table_head_cell,
                  )}
                >
                  Name
                </TableCell>
                <TableCell
                  className={classNames(
                    css.table_cell_secondary,
                    css.table_head_cell,
                  )}
                >
                  Email
                </TableCell>
              </TableHead>
              <TableBody>{!isFetchingData && studentList}</TableBody>
            </Table>
          </Box>
          <hr className={css.hr} />
        </>
      )}
      <Box className={css.button_container}>
        <Button
          className={css.button}
          variant="contained"
          disabled={isFetchingData}
          type="button"
          onClick={returnHandler}
        >
          Back
        </Button>
        <Button
          className={css.button}
          variant="contained"
          color="primary"
          disabled={isFetchingData}
          type="button"
          onClick={submitInvitation}
        >
          Add students({isFetchingData ? 0 : studentCount})
        </Button>
      </Box>
    </>
  );
};
