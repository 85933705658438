import { IState } from "redux/store";

export const getMsTeamsAccessToken = (state: IState) =>
  state.msTeamsImport.msAccessToken;

export const getMsTeamsParticipants = (state: IState) =>
  state.msTeamsImport.participants;

export const getMsTeamsFetchingData = (state: IState) =>
  state.msTeamsImport.isFetchingData;

export const getMsTeamsGroups = (state: IState) => state.msTeamsImport.groups;
