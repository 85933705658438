import React, { useEffect } from "react";
import {
  ICustomSongLyrics,
  EnumDragAndDropActions,
} from "redux/songwriting/songwriting_interfaces";
import {
  deleteCustomSongUnit,
  setCollapsedStructures,
  setTableData,
} from "redux/songwriting/songwriting_slice";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDispatch, useSelector } from "react-redux";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import {
  getCurrentCustomSong,
  getUsingMobileAppFlag,
} from "redux/songwriting/songwriting_selectors";
import {
  getUserEmailSelector,
  getUserIdSelector,
} from "redux/auth/user_selectors";
import { SONGBUILDER_DATA } from "../songbuilder_data";
import css from "../song_unit_list/song_unit_list.module.scss";
import { SongLyricsTable } from "../songbuilder_main_section/song_lyrics_table/song_lyrics_table";
import { SongUnitEditableStructureTitle } from "./song_unit_editable_structure_title/song_unit_editable_structure_title";
import { useSongUnitTableContent } from "../../../../hooks";
import DuplicateSongUnitIcon from "./song_unit_duplicate_icon";

interface ISongUnit {
  songUnit: ICustomSongLyrics;
  collapsedStructures: number[];
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SongUnit = ({
  songUnit,
  collapsedStructures,
  setChordAnchorEl,
}: ISongUnit) => {
  const dispatch = useDispatch();
  const mobile = useSelector(getUsingMobileAppFlag);
  const SEPARATOR = "/n";
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${songUnit.song_structure}_${songUnit.id as number} `,
    data: {
      structureId: songUnit.id as number,
      typeDragAndDropAction: EnumDragAndDropActions.replaceStructure,
      songUnit,
      overId: songUnit.id as number,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "2000" : "auto",
  };

  const collapsed = collapsedStructures?.find((item) => item === songUnit.id);

  const currentSong = useSelector(getCurrentCustomSong);
  const userId = useSelector(getUserIdSelector);
  const userEmail = useSelector(getUserEmailSelector);

  const deleteHandler = () => {
    if (currentSong.user_id !== userId && userEmail) {
      songUnit.id &&
        dispatch(deleteCustomSongUnit({ unit_id: songUnit.id, userEmail }));
    }
    songUnit.id && dispatch(deleteCustomSongUnit({ unit_id: songUnit.id }));
  };

  useEffect(() => {
    const data = songUnit.song_lyrics
      ? songUnit.song_lyrics.split(SEPARATOR).flatMap((item) => [
          {
            ...SONGBUILDER_DATA.droppableRowData,
          },
          { c0: item },
        ])
      : [
          {
            ...SONGBUILDER_DATA.droppableRowData,
          },
          {
            c0: "",
          },
        ];
    const { song_chords, song_lyrics, song_structure, id } = songUnit;
    if (id) {
      dispatch(
        setTableData({
          data,
          id,
          songChords: song_chords,
          songLyrics: song_lyrics,
          title: song_structure,
        }),
      );
    }
  }, [
    dispatch,
    songUnit,
    songUnit.id,
    songUnit.song_chords,
    songUnit.song_lyrics,
    songUnit.song_structure,
  ]);

  const { collectChanges } = useSongUnitTableContent({
    id: songUnit.id || 0,
  });

  const handleCollapsed = () => {
    if (songUnit.id) {
      collectChanges();
      dispatch(
        setCollapsedStructures({
          structureId: songUnit.id,
        }),
      );
    }
  };

  return (
    <div key={`${songUnit.song_structure}`} ref={setNodeRef} style={style}>
      <div
        className={
          mobile ? css.song_structure_title_mobile : css.song_structure_title
        }
      >
        <SongUnitEditableStructureTitle
          structure={songUnit.song_structure}
          id={songUnit.id}
        />
        <div className={mobile ? css.unit_options_mobile : css.unit_options}>
          {React.createElement(
            collapsed ? KeyboardArrowLeft : KeyboardArrowUpIcon,
            {
              onClick: handleCollapsed,
              style: { cursor: "pointer" },
            },
          )}
          <div {...attributes} {...listeners} style={{ cursor: "grab" }}>
            <DragHandleIcon />
          </div>
          <div>
            {songUnit.id && <DuplicateSongUnitIcon songUnitId={songUnit.id} />}
          </div>
          <div>
            <DeleteIcon onClick={deleteHandler} style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>
      {!collapsed && songUnit.id && (
        <SongLyricsTable
          songUnitId={songUnit.id}
          setChordAnchorEl={setChordAnchorEl}
        />
      )}
    </div>
  );
};
