import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SideBar } from "components/sidebar/sidebar";
import { ProfileNav } from "components/reusable/profile_nav/profile_nav";
// import { ProfileCards } from "./profile_cards/profile_cards";
import { ProfileSelectedPlan } from "./profile_selected_plan";
import { AccountInfo } from "./account_info";
import css from "./profile_layout.module.scss";

const useStyles = makeStyles((_theme) => ({
  root: {
    padding: "2em",
    marginBottom: 20,
  },
}));

export const ProfileLayout = () => {
  const classes = useStyles();

  return (
    <div className={css.layout}>
      <ProfileNav />
      <div className={css.layout_flex}>
        <SideBar />
        <div className={css.page_content}>
          <Paper className={classes.root}>
            <AccountInfo />
          </Paper>

          {/*

          temporary disabled

          <Paper className={classes.root}>
            <div className={css.section_title}> Adjust Skills</div>
            <div className={css.section_info}>
              Manually adjust skills here by toggling on and off. New skills are
              automatically added when you complete a song lesson and can be
              viewed in My Journey.
            </div>
            <div className={css.skillset}>
              <ProfileCards />
            </div>
          </Paper> */}
          <Paper className={classes.root}>
            <div className={css.selected_plan}>
              <ProfileSelectedPlan />
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};
