import { useState } from "react";
import * as React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import { useDispatch, useSelector } from "react-redux";
import { openRemoveStudentModal } from "redux/ui/modal/modal_slice";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { resendInvitation } from "redux/courses/courses_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { IStudentWithSkills } from "types/models/student_with_skills";
import css from "./student_actions.module.scss";
import { MoveStudentDialog } from "./move_student_dialog";
import { ResetStudentPasswordDialog } from "./reset_student_password_dialog";

interface IStudentActions {
  student: IStudentWithSkills;
}

export const StudentActions = ({ student }: IStudentActions) => {
  const instructorId = useSelector(getUserIdSelector);
  const orgId = useSelector(getOrganizationId);

  const [moveStudentDialogOpen, setMoveStudentDialogOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const { id, fname: firstName, courseId, lastSignUpAt } = student;
  const dispatch = useDispatch();

  const handleRemoveStudent = () => {
    dispatch(
      openRemoveStudentModal({
        name: firstName,
        studentId: id,
        organizationId: orgId,
        courseId,
      }),
    );
    handleClose();
  };

  const handleResendInvitation = () => {
    if (orgId && instructorId) {
      resendInvitation({ userId: id, orgId, instructorId });
      handleClose();
    } else {
      throw new Error(`${orgId} ${instructorId}`);
    }
  };

  const openResetPasswordDialog = () => {
    setResetPasswordDialogOpen(true);
    handleClose();
  };

  const openMoveStudentDialog = () => {
    setMoveStudentDialogOpen(true);
    handleClose();
  };

  return (
    <div>
      <button
        type="button"
        className={css.student_settings}
        onClick={handleClick}
      >
        <SettingsIcon />
      </button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {lastSignUpAt ? null : (
          <MenuItem onClick={handleResendInvitation}>
            Resend Invitation
          </MenuItem>
        )}

        <MenuItem onClick={openMoveStudentDialog}>Move Student</MenuItem>
        <MoveStudentDialog
          moveStudentDialogOpen={moveStudentDialogOpen}
          setMoveStudentDialogOpen={setMoveStudentDialogOpen}
          student={student}
        />
        <MenuItem onClick={handleRemoveStudent}>Remove Student</MenuItem>
        <MenuItem onClick={openResetPasswordDialog}>Change Password</MenuItem>
        <ResetStudentPasswordDialog
          student={student}
          resetPasswordOpen={resetPasswordOpen}
          setResetPasswordDialogOpen={setResetPasswordDialogOpen}
        />
      </Menu>
    </div>
  );
};
