import format from "date-fns/format";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomSong } from "redux/songwriting/songwriting_slice";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import classNames from "classnames";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { songwritingApi } from "redux/songwriting/songwriting_api";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import unProtectedIcon from "../icons/unprotected.svg";
import protectedIcon from "../icons/protected.svg";
import ProtectionDialogCertificatePdf from "./protection_dialog_certificate_pdf";

import css from "./protection_dialog.module.scss";

interface IProtectionDialog {
  closeAction: () => void;
  protectedStatus: boolean;
}

export const ProtectionDialog = (props: IProtectionDialog) => {
  const dispatch = useDispatch();
  const { closeAction, protectedStatus } = props;
  const currentSong = useSelector(getCurrentCustomSong);
  // @ts-ignore
  const { createdAt, updatedAt, cert_number } = currentSong;

  const dateDisplay = (dateToFormat: String) => {
    return format(new Date(dateToFormat.replace(" ", "T")), "MM/dd/yyyy");
  };

  const certificationNumberDisplay = (
    dateToFormat: String,
    cert_number: number,
  ) => {
    const CERTIFICATION_BASE = 36;
    const create_year = format(
      new Date(dateToFormat.replace(" ", "T")),
      "yyyy",
    );
    return `M-${create_year}${cert_number
      ?.toString(CERTIFICATION_BASE)
      .toUpperCase()}`;
  };

  useEffect(() => {
    const generateCertificationNumber = async () => {
      try {
        if (!cert_number) {
          const { data } =
            await songwritingApi.fetchCustomSongCertificationNumber();
          dispatch(updateCustomSong({ cert_number: data }));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    generateCertificationNumber();
  }, [cert_number, dispatch]);

  return (
    <div className={css.protectionDialogContainer}>
      <div className={css.protectionDialogCloseButton}>
        <div
          className={css.protectionDialogCloseIcon}
          onClick={() => {
            closeAction();
          }}
          role="button"
          tabIndex={0}
        >
          <CloseIcon />
        </div>
      </div>
      <div className={css.protectionDialogTitle}>
        This Song is {protectedStatus ? "Protected" : "Unprotected"}
      </div>
      <img
        src={protectedStatus ? protectedIcon : unProtectedIcon}
        alt={
          protectedStatus
            ? "A musical note on a white lock on a black shield"
            : "A musical note on a white lock on a red shield"
        }
      />
      <p className={css.protectionDialogText}>
        {protectedStatus
          ? "The moment a song or lyric is written, ownership belongs to the author. Download your song's date & timestamped certificate to retain proof of ownership."
          : "You must add at least one lyric and one chord to your song to be considered a musical work, at which point Moosiko will automatically protect your song and provide proof of ownership."}
      </p>
      <a
        className={css.protectionDialogLink}
        href="https://moosiko.com/blog/song-ownership-copyright-protection/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more about Moosiko proof of ownership.
      </a>
      <div className={css.protectionDialogDivider} />
      <div className={css.protectionDialogDetails}>
        <div className={css.protectionDialogDetailsLabel}>Song Name:</div>
        <div className={css.protectionDialogDetailsValue}>
          {currentSong.song_name}
        </div>
        <div className={css.protectionDialogDetailsLabel}>Date Created:</div>
        <div className={css.protectionDialogDetailsValue}>
          {dateDisplay(createdAt)}
        </div>
        <div className={css.protectionDialogDetailsLabel}>Last Updated:</div>
        <div className={css.protectionDialogDetailsValue}>
          {dateDisplay(updatedAt)}
        </div>
        <div className={css.protectionDialogDetailsLabel}>
          Certification Number:
        </div>
        <div className={css.protectionDialogDetailsValue}>
          {protectedStatus && cert_number
            ? certificationNumberDisplay(createdAt, cert_number)
            : "----------"}
        </div>
      </div>
      {cert_number && (
        <PDFDownloadLink
          document={
            <ProtectionDialogCertificatePdf
              currentSong={currentSong}
              certNumber={certificationNumberDisplay(createdAt, cert_number)}
              protectedStatus={protectedStatus}
            />
          }
          fileName={`${
            currentSong.song_name
          } Certificate ${certificationNumberDisplay(createdAt, cert_number)}`}
        >
          <div
            className={
              protectedStatus
                ? classNames(
                    css.protectionDialogButton,
                    css.protectionDialogButtonActive,
                  )
                : classNames(
                    css.protectionDialogButton,
                    css.protectionDialogButtonInactive,
                  )
            }
          >
            Download Certificate
            <GetAppIcon />
          </div>
        </PDFDownloadLink>
      )}
    </div>
  );
};
