import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { removeStudent } from "redux/organizations/organization_slice";
import { useHistory } from "react-router-dom";
import css from "./remove_student_modal.module.scss";
import { ModalContainer } from "../modal_container";

export const RemoveStudentModal = () => {
  const { name, studentId, organizationId, courseId } =
    useSelector(getModalDataSelector);
  const { goBack } = useHistory();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(removeStudent({ studentId, orgId: organizationId, courseId }));

    goBack();
  };
  return (
    <ModalContainer>
      <div className={css.wrapper}>
        <h2 className={css.header}>Are you sure? </h2>
        <span>
          {name} will still have access to their Moosiko account, skills, and
          song lessons, however they will no longer be associated with your
          organization
        </span>
        <Button className={css.send_btn} onClick={handleClick}>
          Yes, remove {name}
        </Button>
      </div>
    </ModalContainer>
  );
};
