import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Button, { BTN_TYPE } from "components/reusable/buttons/button";
import { getUserChordsCountSelector } from "redux/entities/user_skills/user_skills_selector";
import { updateUserSkill } from "redux/entities/user_skills/user_skills_slice";
import { submitChordsStep } from "redux/sign_up_steps/sign_up_steps_slice";
import { ChordsSkillset } from "../../reusable/chords_skillset";
import { ModalContainer } from "../modal_container";
import modalCSS from "../modal.module.scss";
import css from "./skillset_modal.module.scss";

const useStyles = makeStyles({
  formControlLabel: {
    fontSize: 20,
    color: "#aab7c4",
  },
});

/**
 *   Creates a modal for new users, on which they can select their start skills
 */
export const SkillsetModal = () => {
  const classes = useStyles({});

  const [dontKnowChords, setDontKnowChords] = useState(false);
  const dispatch = useDispatch();

  const onChordClickCallback = useCallback(
    ({ skillType, skillName, learned, cancelToken }) => {
      return dispatch(
        updateUserSkill({
          skillType,
          skillName,
          learned,
          cancelSrc: cancelToken,
        }),
      );
    },
    [dispatch],
  );

  const userChordsCount = useSelector(getUserChordsCountSelector);

  const [isNextBtnDisabled, setNextBtnDisableb] = useState(true);

  useEffect(() => {
    const disabled = userChordsCount === 0 && !dontKnowChords;
    setNextBtnDisableb(disabled);
  }, [userChordsCount, dontKnowChords]);

  const handleChordsStep = () => dispatch(submitChordsStep());

  return (
    <ModalContainer>
      <div className={modalCSS.title}>Current Skills</div>
      <div className={modalCSS.description}>
        Select chords you already know how to play. We'll use this info to
        recommend new songs and skills to learn.
      </div>

      <div
        className={classnames(
          modalCSS.content_center,
          modalCSS.side_padding,
          css.dont_know_chords,
        )}
      >
        <FormControlLabel
          color="default"
          classes={{
            label: classnames(
              classes.formControlLabel,
              css.dont_know_chords_label,
            ),
          }}
          control={
            <Checkbox
              color="default"
              checked={dontKnowChords}
              onChange={() => setDontKnowChords(!dontKnowChords)}
              disabled={Boolean(userChordsCount)}
            />
          }
          label="I don't know any chords yet"
        />
      </div>

      <div className={css.skillset}>
        <ChordsSkillset onChordClick={onChordClickCallback} />
      </div>

      <div className={modalCSS.content_center}>
        <Button
          type={BTN_TYPE.PRIMARY}
          disabled={isNextBtnDisabled}
          onClick={handleChordsStep}
        >
          Next
        </Button>
      </div>
    </ModalContainer>
  );
};
