import styled from "@emotion/styled";
import { DESKTOP } from "scss/emotion_consts";
import { Container, StretchContainer } from "../emotion.styles";

export const StyledButton = styled.button`
  padding: 1em 0;
  align-self: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
`;

export const StyledStretchContainer = styled(StretchContainer)`
  align-items: stretch;
`;

export const StyledContainer = styled(Container)`
  align-self: center;
  align-items: center;
  margin-bottom: 100px;
`;

export const Footer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  color: #6775de;
  font-weight: 600;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1300px;
  width: 100%;
  margin-bottom: 1em;
  flex-wrap: wrap;

  @media ${DESKTOP} {
    margin-bottom: initial;
    flex-wrap: initial;
  }
`;

export const Card = styled.button`
  min-width: 350px;
  border: 0.5px solid #c4c4c4;
  box-shadow: 0px 2px 6px rgba(182, 182, 182, 0.24);
  border-radius: 4px;
  padding: 1.7em 0.5em;
  font-weight: 600;
  color: #190067;
  white-space: break-spaces;
  max-width: 310px;
  width: 100%;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    color: #818181;
  }

  @media ${DESKTOP} {
    margin: 2em;
  }
`;

export const Img = styled.img`
  margin: auto;
  margin-top: 1.5em;
  object-fit: none;
  margin-top: 2em;
  flex-grow: 1;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: auto;
`;

export const StyledForm = styled.form`
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 700px;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 0;

    > button {
      align-self: initial;
    }
  }
`;
export const StyledInput = styled.input`
  padding: 14px 18px 14px 42px;
  border-radius: 4px;
  background: #f3f3f3;
  color: #818181;
  margin-right: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  flex-grow: 1;
  text-align: start;

  @media (max-width: 900px) {
    margin-bottom: 1em;
    width: 80%;
    padding: 14px 18px;
  }
`;

export const Label = styled.div<{ img?: string }>`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 25px;
    top: 11px;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
  }
`;
