import styled from "@emotion/styled";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TABLET } from "scss/emotion_consts";

export const StyledButton = styled.div`
  justify-self: center;
  margin: 2em 0;
`;

export const StyledBackIcon = styled(ArrowDownwardIcon)`
  transform: rotate(90deg);
  padding: 0.3em 0.5em;
`;
export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  max-width: 770px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.5em 2.5em;

  > span {
    margin-left: 2em;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  border: 1px solid grey;
  border-radius: 20px;
  padding: 11px 12px;
  box-shadow: 2px 7px 13px rgba(0, 0, 0, 0.25);
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 310px;
  text-align: start;
  color: #72808a;
  top: 60px;
  right: -40px;

  margin-top: 2em;
  position: initial;

  @media ${TABLET} {
    margin-top: initial;
  }
`;
export const HelpDescription = styled.div`
  margin: 0 1em;
  > span {
    font-weight: 600;
  }
`;
export const ClassroomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  max-height: 480px;
  margin-top: 2em;
  padding: initial;

  &::-webkit-scrollbar {
    display: none;
  }
  & > div:nth-child(2n-1) {
    border: 1px solid #56ccf2;
  }
  & > div:nth-child(2n) {
    border: 1px solid #bb6bd9;
  }

  @media ${TABLET} {
    display: flex;
    justify-content: start;
    align-self: center;
    min-width: 600px;
  }
`;
export const StyledClassroomCard = styled.div`
  padding: 1rem;
  margin: 1rem 0.5rem;
  min-width: 80%;
  min-height: 60px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  text-align: center;
  &:hover {
    cursor: pointer;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.05);
  }
  > p {
    font-size: 20px;
    color: #190067;
  }
`;
