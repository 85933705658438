/* eslint-disable no-nested-ternary */
import { Dispatch, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Papa from "papaparse";
import isEmail from "validator/lib/isEmail";
import css from "./add_students_bulk_upload.module.scss";
import { AddStudentsBulkUploadContent } from "./add_students_bulk_upload_content";
import { IStudents } from "../add_students_bulk_tab";

interface IAddStudentsBulkUpload {
  students: IStudents[];
  setStudents: Dispatch<IStudents[]>;
}

export const AddStudentsBulkUpload = ({
  students,
  setStudents,
}: IAddStudentsBulkUpload) => {
  const [fileName, setFileName] = useState("");

  const updateErrors = useCallback((error) => {
    setErrors([error]);
  }, []);
  const [errors, setErrors] = useState<string[]>([]);

  const onFileChange = useCallback(
    async (e) => {
      const { files } = e.target;
      const file = files[files.length - 1] || null;
      setFileName(file.name);
      e.target.value = null;
      setErrors([]);
      Papa.parse<{
        [FIRST_NAME]: string;
        [LAST_NAME]: string;
        [EMAIL]: string;
      }>(file, {
        header: true,
        skipEmptyLines: true,
        complete(results) {
          if (results.errors[0]) {
            const error = `${results.errors[0].message}, Row ${results.errors[0].row}`;
            updateErrors(error);
            toast.error(error);
            return;
          }

          const { data } = results;

          for (let i = 0; i < data.length; i++) {
            const student = data[i];

            if (!student[FIRST_NAME]) {
              const error = `Row ${i}, Enter student first name`;
              updateErrors(error);
              toast.error(error);
              return;
            }
            if (!student[LAST_NAME]) {
              const error = `Row ${i}, Enter student last name`;
              updateErrors(error);
              toast.error(error);
              return;
            }
            if (!student[EMAIL] || !isEmail(student[EMAIL])) {
              const error = `Row ${i}, Enter valid email`;
              updateErrors(error);
              toast.error(error);
              return;
            }
          }

          const transformedStudents = data.map((student) => ({
            firstName: student[FIRST_NAME],
            lastName: student[LAST_NAME],
            email: student[EMAIL],
          }));
          toast.success("Ready to Invite");
          setStudents(transformedStudents);
        },
      });
    },
    [setStudents, updateErrors],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange({ target: { files: acceptedFiles } });
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <AddStudentsBulkUploadContent
        getRootProps={getRootProps}
        isDragActive={isDragActive}
        errors={errors}
        students={students}
        fileName={fileName}
        getInputProps={getInputProps}
        onFileChange={onFileChange}
      />
      {errors.map((error) => (
        <p key={error} className={css.error_text}>
          {error}
        </p>
      ))}
    </>
  );
};

const FIRST_NAME = "First Name";
const LAST_NAME = "Last Name";
const EMAIL = "Email";
