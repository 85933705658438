import { FORM_RESET_PASSWORD } from "components/modal/reset_password_modal/reset_password_form";
import { Header, StretchContainer, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { FooterSignUp } from "../../reusable/footer_sign_up";

import lockLogo from "../../images/lock-logo.svg";
import {
  StyledContainer,
  StyledResetPasswordForm,
  InputContent,
} from "./setPassword.styled";

export const SetPassword = () => {
  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={36} />
      </Top>
      <StyledContainer>
        <Header>Set Your Password</Header>
        <LogoWithBackground
          src={lockLogo}
          padding="21px 28px"
          margin="2em 1em 0em"
        />
        <InputContent>
          <StyledResetPasswordForm isTeacherSignUp />
        </InputContent>
      </StyledContainer>
      <FooterSignUp
        type="submit"
        textButton="Set Password"
        form={FORM_RESET_PASSWORD}
      >
        Need help? Email
        <a
          href="mailto:help@moosiko.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          help@moosiko.com
        </a>
      </FooterSignUp>
    </StretchContainer>
  );
};
