import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getFirstOrganization,
  getOrganizationId,
  getOrganizationPasswordToggle,
  getOrganizationRecordToggle,
} from "redux/organizations/organization_selectors";
import {
  changeGlobalPassword,
  changeGlobalPasswordToggle,
  changeRecordToggle,
} from "redux/organizations/organization_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { toast } from "react-toastify";
import css from "./profile_instructor_controls.module.scss";
import { ProfileInstructorSwitch } from "./profile_instructor_switch";
import { useProfileSwitch } from "./use_profile_switch";

export const ProfileInstructorControls = () => {
  const dispatch = useDispatch();

  const orgId = useSelector(getOrganizationId);
  const userId = useSelector(getUserIdSelector);

  const [handleRecordToggleChange, recordToggle] = useProfileSwitch(
    changeRecordToggle,
    getOrganizationRecordToggle,
  );
  const [handlePasswordToggleChange, passwordToggle] = useProfileSwitch(
    changeGlobalPasswordToggle,
    getOrganizationPasswordToggle,
  );

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const org = useSelector(getFirstOrganization);

  const [password, setPassword] = useState("");

  const handlePasswordBlur = () => {
    if (password.length >= MIN_PASSWORD_LENGTH && userId && orgId) {
      dispatch(changeGlobalPassword({ userId, orgId, password }));
    } else {
      toast.error("Min password length is 6");
    }
  };

  return (
    <div className={css.organization}>
      <ProfileInstructorSwitch
        handleToggleChange={handleRecordToggleChange}
        toggleValue={recordToggle}
        text="Enable student video recording"
      />
      <div>
        <ProfileInstructorSwitch
          handleToggleChange={handlePasswordToggleChange}
          toggleValue={passwordToggle}
          text="Set Password by Admin"
        />
        <TextField
          id="filled-basic"
          label={org?.password}
          variant="filled"
          value={password}
          onChange={handlePasswordChange}
          className={css.input}
          onBlur={handlePasswordBlur}
          onFocus={() => {
            setPassword("");
          }}
        />
        <div className={css.text}>
          Enter a password to be set for all students. Use if students cannot
          receive outside emails due to school regulations.
        </div>
        <div className={css.text}>
          The same password will be set for all users.
        </div>
      </div>
    </div>
  );
};

const MIN_PASSWORD_LENGTH = 6;
