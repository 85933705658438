import { useDispatch } from "react-redux";
import { popTipsList } from "redux/songwriting/songwriting_slice";
import { SvgIcon } from "@material-ui/core";

const BackIcon = () => {
  const dispatch = useDispatch();
  const removeLastTip = () => {
    dispatch(popTipsList());
  };

  return (
    <SvgIcon
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={removeLastTip}
    >
      <path
        d="M15.1165 21.9167H4.9165V19.7917H15.1519C16.8047 19.7917 18.2273 19.2428 19.4196 18.1449C20.612 17.047 21.2082 15.6834 21.2082 14.0542C21.2082 12.4251 20.612 11.0615 19.4196 9.96362C18.2273 8.86571 16.8047 8.31675 15.1519 8.31675H4.704L8.7415 12.3542L7.254 13.8417L0.666504 7.25425L7.254 0.666748L8.7415 2.15425L4.704 6.19175H15.1165C17.3596 6.19175 19.2898 6.9473 20.9071 8.45841C22.5245 9.96953 23.3332 11.8348 23.3332 14.0542C23.3332 16.2737 22.5245 18.139 20.9071 19.6501C19.2898 21.1612 17.3596 21.9167 15.1165 21.9167Z"
        fill="#5F6367"
      />
    </SvgIcon>
  );
};

export default BackIcon;
