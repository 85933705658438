import cn from "classnames";
import { capitalize } from "lodash";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentInstrument } from "redux/entities/user_skills/user_skills_selector";

import css from "./skill.module.scss";

interface ISkill {
  children?: ReactNode;
  bgColor?: string;
  skillType?: string;
  onClick: () => void;
  clickable?: boolean;
}

export const Skill = (props: ISkill) => {
  const { children, bgColor, skillType, onClick, clickable } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(onClick);
  };

  return (
    <button
      type="button"
      style={{ background: bgColor }}
      className={cn(css.container, clickable ? css.clickable : null)}
      onClick={() => (skillType ? handleClick() : null)}
    >
      {children}
    </button>
  );
};

export const createSkills = (
  skills: string[],
  color: string,
  clickable: boolean,
  skillType: string | undefined = undefined,
  createClickHandler: any | undefined = undefined,
) => {
  return skills.length
    ? skills.map((skill) => {
        const currentInstrument = useSelector(getCurrentInstrument);

        let onClick;
        if (!clickable) {
          onClick = () => {};
        } else {
          onClick =
            createClickHandler &&
            createClickHandler(
              skillType,
              transformIntoJSON(skill),
              capitalize(currentInstrument),
            );
        }

        return (
          <Skill
            bgColor={color}
            key={skill}
            skillType={skillType}
            onClick={onClick}
            clickable={clickable}
          >
            {skill}
          </Skill>
        );
      })
    : "None";
};

export const transformIntoJSON = (str: string) => {
  const chordLength = 1;
  const result = str.split("-");
  // if it chord, there is no array need
  if (result.length === chordLength) {
    return result[0];
  }
  return JSON.stringify(result);
};
