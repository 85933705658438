import classnames from "classnames";
import css from "./assessment_row.module.scss";

export const AssessmentSkeletonRow = () => {
  return (
    <div className={css.container}>
      <div className={classnames(css.name, css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classnames(css.createdAt, css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classnames(css.skillTypeName, css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classnames(css.skillName, css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classnames(css.lessonName, css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classnames(css.actions, css.row_item)}>
        <div className={css.info_skeleton} />
        <div
          className={classnames(css.info_skeleton, css.info_skeleton_small)}
        />
      </div>
    </div>
  );
};
