import { createSelector } from "reselect";
import { IState } from "redux/store";
import { IInstruments } from "../user_skills/user_skills_slice";

export const getChordsSelector = (state: IState) => [
  ...state.entities.chords.guitar,
  ...state.entities.chords.ukulele,
];

export const getChordsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.chords[instrument],
    (chords) => chords,
  );
export const getChordsAmountByInstrument = (instrument: IInstruments) =>
  createSelector(getChordsByInstrument(instrument), (amount) => amount.length);
export const getChordsByInstrumentLikeString = (instrument: IInstruments) =>
  createSelector(getChordsByInstrument(instrument), (chords) =>
    chords.map((chord) => chord.name),
  );

export const getChordsLikeStringSelector = createSelector(
  getChordsSelector,
  (chords) => chords.map((chord) => chord.name),
);

export const getChordsCountSelector = createSelector(
  getChordsSelector,
  (chords) => chords.length,
);
