import { memo, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { UseDialog } from "hooks/use_dialog";
import classnames from "classnames";
import { setMediaRecordsStateRecording } from "redux/media_recordings/media_recordings_slice";
import css from "./media_recorder.module.scss";
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import { SendVideoDialog } from "./send_video_dialog";
import { UseMediaRecorder } from "./use_media_recorder";

interface IMediaRecorder {
  handleUpload: (_: File) => void;
  fileName: string;
}

// React.memo because videojs uses native dom api to access <video> tag
export const MediaRecorder = memo(
  ({ handleUpload, fileName }: IMediaRecorder) => {
    const videoRef = useRef(null);
    const playerRef = useRef<any>({});
    const [
      sendVideoDialog,
      closeSendVideoDialog,
      openSendVideoDialog,
    ] = UseDialog();

    const [recorderVideoSrc, setRecorderVideoSrc] = useState("");
    const [recordedData, setRecordedData] = useState<null | Blob>(null);
    const [countdown, setCountdown] = useState<string | null>(null);

    const dispatch = useDispatch();

    const startRecord = useCallback(() => {
      setCountdown(GET_READY);

      setTimeout(() => {
        setCountdown(null);
        dispatch(setMediaRecordsStateRecording());
        // @ts-ignore
        playerRef.current.record().getDevice();
      }, DELAY_3_SEC);
    }, [dispatch]);

    const handleSubmitVideo = () => {
      closeSendVideoDialog({});
      if (recordedData) {
        const file = new File([recordedData], fileName, {
          type: "video/webm",
        });
        handleUpload(file);
      }
    };

    UseMediaRecorder({
      startRecord,
      playerRef,
      openSendVideoDialog,
      setRecorderVideoSrc,
      setRecordedData,
      videoRef,
    });
    return (
      <>
        <div
          className={classnames(css.countdown, {
            [css.countdown_active]: !!countdown,
          })}
        >
          <div className={css.countdown_value}>{countdown}</div>
        </div>
        <video
          id="myVideo"
          ref={videoRef}
          className={classnames(css.video_element, "myVideo")}
        />
        <SendVideoDialog
          closeSendVideoDialog={closeSendVideoDialog}
          sendVideoDialog={sendVideoDialog}
          handleSubmitVideo={handleSubmitVideo}
          recorderVideoSrc={recorderVideoSrc}
        />
      </>
    );
  },
);

const GET_READY = "Get Ready";
const DELAY_3_SEC = 500;
