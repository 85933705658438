import cn from "classnames";
import React from "react";

import Metronome from "./metronome.png";
import css from "./metronome_controls.module.scss";
import MetronomeMobile from "./metronome_mobile.png";

interface IMetronomeControls {
  mobile?: boolean;
  metronome?: number;
  toggleMetronome?: () => void;
  soundSelect: React.ReactElement;
}

export const MetronomeControls = ({
  mobile,
  metronome,
  toggleMetronome,
  soundSelect,
}: IMetronomeControls) => {
  return mobile ? (
    <>
      <button
        type="button"
        className={cn(css.metronome, { [css.metronome_active]: metronome })}
        onClick={toggleMetronome}
      >
        <picture>
          <source srcSet={mobile ? MetronomeMobile : Metronome} />
          <img
            src={Metronome}
            alt="Chris standing up holding his daughter Elva"
          />
        </picture>
        <div>Metronome</div>
      </button>
      {soundSelect}
    </>
  ) : (
    soundSelect
  );
};
