import { createSelector } from "reselect";
import { IState } from "redux/store";
import { IInstruments } from "../user_skills/user_skills_slice";

export const getTransitionsSelector = (state: IState) => [
  ...state.entities.transitions.guitar,
  ...state.entities.transitions.ukulele,
];

export const getTransitionsLikeStringSelector = createSelector(
  [getTransitionsSelector],
  (transitions) =>
    transitions
      .map((transition) => JSON.parse(transition.name).join("-"))
      .sort(),
);

export const getTransitionsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.transitions[instrument],
    (transition) => transition,
  );
export const getTransitionsAmountByInstrument = (instrument: IInstruments) =>
  createSelector(getTransitionsByInstrument(instrument), (transition) =>
    transition ? transition.length : 0,
  );
export const getTransitionsLikeStringByInstrument = (
  instrument: IInstruments,
) =>
  createSelector(getTransitionsByInstrument(instrument), (transitions) =>
    transitions
      .map((transition) => JSON.parse(transition.name).join("-"))
      .sort(),
  );

export const getTransitionsCountSelector = createSelector(
  [getTransitionsSelector],
  (transitions) => transitions.length,
);

export const getAllTransitionsCountSelector = createSelector(
  [getTransitionsByInstrument("guitar"), getTransitionsByInstrument("ukulele")],
  (guitar, ukulele) => guitar.length + ukulele.length,
);
