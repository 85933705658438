import { SongBuilderModal } from "components/songwriting/songbuilder/songbuilder_modal";
import PayModal from "components/stripe/pay_modal";
import { PayForSubscriptionModal } from "components/stripe/subscription/pay_for_subscription_modal";
import { useSelector } from "react-redux";
import { IState } from "redux/store";

import { IModalState } from "redux/ui/modal/modal_slice";
import { ViewSongModal } from "components/songwriting/view_song_modal";
import SeedNoteModal from "components/songwriting_mobile/seed_note/seed_note_modal";
import { DeleteProfileModal } from "components/layouts/profile/delete_profile";
import { MobileMenuModal } from "../nav/mobile/menu_modal";
import { ChoosePlanModal } from "./choose_plan_modal";
import { CompletionReminderModal } from "./completion_reminder_modal";
import { LoginModal } from "./login_modal";
import { OrgNamePromptModal } from "./org_name_modal/org_name_modal";
import { RecommendationModal } from "./recommendation_modal";
import { RemoveStudentModal } from "./remove_student_modal/remove_student_modal";
import { ReqPWResetModal } from "./req_password_reset_modal";
import { ResetPWModal } from "./reset_password_modal/reset_password_modal";
import { RunTunerModal } from "./run_tuner_modal";
import { SignupModal } from "./signup_modal";
import { SkillsetModal } from "./skillset_modal";
import { TeacherAccountConfirmationModal } from "./teacher_account_confirmation_modal/teacher_account_confirmation_modal";
import { UserInfoModal } from "./user_info_modal/user_info_modal";

const modals = {
  login: LoginModal,
  signup: SignupModal,
  skillset: SkillsetModal,
  choosePlan: ChoosePlanModal,
  viewSong: ViewSongModal,
  mobileMenu: MobileMenuModal,
  pay: PayModal,
  runTuner: RunTunerModal,
  recommendation: RecommendationModal,
  reqPWReset: ReqPWResetModal,
  resetPW: ResetPWModal,
  payForSubscription: PayForSubscriptionModal,
  userInfoModal: UserInfoModal,
  removeStudentModal: RemoveStudentModal,
  completionReminderModal: CompletionReminderModal,
  teacherConfirmationReminder: TeacherAccountConfirmationModal,
  orgName: OrgNamePromptModal,
  songbuilder: SongBuilderModal,
  seedNote: SeedNoteModal,
  deleteProfile: DeleteProfileModal,
};

export const Modal = () => {
  const modal = useSelector<IState, IModalState>((state) => state.ui.modal);

  const ModalType = modals[String(modal.type)];

  return ModalType ? <ModalType /> : null;
};
