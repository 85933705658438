import { useDraggable } from "@dnd-kit/core";
import { CircularProgress } from "@material-ui/core";
import { Suspense } from "react";
import { SONGBUILDER_DATA } from "components/songwriting/songbuilder/songbuilder_data";
import {
  SongContent,
  EnumDragAndDropActions,
} from "redux/songwriting/songwriting_interfaces";
import css from "./draggable_item.module.scss";

interface IDraggableItem {
  item: SongContent;
}

export const DraggableItem = ({ item }: IDraggableItem) => {
  const typeDragAndDropAction = SONGBUILDER_DATA.songStructureArray.includes(
    item.title,
  )
    ? EnumDragAndDropActions.createStructure
    : EnumDragAndDropActions.addChordToStructure;

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `draggable/-${item.title}`,
    data: { item, typeDragAndDropAction },
  });

  const style = {
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={css.list_item_box}
      {...listeners}
      {...attributes}
    >
      {item.icon ? (
        <Suspense fallback={<CircularProgress />}>{item.icon}</Suspense>
      ) : (
        <p className={css.list_item_title}>{item.title}</p>
      )}
    </div>
  );
};
