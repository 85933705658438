import { useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteMediaRecords } from "redux/media_recordings/media_recordings_slice";
import { getSelectedAssessments } from "redux/assessments/assessments_selectors";
import { Dialog } from "@material-ui/core";
import { TAssessmentSortTypes } from "redux/assessments/constants";
import css from "./assessment_row.module.scss";
import { TableHeaderItem } from "./table_header_item/table_header_item";

export const AssessmentTableHeader = () => {
  const dispatch = useDispatch();

  // without this, bug with useSelector
  // steps to reproduce:
  // 0. change useSelector(state => getSelectedAssessments(state)) to useSelector(getSelectedAssessments)
  // 1. open assessments with 1+ assessment
  // 2. select 1+ assessment
  // 3. now filter them, so selected assessments won't be displayed
  // 4. delete button will be showing
  const selectedRecords = useSelector(getSelectedAssessments);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleCloseDialog = () => {
    setDeleteDialog(false);
  };

  const tryDeleteMediaRecords = () => {
    dispatch(deleteMediaRecords());
    handleCloseDialog();
  };

  const itemsToDeleteCount = selectedRecords.length;

  return (
    <>
      <div className={classnames(css.container, css.header_row)}>
        <button
          type="button"
          className={classnames(css.delete_button, {
            [css.display_none]: !selectedRecords.length,
          })}
          onClick={() => setDeleteDialog(true)}
        >
          <DeleteIcon />
          Delete
        </button>
        <div className={css.checkbox_place} />
        <TableHeaderItem
          text="Name"
          sortValue={TAssessmentSortTypes.NAME}
          classes={css.name}
        />
        <TableHeaderItem
          text="Submission Date"
          sortValue={TAssessmentSortTypes.SUBMISSION_DATE}
          classes={css.createdAt}
        />
        <TableHeaderItem
          text="Skill Type"
          sortValue={TAssessmentSortTypes.SKILL_TYPE}
          classes={css.skillTypeName}
        />
        <TableHeaderItem
          text="Skill"
          sortValue={TAssessmentSortTypes.SKILL}
          classes={css.skillName}
        />
        <TableHeaderItem
          text="Song Lesson"
          sortValue={TAssessmentSortTypes.SONG_LESSON}
          classes={css.lessonName}
        />
        <TableHeaderItem
          text="Submission"
          sortValue={TAssessmentSortTypes.SUBMISSION}
          classes={css.actions}
        />
      </div>
      <Dialog open={deleteDialog} maxWidth="sm" onClose={handleCloseDialog}>
        <section className={css.remove_class_dialog}>
          <h2 className={css.remove_class_header}>
            {`Are you sure you want to delete ${itemsToDeleteCount} recording${
              itemsToDeleteCount > 1 ? "s" : ""
            }?`}
          </h2>
          <p>
            These recordings will be deleted forever and no longer be accessible
            by you or the student.
          </p>
          <div className={css.buttons_container}>
            <button
              type="button"
              className={css.no_keep}
              onClick={handleCloseDialog}
            >
              Cancel
            </button>
            <button
              onClick={tryDeleteMediaRecords}
              type="button"
              className={css.yes_remove}
            >
              Yes, Delete
            </button>
          </div>
        </section>
      </Dialog>
    </>
  );
};
