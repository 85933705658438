import cn from "classnames";
import { ILesson } from "api/lesson_api";
import css from "./cards_content.module.scss";
import { SongCard } from "../my_journey_song_card/my_journey_song_card";

interface IUserSongs {
  songs: ILesson[];
  topText?: string;
}

export const UserSongs = (props: IUserSongs) => {
  const { songs, topText } = props;
  return (
    <div className={cn(css.wrapper)}>
      <div className={css.text}>{topText}</div>
      <div className={cn(css.songs_wrapper)}>
        {songs.map((song) => (
          <SongCard key={song.id} lesson={song} />
        ))}
      </div>
    </div>
  );
};
