import axios from "axios";
import { getNodeApiBaseUrl } from "./base_api";

const url = `${getNodeApiBaseUrl()}/bedrock-ai`;

export const bedrockAiApi = {
  async invokeAi(prompt: string) {
    try {
      const result = await axios.post(`${url}/invokeAi`, {
        prompt,
      });
      return result.data;
    } catch (err) {
      console.error(err);
    }
  },
};
