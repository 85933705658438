const LIMIT_IN_MINUTES = 5;

export const options = {
  controls: false,
  bigPlayButton: false,
  width: 320,
  height: 240,
  fluid: false,
  debug: true,
  plugins: {
    record: {
      videoMimeType: "video/webm;codecs=vp9.0",
      convertEngine: "ts-ebml",
      audio: true,
      frameHeight: 320,
      frameWidth: 240,
      video: {
        width: 320,
        height: 240,
      },
      maxLength: 60 * LIMIT_IN_MINUTES,
      pip: false,
    },
  },
  controlBar: {
    // hide fullscreen and volume controls
    fullscreenToggle: true,
    volumePanel: false,
    deviceButton: false,
    recordIndicator: false,
    recordToggle: false,
  },
};
