import { ISlide } from "api/lesson_api";
import watchImg from "img/watch.png";
import css from "./slide_types.module.scss";

interface IBreak {
  slide: ISlide;
}

export const Break = ({ slide }: IBreak) => {
  const { text_top, text_bottom } = slide;

  return (
    <div className={css.main}>
      <img alt="watch" src={watchImg} className={css.watch} />
      <h1 className={css.text_top}>{text_top}</h1>
      <div className={css.list}>
        {text_bottom.split(";").map((text, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx} className={css.list_item}>
            {text}
          </li>
        ))}
      </div>
    </div>
  );
};
