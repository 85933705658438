import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { fetchCustomSongTemplates } from "redux/songwriting/songwriting_slice";
import { getCustomSongTemplates } from "redux/songwriting/songwriting_selectors";
import { CreateNewSong } from "./create_new_song/create_new_song";
import css from "./start_new_song.module.scss";

export const StartNewSong = () => {
  const customSongTemplates = useSelector(getCustomSongTemplates);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomSongTemplates());
  }, [dispatch]);

  return (
    <Box className={css.outer_container}>
      <Box className={css.inner_container}>
        <p className={css.title}>Start a new song</p>
        <Box className={css.card_wrapper}>
          <CreateNewSong />
          {customSongTemplates.map((songTemplate) => (
            <CreateNewSong
              key={songTemplate.id}
              songTemplateId = {songTemplate.id}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
