/* eslint-disable no-unused-vars */
import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import {
  updateCustomSong,
  updateCustomSelectedSong,
} from "redux/songwriting/songwriting_slice";

import css from "../songbuilder_header/custom_song_settings/custom_song_settings.module.scss";

export enum SONG_SETTINGS_LISTS {
  time_signature = "time_signature",
  custom_song_key = "custom_song_key",
  tuning = "tuning",
  chord_icon_style = "chord_icon_style",
}

interface ISongMeterList {
  handleClose: () => void;
  dataArray: string[];
  list_key: keyof typeof SONG_SETTINGS_LISTS;
}

export const SongSettingsList = ({
  handleClose,
  dataArray,
  list_key,
}: ISongMeterList) => {
  const dispatch = useDispatch();
  const currentSong = useSelector(getCurrentCustomSong);

  const itemClickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.currentTarget.dataset.value) {
      if (e.currentTarget.dataset.value !== currentSong[list_key]) {
        dispatch(
          updateCustomSong({
            [list_key]: e.currentTarget.dataset.value as string,
          }),
        );
        dispatch(
          updateCustomSelectedSong({
            [list_key]: e.currentTarget.dataset.value,
          }),
        );
      }
    }
    e.currentTarget.dataset.value !== currentSong[list_key] && handleClose();
  };

  return (
    <ul className={css.list_wrapper}>
      {dataArray.map((item, index) => (
        <li
          key={`${index++}-dataArray`}
          role="menuitem"
          data-value={item}
          onClick={itemClickHandler}
          className={cn(css.padding, css.list_item, {
            [css.disabled]: item === currentSong[list_key],
          })}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};
