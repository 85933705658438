import { Route, Redirect, match, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "components/loading/loader";
import React, { useEffect } from "react";
import Spinner from "components/loading/spinner";
import {
  getLessonsCacheSelector,
  isLessonAvailableSelector,
} from "redux/entities/lessons/lesson_selectors";
import { getUserSelector } from "redux/auth/user_selectors";
import { areFreeCreditsAvailableSelector } from "redux/free_user_lessons/free_user_lessons_selectors";
import { createUserLesson } from "redux/entities/user_lessons/user_lessons_slice";
import { getLessonsById } from "redux/entities/lessons/lessons_slice";
import { IState } from "redux/store";
import { openModal } from "redux/ui/modal/modal_slice";
import { isFree } from "util/songs_helpers";
import { LESSON, SONG_SHEET, SIGNUP } from "./route_constants";

interface ILessonRouter {
  component: React.ElementType;
  path?: string;
  exact?: boolean;
}
// Go to lesson if owned, go to pay modal if not, and go to signup modal if not logged in.
export const LessonRoute = ({
  component: Component,
  path,
  exact,
}: ILessonRouter) => {
  const areFreeCreditsAvailable = useSelector(areFreeCreditsAvailableSelector);
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const lessons = useSelector(getLessonsCacheSelector);
  const computedMatch = useRouteMatch<{ id: string }>(LESSON);
  const id = Number(computedMatch?.params.id);
  const lessonFound = lessons[id] !== undefined;

  const isLessonAvailable = useSelector((state: IState) => {
    const lessonId = computedMatch
      ? Number(
          (computedMatch.path === LESSON ||
            computedMatch.path === SONG_SHEET) &&
            computedMatch.params.id,
        )
      : null;
    if (!lessonId || !lessonFound) {
      return false;
    }
    return isLessonAvailableSelector(lessonId)(state);
  });

  useEffect(() => {
    async function fetchData() {
      await dispatch(getLessonsById({ id: [id] }));
    }
    if (!lessonFound) {
      fetchData();
    }

    return () => {};
  }, [dispatch, id, lessonFound]);

  return lessonFound ? (
    <Route
      path={path}
      exact={exact}
      component={(props: { match: match<{ id: string }> }) => {
        // if user is not logged in
        const lesson = lessons[Number(props.match.params.id)];

        if (!user) {
          if (!isFree(lesson)) {
            return <Redirect to={SIGNUP} />;
          }
          // allow non-signed in users to see free lessons
          return <Component {...props} />;
        }
        if (!lesson) {
          // redirect home if no lesson was found
          return <Redirect to="/" />;
        }
        // if user hasn't bought the lesson yet
        if (!isLessonAvailable) {
          // go to pay modal if not free
          if (!isFree(lesson)) {
            if (areFreeCreditsAvailable) {
              dispatch(openModal({ type: "pay", lesson }));
            } else {
              dispatch(openModal({ type: "payForSubscription" }));
            }
            return <Redirect to="/" />;
          }
          // if user doesn't own a free lesson
          const lessonId = lesson.id;
          // make a user lesson if this is their first time seeing the lesson.
          dispatch(createUserLesson({ userId: user.id, lessonId }));
          return <Loader spinner={Spinner} delay={500} />;
        }
        return <Component {...props} />;
      }}
    />
  ) : (
    <div />
  );
};
