import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { logout } from "redux/auth/auth_actions";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { IState } from "redux/store";
import { feedbackApi } from "./feedback_api";
import { getFeedbackInfo } from "./feedback_selectors";
import { IFeedback, IFeedbackBase } from "./feedback_types";

interface IFetchUserFeedback {
  studentId: number;
}

export const fetchUserFeedback = createAsyncThunk<
  IMediaRecord[],
  IFetchUserFeedback,
  { state: IState }
>("feedback/fetchUserFeedback", async ({ studentId }) => {
  const {
    data: { feedback },
  } = await feedbackApi.fetchFeedback(studentId);
  return feedback;
});

interface ISendVideoFeedback {
  file: File;
}

export const sendVideoFeedback = createAsyncThunk<
  void,
  ISendVideoFeedback,
  { state: IState }
>("feedback/sendVideoFeedback", async ({ file }, { getState }) => {
  const state = getState();
  const { studentId, teacherId, mediaRecordId } = getFeedbackInfo(state) || {};
  if (studentId && teacherId && mediaRecordId) {
    const feedback = {
      studentId,
      teacherId,
      mediaRecordId,
      file,
    };
    await feedbackApi.createFeedback(feedback);
  } else {
    throw new Error("feedback/sendVideoFeedback");
  }
});

type IUpdateUserFeedback = Partial<IFeedback>;

export const updateUserFeedback = createAsyncThunk<
  Partial<IFeedback>,
  IUpdateUserFeedback,
  { state: IState }
>("feedback/updateUserFeedback", async (newFeedbackValues) => {
  await feedbackApi.updateFeedback(newFeedbackValues);
  return newFeedbackValues;
});

interface IFeedbackState {
  feedback: IMediaRecord[];
  info: IFeedbackBase | null;
}

const initialState: IFeedbackState = {
  feedback: [],
  info: null,
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setFeedbackInfo: (state, action: PayloadAction<IFeedbackBase>) => {
      state.info = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserFeedback.fulfilled, (state, { payload }) => {
      const { feedback } = state;
      const updatedRecordIndex = feedback.findIndex(
        (record) =>
          record.feedbacks.length && record.feedbacks[0].id === payload.id,
      );
      if (updatedRecordIndex === -1) {
        return state;
      }
      let feedbackValue = feedback[updatedRecordIndex].feedbacks[0];
      feedbackValue = { ...feedbackValue, ...payload };
      feedback[updatedRecordIndex].feedbacks[0] = feedbackValue;

      const newRecord = { ...feedback[updatedRecordIndex] };
      feedback[updatedRecordIndex] = newRecord;
    });
    builder.addCase(fetchUserFeedback.fulfilled, (state, { payload }) => {
      const areRecordsUpdated =
        state.feedback.length &&
        payload.some((record) => {
          const existingVideoEntity = state.feedback.find(
            (existingRecord) => record.video?.id === existingRecord.video?.id,
          );

          if (!existingVideoEntity) {
            return true;
          }
          return (
            record.video?.updatedAt !== existingVideoEntity.video?.updatedAt
          );
        });
      if (areRecordsUpdated) {
        toast.success("Video successfully submitted. View in My Journey");
      }
      state.feedback = payload;
    });
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const {
  reducer: feedbackReducer,
  actions: { setFeedbackInfo },
} = feedbackSlice;
