import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";

import css from "./scroll_controls.module.scss";
import { useScrollPercentage } from "../hooks/use_scroll_percentage";

interface IScrollControls {
  length: number;
  scrollActive: boolean;
  scrollRequested: boolean;
  toggleScroll: () => void;
}

export const ScrollControls = ({
  length,
  scrollActive,
  scrollRequested,
  toggleScroll,
}: IScrollControls) => {
  const scrollPercentage = useScrollPercentage();

  const currentTimePlain = Math.ceil(length * scrollPercentage);
  const currentTime = currentTimePlain >= 0 ? currentTimePlain : 0;
  const secondsPlain = currentTime % 60;
  const TWO_DIGITS_NUMBER = 9;
  const seconds =
    secondsPlain > TWO_DIGITS_NUMBER ? secondsPlain : `0${secondsPlain}`;
  const minutes = Math.floor(currentTime / 60);
  const totalSecondsPlain = length % 60;
  const totalSeconds =
    totalSecondsPlain > TWO_DIGITS_NUMBER
      ? totalSecondsPlain
      : `0${totalSecondsPlain}`;
  const totalMinutes = Math.floor(length / 60);

  const timeValue = (
    <>
      <span>{`${minutes}:${seconds}`} | </span>
      <span className={css.end_time}>{`${totalMinutes}:${totalSeconds}`}</span>
    </>
  );

  const showPlayButton = !scrollActive && !scrollRequested;
  const controlButton = (
    <button onClick={toggleScroll} type="button">
      {showPlayButton ? (
        <PlayCircleFilledIcon
          className={css.playButtonStyles}
          fontSize="inherit"
        />
      ) : (
        <PauseCircleFilledIcon
          className={css.playButtonStyles}
          fontSize="inherit"
        />
      )}
    </button>
  );
  const scrollControls = (
    <div className={css.controls}>
      {controlButton}
      <div className={css.time}>{timeValue}</div>
    </div>
  );
  return scrollControls;
};
