import React from "react";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAuthSelector } from "redux/auth/user_selectors";
import { areFreeCreditsAvailableSelector } from "redux/free_user_lessons/free_user_lessons_selectors";

import { ROLES } from "redux/auth/roles/roles_consts";
import { CreateSongCard } from "./create_song_card/create_song_card";

interface ICrateNewSong {
  songTemplateId?: number;
}

export const CreateNewSong = ({ songTemplateId }: ICrateNewSong) => {
  const { role } = useSelector(getAuthSelector);
  const areFreeCreditsAvailable = useSelector(areFreeCreditsAvailableSelector);

  const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
  }: {
    condition: boolean;
    wrapper: Function;
    children: React.ReactNode;
  }) => (condition ? wrapper(children) : children);

  const freeUserPlan = [
    ROLES.FREE_USER,
    ROLES.FREE_TEACHER_STUDENT,
    ROLES.FREE_TEACHER_ACCOUNT,
    ROLES.FREE_SONGWRITING,
  ].includes(role as any);

  return (
    <ConditionalWrapper
      condition={freeUserPlan && !areFreeCreditsAvailable}
      // eslint-disable-next-line no-undef
      wrapper={(children: JSX.Element) => (
        <Tooltip title="Upgrade your account to create more songs">
          {children}
        </Tooltip>
      )}
    >
      <div>
        <CreateSongCard
          freeUserPlan={freeUserPlan}
          areFreeCreditsAvailable={areFreeCreditsAvailable}
          songTemplateId={songTemplateId}
        />
      </div>
    </ConditionalWrapper>
  );
};
