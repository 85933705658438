import axios from "axios";

import { getNodeApiBaseUrl } from "./base_api";

interface ISignUpSteps {
  select_chords_step_done: boolean;
  select_plan_step_done: boolean;
}

export const signUpStepsApi = {
  fetchChordSkillSteps() {
    const url = `${getNodeApiBaseUrl()}/sign-up-steps`;
    return axios.get<ISignUpSteps>(url);
  },

  submitChordsStep(chordIds: number[]) {
    const url = `${getNodeApiBaseUrl()}/sign-up-steps/chords`;
    return axios.post(url, { chordIds });
  },

  submitPlanStep() {
    const url = `${getNodeApiBaseUrl()}/sign-up-steps/plan`;
    return axios.post(url);
  },
};
