import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { useLocation } from "react-router";
import { organizationApi } from "redux/organizations/organization_api";
import { UPGRADE } from "routes/route_constants";
import { userApi } from "api/user_api";
import { fetchPermissions } from "redux/auth/auth_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import { getUserEmailSelector } from "redux/auth/user_selectors";
import { getProductQuantity } from "redux/entities/stripe/stripe_selectors";
import { updateOrganization } from "redux/org_management/org_management_slice";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { GetQuoteFormWrapper, GetQuoteStepWrapper } from "./get_quote.styled";

interface ICustomEvent {
  data: {
    type: string;
    eventName: string;
    id: string;
  };
}

const formID = "e70af434-6def-4139-a1f6-97f1573fa8de";

export const GetQuote = () => {
  const orgId = useSelector(getOrganizationId);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const studentEmail = useSelector(getUserEmailSelector);
  const quantity = useSelector(getProductQuantity);

  const handler = (event: ICustomEvent) => {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      if (event.data.id === formID) {
        if (pathname === UPGRADE && orgId && studentEmail) {
          organizationApi.upgradeStudents(orgId);
          dispatch(updateOrganization({ id: orgId, max_seats: quantity }));
          userApi.updateUserRole({
            email: studentEmail,
            roleName: ROLES.PAID_TEACHER_ACCOUNT,
          });
          dispatch(fetchPermissions());
        }
        dispatch(
          stepListActions.nextStep({
            stepListId: STEP_LIST_IDS.signUpStepList,
          }),
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  });

  useEffect(() => {
    const scriptTag = document.createElement("script");

    scriptTag.src = "//js.hsforms.net/forms/shell.js";
    scriptTag.charset = "utf-8";
    scriptTag.type = "text/javascript";

    document.body.appendChild(scriptTag);

    scriptTag.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6894701",
          formId: formID,
          target: "#quote_step",
        });
      }
    });

    return () => {
      document.body.removeChild(scriptTag);
    };
  });
  return (
    <GetQuoteStepWrapper>
      <GetQuoteFormWrapper>
        <div id="quote_step" />
      </GetQuoteFormWrapper>
    </GetQuoteStepWrapper>
  );
};
