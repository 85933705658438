import { startOfWeek } from "date-fns";
import { TODAY, MONDAY } from "constants/date_fns_constants";

export function getThisWeekMinutes(userSessions: {day: string, minutes: number}[]) {
  const thisWeekMonday = startOfWeek(TODAY, { weekStartsOn: MONDAY });
  thisWeekMonday.setHours(0, 0, 0, 0);
  const thisWeekMinutes = userSessions
    .filter(date => {
      const realDate = new Date(`${date.day}:`);
      realDate.setHours(1, 0, 0, 0);
      return realDate > thisWeekMonday;
    })
    .reduce((acc, date) => acc + date.minutes, 0);
  return thisWeekMinutes;
}
