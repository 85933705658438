import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { getUserSelector } from "redux/auth/user_selectors";
import {
  openLoginModal,
  openReqPWResetModal,
  openSignupModal,
} from "redux/ui/modal/modal_slice";
import { applyPromo } from "redux/auth/auth_slice";
import {
  LOGIN,
  REQ_PASSWORD_RESET,
  MODAL_SIGNUP,
  MOBILE_SIGNUP,
} from "./route_constants";

export function clearURL() {
  window.history.replaceState({}, document.title, "/#/");
}

interface IAuth {
  path: string;
  exact?: boolean;
}

export const AuthRoute = ({ path, exact }: IAuth) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getUserSelector);
  const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

  return (
    <Route
      path={path}
      exact={exact}
      component={() => {
        if (!loggedIn) {
          // handle opening signup/login modals when user goes to these URLs
          switch (path) {
            case LOGIN:
              dispatch(openLoginModal());
              break;
            case MODAL_SIGNUP: {
              // 'promo' shows up in the URL params when using a promo link (e.g. free sub)
              const { promo } = queryString.parse(window.location.search);
              if (promo) {
                clearURL();
                dispatch(applyPromo(promo));
              }
              dispatch(openSignupModal());
              break;
            }
            case MOBILE_SIGNUP: {
              dispatch(openSignupModal());
              break;
            }
            case REQ_PASSWORD_RESET:
              dispatch(openReqPWResetModal());
              break;
            default:
              break;
          }
        }
        return <Redirect to={mobile ? "/newMobileUser" : "/"} />;
      }}
    />
  );
};
