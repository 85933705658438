import { createSelector } from "reselect";
import { getAuthSelector } from "redux/auth/user_selectors";
import { IRoles } from "./roles_consts";

export const getRoleSelector = createSelector(
  [getAuthSelector],
  (user) => user.role,
);

// access only for these roles
export const accessByRoleSelector = (roles: IRoles[]) =>
  createSelector([getRoleSelector], (userRole) =>
    userRole ? roles.includes(userRole) : false,
  );
