export const VisibilityOffIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        borderRadius: "20px",
        padding: "9px 8px 8px",
        backgroundColor: "#f3f3f3",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01 3.42L3.42 2L21.15 19.75L19.74 21.16L16.31 17.73C14.97 18.25 13.52 18.55 12 18.55C7 18.55 2.73 15.44 1 11.05C1.77 9.08 3.06 7.38 4.69 6.1L2.01 3.42ZM17 11.05C17 8.29 14.76 6.05 12 6.05C11.49 6.05 11 6.15 10.53 6.29L8.36 4.12C9.51 3.75 10.73 3.55 12 3.55C17 3.55 21.27 6.66 23 11.04C22.31 12.8 21.21 14.34 19.82 15.57L16.76 12.51C16.9 12.05 17 11.56 17 11.05ZM12 16.05C9.24 16.05 7 13.81 7 11.05C7 10.28 7.18 9.55 7.49 8.91L9.06 10.48C9.03 10.66 9 10.85 9 11.05C9 12.71 10.34 14.05 12 14.05C12.2 14.05 12.38 14.02 12.57 13.98L14.14 15.55C13.49 15.87 12.77 16.05 12 16.05ZM14.97 10.72C14.82 9.32 13.72 8.23 12.33 8.08L14.97 10.72Z"
        fill="#333333"
      />
    </svg>
  );
};
