/* eslint-disable no-alert */
/* eslint-disable no-magic-numbers */

class Tuner {
  middleA: number;

  semitone: number;

  bufferSize: number;

  noteStrings: string[];

  audioContext?: AudioContext;

  analyser?: AnalyserNode;

  scriptProcessor?: ScriptProcessorNode;

  audioProc?: (event: any) => void;

  pitchDetector: any;

  /* eslint-disable-next-line no-unused-vars */
  onNoteDetected?: (note: {
    name?: string;
    value?: number;
    cents?: number;
    octave?: number;
    frequency?: number;
  }) => void;

  oscillator?: OscillatorNode | null;

  constructor() {
    this.middleA = 440;
    this.semitone = 69;
    this.bufferSize = 4096;
    this.noteStrings = [
      "C",
      "C♯",
      "D",
      "D♯",
      "E",
      "F",
      "F♯",
      "G",
      "G♯",
      "A",
      "A♯",
      "B",
    ];

    this.initGetUserMedia();
  }

  initGetUserMedia() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!window.AudioContext) {
      return alert("AudioContext not supported");
    }
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    // if (navigator.mediaDevices === undefined) {
    //   navigator.mediaDevices = {};
    // }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints: {
        video?: boolean;
        audio?: boolean;
      }) {
        // First get ahold of the legacy getUserMedia, if present
        const getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          alert("getUserMedia is not implemented in this browser");
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
  }

  startRecord() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        window.streamReference = stream;

        if (self.analyser) {
          self.audioContext
            ?.createMediaStreamSource(stream)
            ?.connect(self.analyser);
        }

        if (self.scriptProcessor) {
          self.analyser?.connect(self.scriptProcessor);
        }
        if (self.audioContext) {
          self.scriptProcessor?.connect(self.audioContext.destination);
        }

        self.audioProc = (event) => {
          const frequency = self.pitchDetector.do(
            event.inputBuffer.getChannelData(0),
          );
          if (frequency && self.onNoteDetected) {
            const note = self.getNote(frequency);
            self.onNoteDetected({
              name: self.noteStrings[note % 12],
              value: note,
              cents: self.getCents(frequency, note),
              octave: parseInt(`${note / 12}`, 10) - 1,
              frequency,
            });
          }
        };

        self.scriptProcessor?.addEventListener("audioprocess", self.audioProc);
      })
      .catch((error) => {
        alert(`${error.name}: ${error.message}`);
      });
  }

  init() {
    this.audioContext = new window.AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.scriptProcessor = this.audioContext.createScriptProcessor(
      this.bufferSize,
      1,
      1,
    );

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    window.Aubio().then((aubio: any) => {
      self.pitchDetector = new aubio.Pitch(
        "default",
        self.bufferSize,
        1,
        self.audioContext?.sampleRate,
      );
      self.startRecord();
    });
  }

  getNote(frequency: number) {
    const note = 12 * (Math.log(frequency / this.middleA) / Math.log(2));
    return Math.round(note) + this.semitone;
  }

  getStandardFrequency(note: number) {
    return this.middleA * 2 ** ((note - this.semitone) / 12);
  }

  getCents(frequency: number, note: number) {
    return Math.floor(
      (1200 * Math.log(frequency / this.getStandardFrequency(note))) /
        Math.log(2),
    );
  }

  play(frequency: number) {
    if (!this.oscillator) {
      this.oscillator = this.audioContext?.createOscillator();
      if (this.audioContext) {
        this.oscillator?.connect(this.audioContext.destination);
      }
      this.oscillator?.start();
    }
    if (this.oscillator) {
      this.oscillator.frequency.value = frequency;
    }
  }

  stop() {
    this.oscillator?.stop();
    this.oscillator = null;
  }
}

export default Tuner;
