import React, { SetStateAction } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomSong } from "redux/songwriting/songwriting_slice";
import { isUnlimitedSongwritingUser } from "redux/songwriting/songwriting_selectors";

const OPTIONS = [{ optionName: "Delete Song" }, { optionName: "Rename" }];

interface ISongCardOptions {
  menuAnchor: null | HTMLElement;
  closeOptionsHandler: () => void;
  song_id?: number;
  setIsEditingTitle: React.Dispatch<SetStateAction<boolean>>;
  isSharedSong: boolean;
}

export const SongCardOptions = ({
  menuAnchor,
  closeOptionsHandler,
  song_id,
  setIsEditingTitle,
  isSharedSong,
}: ISongCardOptions) => {
  const dispatch = useDispatch();
  const unlimitedSongwritingAccess = useSelector(isUnlimitedSongwritingUser);

  const clickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
    closeOptionsHandler();
    if (e.currentTarget.title === "Rename") {
      setIsEditingTitle(true);
    } else if (e.currentTarget.title === "Delete Song") {
      song_id && dispatch(deleteCustomSong(song_id));
    }
  };

  return (
    <Menu
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableScrollLock
      anchorEl={menuAnchor}
      keepMounted
      open={Boolean(menuAnchor)}
      onClose={closeOptionsHandler}
    >
      {OPTIONS.map(({ optionName }) => {
        if (!isSharedSong) {
          if (!unlimitedSongwritingAccess && optionName === "Delete Song") {
            return null;
          }
          return (
            <MenuItem
              title={optionName}
              key={optionName}
              onClick={clickHandler}
            >
              {optionName}
            </MenuItem>
          );
        }
        if (isSharedSong && optionName !== "Delete Song") {
          return (
            <MenuItem
              title={optionName}
              key={optionName}
              onClick={clickHandler}
            >
              {optionName}
            </MenuItem>
          );
        }
        return null;
      })}
    </Menu>
  );
};
