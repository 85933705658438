import React, { ReactElement } from "react";
import { Box } from "@material-ui/core";
import classNames from "classnames";
import { GUITAR, Instrument, UKULELE } from "constants/skills_constants";
import css from "../tuner_layout.module.scss";

interface ITabButtons {
  type: Instrument;
  title: string;
  currentLayout: Instrument;
  icon: ReactElement;
  setCurrentLayout: React.Dispatch<React.SetStateAction<Instrument>>;
  setActiveNoteFrequency: React.Dispatch<React.SetStateAction<string>>;
  setActiveNoteName: React.Dispatch<React.SetStateAction<string>>;
}

export const TabButton = ({
  type,
  title,
  currentLayout,
  icon,
  setCurrentLayout,
  setActiveNoteFrequency,
  setActiveNoteName,
}: ITabButtons) => {
  const changeTunerHandler = () => {
    switch (currentLayout) {
      case GUITAR:
        setCurrentLayout(UKULELE);
        break;
      case UKULELE:
        setCurrentLayout(GUITAR);
        break;
      default:
        break;
    }
    // Reset selected note
    setActiveNoteFrequency("");
    setActiveNoteName("");
  };
  return (
    <Box onClick={changeTunerHandler}>
      <Box
        className={classNames(css.icon_box, {
          [css.active]: currentLayout === type,
        })}
      >
        {icon}
      </Box>
      <span
        className={classNames({
          [css.span_active]: currentLayout === type,
        })}
      >
        {title}
      </span>
    </Box>
  );
};
