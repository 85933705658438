import React, { forwardRef } from "react";
import cn from "classnames";
import css from "./input.module.scss";

export interface IInputClasses {
  input?: string;
  wrapper?: string;
}

type IInput = Partial<HTMLInputElement> & {
  classes?: IInputClasses;
  value: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef<HTMLInputElement, IInput>((props, ref) => {
  const { classes, error, onChange, ...otherProps } = props;

  return (
    <div className={cn(css.wrapper, classes?.wrapper)}>
      {/* @ts-ignore */}
      <input
        onChange={onChange}
        {...otherProps}
        ref={ref}
        className={cn(css.input, classes?.input, {
          [css.input_error]: error,
        })}
      />
    </div>
  );
});
