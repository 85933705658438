/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIdOfStructureToFocus } from "redux/songwriting/songwriting_slice";
import { getIdOfStructureToFocus } from "redux/songwriting/songwriting_selectors";

const INDEX_OF_FIRST_INPUT = 1;

interface IUseChangeFocusInput {
  editableCellRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  songUnitId: number;
}

export const useChangeFocusInput = ({
  editableCellRefs,
  songUnitId,
}: IUseChangeFocusInput) => {
  const dispatch = useDispatch();
  const idOfStructureToFocus = useSelector(getIdOfStructureToFocus);

  const [indexOfInputToFocus, setIndexOfInputToFocus] = useState<number | null>(
    null,
  );
  const [positionOfValueToFocus, setPositionOfValueToFocus] = useState<{
    start: number;
    len: number;
  }>({ start: 0, len: 0 });

  useEffect(() => {
    if (indexOfInputToFocus !== null) {
      editableCellRefs.current[indexOfInputToFocus]?.focus();
      setIndexOfInputToFocus(null);

      editableCellRefs.current[indexOfInputToFocus]?.setSelectionRange(
        positionOfValueToFocus.start,
        positionOfValueToFocus.start + positionOfValueToFocus.len,
      );
    }
  }, [editableCellRefs, indexOfInputToFocus, positionOfValueToFocus]);

  useEffect(() => {
    if (idOfStructureToFocus === songUnitId) {
      editableCellRefs.current[INDEX_OF_FIRST_INPUT]?.focus();
      dispatch(setIdOfStructureToFocus(null));
    }
  }, [dispatch, editableCellRefs, idOfStructureToFocus, songUnitId]);

  return {
    setIndexOfInputToFocus,
    setPositionOfValueToFocus,
  };
};
