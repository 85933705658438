import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import { getOrgSongwritingSongById } from "redux/songwriting/songwriting_selectors";
import SongbuilderPdf from "components/songwriting/songbuilder/songbuilder_main_section/songbuilder_pdf/songbuilder_pdf";
import GetAppIcon from "@material-ui/icons/GetApp";

import { setActiveOrgSongId } from "redux/songwriting/songwriting_slice";
import { openViewSongModal } from "redux/ui/modal/modal_slice";

import css from "./assessment_song.module.scss";

interface IAssessmentSong {
  song: number;
  protectedSong: boolean;
}

export const AssessmentSong: React.FC<IAssessmentSong> = ({
  song,
  protectedSong,
}) => {
  const orgSong = useSelector(getOrgSongwritingSongById(song));
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(setActiveOrgSongId(orgSong?.id));
    dispatch(openViewSongModal());
  };

  return (
    <div className={css.view_song_container}>
      <div />
      <button type="button" onClick={openModal}>
        <div>View Song</div>
      </button>
      {orgSong && (
        <PDFDownloadLink
          document={
            <Document>
              <SongbuilderPdf
                customSong={orgSong}
                protectedStatus={protectedSong}
              />
            </Document>
          }
          fileName={orgSong.song_name}
        >
          <GetAppIcon />
        </PDFDownloadLink>
      )}
    </div>
  );
};
