import { IUserLesson } from "redux/entities/user_lessons/user_lesson_api";
import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getUserLessonsObjSelector = (state: IState) =>
  state.entities.userLessons;

export const getUserLessonsArraySelector = createSelector(
  getUserLessonsObjSelector,
  (userLessonsObj) => Object.values(userLessonsObj),
);

export const isLessonFinished = (user_lesson: IUserLesson) => {
  return user_lesson.progress === 100;
};

export const getSongProgress = (user_lesson: IUserLesson) => {
  return user_lesson.progress;
};
