import { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useWindowWidth } from "hooks/use_width";
import {
  mondayNweeksEarlier,
  DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART,
  TODAY,
} from "constants/date_fns_constants";
import { format, differenceInWeeks, addWeeks, parse } from "date-fns";
import { IUserSession } from "api/user_session_api";
import { AxisProps } from "@nivo/axes";
import css from "./chart_responsive_bar.module.scss";

const theme = {
  axis: {
    ticks: {
      text: {
        fill: "#aab7c4",
      },
    },
    legend: {
      text: {
        fill: "#aab7c4",
        fontStyle: "italic",
      },
    },
  },
};

const CHART_MOBILE_WIDTH = 800;
const MAX_HEIGHT_MULTIPLIER = 1.5;

interface IChartResponsiveBar {
  minutesPerDay: IUserSession[];
}

interface IChartData {
  minutes: number;
  date: string;
  [key: string]: string | number;
}

export const ChartResponsiveBar = (props: IChartResponsiveBar) => {
  const { minutesPerDay } = props;

  useEffect(() => {
    setChartData(createDataForChart(minutesPerDay));
  }, [minutesPerDay]);

  const [chartData, setChartData] = useState<IChartData[]>([]);

  const calculateMonthRagne = () => {
    return `${format(
      mondayNweeksEarlier(DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART),
      "MMMM d",
    )} - ${format(TODAY, "MMMM d")}`;
  };

  const data = chartData;
  const month = calculateMonthRagne();
  const width = useWindowWidth();

  const axisLeft: AxisProps =
    width > CHART_MOBILE_WIDTH
      ? {
          tickSize: 0,
          legend: "Practice Time (min)",
          legendOffset: -50,
          legendPosition: "middle",
          tickValues: 7,
        }
      : {
          tickSize: 0,
          legend: "Practice Time (min)",
          legendOffset: 0,
          legendPosition: "middle",
          tickValues: [],
        };
  let maxUserMinutes = 0;

  if (data) {
    const maxMinutes = Math.max(...data.map((week) => week.minutes));
    maxUserMinutes = maxMinutes > 60 ? maxMinutes * MAX_HEIGHT_MULTIPLIER : 60;
  }

  return (
    <>
      <ResponsiveBar
        theme={theme}
        data={data}
        keys={["minutes"]}
        indexBy="date"
        margin={{
          top: 150,
          right: 0,
          bottom: 50,
          // eslint-disable-next-line no-magic-numbers
          left: width > CHART_MOBILE_WIDTH ? 100 : 50,
        }}
        padding={0.8}
        // eslint-disable-next-line no-magic-numbers
        borderRadius={width > CHART_MOBILE_WIDTH ? 14 : 4}
        // @ts-ignore
        colorBy="index"
        maxValue={maxUserMinutes}
        colors={[
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#76b3f3",
          "#200065",
        ]}
        animate={false}
        enableLabel={false}
        enableGridY={false}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          legend: "Week Starting",
          legendPosition: "middle",
          legendOffset: 40,
        }}
        axisLeft={axisLeft}
        axisTop={{
          tickValues: [],
          legendPosition: "middle",
          legendOffset: -60,
        }}
        isInteractive
        tooltip={(options) => {
          return (
            <div>
              <span style={{ color: options.color }}>{options.id}</span>:{" "}
              {options.value}
            </div>
          );
        }}
      />
      <div className={css.month}>{month}</div>
    </>
  );
};

function createDataForChart(minutesPerDayArray: IUserSession[]): IChartData[] {
  const firstMonday = mondayNweeksEarlier(
    DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART,
  );
  let currentMonday = firstMonday;

  // fill all weeks with 0 minutes
  const timePerWeek = new Array(DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART)
    .fill(null)
    .map((_e, i) => {
      const result = {
        minutes: 0,
        // ${Array(i).join(" ") to create unique key for each date
        date: `${currentMonday.getDate().toString()}${Array(i).join(" ")}`,
      };
      currentMonday = addWeeks(currentMonday, 1);
      return result;
    });

  if (!minutesPerDayArray || minutesPerDayArray.length === 0) {
    return timePerWeek;
  }

  const WEEKS_AMOUNT = DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART - 1;
  minutesPerDayArray.forEach((minutesPerDay) => {
    if (!minutesPerDay.minutes) {
      return;
    }
    const date = parse(minutesPerDay.day, "yyyy-M-d", new Date());
    date.setHours(1, 0, 0, 0);
    // calculate week nuber before current week (current week = timePerWeek[WEEKS_AMOUNT], WEEKS_AMOUNT week previous = time perWeek[0])
    let difInWeeks = differenceInWeeks(currentMonday, date);
    difInWeeks = difInWeeks > WEEKS_AMOUNT ? WEEKS_AMOUNT : difInWeeks; // if because of timezone last monday counted as sunday
    timePerWeek[WEEKS_AMOUNT - difInWeeks].minutes += minutesPerDay.minutes;
  });

  return timePerWeek;
}
