import { Box, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { RecordBar } from "components/songwriting/songbuilder/songbuilder_sidebar/record_bar/record_bar";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentSeedType } from "redux/song_seeds/song_seeds_slice";

export const AudioIdeas = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const goBack = () => {
    dispatch(setCurrentSeedType({ type: null }));
    history.push("/songwriting");
  };

  return (
    <Box
      width="90vw"
      height="90vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="auto"
      paddingTop={1}
    >
      <Box width="100%">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={goBack}
          style={{ cursor: "pointer" }}
          height="45px"
        >
          <IconButton aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#6b6868",
              fontFamily: "Raleway",
            }}
          >
            My Seeds
          </Typography>
        </Box>
      </Box>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: 24,
          color: "#190067",
        }}
      >
        Audio Ideas
      </Typography>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontWeight: 500,
          fontStyle: "italic",
          fontSize: 16,
          color: "#5F6367",
        }}
      >
        Your list of audio clips
      </Typography>
      <Box height="calc(100vh - 173px)" width="100vw">
        <RecordBar />
      </Box>
    </Box>
  );
};
