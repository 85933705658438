import axios from "axios";
import { ISkill } from "types/models/skill";
import { getNodeApiBaseUrl } from "./base_api";

export interface IChordText {
  front_text_correct: string;
  front_text_incorrect: string;
  id: number;
  instrument: string;
  skill_id: number;
  text_correct: string;
  text_incorrect: string;
  top_text_correct: string;
  top_text_incorrect: string;
}

export const chordsApi = {
  fetchChords() {
    const url = `${getNodeApiBaseUrl()}/skills/chords`;
    return axios.get<ISkill[]>(url);
  },
  fetchChordText({
    chordName,
    instrument,
  }: {
    chordName: string;
    instrument: string;
  }) {
    return axios.get<IChordText>(
      `${getNodeApiBaseUrl()}/skills/chord-diagram`,
      {
        params: {
          chordName,
          instrument,
        },
      },
    );
  },
};
