import { Switch } from "components/reusable/switch";
import css from "../more_info_slide.module.scss";

export const SwitchElem = (isSwitched: boolean, handleClick: () => void) => {
  return (
    <div className={css.switch}>
      <span
        className={css.switch_text}
        style={isSwitched ? { color: "#23c39d" } : {}}
      >
        Correct
      </span>
      <Switch isSwitched={isSwitched} setSwitch={handleClick} />
      <span
        className={css.switch_text}
        style={isSwitched ? {} : { color: "#f00" }}
      >
        Incorrect
      </span>
    </div>
  );
};
