import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  songSeedTab: 0,
  showSelectSongOverlay: false,
  addChordPosition: { songUnitId: 0, row: 0, column: 0 },
  addChordClickPosition: {
    songUnitId: 0,
    row: 0,
    column: 0,
    existingChord: null,
  },
  addChordMethod: "none",
  addChordDrawerOpen: false,
};

const songwritingMobile = createSlice({
  name: "songwritingMobile",
  initialState,
  reducers: {
    setSongSeedTab: (state, action) => {
      state.songSeedTab = action.payload;
    },
    setShowSelectSongOverlay: (state, action) => {
      state.showSelectSongOverlay = action.payload;
    },
    setAddChordPosition: (state, action) => {
      state.addChordPosition = action.payload;
      state.addChordMethod = "none";
    },
    setAddChordClickPosition: (state, action) => {
      state.addChordClickPosition = action.payload;
      state.addChordMethod = "click";
    },
    setAddChordMethod: (state, action) => {
      state.addChordMethod = action.payload;
    },
    setAddChordDrawerOpen: (state, action) => {
      state.addChordDrawerOpen = action.payload;
    },
  },
});

export const {
  reducer: songwritingMobileReducer,
  actions: {
    setSongSeedTab,
    setShowSelectSongOverlay,
    setAddChordPosition,
    setAddChordClickPosition,
    setAddChordMethod,
    setAddChordDrawerOpen,
  },
} = songwritingMobile;
