import { ILesson } from "api/lesson_api";
import { IUserLesson } from "redux/entities/user_lessons/user_lesson_api";

// Hot Fix! We are removing any lessons without songs in them.
export const filterLessonsWithoutSongs = (lessons: ILesson[]) => {
  return lessons.filter((lesson) => lesson.song);
};

export const sortBySongName = (lessons: ILesson[]) => {
  lessons.sort((a, b) => {
    if (a.song.song_name < b.song.song_name) {
      return -1;
    }
    if (a.song.song_name > b.song.song_name) {
      return 1;
    }
    return 0;
  });
};

export const putFreeSongsFirst = (lessons: ILesson[]) => {
  const result = [...lessons];

  result.sort((a, b) => {
    return Number(isFree(b)) - Number(isFree(a));
  });

  return result;
};

export const findUserLesson = (uls: IUserLesson, id: string) => {
  return Object.values(uls).find((ul) => ul.lessonId === parseInt(id, 10));
};

export const isFree = (lesson: ILesson) =>
  lesson.price === "0.0" || lesson.song.song_name === "Let it Be (Sample)";
