import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { AddStudentsBulkUpload } from "components/organization/add_students_bulk_tab/add_students_bulk_upload";
import { IStudents } from "components/organization/add_students_bulk_tab";
import { inviteStudents } from "redux/courses/courses_slice";
import { getCourses } from "redux/courses/courses_selectors";
import { useHistory } from "react-router";
import { HelpAddStudents } from "components/common/learn_new_button/help_add_students/help_add_students";
import { isActionRejectedHelper } from "helpers/is_action_rejected_helper";
import { AppDispatch } from "types/redux_";
import {
  CircleBackground,
  Container,
  Header,
  StretchContainer,
  StyledButtonBack,
  Top,
} from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { SignUpButton } from "../../reusable/button_sign_up";
import { stepsId } from "../../newSignUp";
import {
  StyledBackIcon,
  StyledButton,
  StepsContainer,
  Steps,
  Step,
  Upload,
} from "./add_students_bulk.styled";

import addStudent from "../../images/add-student-logo.svg";

const FORM_STUDENT_BULK_UPLOAD = "form_student_bulk_upload";

export const AddStudentsBulk = () => {
  const history = useHistory();
  const [students, setStudents] = useState<IStudents[]>([]);
  const stepDescription = [
    <span>
      Download this template{" "}
      <a href="https://moosiko-prod.s3-us-west-1.amazonaws.com/MyStudents.csv">
        MyStudents.CSV
      </a>
    </span>,
    <span>
      Add your students to the template file; First Name, Last Name, Email
    </span>,
    <span>Upload the CSV below by dragging/dropping OR click ‘select’</span>,
  ];
  const dispatch = useDispatch<AppDispatch>();

  // get id first class
  const courseId = useSelector(getCourses)[0].id;

  const handleBackStep = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.addStudents,
      }),
    );
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const result = await dispatch(inviteStudents({ students, courseId }));

    if (isActionRejectedHelper(result)) {
      return;
    }

    history.push("/");
  };

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={90} />
        <StyledButtonBack type="button" onClick={handleBackStep}>
          <StyledBackIcon />
        </StyledButtonBack>
      </Top>
      <Container>
        <Header>Add Students</Header>
        <LogoWithBackground src={addStudent} padding="28px" margin="2em" />
        <StepsContainer>
          <Steps>
            {stepDescription.map((step, ind) => (
              // eslint-disable-next-line
              <Step key={ind}>
                <CircleBackground>{ind + 1}</CircleBackground>
                {step}
              </Step>
            ))}
          </Steps>
        </StepsContainer>
        <Upload>
          <form onSubmit={handleSubmit} id={FORM_STUDENT_BULK_UPLOAD}>
            <AddStudentsBulkUpload
              students={students}
              setStudents={setStudents}
            />
          </form>
          <HelpAddStudents />
        </Upload>

        <StyledButton>
          <SignUpButton type="submit" formId={FORM_STUDENT_BULK_UPLOAD}>
            Add Students
          </SignUpButton>
        </StyledButton>
      </Container>
    </StretchContainer>
  );
};
