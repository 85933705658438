import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentCustomSong,
  getUsingMobileAppFlag,
} from "redux/songwriting/songwriting_selectors";
import {
  setAddChordClickPosition,
  setAddChordDrawerOpen,
} from "redux/songwriting_mobile/songwriting_mobile_slice";

import cn from "classnames";
import _ from "lodash";
import { useDroppable } from "@dnd-kit/core";

import { getLazyImage } from "components/songwriting/songbuilder/songbuilder_sidebar/build_song_bar/chords_list/chords_list";
import { SONGBUILDER_DATA } from "../../../songbuilder_data";
import { DraggableChord } from "../draggable_chord/draggable_chord";

import css from "./droppable_cell.module.scss";

interface IDroppableCell {
  cell: any;
  chordsData: {
    chord: string;
    row: string;
    column: string;
  }[];
  songUnitId: number;
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  padding?: boolean;
}

interface ChordInfo {
  title: string;
  // eslint-disable-next-line no-undef
  icon: JSX.Element | string;
}

export const DroppableCell = ({
  cell,
  chordsData,
  songUnitId,
  setChordAnchorEl,
  padding,
}: IDroppableCell) => {
  const dispatch = useDispatch();
  const [chordInfo, setChordInfo] = useState<ChordInfo | null>();
  const [isMounted, setIsMounted] = useState(true);

  const { setNodeRef } = useDroppable({
    id: `droppable/-${songUnitId}/-${cell.row.id}/-${cell.value}`,
    data: {
      accepts: SONGBUILDER_DATA.draggableChordsArray,
      structureId: songUnitId,
      rowId: cell.row.id,
      columnId: cell.value,
    },
  });

  const positionOfChord = _.findIndex(chordsData, {
    row: cell.row.id,
    column: cell.value,
  });

  const currentSong = useSelector(getCurrentCustomSong);

  const mobile = useSelector(getUsingMobileAppFlag);
  const cellClasses = cn(css.cell, { [css.mobile]: mobile }, css.editable_cell);
  const mobileClickHandler = () => {
    dispatch(setAddChordDrawerOpen(true));
    dispatch(
      setAddChordClickPosition({
        songUnitId,
        row: cell.row.id,
        column: cell.value,
        existingChord: null,
      }),
    );
  };

  const handleClickChord = (event: React.MouseEvent<HTMLDivElement>) => {
    setChordAnchorEl(event.currentTarget);
    dispatch(
      setAddChordClickPosition({
        songUnitId,
        row: cell.row.id,
        column: cell.value,
        existingChord: chordsData[positionOfChord].chord,
      }),
    );
  };

  useEffect(() => {
    if (
      chordsData[positionOfChord] &&
      chordsData[positionOfChord].chord &&
      isMounted
    ) {
      (async () => {
        setChordInfo(
          await getLazyImage(
            chordsData[positionOfChord].chord,
            currentSong.chord_icon_style,
          ),
        );
      })();
    }
  }, [chordsData, isMounted, positionOfChord, currentSong.chord_icon_style]);

  useEffect(() => {
    return () => {
      setChordInfo(null);
      setIsMounted(false);
    };
  }, []);

  return positionOfChord >= 0 && chordInfo ? (
    <td
      ref={setNodeRef}
      {...cell.getCellProps()}
      className={cellClasses}
      style={{
        height: `${currentSong.chord_icon_style === "Basic" ? "15px" : "45px"}`,
        paddingLeft: padding && "10px",
      }}
    >
      {!mobile ? (
        <DraggableChord
          icon={chordInfo.icon}
          title={chordInfo.title}
          row={cell.row.id}
          column={cell.value}
          songUnitId={songUnitId}
        />
      ) : (
        <div role="button" tabIndex={0} onClick={handleClickChord}>
          {chordInfo.icon}
        </div>
      )}
    </td>
  ) : (
    <td
      ref={setNodeRef}
      {...cell.getCellProps()}
      className={cn(css.cell, css.editable_cell)}
      style={{
        height: `${currentSong.chord_icon_style === "Basic" ? "15px" : "45px"}`,
        paddingLeft: padding && "10px",
      }}
    >
      <div
        onClick={() => {
          mobile && mobileClickHandler();
        }}
        role="button"
        tabIndex={0}
        aria-label="create a chord"
        style={{ height: "100%" }}
      />
    </td>
  );
};
