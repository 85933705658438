import classNames from "classnames";
import { TSongwritingAssessmentSortTypes } from "redux/songwriting_assessments/songwriting_constants";
import { TableHeaderItem } from "./table_header_item";
import css from "../../assessment/assessment_row/assessment_row.module.scss";

export const SongwritingAssessmentTableHeader = () => {
  return (
    <div className={classNames(css.container, css.header_row)}>
      <TableHeaderItem
        text="Student Name"
        sortValue={TSongwritingAssessmentSortTypes.STUDENT_NAME}
        classes={css.name}
      />
      <TableHeaderItem
        text="Song Name"
        sortValue={TSongwritingAssessmentSortTypes.SONG_NAME}
        classes={css.songName}
      />
      <TableHeaderItem
        text="Create Date"
        sortValue={TSongwritingAssessmentSortTypes.CREATE_DATE}
        classes={css.createdAt}
      />
      <TableHeaderItem
        text="View Song"
        sortValue={TSongwritingAssessmentSortTypes.VIEW_SONG}
        classes={css.viewSong}
      />
    </div>
  );
};
