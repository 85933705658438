import { useDispatch, useSelector } from "react-redux";
import { getSharedWithMeSongs } from "redux/songwriting/songwriting_selectors";
import { useEffect } from "react";
import { fetchSharedSongs } from "redux/songwriting/songwriting_slice";
import { ISharedSong } from "redux/songwriting/songwriting_interfaces";
import { MemoizedSongCard } from "../song_card/song_card";

export const SharedSongList = () => {
  const sharedSongs: ISharedSong[] | null = useSelector(getSharedWithMeSongs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSharedSongs());
  }, [dispatch]);

  return (
    <div>
      {!!sharedSongs && sharedSongs.length ? (
        sharedSongs.map((item) => (
          <MemoizedSongCard
            key={item.id}
            src={
              item.sharedSong.custom_song.custom_song_assets?.length
                ? item.sharedSong.custom_song.custom_song_assets[0]?.url
                : ""
            }
            sharedSong={item}
            isSharedSong
            song_data={null}
          />
        ))
      ) : (
        <div style={{ padding: "1rem" }}>
          <h3>Nobody has shared a song with you yet</h3>
        </div>
      )}
    </div>
  );
};
