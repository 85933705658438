import classnames from "classnames";
import { format } from "date-fns";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { useGetVideoWithAccess } from "hooks/use_get_video";
import { useDispatch, useSelector } from "react-redux";
import { getUserIdSelector } from "redux/auth/user_selectors";
import {
  fetchUserFeedback,
  updateUserFeedback,
} from "redux/feedback/feedback_slice";
import { IFeedback } from "redux/feedback/feedback_types";
import css from "./feedback_content.module.scss";

interface IFeedbackContent {
  feedback: IFeedback;
  openFeedbackDialog: (_?: string) => void;
}

export const FeedbackContent = ({
  feedback,
  openFeedbackDialog,
}: IFeedbackContent) => {
  const {
    encoded_video: feedbackURLWithoutAccess = null,
    id,
    video,
  } = feedback;

  const userId = useSelector(getUserIdSelector);
  const dispatch = useDispatch();
  const { encoded_video_url } = video || {};
  const feedbackURL = useGetVideoWithAccess(
    encoded_video_url || feedbackURLWithoutAccess,
  );

  if (!userId || !id || (!feedback.text && !feedbackURL)) {
    return null;
  }

  const handleFeedbackModalOpen = async (url?: string) => {
    openFeedbackDialog(url);
    await dispatch(updateUserFeedback({ id, viewed: true }));
    dispatch(fetchUserFeedback({ studentId: userId }));
  };

  return (
    <>
      <button
        onClick={() => handleFeedbackModalOpen(feedbackURL || undefined)}
        type="button"
        className={classnames(css.play_video_container, {
          [css.play_video_hint__unwatched]: !feedback.viewed,
        })}
      >
        <FeedbackIcon />
        <div
          className={classnames(css.play_video_text, {
            [css.play_video_hint__unwatched]: !feedback.viewed,
          })}
        >
          {feedback.text ? "View note" : " Play Video"}
        </div>
      </button>
      <div>
        {feedback.viewed ? "Viewed on " : "Sent on "}
        {format(new Date(String(feedback?.updatedAt)), "MMM d, h:mma")}
      </div>
    </>
  );
};
