/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Container } from "@material-ui/core";
import { getActiveTab } from "redux/songwriting/songwriting_selectors";
import { setTabs } from "redux/songwriting/songwriting_slice";
import { TabSongContent } from "./tab_song_content/tab_song_content";
import css from "../songwriting.module.scss";

export enum TABS {
  OWNED = 0,
  SHARED = 1,
}

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ExistingSongs = () => {
  const dispatch = useDispatch();

  const activeTab = useSelector(getActiveTab);

  const handleChange = (e: React.ChangeEvent<{}>, newValue: TABS) => {
    dispatch(setTabs(newValue));
  };

  return (
    <Container maxWidth="lg">
      <Tabs
        TabIndicatorProps={{ style: { background: "#190067" } }}
        value={activeTab}
        onChange={handleChange}
      >
        <Tab
          label={<span className={css.tab_styles}>Owned by me</span>}
          {...a11yProps(TABS.OWNED)}
        />
        <Tab
          label={<span className={css.tab_styles}>Shared with me</span>}
          {...a11yProps(TABS.SHARED)}
        />
      </Tabs>
      <TabSongContent tabValue={activeTab} />
    </Container>
  );
};
