import { routerMiddleware } from "connected-react-router";
import { persistReducer, persistStore } from "redux-persist";
import { getPersistConfig } from "redux-deep-persist";
import storage from "redux-persist/lib/storage";
import { createReducer } from "redux/root_reducer";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import { history } from "../history_";
import { authMiddleware } from "./middlewares/auth_middleware";
import { historyMiddleware } from "./middlewares/history_middleware";
import { migrate, VERSION } from "./migrations";
import { skillsApi } from "./skills/skills_service";

const rootReducer = createReducer(history);

const persistConfig = getPersistConfig({
  key: "root",
  version: VERSION,
  storage,
  whitelist: ["songwriting", "auth"],
  migrate,
  rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// auth-middleware must come after your async middleware
const middlewares = getDefaultMiddleware({
  serializableCheck: false,
}).concat([
  routerMiddleware(history),
  authMiddleware,
  historyMiddleware,
  skillsApi.middleware,
]);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: middlewares,
});

export const persistor = persistStore(store);
export type IState = ReturnType<typeof rootReducer>;
