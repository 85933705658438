import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSelectedAssessments } from "redux/assessments/assessments_selectors";
import { IFeedback } from "redux/feedback/feedback_types";
import { IState } from "redux/store";
import {
  ICreateMediaRecord,
  IMediaRecord,
  mediaRecordingsApi,
} from "./media_recordings_api";
import { getMediaRecordingsRecords } from "./media_recordings_selectors";

export const deleteMediaRecords = createAsyncThunk<
  void,
  void,
  { state: IState }
>("mediaRecordings/deleteMediaRecords", async (_, { dispatch, getState }) => {
  const selectedMediaRecordIds = getSelectedAssessments(getState());
  const mediaRecords = getMediaRecordingsRecords(getState());

  const newMediaRecords = mediaRecords.filter(
    (mediaRecord) => !selectedMediaRecordIds.includes(mediaRecord.id),
  );
  dispatch(setMediaRecords(newMediaRecords));
  try {
    if (selectedMediaRecordIds.length > 1) {
      toast.success("Records deleted");
    } else {
      toast.success("Record deleted");
    }
    await mediaRecordingsApi.deleteRecordings(selectedMediaRecordIds);
  } catch (e) {
    console.error(e);
    toast.error("Cannot delete records");
    dispatch(setMediaRecords(mediaRecords));
  }
});

interface IFetchMediaRecordings {
  orgId: number;
}

export const fetchMediaRecordings = createAsyncThunk<
  void,
  IFetchMediaRecordings,
  { state: IState }
>("mediaRecordings/fetchMediaRecordings", async ({ orgId }, { dispatch }) => {
  try {
    const {
      data: { mediaRecords },
    } = await mediaRecordingsApi.fetchMediaRecordings(orgId);
    dispatch(setMediaRecords(mediaRecords));
  } catch (e) {
    toast.error((e as any).response.data.message);
  }
});

export const createMediaRecord = createAsyncThunk<
  void,
  ICreateMediaRecord,
  { state: IState }
>("mediaRecordings/createMediaRecord", async (mediaRecord) => {
  await mediaRecordingsApi.createMediaRecord(mediaRecord);
});

interface IFetchUserMediaRecordings {
  userId: number;
}

export const fetchUserMediaRecordings = createAsyncThunk<
  IMediaRecord[],
  IFetchUserMediaRecordings,
  { state: IState }
>("mediaRecordings/fetchUserMediaRecordings", async ({ userId }) => {
  const records = await mediaRecordingsApi.fetchUserMediaRecordings(userId);
  return records;
});

export enum TMediaRecordingStatuses {
  // eslint-disable-next-line no-unused-vars
  WAITING = "WAITING",
  // eslint-disable-next-line no-unused-vars
  RECORDING = "RECORDING",
  // eslint-disable-next-line no-unused-vars
  START = "START",
}
interface IMediaRecordingsState {
  records: IMediaRecord[];
  status: TMediaRecordingStatuses;
  id: null | number;
  userRecords: IMediaRecord[];
}

const initialState: IMediaRecordingsState = {
  // used to show assessments for instructor
  records: [],
  status: TMediaRecordingStatuses.WAITING,
  id: null,
  // used to display user videos in slides
  userRecords: [],
};

const mediaRecordingsSlice = createSlice({
  name: "mediaRecordings",
  initialState,
  reducers: {
    setMediaRecords: (state, action: PayloadAction<IMediaRecord[]>) => {
      state.records = action.payload;
    },
    setMediaRecordsStateWaiting: (state) => {
      state.status = TMediaRecordingStatuses.WAITING;
    },
    setMediaRecordsStateRecording: (state) => {
      state.status = TMediaRecordingStatuses.RECORDING;
    },
    setMediaRecordsStateStart: (state) => {
      state.status = TMediaRecordingStatuses.START;
    },
    updateMediaRecords: (
      state,
      { payload }: PayloadAction<Partial<IMediaRecord>>,
    ) => {
      const { records } = state;
      const updatedRecordIndex = records.findIndex(
        (record) => record.id === payload.id,
      );
      if (updatedRecordIndex === -1) {
        return state;
      }
      const newRecord = { ...records[updatedRecordIndex], ...payload };
      state.records[updatedRecordIndex] = newRecord;
    },
    updateMediaRecordFeedback: (
      state,
      { payload }: PayloadAction<Partial<IFeedback>>,
    ) => {
      const { records } = state;
      const updatedRecordIndex = records.findIndex(
        (record) => record.feedbacks[0]?.id === payload.id,
      );
      if (updatedRecordIndex === -1) {
        return state;
      }
      state.records[updatedRecordIndex].feedbacks[0].text = payload.text;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserMediaRecordings.fulfilled, (state, action) => {
      state.userRecords = action.payload;
    });
  },
});

export const {
  reducer: mediaRecordingsReducer,
  actions: {
    setMediaRecords,
    setMediaRecordsStateWaiting,
    setMediaRecordsStateRecording,
    setMediaRecordsStateStart,
    updateMediaRecords,
    updateMediaRecordFeedback,
  },
} = mediaRecordingsSlice;
