import cn from "classnames";
import css from "../chords_with_strumming.module.scss";

interface IImg {
  chord: string;
  strummingUrl: string;
  isOnlyOneChord: boolean;
}

export const ImgChordsWithStrumming = (props: IImg) => {
  const { chord, strummingUrl, isOnlyOneChord } = props;
  const bgColor = getBgColorFromChord(chord);
  return (
    <div
      className={cn(css.square, isOnlyOneChord ? css.square_one_chord : null)}
      style={{ backgroundColor: bgColor }}
    >
      <img
        src={strummingUrl}
        className={cn(css.img, isOnlyOneChord ? css.img_one_chord : null)}
        alt="strumming pattern"
      />
      <div className={css.chord}>{chord}</div>
    </div>
  );
};

const E_COLOR = "rgba(255,147,0,0.2)";
const Em_COLOR = "rgba(148,82,0,0.2)";
const Em7_COLOR = "rgba(148,82,0,0.2)";
const D_COLOR = "rgba(04,51,255,0.2)";
const Dsus_COLOR = "rgba(04,51,255,0.2)";
const D7_COLOR = "rgba(04,51,255,0.2)";
const Am_COLOR = "rgba(255,47,146,0.2)";
const C_COLOR = "rgba(83,27,147,0.2)";
const G_COLOR = "rgba(79,143,0,0.2)";
const A_COLOR = "rgba(148,11,0,0.2)";
const Asus_COLOR = "rgba(148,11,0,0.2)";
const A7_COLOR = "rgba(148,11,0,0.2)";
const G7_COLOR = "rgba(79,143,0,0.2)";
const Dm_COLOR = "rgba(156,129,253,0.2)";
const B7_COLOR = "rgba(82,185,126,0.2)";
const Bm_COLOR = "rgba(255,47,146,0.2)";
const C_hash_m_COLOR = "rgba(82,185,126,0.2)";
const F_hash_m_COLOR = "rgba(148,82,0,0.2)";
const F_COLOR = "rgba(67,177,209,0.2)";

const getBgColorFromChord = (chord: string) => {
  switch (chord) {
    case "C#m":
      return C_hash_m_COLOR;
    case "Bm":
      return Bm_COLOR;
    case "F":
      return F_COLOR;
    case "F#m":
      return F_hash_m_COLOR;
    case "E":
      return E_COLOR;
    case "Em7":
      return Em7_COLOR;
    case "Am":
      return Am_COLOR;
    case "Dsus":
      return Dsus_COLOR;
    case "D7":
      return D7_COLOR;
    case "Dm":
      return Dm_COLOR;
    case "G7":
      return G7_COLOR;
    case "B7":
      return B7_COLOR;
    case "A":
      return A_COLOR;
    case "Asus":
      return Asus_COLOR;
    case "A7":
      return A7_COLOR;
    case "D":
      return D_COLOR;
    case "C":
      return C_COLOR;
    case "G":
      return G_COLOR;
    case "Em":
      return Em_COLOR;
    default:
      return Bm_COLOR;
  }
};
