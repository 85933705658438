import { useSelector } from "react-redux";
import {
  getActiveCourses,
  getExpiredCourses,
} from "redux/courses/courses_selectors";

import { Typography } from "@material-ui/core";
import { CourseBlock } from "../course_block/course_block";
import css from "./course_blocks.module.scss";

export const CourseBlocks = () => {
  const activeCourses = useSelector(getActiveCourses);
  const expiredCourses = useSelector(getExpiredCourses);

  return (
    <>
      <Typography
        classes={{
          root: [css.classes_header, css.classes_header_active].join(" "),
        }}
      >
        Active Classes ({activeCourses.length || 0})
      </Typography>
      <ul className={css.courses_container}>
        {activeCourses.map((course) => (
          <CourseBlock
            showMenu
            key={`${course.name} ${course.createdAt}`}
            course={course}
          />
        ))}
      </ul>
      <Typography
        classes={{
          root: [css.classes_header, css.classes_header_expired].join(" "),
        }}
      >
        Expired Classes ({expiredCourses.length || 0})
      </Typography>
      <ul className={css.courses_container}>
        {expiredCourses.map((course) => (
          <CourseBlock
            showMenu
            key={`${course.name} ${course.createdAt}`}
            course={course}
          />
        ))}
      </ul>
    </>
  );
};
