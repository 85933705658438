import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAxiosHeaders } from "redux/auth/auth_helpers";
import { openModal } from "redux/ui/modal/modal_slice";
import { stepsId } from "components/sign_up/newSignUp";
import { isTeacherRole } from "redux/auth/roles/roles_helpers";
import { DEV } from "util/vars";
import { SIGNUP } from "./route_constants";

interface IResetPassword {
  path: string;
  exact?: boolean;
}

export const ResetPasswordRoute = ({ path, exact }: IResetPassword) => {
  const dispatch = useDispatch();
  const { hash } = window.location;
  const role = /\?role=(.*?)\?/.exec(hash);
  const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

  return (
    <Route
      path={path}
      exact={exact}
      component={() => {
        if (DEV) {
          console.info("LOCATION: ", window.location.href);
        }
        if (window.location.href.includes("uid")) {
          setAxiosHeaders();

          if (role) {
            if (isTeacherRole(role[1])) {
              return (
                <Redirect
                  to={{
                    pathname: SIGNUP,
                    state: {
                      initStep: stepsId.setPassword,
                    },
                  }}
                />
              );
            }
          }
          dispatch(openModal({ type: "resetPW" }));
        }
        return <Redirect to={mobile ? "/newMobileUser" : "/"} />;
      }}
    />
  );
};
