import { useDispatch } from "react-redux";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { useHistory } from "react-router";
import { Header, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { stepsId } from "../../newSignUp";
import { SignUpButton } from "../../reusable/button_sign_up";

import addStudent from "../../images/add-student-logo.svg";
import upload from "../../images/upload-logo.svg";
import addManually from "../../images/add-manually-logo.svg";
import GoogleClassRoomLogo from "../../images/google-classroom-logo.svg";
import MsTeamsLogo from "../../images/microsoft-teams-logo.svg";
import {
  StyledStretchContainer,
  StyledContainer,
  Cards,
  Card,
  Img,
  ImgContainer,
} from "./add_students.styled";

export const AddStudents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [copySuccess, setCopySuccess] = useState("Copy Link");
  const handleNextStep = (stepId: number) => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId,
      }),
    );
  };
  const handleSkip = () => {
    history.push("/");
  };

  return (
    <StyledStretchContainer>
      <Top>
        <TopLineProgress percent={81} />
      </Top>
      <StyledContainer>
        <Header>Add Students</Header>
        <LogoWithBackground src={addStudent} padding="28px" margin="2em" />
        <Cards>
          <Card
            onClick={() =>
              handleNextStep(stepsId.bulkImportFromExternalResources)
            }
            type="button"
          >
            {"Import Students\n"}
            <span>Google Classroom or MS Teams</span>
            <ImgContainer>
              <Img src={GoogleClassRoomLogo} alt="add-gclassroom-logo" />
              <Img src={MsTeamsLogo} alt="add-msteams" />
            </ImgContainer>
          </Card>
          <Card
            onClick={() => handleNextStep(stepsId.addStudentsBulk)}
            type="button"
          >
            {"Add Bulk\n"}
            <span>CSV Upload</span>
            <ImgContainer>
              <Img src={upload} alt="add-bulk-logo" />
            </ImgContainer>
          </Card>

          <Card
            onClick={() => handleNextStep(stepsId.addStudentsManually)}
            type="button"
          >
            {"Add Manually\n"}
            <span>Add 1 by 1</span>
            <ImgContainer>
              <Img src={addManually} alt="add-manually-logo" />
            </ImgContainer>
          </Card>
        </Cards>
        <StyledContainer>
          <SignUpButton type="button" onClick={handleSkip}>
            Skip step
          </SignUpButton>
        </StyledContainer>
      </StyledContainer>
    </StyledStretchContainer>
  );
};
