import { Dialog } from "@material-ui/core";
import { SongBuilder } from "./songbuilder";

export const SongBuilderModal = () => {
  return (
    <Dialog open fullScreen>
      <SongBuilder />
    </Dialog>
  );
};

// We cant call from inside
