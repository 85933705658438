import { ChangeEvent, FormEvent, useState } from "react";
import { Dialog, TextField } from "@material-ui/core";
import { updateCourseName } from "redux/courses/courses_slice";
import { useDispatch } from "react-redux";
import { ICourse } from "redux/courses/courses_api";
import css from "./change_course_name_dialog.module.scss";

interface IChangeCourseNameDialog {
  course: ICourse;
  renameCourseDialog: boolean;
  closeRenameCourseDialog: (_?: undefined) => void;
}

export const ChangeCourseNameDialog = ({
  course,
  renameCourseDialog,
  closeRenameCourseDialog,
}: IChangeCourseNameDialog) => {
  const dispatch = useDispatch();

  const [courseName, setCourseName] = useState("");

  const handleCourseNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCourseName(event.target.value);
  };
  const changeCourseName = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateCourseName({ courseId: course.id, name: courseName }));
  };

  const handleClose = () => {
    closeRenameCourseDialog();
  };

  return (
    <Dialog open={renameCourseDialog} onClose={handleClose}>
      <form className={css.rename_form} onSubmit={changeCourseName}>
        <h2 className={css.rename_header}>Rename Class</h2>
        <TextField
          label="Class name"
          variant="filled"
          inputProps={{ minLength: 3 }}
          value={courseName}
          onChange={handleCourseNameChange}
        />
        <button type="submit" className={css.rename_button}>
          Rename
        </button>
      </form>
    </Dialog>
  );
};
