import { useState } from "react";
import classnames from "classnames";
import { format } from "date-fns";
import { getSkillText } from "components/slides/slide_top_popover";
import { useGetVideoWithAccess } from "hooks/use_get_video";
import { UseDialog } from "hooks/use_dialog";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import css from "./feedback_row.module.scss";
import { FeedbackDialog } from "./feedback_dialog";
import { FeedbackContent } from "./feedback_content";
import { FeedbackVideo } from "./feedback_video";

interface IFeedbackRow {
  mediaRecordWithFeedback: IMediaRecord;
}

export const FeedBackRow = ({ mediaRecordWithFeedback }: IFeedbackRow) => {
  const {
    skill,
    encoded_video: assessmentURL,
    video,
    lesson: {
      song: { song_name: lessonName },
    },
    feedbacks,
    // TODO: in 1 year remove this firstVersionOfVideoURLNotTransformedOnS3, it used only for a couple first recorded videos
    URL: firstVersionOfVideoURLNotTransformedOnS3,
  } = mediaRecordWithFeedback;
  const feedback = feedbacks[0] || {};
  const { encoded_video_url } = video || {};
  let skillName = "Full Song";

  if (skill) {
    if (skill.skill?.name === "Strumming") {
      [skillName] = skill.name.split(" ");
    } else {
      skillName = skill.name;
    }
  }
  const studentVideoURLWithAccess = useGetVideoWithAccess(
    encoded_video_url ||
      assessmentURL ||
      firstVersionOfVideoURLNotTransformedOnS3,
  );

  const skillTypeName = skill?.skill?.name || "Song";
  const [videoURL, setVideoURL] = useState<string | null>(null);

  const onClose = () => setVideoURL(null);
  const onOpen = async (url?: string) => {
    if (url) {
      setVideoURL(url);
    }
  };
  const [feedbackDialog, closeFeedbackDialog, openFeedbackDialog] = UseDialog({
    onClose,
    onOpen,
  });
  const showStudentVideo = videoURL && videoURL === studentVideoURLWithAccess;
  const showNote = !showStudentVideo && feedback.text;

  return (
    <div className={css.container}>
      <div className={classnames(css.createdAt, css.row_item, css.createdAt)}>
        {format(new Date(mediaRecordWithFeedback.createdAt), "MMMM d, yyyy")}
      </div>
      <div className={classnames(css.skillTypeName, css.row_item)}>
        {skillTypeName}
      </div>
      <div className={classnames(css.skillName, css.row_item)}>
        {getSkillText(skillName)}
      </div>
      <div className={classnames(css.lessonName, css.row_item)}>
        {lessonName}
      </div>
      <div className={classnames(css.actions, css.row_item, css.play_video)}>
        <FeedbackVideo
          openFeedbackDialog={openFeedbackDialog}
          studentVideoURLWithAccess={studentVideoURLWithAccess || undefined}
          video={video}
        />
      </div>
      <div className={classnames(css.actions, css.row_item)}>
        {feedback.updatedAt &&
        (feedback.video?.encoded_video_url ||
          feedback.encoded_video ||
          feedback.text) ? (
          <FeedbackContent
            openFeedbackDialog={openFeedbackDialog}
            feedback={feedback}
          />
        ) : null}
      </div>
      <FeedbackDialog
        feedback={feedback}
        feedbackDialog={feedbackDialog}
        videoURL={videoURL}
        closeFeedbackDialog={closeFeedbackDialog}
        showNote={!!showNote}
      />
    </div>
  );
};
