import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISlide, lessonApi } from "api/lesson_api";
import { AppDispatch } from "types/redux_";

interface ISlidesState {
  slider: {
    lessonId: number | null;
    slideNumber: number | null;
  };
  slides: { [key: number]: ISlide };
}

const initialState: ISlidesState = {
  slider: {
    lessonId: null,
    slideNumber: null,
  },
  slides: {},
};

export const fetchSlides = (id: number) => (dispatch: AppDispatch) => {
  return lessonApi
    .fetchSlides(id)
    .then((payload) => {
      dispatch(receiveAllSlides(payload.data.slides));
    })
    .catch((err) => {
      console.error(err);
      dispatch(receiveSlideErr());
    });
};

const slidesSlice = createSlice({
  name: "slides",
  initialState,
  reducers: {
    receiveAllSlides: (
      state,
      action: PayloadAction<{ [key: number]: ISlide }>,
    ) => {
      state.slides = action.payload;
    },
    receiveSlideErr: () => {
      return initialState;
    },
    setCurrentLessonSlide: (
      state,
      action: PayloadAction<{ lessonId: number; slideNumber: number }>,
    ) => {
      state.slider = action.payload;
    },
  },
});

export const {
  reducer: slidesReducer,
  actions: { receiveAllSlides, receiveSlideErr, setCurrentLessonSlide },
} = slidesSlice;
