import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICarouselState {
  teachPluckModalOpen: boolean;
}

const initialState: ICarouselState = {
  teachPluckModalOpen: false,
};

const slidesSlice = createSlice({
  name: "slides",
  initialState,
  reducers: {
    setCarouselTeachPluckModalOpen: (state, action: PayloadAction<boolean>) => {
      state.teachPluckModalOpen = action.payload;
    },
  },
});

export const {
  reducer: carouselReducer,
  actions: { setCarouselTeachPluckModalOpen },
} = slidesSlice;
