/* eslint-disable react/no-multi-comp */
import { CircularProgress, Typography } from "@material-ui/core";
import { ExternalResource } from "components/organization/add_from_external_resources/add_from_external_resources";
import { LogoWithBackground } from "components/sign_up/reusable/background_image";
import React from "react";
import { useSelector } from "react-redux";
import {
  getClassroomCourses,
  getClassroomFetchingData,
} from "redux/import_students/google_classroom/google_classroom_students_selectors";
import {
  getMsTeamsFetchingData,
  getMsTeamsGroups,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_selectors";
import { IClassroomCourse } from "types/helpers/classroomTypes";
import { IMsTeamsGroup } from "types/helpers/msTeamsTypes";
import addStudent from "../../../images/add-student-logo.svg";
import { Header, Container } from "../../emotion.styles";
import { STEPS } from "../bulk_import_from_google_classroom/bulk_import_from_google_classroom";
import { ClassroomContainer } from "../bulk_import_from_google_classroom/bulk_import_from_google_classroom.styled";
import { ClassroomCard } from "./classroom_card/classroom_card";

interface IBulkImportClassroomList {
  type: ExternalResource;
  setCurrentStep: React.Dispatch<React.SetStateAction<STEPS>>;
}

export const BulkImportClassroomList = ({
  type,
  setCurrentStep,
}: IBulkImportClassroomList) => {
  const courses = useSelector(getClassroomCourses);
  const groups = useSelector(getMsTeamsGroups);
  const isFetchingMsTeamsData = useSelector(getMsTeamsFetchingData);
  const isFetchingClassroomData = useSelector(getClassroomFetchingData);

  return (
    <Container>
      <Header>
        {type === ExternalResource.GOOGLE_CLASSROOM
          ? "Select a Classroom"
          : "Select a Group"}
      </Header>
      <LogoWithBackground src={addStudent} padding="28px" margin="2em" />
      {type === ExternalResource.GOOGLE_CLASSROOM ? (
        <ClassroomContainer>
          {isFetchingClassroomData && (
            <span>
              <CircularProgress />
            </span>
          )}
          {!isFetchingClassroomData && courses.length < 0 && (
            <Typography>No courses found.</Typography>
          )}
          {courses &&
            courses.map((course: IClassroomCourse) => (
              <ClassroomCard
                type={ExternalResource.GOOGLE_CLASSROOM}
                setCurrentStep={setCurrentStep}
                key={course.id}
                courseId={course.id}
                title={course.name}
              />
            ))}
        </ClassroomContainer>
      ) : (
        <ClassroomContainer>
          {isFetchingMsTeamsData && (
            <span>
              <CircularProgress />
            </span>
          )}
          {!isFetchingMsTeamsData && groups.length < 0 && (
            <Typography>No groups found.</Typography>
          )}
          {groups &&
            groups.map((group: IMsTeamsGroup) => (
              <ClassroomCard
                type={ExternalResource.MS_TEAMS}
                setCurrentStep={setCurrentStep}
                key={group.id}
                courseId={group.id}
                title={group.displayName}
              />
            ))}
        </ClassroomContainer>
      )}
    </Container>
  );
};
