import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { SKILL_TYPES } from "components/songs/constants";
import classNames from "classnames";
import { IState } from "redux/store";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import css from "./select_skill_type_popover.module.scss";

const DEFAULT_VALUES = [false, false, false, false];

interface ISelectSkill {
  getRecordsFunction: (_: IState) => IMediaRecord[];
  setSkillFunction: (_: { active: boolean; filteredRecords: any[] }) => void;
  dropSelectionsFunction: (_: IState) => void;
}

export const SelectSkillTypePopover: React.FC<ISelectSkill> = ({
  getRecordsFunction,
  setSkillFunction,
  dropSelectionsFunction,
}) => {
  const BASIC_CHORDS = 0;
  const STRUMMING = 1;
  const BARRE_CHORDS = 2;
  const MELODY = 3;
  const [selectedSkillTypes, setSelectedSkillTypes] = useState(DEFAULT_VALUES);

  const dispatch = useDispatch();
  const records = useSelector(getRecordsFunction);

  const shouldDropSelection = useSelector(dropSelectionsFunction);
  useEffect(() => {
    setSelectedSkillTypes(DEFAULT_VALUES);
  }, [shouldDropSelection]);

  const [selectSkillTypePopover, setSelectSkillTypePopover] =
    useState<HTMLButtonElement | null>(null);
  const handleOpenSelectSkillType = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSelectSkillTypePopover(event.currentTarget);
  };
  const handleCloseSelectSkillType = () => {
    setSelectSkillTypePopover(null);
  };

  const toggleSkillType = (skillType: number) => {
    const newValue = [...selectedSkillTypes];
    newValue[skillType] = !newValue[skillType];
    setSelectedSkillTypes(newValue);
  };
  const active = selectedSkillTypes.some((e) => e);

  useEffect(() => {
    dispatch(
      setSkillFunction({
        active,
        filteredRecords: active
          ? records.filter((record) =>
              isSelectedSkillTypeNotSelected(record, selectedSkillTypes),
            )
          : [],
      }),
    );
  }, [active, dispatch, records, selectedSkillTypes, setSkillFunction]);

  return (
    <>
      <button
        onClick={handleOpenSelectSkillType}
        type="button"
        className={classNames(css.sort_button, {
          [css.sort_button_active]: active,
        })}
      >
        Skill type
      </button>
      <Popover
        anchorEl={selectSkillTypePopover}
        keepMounted
        open={Boolean(selectSkillTypePopover)}
        onClose={handleCloseSelectSkillType}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.filtering_container}>
          <div className={css.checkbox_container}>
            <Checkbox
              value={selectedSkillTypes[BASIC_CHORDS]}
              checked={selectedSkillTypes[BASIC_CHORDS]}
              className={css.checkbox}
              onClick={() => toggleSkillType(BASIC_CHORDS)}
            />
            <div>Basic chords</div>
          </div>
          <div className={css.checkbox_container}>
            <Checkbox
              value={selectedSkillTypes[STRUMMING]}
              className={css.checkbox}
              onClick={() => toggleSkillType(STRUMMING)}
              checked={selectedSkillTypes[STRUMMING]}
            />
            <div>Strumming</div>
          </div>
          <div className={css.checkbox_container}>
            <Checkbox
              value={selectedSkillTypes[BARRE_CHORDS]}
              className={css.checkbox}
              onClick={() => toggleSkillType(BARRE_CHORDS)}
              checked={selectedSkillTypes[BARRE_CHORDS]}
            />
            <div>Barre chords</div>
          </div>
          <div className={css.checkbox_container}>
            <Checkbox
              value={selectedSkillTypes[MELODY]}
              className={css.checkbox}
              onClick={() => toggleSkillType(MELODY)}
              checked={selectedSkillTypes[MELODY]}
            />
            <div>Melody</div>
          </div>
        </div>
      </Popover>
    </>
  );
};

const isSelectedSkillTypeNotSelected = (
  record: IMediaRecord,
  selectedSkillTypes: boolean[],
) => {
  if (!record.skill) {
    return false;
  }
  const skillTypeIndex = SKILL_TYPES.findIndex(
    (e) => e === record.skill.skill?.name,
  );
  return selectedSkillTypes[skillTypeIndex];
};
