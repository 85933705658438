/* eslint-disable no-magic-numbers */
export const CATEOGIRES_DATA = [
  {
    name: "Recommended for You",
    wide: true,
    seeAll: true,
  },
  {
    name: "Most Popular",
    wide: false,
    seeAll: false,
  },
  {
    name: "Classic Riffs",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [1047, 1092, 1045, 1044, 1049],
    },
  },
  {
    name: "Easy 3 Chord Songs",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [934, 989, 996, 947, 888],
      Ukulele: [1036, 853, 1080, 1089, 1081],
    },
  },
  {
    name: "Recently Added",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [],
    },
  },
  {
    name: "TikTok Hits",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [1003, 1006, 822, 1019, 1071],
    },
  },
  {
    name: "Learn to Solo",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [1157, 1164, 1153, 1161, 1168],
    },
  },
  {
    name: "Advanced Strumming Songs",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [1057, 836, 1066, 1099, 1067],
    },
  },
  {
    name: "Hip Hop",
    wide: false,
    seeAll: false,
    lessons: {
      Guitar: [1010, 818, 965, 824, 923],
    },
  },
  {
    name: "Disney Hits",
    wide: false,
    seeAll: false,
    lessons: {
      Ukulele: [1084, 1077, 1079, 1082, 1091],
    },
  },
  {
    name: "Fun Melodies",
    wide: false,
    seeAll: false,
    lessons: {
      Ukulele: [1135, 1140, 1136, 1147, 1145],
    },
  },
];
