import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { SignUpButton } from "./button_sign_up";

interface IFooterSignUp {
  type?: "submit" | "reset" | "button";
  children: ReactNode;
  handleClick?: () => void;
  textButton: string;
  form?: string;
  paddingButton?: string;
}
export const FooterSignUp = ({
  form,
  type = "button",
  paddingButton,
  children,
  handleClick,
  textButton,
}: IFooterSignUp) => {
  return (
    <Footer>
      <StyledButton>
        <SignUpButton
          onClick={handleClick}
          type={type}
          formId={form}
          padding={paddingButton}
        >
          {textButton}
        </SignUpButton>
      </StyledButton>
      <Description>{children}</Description>
    </Footer>
  );
};

const StyledButton = styled.div`
  margin: 1.3em 1em 1em;
`;

const Footer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: auto;
  text-align: center;
`;

const Description = styled.div`
  color: rgba(51, 51, 51, 0.42);
  padding-bottom: 1em;
  font-weight: 600;
`;
