import { useRef, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AudioPlayerContext,
  IAudioPlayerState,
} from "context/audio_player_context";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import css from "./audio.module.scss";

interface IAudioPlayer {
  src: string;
}

export const AudioPlayer = (props: IAudioPlayer) => {
  const { audioPlayerState } = useContext(AudioPlayerContext);

  const sendPreviousPlayer = (values: IAudioPlayerState) => {
    audioPlayerState.audioRef.current = values.audioRef.current;
    audioPlayerState.progressFilledRef.current =
      values.progressFilledRef.current;
    audioPlayerState.resetButtons = values.resetButtons;
  };

  const { src } = props;
  const [playIcon, setPlayIcon] = useState<IconProp>("play");
  const [loopIcon, setLoopIcon] = useState<string>("gray");

  const audioRef = useRef<HTMLAudioElement | null>(null); // audio element
  const progressFilledRef = useRef<HTMLDivElement | null>(null); // timeline

  const resetButtons = () => {
    setPlayIcon("play");
    setLoopIcon("gray");
  };

  const playerRefs = {
    audioRef,
    resetButtons,
    progressFilledRef,
  };

  const updateButton = () => {
    if (audioRef.current?.paused) {
      setPlayIcon("play");
    } else {
      setPlayIcon("pause");
    }
  };

  const togglePlay = () => {
    if (audioRef.current) {
      const method = audioRef.current.paused ? "play" : "pause";
      audioRef.current[method]();
      updateButton();
    }
  };
  // pause audio and reset the previous player's buttons and progress bar
  const handlePreviousPlayer = () => {
    if (!audioPlayerState.audioRef.current) {
      return;
    }
    audioPlayerState.audioRef.current.pause();
    audioPlayerState.audioRef.current.currentTime = 0;
    if (audioPlayerState.progressFilledRef.current) {
      audioPlayerState.progressFilledRef.current.style.flexBasis = "0";
    }
    audioPlayerState.resetButtons();
  };

  const handleCurrentPlayer = () => {
    if (audioPlayerState.audioRef === undefined) {
      togglePlay();
      // send playerRefs to redux
      sendPreviousPlayer(playerRefs);
      return;
    }
    const lastPlayer = audioPlayerState.audioRef.current;
    const currentPlayer = audioRef.current;
    if (lastPlayer !== currentPlayer) {
      handlePreviousPlayer();
      sendPreviousPlayer(playerRefs);
    }
    togglePlay();
  };

  // loops the audio of the current player
  const loopAudio = () => {
    if (audioRef.current) {
      audioRef.current.loop = !audioRef.current.loop;
      if (audioRef.current.loop) {
        setLoopIcon("green");
      } else {
        setLoopIcon("gray");
      }
    }
  };

  const resetPlayer = () => {
    if (progressFilledRef.current) {
      progressFilledRef.current.style.flexBasis = "";
      setPlayIcon("play");
    }
  };

  const handleProgress = () => {
    if (audioRef.current) {
      const percent =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
      if (percent === 100) {
        setPlayIcon("play");
      }
      if (progressFilledRef.current) {
        progressFilledRef.current.style.flexBasis = `${percent}%`;
      }
      if (audioRef.current.currentTime === audioRef.current.duration) {
        resetPlayer();
      }
    }
  };

  return (
    <div className={css.player}>
      <audio
        preload="none"
        src={src}
        ref={audioRef}
        onTimeUpdate={handleProgress}
      />
      <div>
        <FontAwesomeIcon
          size="lg"
          title="Toggle Play"
          className={css.icon}
          onClick={handleCurrentPlayer}
          icon={playIcon}
        />
      </div>
      <div className={css.progress}>
        <div className={css.progress_filled} ref={progressFilledRef} />
      </div>
      <FontAwesomeIcon
        size="lg"
        color={loopIcon}
        className={css.icon}
        onClick={loopAudio}
        icon="redo-alt"
      />
    </div>
  );
};
