import React from "react";
import { ROLES } from "redux/auth/roles/roles_consts";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentSeed,
  updateSongSeed,
} from "redux/song_seeds/song_seeds_slice";
import { getAuthSelector } from "redux/auth/user_selectors";
import { openModal } from "redux/ui/modal/modal_slice";
import { Box, MenuItem, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { StyledMenu } from "../styled_menu/styled_menu";

interface ISeedMenu {
  seedAnchorEl: null | HTMLElement;
  setSeedAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SeedMenu = ({ seedAnchorEl, setSeedAnchorEl }: ISeedMenu) => {
  const dispatch = useDispatch();

  const { role } = useSelector(getAuthSelector);

  const freeUserPlan = [
    ROLES.FREE_USER,
    ROLES.FREE_TEACHER_STUDENT,
    ROLES.FREE_TEACHER_ACCOUNT,
    ROLES.FREE_SONGWRITING,
  ].includes(role as any);

  const menuVerticalOffset = 75;

  const deleteSeed = () => {
    if (freeUserPlan) {
      dispatch(openModal({ type: "payForSubscription" }));
    } else {
      dispatch(updateSongSeed({ status: "Deleted" }));
    }
    setSeedAnchorEl(null);
    dispatch(setCurrentSeed({ id: null }));
  };

  return (
    <StyledMenu
      id="seedMenu"
      anchorEl={seedAnchorEl}
      keepMounted
      open={Boolean(seedAnchorEl)}
      onClose={() => {
        setSeedAnchorEl(null);
        dispatch(setCurrentSeed({ id: null }));
      }}
      vertical={menuVerticalOffset}
    >
      <MenuItem onClick={deleteSeed}>
        <Box display="flex" style={{ gap: "10px" }}>
          <DeleteOutlineIcon />
          <Typography>Delete</Typography>
        </Box>
      </MenuItem>
    </StyledMenu>
  );
};
