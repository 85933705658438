import { getUserLessonsArraySelector } from "redux/entities/user_lessons/user_lesson_selectors";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ILesson, ILessonSearchOptions, lessonApi } from "api/lesson_api";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import { IState } from "redux/store";
import { IStatisticLessons } from "../../../api/lesson_api";
import { getUserSelector } from "../../auth/user_selectors";
import {
  getLessonsIdArraySelector,
  getLessonsOffsetSelector,
} from "./lesson_selectors";
import { getLacksLessonsId } from "./lessons_helpers";

export const fetchLessonKeys = createAsyncThunk(
  "lessons/fetchLessonKeys",
  async () => {
    const {
      data: { lessonKeys },
    } = await lessonApi.fetchLessonKeys();
    return lessonKeys;
  },
);

export const fetchSongKeys = createAsyncThunk(
  "lessons/fetchSongKeys",
  async () => {
    const {
      data: { songKeys },
    } = await lessonApi.fetchSongKeys();
    return songKeys;
  },
);

export const fetchAmountLessons = createAsyncThunk(
  "lessons/fetchAmountLessons",
  async () => {
    const { data } = await lessonApi.fetchAmountLessons();
    return data;
  },
);

export const searchLessons = createAsyncThunk<
  {
    lessons: { [key: string]: ILesson };
    lackUserLessons?: { [key: string]: ILesson };
    hasNextPage: boolean;
  },
  { resetLessons?: boolean },
  { state: IState }
>("lessons/searchLessons", async ({ resetLessons = false }, { getState }) => {
  const user = getUserSelector(getState());

  const offset = resetLessons ? 0 : getLessonsOffsetSelector(getState()) + 1;
  const options: ILessonSearchOptions & { offset?: number } = {
    ...getSongFilters(getState()),
    offset,
    userId: user?.id,
  };

  const { data } = await lessonApi.fetchLessons(options);
  const userId = getState().auth.user?.id;
  const userLessonsId = getUserLessonsArraySelector(getState()).map(
    (userLesson) => userLesson.lesson_id,
  );

  const cacheLessonsId = getLessonsIdArraySelector(getState());

  const availableLessonsId = [
    ...cacheLessonsId,
    ...Object.values(data.lessons).map((lesson) => lesson.id),
  ];

  const lacksLessonsId = getLacksLessonsId({
    userLessonsId,
    availableLessonsId,
  });

  if (lacksLessonsId) {
    const { data: lackData } = await lessonApi.fetchLessonsById({
      id: lacksLessonsId,
      userId,
    });
    return { ...data, lackUserLessons: lackData.lessons };
  }

  return data;
});

export const getLessonsById = createAsyncThunk<
  { lessons: { [key: string]: ILesson } },
  { id: number[] },
  { state: IState }
>("lessons/getLessonsById", async ({ id }, { getState }) => {
  const userId = getState().auth.user?.id;
  const { data } = await lessonApi.fetchLessonsById({ id, userId });
  return { lessons: data.lessons };
});

interface ILessonsState {
  lessonsCache: { [key: string]: ILesson };
  filteredLessons: { [key: string]: ILesson };
  fetching: boolean;
  lessonKeys: string[];
  songKeys: string[];
  amountLessons: IStatisticLessons;
  offset: number;
  hasNextPage: boolean;
}

const initialState: ILessonsState = {
  lessonsCache: {},
  filteredLessons: {},
  fetching: false,
  lessonKeys: [],
  songKeys: [],
  amountLessons: {
    guitar: {},
    ukulele: {},
  },
  offset: 0,
  hasNextPage: false,
};

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLessonKeys.fulfilled, (state, action) => {
      state.lessonKeys = action.payload;
    });
    builder.addCase(fetchSongKeys.fulfilled, (state, action) => {
      state.songKeys = action.payload;
    });

    builder.addCase(searchLessons.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(searchLessons.fulfilled, (state, action) => {
      const { resetLessons } = action.meta.arg;
      const {
        lessons = {},
        lackUserLessons = {},
        hasNextPage,
      } = action.payload;

      state.offset = resetLessons ? 0 : state.offset + 1;
      state.lessonsCache = {
        ...state.lessonsCache,
        ...lessons,
        ...lackUserLessons,
      };
      state.filteredLessons = resetLessons
        ? { ...lessons }
        : { ...state.filteredLessons, ...lessons };
      state.hasNextPage = hasNextPage;
      state.fetching = false;
    });
    builder.addCase(searchLessons.rejected, (state) => {
      state.fetching = false;
    });

    builder.addCase(getLessonsById.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(getLessonsById.fulfilled, (state, action) => {
      state.lessonsCache = { ...state.lessonsCache, ...action.payload.lessons };
      state.fetching = false;
    });
    builder.addCase(getLessonsById.rejected, (state) => {
      state.fetching = false;
    });
    builder.addCase(fetchAmountLessons.fulfilled, (state, { payload }) => {
      state.amountLessons = payload;
    });
  },
});

export const { reducer: lessonsReducer } = lessonsSlice;
