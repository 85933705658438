import { Break } from "./slide_types/break";
import Generic from "./slide_types/generic";
import { PartBegin } from "./slide_types/part_begin";
import PartEnd from "./slide_types/part_end";
import TeachPluck from "./slide_types/teach_pluck";
import Teach from "./slide_types/teach";
import { BarreChordIntro } from "./slide_types/BarreChordIntro";
import StrummingStatic from "./slide_types/strumming_static";
import { StrummingStatic2Audios } from "./slide_types/strumming_static_with_2_audios";
import { StrummingDynamic } from "./slide_types/strumming_dynamic";
import { Melody } from "./slide_types/Melody";

export const slideTypes = {
  break: Break,
  generic: Generic,
  part_begin: PartBegin,
  part_end: PartEnd,
  teach: Teach,
  teach_pluck: TeachPluck,
  strumming_static_1_audio: StrummingStatic,
  strumming_static_2_audios: StrummingStatic2Audios,
  strumming_dynamic: StrummingDynamic,
  barre_chord_intro: BarreChordIntro,
  melody: Melody,
};
