/* eslint-disable no-magic-numbers */
import { useDispatch } from "react-redux";
import { ISharedUserSong } from "redux/songwriting/songwriting_interfaces";
import { deleteInvitee } from "redux/songwriting/songwriting_slice";

import {
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { timeSince } from "helpers/timestampToSince";
import classes from "./song_invitees_list.module.scss";

interface ISongInviteesList {
  sharedSongs: ISharedUserSong[] | null;
}

export const SongInviteesList = ({ sharedSongs }: ISongInviteesList) => {
  const dispatch = useDispatch();

  const deleteInviteeHandler = (email: string, shared_song_id: number) => {
    dispatch(deleteInvitee({ email, shared_song_id }));
  };

  return (
    <List className={classes.invitees_list_wrapper}>
      {sharedSongs?.map((sharedSong) => {
        if (
          !sharedSong ||
          !("invitees" in sharedSong) ||
          sharedSong?.invitees?.length === 0
        ) {
          return (
            <Box
              key={sharedSong.custom_song_id}
              className={classes.invitees_list_wrapper}
            />
          );
        }
        const { invitees } = sharedSong;

        return invitees.map((invitee) => {
          const handleDeleteClick = () => {
            deleteInviteeHandler(invitee.recipient_email, sharedSong.id);
          };

          const hasOpened =
            (invitee.editedAt && `Edited ${timeSince(invitee.editedAt)} ago`) ||
            (invitee.viewedAt && `Viewed ${timeSince(invitee.viewedAt)} ago`);

          return (
            <ListItem key={invitee.recipient_email}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography>{invitee.recipient_email}</Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  xs={4}
                >
                  {invitee.invitation_status === "pending" ? (
                    <div className={classes.invite_sent}>Invite Sent</div>
                  ) : (
                    <Grid
                      item
                      style={{
                        flexBasis: hasOpened ? "80%" : "70%",
                        maxWidth: "100%",
                      }}
                      xs={5}
                    >
                      <Typography component="em">
                        {hasOpened || "Not viewed yet"}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  xs={3}
                >
                  <IconButton onClick={handleDeleteClick}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        });
      })}
    </List>
  );
};
