import { IState } from "redux/store";

export const getUserFetchedSelector = (state: IState) =>
  state.ui.filters.userFetched;

export const getFetchedULsSelector = (state: IState) =>
  state.ui.filters.fetchedULs;

export const getUserClickedSelector = (state: IState) =>
  state.ui.filters.userClicked;
