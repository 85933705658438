import { ILesson } from "api/lesson_api";
import cn from "classnames";
import { eventHandler, usePopover } from "hooks/use_popover";
import React from "react";
import { useHistory } from "react-router-dom";

import { Popover } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import InfoIcon from "@material-ui/icons/Info";

import { calculateCapo } from "../../../util/calculateCapo";
import css from "./song_sheet_top_bar_desktop.module.scss";
import { ListenOnSpotify } from "../listen_on_spotify";

interface ISongSheetDesktop {
  handleClickSong: eventHandler;
  slider: React.ReactNode;
  lesson: ILesson;
  disableScroll: () => void;
  scrollControls: React.ReactNode;
  metronomeControls: React.ReactNode;
  tempo: number;
  handleOpenChords: () => void;
}

export const SongSheetDesktop = ({
  handleClickSong,
  slider,
  lesson,
  disableScroll,
  scrollControls,
  metronomeControls,
  tempo,
  handleOpenChords,
}: ISongSheetDesktop) => {
  const { alpha_tab_code, lesson_key } = lesson;
  const { song_name, artist, song_key, spotify_id } = lesson.song;
  const capo = calculateCapo(lesson_key, song_key);

  const { goBack } = useHistory();

  const [anchorElementSettings, handleClickSettings, handleCloseSettings] =
    usePopover({ onOpen: disableScroll });

  return (
    <section className={css.container}>
      <div className={css.song_info}>
        <button type="button" className={css.arrow_back} onClick={goBack}>
          <ArrowBackIcon />
        </button>
        <button
          type="button"
          onClick={handleClickSong}
          className={css.song_info_container}
        >
          <div className={css.song_info_main}>
            <div className={css.song_name}>{song_name}</div>
            <div className={css.artist}>{artist}</div>
            <div>{capo}</div>
          </div>
          <InfoIcon className={css.info_icon_styles} />
        </button>
      </div>
      {!alpha_tab_code && (
        <>
          <div className={css.song_settings_panel}>
            {scrollControls}
            <div className={css.settings}>
              <div className={cn(css.padding, css.lessonBox)}>
                <div className={css.main_font_styles}>{lesson_key}</div>
                <p className={css.subtitle_font_styles}>Lesson Key</p>
              </div>
              <div className={cn(css.padding, css.meterBox)}>
                <div className={css.main_font_styles}>
                  {lesson.song.time_signature}
                </div>
                <p className={css.subtitle_font_styles}>Meter</p>
              </div>
              <div className={cn(css.padding, css.tempo_styles)}>
                <button
                  className={css.slider_button}
                  type="button"
                  onClick={handleClickSettings}
                >
                  <div>
                    <div className={css.main_font_styles}>{tempo}</div>
                    <p className={css.subtitle_font_styles}>Tempo</p>
                  </div>
                  <div>
                    <ArrowDropDownIcon />
                  </div>
                </button>
              </div>
              <div
                className={cn({
                  [css.margin_right]: !spotify_id,
                  [css.metronome_styles]: true,
                  [css.padding]: true,
                })}
              >
                <div className={css.main_font_styles}>{metronomeControls}</div>
                <p className={css.subtitle_font_styles}>Metronome</p>
              </div>
            </div>
            {spotify_id && <ListenOnSpotify spotifyId={spotify_id} />}
            <Popover
              disableScrollLock
              anchorEl={anchorElementSettings}
              keepMounted
              style={{ height: "150px" }}
              open={Boolean(anchorElementSettings)}
              onClose={handleCloseSettings}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {slider}
            </Popover>
          </div>
          <button
            className={css.openChordButton}
            type="button"
            onClick={handleOpenChords}
          >
            Chords
          </button>
        </>
      )}
    </section>
  );
};
