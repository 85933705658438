import { useHistory } from "react-router";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { useSelector } from "react-redux";
import { ROLES } from "redux/auth/roles/roles_consts";
import { UPGRADE } from "routes/route_constants";
import css from "./unlock.module.scss";
import image from "../user_skill/images/Group.png";

interface IUnlock {
  text: string;
}

export const Unlock = (props: IUnlock) => {
  const userRole = useSelector(getRoleSelector);
  const history = useHistory();
  const { text } = props;
  const handleClick = () => {
    if (userRole === ROLES.FREE_TEACHER_STUDENT) {
      return false;
    }
    history.push(UPGRADE);
  };

  return (
    <div className={css.container}>
      <img className={css.image} src={image} alt="" />
      <div className={css.text}>{text}</div>
      <button onClick={handleClick} className={css.button} type="button">
        Unlock
      </button>
    </div>
  );
};
