import cn from "classnames";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";

import { Checkbox } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import css from "./metronome_sound_select.module.scss";
import BassDrum from "./soundAssets/Bass_Drum.wav";
import Clap from "./soundAssets/Clap.wav";
import HiHat from "./soundAssets/Hi-Hat.wav";
import SnareDrum from "./soundAssets/Snare_Drum.wav";
import tick from "./soundAssets/tick.wav";

const notSelected = "None";

const sounds = [
  { name: "Tick", value: tick },
  { name: "Snare Drum", value: SnareDrum },
  { name: "Hi Hat", value: HiHat },
  { name: "Clap", value: Clap },
  { name: "Bass Drum", value: BassDrum },
];
const desktopSounds = [{ name: "None", value: notSelected }, ...sounds];
interface ISoundSelect {
  mobile?: boolean;
  refreshMetronome?: () => void;
  turnOffMetronome?: () => void;
}

export const SoundSelect = forwardRef<HTMLAudioElement, ISoundSelect>(
  ({ mobile, turnOffMetronome, refreshMetronome }, audioRef) => {
    const [selectedSound, setSelectedSound] = useState<string>(
      mobile ? HiHat : notSelected,
    );

    const changeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
      setSelectedSound(event.target.value as string);
      if (refreshMetronome) {
        refreshMetronome();
      }
      if (event.target.value === notSelected && turnOffMetronome) {
        turnOffMetronome();
      }
    };

    useEffect(() => {
      (
        audioRef as ForwardedRef<HTMLAudioElement> & {
          current: { load: any };
        }
      ).current.load();
    }, [audioRef, selectedSound]);

    return (
      <FormControl>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          inputProps={{
            classes: {
              root: cn(css.border, css.root),
              icon: css.icon,
            },
          }}
          onClose={() => {
            setTimeout(() => {
              (document.activeElement as HTMLElement).blur(); // Remove focus from select, setTimeout used to allow focus to return to input
            }, 0);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedSound}
          onChange={changeHandler}
          className={css.select}
          renderValue={(value) => {
            return (mobile ? sounds : desktopSounds).find(
              (sound) => sound.value === value,
            )?.name;
          }}
        >
          {(mobile ? sounds : desktopSounds).map((sound) => (
            <MenuItem key={sound.name} value={sound.value}>
              <Checkbox
                checked={sound.value === selectedSound}
                color="primary"
              />
              {sound.name}
            </MenuItem>
          ))}
        </Select>
        <audio controls ref={audioRef} className={css.audio}>
          <source
            src={selectedSound === notSelected ? undefined : selectedSound}
            type="audio/wav"
          />
          Your browser does not support the audio element.
        </audio>
      </FormControl>
    );
  },
);
