import "./style.scss";

import classNames from "classnames";
import { Instrument } from "constants/skills_constants";
import { FreqMatch, FreqMatchValue } from "constants/tuner_constants";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserClickedSelector } from "redux/ui/filters/filters_selectors";
import { openModal } from "redux/ui/modal/modal_slice";

import Application from "./app";

// tuner adapted from: https://github.com/qiuxiang/tuner
interface ITuner {
  frequency: string;
  selectedNote: string;
  currentLayout: Instrument;
}
export const Tuner = ({ frequency, selectedNote, currentLayout }: ITuner) => {
  const dispatch = useDispatch();
  const userClicked = useSelector(getUserClickedSelector);
  const [freqMatch, setFreqMatch] = useState<FreqMatch | null>(null);

  useEffect(() => {
    // Force update when layout changes
    if (!frequency) {
      setFreqMatch(null);
      return;
    }
    const observedFrequency = document.querySelector(".frequency")?.children[0];

    const observer = new MutationObserver((mutationList, _observer) => {
      const freq = mutationList[0].addedNodes["0"].nodeValue;

      // +-2 Hz error tolerance
      const ERROR_THRESHOLD = 2;
      if (freq) {
        if (parseFloat(freq) < parseFloat(frequency) - ERROR_THRESHOLD) {
          setFreqMatch(FreqMatchValue.TOO_LOW);
        } else if (parseFloat(freq) > parseFloat(frequency) + ERROR_THRESHOLD) {
          setFreqMatch(FreqMatchValue.TOO_HIGH);
        } else {
          setFreqMatch(FreqMatchValue.OK);
        }
      }
    });
    if (observedFrequency) {
      observer.observe(observedFrequency, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: false,
      });
    }
  }, [frequency, currentLayout, freqMatch]);

  const app = useRef<(Application & { tuner: { audioProc?: any } }) | null>(
    null,
  );

  useEffect(() => {
    app.current = new Application();

    if (userClicked) {
      app.current.start();
    } else {
      dispatch(openModal({ type: "runTuner" }));
    }

    return () => {
      if (app?.current?.animationFrame) {
        cancelAnimationFrame(app.current.animationFrame);
      }

      if (app?.current?.tuner.scriptProcessor) {
        app.current.tuner.scriptProcessor.removeEventListener(
          "audioprocess",
          app?.current?.tuner.audioProc,
        );
      }

      // This removes the red recording dot (https://github.com/streamproc/MediaStreamRecorder/issues/76)
      if (window.streamReference) {
        window.streamReference
          .getAudioTracks()
          .forEach((track) => track.stop());
        window.streamReference = null;
      }
    };
  }, [userClicked, dispatch]);

  return (
    <div className="tuner">
      <canvas className="frequency-bars" />
      <div className="meter">
        <div className="meter-dot" />
        <div
          className={classNames("meter-pointer", {
            "meter-pointer-high": freqMatch === FreqMatchValue.TOO_HIGH,
            "meter-pointer-low": freqMatch === FreqMatchValue.TOO_LOW,
            "meter-pointer-ok": freqMatch === FreqMatchValue.OK,
          })}
        />
      </div>
      <div className="notes">
        <div className="notes-list" />
        <div className="frequency">
          <span />
          <span>Hz</span>
          {frequency && (
            <p className="frequency-goal">
              Tune {selectedNote} string to {Math.round(Number(frequency))} Hz
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
