import { AddFromExternalResources } from "components/organization/add_from_external_resources/add_from_external_resources";
import { AddFromGoogleClassroom } from "components/organization/add_from_external_resources/add_from_google_classroom/add_from_google_classroom";
import { AddFromMicrosoftTeams } from "components/organization/add_from_external_resources/add_from_ms_teams/add_from_microsoft_teams";
import { AddStudentsBulkTab } from "components/organization/add_students_bulk_tab";
import { AddStudentsManuallyTab } from "components/organization/add_students_manually_tab";
import { IAddStudentsTab } from "..";
import { ImportOptions } from "../constants";

interface ISubTabs extends IAddStudentsTab {
  subTab: ImportOptions;
  courseId?: number;
}

export const SubTabs = ({
  subTab,
  courseId,
  setSelectedStudentsCount,
}: ISubTabs) => {
  const {
    ADD_BULK,
    ADD_FROM_EXTERNAL_RESOURCES,
    ADD_FROM_GOOGLE_CLASSROOM,
    ADD_FROM_MS_TEAMS,
    ADD_MANUALLY,
    NO_TAB,
  } = ImportOptions;

  switch (subTab) {
    case ADD_BULK: {
      return setSelectedStudentsCount ? (
        <AddStudentsBulkTab
          setSelectedStudentsCount={setSelectedStudentsCount}
        />
      ) : null;
    }
    case ADD_MANUALLY: {
      return setSelectedStudentsCount && courseId ? (
        <AddStudentsManuallyTab
          courseId={courseId}
          setSelectedStudentsCount={setSelectedStudentsCount}
        />
      ) : null;
    }
    case ADD_FROM_EXTERNAL_RESOURCES: {
      return <AddFromExternalResources />;
    }
    case ADD_FROM_GOOGLE_CLASSROOM: {
      return <AddFromGoogleClassroom />;
    }
    case ADD_FROM_MS_TEAMS: {
      return <AddFromMicrosoftTeams />;
    }
    case NO_TAB: {
      return null;
    }
    default: {
      console.error("No tab was found");
      return null;
    }
  }
};
