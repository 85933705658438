import classnames from "classnames";
import { dialogEvent } from "hooks/use_dialog";
import { IVideo } from "types/models/video";
// import { eventHandler } from "hooks/use_popover";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import css from "./feedback_video.module.scss";

interface IFeedbackVideo {
  openFeedbackDialog: (event: dialogEvent) => void;
  studentVideoURLWithAccess?: string;
  video: IVideo;
}
export const FeedbackVideo = ({
  openFeedbackDialog,
  studentVideoURLWithAccess,
  video,
}: IFeedbackVideo) => {
  return video?.encoded_video_url || studentVideoURLWithAccess ? (
    <button
      onClick={() => openFeedbackDialog(studentVideoURLWithAccess)}
      type="button"
      className={classnames(css.play_video_container, {})}
    >
      <PlayCircleOutlineIcon />
      <div className={classnames(css.play_video_text)}>Play Video</div>
    </button>
  ) : (
    <>Video is processing...</>
  );
};
