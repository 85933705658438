import css from "./skill_item.module.scss";

interface ISkillItem {
  text: string;
  learned: boolean;
}

export const SkillItem = ({ text, learned }: ISkillItem) => {
  return (
    <div
      className={
        learned ? css.progress_section_box_active : css.progress_section_box
      }
    >
      {text}
    </div>
  );
};
