import cn from "classnames";
import { ReactComponent as MoosikoBtn } from "img/moosiko_btn.svg";
import css from "../recommendation_modal.module.scss";
import { noop } from "../recommendation_modal_base";

interface IUploadMoreBtn {
  fetchMoreLessons: () => void;
  fetchingMore: boolean;
}

export const UploadMoreBtn = (props: IUploadMoreBtn) => {
  const { fetchingMore, fetchMoreLessons } = props;

  return (
    <div className={css.more_lessons}>
      <button
        type="button"
        className={cn(css.more_songs_btn, {
          [css.more_songs_fetching]: fetchingMore,
        })}
        onClick={fetchingMore ? noop : fetchMoreLessons}
      >
        <MoosikoBtn onClick={fetchingMore ? noop : fetchMoreLessons} />
        <div className={css.more_songs_loading_text}>
          {fetchingMore && "Loading"}
        </div>
      </button>

      <div className={css.more_songs_text}>Tap for more song lessons</div>
    </div>
  );
};
