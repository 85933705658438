/* eslint-disable no-unused-vars */

export enum ImportOptions {
  ADD_BULK = "ADD_BULK",
  ADD_MANUALLY = "ADD_MANUALLY",
  ADD_FROM_GOOGLE_CLASSROOM = "ADD_FROM_GOOGLE_CLASSROOM",
  ADD_FROM_MS_TEAMS = "ADD_FROM_MS_TEAMS",
  ADD_FROM_EXTERNAL_RESOURCES = "ADD_FROM_EXTERNAL_RESOURCES",
  NO_TAB = "NO_TAB",
}
