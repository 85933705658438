import { Dialog } from "@material-ui/core";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { closeClassModal } from "redux/ui/modal/modal_slice";
import css from "./lose_data_warning_modal.module.scss";

interface ILoseDataWarning {
  loseDataWarningDialog: boolean;
  closeLoseDataWarningDialog: () => void;
}

export const LoseDataWarning = ({
  loseDataWarningDialog,
  closeLoseDataWarningDialog,
}: ILoseDataWarning) => {
  const dispatch = useDispatch();

  const closeDialogs = () => {
    closeLoseDataWarningDialog();
    dispatch(closeClassModal());
  };

  return (
    <Dialog
      classes={{
        paper: css.sure_paper,
      }}
      onClose={closeLoseDataWarningDialog}
      open={loseDataWarningDialog}
    >
      <div className={css.sure_container}>
        <h3 className={css.sure_text}>
          Are you sure you want to close? You will loose the existing info you
          have entered and will have to enter it again.
        </h3>
        <div className={css.sure_buttons_container}>
          <button
            className={cn(css.cancel_button, css.sure_button)}
            onClick={closeDialogs}
            type="button"
          >
            Close
          </button>
          <button
            className={css.sure_button}
            onClick={closeLoseDataWarningDialog}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};
