import { RowType } from "redux/songwriting/songwriting_interfaces";

const CELL_COUNT = 50;

const droppableRowCells = (cellCount: number): RowType => {
  const result = {} as RowType;

  for (let i = 0; i < cellCount; i++) {
    result[`c${i}`] = i.toString();
  }

  return result;
};

const editableRowCells = (cellCount: number): RowType => {
  const result = {} as RowType;

  for (let i = 0; i < cellCount; i++) {
    if (i === 0) {
      result[`c${i}`] = "";
      // eslint-disable-next-line no-continue
      continue;
    }
    result[`c${i}`] = undefined;
  }

  return result;
};

export const SONGBUILDER_DATA = {
  songStructureArray: [
    "Intro",
    "Other",
    "Pre-Chorus",
    "Chorus / Refrain",
    "Post-Chorus",
    "Bridge",
    "Outro",
    "Instrumental",
    "Verse",
  ],
  draggableSongUnitsArray: [
    "draggable/-Intro",
    "draggable/-Outro",
    "draggable/-Verse",
    "draggable/-Instrumental",
    "draggable/-ChorusRefrain",
    "draggable/-Pre-Chorus",
    "draggable/-Post-Chorus",
    "draggable/-Other",
    "draggable/-Bridge",
  ],
  chordsArray: [
    "C",
    "F",
    "Am",
    "Dm",
    "Em",
    "G",
    "Db",
    "Gm",
    "Ab",
    "Bbm",
    "Ebm",
    "Fm",
    "D",
    "A",
    "Bm",
    "F#m",
    "Eb",
    "Bb",
    "Cm",
    "E",
    "B",
    "C#m",
    "G#m",
    "F#",
    "D#m",
  ],
  draggableChordsArray: [
    "draggable/-C",
    "draggable/-F",
    "draggable/-Am",
    "draggable/-Dm",
    "draggable/-Em",
    "draggable/-G",
    "draggable/-Db",
    "draggable/-Gm",
    "draggable/-Ab",
    "draggable/-Bbm",
    "draggable/-Ebm",
    "draggable/-Fm",
    "draggable/-D",
    "draggable/-A",
    "draggable/-Bm",
    "draggable/-F#m",
    "draggable/-Eb",
    "draggable/-Bb",
    "draggable/-Cm",
    "draggable/-E",
    "draggable/-B",
    "draggable/-C#m",
    "draggable/-G#m",
    "draggable/-F#",
    "draggable/-D#m",
  ],
  droppableRowData: {
    ...droppableRowCells(CELL_COUNT),
  },
  defaultTableData: [
    {
      ...droppableRowCells(CELL_COUNT),
    },
    {
      ...editableRowCells(CELL_COUNT),
    },
  ],
};
