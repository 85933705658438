import { getUserEmailSelector } from "redux/auth/user_selectors";
import { toast } from "react-toastify";
import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router";
import {
  getFirstOrganization,
  getOrganizationId,
} from "redux/organizations/organization_selectors";
import { organizationApi } from "redux/organizations/organization_api";
import {
  getStripeProduct,
  getProductQuantity,
} from "redux/entities/stripe/stripe_selectors";
import { updateOrganization } from "redux/org_management/org_management_slice";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { IRoles, ROLES } from "redux/auth/roles/roles_consts";
import { closeModal } from "redux/ui/modal/modal_slice";
import { disableButton } from "redux/ui/form/form_slice";
import { fetchPermissions } from "redux/auth/auth_slice";
import { CheckoutFormBase } from "./checkout_form_base";
import { useStripeOnSubmit } from "./stripe_checkout/checkout_form/stripe_onsubmit_hook";

interface ICheckoutSubscriptionForm {
  addAction?: () => void;
}

export const CheckoutSubscriptionForm = ({
  addAction,
}: ICheckoutSubscriptionForm) => {
  const orgId = useSelector(getOrganizationId);
  const dispatch = useDispatch();
  const product = useSelector(getStripeProduct);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const quantity = useSelector(getProductQuantity);

  const onChangeCallback = useCallback(() => {
    setError(null);
  }, []);
  const organization = useSelector(getFirstOrganization);
  const userRole = useSelector(getRoleSelector) as IRoles;
  useEffect(() => {
    if (userRole === ROLES.INDIVIDUAL || userRole === ROLES.PAID_SONGWRITING) {
      dispatch(closeModal());
    }
    if (userRole === ROLES.PAID_TEACHER_ACCOUNT && !organization) {
      history.push("/");
    }
  }, [userRole, dispatch, history, organization]);

  const studentEmail = useSelector(getUserEmailSelector);
  const priceId = useSelector(getStripeProduct).id;
  const onSuccess = () => {
    if (userRole === ROLES.FREE_TEACHER_ACCOUNT && orgId) {
      organizationApi.upgradeStudents(orgId);
      dispatch(updateOrganization({ id: orgId, max_seats: quantity }));
    }
    dispatch(fetchPermissions());
    toast.success("Payment was successfully completed.", {
      autoClose: 10000,
    });
    if (addAction) {
      addAction();
    }
  };

  let useStripeData = { priceId, onSuccess, userRole } as {
    priceId: string;
    onSuccess: (_: string) => void;
    userRole?: IRoles;
    quantity?: number;
  };

  if (quantity && quantity >= 1) {
    useStripeData = { priceId, onSuccess, userRole, quantity };
  }
  const onSubmit = useStripeOnSubmit(useStripeData);

  const handleSubmit = useCallback(
    async (error) => {
      if (error) {
        return;
      }
      studentEmail && (await onSubmit({ studentEmail }));
      await dispatch(fetchPermissions());
      await dispatch(disableButton(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, priceId, onSubmit, studentEmail],
  );

  return (
    <CheckoutFormBase
      price={product.price}
      onChange={onChangeCallback}
      onSubmit={handleSubmit}
      error={error}
    />
  );
};

// const stripePromise = loadStripe(stripePubKey);
