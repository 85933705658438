import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "redux/ui/modal/modal_slice";
import { deleteUserProfile } from "redux/auth/auth_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { Box, Button, Typography } from "@material-ui/core";
import { ModalContainer } from "components/modal/modal_container";

export const DeleteProfileModal = () => {
  const dispatch = useDispatch();
  const buttonStyle = { color: "#fff", width: "150px" };
  const typographyStyle = {
    color: "#190067",
    fontFamily: "Raleway",
    fontSize: "20px",
  };
  const user_id = useSelector(getUserIdSelector);

  return (
    <ModalContainer>
      <Typography
        style={{
          ...typographyStyle,
          fontWeight: 800,
          fontSize: "30px",
          padding: "30px 60px 0 60px",
          textAlign: "center",
        }}
      >
        Confirm Deleting Your Account
      </Typography>
      <Typography
        style={{
          ...typographyStyle,
          margin: "30px",
          textAlign: "center",
        }}
      >
        You will lose all your songs, seeds, and audio files after deleting your
        account. This cannot be undone.
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        <Box style={{ display: "flex", gap: "15px" }}>
          <Button
            style={{ ...buttonStyle, backgroundColor: "#6772e5" }}
            variant="contained"
            onClick={() => {
              user_id && dispatch(deleteUserProfile({ user_id }));
              dispatch(closeModal());
            }}
          >
            Yes Delete
          </Button>
          <Button
            style={buttonStyle}
            variant="contained"
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            No
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
};
