import { Component } from "react";
import Spinner from "./spinner";

const DEFAULT_DELAY = 200;

interface ILoader {
  delay?: number;
  spinner?: typeof Spinner;
  spinnerClass?: string;
}

export class Loader extends Component<ILoader> {
  state = {
    spin: false,
  };

  timerHandle: number | null = null;

  componentDidMount() {
    const { delay = DEFAULT_DELAY } = this.props;
    this.timerHandle = window.setTimeout(() => {
      this.setState({ spin: true });
      this.timerHandle = null;
    }, delay);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = null;
    }
  }

  render() {
    const { spinner: SpinnerComponent = Spinner, spinnerClass: style } =
      this.props;
    const { spin } = this.state;
    return spin ? <SpinnerComponent spinnerClass={style} /> : null;
  }
}
