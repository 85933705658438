import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalContainer } from "components/modal/modal_container";
import css from "components/modal/modal.module.scss";
import Button from "components/reusable/buttons/button";
import { purchaseLessonForFree } from "redux/free_user_lessons/free_user_lessons_slice";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { AppDispatch } from "types/redux_";
import css_stripe from "./stripe.module.scss";

const PayModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const modal = useSelector(getModalDataSelector);

  const [fetching, setFetching] = useState(false);

  const { song_name, artist } = modal.lesson
    ? modal.lesson.song
    : { song_name: null, artist: null };

  const lessonId = modal.lesson ? modal.lesson.id : null;

  const title = "Learn a New Song";
  const description = `${song_name} by ${artist}`;

  const onUseFreeSongClick = useCallback(async () => {
    setFetching(true);
    dispatch(purchaseLessonForFree({ lesson_id: lessonId })).finally(() => {
      setFetching(false);
    });
  }, [dispatch, lessonId]);

  const AccessLessonForm = (
    <div className={css_stripe.pay_btn_wrapper}>
      <Button
        classes={{ btn: css_stripe.pay_btn }}
        onClick={onUseFreeSongClick}
        showLoader={fetching}
      >
        Use 1 Free Credit
      </Button>
    </div>
  );

  return (
    <ModalContainer>
      <div className={css.title}>{title}</div>
      <div className={css.description}>{description}</div>

      {AccessLessonForm}
    </ModalContainer>
  );
};

export default withRouter(PayModal);
