import { useDispatch, useSelector } from "react-redux";
import {
  EnumDragAndDropActions,
  ICustomSongLyrics,
  SongContent,
} from "redux/songwriting/songwriting_interfaces";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import {
  getAddChordClickPosition,
  getAddChordMethod,
  getAddChordPosition,
} from "redux/songwriting_mobile/songwriting_mobile_selectors";
import { updateCustomSong } from "redux/songwriting/songwriting_slice";
import { SONG } from "redux/songwriting/songwriting_consts";
import { useSongUnitTableContent } from "./use_song_unit_table_content";
import { useChordsTable } from "./use_chords_table";

interface IAddHandler {
  item: SongContent;
}

export const useHandleMobileAdd = () => {
  const currentSong = useSelector(getCurrentCustomSong);
  const dispatch = useDispatch();
  const { collectAndSaveAllUnits } = useSongUnitTableContent();
  const { addNewChordToStructure, replaceChordInTable } = useChordsTable();
  const chordPosition = useSelector(getAddChordPosition);
  const clickPosition = useSelector(getAddChordClickPosition);
  const addChordMethod = useSelector(getAddChordMethod);

  const addMobileSongSectionHandler = ({ item }: IAddHandler) => {
    const newSongUnit: ICustomSongLyrics = {
      song_chords: "",
      song_lyrics: "",
      song_structure: item.title,
      position: currentSong.custom_song_lyrics.length,
    };

    dispatch(
      updateCustomSong({
        custom_song_lyrics: [
          {
            ...newSongUnit,
            type: EnumDragAndDropActions.createStructure,
          },
        ],
      }),
    );
    collectAndSaveAllUnits();
  };

  const addMobileSongChordHandler = ({ chordName }: { chordName: string }) => {
    const columnsInChordRow = 50;
    const columnsInLyricRow = SONG.MAX_LENGTH_EDITABLE_CELL;
    const positionToInsertNewChord =
      addChordMethod === "click"
        ? clickPosition.column
        : Math.floor(
            (chordPosition.column * columnsInChordRow) / columnsInLyricRow,
          ).toString();
    if (clickPosition.existingChord) {
      replaceChordInTable(
        {
          title: chordName,
          structureId: clickPosition.songUnitId,
          rowId: clickPosition.row,
          columnId: clickPosition.column,
        },
        {
          structureId: clickPosition.songUnitId,
          rowId: clickPosition.row,
          columnId: clickPosition.column,
        },
        true,
      );
    } else {
      addNewChordToStructure(
        chordName,
        addChordMethod === "click"
          ? clickPosition.songUnitId
          : chordPosition.songUnitId,
        // TODO: addNewChordToStructure() only works if you pass in a string, but it is defined to expect a number.
        //       Fix addNewChordToStructure() so that passing a number in works.
        // @ts-ignore
        addChordMethod === "click"
          ? clickPosition.row
          : chordPosition.row.toString(),
        positionToInsertNewChord,
      );
    }
  };

  return {
    addMobileSongSectionHandler,
    addMobileSongChordHandler,
  };
};
