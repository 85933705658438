import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepListActions, TStepId } from "./stepListSlice";
import { currentStepSelector } from "./stepListSelector";
import { TStepListIds } from "./stepListConstants";

export interface IStepListChild {
  nextStep?: () => void;
  prevStep?: () => void;
  // eslint-disable-next-line no-unused-vars
  setStep?: (id: TStepId) => void;
}

export interface IStepListComponent {
  stepListId: TStepListIds;
  items: {
    id: TStepId;
    Component: React.ReactElement;
  }[];
  setStartStep: TStepId;
}

export const StepList = ({
  stepListId,
  items,
  setStartStep,
}: IStepListComponent): React.ReactElement | null => {
  const dispatch = useDispatch();
  const currentStep = useSelector(currentStepSelector(stepListId));
  const initStepList = (items: { id: TStepId }[]) =>
    dispatch(stepListActions.initStepList({ stepListId, items, setStartStep }));

  useEffect(() => {
    const idList = items.map((i) => ({ id: i.id }));
    initStepList(idList);
    // eslint-disable-next-line
  }, [items]);

  const currentStepItem = items.find((i) => i.id === currentStep);

  if (!currentStepItem) {
    console.error(`StepContainer. Cand find item with id = ${currentStep}`);
    return null;
  }

  return currentStepItem.Component || null;
};
