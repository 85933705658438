import React from "react";
import { isEmpty } from "lodash";
import { store } from "redux/store";
import { ROLES, IRoles } from "redux/auth/roles/roles_consts";
import { setSubscriptionEnd } from "redux/auth/auth_slice";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { stripeApi } from "api/stripe_api";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { TStripeError } from "../types";
import { userApi } from "../../../../api/user_api";

interface IForm {
  studentEmail: string;
  parentEmail?: string;
  nameOnCard?: string;
}

export const useStripeOnSubmit = ({
  priceId,
  setProcessing,
  onSuccess,
  setCardError,
  cardEmpty,
  cardError,
  userRole,
  quantity,
}: {
  priceId: string;
  setProcessing?: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (_: string) => void;
  setCardError?: React.Dispatch<React.SetStateAction<TStripeError>>;
  cardEmpty?: boolean;
  cardError?: TStripeError;
  userRole?: IRoles;
  quantity?: number;
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const onSubmit = async ({
    studentEmail,
    parentEmail = "",
    nameOnCard = "",
  }: IForm) => {
    if (!stripe || !elements) {
      console.error(stripe, elements);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cardElement = elements.getElement(CardElement)!;

    if (cardEmpty && setCardError) {
      elements?.getElement("card")?.focus();
      setCardError({
        message: "Card is empty",
        type: "validation_error",
        code: "",
      });
      return;
    }

    if (cardError) {
      elements?.getElement("card")?.focus();
      return;
    }
    setProcessing && setProcessing(true);
    let createSubscriptionData = { priceId, studentEmail } as {
      priceId: string;
      studentEmail: string;
      quantity: number;
      orgId?: number;
    };
    if (quantity) {
      createSubscriptionData = {
        priceId,
        studentEmail,
        quantity,
      };
    }
    try {
      await stripeApi.createCustomer(studentEmail, parentEmail, nameOnCard);
      // TODO: refactor
      let clientSecret = "";
      const stripeFetch = async () => {
        if (
          userRole === ROLES.PAID_TEACHER_ACCOUNT ||
          userRole === ROLES.FREE_TEACHER_ACCOUNT
        ) {
          const result = await stripeApi.createInvoice(createSubscriptionData);
          clientSecret = result.client_secret;
        } else {
          const result = await stripeApi.createSubscription(
            createSubscriptionData,
          );
          clientSecret = result.clientSecret;
          store.dispatch(
            setSubscriptionEnd(result.subscription.current_period_end),
          );
        }
      };
      await stripeFetch();
      let confirmData;
      if (isEmpty(nameOnCard) || isEmpty(nameOnCard)) {
        confirmData = { card: cardElement };
      } else {
        confirmData = {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        };
      }
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: confirmData,
      });
      if (result.error) {
        toast.error(result.error.message, { autoClose: 10000 });
      } else {
        onSuccess(studentEmail);
        switch (userRole) {
          case ROLES.FREE_USER:
            userApi.updateUserRole({
              email: studentEmail,
              roleName: ROLES.INDIVIDUAL,
            });
            break;
          case ROLES.FREE_TEACHER_ACCOUNT:
            userApi.updateUserRole({
              email: studentEmail,
              roleName: ROLES.PAID_TEACHER_ACCOUNT,
            });
            break;
          case ROLES.FREE_SONGWRITING:
            userApi.updateUserRole({
              email: studentEmail,
              roleName: ROLES.PAID_SONGWRITING,
            });
            break;
          default:
            return;
        }
      }
    } catch (err) {
      handleStripeErrors(err as AxiosError);
    } finally {
      setProcessing && setProcessing(false);
    }
  };
  return onSubmit;
};

function handleStripeErrors(err: AxiosError) {
  if (err.response?.status === 403) {
    toast.error(
      "This email is not currently associated with a school account. Only emails currently in a school plan are eligible for the discounted student pricing.",
      { autoClose: 10000 },
    );
  } else if (err.response?.status === 404) {
    toast.error(
      "Looks like that email is not registered with Moosiko. Check spelling or try another",
      { autoClose: 10000 },
    );
  } else {
    toast.error(err, { autoClose: 10000 });
  }
  // else if (err.response?.status === 409) {
  //   toast.error("This account is already a paying Moosiko member.", {
  //     autoClose: 10000,
  //   });
  // }
}
