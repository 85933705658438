// todo:
import { useEffect, useState } from "react";
import { IUserSession, userTimeApi } from "api/user_session_api";
import {
  DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART,
  mondayNweeksEarlier,
  TODAY,
} from "constants/date_fns_constants";
import { IStudentWithSkills } from "types/models/student_with_skills";
import { ICourseStats } from "redux/courses/courses_api";
import { StudentRowPreview } from "./student_row_preview/student_row_preview";
import css from "./student_row.module.scss";

const THIRTY_SECONDS = 30000;

interface IStudentRow {
  student: IStudentWithSkills;
  studentCSVData: ICourseStats;
}

export const StudentRow = (props: IStudentRow) => {
  const { student, studentCSVData } = props;
  // eslint-disable-next-line no-unused-vars
  const { id, user_sessions: userSessionsFromCourseData } = student;

  // eslint-disable-next-line no-unused-vars
  const [onlineUserSessions, setOnlineUserSessions] = useState<IUserSession[]>(
    [],
  );
  // we update userTime locally
  // probably should redo whole course students logic
  useEffect(() => {
    const interval = setInterval(async () => {
      // todo: move to redux
      const { data: userTime } = await userTimeApi.getTime(
        id,
        mondayNweeksEarlier(DEFAULT_NUMBER_OF_WEEKS_FOR_TIME_CHART),
        TODAY,
      );
      setOnlineUserSessions(userTime);
    }, THIRTY_SECONDS);
    return () => clearInterval(interval);
  }, [id]);

  return (
    <div className={css.card_container}>
      <StudentRowPreview student={student} studentCsvData={studentCSVData} />
    </div>
  );
};
