import {
  ILessonWithUnknownSkills,
  recommendationApi,
} from "api/recommendation_api";

import { push } from "connected-react-router";
import { getLessonUrl } from "util/url_util";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IState } from "redux/store";
import { openChoosePlanModal, closeModal } from "../../ui/modal/modal_slice";
import { isLessonAvailableSelector } from "../lessons/lesson_selectors";
import { getRecommendedLessonsPaginationSelector } from "./recommendation_selectors";
import { getLessonsById } from "../lessons/lessons_slice";
import { getLacksLessonsId } from "../lessons/lessons_helpers";

interface IOnRecommendedSongClick {
  lessonId: number;
}

export const onRecommendedSongClick = createAsyncThunk<
  void,
  IOnRecommendedSongClick,
  { state: IState }
>(
  "recommendation/onRecommendedSongClick",
  async ({ lessonId }, { getState, dispatch }) => {
    dispatch(closeModal());

    const isLessonAvailable = isLessonAvailableSelector(lessonId)(getState());

    if (isLessonAvailable) {
      dispatch(push(getLessonUrl(lessonId)));
    } else {
      dispatch(openChoosePlanModal());
    }
  },
);

interface IFetchMoreRecommendedLessons {
  type?: string;
  skillName?: string;
  skillId?: number;
  instrument?: string;
}

export const fetchMoreRecommendedLessons = createAsyncThunk<
  { lessons: ILessonWithUnknownSkills[]; newStart: number },
  IFetchMoreRecommendedLessons,
  { state: IState }
>(
  "recommendation/fetchMoreRecommendedLessons",
  async ({ type, skillName, skillId, instrument }, { getState, dispatch }) => {
    const { start, limit } = getRecommendedLessonsPaginationSelector(
      getState(),
    );

    const newStart = start + limit;
    const {
      data: { lessons },
    } = await recommendationApi.fetchRecommendedLessons({
      limit,
      start: newStart,
      type,
      skillName,
      instrument,
      skillId,
    });

    const idLessons = lessons.map((lesson) => lesson.id);
    const lacksLessonsId = getLacksLessonsId({
      userLessonsId: idLessons,
      getState,
    });
    if (lacksLessonsId) {
      await dispatch(getLessonsById({ id: lacksLessonsId }));
    }
    return { lessons, newStart };
  },
);

interface IFetchRecommendedLessons {
  type?: string;
  skillName?: string;
  instrument?: string;
  limit?: number;
  skillId?: number;
}

export const fetchRecommendedLessons = createAsyncThunk<
  { lessons: ILessonWithUnknownSkills[]; lessonsCount: number },
  IFetchRecommendedLessons,
  { state: IState }
>(
  "recommendation/fetchRecommendedLessons",
  async (
    { type, skillName, limit, skillId, instrument },
    { getState, dispatch },
  ) => {
    const paginationOptions = getRecommendedLessonsPaginationSelector(
      getState(),
    );

    const {
      data: { lessons, lessonsCount },
    } = await recommendationApi.fetchRecommendedLessons({
      ...paginationOptions,
      limit,
      instrument,
      type,
      skillName,
      skillId,
    });
    const idLessons = lessons.map((lesson) => lesson.id);
    const lacksLessonsId = getLacksLessonsId({
      userLessonsId: idLessons,
      getState,
    });
    if (lacksLessonsId) {
      await dispatch(getLessonsById({ id: lacksLessonsId }));
    }
    return { lessons, lessonsCount };
  },
);

interface IRecommendationState {
  start: number;
  limit: number;
  fetching: boolean;
  fetchingMore: boolean;
  recommendedLessons: ILessonWithUnknownSkills[];
  lessonsCount: number;
}

const initialState: IRecommendationState = {
  start: 0,
  limit: 4,
  fetching: true,
  fetchingMore: false,
  recommendedLessons: [],
  lessonsCount: 0,
};

const recommendationSlice = createSlice({
  name: "recommendation",
  initialState,
  reducers: {
    resetRecommendedLessonsAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendedLessons.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchRecommendedLessons.fulfilled, (state, { payload }) => {
      const { lessons, lessonsCount } = payload;
      state.fetching = false;
      state.recommendedLessons = lessons;
      state.lessonsCount = lessonsCount;
    });
    builder.addCase(fetchRecommendedLessons.rejected, (state) => {
      state.fetching = false;
    });
    builder.addCase(fetchMoreRecommendedLessons.pending, (state) => {
      state.fetchingMore = true;
    });
    builder.addCase(
      fetchMoreRecommendedLessons.fulfilled,
      (state, { payload }) => {
        const { lessons, newStart } = payload;
        let updatedLessons = state.recommendedLessons.slice(0);
        updatedLessons = updatedLessons.concat(lessons);
        state.recommendedLessons = updatedLessons;
        state.fetchingMore = false;
        state.start = newStart;
      },
    );
    builder.addCase(fetchMoreRecommendedLessons.rejected, (state) => {
      state.fetchingMore = false;
    });
  },
});

export const {
  reducer: recommendationReducer,
  actions: { resetRecommendedLessonsAction },
} = recommendationSlice;
