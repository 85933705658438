const BASE_URL = "https://moosiko-prod.s3.us-west-1.amazonaws.com/Images";

const A7_FRONT_CORRECT = `${BASE_URL}/guitar/A7-front-corr.png`;
const A7_FRONT_INCORRECT = `${BASE_URL}/guitar/A7-front-incorr.png`;
const A7_TOP_CORRECT = `${BASE_URL}/guitar/A7-top-corr.png`;
const A7_TOP_INCORRECT = `${BASE_URL}/guitar/A7-top-incorr.png`;
const A7_DIAGRAM_CORRECT = `${BASE_URL}/guitar/A7 - Correct.svg`;
const A7_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/A7 - Incorrect.svg`;
const A7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/A7 - Top Level.svg`;

const A_FRONT_CORRECT = `${BASE_URL}/guitar/A-front-corr.png`;
const A_FRONT_INCORRECT = `${BASE_URL}/guitar/A-front-incorr.png`;
const A_TOP_CORRECT = `${BASE_URL}/guitar/A-top-corr.png`;
const A_TOP_INCORRECT = `${BASE_URL}/guitar/A-top-incorr.png`;
const A_DIAGRAM_CORRECT = `${BASE_URL}/guitar/A - Correct.svg`;
const A_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/A - Incorrect.svg`;
const A_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/A - Top Level.svg`;

const Am_FRONT_CORRECT = `${BASE_URL}/guitar/Am-front-corr.png`;
const Am_FRONT_INCORRECT = `${BASE_URL}/guitar/Am-front-incorr.png`;
const Am_TOP_CORRECT = `${BASE_URL}/guitar/Am-top-corr.png`;
const Am_TOP_INCORRECT = `${BASE_URL}/guitar/Am-top-incorr.png`;
const Am_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Am - Correct.svg`;
const Am_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Am - Incorrect.svg`;
const Am_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Am - Top Level.svg`;

const Asus_FRONT_CORRECT = `${BASE_URL}/guitar/Asus-front-corr.png`;
const Asus_FRONT_INCORRECT = `${BASE_URL}/guitar/Asus-front-incorr.png`;
const Asus_TOP_CORRECT = `${BASE_URL}/guitar/Asus-top-corr.png`;
const Asus_TOP_INCORRECT = `${BASE_URL}/guitar/Asus-top-incorr.png`;
const Asus_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Asus - Correct.svg`;
const Asus_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Asus - Incorrect.svg`;
const Asus_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Asus - Top Level.svg`;

const C_FRONT_CORRECT = `${BASE_URL}/guitar/C-front-corr.png`;
const C_FRONT_INCORRECT = `${BASE_URL}/guitar/C-front-incorr.png`;
const C_TOP_CORRECT = `${BASE_URL}/guitar/C-top-corr.png`;
const C_TOP_INCORRECT = `${BASE_URL}/guitar/C-top-incorr.png`;
const C_DIAGRAM_CORRECT = `${BASE_URL}/guitar/C - Correct.svg`;
const C_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/C - Incorrect.svg`;
const C_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/C - Top Level.svg`;

const D7_FRONT_CORRECT = `${BASE_URL}/guitar/D7-front-corr.png`;
const D7_FRONT_INCORRECT = `${BASE_URL}/guitar/D7-front-incorr.png`;
const D7_TOP_CORRECT = `${BASE_URL}/guitar/D7-top-corr.png`;
const D7_TOP_INCORRECT = `${BASE_URL}/guitar/D7-top-incorr.png`;
const D7_DIAGRAM_CORRECT = `${BASE_URL}/guitar/D7 - Correct.svg`;
const D7_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/D7 - Incorrect.svg`;
const D7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/D7 - Top Level.svg`;

const D_FRONT_CORRECT = `${BASE_URL}/guitar/D-front-corr.png`;
const D_FRONT_INCORRECT = `${BASE_URL}/guitar/D-front-incorr.png`;
const D_TOP_CORRECT = `${BASE_URL}/guitar/D-top-corr.png`;
const D_TOP_INCORRECT = `${BASE_URL}/guitar/D-top-incorr.png`;
const D_DIAGRAM_CORRECT = `${BASE_URL}/guitar/D - Correct.svg`;
const D_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/D - Incorrect.svg`;
const D_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/D - Top Level.svg`;

const Dsus_FRONT_CORRECT = `${BASE_URL}/guitar/Dsus-front-corr.png`;
const Dsus_FRONT_INCORRECT = `${BASE_URL}/guitar/Dsus-front-incorr.png`;
const Dsus_TOP_CORRECT = `${BASE_URL}/guitar/Dsus-top-corr.png`;
const Dsus_TOP_INCORRECT = `${BASE_URL}/guitar/Dsus-top-incorr.png`;
const Dsus_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Dsus - Correct.svg`;
const Dsus_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Dsus - Incorrect.svg`;
const Dsus_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Dsus - Top Level.svg`;

const E_FRONT_CORRECT = `${BASE_URL}/guitar/E-front-corr.png`;
const E_FRONT_INCORRECT = `${BASE_URL}/guitar/E-front-incorr.png`;
const E_TOP_CORRECT = `${BASE_URL}/guitar/E-top-corr.png`;
const E_TOP_INCORRECT = `${BASE_URL}/guitar/E-top-incorr.png`;
const E_DIAGRAM_CORRECT = `${BASE_URL}/guitar/E - Correct.svg`;
const E_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/E - Incorrect.svg`;
const E_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/E - Top Level.svg`;

const Em_FRONT_CORRECT = `${BASE_URL}/guitar/Em-front-corr.png`;
const Em_FRONT_INCORRECT = `${BASE_URL}/guitar/Em-front-incorr.png`;
const Em_TOP_CORRECT = `${BASE_URL}/guitar/Em-top-corr.png`;
const Em_TOP_INCORRECT = `${BASE_URL}/guitar/Em-top-incorr.png`;
const Em_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Em - Correct.svg`;
const Em_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Em - Incorrect.svg`;
const Em_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Em - Top Level.svg`;

const G_FRONT_CORRECT = `${BASE_URL}/guitar/G-front-corr.png`;
const G_FRONT_INCORRECT = `${BASE_URL}/guitar/G-front-incorr.png`;
const G_TOP_CORRECT = `${BASE_URL}/guitar/G-top-corr.png`;
const G_TOP_INCORRECT = `${BASE_URL}/guitar/G-top-incorr.png`;
const G_DIAGRAM_CORRECT = `${BASE_URL}/guitar/G - Correct.svg`;
const G_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/G - Incorrect.svg`;
const G_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/G - Top Level.svg`;

const B7_FRONT_CORRECT = `${BASE_URL}/guitar/B7-front-corr.png`;
const B7_FRONT_INCORRECT = `${BASE_URL}/guitar/B7-front-incorr.png`;
const B7_TOP_CORRECT = `${BASE_URL}/guitar/B7-top-corr.png`;
const B7_TOP_INCORRECT = `${BASE_URL}/guitar/B7-top-incorr.png`;
const B7_DIAGRAM_CORRECT = `${BASE_URL}/guitar/B7 - Correct.svg`;
const B7_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/B7 - Incorrect.svg`;
const B7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/B7 - Top Level.svg`;

const Dm_FRONT_CORRECT = `${BASE_URL}/guitar/Dm-front-corr.png`;
const Dm_FRONT_INCORRECT = `${BASE_URL}/guitar/Dm-front-incorr.png`;
const Dm_TOP_CORRECT = `${BASE_URL}/guitar/Dm-top-corr.png`;
const Dm_TOP_INCORRECT = `${BASE_URL}/guitar/Dm-top-incorr.png`;
const Dm_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Dm - Correct.svg`;
const Dm_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Dm - Incorrect.svg`;
const Dm_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Dm - Top Level.svg`;

const Em7_FRONT_CORRECT = `${BASE_URL}/guitar/Em7-front-corr.png`;
const Em7_FRONT_INCORRECT = `${BASE_URL}/guitar/Em7-front-incorr.png`;
const Em7_TOP_CORRECT = `${BASE_URL}/guitar/Em7-top-corr.png`;
const Em7_TOP_INCORRECT = `${BASE_URL}/guitar/Em7-top-incorr.png`;
const Em7_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Em7 - Correct.svg`;
const Em7_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Em7 - Incorrect.svg`;
const Em7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Em7 - Top Level.svg`;

const G7_FRONT_CORRECT = `${BASE_URL}/guitar/G7-front-corr.png`;
const G7_FRONT_INCORRECT = `${BASE_URL}/guitar/G7-front-incorr.png`;
const G7_TOP_CORRECT = `${BASE_URL}/guitar/G7-top-corr.png`;
const G7_TOP_INCORRECT = `${BASE_URL}/guitar/G7-top-incorr.png`;
const G7_DIAGRAM_CORRECT = `${BASE_URL}/guitar/G7 - Correct.svg`;
const G7_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/G7 - Incorrect.svg`;
const G7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/G7 - Top Level.svg`;

const F_FRONT_CORRECT = `${BASE_URL}/guitar/F-front-corr.png`;
const F_FRONT_INCORRECT = `${BASE_URL}/guitar/F-front-incorr.png`;
const F_TOP_CORRECT = `${BASE_URL}/guitar/F-top-corr.png`;
const F_TOP_INCORRECT = `${BASE_URL}/guitar/F-top-incorr.png`;
const F_DIAGRAM_CORRECT = `${BASE_URL}/guitar/F - Correct.svg`;
const F_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/F - Incorrect.svg`;
const F_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/F - Top Level.svg`;

const F_SHARP_M_FRONT_CORRECT = `${BASE_URL}/guitar/F_SHARP_m-front-corr.png`;
const F_SHARP_M_FRONT_INCORRECT = `${BASE_URL}/guitar/F_SHARP_m-front-incorr.png`;
const F_SHARP_M_TOP_CORRECT = `${BASE_URL}/guitar/F_SHARP_m-top-corr.png`;
const F_SHARP_M_TOP_INCORRECT = `${BASE_URL}/guitar/F_SHARP_m-top-incorr.png`;
const F_SHARP_M_DIAGRAM_CORRECT = `${BASE_URL}/guitar/F_SHARP_m - Correct.svg`;
const F_SHARP_M_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/F_SHARP_m - Incorrect.svg`;
const F_SHARP_M_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/F_SHARP_m - Top Level.svg`;

const C_SHARP_M_FRONT_CORRECT = `${BASE_URL}/guitar/C_SHARP_m-front-corr.png`;
const C_SHARP_M_FRONT_INCORRECT = `${BASE_URL}/guitar/C_SHARP_m-front-incorr.png`;
const C_SHARP_M_TOP_CORRECT = `${BASE_URL}/guitar/C_SHARP_m-top-corr.png`;
const C_SHARP_M_TOP_INCORRECT = `${BASE_URL}/guitar/C_SHARP_m-top-incorr.png`;
const C_SHARP_M_DIAGRAM_CORRECT = `${BASE_URL}/guitar/C_SHARP_m - Correct.svg`;
const C_SHARP_M_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/C_SHARP_m - Incorrect.svg`;
const C_SHARP_M_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/C_SHARP_m - Top Level.svg`;

const Bm_FRONT_CORRECT = `${BASE_URL}/guitar/Bm-front-corr.png`;
const Bm_FRONT_INCORRECT = `${BASE_URL}/guitar/Bm-front-incorr.png`;
const Bm_TOP_CORRECT = `${BASE_URL}/guitar/Bm-top-corr.png`;
const Bm_TOP_INCORRECT = `${BASE_URL}/guitar/Bm-top-incorr.png`;
const Bm_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Bm - Correct.svg`;
const Bm_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Bm - Incorrect.svg`;
const Bm_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Bm - Top Level.svg`;

const E7_FRONT_CORRECT = `${BASE_URL}/guitar/E7-front-corr.png`;
const E7_FRONT_INCORRECT = `${BASE_URL}/guitar/E7-front-incorr.png`;
const E7_TOP_CORRECT = `${BASE_URL}/guitar/E7-top-corr.png`;
const E7_TOP_INCORRECT = `${BASE_URL}/guitar/E7-top-incorr.png`;
const E7_DIAGRAM_CORRECT = `${BASE_URL}/guitar/E7 - Correct.svg`;
const E7_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/E7 - Incorrect.svg`;
const E7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/E7 - Top Level.svg`;

const C_SHARP_FRONT_CORRECT = `${BASE_URL}/guitar/C_SHARP-front-corr.png`;
const C_SHARP_FRONT_INCORRECT = `${BASE_URL}/guitar/C_SHARP-front-incorr.png`;
const C_SHARP_TOP_CORRECT = `${BASE_URL}/guitar/C_SHARP-top-corr.png`;
const C_SHARP_TOP_INCORRECT = `${BASE_URL}/guitar/C_SHARP-top-incorr.png`;
const C_SHARP_DIAGRAM_CORRECT = `${BASE_URL}/guitar/C_SHARP - Correct.svg`;
const C_SHARP_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/C_SHARP - Incorrect.svg`;
const C_SHARP_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/C_SHARP - Top Level.svg`;

const Cm_FRONT_CORRECT = `${BASE_URL}/guitar/Cm-front-corr.png`;
const Cm_FRONT_INCORRECT = `${BASE_URL}/guitar/Cm-front-incorr.png`;
const Cm_TOP_CORRECT = `${BASE_URL}/guitar/Cm-top-corr.png`;
const Cm_TOP_INCORRECT = `${BASE_URL}/guitar/Cm-top-incorr.png`;
const Cm_DIAGRAM_CORRECT = `${BASE_URL}/guitar/Cm - Correct.svg`;
const Cm_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/Cm - Incorrect.svg`;
const Cm_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/Cm - Top Level.svg`;

const B_FRONT_CORRECT = `${BASE_URL}/guitar/B-front-corr.png`;
const B_FRONT_INCORRECT = `${BASE_URL}/guitar/B-front-incorr.png`;
const B_TOP_CORRECT = `${BASE_URL}/guitar/B-top-corr.png`;
const B_TOP_INCORRECT = `${BASE_URL}/guitar/B-top-incorr.png`;
const B_DIAGRAM_CORRECT = `${BASE_URL}/guitar/B - Correct.svg`;
const B_DIAGRAM_INCORRECT = `${BASE_URL}/guitar/B - Incorrect.svg`;
const B_DIAGRAM_TOP_LEVEL = `${BASE_URL}/guitar/B - Top Level.svg`;

export const guitarChords: {
  [key: string]: {
    front_correct: string;
    front_incorrect: string;
    top_correct: string;
    top_incorrect: string;
    diagram_correct: string;
    diagram_incorrect: string;
    diagram_top_level: string;
  };
} = {
  A7: {
    front_correct: A7_FRONT_CORRECT,
    front_incorrect: A7_FRONT_INCORRECT,
    top_correct: A7_TOP_CORRECT,
    top_incorrect: A7_TOP_INCORRECT,
    diagram_correct: A7_DIAGRAM_CORRECT,
    diagram_incorrect: A7_DIAGRAM_INCORRECT,
    diagram_top_level: A7_DIAGRAM_TOP_LEVEL,
  },
  A: {
    front_correct: A_FRONT_CORRECT,
    front_incorrect: A_FRONT_INCORRECT,
    top_correct: A_TOP_CORRECT,
    top_incorrect: A_TOP_INCORRECT,
    diagram_correct: A_DIAGRAM_CORRECT,
    diagram_incorrect: A_DIAGRAM_INCORRECT,
    diagram_top_level: A_DIAGRAM_TOP_LEVEL,
  },
  Am: {
    front_correct: Am_FRONT_CORRECT,
    front_incorrect: Am_FRONT_INCORRECT,
    top_correct: Am_TOP_CORRECT,
    top_incorrect: Am_TOP_INCORRECT,
    diagram_correct: Am_DIAGRAM_CORRECT,
    diagram_incorrect: Am_DIAGRAM_INCORRECT,
    diagram_top_level: Am_DIAGRAM_TOP_LEVEL,
  },
  Asus: {
    front_correct: Asus_FRONT_CORRECT,
    front_incorrect: Asus_FRONT_INCORRECT,
    top_correct: Asus_TOP_CORRECT,
    top_incorrect: Asus_TOP_INCORRECT,
    diagram_correct: Asus_DIAGRAM_CORRECT,
    diagram_incorrect: Asus_DIAGRAM_INCORRECT,
    diagram_top_level: Asus_DIAGRAM_TOP_LEVEL,
  },
  B: {
    front_correct: B_FRONT_CORRECT,
    front_incorrect: B_FRONT_INCORRECT,
    top_correct: B_TOP_CORRECT,
    top_incorrect: B_TOP_INCORRECT,
    diagram_correct: B_DIAGRAM_CORRECT,
    diagram_incorrect: B_DIAGRAM_INCORRECT,
    diagram_top_level: B_DIAGRAM_TOP_LEVEL,
  },
  Bm: {
    front_correct: Bm_FRONT_CORRECT,
    front_incorrect: Bm_FRONT_INCORRECT,
    top_correct: Bm_TOP_CORRECT,
    top_incorrect: Bm_TOP_INCORRECT,
    diagram_correct: Bm_DIAGRAM_CORRECT,
    diagram_incorrect: Bm_DIAGRAM_INCORRECT,
    diagram_top_level: Bm_DIAGRAM_TOP_LEVEL,
  },
  B7: {
    front_correct: B7_FRONT_CORRECT,
    front_incorrect: B7_FRONT_INCORRECT,
    top_correct: B7_TOP_CORRECT,
    top_incorrect: B7_TOP_INCORRECT,
    diagram_correct: B7_DIAGRAM_CORRECT,
    diagram_incorrect: B7_DIAGRAM_INCORRECT,
    diagram_top_level: B7_DIAGRAM_TOP_LEVEL,
  },
  C: {
    front_correct: C_FRONT_CORRECT,
    front_incorrect: C_FRONT_INCORRECT,
    top_correct: C_TOP_CORRECT,
    top_incorrect: C_TOP_INCORRECT,
    diagram_correct: C_DIAGRAM_CORRECT,
    diagram_incorrect: C_DIAGRAM_INCORRECT,
    diagram_top_level: C_DIAGRAM_TOP_LEVEL,
  },
  "C#": {
    front_correct: C_SHARP_FRONT_CORRECT,
    front_incorrect: C_SHARP_FRONT_INCORRECT,
    top_correct: C_SHARP_TOP_CORRECT,
    top_incorrect: C_SHARP_TOP_INCORRECT,
    diagram_correct: C_SHARP_DIAGRAM_CORRECT,
    diagram_incorrect: C_SHARP_DIAGRAM_INCORRECT,
    diagram_top_level: C_SHARP_DIAGRAM_TOP_LEVEL,
  },
  "C#m": {
    front_correct: C_SHARP_M_FRONT_CORRECT,
    front_incorrect: C_SHARP_M_FRONT_INCORRECT,
    top_correct: C_SHARP_M_TOP_CORRECT,
    top_incorrect: C_SHARP_M_TOP_INCORRECT,
    diagram_correct: C_SHARP_M_DIAGRAM_CORRECT,
    diagram_incorrect: C_SHARP_M_DIAGRAM_INCORRECT,
    diagram_top_level: C_SHARP_M_DIAGRAM_TOP_LEVEL,
  },
  Cm: {
    front_correct: Cm_FRONT_CORRECT,
    front_incorrect: Cm_FRONT_INCORRECT,
    top_correct: Cm_TOP_CORRECT,
    top_incorrect: Cm_TOP_INCORRECT,
    diagram_correct: Cm_DIAGRAM_CORRECT,
    diagram_incorrect: Cm_DIAGRAM_INCORRECT,
    diagram_top_level: Cm_DIAGRAM_TOP_LEVEL,
  },
  D: {
    front_correct: D_FRONT_CORRECT,
    front_incorrect: D_FRONT_INCORRECT,
    top_correct: D_TOP_CORRECT,
    top_incorrect: D_TOP_INCORRECT,
    diagram_correct: D_DIAGRAM_CORRECT,
    diagram_incorrect: D_DIAGRAM_INCORRECT,
    diagram_top_level: D_DIAGRAM_TOP_LEVEL,
  },
  Dm: {
    front_correct: Dm_FRONT_CORRECT,
    front_incorrect: Dm_FRONT_INCORRECT,
    top_correct: Dm_TOP_CORRECT,
    top_incorrect: Dm_TOP_INCORRECT,
    diagram_correct: Dm_DIAGRAM_CORRECT,
    diagram_incorrect: Dm_DIAGRAM_INCORRECT,
    diagram_top_level: Dm_DIAGRAM_TOP_LEVEL,
  },
  D7: {
    front_correct: D7_FRONT_CORRECT,
    front_incorrect: D7_FRONT_INCORRECT,
    top_correct: D7_TOP_CORRECT,
    top_incorrect: D7_TOP_INCORRECT,
    diagram_correct: D7_DIAGRAM_CORRECT,
    diagram_incorrect: D7_DIAGRAM_INCORRECT,
    diagram_top_level: D7_DIAGRAM_TOP_LEVEL,
  },
  Dsus: {
    front_correct: Dsus_FRONT_CORRECT,
    front_incorrect: Dsus_FRONT_INCORRECT,
    top_correct: Dsus_TOP_CORRECT,
    top_incorrect: Dsus_TOP_INCORRECT,
    diagram_correct: Dsus_DIAGRAM_CORRECT,
    diagram_incorrect: Dsus_DIAGRAM_INCORRECT,
    diagram_top_level: Dsus_DIAGRAM_TOP_LEVEL,
  },
  E: {
    front_correct: E_FRONT_CORRECT,
    front_incorrect: E_FRONT_INCORRECT,
    top_correct: E_TOP_CORRECT,
    top_incorrect: E_TOP_INCORRECT,
    diagram_correct: E_DIAGRAM_CORRECT,
    diagram_incorrect: E_DIAGRAM_INCORRECT,
    diagram_top_level: E_DIAGRAM_TOP_LEVEL,
  },
  Em: {
    front_correct: Em_FRONT_CORRECT,
    front_incorrect: Em_FRONT_INCORRECT,
    top_correct: Em_TOP_CORRECT,
    top_incorrect: Em_TOP_INCORRECT,
    diagram_correct: Em_DIAGRAM_CORRECT,
    diagram_incorrect: Em_DIAGRAM_INCORRECT,
    diagram_top_level: Em_DIAGRAM_TOP_LEVEL,
  },
  Em7: {
    front_correct: Em7_FRONT_CORRECT,
    front_incorrect: Em7_FRONT_INCORRECT,
    top_correct: Em7_TOP_CORRECT,
    top_incorrect: Em7_TOP_INCORRECT,
    diagram_correct: Em7_DIAGRAM_CORRECT,
    diagram_incorrect: Em7_DIAGRAM_INCORRECT,
    diagram_top_level: Em7_DIAGRAM_TOP_LEVEL,
  },
  E7: {
    front_correct: E7_FRONT_CORRECT,
    front_incorrect: E7_FRONT_INCORRECT,
    top_correct: E7_TOP_CORRECT,
    top_incorrect: E7_TOP_INCORRECT,
    diagram_correct: E7_DIAGRAM_CORRECT,
    diagram_incorrect: E7_DIAGRAM_INCORRECT,
    diagram_top_level: E7_DIAGRAM_TOP_LEVEL,
  },
  F: {
    front_correct: F_FRONT_CORRECT,
    front_incorrect: F_FRONT_INCORRECT,
    top_correct: F_TOP_CORRECT,
    top_incorrect: F_TOP_INCORRECT,
    diagram_correct: F_DIAGRAM_CORRECT,
    diagram_incorrect: F_DIAGRAM_INCORRECT,
    diagram_top_level: F_DIAGRAM_TOP_LEVEL,
  },
  "F#m": {
    front_correct: F_SHARP_M_FRONT_CORRECT,
    front_incorrect: F_SHARP_M_FRONT_INCORRECT,
    top_correct: F_SHARP_M_TOP_CORRECT,
    top_incorrect: F_SHARP_M_TOP_INCORRECT,
    diagram_correct: F_SHARP_M_DIAGRAM_CORRECT,
    diagram_incorrect: F_SHARP_M_DIAGRAM_INCORRECT,
    diagram_top_level: F_SHARP_M_DIAGRAM_TOP_LEVEL,
  },
  G: {
    front_correct: G_FRONT_CORRECT,
    front_incorrect: G_FRONT_INCORRECT,
    top_correct: G_TOP_CORRECT,
    top_incorrect: G_TOP_INCORRECT,
    diagram_correct: G_DIAGRAM_CORRECT,
    diagram_incorrect: G_DIAGRAM_INCORRECT,
    diagram_top_level: G_DIAGRAM_TOP_LEVEL,
  },
  G7: {
    front_correct: G7_FRONT_CORRECT,
    front_incorrect: G7_FRONT_INCORRECT,
    top_correct: G7_TOP_CORRECT,
    top_incorrect: G7_TOP_INCORRECT,
    diagram_correct: G7_DIAGRAM_CORRECT,
    diagram_incorrect: G7_DIAGRAM_INCORRECT,
    diagram_top_level: G7_DIAGRAM_TOP_LEVEL,
  },
};
