import React from "react";
import css from "../view_song_modal.module.scss";

interface ISongSettings {
  songKey: string | undefined;
  songMeter: string | undefined;
  songTempo: number | undefined;
}

export const ViewSongSettings: React.FC<ISongSettings> = ({
  songKey,
  songMeter,
  songTempo,
}) => {
  return (
    <div className={css.song_settings_bar}>
      <div className={css.song_settings_panel}>
        <div className={css.song_settings_container}>
          <div className={css.song_setting_container}>
            <div className={css.song_setting_value}>{songKey}</div>
            <div className={css.song_setting_label}>Key</div>
          </div>
          <div className={css.song_setting_container}>
            <div className={css.song_setting_value}>{songMeter}</div>
            <div className={css.song_setting_label}>Meter</div>
          </div>
          <div className={css.song_setting_container}>
            <div className={css.song_setting_value}>{songTempo}</div>
            <div className={css.song_setting_label}>Tempo</div>
          </div>
        </div>
      </div>
    </div>
  );
};
