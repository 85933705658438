import { useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import cn from "classnames";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Img } from "react-image";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IChordImage } from "resources/chords";
import css from "./chord_library_chord.module.scss";

export interface IChordLibraryChord {
  chord: [string, IChordImage];
  alreadyKnow?: boolean;
}

export const ChordLibraryChord = ({
  chord,
  alreadyKnow = false,
}: IChordLibraryChord) => {
  const chordData = chord[1];
  const images = [
    chordData.diagram_top_level,
    chordData.diagram_correct,
    chordData.top_correct,
    chordData.front_correct,
  ];
  const [imageNumber, setImageNumber] = useState(0);

  const handlePrev = () => {
    if (imageNumber > 0) {
      setImageNumber(imageNumber - 1);
    }
  };
  const handleNext = () => {
    if (imageNumber < images.length - 1) {
      setImageNumber(imageNumber + 1);
    }
  };

  return (
    <div className={css.container}>
      <div className={css.slide_wrapper}>
        {alreadyKnow ? (
          <CheckCircleOutlineIcon
            style={{
              color: "#29bb9c",
              marginRight: "8px",
              position: "absolute",
              right: 20,
              top: 20,
            }}
          />
        ) : null}
        {imageNumber === 0 ? null : (
          <button
            onClick={handlePrev}
            className={cn(css.button, css.left)}
            type="button"
          >
            <ArrowBackIosIcon style={{ fontSize: 16, marginLeft: 4 }} />
          </button>
        )}
        <Img
          src={images[imageNumber]}
          key={images[imageNumber]}
          loader={<CircularProgress />}
          className={css.img}
        />
        {images.length === imageNumber + 1 ? null : (
          <button
            onClick={handleNext}
            className={cn(css.button, css.right)}
            type="button"
          >
            <ArrowForwardIosIcon style={{ fontSize: 16, marginLeft: 4 }} />
          </button>
        )}
      </div>
    </div>
  );
};
