import styled from "@emotion/styled";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TABLET } from "scss/emotion_consts";

export const StyledButton = styled.div`
  justify-self: center;
  margin: 2em 0;
`;

export const StyledBackIcon = styled(ArrowDownwardIcon)`
  transform: rotate(90deg);
  padding: 0.3em 0.5em;
`;
export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  max-width: 770px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.5em 2.5em;

  > span {
    margin-left: 2em;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  border: 1px solid grey;
  border-radius: 20px;
  padding: 11px 12px;
  box-shadow: 2px 7px 13px rgba(0, 0, 0, 0.25);
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 310px;
  text-align: start;
  color: #72808a;
  top: 60px;
  right: -40px;

  margin-top: 2em;
  position: initial;

  @media ${TABLET} {
    margin-top: initial;
  }
`;
export const HelpDescription = styled.div`
  margin: 0 1em;
  > span {
    font-weight: 600;
  }
`;
export const Upload = styled.div`
  position: relative;
  margin-top: 2em;
  padding: initial;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${TABLET} {
    padding: 0 310px;
    display: flex;
    flex-direction: initial;
    justify-content: center;
    align-self: center;
  }
`;
