import React from "react";
import { SkillProgressLine } from "components/my_journey/skill_progress_line/skill_progress_line";
import css from "./top_skill_info.module.scss";

interface ITopSkillInfo {
  skillName: string;
  skillsNumber: number;
  progress: number;
  skillsMinNumber: number;
}

export const TopSkillInfo = (props: ITopSkillInfo) => {
  const { skillName, skillsNumber, progress, skillsMinNumber } = props;

  return (
    <div className={css.top_skill_container}>
      <div className={css.top_skill_number}>{skillsNumber}</div>
      <div className={css.top_skill_name}> {skillName}</div>
      <div className={css.top_skill_progress}>
        <SkillProgressLine progress={progress} />
      </div>
      <div className={css.top_skill_text}>
        Complete {skillsMinNumber} lessons for each {skillName.toLowerCase()}{" "}
        pattern to learn {skillName.toLowerCase()} skills
      </div>
    </div>
  );
};
