import { useRef } from "react";
import cn from "classnames";
import WarningIcon from "@material-ui/icons/Warning";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import uploadFileLogo from "../../../../sign_up/images/icon-upload-logo.svg";
import SVGDocument from "../../../../../img/CSVDociument.svg";
import css from "./add_students_bulk_upload_content.module.scss";
import { IStudents } from "../../add_students_bulk_tab";

interface IAddStudentsBulkUploadContent {
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  isDragActive: boolean;
  errors: string[];
  students: IStudents[];
  fileName: string;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  onFileChange: (e: any) => Promise<void>;
}

export const AddStudentsBulkUploadContent = ({
  getRootProps,
  isDragActive,
  errors,
  students,
  fileName,
  getInputProps,
  onFileChange,
}: IAddStudentsBulkUploadContent) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  let content = null;

  if (errors.length) {
    content = (
      <>
        <div className={css.warning_icon}>
          <WarningIcon />
        </div>
        <p className={css.circle_error_text}>
          There was an error processing your file. Please reupload or{" "}
        </p>
        <div className={css.select_from_computer}>Select From Computer</div>
      </>
    );
  } else if (students.length) {
    content = (
      <div className={css.inner_circle}>
        <p className={css.file_name_container}>
          <img alt="csv document" src={SVGDocument} />
          <span className={css.document_name}>{fileName}</span>
        </p>
        <p className={css.upload_percentage}>100%</p>
        <div className={css.border_animation} />
      </div>
    );
  } else {
    content = (
      <>
        <img src={uploadFileLogo} className={css.arrow_img} alt="upload csv" />
        <div className={cn(css.drag_csv, css.drag_first)}>Upload File</div>
        <div className={css.drag_csv}>
          {"Drag and drop your file here\n"} MyStudents.CSV Here
        </div>
        <div className={css.select_from_computer}>Select From Computer</div>
      </>
    );
  }

  return (
    <button
      {...getRootProps()}
      type="button"
      onClick={() => {
        fileInputRef?.current?.click();
      }}
      className={cn(
        css.inputCircle,
        isDragActive && css.inputCircleActive,
        errors.length && css.inputCircleError,
      )}
    >
      {content}
      <input
        {...getInputProps()}
        onChange={onFileChange}
        multiple={false}
        type="file"
        accept=".csv"
        ref={fileInputRef}
        className={css.fileInput}
      />
    </button>
  );
};
