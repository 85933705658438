import { UserSkills } from "../cards_content/user_skills";

interface IChordView {
  userSkills: string[];
  allSkills: string[];
  skillType: string;
  topText: string;
  bottomText: string;
  learnText?: string;
  clickable: boolean;
}

export const ChordView = ({
  userSkills,
  allSkills,
  skillType,
  topText,
  bottomText,
  learnText,
  clickable,
}: IChordView) => {
  return (
    <UserSkills
      userSkills={userSkills}
      allSkills={allSkills}
      learnText={learnText}
      skillType={skillType}
      clickable={clickable}
      topText={topText}
      bottomText={bottomText}
    />
  );
};
