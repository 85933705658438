import { useMemo } from "react";
// @ts-ignore
import { useTable } from "react-table";
import { RowType } from "redux/songwriting/songwriting_interfaces";

interface IUseTableData {
  data: RowType[];
}

const COLUMN_COUNT = 50;

const createColumns = (numOfColumns: number) => {
  const columns = [];
  for (let i = 0; i < numOfColumns; i++) {
    columns.push({
      Header: `Column ${i}`,
      accessor: `c${i}` as const,
    });
  }
  return columns;
};

export const useTableData = ({ data }: IUseTableData) => {
  const columns = useMemo(() => createColumns(COLUMN_COUNT), []);
  const { getTableBodyProps, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
  });
  return { getTableBodyProps, rows, prepareRow };
};
