import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getSignUpStepsSelector = createSelector(
  (state: IState) => state.signUpSteps.steps,
  (steps) => steps,
);
export const getSignUpStepsUserDataSelector = createSelector(
  (state: IState) => state.signUpSteps.userData,
  (userData) => userData,
);

export const isSelectChordsStepPassedSelector = createSelector(
  (state: IState) => state.signUpSteps.steps,
  (steps) => steps?.selectChords,
);

export const isSelectPlanStepPassedSelector = createSelector(
  (state: IState) => state.signUpSteps.steps,
  (steps) => !!steps?.selectPlan,
);
