import styled from "@emotion/styled";
import { Container } from "../emotion.styles";

export const StyledContainer = styled(Container)`
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  margin-bottom: 2em;
  text-align: start;
  width: 100%;
  max-width: 500px;
  display: flex;
  align-self: center;
`;

export const LogoContainer = styled.div`
  justify-content: center;
  margin: 1em 0em;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 1em;
`;

export const EmailDescription = styled.div`
  font-size: 1.2em;
  color: #190067;
  white-space: break-spaces;
  font-weight: 600;
  margin-bottom: 0.5em;
`;
