import React from "react";
import classNames from "classnames";
import { UKULELE_NOTES } from "constants/notes";
import { Box } from "@material-ui/core";
import Ukulele from "../assets/ukulele.png";
import css from "../tuner_layout.module.scss";

interface IUkuleleLayout {
  activeNoteFrequency: string;
  setActiveNoteFrequency: React.Dispatch<React.SetStateAction<string>>;
  setActiveNoteName: React.Dispatch<React.SetStateAction<string>>;
}

export const UkuleleGrid = ({
  setActiveNoteName,
  activeNoteFrequency,
  setActiveNoteFrequency,
}: IUkuleleLayout) => {
  const changeNoteHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveNoteFrequency(e.currentTarget.value);
    setActiveNoteName(e.currentTarget.dataset.notename || "");
  };
  // Important! if 1st element is "G" we swap "left-sided" notes cause of the ukulele img layout
  const shuffledNotes = [...UKULELE_NOTES];

  shuffledNotes[0].note === "G" &&
    ([shuffledNotes[0], shuffledNotes[1]] = [
      shuffledNotes[1],
      shuffledNotes[0],
    ]);

  return (
    <Box className={css.grid_ukulele}>
      {shuffledNotes.map((note, index) => {
        return (
          <button
            onClick={changeNoteHandler}
            value={note.frequency}
            type="button"
            data-notename={note.note}
            className={classNames(
              css.rounded_button,
              css[`grid_pos-ukulele-${index + 1}`],
              {
                [css.active_rounded_button]:
                  activeNoteFrequency === note.frequency,
              },
            )}
            key={note.frequency}
          >
            {note.note}
          </button>
        );
      })}
      <img className={css.img_box} src={Ukulele} alt="Ukulele head" />
    </Box>
  );
};
