import { StyleSheet } from "@react-pdf/renderer";
import "./songbuilder_pdf_font_module";

export const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 65,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  title: {
    color: "#5f6367",
    fontWeight: 300,
    fontSize: 25,
    textAlign: "center",
    fontFamily: "Raleway",
  },
  songUnitTitleView: {
    height: 30,
  },
  songUnitTitle: {
    color: "#979797",
    fontWeight: 700,
    fontSize: 14,
    top: 12,
    fontFamily: "Raleway",
  },
  songLyrics: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: "Courier New",
  },
  chordRow: {
    height: 30,
  },
  chordContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  chord: {
    top: 7.5,
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 15,
  },
  footerImage: {
    height: 50,
    width: 120,
  },
});

export const protectionBadge = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
  },
  image: {
    width: 15,
    height: 18.57,
  },
  text: {
    fontSize: 10,
  },
});

export const certificate_styles = StyleSheet.create({
  page: {},
  section: {
    marginTop: 52,
    marginLeft: 21,
    marginRight: 21,
    marginBottom: 52,
    paddingTop: 60,
    borderStyle: "solid",
    borderWidth: 4,
    borderColor: "#76B3F3",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  certTitle: {
    fontFamily: "Raleway",
    fontSize: 16,
    fontWeight: "heavy",
    letterSpacing: 2,
  },
  songTitle: {
    fontFamily: "Raleway",
    fontSize: 24,
    color: "#76B3F3",
    fontWeight: "bold",
    fontStyle: "italic",
    marginTop: 20,
  },
  songData: {
    fontFamily: "Raleway",
    fontSize: 14,
    color: "#818181",
    fontWeight: "semibold",
    marginTop: 20,
  },
  songLabel: {
    fontFamily: "Raleway",
    fontSize: 14,
    fontWeight: "semibold",
  },
  blueRibbonView: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  blueRibbonImage: {
    width: 35,
    height: 51,
    position: "absolute",
    top: 37,
  },
  blueRibbonBackground: {
    position: "absolute",
    top: 52,
    left: -25,
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
    bottom: 70,
  },
  footerImage: {
    height: 50,
    width: 120,
  },
});
