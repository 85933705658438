import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/reusable/buttons/button";
import {
  isButtonDisabledSelector,
  formSelector,
} from "redux/ui/form/form_selectors";
import { reqPWReset } from "redux/auth/auth_slice";
import { clearErrors } from "redux/errors/session/session_errors_actions";
import { reqPWResetMessage } from "redux/ui/form/form_slice";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { getErrors } from "redux/errors/errors_selectors";

import { openLoginModal } from "redux/ui/modal/modal_slice";
import { ModalContainer } from "./modal_container";

import css from "./modal.module.scss";

export const ReqPWResetModal = () => {
  const dispatch = useDispatch();
  const { url } = useSelector(getModalDataSelector);
  const confirm = useSelector(formSelector).reqPWReset;
  const disabled = useSelector(isButtonDisabledSelector);
  const errors = useSelector(getErrors).session.reqPWReset;
  const [email, setEmail] = useState("");

  useEffect(() => {
    return () => {
      if (errors) {
        dispatch(clearErrors());
      }
      if (confirm) {
        dispatch(reqPWResetMessage(null));
      }
    };
  }, [confirm, dispatch, errors]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(reqPWReset({ email }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  let submitErr;
  let confirmation;
  if (errors) {
    [submitErr] = errors;
  }
  if (confirm) {
    confirmation = confirm;
  }

  return (
    <ModalContainer>
      <div className={css.title}>Request Password Reset</div>
      {confirmation ? (
        <div className={`${css.description} fadeIn animated2`}>
          {confirmation}
        </div>
      ) : (
        <div className={css.description}>
          Enter the email you used to sign up to Moosiko. We'll send you a link
          to reset your password.
        </div>
      )}

      <form onSubmit={handleSubmit} className={css.form}>
        <input
          onChange={handleChange}
          className={`${css.input}  ${submitErr ? css.input_error : null}`}
          value={email}
          id="email"
          type="email"
          placeholder="Email"
          autoComplete="email"
        />
        <div
          className={submitErr ? `${css.error} fadeIn animated2` : undefined}
        >
          {submitErr}
        </div>
        <Button showLoader={disabled}>Submit</Button>
      </form>
      <button
        type="button"
        style={{ paddingTop: "5px" }}
        className={css.link}
        onClick={() => dispatch(openLoginModal({ url }))}
      >
        &#8592; Back to Login
      </button>
    </ModalContainer>
  );
};
