import { useRef, useCallback } from "react";
import { Input } from "components/reusable/input/input";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrganizationName,
  getOrganizationLogoURL,
} from "redux/organizations/organization_selectors";
import { S3ClientLogos, uploadFileToS3 } from "api/s3_api";
import { updateLogo } from "redux/organizations/organization_slice";
import { useDropzone } from "react-dropzone";
import cn from "classnames";
import { Button } from "@material-ui/core";
import css from "./business_card_edit.module.scss";

const MIN_IMAGE_SIZE = 50;

export const BusinessCardEdit = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const organizationName = useSelector(getOrganizationName);

  const dispatch = useDispatch();

  const onFileChange = useCallback(
    async (e) => {
      const { files } = e.target;
      const file = files[files.length - 1] || null;

      const processedFile = await readFile(file);

      const MAX_FILE_SIZE_5MB = 5242880;
      if (!isFileSizeOk(file, MAX_FILE_SIZE_5MB)) {
        toast.error(
          "Max file size for logo is 5 MB. We want to make room for everyone. Please upload smaller file thanks!",
        );
        return;
      }

      const { location } = await uploadFileToS3(processedFile, S3ClientLogos);
      dispatch(updateLogo({ logoPath: location }));
    },
    [dispatch],
  );

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange({ target: { files: acceptedFiles } });
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const orgLogo = useSelector(getOrganizationLogoURL);

  const readFile = (file: File) => {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Add the file name to the data URL
        let dataURL = e.target?.result;
        if (typeof dataURL !== "string") {
          return;
        }
        dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
        const img = new Image();
        img.onload = () => {
          if (img.width < MIN_IMAGE_SIZE || img.height < MIN_IMAGE_SIZE) {
            reject(new Error("Image is too small"));
          }
          resolve(file);
        };

        img.onerror = () => {
          reject(new Error("Not an image"));
        };
        img.src = dataURL;
      };

      reader.readAsDataURL(file);
    });
  };
  const isFileSizeOk = (file: File, fileSize: number) => file.size < fileSize;

  const removeLogo = () => {
    dispatch(updateLogo({ logoPath: "" }));
  };

  return orgLogo ? (
    <Button variant="outlined" color="primary" onClick={removeLogo}>
      Remove logo
    </Button>
  ) : (
    <div className={css.layout}>
      <h3 className={css.settingsHeader}>Organization Settings</h3>
      <div className={css.input}>
        <Input name="organizationName" value={`${organizationName}`} disabled />
      </div>
      <div className={css.logoHeader}>Upload Logo</div>
      <div
        className={cn(css.uploadBlock, isDragActive ? css.dragActive : null)}
        {...getRootProps()}
      >
        <div className={css.dragFile}>Drag a File Here</div>
        <em className={css.imageRequirements}>
          {"{Max 5 MB. Horizontal images work best.}"}
        </em>
        <button
          type="button"
          className={css.selectFileBtn}
          onClick={handleClick}
        >
          Select a File From Computer
        </button>
        <input
          {...getInputProps()}
          onChange={onFileChange}
          multiple={false}
          type="file"
          ref={fileInputRef}
          className={css.fileInput}
        />
      </div>
    </div>
  );
};
