import { Menu, MenuProps, withStyles } from "@material-ui/core";

interface StyledMenuProps extends MenuProps {
  vertical: number;
}

export const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#2C2C2C",
    color: "#fff",
    borderRadius: "16px",
  },
})((props: StyledMenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: props.vertical,
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    {...props}
  />
));
