import { useState, useEffect } from "react";

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () =>
      setWidth(Math.min(window.innerWidth, window.screen.width));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return width;
};

export const MOBILE_WIDTH = 600;

export const LAPTOP_WIDTH = 1200;
