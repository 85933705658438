import { Dialog } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import { useSelector, useDispatch } from "react-redux";
import {
  getMediaRecordingState,
  getUserMediaRecordingForSlide,
} from "redux/media_recordings/media_recordings_selectors";
import {
  createMediaRecord,
  setMediaRecordsStateStart,
  setMediaRecordsStateWaiting,
  TMediaRecordingStatuses,
} from "redux/media_recordings/media_recordings_slice";

import { useParams } from "react-router-dom";
import { getUserIdSelector, getUserSelector } from "redux/auth/user_selectors";
import { ISlide } from "api/lesson_api";
import { useGetVideoWithAccess } from "hooks/use_get_video";
import { isTouchDevice } from "helpers/isTouchDevice";
import { getLessonById } from "redux/entities/lessons/lesson_selectors";
import { format } from "date-fns";
import { Video } from "components/video";
import { ISkill } from "types/models/skill";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { MediaRecorder } from "../media-recorder";
import css from "./skill_recorder.module.scss";

interface ISkillRecorder {
  skill?: ISkill;
  songRecord?: boolean;
  slide?: ISlide;
}

export const SkillRecorder = ({
  skill = undefined,
  songRecord = undefined,
  slide = undefined,
}: ISkillRecorder) => {
  const user = useSelector(getUserSelector);
  const accessVideoRecording = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_VIDEO_RECORDING),
  );

  const recordingState = useSelector(getMediaRecordingState);
  const videoInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const userId = useSelector(getUserIdSelector);
  const { id: lessonId } = useParams<{ id: string }>();
  // const userOrganizations = useSelector(getOrganizationsInfo);

  // if (process.env.NODE_ENV === "development") {
  //   // be able to record videos locally always
  //   accessVideoRecording = false;
  // }
  const skillId = skill?.id;
  const lesson = useSelector(getLessonById(Number(lessonId)));

  const skillName = skill?.name || "song";
  const fileName = `${user?.lname}-${user?.fname}-${lesson.song.song_name}-${skillName}`;

  const handleButtonClickSelect = () => {
    if (isTouchDevice()) {
      videoInputRef.current?.click();
    } else {
      if (recordingState === TMediaRecordingStatuses.WAITING) {
        dispatch(setMediaRecordsStateStart());
      }
      if (recordingState === TMediaRecordingStatuses.RECORDING) {
        dispatch(setMediaRecordsStateWaiting());
      }
    }
  };

  const handleUpload = async (file: File) => {
    if (!userId) {
      console.error("user not found");
      return;
    }
    const mediaRecord = {
      file,
      userId,
      lessonId: Number(lessonId),
      skillId,
    };
    dispatch(createMediaRecord(mediaRecord));
  };

  const onVideoUploadFromTouchDevice = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target?.files) {
      const file = event.target.files[0];

      const newFile = new File([file], fileName, {
        type: "video/mov",
      });
      handleUpload(newFile);
      dispatch(setMediaRecordsStateWaiting());

      // eslint-disable-next-line no-param-reassign
      event.target.value = "";
    } else {
      console.error("file not found");
    }
  };

  const [videoModal, setVideoModal] = useState(false);

  const [videoURL, setVideoURL] = useState("");
  const handleModalModal = (url: string) => {
    setVideoModal(true);
    setVideoURL(url);
  };

  const userMediaRecordings = useSelector(getUserMediaRecordingForSlide(slide));

  const isRecording = recordingState === TMediaRecordingStatuses.RECORDING;

  const [openPreviousRecords, setOpenPreviousRecords] = useState(false);

  useEffect(() => {
    setOpenPreviousRecords(false);
  }, []);

  const toggleOpenPreviousRecords = () => {
    setOpenPreviousRecords(!openPreviousRecords);
  };

  const handleCloseModal = () => {
    setVideoModal(false);
  };
  const videoURLWithAccess = useGetVideoWithAccess(videoURL);

  return (
    <div>
      <div className={css.previously_recorded_container}>
        {false &&
          openPreviousRecords &&
          userMediaRecordings.map((mediaRecord) => (
            <div className={css.previously_recorded} key={mediaRecord.id}>
              <div className={classnames(css.play_video_text)}>
                {format(new Date(mediaRecord.createdAt), "MMMM d, yyyy")}
              </div>
              <button
                onClick={() =>
                  handleModalModal(
                    mediaRecord.video?.encoded_video_url ||
                      mediaRecord.encoded_video,
                  )
                }
                type="button"
                className={classnames(css.play_video_container)}
              >
                <PlayCircleOutlineIcon />
                <div>Play Video</div>
              </button>
            </div>
          ))}
      </div>
      <div
        className={classnames(css.record_button_container, {
          [css.display_outside]:
            (!skillId && !songRecord) || accessVideoRecording,
          [css.record_button_container_active]: isRecording,
          [css.record_button_previously_recorded_container]:
            false && userMediaRecordings.length > 0,
        })}
      >
        <button
          type="button"
          onClick={handleButtonClickSelect}
          aria-label="Record"
          className={classnames({
            [css.record_button]: !isRecording,
            [css.record_button_recording]: isRecording,
          })}
        />
        <div
          className={classnames({
            [css.display_none]: !isRecording,
          })}
        >
          Stop
        </div>
        <button
          aria-label=" cz"
          type="button"
          onClick={toggleOpenPreviousRecords}
          className={classnames(css.previously_recorded_text, {
            [css.display_none]: isRecording,
          })}
        />
      </div>
      <input
        className={css.video_input}
        type="file"
        accept="video/*"
        ref={videoInputRef}
        hidden
        onChange={onVideoUploadFromTouchDevice}
      />
      <MediaRecorder handleUpload={handleUpload} fileName={fileName} />
      <Dialog
        open={videoModal}
        maxWidth="sm"
        onClose={handleCloseModal}
        aria-labelledby="max-width-dialog-title"
      >
        {videoURL && <Video src={videoURLWithAccess} />}
      </Dialog>
    </div>
  );
};
