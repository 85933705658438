import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { finishLesson } from "redux/entities/user_skills/user_skills_slice";

import { Dialog } from "@material-ui/core";

import { dismissLesson, isLessonDismissed } from "helpers/isLessonDismissed";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import Button from "../../reusable/buttons/button";
import css from "../modal.module.scss";
import modalCss from "./completion_reminder_modal.module.scss";

interface ICompletionReminderModal {
  lessonId: number;
  songName: string;

  onClose?: () => void;
  onConfirm?: () => void;
  open?: boolean;
}

export const CompletionReminderModal = ({
  songName,
  lessonId,
  onClose,
  onConfirm,
  open,
}: ICompletionReminderModal) => {
  const dispatch = useDispatch();
  const { instruments } = useSelector(getSongFilters);

  const dismissHandler = () => {
    const isDismissed = isLessonDismissed(lessonId);
    if (!isDismissed) {
      dismissLesson(lessonId);
    }
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const completionHandler = () => {
    onConfirm && onConfirm();
    // Weird solution to prevent fetching users' skills if progress in not undefined,
    // I guess its related to useEffect hook in Slides component, by doing that we prevent component from re-rendering infinitely,
    // Anyways, this is not the best way to do it
    dispatch(
      finishLesson({
        lessonId,
        instrument: instruments[0],
        progress: undefined,
      }),
    );
  };

  return (
    <Dialog open={open || false} onClose={handleClose}>
      <div className={modalCss.wrapper}>
        <button type="button" onClick={handleClose} className={modalCss.close}>
          &times;
        </button>
        <div className={modalCss.exclamation_mark}>!</div>
        <h2 className={css.title}>Did you learn {songName}?</h2>
        <span className={css.description}>
          If so, don't forget to finish the lesson by clicking the orange button
          below to capture your skills and complete the lesson.
        </span>
        <Button type="primary" onClick={completionHandler}>
          Complete Lesson
        </Button>
        <Button onClick={dismissHandler} type="secondary">
          Dismiss
        </Button>
      </div>
    </Dialog>
  );
};
