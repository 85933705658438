import { useDispatch, useSelector } from "react-redux";
import { getAuthSelector, getUserSelector } from "redux/auth/user_selectors";
import { areFreeCreditsAvailableSelector } from "redux/free_user_lessons/free_user_lessons_selectors";
import {
  getCurrentSeed,
  getSeedText,
  getSeedTitle,
  getSeedType,
} from "redux/song_seeds/song_seed_selector";
import { createCustomSong } from "redux/songwriting/songwriting_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import { openModal, openSongbuilderModal } from "redux/ui/modal/modal_slice";
import { AppDispatch } from "types/redux_";
import { updateSongSeed } from "redux/song_seeds/song_seeds_slice";
import mixpanel from "mixpanel-browser";

interface ICreateAiInspiredSongProps {
  description: string;
  title: string;
}

export const useCreateNewSong = () => {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(getUserSelector);
  const userName = `${user?.fname} ${user?.lname}`;
  const { role } = useSelector(getAuthSelector);
  const areFreeCreditsAvailable = useSelector(areFreeCreditsAvailableSelector);

  const currentSeed = useSelector(getCurrentSeed);
  const seedType = useSelector(getSeedType(currentSeed));
  const seedText = useSelector(getSeedText(currentSeed));
  const seedTitle = useSelector(getSeedTitle(currentSeed));

  const lyricText = seedType === "lyric" ? seedText : null;
  const noteText = seedType === "song" ? seedText : null;
  const title = seedType === "song" ? seedTitle : null;

  const freeUserPlan = [
    ROLES.FREE_USER,
    ROLES.FREE_TEACHER_STUDENT,
    ROLES.FREE_TEACHER_ACCOUNT,
    ROLES.FREE_SONGWRITING,
  ].includes(role as any);

  const createSong = async (songData: any) => {
    const result = await dispatch(createCustomSong(songData));
    const song_id = result.payload as number;
    mixpanel.track("Create Song");
    dispatch(updateSongSeed({ status: "Linked", song_id }));
    dispatch(openSongbuilderModal());
  };

  const handleSongCreation = (
    createSongFunc: Function,
    props?: ICreateAiInspiredSongProps,
  ) => {
    if (freeUserPlan) {
      if (areFreeCreditsAvailable) {
        createSongFunc(props);
      } else {
        dispatch(openModal({ type: "payForSubscription" }));
      }
    } else {
      createSongFunc(props);
    }
  };

  const createNewSong = () => {
    const songData = {
      user_id: user!.id,
      artist: userName,
      noteText,
      lyricText,
      title,
    };
    createSong(songData);
  };

  const createNewAiSong = (props: ICreateAiInspiredSongProps) => {
    const songData = {
      user_id: user!.id,
      artist: userName,
      noteText: `${noteText}\n\n${props.description}`,
      title: props.title,
    };
    createSong(songData);
  };

  const createSongHandler = () => handleSongCreation(createNewSong);
  const createAiSongHandler = (props: ICreateAiInspiredSongProps) =>
    handleSongCreation(createNewAiSong, props);

  return { createSongHandler, createAiSongHandler };
};
