import { combineReducers } from "redux";
import { decadesReducer } from "redux/decades/decades_slice";
import { chordsReducer } from "./chords/chords_slice";
import { lessonsReducer } from "./lessons/lessons_slice";
import { recommendationReducer } from "./recommendation/recommendation_slice";
import { slidesReducer } from "./slides/slides_slice";
import { stripeReducer } from "./stripe/stripe_slice";
import { userLessonsReducer } from "./user_lessons/user_lessons_slice";
import { userSkillsReducer } from "./user_skills/user_skills_slice";
import { transitionsReducer } from "./transitions/transitions_slice";
import { progressionsReducer } from "./progressions/progressions_slice";
import { organizationReducer } from "../organizations/organization_slice";
import { coursesReducer } from "../courses/courses_slice";
import { organizationManagementReducer } from "../org_management/org_management_slice";
import { storeKitReducer } from "./store_kit/store_kit_slice";

export const entitiesReducer = combineReducers({
  progressions: progressionsReducer,
  transitions: transitionsReducer,
  chords: chordsReducer,
  decades: decadesReducer,
  lessons: lessonsReducer,
  recommendation: recommendationReducer,
  slides: slidesReducer,
  userLessons: userLessonsReducer,
  stripe: stripeReducer,
  userSkills: userSkillsReducer,
  organization: organizationReducer,
  courses: coursesReducer,
  orgManagement: organizationManagementReducer,
  storeKit: storeKitReducer,
});
