import axios from "axios";
import { DEV } from "util/vars";

// parse URL params from password reset link
export const getURLParams = () => {
  const params: { [key: string]: string } = {};
  const pairs = window.location.href.split("?")[1];
  if (pairs) {
    const pairsArr = pairs.split("#/")[0].split("&");
    pairsArr.forEach((pair) => {
      const splitPair = pair.split("=");
      // eslint-disable-next-line prefer-destructuring
      params[splitPair[0]] = splitPair[1];
    });
  }
  return params;
};

export const setAxiosHeaders = () => {
  const params = getURLParams();
  const headers = ["access-token", "client", "expiry"];

  axios.defaults.headers.common["token-type"] = "Bearer";
  axios.defaults.headers.common.uid = decodeURIComponent(params.uid);
  headers.forEach((token) => {
    axios.defaults.headers.common[token] = params[token];
  });
  if (DEV) {
    console.info("AXIOS HEADERS: ", axios.defaults.headers);
  }

  // remove query params from URL
  window.history.replaceState({}, document.title, "/");
};
