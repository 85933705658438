import queryString from "query-string";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { applyPromo } from "redux/auth/auth_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { isAuthorizedSelector } from "redux/auth/user_selectors";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { StepList } from "redux/step_list/stepListContainer";

import { useHistory } from "react-router-dom";
import { AddStudents } from "./steps/add_students";
import { AddStudentsBulk } from "./steps/add_students_bulk";
import { AddStudentsManually } from "./steps/add_students_manually";
import { BulkImportFromExternalResources } from "./steps/bulk_import_from_external_resources/bulk_import_from_external_resources";
import { BulkImportFromGoogleClassroom } from "./steps/bulk_import_from_external_resources/bulk_import_from_google_classroom/bulk_import_from_google_classroom";
import { BulkImportFromMsTeams } from "./steps/bulk_import_from_external_resources/bulk_import_from_ms_teams/bulk_import_from_ms_teams";
import { ChooseMember } from "./steps/choose_member";
import { ChoosePayment } from "./steps/choose_payment";
import { ConfirmEmail } from "./steps/confirm_email";
import { CreateTeacherAccount } from "./steps/create_account";
import { CreateClass } from "./steps/create_class";
import { CreateOrganization } from "./steps/create_organization";
import { GetQuote } from "./steps/get_quotes/get_quote";
import { PayByCard } from "./steps/pay_by_card";
import { PayByCheckNextSteps } from "./steps/pay_by_check_next_step";
import { SetPassword } from "./steps/setPassword";
import { TeacherPlans } from "./steps/teacher_plans";

export const stepsId = {
  chooseMember: 1,
  teacherPlans: 2,
  createAccount: 3,
  confirmEmail: 4,
  setPassword: 5,
  createOrg: 6,
  choosePayment: 7,
  payByCard: 8,
  getQuote: 9,
  payByCheckNext: 10,
  createClass: 11,
  addStudents: 12,
  addStudentsBulk: 13,
  addStudentsManually: 14,
  bulkImportFromExternalResources: 15,
  bulkImportFromGoogleClassroom: 16,
  bulkImportFromMsTeams: 17,
};

interface ISignUpSteps {
  location: { state: { initStep: number } };
}

export const SignUpSteps = ({ location }: ISignUpSteps) => {
  const dispatch = useDispatch();
  const { redirect_url, url_token } = queryString.parse(window.location.hash);

  const { promo } = queryString.parse(window.location.search);

  const history = useHistory();

  useEffect(() => {
    if (redirect_url && url_token) {
      localStorage.setItem("redirect_url", String(redirect_url));
      localStorage.setItem("url_token", String(url_token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirect_url");
    const urlToken = localStorage.getItem("url_token");
    return () => {
      if (redirectUrl && urlToken) {
        history.push(`/${redirectUrl}/&url_token=${urlToken}`);
      }
    };
  });

  promo && dispatch(applyPromo(promo));
  const initStep = location.state?.initStep;
  const userRole = useSelector(getRoleSelector);
  const isUserAuth = useSelector(isAuthorizedSelector);

  const steps = [
    { id: stepsId.chooseMember, Component: <ChooseMember /> },
    { id: stepsId.teacherPlans, Component: <TeacherPlans /> },
    { id: stepsId.createAccount, Component: <CreateTeacherAccount /> },
    { id: stepsId.confirmEmail, Component: <ConfirmEmail /> },
    { id: stepsId.setPassword, Component: <SetPassword /> },
    { id: stepsId.createOrg, Component: <CreateOrganization /> },
    { id: stepsId.choosePayment, Component: <ChoosePayment /> },
    { id: stepsId.payByCard, Component: <PayByCard /> },
    { id: stepsId.getQuote, Component: <GetQuote /> },
    { id: stepsId.payByCheckNext, Component: <PayByCheckNextSteps /> },
    { id: stepsId.createClass, Component: <CreateClass /> },
    { id: stepsId.addStudents, Component: <AddStudents /> },
    { id: stepsId.addStudentsBulk, Component: <AddStudentsBulk /> },
    { id: stepsId.addStudentsManually, Component: <AddStudentsManually /> },
    {
      id: stepsId.bulkImportFromExternalResources,
      Component: <BulkImportFromExternalResources />,
    },
    {
      id: stepsId.bulkImportFromGoogleClassroom,
      Component: <BulkImportFromGoogleClassroom />,
    },
    {
      id: stepsId.bulkImportFromMsTeams,
      Component: <BulkImportFromMsTeams />,
    },
  ];

  let startStep = 1;
  if (initStep === stepsId.setPassword) {
    startStep = stepsId.setPassword;
  } else if (userRole === ROLES.FREE_TEACHER_ACCOUNT) {
    startStep = stepsId.teacherPlans;
  } else {
    startStep = steps[0].id;
  }

  const Component = useMemo(() => {
    return isUserAuth && userRole !== ROLES.FREE_TEACHER_ACCOUNT ? (
      <Redirect to="/" />
    ) : (
      <StepList
        stepListId={STEP_LIST_IDS.signUpStepList}
        items={steps}
        setStartStep={startStep}
      />
    );
    // eslint-disable-next-line
  }, []);

  return Component;
};
