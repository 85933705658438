import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formSelector,
  isButtonDisabledSelector,
} from "redux/ui/form/form_selectors";
import { SSHLogIn } from "components/sign_up/reusable/SSHLogIn";
import { getSignUpStepsUserDataSelector } from "redux/sign_up_steps/sign_up_steps_selectors";
import { closeModal, openLoginModal } from "redux/ui/modal/modal_slice";
import { applyLoginType } from "redux/auth/auth_slice";
import {
  getPromoRoleId,
  isAuthorizedSelector,
} from "redux/auth/user_selectors";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import Button, { BTN_TYPE } from "components/reusable/buttons/button";
import { roleFromRoleId } from "redux/auth/roles/roles_helpers";
import { Typography } from "@material-ui/core";
import { ButtonAuth } from "../login_modal/buttonAuth";
import { ModalContainer } from "../modal_container";
import { SignUpForm } from "./signup_form";
import { AvailableProvidersEnum } from "../login_modal";

import css from "../login_modal/login_modal.module.scss";

export const SignupModal = () => {
  const dispatch = useDispatch();
  const disabled = useSelector(isButtonDisabledSelector);
  const { url } = useSelector(getModalDataSelector);
  const confirm = useSelector(formSelector).reqPWReset;
  const userData = useSelector(getSignUpStepsUserDataSelector);
  const promoRole = useSelector(getPromoRoleId);
  const [isShowForm, setIsShowForm] = useState(false);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const mobile = Boolean(
    navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/),
  );
  const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");

  useEffect(() => {
    isAuthorized && dispatch(closeModal());
  }, [dispatch, isAuthorized]);

  const handleOpenLoginModal = (url: string) => {
    dispatch(openLoginModal({ url }));
  };

  const handleShowForm = () => {
    setIsShowForm(!isShowForm);
  };

  const termsOfServiceLink = new URL("https://moosiko.com/app-privacy-policy/");

  dispatch(applyLoginType("SignUp"));

  return (
    <ModalContainer noCloseButton={mobile}>
      <div className={css.title}>Create Account</div>
      {confirm ? (
        <>
          <div className={css.description}>
            Security and privacy are important to us.
          </div>
          <div className={css.description}>{confirm}</div>
        </>
      ) : (
        <>
          <div className={css.description} />
          {isShowForm && (
            <div className={css.form}>
              <div className={css.description}>
                Please enter your name and email address to create an account.
              </div>
              <SignUpForm
                role={userData?.role || roleFromRoleId(promoRole, mobile)}
              />
              <Button
                showLoader={disabled}
                type={BTN_TYPE.PRIMARY}
                form="signUp_default_form"
              >
                Sign Up
              </Button>
              <div className={css.divider__wrapper}>
                <span className={css.test}>OR</span>
              </div>
            </div>
          )}
        </>
      )}
      <div className={css.button_container}>
        <SSHLogIn provider={AvailableProvidersEnum.google}>
          <ButtonAuth provider={AvailableProvidersEnum.google} />
        </SSHLogIn>
      </div>
      {!iOSApp && (
        <div className={css.button_container}>
          <SSHLogIn provider={AvailableProvidersEnum.microsoft}>
            <ButtonAuth provider={AvailableProvidersEnum.microsoft} />
          </SSHLogIn>
        </div>
      )}
      {!isShowForm && (
        <div className={css.button_container}>
          <ButtonAuth
            provider={AvailableProvidersEnum.email}
            handleClick={handleShowForm}
            overideLabel="Create account with"
          />
        </div>
      )}
      {!isShowForm && (
        <div className={css.footer}>
          <div className={css.description_bottom}>Already have an account?</div>
          <button
            type="button"
            className={css.link}
            onClick={() => handleOpenLoginModal(url)}
          >
            Log In
          </button>
        </div>
      )}
      <div className={css.terms}>
        By signing up you agree to the{" "}
        <a
          href={termsOfServiceLink.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          Moosiko&nbsp;Privacy&nbsp;Policy
        </a>
      </div>
      <Typography
        style={{
          fontFamily: "Raleway",
          textAlign: "center",
          color: "grey",
          fontWeight: "400",
          padding: "0px 20px 25px 20px",
        }}
      >
        Need help signing up? Email{" "}
        <a
          href="mailto:help@moosiko.com"
          style={{ textDecoration: "underline", color: "inherit" }}
        >
          help@moosiko.com
        </a>
      </Typography>
    </ModalContainer>
  );
};
