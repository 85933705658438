/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassroomCourses,
  getClassroomFetchingData,
} from "redux/import_students/google_classroom/google_classroom_students_selectors";
import { listGoogleClassroomCourses } from "redux/import_students/google_classroom/google_classroom_students_slice";
import { handleClassroomAuthClick } from "../../../../helpers/google_classroom_auth_helper";
import { ExternalResource } from "../add_from_external_resources";

import { CourseList } from "../course_list/course_list";
import { CourseStudentsList } from "../course_students_table/course_students_table";

export enum GoogleClassroomSteps {
  COURSE_LIST = 1,
  STUDENTS_LIST = 2,
}

export const AddFromGoogleClassroom = () => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<GoogleClassroomSteps>(
    GoogleClassroomSteps.COURSE_LIST,
  );

  useEffect(() => {
    handleClassroomAuthClick(async (resp: any) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      dispatch(listGoogleClassroomCourses({}));
    });
  }, [dispatch]);

  return currentStep === GoogleClassroomSteps.COURSE_LIST ? (
    <CourseList
      type={ExternalResource.GOOGLE_CLASSROOM}
      setCurrentStep={setCurrentStep}
    />
  ) : (
    <CourseStudentsList
      type={ExternalResource.GOOGLE_CLASSROOM}
      setCurrentStep={setCurrentStep}
    />
  );
};
