import styled from "@emotion/styled";
import { SwitchSettings } from "components/reusable/switch/switch_settings/switch_settings";
import { updateCourse } from "redux/courses/courses_slice";
import { useDispatch } from "react-redux";
import { ICourse } from "redux/courses/courses_api";

interface ISwitchToggle {
  course: ICourse;
}

export const CourseSwitch = ({ course }: ISwitchToggle) => {
  const dispatch = useDispatch();
  const handleExplicitToggleChange = () => {
    dispatch(updateCourse({ courseId: course.id, explicit: !course.explicit }));
  };

  return (
    <SwitchContainer>
      <SwitchSettings
        name="explicitSwitch"
        onChange={handleExplicitToggleChange}
        checked={!course.explicit}
      />
    </SwitchContainer>
  );
};

const SwitchContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px;

  div {
    padding-left: 10px;
  }
`;
