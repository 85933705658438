import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createCourse } from "redux/courses/courses_slice";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { openAddStudentsModal } from "redux/ui/modal/modal_slice";
import { AppDispatch } from "types/redux_";
import { CreateClassInputsContainer } from "components/sign_up/reusable/create_class_inputs_container";
import { courseCompletionDateDefault } from "components/sign_up/reusable/helper/input_add_class_helper";
import { ICreateCourse } from "redux/courses/courses_api";
import { ADD_STUDENTS } from "../organization_constants";
import css from "./create_class_tab.module.scss";

interface ICreateClassTab {
  setTab: React.Dispatch<React.SetStateAction<number>>;
}
// eslint-disable-next-line no-magic-numbers
export const ONE_YEAR_LIMIT = 1000 * 60 * 60 * 24 * 365;

export const CreateClassTab = (props: ICreateClassTab) => {
  const { setTab } = props;
  const orgId = useSelector(getOrganizationId);
  const dispatch = useDispatch<AppDispatch>();

  const [courseName, setCourseName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(
    courseCompletionDateDefault(),
  );

  const history = useHistory();
  const goToCreatedCourse = (id: number) => history.push(`/organization/${id}`);

  const handleCreateClass = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!endDate || !startDate || endDate <= startDate || !orgId) {
      toast.error("Choose correct dates");
    }
    // @ts-ignore
    else if (endDate - startDate > ONE_YEAR_LIMIT) {
      toast.error("Class subscriptions cannot be longer than 12 months");
    } else {
      const result = await dispatch(
        createCourse({
          orgId,
          name: courseName,
          startDate,
          endDate,
        }),
      );

      const { id } = result.payload as ICreateCourse;
      goToCreatedCourse(id);
      dispatch(openAddStudentsModal(id));
      setTab(ADD_STUDENTS);
    }
  };

  const inputValue = {
    courseName,
    setCourseName,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
  };

  return (
    <form className={css.form} onSubmit={handleCreateClass}>
      <CreateClassInputsContainer input={inputValue} />
      <div className={css.next_button}>
        <Button
          type="submit"
          className={css.createButton}
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </div>
    </form>
  );
};
