/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getClassModalCourseId } from "redux/ui/modal/modal_selectors";
import css from "./add_students_tab.module.scss";
import Manually from "../../../img/manually.png";
import GoogleClassroomLogo from "../../sign_up/images/google-classroom-logo.svg";
import MsTeamsLogo from "../../sign_up/images/microsoft-teams-logo.svg";
import Bulk from "../../../img/bulk.png";
import { ImportOptions } from "./constants";
import { SubTabs } from "./sub_tabs/sub_tabs";
import { TabButton } from "./tab_button/tab_button";

export interface IAddStudentsTab {
  setSelectedStudentsCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const AddStudentsTab = (props: IAddStudentsTab) => {
  const { setSelectedStudentsCount } = props;
  const { ADD_BULK, ADD_MANUALLY, NO_TAB, ADD_FROM_EXTERNAL_RESOURCES } =
    ImportOptions;
  const [subTab, setSubTab] = useState<ImportOptions>(NO_TAB);
  const courseId = useSelector(getClassModalCourseId);
  return subTab === NO_TAB ? (
    <div className={css.container}>
      <TabButton
        imgSource={Bulk}
        setSubTab={setSubTab}
        tabTitle={ADD_BULK}
        header="Add Bulk"
        text="Upload CSV"
      />
      <TabButton
        imgSource={Manually}
        setSubTab={setSubTab}
        tabTitle={ADD_MANUALLY}
        header="Add manually"
        text="Add 1 by 1"
      />
      <TabButton
        imgSource={GoogleClassroomLogo}
        extraImgSource={MsTeamsLogo}
        setSubTab={setSubTab}
        tabTitle={ADD_FROM_EXTERNAL_RESOURCES}
        header="Import students"
      />
    </div>
  ) : (
    <SubTabs
      subTab={subTab}
      courseId={courseId}
      setSelectedStudentsCount={setSelectedStudentsCount}
    />
  );
};
