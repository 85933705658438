import InfoIcon from "@material-ui/icons/Info";
import unProtectedIcon from "../icons/unprotected.svg";
import protectedIcon from "../icons/protected.svg";

import css from "../songbuilder_main_section.module.scss";

interface IProtectionBadge {
  openAction: () => void;
  protectedStatus: boolean;
}

export const ProtectionBadge = ({
  openAction,
  protectedStatus,
}: IProtectionBadge) => {
  return (
    <div
      className={css.song_protection_area}
      onClick={openAction}
      role="button"
      tabIndex={0}
    >
      <img
        src={protectedStatus ? protectedIcon : unProtectedIcon}
        alt={
          protectedStatus
            ? "A musical note on a white lock on a black shield"
            : "A musical note on a white lock on a red shield"
        }
        className={css.song_protection_shield}
      />
      <div className={css.song_protection_label_area}>
        <div className={css.song_protection}>
          This song is {protectedStatus ? "Protected" : "Unprotected"}
        </div>
        <div className={css.song_protection_info_icons}>
          <InfoIcon fontSize="inherit" />
        </div>
      </div>
    </div>
  );
};
