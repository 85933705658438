import { guitarChords } from "./guitarChords";
import { ukuleleChords } from "./ukuleleChords";

export const chords: {
  [key: string]: {
    [key: string]: {
      front_correct: string;
      front_incorrect: string;
      top_correct: string;
      top_incorrect: string;
      diagram_correct: string;
      diagram_incorrect: string;
      diagram_top_level: string;
    };
  };
} = {
  Guitar: guitarChords,
  Ukulele: ukuleleChords,
};

export interface IChordImage {
  front_correct: string;
  front_incorrect: string;
  top_correct: string;
  top_incorrect: string;
  diagram_correct: string;
  diagram_incorrect: string;
  diagram_top_level: string;
}
