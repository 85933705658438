import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Switch from "@material-ui/core/Switch";
import { compareAsc } from "date-fns";
import classNames from "classnames";
import { IState } from "redux/store";
import css from "./select_date_range.module.scss";

const HOURS = 23;
const MINUTES = 59;
const SECONDS = 59;

const START_DATE = new Date();
START_DATE.setHours(0, 0, 0, 0);

const END_DATE = new Date();
END_DATE.setHours(HOURS, MINUTES, SECONDS, 0);

interface ISelectDateRange {
  // getRecordsFunction will return either IMediaRecord[] or IOrgSongs[]
  getRecordsFunction: (_: IState) => any[];
  setDateFunction: (_: { active: boolean; filteredRecords: any[] }) => void;
  dropSelectionsFunction: (_: IState) => void;
}

export const SelectDateRange: React.FC<ISelectDateRange> = ({
  getRecordsFunction,
  setDateFunction,
  dropSelectionsFunction,
}) => {
  const dispatch = useDispatch();
  const records = useSelector(getRecordsFunction);

  const [startDateRange, setStartDateRange] = useState(START_DATE);
  const [active, setActive] = useState(false);
  const [endDateRange, setEndDateRange] = useState(END_DATE);

  const shouldDropSelection = useSelector(dropSelectionsFunction);
  useEffect(() => {
    setActive(false);
  }, [shouldDropSelection]);

  useEffect(() => {
    dispatch(
      setDateFunction({
        active,
        filteredRecords: active
          ? records.filter((record) => {
              const isStartOk =
                compareAsc(startDateRange, new Date(record.createdAt)) <= 0;
              const isEndOk =
                compareAsc(endDateRange, new Date(record.createdAt)) >= 0;
              return isStartOk && isEndOk;
            })
          : [],
      }),
    );
  }, [
    dispatch,
    records,
    active,
    startDateRange,
    endDateRange,
    setDateFunction,
  ]);

  const [selectSkillTypePopover, setSelectSkillTypePopover] =
    useState<HTMLButtonElement | null>(null);
  const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectSkillTypePopover(event.currentTarget);
  };
  const handleClosePopup = () => {
    setSelectSkillTypePopover(null);
  };

  return (
    <>
      <button
        onClick={handleOpenPopup}
        className={classNames(css.sort_button, {
          [css.sort_button_active]: active,
        })}
        type="button"
      >
        Date Range
      </button>
      <Popover
        anchorEl={selectSkillTypePopover}
        keepMounted
        open={Boolean(selectSkillTypePopover)}
        onClose={handleClosePopup}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.filtering_container}>
          <div className={css.date_container}>
            <div className={css.active_container}>
              <div>Active</div>
              <Switch
                checked={active}
                onChange={(event) => setActive(event.target.checked)}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <div className={css.checkbox_container}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  className={css.dateInput}
                  variant="inline"
                  format="MMM d yyyy"
                  margin="normal"
                  label="Start Date"
                  value={startDateRange}
                  onChange={(date) => {
                    if (date) {
                      date.setHours(0, 0, 0, 0);
                      setStartDateRange(date);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={css.date_container}>
            <div className={css.checkbox_container}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  className={css.dateInput}
                  variant="inline"
                  format="MMM d yyyy"
                  margin="normal"
                  label="End Date"
                  value={endDateRange}
                  onChange={(date) => {
                    if (date) {
                      date.setHours(HOURS, MINUTES, SECONDS, 0);
                      setEndDateRange(date);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};
