import { useSelector } from "react-redux";
import { getFirstOrganization } from "redux/organizations/organization_selectors";
import { Container, ColumnName, Row, Header, Cell } from "../common/common";
import { LessonCheckbox } from "./lesson_checkbox";

export const LessonSettings = () => {
  const orgInfo = useSelector(getFirstOrganization);

  const modes = {
    tab: orgInfo?.tab,
    staff: orgInfo?.staff,
  };

  return (
    <Container>
      <Header>Lesson Settings</Header>
      <ColumnName>Staff</ColumnName>
      <ColumnName lastCell>Tab</ColumnName>

      <Row>Show Staff & Tab for Melody Lessons</Row>

      <Cell>
        <LessonCheckbox target="staff" modeStatus={modes} />
      </Cell>
      <Cell lastCell>
        <LessonCheckbox target="tab" modeStatus={modes} />
      </Cell>
    </Container>
  );
};
