import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getSignUpStepsUserDataSelector } from "redux/sign_up_steps/sign_up_steps_selectors";
import { SubmitHandler, useForm } from "react-hook-form";
import { registerTeacherSignUp } from "redux/auth/auth_slice";
import { userApi } from "api/user_api";
import { getErrors } from "redux/errors/errors_selectors";
import { AppDispatch } from "types/redux_";
import { isActionRejectedHelper } from "helpers/is_action_rejected_helper";
import { EmotionInput } from "components/sign_up/reusable/styled_input";
import { Container, Header, StretchContainer, Top } from "../emotion.styles";
import { CloseStepsButton } from "../../reusable/close_steps_button";
import { FooterSignUp } from "../../reusable/footer_sign_up";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import {
  StyledForm,
  StyledBytton,
  Content,
  Description,
} from "./confirm_email.styled";

import checkTrueLogo from "../../images/check-true-logo.svg";

interface IFormInputs {
  email: string;
}

const FORM_SEND_NEW_LINK = "form_send_new_link_confirm_email";

export const ConfirmEmail = () => {
  const dispatch = useDispatch<AppDispatch>();
  const signUpData = useSelector(getSignUpStepsUserDataSelector);
  const errors = useSelector(getErrors).session.signup;
  const [toggleNewEmail, setToggleNewEmail] = useState(false);
  const { register, handleSubmit } = useForm<IFormInputs>();

  const handleEditToggle = () => {
    setToggleNewEmail((prev) => !prev);
  };

  let emailErr;
  if (errors?.email) {
    emailErr = `Email ${errors.email[0]}`;
  }

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const {
      fname: firstName,
      lname: lastName,
      role,
      email,
    } = signUpData || {
      role: null,
      firstName: null,
      lastName: null,
    };

    if (toggleNewEmail && role && firstName && lastName) {
      const result = await dispatch(
        registerTeacherSignUp({ firstName, lastName, email: data.email, role }),
      );

      if (isActionRejectedHelper(result)) {
        return;
      }

      handleEditToggle();
    } else {
      email && userApi.resendConfirmation(email);
    }
  };

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={27} />
        <CloseStepsButton />
      </Top>
      <Container>
        <Content>
          <Header>Confirm Your School Email Address</Header>
          <LogoWithBackground
            src={checkTrueLogo}
            alt="defaultAvatar-plan-logo"
            padding="33px 29px"
            margin="1em 0 0 0"
          />
          <Description>
            <StyledForm
              onSubmit={handleSubmit(onSubmit)}
              id={FORM_SEND_NEW_LINK}
            >
              {!toggleNewEmail ? (
                <>
                  {"We sent a confirmation email to:\n"}
                  <span>
                    {signUpData?.email}{" "}
                    <StyledBytton type="button" onClick={handleEditToggle}>
                      (edit)
                    </StyledBytton>
                  </span>
                </>
              ) : (
                <>
                  Enter new email:
                  <EmotionInput
                    placeholder="Email"
                    type="email"
                    required
                    customErr={emailErr}
                    {...register("email")}
                  />
                </>
              )}
            </StyledForm>
            {
              "\n\nCheck your email and click on the confirmation link to continue.\n\n"
            }
            Didn’t receive a confirmation email? Sometimes districts block
            incoming confirmation emails. Go back and signup with SSO or email
            help@moosiko.com and we’ll get you all set up.
          </Description>
        </Content>
      </Container>
      <FooterSignUp
        type="submit"
        form={FORM_SEND_NEW_LINK}
        textButton="Resend Link"
      >
        Need help? Email{" "}
        <a
          href="mailto:help@moosiko.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          help@moosiko.com
        </a>
      </FooterSignUp>
    </StretchContainer>
  );
};
