import { createSelector } from "reselect";

export const getModalType = createSelector(
  [(state) => state.ui.modal],
  (modal) => modal.type,
);

export const getModalSongwriting = createSelector(
  [(state) => state.ui.modal],
  (modal) => modal.songwriting,
);

export const getModalDataSelector = createSelector(
  [(state) => state.ui.modal],
  (modal) => modal,
);

export const getSongNameSelector = createSelector(
  [getModalDataSelector],
  (modal) => modal.songName,
);

export const getLessonIdSelector = createSelector(
  [getModalDataSelector],
  (modal) => modal.lessonId,
);
export const getLessonProgressSelector = createSelector(
  [getModalDataSelector],
  (modal) => modal.progress,
);

export const getSignUpStateSelector = createSelector(
  [getModalDataSelector],
  (modal) => modal.signUpState,
);

export const getModalOpenState = createSelector(
  [getModalDataSelector],
  (modal) => modal.open,
);

export const getAddClassModalStatus = createSelector(
  [getModalDataSelector],
  (modal) => modal.addClassModalOpened || false,
);

export const getClassModalInfoDisabled = createSelector(
  [getModalDataSelector],
  (modal) => modal.infoDisabled || false,
);

export const getClassModalCourseId = createSelector(
  [getModalDataSelector],
  (modal) => modal.courseId,
);

export const getChordLibraryModalStatus = createSelector(
  [getModalDataSelector],
  (modal) => modal.chordLibrary,
);

export const getStudentOnboardModalStatus = createSelector(
  [getModalDataSelector],
  (modal) => modal.studentOnboard,
);

export const getStudentPlansModalStatus = createSelector(
  [getModalDataSelector],
  (modal) => modal.studentPlans,
);

export const getStudentPayModalStatus = createSelector(
  [getModalDataSelector],
  (modal) => modal.studentPay,
);

export const getStudentParentModalStatus = createSelector(
  [getModalDataSelector],
  (modal) => modal.studentParentEmail,
);
