import styled from "@emotion/styled";

export const Paragraph = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5em;
`;

export const ContentHeader = styled.div`
  color: #190067;
  font-weight: 700;
`;

export const Description = styled.div`
  margin: 0 1.5em;
  color: #818181;
  max-width: 800px;
  > span {
    font-weight: 700;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: start;
`;
