import React, { ChangeEvent, useEffect, useState } from "react";

/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { SongItemsGrid } from "../song_items_grid/song_items_grid";
import css from "./build_song_bar.module.scss";
import { getChordsByKey } from "./chords_list/chords_list";
import { SONG_STRUCTURE_LIST } from "./song_structure_list/song_structure_list";
import { RhymesAndThesaurus } from "./rhymes_and_thesaurus/rhymes_and_thesaurus";
import { ChordAccordion } from "./chord_accordion/chord_accordion";

enum AccordionTab {
  SONG_STRUCTURE,
  CHORDS,
  RHYMES,
}

const accordions = [
  {
    key: "song_structure",
    summary: "Song Structure",
    tab: AccordionTab.SONG_STRUCTURE,
    expanded: true,
    content: SONG_STRUCTURE_LIST,
    draggable: true,
  },
  {
    key: "chords",
    tab: AccordionTab.CHORDS,
    expanded: true,
    draggable: true,
  },
  {
    key: "rhymes_and_thesaurus",
    summary: "Rhymes & Thesaurus",
    tab: AccordionTab.RHYMES,
    expanded: true,
    draggable: false,
  },
];

const updateChordSummary = (chord_icon_style: string) => {
  const found = accordions.findIndex((element) => element.key === "chords");
  accordions[found].summary = `${chord_icon_style} Chords`;
};

export const BuildSongBar = () => {
  const [activeAccordion, setActiveAccordion] = useState(accordions);
  const customSong = useSelector(getCurrentCustomSong);
  const { custom_song_key } = customSong;
  const chord_icon_style = customSong.chord_icon_style || "Guitar";
  const [currentChordSearch, setCurrentChordSearch] = useState<string>("");
  const [currentChords, setCurrentChords] = useState<
    { title: string; icon: any }[]
  >([]);

  updateChordSummary(chord_icon_style);

  const expandHandler = (
    event: ChangeEvent<{ id: unknown }>,
    isExpanded: boolean,
  ) => {
    const selectedAcc = accordions[event.currentTarget.id as number];

    setActiveAccordion((prev) => {
      selectedAcc.expanded = !selectedAcc.expanded;
      const updatedArray = prev.map((item) => {
        if (item.tab === selectedAcc.tab) {
          return selectedAcc;
        }
        return item;
      });
      return [...updatedArray];
    });
  };

  useEffect(() => {
    (async () => {
      const result = await getChordsByKey(custom_song_key, chord_icon_style);
      setCurrentChords(result);
    })();
  }, [custom_song_key, chord_icon_style]);

  useEffect(() => {
    setActiveAccordion((prev) => {
      const updatedArray = prev.map((item) => {
        if (item.key === "chords") {
          return { ...item, summary: `${chord_icon_style} chords` };
        }
        return item;
      });
      return [...updatedArray];
    });
  }, [chord_icon_style]);

  const getAccordionDetails = (
    content: { title: string }[],
    draggable: boolean,
    type: AccordionTab,
  ) => {
    let component = null;
    switch (type) {
      case AccordionTab.SONG_STRUCTURE: {
        component = (
          <SongItemsGrid
            content={content.map((item) => ({
              ...item,
              draggable,
            }))}
            tipText="Drag and drop to build your song"
          />
        );
        break;
      }
      case AccordionTab.CHORDS: {
        component = (
          <div className={css.chordContainer}>
            <ChordAccordion
              content={content}
              setCurrentChordSearch={setCurrentChordSearch}
              currentChordSearch={currentChordSearch}
            />
          </div>
        );
        break;
      }
      case AccordionTab.RHYMES: {
        component = <RhymesAndThesaurus />;
        break;
      }
      default: {
        break;
      }
    }
    return (
      <AccordionDetails style={{ backgroundColor: "#f8f9fa" }}>
        {component}
      </AccordionDetails>
    );
  };

  return (
    <>
      {activeAccordion.map(({ summary, tab, content, draggable }) => (
        <Accordion
          elevation={0}
          key={summary}
          expanded={activeAccordion[tab].expanded}
          onChange={expandHandler as any}
        >
          <AccordionSummary
            style={{ backgroundColor: "#f8f9fa" }}
            id={`${tab}`}
            expandIcon={<ExpandMoreIcon />}
          >
            {summary}
          </AccordionSummary>
          {getAccordionDetails(content || currentChords, draggable, tab)}
        </Accordion>
      ))}
    </>
  );
};
