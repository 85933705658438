/* eslint-disable react/no-array-index-key */
import { Link, useParams } from "react-router-dom";
import Button, { BTN_TYPE } from "components/reusable/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getUserLessonsArraySelector } from "redux/entities/user_lessons/user_lesson_selectors";
import { finishLesson as finishLessonAction } from "redux/entities/user_skills/user_skills_slice";
import { getLessonsCacheSelector } from "redux/entities/lessons/lesson_selectors";
import { ISlide } from "api/lesson_api";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import css from "./slide_types.module.scss";
import image from "./images/high-five.png";
import dance from "./images/Celebration_Dance.png";

interface IPartEnd {
  slide: ISlide;
  isLast: boolean;
}

const PartEnd = (props: IPartEnd) => {
  const { slide, isLast } = props;
  const { id: lessonId } = useParams<{ id: string }>();
  const userLessons = useSelector(getUserLessonsArraySelector);
  const { instruments } = useSelector(getSongFilters);
  const userLesson = userLessons.find(
    (userLesson) => userLesson.lesson_id.toString() === lessonId,
  );
  const lesson = useSelector(getLessonsCacheSelector)[lessonId];
  const finished = !!(userLesson?.progress === 100);

  const { text_top, text_bottom } = slide;

  const items = text_bottom.split(";");

  const dispatch = useDispatch();

  const finishLesson = () => {
    dispatch(
      finishLessonAction({
        lessonId: Number(lessonId),
        instrument: instruments[0],
        undo: finished,
      }),
    );
  };

  // handle item that has song sheet link
  const assets = items.map((item, idx) => {
    if (item.match(/Song Sheet/i)) {
      // eslint-disable-next-line no-param-reassign
      const newItem = item.split(/Song Sheet/i);
      return (
        <li key={idx} className={css.list_item}>
          {newItem[0]}
          <Link to={`/lesson/${lesson.id}/song-sheet`}>Song Sheet</Link>
          {newItem[1]}
        </li>
      );
    }
    return (
      <li key={idx} className={css.list_item}>
        {item}
      </li>
    );
  });
  const dateOfFinish = moment(userLesson?.updated_at).format("ll");
  const buttonType = finished ? BTN_TYPE.COMPLETED : BTN_TYPE.PRIMARY;
  const buttonText = finished
    ? `Completed on ${dateOfFinish}`
    : "     I Know How To Play This";
  return (
    <div className={css.main}>
      <img
        src={isLast ? dance : image}
        className={css.part_end_image}
        alt="high five"
      />
      <h1 className={`${css.text_top} ${css.top_begin_end}`}>{text_top}</h1>
      <div className={css.list}>{assets.map((item) => item)}</div>
      {isLast && (
        <div className={css.finish_lesson_btn}>
          <Button type={buttonType} onClick={() => !finished && finishLesson()}>
            {buttonText}
          </Button>
          {finished && (
            <p
              role="presentation"
              className={css.undo_text}
              onClick={() => finishLesson()}
            >
              Undo Complete
            </p>
          )}
        </div>
      )}
    </div>
  );
};

PartEnd.propTypes = {};
PartEnd.defaultValues = {};
export default PartEnd;
