import React, { useState } from "react";
import { Dialog, TextField } from "@material-ui/core";
import { changeStudentPassword } from "redux/courses/courses_slice";
import { IStudentWithSkills } from "types/models/student_with_skills";
import { useDispatch } from "react-redux";
import css from "./reset_student_password_dialog.module.scss";

interface IResetStudentPasswordDialog {
  student: IStudentWithSkills;
  resetPasswordOpen: boolean;
  setResetPasswordDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetStudentPasswordDialog = (
  props: IResetStudentPasswordDialog,
) => {
  const {
    student: { id: studentId },
    resetPasswordOpen,
    setResetPasswordDialogOpen,
  } = props;

  const dispatch = useDispatch();

  const handleResetPassword = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(changeStudentPassword({ userId: studentId, password }));
    setResetPasswordDialogOpen(false);
  };

  const [password, setPasswords] = useState("");

  return (
    <Dialog
      open={resetPasswordOpen}
      onClose={() => setResetPasswordDialogOpen(false)}
    >
      <form onSubmit={handleResetPassword} className={css.reset_password_form}>
        <h2 className={css.reset_password_header}>Reset password</h2>
        <TextField
          label="password"
          type="password"
          inputProps={{ minLength: 6 }}
          variant="outlined"
          className={css.reset_password_input}
          onChange={(event) => setPasswords(event.target.value)}
        />
        <button className={css.reset_password_button} type="submit">
          Reset
        </button>
      </form>
    </Dialog>
  );
};
