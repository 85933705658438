/* eslint-disable no-unused-vars */
import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMediaRecordsStateWaiting,
  TMediaRecordingStatuses,
} from "redux/media_recordings/media_recordings_slice";
import { getMediaRecordingState } from "redux/media_recordings/media_recordings_selectors";
import { toast } from "react-toastify";
import { isTouchDevice } from "helpers/isTouchDevice";

// imports required  for videojs
import videojs from "video.js";
import "video.js/dist/video-js.css";

import "webrtc-adapter";
// @ts-ignore
import RecordRTC from "recordrtc";

// @ts-ignore
import "videojs-record/dist/css/videojs.record.css";
// @ts-ignore
import Record from "videojs-record/dist/videojs.record.js";
// @ts-ignore
import TsEBMLEngine from "videojs-record/dist/plugins/videojs.record.ts-ebml.js";
import { applyVideoWorkaround } from "./helpers/apply-video-workaround";
import css from "./media_recorder.module.scss";
import { options } from "./video_options";

// import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js";

const a = Record;
const b = TsEBMLEngine;
// import WaveSurfer from "wavesurfer.js";
// import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
// WaveSurfer.microphone = MicrophonePlugin;

interface IUseMediaRecorder {
  videoRef: { current: any };
  startRecord: () => void;
  playerRef: { current: any };
  openSendVideoDialog: (_?: MouseEvent) => void;
  setRecorderVideoSrc: React.Dispatch<React.SetStateAction<string>>;
  setRecordedData: React.Dispatch<React.SetStateAction<Blob | null>>;
}

export const UseMediaRecorder = ({
  startRecord,
  playerRef,
  openSendVideoDialog,
  setRecorderVideoSrc,
  setRecordedData,
  videoRef,
}: IUseMediaRecorder) => {
  const dispatch = useDispatch();
  const recordingState = useSelector(getMediaRecordingState);

  useLayoutEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    switch (recordingState) {
      case TMediaRecordingStatuses.RECORDING: {
        document
          .querySelector(".myVideo")
          ?.classList.add(css.video_element_active);
        break;
      }
      case TMediaRecordingStatuses.START: {
        if (isSafari) {
          toast.info(
            "Videos not supported on Safari. Please use Chrome browser to record videos",
          );
          dispatch(setMediaRecordsStateWaiting());
          break;
        }
        if (!isTouchDevice()) {
          startRecord();
        }
        break;
      }
      case TMediaRecordingStatuses.WAITING: {
        document
          .querySelector(".myVideo")
          ?.classList.remove(css.video_element_active);

        // @ts-ignore
        if (!playerRef.current?.record) {
          return () => {};
        }
        // @ts-ignore
        const record = playerRef.current?.record();

        if (record) {
          record.stop();
          record.stopDevice();
        }
        break;
      }
      default: {
        break;
      }
    }
    // don't add any dependencies because of re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingState]);

  useLayoutEffect(() => {
    applyVideoWorkaround(options);
    if (!videoRef.current) {
      console.error({ videoRef });
      return;
    }
    const player = videojs(videoRef.current, options);
    playerRef.current = player;

    player.on("deviceError", (e) => {
      // @ts-ignore
      console.error(e, player.deviceErrorCode);
      toast.error("No camera detected. Cannot record video");
      dispatch(setMediaRecordsStateWaiting());
    });

    player.on("deviceReady", () => {
      // @ts-ignore
      player.record().start();
    });

    player.on("finishRecord", async () => {
      dispatch(setMediaRecordsStateWaiting());
      // @ts-ignore

      const file = new File([player.recordedData], "temp-name", {
        type: "video/webm",
      });
      setRecorderVideoSrc(URL.createObjectURL(file));
      openSendVideoDialog();
      // @ts-ignore
      playerRef.current.record().reset();
      // @ts-ignore
      setRecordedData(player.recordedData);
    });

    return () => {
      player.dispose();
    };
    // don't add any dependencies because of re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
