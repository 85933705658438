import { CHORD, PROGRESSION, TRANSITION } from "constants/skills_constants";
import { createSelector } from "reselect";
import { IState } from "redux/store";
import { getUserCompletedLessons } from "redux/entities/lessons/lesson_selectors";
import { getChordsSelector } from "../chords/chords_selectors";
import { getProgressionsSelector } from "../progressions/progressions_selectors";
import { getTransitionsSelector } from "../transitions/transitions_selectors";
import { IInstruments } from "./user_skills_slice";

export const getUserChordSkillsSelector = createSelector(
  (state: IState) => state.entities.userSkills.chords,
  (chords) => [...chords.guitar, ...chords.ukulele],
);

export const getUserChordsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.userSkills.chords[instrument],
    (chords) => chords,
  );
export const getUserChordsAmountByInstrument = (instrument: IInstruments) =>
  createSelector(
    getUserChordsByInstrument(instrument),
    (chords) => chords.length,
  );

export const getUserChordsCountSelector = createSelector(
  getUserChordSkillsSelector,
  (chords) => chords.length,
);

export const getUserTransitionsSelector = (state: IState) => [
  ...state.entities.userSkills.transitions.guitar,
  ...state.entities.userSkills.transitions.ukulele,
];

export const getUserTransitionsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.userSkills.transitions[instrument],
    (transitions) => transitions,
  );
export const getUserTransitionsAmountByInstrument = (
  instrument: IInstruments,
) =>
  createSelector(
    getUserTransitionsByInstrument(instrument),
    (transitions) => transitions.length,
  );

export const getUserTransitionsCount = createSelector(
  [getUserTransitionsSelector],
  (transitions) => transitions.length,
);
export const getUserTransitionsLikeStringSelector = createSelector(
  [getUserTransitionsSelector],
  (transitions) =>
    transitions.map((transition) => JSON.parse(transition).join("-")),
);

export const getUserStrummingSongsSelector = (state: IState) => {
  const { guitar, ukulele } = state.entities.userSkills.strummingSongs;
  return { ...guitar, ...ukulele };
};

export const getUserStrummingSongsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.userSkills.strummingSongs[instrument],
    (strumming) => strumming,
  );

export const getUserMelodiesSongsSelector = createSelector(
  getUserCompletedLessons,
  (lessons) => {
    return lessons.filter((lesson) =>
      lesson.skills.some((skillType) => skillType.name === "Melody"),
    );
  },
);

export const getUserMelodiesSongsByInstrument = (instrument: IInstruments) =>
  createSelector(getUserCompletedLessons, (lessons) =>
    lessons.filter(
      (lesson) =>
        lesson.skills.some((skillType) => skillType.name === "Melody") &&
        lesson.instrument.toLocaleLowerCase() === instrument,
    ),
  );

export const getUserProgressionsSelector = (state: IState) => [
  ...state.entities.userSkills.progressions.guitar,
  ...state.entities.userSkills.progressions.ukulele,
];

export const getUserProgressionsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.userSkills.progressions[instrument],
    (progressions) => progressions,
  );
export const getUserProgressionsAmountByInstrument = (
  instrument: IInstruments,
) =>
  createSelector(getUserProgressionsByInstrument(instrument), (progressions) =>
    progressions ? progressions.length : 0,
  );

export const getUserProgressionsCount = createSelector(
  [getUserProgressionsSelector],
  (progressions) => progressions.length,
);

export const getUserProgressionsLikeStringSelector = createSelector(
  [getUserProgressionsSelector],
  (progressions) =>
    progressions.map((progression) => JSON.parse(progression).join("-")),
);

export const getUserSkillsSelector = createSelector(
  [(state: IState) => state.entities.userSkills],
  (skills) => skills,
);

export const getCurrentInstrument = createSelector(
  (state: IState) => state.entities.userSkills.currentInstrument,
  (currentInstrument) => currentInstrument,
);

export const getUserAllSkills = createSelector(
  [
    getUserProgressionsSelector,
    getUserTransitionsSelector,
    getUserChordSkillsSelector,
  ],
  (progressions, transitions, chords) => {
    return progressions.concat(transitions.concat(chords));
  },
);

/**
 * @param {string} skillType
 * @param {string} skillName
 * @param {function} getState
 */
export const getSkillId =
  (skillType: string, skillName: string) => (state: IState) => {
    switch (skillType) {
      case CHORD: {
        const chords = getChordsSelector(state);
        return chords.find((chord) => chord.name === skillName)?.id;
      }
      case TRANSITION: {
        const transitions = getTransitionsSelector(state);
        return transitions.find((transition) => transition.name === skillName)
          ?.id;
      }
      case PROGRESSION: {
        const progressions = getProgressionsSelector(state);
        return progressions.find(
          (progression) => progression.name === skillName,
        )?.id;
      }
      default: {
        throw new Error("Wrong skill type");
      }
    }
  };
