import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IComponentState {
  audioComponentExpandedId: number | null;
  audioSaveInProgress: boolean;
}

const initialState: IComponentState = {
  audioComponentExpandedId: null,
  audioSaveInProgress: false,
};

const componentsSlice = createSlice({
  name: "components",
  initialState,
  reducers: {
    setAudioComponentExpandedId: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.audioComponentExpandedId = action.payload;
    },
    setAudioSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.audioSaveInProgress = action.payload;
    },
  },
});

export const {
  reducer: componentsReducer,
  actions: { setAudioComponentExpandedId, setAudioSaveInProgress },
} = componentsSlice;
