import { useEffect, useRef } from "react";
import { TableRows } from "redux/songwriting/songwriting_interfaces";

interface IUseEditableCellsRefs {
  rows: TableRows;
}

export const useEditableCellsRefs = ({ rows }: IUseEditableCellsRefs) => {
  // const POSITION_OF_FIRST_INPUT = 1;
  // const isFirstRender = useRef(true);

  const editableCellRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    editableCellRefs.current = editableCellRefs.current.slice(0, rows.length);
  }, [rows]);

  return { editableCellRefs };
};
