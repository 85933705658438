import { loadStripe } from "@stripe/stripe-js";
import { ReactNode } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { stripePubKey } from "redux/entities/stripe/stripe_consts";

const stripePromise = loadStripe(stripePubKey);

interface IAsyncStripeProvider {
  // apiKey: string;
  children: ReactNode;
}

export const AsyncStripeProvider = (props: IAsyncStripeProvider) => {
  // const [stripe, setStripe] = useState<Stripe | null>(null);
  const { children } = props;
  // useEffect(() => {
  //   if (window.Stripe) {
  //     // setStripe(window.Stripe(apiKey));
  //   } else {
  //     document?.querySelector("#stripe-js")?.addEventListener("load", () => {
  //       // Create Stripe instance once Stripe.js loads
  //       // @ts-ignore
  //       setStripe(window.Stripe(apiKey));
  //     });
  //   }
  // }, [apiKey]);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
