import { useSelector } from "react-redux";
import { getTypeOfDragAndDropAction } from "redux/songwriting/songwriting_selectors";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useDroppable } from "@dnd-kit/core";

import css from "./songbuilder_chord_delete_footer.module.scss";

export const SongbuilderChordDeleteFooter = () => {
  const typeOfDragAndDropAction = useSelector(getTypeOfDragAndDropAction);
  const { setNodeRef } = useDroppable({
    id: "delete-chord",
  });

  return (
    <div
      style={{
        position: "fixed",
        bottom: "5%",
        width: "calc(100% - 450px)",
        display: "flex",
        justifyContent: "center",
        color: "#fff",
      }}
    >
      {(typeOfDragAndDropAction === "replace-chord" ||
        typeOfDragAndDropAction === "add-chord") && (
        <div
          className={css.delete_chord_footer_icon}
          ref={setNodeRef}
          style={{
            backgroundColor: "#979797",
            borderRadius: "50%",
            padding: "5px",
            opacity: 0.5,
          }}
        >
          <DeleteOutlineIcon />
        </div>
      )}
    </div>
  );
};
