/* eslint-disable no-unused-vars */

// Enum and eslint rules bug,
// to fix this we need to change eslint rule no-unused-vars to typescript-no-unused-vars,
// but now I just added eslint disable at the top

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}
