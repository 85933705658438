import React from "react";
import classnames from "classnames";

import { Spinner } from "components/loading/spinner_circle";
import css from "./button.module.scss";

export const BTN_TYPE = {
  DEFAULT: "default",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  COMPLETED: "completed",
  DARK: "dark",
} as const;

const BTN_TYPE_CLASSES = {
  [BTN_TYPE.DEFAULT]: css.default,
  [BTN_TYPE.PRIMARY]: css.primary,
  [BTN_TYPE.SECONDARY]: css.secondary,
  [BTN_TYPE.COMPLETED]: css.completed,
  [BTN_TYPE.DARK]: css.dark,
};

type ButtonType = typeof BTN_TYPE[keyof typeof BTN_TYPE];
type ButtonPropsType = {
  type?: ButtonType;
  classes?: { btn?: string };
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  showLoader?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  form?: string;
};

const Button = (props: ButtonPropsType) => {
  const {
    classes,
    disabled = false,
    showLoader,
    children,
    form,
    type = BTN_TYPE.DEFAULT,
    ...otherProps
  } = props;

  const btnDisabled = disabled || showLoader;

  return (
    <button
      type="submit"
      form={form}
      {...otherProps}
      disabled={btnDisabled}
      className={classnames(css.button, BTN_TYPE_CLASSES[type], classes?.btn, {
        [css.disabled]: btnDisabled,
      })}
    >
      {showLoader ? <Spinner /> : children}
    </button>
  );
};

export default Button;
