import { Popover } from "@material-ui/core";
import { Fragment } from "react";
import cn from "classnames";
import { ISlide } from "api/lesson_api";
import css from "./slide_bottom_popover.module.scss";

interface ISlideBottomPopover {
  selectAnchor: HTMLButtonElement | null;
  handleCloseSelect: () => void;
  slides: ISlide[];
  currentSlide: number;
  goToSlide: (_: number) => void;
}

export const SlideBottomPopover = ({
  selectAnchor,
  handleCloseSelect,
  slides = [],
  currentSlide,
  goToSlide,
}: ISlideBottomPopover) => {
  const beginSlides = slides.filter(
    (slide) => slide.slide_type === "part_begin",
  );
  const slidesByPart = [];

  let firstSlideIndex = 0;
  let lastSlideIndex = 1;
  for (let i = 0; i < beginSlides.length - 1; i++) {
    firstSlideIndex = beginSlides[i].slide_num;
    lastSlideIndex = beginSlides[i + 1].slide_num;
    slidesByPart.push(slides.slice(firstSlideIndex - 1, lastSlideIndex - 1));
  }

  // @ts-ignore
  slidesByPart.push(slides.slice(lastSlideIndex - 1));

  return (
    <Popover
      anchorEl={selectAnchor}
      keepMounted
      open={Boolean(selectAnchor)}
      onClose={handleCloseSelect}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      disableScrollLock={false}
    >
      <div className={css.container}>
        {slidesByPart.map((currentPart, currentPartIndex) => {
          return (
            <div key={currentPart[0].slide_num} className={css.part_container}>
              {currentPart.map((slide) => {
                if (slide.slide_type === "part_begin") {
                  return (
                    <Fragment key={slide.slide_num}>
                      <button
                        className={cn(css.slide_button_text, {
                          [css.slide_button_active]:
                            slide.slide_num - 1 === currentSlide,
                        })}
                        onClick={() => goToSlide(slide.slide_num - 1)}
                        type="button"
                      >
                        Part {currentPartIndex + 1}
                      </button>
                    </Fragment>
                  );
                }

                return (
                  <button
                    key={slide.slide_num}
                    className={cn(css.slide_button, {
                      [css.slide_button_active]:
                        slide.slide_num - 1 === currentSlide,
                    })}
                    onClick={() => goToSlide(slide.slide_num - 1)}
                    type="button"
                  >
                    {slide.slide_num}
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
    </Popover>
  );
};
