import { createSelector } from "reselect";

export const getFreePlanDescriptionItems = createSelector(
  () => undefined,
  () => {
    return [
      { id: 0, title: "3 free songwriting songs or song lessons" },
      {
        id: 1,
        title: "Access to songwriting builder and guitar / ukulele lessons",
      },
      { id: 2, title: "Learn chords, riffs, and solos" },
    ];
  },
);

export const getMemberPlanDescriptionItems = createSelector(
  () => undefined,
  () => {
    return [
      { id: 0, title: "Write and/or learn UNLIMITED songs" },
      { id: 1, title: "Personalized chord suggestions" },
      { id: 2, title: "Audio recording" },
      { id: 3, title: "Skill tracking" },
      { id: 4, title: "Sharing" },
    ];
  },
);

export const getSongwritingPlanDescriptionItems = createSelector(
  () => undefined,
  () => {
    return [
      { id: 0, title: "Write unlimited songs" },
      { id: 1, title: "Write unlimited song seeds: ideas, lyrics, and audio" },
      { id: 2, title: "Delete songs & seeds" },
      { id: 3, title: "Share via text, email" },
    ];
  },
);
