import { Link } from "react-router-dom";
import cn from "classnames";
import { FreeSongsRemains } from "components/free_songs_remains";
import { Logo } from "components/reusable/logo/Logo";
import { DNavRoute } from "routes/d_nav_route";
import { SongLinks } from "../shared/song_links";

import css from "../nav.module.scss";

const NavDesktop = () => {
  return (
    <div className={css.desktop}>
      <div className={css.left}>
        <Link to="/" className={css.logo}>
          <Logo />
        </Link>
        <SongLinks cssLink={css.nav_link} />
      </div>

      <div className={cn(css.right, css.menu_section)}>
        <div className={css.free_songs_remains_section}>
          <FreeSongsRemains />
        </div>

        <div>
          <DNavRoute css={css} />
        </div>
      </div>
    </div>
  );
};

export default NavDesktop;
