import { forwardRef, InputHTMLAttributes, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActualTableDataRows } from "redux/songwriting/songwriting_slice";
import { setAddChordPosition } from "redux/songwriting_mobile/songwriting_mobile_slice";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";
import { SONG } from "redux/songwriting/songwriting_consts";

import css from "./editable_cell.module.scss";

interface IEditableCell extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  index: number;
  songUnitId: number;
}

export const EditableCell = forwardRef<HTMLInputElement, IEditableCell>(
  ({ value, index, songUnitId, ...props }, ref) => {
    const [inputValue, setInputValue] = useState(value || "");
    const dispatch = useDispatch();

    const mobile = useSelector(getUsingMobileAppFlag);

    const onChange = useCallback(
      (e) => {
        const { value } = e.target;

        setInputValue(value);
        dispatch(
          updateActualTableDataRows({
            songUnitId,
            index,
            inputValue: value,
          }),
        );
      },
      [dispatch, index, songUnitId],
    );

    const onBlur = useCallback(
      (e) => {
        mobile &&
          dispatch(
            setAddChordPosition({
              songUnitId,
              row: index - 1,
              column: e.target.selectionStart,
            }),
          );
      },
      [dispatch, index, songUnitId, mobile],
    );

    const placeholderText = index === 1 ? "Add your lyrics here..." : "";
    return (
      <td colSpan={50} style={{ paddingLeft: 0 }}>
        <input
          {...props}
          ref={(el) => ref && typeof ref === "function" && ref(el)}
          onChange={onChange}
          value={inputValue}
          className={mobile ? css.input_font_mobile : css.input_font}
          maxLength={SONG.MAX_LENGTH_EDITABLE_CELL}
          type="text"
          placeholder={placeholderText}
          onBlur={onBlur}
          enterKeyHint="enter"
        />
      </td>
    );
  },
);
