import { useDispatch } from "react-redux";
import { ICourse } from "redux/courses/courses_api";
import { updateCourse } from "redux/courses/courses_slice";
import { StyledCheckbox } from "../common/common";

interface ICourseCheckbox {
  course: ICourse;
  target: "guitar_songs" | "ukulele_songs";
}
export const CourseCheckbox = ({ course, target }: ICourseCheckbox) => {
  const dispatch = useDispatch();
  const instruments = ["guitar_songs", "ukulele_songs"] as const;
  // This checkbox disabled, if only this one activated
  let disabled = true;
  if (course[target]) {
    const restInstruments = instruments.filter(
      (instrument) => target !== instrument,
    );

    restInstruments.map((restInstrument) => {
      if (course[restInstrument]) {
        disabled = false;
      }
      return null;
    });
  } else {
    disabled = false;
  }

  const handleToggleChange = () => {
    dispatch(updateCourse({ courseId: course.id, [target]: !course[target] }));
  };

  return (
    <StyledCheckbox
      disabled={disabled}
      checked={course[target]}
      onChange={handleToggleChange}
    />
  );
};
