import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  IPermissions,
  PERMISSIONS,
} from "redux/auth/permissions/permissions_consts";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { getUserSelector } from "redux/auth/user_selectors";
import queryString from "query-string";
import { toast } from "react-toastify";
import { SIGNUP } from "./route_constants";

interface IProtected {
  component: React.ElementType;
  path: string;
  exact?: boolean;
  permission?: IPermissions;
}
export const ProtectedRoute = ({
  component: Component,
  path,
  exact,
  permission,
}: IProtected) => {
  const loggedIn = useSelector(getUserSelector);
  const access = useSelector(
    accessByPermissionSelector(permission || PERMISSIONS.SONGWRITING),
  );

  return (
    <Route
      path={path}
      exact={exact}
      component={(props: unknown) => {
        if (!loggedIn) {
          const { redirect_url, url_token } = queryString.parse(
            window.location.hash,
          );
          if (redirect_url && url_token) {
            toast.info("Please login to accept invitation");
            localStorage.setItem("redirect_url", String(redirect_url));
            localStorage.setItem("url_token", String(url_token));
          }
          return <Redirect to={SIGNUP} />;
        }
        if (access) {
          // @ts-ignore
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};
