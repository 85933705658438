import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createOrganization } from "redux/organizations/organization_slice";
import css from "./create_org.module.scss";

export const CreateOrg = () => {
  const [email, setEmail] = useState("");
  const [orgName, setOrgName] = useState("");
  const dispatch = useDispatch();

  async function submitForm(event: React.FormEvent) {
    event.preventDefault();
    dispatch(createOrganization({ email, orgName }));
  }

  return (
    <form className={css.wrapper} onSubmit={submitForm}>
      <h2 className={css.header}>Create Organization</h2>
      <TextField
        className={css.input}
        id="outlined-basic"
        label="email"
        variant="filled"
        name="email"
        value={email}
        required
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        className={css.input}
        label="orgName"
        variant="filled"
        type="text"
        name="orgName"
        value={orgName}
        required
        onChange={(event) => setOrgName(event.target.value)}
      />
      <Button type="submit" className={css.send_btn}>
        Send
      </Button>
    </form>
  );
};
