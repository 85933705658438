import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISkill } from "types/models/skill";
import { chordsApi } from "../../../api/chords_api";

export const fetchChords = createAsyncThunk("chords/fetchChords", async () => {
  const { data: chords } = await chordsApi.fetchChords();
  return chords;
});

interface IChordsState {
  fetching: boolean;
  guitar: ISkill[];
  ukulele: ISkill[];
}

const initialState: IChordsState = {
  fetching: true,
  guitar: [],
  ukulele: [],
};

const chordsSlice = createSlice({
  name: "chords",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChords.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchChords.fulfilled, (state, action) => {
      state.guitar = action.payload.filter(
        (chord) => chord.skill?.skill?.name === "Guitar",
      );
      state.ukulele = action.payload.filter(
        (chord) => chord.skill?.skill?.name === "Ukulele",
      );
      state.fetching = false;
    });
    builder.addCase(fetchChords.rejected, (state, action) => {
      state.fetching = false;
      console.error("fetchChords error", action.error);
    });
  },
});

export const { reducer: chordsReducer } = chordsSlice;
