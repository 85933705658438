import React, { useEffect, useState } from "react";
import { Image, Line, Page, Path, Svg, Text, View } from "@react-pdf/renderer";
import {
  ICustomSong,
  IOrgSong,
  ISongwriting,
} from "redux/songwriting/songwriting_interfaces";
import { ProtectionBadgePdf } from "../protection_badge/protection_badge_pdf";
import { getLazyImage } from "../../songbuilder_sidebar/build_song_bar/chords_list/chords_list";
import moosikoFooterImage from "./moosiko_footer_image";
import { styles } from "./songbuilder_pdf_styles";

interface ISongbuilderPdfProps {
  customSong: ICustomSong | ISongwriting | IOrgSong;
  protectedStatus: boolean;
}

interface IChordInfo {
  title: string;
  icon: React.ReactElement;
}

interface IPdfSvgImages {
  title: string;
  data: string;
}

interface ISongChords {
  chord: string;
  row: string;
  column: string;
}

const SongbuilderPdf: React.FC<ISongbuilderPdfProps> = ({
  customSong,
  protectedStatus,
}) => {
  const [chordList, updateChordList] = useState<string[] | undefined>(
    undefined,
  );
  const [chordLazyImages, updateChordLazyImages] = useState<any>([]);
  const [pdfSvgImages, updatePdfSvgImages] = useState<IPdfSvgImages[]>([]);

  useEffect(() => {
    //  build chordList - an array of the name of all chords used in the song
    const songChordArray: string[] = [];
    customSong.custom_song_lyrics.forEach((songUnit) => {
      if (songUnit.song_chords) {
        JSON.parse(songUnit.song_chords).forEach((chord: { chord: string }) => {
          if (!songChordArray.includes(chord.chord)) {
            songChordArray.push(chord.chord);
          }
        });
      }
    });
    updateChordList(songChordArray);
  }, [customSong]);

  useEffect(() => {
    // build chordLazyImages each time chordList changes = [{title: chord, icon.props.src: svg image path }]
    chordList?.forEach((chord) => {
      (async () => {
        const svg = await getLazyImage(chord, customSong.chord_icon_style);

        updateChordLazyImages((prior: IChordInfo[] | undefined) => {
          if (!prior?.some((thisSvg) => thisSvg.title === svg.title)) {
            return [...(prior || []), svg];
          }
          return prior || [];
        });
      })();
    });
  }, [chordList, customSong]);

  useEffect(() => {
    //  build pdfSvgImages each time chordLazyImages changes = [{title: chord, data: svg in xml format}]
    const readSvgFile = (svgPath: string, title: string) => {
      fetch(svgPath)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch");
          }
          return response.text();
        })
        .then((data) => {
          updatePdfSvgImages((prior) => {
            if (!prior.some((thisSvg) => thisSvg.title === title)) {
              return [...prior, { title, data }];
            }
            return prior;
          });
        })
        .catch((error) => {
          console.error("Error", error);
        });
    };

    chordLazyImages.forEach((chordSvg: IChordInfo) => {
      readSvgFile(chordSvg.icon.props.src, chordSvg.title);
    });
  }, [chordLazyImages]);

  const svgFromData = (data: string) => {
    const xmlDoc = new DOMParser().parseFromString(data, "text/xml");
    const rootElement = xmlDoc.documentElement;
    const rootElementViewBox = rootElement.getAttribute("viewBox");

    const pathElements = rootElement.querySelectorAll("path");
    const pathElementsArray = Array.from(pathElements);
    const pathAttributes = pathElementsArray.map((pathElement) => ({
      d: pathElement.getAttribute("d") as string,
      fill: pathElement.getAttribute("fill") as string,
    }));

    const lineElements = rootElement.querySelectorAll("line");
    const lineElementsArray = Array.from(lineElements);
    const lineAttributes = lineElementsArray.map((lineElement) => ({
      x1: lineElement.getAttribute("x1") as string,
      y1: lineElement.getAttribute("y1") as string,
      x2: lineElement.getAttribute("x2") as string,
      y2: lineElement.getAttribute("y2") as string,
      stroke: lineElement.getAttribute("stroke") as string,
      strokeWidth: lineElement.getAttribute("stroke-width") as string,
      strokeLinecap: lineElement.getAttribute("stroke-linecap") as string,
      strokeLinejoin: lineElement.getAttribute("stroke-linejoin") as string,
    }));

    return (
      <Svg width={27} height={15} viewBox={rootElementViewBox as string}>
        {pathAttributes.map((pathAttribute, index) => {
          const key = `svg-d-${index}`;
          return (
            <Path d={pathAttribute.d} fill={pathAttribute.fill} key={key} />
          );
        })}

        {lineAttributes.map((lineAttribute, index) => {
          const key = `svg-line-${index}`;
          return (
            <Line
              x1={lineAttribute.x1}
              x2={lineAttribute.x2}
              y1={lineAttribute.y1}
              y2={lineAttribute.y2}
              stroke={lineAttribute.stroke}
              key={key}
            />
          );
        })}
      </Svg>
    );
  };

  const printRowChords = (chordRowArray: [ISongChords], rowKey: number) => {
    const chordRowSize = 49;
    const chordPositionArray = Array(chordRowSize).fill(null);

    return (
      <View style={styles.chordContainer}>
        {chordPositionArray.map((_, pos) => {
          const foundCordForPos = chordRowArray.find((entry) => {
            return Number(entry.column) === Number(pos);
          });
          const key = `${rowKey}-${pos}`;
          let chordDisplay = null;

          if (foundCordForPos) {
            const foundEntry = pdfSvgImages.find(
              (entry) => entry.title === foundCordForPos.chord,
            );

            if (foundEntry) {
              chordDisplay = (
                <View style={styles.chord}>{svgFromData(foundEntry.data)}</View>
              );
            }
          }

          return <View key={key}>{chordDisplay}</View>;
        })}
      </View>
    );
  };

  return (
    <Page size="A4" orientation="portrait" style={styles.page}>
      <View style={styles.titleContainer}>
        <Text> </Text>
        <Text style={styles.title}>{customSong.song_name}</Text>

        <ProtectionBadgePdf protectedStatus={protectedStatus} />
      </View>
      {customSong.custom_song_lyrics.map((unit, unitOffset) => {
        const unitKey = unitOffset;
        return (
          <View key={`Unit ${unitKey}`}>
            <View style={styles.songUnitTitleView}>
              <Text style={styles.songUnitTitle}>{unit.song_structure}</Text>
            </View>
            {unit.song_lyrics
              .split("/n")
              .map((line) => line || " ")
              .map((LyricLine, lineOffset) => {
                const rowKey = lineOffset;
                return (
                  <View key={`Line ${unitKey} + ${rowKey}`}>
                    <View style={styles.chordRow}>
                      {unit.song_chords &&
                        printRowChords(
                          JSON.parse(unit.song_chords).filter(
                            (x: ISongChords) => {
                              return Number(x.row) === rowKey * 2;
                            },
                          ),
                          rowKey,
                        )}
                    </View>
                    <Text style={styles.songLyrics}>{LyricLine}</Text>
                  </View>
                );
              })}
          </View>
        );
      })}
      <View fixed style={styles.footer}>
        <Image style={styles.footerImage} src={moosikoFooterImage} />
      </View>
    </Page>
  );
};

export default SongbuilderPdf;
