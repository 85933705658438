import DeleteIcon from "@material-ui/icons/Delete";
import { TextField } from "@material-ui/core";

import css from "../add_students_manually_tab.module.scss";
import { IHandleChangeStudent, IInviteStudentWithId } from "..";

interface IAddStudentRow {
  student: IInviteStudentWithId;
  removeStudent: (_: number) => void;
  handleChangeStudent: (_: IHandleChangeStudent) => void;
}
export const AddStudentRow = (props: IAddStudentRow) => {
  const { handleChangeStudent, removeStudent, student } = props;
  const { firstName, lastName, id, email } = student;

  return (
    <div className={css.container}>
      <TextField
        value={firstName}
        className={css.input}
        onChange={(event) =>
          handleChangeStudent({
            id,
            field: "firstName",
            value: event.target.value,
          })
        }
        label="First Name"
        variant="outlined"
      />
      <TextField
        value={lastName}
        className={css.input}
        onChange={(event) =>
          handleChangeStudent({
            id,
            field: "lastName",
            value: event.target.value,
          })
        }
        label="Last Name"
        variant="outlined"
      />
      <TextField
        className={css.input}
        type="email"
        onChange={(event) =>
          handleChangeStudent({ id, field: "email", value: event.target.value })
        }
        value={email}
        label="Email"
        variant="outlined"
      />
      <button
        className={css.deleteButton}
        type="button"
        onClick={() => removeStudent(id)}
      >
        <DeleteIcon />
      </button>
    </div>
  );
};
