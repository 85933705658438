import { ReactComponent as MoosikoLogo } from "img/moosiko_logo_white.svg";
import { useSelector } from "react-redux";
import {
  getOrganizationName,
  getOrganizationLogoURL,
} from "redux/organizations/organization_selectors";
import css from "./logo.module.scss";

export const Logo = () => {
  const orgName = useSelector(getOrganizationName);
  const orgLogoUrl = useSelector(getOrganizationLogoURL);
  if (orgLogoUrl) {
    return <img src={orgLogoUrl} alt="logo" className={css.logo} />;
  }
  return orgName ? <div>{orgName}</div> : <MoosikoLogo />;
};
