import styled from "@emotion/styled";
import { Dialog } from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { UseDialog } from "hooks/use_dialog";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserCourses } from "redux/user_courses/user_courses_selectors";
import { getUserEmails } from "redux/auth/user_selectors";
import { StudentConversationEmail } from "../student_conversation_dialog/student_conversation_email";
// import { useStudentConversationDialog } from "../student_conversation_dialog/use_student_conversation_dialog";
import Img from "./archived.svg";

export const ArchivedDialog = () => {
  const courses = useSelector(getUserCourses);

  const userEmails = useSelector(getUserEmails);

  // const { conversationDialog } = useStudentConversationDialog();

  const [archivedDialog, closeArchivedDialog, openArchivedDialog] = UseDialog(
    {},
  );

  useEffect(() => {
    if (userEmails.length > 0) {
      closeArchivedDialog({});
      return;
    }
    if (courses[0]?.end_date) {
      const difference = differenceInDays(
        new Date(courses[0]?.end_date),
        new Date(),
      );

      if (difference < 0) {
        openArchivedDialog({});
      }
    }
  }, [
    courses,
    openArchivedDialog,
    archivedDialog,
    userEmails,
    closeArchivedDialog,
  ]);

  // todo: enable when next school year ends
  const open = false; // archivedDialog && !conversationDialog
  return (
    <Dialog open={open} onClose={closeArchivedDialog}>
      <Container>
        <MainImgContainer>
          <MainImg src={Img} />
        </MainImgContainer>
        <Header>Your Account & Data Has Been Archived!</Header>
        <Text>
          We’re required to remove all data associated with school email
          addresses at the end of the school year. Save your data and continue
          using Moosiko by entering your personal email address.
        </Text>

        <StudentConversationEmail
          buttonText="Access Moosiko"
          closeDialog={closeArchivedDialog}
        />
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  padding: 28px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MainImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: rgba(103, 117, 222, 0.26);
`;

const MainImg = styled.img`
  width: 44px;
  position: relative;
  top: 8px;
`;

const Header = styled.h1`
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #190067;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #333333;
`;
