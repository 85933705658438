import React from "react";
import css from "./skill_progress_line.module.scss";

interface ISkillProgressLine {
  progress: number;
}
export const SkillProgressLine = (props: ISkillProgressLine) => {
  const { progress } = props;
  return (
    <div className={css.progress_container}>
      <div className={css.progress_line} style={{ width: `${progress}%` }} />
    </div>
  );
};
