import React from "react";

import { ViewLyricRow } from "./viewLyricRow";
import { ViewChordRow } from "./viewChordRow";

interface IChordInfo {
  title: string;
  icon: React.ReactElement;
}

interface IViewSongRow {
  song_line: string;
  row: number;
  songChords: [{ chord: string }];
  chordLazyImages: IChordInfo[];
}

export const ViewSongRow: React.FC<IViewSongRow> = ({
  song_line,
  row,
  songChords,
  chordLazyImages,
}) => {
  const rowKey = `rowKey(${row})`;

  return (
    <table key={`table${rowKey}`}>
      <tbody>
        <ViewChordRow
          row={row}
          songChords={songChords}
          chordLazyImages={chordLazyImages}
        />
        <ViewLyricRow row={row} song_line={song_line} />
      </tbody>
    </table>
  );
};
