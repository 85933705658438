import { Link, useLocation, useHistory } from "react-router-dom";
import { Popover } from "@material-ui/core";
import { usePopover } from "hooks/use_popover";
import styled from "@emotion/styled";
import { last, dropRight, drop, isEqual } from "lodash";
import { MouseEvent, useCallback, useEffect } from "react";
import {
  MY_SONGS,
  ALL_SONGS,
  MAIN,
  MY_JOURNEY,
  SONGWRITING,
} from "routes/route_constants";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { useSelector, useDispatch } from "react-redux";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import { setSongFilter } from "redux/songs_filter/song_filter_slice";
import { getNewUserFeedbackCount } from "redux/feedback/feedback_selectors";
import { getCourses } from "redux/courses/courses_selectors";
import {
  getUserSelector,
  isAuthorizedSelector,
} from "redux/auth/user_selectors";
import { INSTRUMENTS } from "components/songs/constants";
import css from "./song_links.module.scss";
import { activeLinks } from "./active_links";

interface ISongLinks {
  close?: () => void;
  cssLink: string;
}
const MOBILE_MAX_VIEWPORT = 660;

export const SongLinks = (props: ISongLinks) => {
  const isAuth = useSelector(isAuthorizedSelector);
  const songwritingAccess = useSelector(
    accessByPermissionSelector(PERMISSIONS.SONGWRITING),
  );

  const guitarAccess = useSelector(
    accessByPermissionSelector(PERMISSIONS.ACCESS_TO_GUITAR),
  );
  const ukuleleAccess = useSelector(
    accessByPermissionSelector(PERMISSIONS.ACCESS_TO_UKULELE),
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const active = activeLinks(location.pathname);
  const { close, cssLink } = props;

  const loggedIn = useSelector(getUserSelector);

  const [course] = useSelector(getCourses);
  const guitar_songs = course?.guitar_songs;
  const ukulele_songs = course?.ukulele_songs;
  const instructor = loggedIn?.instructor;
  const admin = loggedIn?.admin;
  let shallOpenInstrumentFilter = true;
  const specialStatus = instructor || admin || !loggedIn;
  if (specialStatus || !course) {
    shallOpenInstrumentFilter = true;
  } else if (!guitar_songs || !ukulele_songs) {
    shallOpenInstrumentFilter = false;
  }

  const newFeedbacksCount = useSelector(getNewUserFeedbackCount);

  const [
    instrumentsPopoverAnchor,
    openInstrumentsPopover,
    closeInstrumentsPopover,
  ] = usePopover();
  const handleAllSongsClick = (event: MouseEvent<HTMLElement>) => {
    if (
      (location.pathname === ALL_SONGS || location.pathname === MAIN) &&
      shallOpenInstrumentFilter
    ) {
      openInstrumentsPopover(event);
      if (window.innerWidth > MOBILE_MAX_VIEWPORT) {
        close && close();
      }
    } else {
      history.push(ALL_SONGS);
    }
  };

  const { instruments: selectedInstruments } = useSelector(getSongFilters);

  const setSelectedInstruments = useCallback(
    (data: string[]) => {
      !isEqual(selectedInstruments, data) &&
        dispatch(setSongFilter({ name: "instruments", data }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedInstruments],
  );
  useEffect(() => {
    if (!(selectedInstruments && window.innerWidth < MOBILE_MAX_VIEWPORT)) {
      if (ukulele_songs && !guitar_songs && !specialStatus && course) {
        // set ukulele
        setSelectedInstruments(drop(INSTRUMENTS));
      } else {
        // set guitar
        setSelectedInstruments(dropRight(INSTRUMENTS));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, specialStatus, course]);

  const onInstrumentChange = useCallback(
    (instrument) => {
      let newSelectedInstruments: string[] = [];
      if (selectedInstruments.includes(instrument)) {
        newSelectedInstruments = [...selectedInstruments];
      } else {
        newSelectedInstruments = [instrument];
      }
      closeInstrumentsPopover(instrument);
      setSelectedInstruments(newSelectedInstruments);
      close && close();
    },
    [
      selectedInstruments,
      setSelectedInstruments,
      closeInstrumentsPopover,
      close,
    ],
  );

  const isInstrumentChosen = (instrument: string) => {
    return selectedInstruments.includes(instrument);
  };

  return (
    <>
      <>
        {ukuleleAccess || guitarAccess ? (
          <AllSongsLink
            onClick={handleAllSongsClick}
            // to={ALL_SONGS}
            className={`${cssLink} ${active[ALL_SONGS]} ${active[MAIN]}`}
          >
            All Songs | {last(selectedInstruments)}
            {shallOpenInstrumentFilter && <MenuTriangle />}
          </AllSongsLink>
        ) : null}
        <Popover
          anchorEl={instrumentsPopoverAnchor}
          keepMounted
          open={Boolean(instrumentsPopoverAnchor)}
          onClose={closeInstrumentsPopover}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: 132 }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {INSTRUMENTS.map((instrument, index) => (
            <MenuItem
              role="menuitem"
              onClick={() => onInstrumentChange(instrument)}
              key={instrument}
              tabIndex={index}
              isChosen={isInstrumentChosen(instrument)}
            >
              {instrument.toUpperCase()}
            </MenuItem>
          ))}
        </Popover>
      </>

      {ukuleleAccess || guitarAccess ? (
        <Link
          onClick={loggedIn ? close : undefined}
          to={MY_SONGS}
          className={`${cssLink} ${active[MY_SONGS]}`}
        >
          My Songs
        </Link>
      ) : null}
      {ukuleleAccess || guitarAccess ? (
        <Link
          onClick={loggedIn ? close : undefined}
          to={MY_JOURNEY}
          className={`${cssLink} ${active[MY_JOURNEY]} ${css.my_journey_link}`}
        >
          My Journey {newFeedbacksCount ? <div>{newFeedbacksCount}</div> : null}
        </Link>
      ) : null}
      {songwritingAccess && isAuth ? (
        <Link
          onClick={loggedIn ? close : undefined}
          to={SONGWRITING}
          className={`${cssLink} ${active[SONGWRITING]}`}
        >
          Songwriting
        </Link>
      ) : null}
    </>
  );
};

const MenuItem = styled.div<{ isChosen?: boolean }>`
  display: flex;
  align-items: center;
  width: 268px;
  box-sizing: border-box;
  padding-left: 20px;
  height: 48px;
  letter-spacing: 1.25px;
  color: ${(props) => (props.isChosen ? "#190067" : "#818181")};
  font-weight: ${(props) => (props.isChosen ? "800" : "500")};
  font-size: 14px;
  line-height: 16px;
  border-right: ${(props) => props.isChosen && "5px solid #190067"};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (max-width: 660px) {
    width: 170px;
  }
`;

const MenuTriangle = styled.div`
  margin-left: 7px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #190067;
`;

const AllSongsLink = styled.div``;
