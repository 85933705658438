import {
  API_KEY,
  CLIENT_ID,
  DISCOVERY_DOC,
  SENSITIVE_SCOPES,
} from "constants/keys";
import {
  IClassroomStudent,
  IClassroomCourse,
} from "types/helpers/classroomTypes";

let tokenClient;
// @ts-ignore
const { gapi, google } = window;

async function intializeGapiClient() {
  await gapi.client.init({
    apiKey: API_KEY,
    discoveryDocs: [DISCOVERY_DOC],
  });
}

export function handleClassroomAuthClick(
  callback: (res: any) => Promise<void>,
) {
  if (gapi) {
    gapi.load("client", intializeGapiClient);
  }

  tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID,
    scope: SENSITIVE_SCOPES,
    callback: "" as any,
  });
  // @ts-ignore
  tokenClient.callback = callback;

  if (gapi.client.getToken() === null) {
    tokenClient.requestAccessToken({ prompt: "consent" });
  } else {
    tokenClient.requestAccessToken({ prompt: "" });
  }
}

export async function listCoursesHelper(
  pageSize = 10,
): Promise<IClassroomCourse[] | []> {
  // @ts-ignore
  const response = await gapi.client.classroom.courses.list({
    pageSize,
  });

  const { courses } = response.result;

  if (!courses || courses.length === 0) {
    return [];
  }

  return courses;
}

export async function listUsersFromChosenCourseHelper(
  courseId: string,
): Promise<IClassroomStudent[] | []> {
  // @ts-ignore
  const response = await gapi.client.classroom.courses.students.list({
    courseId,
  });
  const { students } = response.result;

  if (!students || students.length === 0) {
    return [];
  }
  return students;
}
