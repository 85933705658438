import css from "./ideas_bar_resource.module.scss";

interface IIdeaResource {
  description: string;
  image: string;
  url: string;
}

export const IdeaResource = ({ description, image, url }: IIdeaResource) => {
  return (
    <a
      className={css.tips_accordion_resource_links}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={css.tips_accordion_content}>
        <img src={image} style={{ width: "30px" }} alt=" " />
        {description}
      </div>
    </a>
  );
};
