import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateUserRole } from "redux/auth/auth_slice";
import {
  closeModal,
  openChoosePlanModal,
  openTeacherConfirmationModal,
  // openSkillsetModal,
} from "redux/ui/modal/modal_slice";
import { signUpStepsApi } from "api/sign_up_steps_api";
import { push } from "connected-react-router";
import { formSelector } from "redux/ui/form/form_selectors";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import {
  // isSelectChordsStepPassedSelector,
  isSelectPlanStepPassedSelector,
} from "redux/sign_up_steps/sign_up_steps_selectors";
import { IState } from "redux/store";
import { IRoles, ROLES, ROLE_IDS } from "redux/auth/roles/roles_consts";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { reminderModalApi } from "api/reminder_modal_api";
import { isSchoolEmail } from "util/email_domain_checker";
import { REMINDER_MODAL_NAMES } from "constants/reminder_modal_names";
import {
  getUserEmailSelector,
  getIsPromoSelector,
  getUserIdSelector,
  getPromoRole,
  getLogInType,
  getAuthSelector,
} from "../auth/user_selectors";
import { fetchUserChordSkills } from "../entities/user_skills/user_skills_slice";
import { disableButton } from "../ui/form/form_slice";

export const submitChordsStep = createAsyncThunk<void, void, { state: IState }>(
  "signUpSteps/submitChordsStep",
  async (_, { dispatch, getState }) => {
    try {
      await signUpStepsApi.submitChordsStep([]);
      const isSelectPlanStepPassed = isSelectPlanStepPassedSelector(getState());
      dispatch(fetchSignUpSteps());
      dispatch(fetchUserChordSkills());
      if (!isSelectPlanStepPassed) {
        dispatch(openChoosePlanModal());
      }
    } catch (err) {
      console.error("submitChordsStep error", err);
    }
  },
);

export const chooseFreePlan = createAsyncThunk<void, void, { state: IState }>(
  "signUpSteps/chooseFreePlan",
  async (_, { dispatch }) => {
    try {
      await signUpStepsApi.submitPlanStep();
      dispatch(fetchSignUpSteps());
      dispatch(closeModal());
    } catch (err) {
      console.error("submitChordsStep error", err);
    }
  },
);

export const checkRoleReminder = createAsyncThunk<
  void,
  { role: string },
  { state: IState }
>("signUpSteps/checkReminder", async ({ role }, { getState, dispatch }) => {
  const userId = getUserIdSelector(getState());
  const userEmail = getUserEmailSelector(getState());

  if (
    userEmail &&
    userId &&
    isSchoolEmail(userEmail) &&
    role === ROLES.FREE_USER
  ) {
    const result = await reminderModalApi.getReminderStatus({
      userId,
      modalTitle: REMINDER_MODAL_NAMES.TEACHER_CONFIRMATION,
    });

    if (!result.data) {
      dispatch(openTeacherConfirmationModal());
    }
  }
});

export const showSignUpStepIfNeeded = createAsyncThunk<
  void,
  void,
  { state: IState }
>("signUpSteps/showSignUpStepIfNeeded", async (_, { getState, dispatch }) => {
  // const selectChordsStepPassed = isSelectChordsStepPassedSelector(getState());
  const selectPlanStepPassed = isSelectPlanStepPassedSelector(getState());

  const { url } = getModalDataSelector(getState());
  const { buttonDisabled } = formSelector(getState());
  const isPromo = getIsPromoSelector(getState());
  const userRole = getRoleSelector(getState());
  const loginType = getLogInType(getState());

  const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

  const user = getAuthSelector(getState());
  const currentRole = user.role || "FREE_USER";

  if (loginType === "SignUp") {
    const promoRole = getPromoRole(getState());
    const userEmail = getUserEmailSelector(getState());
    const promoCodeToString = (promoRole: number | null) => {
      switch (promoRole) {
        case ROLE_IDS.FREE_USER:
          return "FREE_USER";
        case ROLE_IDS.INDIVIDUAL:
          return "INDIVIDUAL";
        case ROLE_IDS.SOLO_TEACHER:
          return "SOLO_TEACHER";
        case ROLE_IDS.FREE_TEACHER_ACCOUNT:
          return "FREE_TEACHER_ACCOUNT";
        case ROLE_IDS.PAID_TEACHER_ACCOUNT:
          return "PAID_TEACHER_ACCOUNT";
        default:
          return mobile && currentRole === "FREE_USER"
            ? "FREE_SONGWRITING"
            : currentRole;
      }
    };
    const roleString = promoCodeToString(promoRole);

    userEmail &&
      dispatch(updateUserRole({ email: userEmail, roleName: roleString }));
  }

  if (isPromo) {
    dispatch(closeModal());
  }

  if (!selectPlanStepPassed && userRole === ROLES.FREE_USER) {
    dispatch(openChoosePlanModal());
    // If we are coming from a successful login attempt:
  } else if (buttonDisabled) {
    dispatch(closeModal());
    if (url) {
      dispatch(push(url));
    }
  }

  if (buttonDisabled) {
    dispatch(disableButton(false));
  }
});

interface ISteps {
  selectChords: boolean;
  selectPlan: boolean;
}

interface IUserData {
  role?: IRoles;
  email?: string;
  fname?: string;
  lname?: string;
  orgName?: string;
  id?: string;
  amountStudents?: number;
}
interface ISignUpStepsState {
  fetching: boolean;
  steps: ISteps | null;
  userData: IUserData | null;
}

const initialState: ISignUpStepsState = {
  fetching: true,
  steps: null,
  userData: null,
};

export const fetchSignUpSteps = createAsyncThunk<
  ISteps,
  void,
  { state: IState }
>("signUpSteps/fetchSignUpSteps", async () => {
  try {
    const { data: steps } = await signUpStepsApi.fetchChordSkillSteps();
    return {
      selectChords: steps.select_chords_step_done,
      selectPlan: steps.select_plan_step_done,
    };
  } catch (err) {
    console.error("fetchSignUpSteps error", err);
    throw err;
  }
});

const signUpStepsSlice = createSlice({
  name: "signUpSteps",
  initialState,
  reducers: {
    setUserDataSignup(state, { payload }: PayloadAction<Partial<IUserData>>) {
      state.userData = { ...state.userData, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSignUpSteps.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchSignUpSteps.fulfilled, (state, action) => {
      state.steps = action.payload;
    });
  },
});

export const {
  reducer: signUpStepsReducer,
  actions: { setUserDataSignup },
} = signUpStepsSlice;
