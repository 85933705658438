import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openRemoveStudentModal } from "redux/ui/modal/modal_slice";
import { getChordsLikeStringSelector } from "redux/entities/chords/chords_selectors";
import { getProgressionsLikeStringSelector } from "redux/entities/progressions/progressions_selectors";
import { getTransitionsLikeStringSelector } from "redux/entities/transitions/transitions_selectors";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { CourseCards } from "components/course/course_cards";
import {
  reduceThisWeekMinutes,
  ZERO_TIME,
} from "redux/entities/my_journey/my_journey_selectors";
import { ChartWithAside } from "components/my_journey/i_chart_with_aside";
import { ISkill } from "types/models/skill";
import { IUserSession } from "api/user_session_api";
import css from "./user_info_modal.module.scss";
import { ModalContainer } from "../modal_container";

export const UserInfoModal = () => {
  const dispatch = useDispatch();
  const {
    fname,
    lname,
    email,
    chords,
    transitions,
    progressions,
    userSessions,
    lessons,
    user_strumming_lessons,
  } = useSelector(getModalDataSelector);

  const dataForGuitarSkillsCard = {
    chords: useSelector(getChordsLikeStringSelector),
    progressions: useSelector(getProgressionsLikeStringSelector),
    transitions: useSelector(getTransitionsLikeStringSelector),
    userChords: chords.guitar.map((chord: ISkill) => chord.name),
    userProgressions: progressions.guitar.map((progression: ISkill) =>
      JSON.parse(progression.name).join("-"),
    ),
    userTransitions: transitions.guitar.map((transition: ISkill) =>
      JSON.parse(transition.name).join("-"),
    ),
    lessons,
    fname,
    lname,
    email,
    user_strumming_lessons,
  };

  const dataForUkuleleSkillsCard = {
    chords: useSelector(getChordsLikeStringSelector),
    progressions: useSelector(getProgressionsLikeStringSelector),
    transitions: useSelector(getTransitionsLikeStringSelector),
    userChords: chords.ukulele.map((chord: ISkill) => chord.name),
    userProgressions: progressions.ukulele.map((progression: ISkill) =>
      JSON.parse(progression.name).join("-"),
    ),
    userTransitions: transitions.ukulele.map((transition: ISkill) =>
      JSON.parse(transition.name).join("-"),
    ),
    lessons,
    fname,
    lname,
    email,
    user_strumming_lessons,
  };
  const totalTime = userSessions
    ? userSessions.reduce(
        (prev: number, current: IUserSession) => current.minutes + prev,
        ZERO_TIME,
      )
    : ZERO_TIME;

  const thisWeekMinutes =
    totalTime !== ZERO_TIME ? reduceThisWeekMinutes(userSessions) : ZERO_TIME;

  return (
    <ModalContainer noCloseButton>
      <div>
        <div className={css.student_info}>
          <button
            className={css.back_btn}
            onClick={() => dispatch(closeModal())}
            type="button"
          >
            <div className={css.back_icon}>
              <ArrowBackIosIcon />
            </div>
            {fname} {lname}
          </button>
          <h3 className={css.card_header}>Info</h3>
          <div className={css.info_container}>
            <span className={css.student_email}>{email}</span>
            <button
              className={css.remove_btn}
              type="button"
              onClick={() => dispatch(openRemoveStudentModal({ name: fname }))}
            >
              Remove {fname}
            </button>
          </div>
        </div>
        <hr className={css.hr} />

        <h3 className={css.card_header}>Guitar Skills</h3>
        <CourseCards
          instrument="guitar"
          userSkills={dataForGuitarSkillsCard}
          fname={dataForGuitarSkillsCard.fname}
        />
        <hr className={css.hr} />

        <h3 className={css.card_header}>Ukulele Skills</h3>
        <CourseCards
          instrument="ukulele"
          userSkills={dataForUkuleleSkillsCard}
          fname={dataForUkuleleSkillsCard.fname}
        />
        <hr className={css.hr} />
        <div className={css.chart_container}>
          <ChartWithAside
            totalTime={totalTime}
            thisWeekMinutes={thisWeekMinutes}
            minutesPerDay={userSessions}
            topText={`${fname} Practice Time`}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
