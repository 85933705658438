import axios from "axios";
import {
  IMsTeamsParticipantType,
  IMsTeamsGroupType,
} from "../../../types/helpers/msTeamsTypes";

const msGraphApiUrl = "https://graph.microsoft.com/v1.0";

export const msTeamsApi = {
  async getGroups(msAccessToken: string) {
    const url = `${msGraphApiUrl}/groups/`;
    if (msAccessToken) {
      const { data } = await axios.get<IMsTeamsGroupType>(url, {
        headers: {
          Authorization: `Bearer ${msAccessToken}`,
        },
      });
      return data;
    }
    return { value: [] };
  },
  async getParticipants(msAccessToken: string, groupId: string) {
    const url = `${msGraphApiUrl}/teams/${groupId}/members`;
    if (msAccessToken) {
      const { data } = await axios.get<IMsTeamsParticipantType>(url, {
        headers: { Authorization: `Bearer ${msAccessToken}` },
      });

      return data;
    }
    return { value: [] };
  },
};
