import axios from "axios";
import { getNodeApiBaseUrl } from "api/base_api";
import { ICourse } from "redux/courses/courses_api";

export interface ICourseWithTime extends ICourse {
  users: {
    id: number;
    totalMinutes: number;
  }[];
}

export const userCoursesApi = {
  async fetchUserCourses(userId: number) {
    const url = `${getNodeApiBaseUrl()}/courses`;
    const {
      data: { courses },
    } = await axios.get<{ courses: ICourseWithTime[] }>(url, {
      params: { userId },
    });
    return courses;
  },
};
