import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { getMediaRecordingsRecords } from "redux/media_recordings/media_recordings_selectors";
import { IState } from "redux/store";
import { AppDispatch } from "types/redux_";
import { getAssessmentsFilteredRecords } from "./assessments_selectors";
import { TAssessmentSortDirections, TAssessmentSortTypes } from "./constants";

export const updateAllAssessments =
  (status: boolean) => (dispatch: AppDispatch, getState: () => IState) => {
    const { finalRecords, isFiltered } = getAssessmentsFilteredRecords(
      getState(),
    );
    let assessments: number[];
    if (isFiltered) {
      assessments = finalRecords.map((assessment) => assessment.id);
    } else {
      const assessmentsTemp = getMediaRecordingsRecords(getState());
      assessments = assessmentsTemp.map((assessment) => assessment.id);
    }
    dispatch(updateAllAssessmentsAction({ status, assessments }));
  };

interface IAssessmentFilteredRecords {
  active: boolean;
  filteredRecords: IMediaRecord[];
}

interface IAssessmentsState {
  sort: { type: TAssessmentSortTypes; direction: TAssessmentSortDirections };
  dateRange: IAssessmentFilteredRecords;
  skillType: IAssessmentFilteredRecords;
  class: IAssessmentFilteredRecords;
  submissionType: IAssessmentFilteredRecords;
  name: IAssessmentFilteredRecords;
  selectedAssessments: number[];
  dropSelection: number;
}

const initialState: IAssessmentsState = {
  sort: {
    type: TAssessmentSortTypes.SUBMISSION_DATE,
    direction: TAssessmentSortDirections.DESC,
  },
  dateRange: {
    active: false,
    filteredRecords: [],
  },
  skillType: {
    active: false,
    filteredRecords: [],
  },
  class: {
    active: false,
    filteredRecords: [],
  },
  submissionType: {
    active: false,
    filteredRecords: [],
  },
  name: {
    active: false,
    filteredRecords: [],
  },
  selectedAssessments: [],
  // used to drop selections by updating ref
  dropSelection: Math.random(),
};

const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    updateAllAssessmentsAction: (
      state,
      action: PayloadAction<{
        status: boolean;
        assessments: number[];
      }>,
    ) => {
      const { status, assessments } = action.payload;
      let newSelectedAssessments: number[] = [];
      if (status) {
        newSelectedAssessments = assessments;
      }
      state.selectedAssessments = newSelectedAssessments;
    },
    setAssessmentsSort: (
      state,
      action: PayloadAction<{
        type: TAssessmentSortTypes;
        direction: TAssessmentSortDirections;
      }>,
    ) => {
      state.sort = action.payload;
    },
    clearAssessmentsFilter: (state) => {
      state.dropSelection = Math.random();
    },
    setAssessmentsSubmissionType: (
      state,
      action: PayloadAction<IAssessmentFilteredRecords>,
    ) => {
      state.submissionType = action.payload;
    },
    setAssessmentsStudentName: (
      state,
      action: PayloadAction<IAssessmentFilteredRecords>,
    ) => {
      state.name = action.payload;
    },
    setAssessmentsClass: (
      state,
      action: PayloadAction<IAssessmentFilteredRecords>,
    ) => {
      state.class = action.payload;
    },
    setAssessmentsSkillType: (
      state,
      action: PayloadAction<IAssessmentFilteredRecords>,
    ) => {
      state.skillType = action.payload;
    },
    setAssessmentsDateRange: (
      state,
      action: PayloadAction<IAssessmentFilteredRecords>,
    ) => {
      state.dateRange = action.payload;
    },
    updateSelectedAssessments: (
      state,
      action: PayloadAction<{ id: number; status: boolean }>,
    ) => {
      const { status, id } = action.payload;
      const newSelectedAssessments = state.selectedAssessments.filter(
        (recordId) => recordId !== id,
      );
      if (status) {
        newSelectedAssessments.push(id);
      }
      state.selectedAssessments = newSelectedAssessments;
    },
  },
});

export const {
  reducer: assessmentsReducer,
  actions: {
    setAssessmentsSort,
    clearAssessmentsFilter,
    setAssessmentsSubmissionType,
    setAssessmentsStudentName,
    setAssessmentsClass,
    setAssessmentsSkillType,
    setAssessmentsDateRange,
    updateSelectedAssessments,
    updateAllAssessmentsAction,
  },
} = assessmentsSlice;
