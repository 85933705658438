import { SongPlayer } from "components/songwriting/songbuilder/songbuilder_sidebar/record_bar/records_list/song_player/song_player";
import { useDispatch, useSelector } from "react-redux";
import { setAudioComponentExpandedId } from "redux/ui/components/components_slice";
import { getExpandedAudioComponentId } from "redux/ui/components/components_selectors";
import { Audio } from "types/recorder";
import { differenceInSeconds } from "date-fns";
import EditIcon from "@material-ui/icons/Edit";
import { Box, Typography } from "@material-ui/core";

import css from "./record_item.module.scss";
import { EditableRecordingTitle } from "../../editable_recording_title/editable_recording_title";

interface IRecordItem {
  record: Audio;
  id: number;
}
const WEEK_DAY_POSITION = 4;

export const RecordItem = ({ record, id }: IRecordItem) => {
  const dispatch = useDispatch();
  const expandedId = useSelector(getExpandedAudioComponentId);

  // Expand the audio component if the recording was just created
  const fiveSeconds = 5;
  if (
    expandedId === null &&
    differenceInSeconds(new Date(), new Date(record.createdAt)) < fiveSeconds
  ) {
    dispatch(setAudioComponentExpandedId(id));
  }

  const itemTitle = record.title ? record.title : `New recording ${id + 1}`;
  const handleToggleRecording = () => {
    if (expandedId === id) {
      dispatch(setAudioComponentExpandedId(null));
    } else {
      dispatch(setAudioComponentExpandedId(id));
    }
  };

  return (
    <Box
      padding="12px 20px"
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
    >
      <Box onClick={handleToggleRecording}>
        <Box
          display="flex"
          justifyContent="space-between"
          className={css.record_item_wrapper}
        >
          <EditableRecordingTitle
            id={record.assetId}
            title={itemTitle}
            defaultTitle={!record.title}
          >
            <Box
              display="flex"
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <Typography
                className={css.recording_title}
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  fontFamily: "Raleway",
                }}
              >
                {itemTitle}
              </Typography>
              <EditIcon />
            </Box>
          </EditableRecordingTitle>
          <Typography style={{ fontSize: "18px", fontFamily: "Raleway" }}>
            {record.rec_minutes}:
            {record.rec_seconds < 10
              ? `0${record.rec_seconds}`
              : record.rec_seconds}
          </Typography>
        </Box>
        <Typography style={{ fontFamily: "Raleway" }}>
          {new Date(record.createdAt).toDateString().slice(WEEK_DAY_POSITION)}
        </Typography>
      </Box>
      {expandedId === id && <SongPlayer src={record.audio} />}
    </Box>
  );
};
