import axios from "axios";
import { toast } from "react-toastify";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { getNodeApiBaseUrl } from "../../api/base_api";
import { IFeedback } from "./feedback_types";

export const feedbackApi = {
  async createFeedback(feedback: IFeedback) {
    const url = `${getNodeApiBaseUrl()}/feedback`;
    const formData = new FormData();
    for (const [key, value] of Object.entries(feedback)) {
      // @ts-ignore
      formData.append(key, value);
    }
    try {
      await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Feedback sent");
    } catch (e) {
      toast.error((e as any).response.data.message);
    }
  },

  fetchFeedback(studentId: number) {
    const url = `${getNodeApiBaseUrl()}/feedback/${studentId}`;
    return axios.get<{ feedback: IMediaRecord[] }>(url);
  },

  /**
   * @param {Object} feedback
   * @param {boolean?} feedback.viewed
   * @param {number} feedback.id
   */
  updateFeedback(feedback: Partial<IFeedback>) {
    const url = `${getNodeApiBaseUrl()}/feedback/${feedback.id}`;
    return axios.patch(url, { ...feedback });
  },
};
