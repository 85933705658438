import React from "react";
import css from "../add_students_tab.module.scss";
import { ImportOptions } from "../constants";

interface ITabButton {
  setSubTab: React.Dispatch<React.SetStateAction<ImportOptions>>;
  tabTitle: ImportOptions;
  imgSource: string;
  extraImgSource?: string;
  header?: string;
  text?: string;
}

export const TabButton = ({
  setSubTab,
  tabTitle,
  imgSource,
  extraImgSource,
  header,
  text,
}: ITabButton) => {
  return (
    <button
      className={css.button}
      onClick={() => setSubTab(tabTitle)}
      type="button"
    >
      {header && <h2 className={css.header}>{header}</h2>}
      {text && <div className={css.text}>{text}</div>}
      <img alt={header} src={imgSource} />
      {extraImgSource && <img alt={header} src={extraImgSource} />}
    </button>
  );
};
