import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getSongwritingAssessmentsSort } from "redux/songwriting_assessments/songwriting_assessments_selectors";
import { setSongwritingAssessmentsSort } from "redux/songwriting_assessments/songwriting_assessments_slice";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {
  TSongwritingAssessmentSortDirections,
  TSongwritingAssessmentSortTypes,
} from "redux/songwriting_assessments/songwriting_constants";

import css from "../../assessment/assessment_row/assessment_row.module.scss";

interface ITableHeaderItem {
  text: string;
  sortValue: TSongwritingAssessmentSortTypes;
  classes: string;
}

export const TableHeaderItem = ({
  text,
  sortValue,
  classes,
}: ITableHeaderItem) => {
  const currentSort = useSelector(getSongwritingAssessmentsSort);

  const icon =
    currentSort.direction === TSongwritingAssessmentSortDirections.ASC ? (
      <ArrowUpwardIcon />
    ) : (
      <ArrowDownwardIcon />
    );

  const dispatch = useDispatch();

  const handleSort = (sortName: TSongwritingAssessmentSortTypes) => {
    let sortDirection = TSongwritingAssessmentSortDirections.DESC;
    if (currentSort.type === sortName) {
      if (currentSort.direction === TSongwritingAssessmentSortDirections.DESC) {
        sortDirection = TSongwritingAssessmentSortDirections.ASC;
      } else {
        sortDirection = TSongwritingAssessmentSortDirections.DESC;
      }
    }

    dispatch(
      setSongwritingAssessmentsSort({
        direction: sortDirection,
        type: sortName,
      }),
    );
  };

  return (
    <button
      type="button"
      onClick={() => handleSort(sortValue)}
      className={classnames(classes, css.row_item)}
    >
      <div className={css.header_item}>
        {text}
        <div
          className={classnames({
            [css.low_opacity]: currentSort.type !== sortValue,
            [css.icon_color]: true,
          })}
        >
          {icon}
        </div>
      </div>
    </button>
  );
};
