import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { MouseEvent } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ROLES } from "redux/auth/roles/roles_consts";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import css from "./user_skill.module.scss";
import icon2 from "../user_skills/icons/chords_icon.svg";
import { SkillProgressLine } from "../skill_progress_line/skill_progress_line";

interface IUserSkill {
  icon: string;
  name: string;
  progress: number;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  skills: number;
}

export const UserSkill = ({
  icon = icon2,
  name = "chords",
  progress,
  onClick = () => {},
  skills,
}: IUserSkill) => {
  if (Number.isNaN(progress)) {
    progress = 0;
  }
  const userRole = useSelector(getRoleSelector);

  const shallBlock = !(
    userRole === ROLES.FREE_TEACHER_ACCOUNT ||
    userRole === ROLES.FREE_TEACHER_STUDENT
  );

  return (
    <Container shallBlock={shallBlock} onClick={onClick} type="button">
      <div className={css.skill_type}>
        <img className={css.icon} src={icon} alt={name} />
        <div>{name}</div>
      </div>
      <div className={css.skill_info}>
        <div className={css.number_of_skills}>{skills}</div>
        <SkillProgressLine progress={progress} />
        <div className={css.arrow}>
          <ArrowForwardIosIcon viewBox="0 -10 40 40" />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.button<{ shallBlock: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  filter: blur(${(props) => !props.shallBlock && "8px"});
  user-select: ${(props) => !props.shallBlock && "none"};
  pointer-events: ${(props) => !props.shallBlock && "none"};
  width: 100%;
  padding: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
