import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Popover, Typography } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  getCurrentCustomSong,
  getUsingMobileAppFlag,
} from "redux/songwriting/songwriting_selectors";
import SearchBar from "components/reusable/search_bar/search_bar";
import { useChordsTable } from "hooks";
import { SongSettingsList } from "../../../song_settings_list/song_settings_list";
import { SongItemsGrid } from "../../song_items_grid/song_items_grid";
import { getChordsByKey, getAllChords } from "../chords_list/chords_list";
import css from "./chord_accordion.module.scss";

interface IChordAccordion {
  content: { title: string }[];
  setCurrentChordSearch: (_: string) => void;
  currentChordSearch: string;
  closeMenuHandler?: () => void;
}

export const ChordAccordion = ({
  content,
  setCurrentChordSearch,
  currentChordSearch,
  closeMenuHandler,
}: IChordAccordion) => {
  const [chordList, setChordList] = useState<{ title: string }[]>(content);
  const [defaultChordList, setDefaultChordList] = useState<{ title: string }[]>(
    [],
  );
  const [fullChordList, setFullChordList] = useState<{ title: string }[]>([]);
  const [chordInventory, setChordInventory] = useState<{ title: string }[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { getChordInventory } = useChordsTable();

  const openPopover = !!anchorEl;

  const mobile = useSelector(getUsingMobileAppFlag);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { custom_song_key, chord_icon_style } =
    useSelector(getCurrentCustomSong);

  const draggable = true;

  const performChordSearch = (chordSearchString: string) => {
    setCurrentChordSearch(chordSearchString);
  };

  const clearCurrentSearch = () => {
    performChordSearch("");
  };

  useEffect(() => {
    const chordInventory = getChordInventory();

    const minimumChords = 3;
    if (chordInventory.length >= minimumChords) {
      const formattedChordInventory = chordInventory.map((item) => ({
        chord: item.chord.replace("sharp", "#"),
        count: item.count,
      }));
      const fullChordSet = new Set(fullChordList.map((chord) => chord.title));

      const filteredList = formattedChordInventory
        .filter((item) => fullChordSet.has(item.chord))
        .map((item) =>
          fullChordList.find((chord) => chord.title === item.chord),
        );

      //  This is to help pass the type check for setChordInventory
      const filteredListWithoutUndefined = filteredList.filter(
        (item): item is { title: string } => item !== undefined,
      );

      setChordInventory(filteredListWithoutUndefined);
    }
  }, [getChordInventory, fullChordList]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const result = await getAllChords(chord_icon_style);
      if (isMounted) {
        setFullChordList(result);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [chord_icon_style]);

  useEffect(() => {
    let isMounted = true;
    if (!currentChordSearch) {
      (async () => {
        let result;
        const defaultChords = await getChordsByKey(
          custom_song_key,
          chord_icon_style,
        );
        if (chordInventory.length > 0) {
          //  This is to prevent the same chord from appearing twice
          const filteredChords = defaultChords.filter(
            (chord) =>
              !chordInventory.some(
                (inventoryChord) =>
                  inventoryChord.title.replace("#", "sharp") === chord.title,
              ),
          );

          result = [...chordInventory, ...filteredChords];
        } else {
          result = defaultChords;
        }

        if (isMounted) {
          setChordList(result);
          setDefaultChordList(result);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [custom_song_key, chord_icon_style, currentChordSearch, chordInventory]);

  useEffect(() => {
    const searchResults = !currentChordSearch
      ? defaultChordList
      : fullChordList.filter((chord) =>
          chord.title.toUpperCase().includes(currentChordSearch.toUpperCase()),
        );
    setChordList(searchResults);
  }, [chord_icon_style, currentChordSearch, defaultChordList, fullChordList]);

  return (
    <div>
      <div className={css.chordControlRow}>
        <SearchBar
          style={{
            borderRadius: "100px",
            fontFamily: "Raleway",
            marginBottom: "10px",
            width: mobile ? "100%" : "75%",
          }}
          placeholder="Search for more chords..."
          value={currentChordSearch}
          onChange={performChordSearch}
          onCancelSearch={clearCurrentSearch}
        />
        {!mobile && (
          <div role="button" tabIndex={0} onClick={handleOpen}>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </div>
        )}
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className={css.padding}>
            <Typography gutterBottom>Chord Icons</Typography>
            <SongSettingsList
              list_key="chord_icon_style"
              dataArray={["Basic", "Guitar"]}
              handleClose={handleClose}
            />
          </div>
        </Popover>
      </div>
      <SongItemsGrid
        content={chordList.map((item) => ({
          ...item,
          draggable,
        }))}
        tipText={mobile ? "" : "Drag and drop chords over your lyrics"}
        closeMenuHandler={closeMenuHandler}
      />
    </div>
  );
};
