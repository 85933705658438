import classNames from "classnames";
import css from "../../assessment/assessment_row/assessment_row.module.scss";

export const SongwritingAssessmentSkeletonRow = () => {
  return (
    <div className={css.container}>
      <div className={classNames(css.name, css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classNames(css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classNames(css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
      <div className={classNames(css.row_item)}>
        <div className={css.info_skeleton} />
      </div>
    </div>
  );
};
