import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentCustomSong,
  getCurrentCustomSongUnit,
  getActualTableDataById,
} from "redux/songwriting/songwriting_selectors";
import { updateCustomSong } from "redux/songwriting/songwriting_slice";
import { useSongUnitTableContent } from "hooks";

import { SvgIcon } from "@material-ui/core";

import {
  ICustomSongLyrics,
  EnumDragAndDropActions,
} from "redux/songwriting/songwriting_interfaces";

interface ITable {
  songUnitId: number;
}

const DuplicateSongUnitIcon = ({ songUnitId }: ITable) => {
  // TODO: Consolidate this and code in song_unit_mobile_menu.tsx

  const dispatch = useDispatch();
  const { collectAndSaveAllUnits, getPreparedSongLyricsData } =
    useSongUnitTableContent();
  const currentSong = useSelector(getCurrentCustomSong);
  const currentSongLyrics = useSelector(
    getCurrentCustomSongUnit(songUnitId as number),
  );
  const actualSongLyrics = useSelector(getActualTableDataById(songUnitId));
  const preparedActualSongLyrics = getPreparedSongLyricsData(
    actualSongLyrics || [],
  );

  const currentSong_Structure = currentSongLyrics?.song_structure;
  const currentSong_Chords = currentSongLyrics?.song_chords;

  const copyHandler = () => {
    const newSongUnit: ICustomSongLyrics = {
      song_chords: currentSong_Chords !== undefined ? currentSong_Chords : "",
      song_lyrics:
        preparedActualSongLyrics !== undefined ? preparedActualSongLyrics : "",
      song_structure:
        currentSong_Structure !== undefined
          ? `${currentSong_Structure} copy`
          : "",
      position: currentSong.custom_song_lyrics.length,
    };

    dispatch(
      updateCustomSong({
        custom_song_lyrics: [
          {
            ...newSongUnit,
            type: EnumDragAndDropActions.createStructure,
          },
        ],
      }),
    );
    collectAndSaveAllUnits();
    // }
  };

  return (
    <SvgIcon
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={copyHandler}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M7.55675 0.952509C6.91965 1.09743 6.35548 1.57481 6.05995 2.22696C5.76059 2.88762 6.07147 3.56534 6.69705 3.60796C7.06166 3.63354 7.33032 3.44599 7.53373 3.02828C7.56827 2.95156 7.65654 2.85353 7.72562 2.80664C7.85228 2.72139 7.90985 2.72139 11.9589 2.72139C15.8659 2.72139 16.0732 2.72565 16.196 2.79811C16.2689 2.84074 16.361 2.94303 16.3994 3.02402C16.4646 3.16042 16.4685 3.43747 16.4685 9.09363C16.4685 14.8905 16.4685 15.0226 16.3917 15.1547C16.2344 15.4403 16.2344 15.4403 14.9103 15.4659L13.7128 15.4872L13.5478 15.6151C13.3022 15.8069 13.1985 16.0583 13.2139 16.4079C13.2292 16.7361 13.329 16.9321 13.567 17.1197L13.7167 17.2348L14.9755 17.2475C16.0386 17.2561 16.2727 17.2475 16.4762 17.1921C17.19 16.9833 17.7734 16.378 18.0075 15.5895L18.0996 15.2741V9.09363C18.0996 3.21583 18.0958 2.90041 18.0305 2.65746C17.8079 1.85613 17.2783 1.2594 16.5452 0.99087C16.3457 0.914148 16.0885 0.909885 12.074 0.901361C8.62371 0.897098 7.764 0.905623 7.55675 0.952509Z"
        fill="#979797"
      />
      <path
        d="M1.67317 4.6352C0.970825 4.85684 0.41816 5.44931 0.19172 6.22506L0.0996094 6.53621V12.7166C0.0996094 18.5944 0.103447 18.9099 0.168693 19.1528C0.395132 19.9627 0.943959 20.5722 1.67317 20.8237C1.89193 20.8961 2.13756 20.9004 6.24033 20.9004H10.5772L10.8574 20.7981C11.3947 20.602 11.8591 20.1758 12.1124 19.643C12.3772 19.0761 12.3619 19.5151 12.3619 12.7252C12.3619 5.95227 12.3772 6.35719 12.1201 5.81161C11.9627 5.47914 11.529 4.9975 11.2297 4.82274C10.7461 4.54142 10.9725 4.55421 6.21731 4.55847C2.24886 4.55847 1.88426 4.567 1.67317 4.6352ZM10.4928 6.44244C10.5504 6.4808 10.6271 6.56605 10.6617 6.62998C10.7269 6.74081 10.7307 7.08179 10.7307 12.7166C10.7307 18.3984 10.7269 18.6925 10.6617 18.8033C10.6233 18.8715 10.5388 18.961 10.4698 19.0036C10.3508 19.0846 10.2433 19.0889 6.24033 19.0889C2.3333 19.0889 2.12605 19.0846 2.00323 19.0122C1.93031 18.9695 1.8382 18.8672 1.79982 18.7863C1.73458 18.6499 1.73074 18.3728 1.73074 12.7252C1.73074 6.39129 1.72306 6.69392 1.91496 6.50211C1.95718 6.45949 2.04161 6.40834 2.09918 6.38703C2.16059 6.36572 4.04886 6.35293 6.2979 6.35719C10.1359 6.36572 10.3968 6.36998 10.4928 6.44244Z"
        fill="#979797"
      />
    </SvgIcon>
  );
};

export default DuplicateSongUnitIcon;
