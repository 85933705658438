/* eslint-disable @typescript-eslint/ban-types */
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { format } from "date-fns/esm";
import { Link } from "react-router-dom";
import { usePopover } from "hooks/use_popover";
import { UseDialog } from "hooks/use_dialog";
import React, { useCallback } from "react";
import { ICourse } from "redux/courses/courses_api";
import cn from "classnames";
import css from "./course_block.module.scss";
import { CourseBlockMenu } from "./course_block_menu";
import { DeleteCourseDialog } from "./delete_course_dialog";
import { ChangeCourseNameDialog } from "./change_course_name_dialog";

interface ICourseBlock {
  course: ICourse;
  showMenu: boolean;
  onClick?: (event: React.MouseEvent) => void;
  icon?: React.ReactElement;
}

export const CourseBlock = ({
  course,
  showMenu,
  onClick = () => {},
  icon,
}: ICourseBlock) => {
  const onOpen = (event: React.MouseEvent) => event.preventDefault();
  const [menuAnchor, handleOpenMenu, handleCloseMenu] = usePopover({ onOpen });

  const [deleteCourseDialog, closeDeleteCourseDialog, openDeleteCourseDialog] =
    UseDialog();

  const [renameCourseDialog, closeRenameCourseDialog, openRenameCourseDialog] =
    UseDialog();

  const isCourseExpired = useCallback(() => {
    const now = new Date();
    const endDate = new Date(course.end_date);
    return now > endDate;
  }, [course]);

  return (
    <>
      <li className={css.course_container} key={course.name}>
        <Link
          // @ts-ignore
          onClick={onClick}
          to={`/organization/${course.id}`}
          className={css.course_link}
        >
          <button
            // @ts-ignore
            onClick={handleOpenMenu}
            type="button"
            className={css.dots_button}
          >
            {showMenu ? <MoreVertIcon /> : icon}
          </button>

          <h3 className={cn(css.h3, { [css.expired]: isCourseExpired() })}>
            {course.name}
          </h3>
          <p>{course.users.length}</p>
          <p>students</p>
          <p className={css.course_dates}>
            {`${format(new Date(course.start_date), "MMM d yyyy")} - ${format(
              new Date(course.end_date),
              "MMM d yyyy",
            )}`}
          </p>
        </Link>
        {showMenu && (
          <CourseBlockMenu
            menuAnchor={menuAnchor}
            handleCloseMenu={handleCloseMenu}
            openDeleteCourseDialog={openDeleteCourseDialog}
            openRenameCourseDialog={openRenameCourseDialog}
          />
        )}
      </li>
      <ChangeCourseNameDialog
        renameCourseDialog={renameCourseDialog}
        closeRenameCourseDialog={closeRenameCourseDialog}
        course={course}
      />
      <DeleteCourseDialog
        course={course}
        deleteCourseDialog={deleteCourseDialog}
        closeDeleteCourseDialog={closeDeleteCourseDialog}
        handleCloseMenu={handleCloseMenu}
      />
    </>
  );
};
