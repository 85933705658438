import PreviewImg from "../../../img/students.png";
import css from "./table_preview.module.scss";

export const TablePreview = () => {
  return (
    <div>
      <div className={css.preview_header}>
        <img
          className={css.students_image}
          src={PreviewImg}
          alt="happy students"
        />
        <h2 className={css.h2}>Your Students Will Appear Here</h2>
        <h3 className={css.h3}>Press ‘Add Students’ to add</h3>
      </div>
    </div>
  );
};
