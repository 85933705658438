import { useCallback, useState, useEffect } from "react";

export const useScrollPercentage = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const onPageUpdate = useCallback(() => {
    const windowHeight = window.innerHeight;
    const scrolled = window.scrollY;
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );
    const scrollLength = pageHeight - windowHeight;
    setScrollPercentage(scrolled / scrollLength);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onPageUpdate);
    return () => window.removeEventListener("scroll", onPageUpdate);
  }, [onPageUpdate]);
  return scrollPercentage;
};
