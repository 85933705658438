import { Box, Card, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSongUnitTableContent } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentSeed,
  getSeedText,
  getSeedType,
} from "redux/song_seeds/song_seed_selector";
import { updateSongSeed } from "redux/song_seeds/song_seeds_slice";
import {
  EnumDragAndDropActions,
  ICustomSongLyrics,
} from "redux/songwriting/songwriting_interfaces";
import { getCustomSongById } from "redux/songwriting/songwriting_selectors";
import {
  setCurrentSongId,
  updateCustomSong,
} from "redux/songwriting/songwriting_slice";
import { setShowSelectSongOverlay } from "redux/songwriting_mobile/songwriting_mobile_slice";
import { openSongbuilderModal } from "redux/ui/modal/modal_slice";

interface ISongCard {
  song_id?: number;
  title?: string;
  lastUpdated: string;
  firstLine: string;
  purpose?: string;
  setSongAnchorEl?: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

const SongCard = ({
  song_id,
  title,
  lastUpdated,
  firstLine,
  purpose,
  setSongAnchorEl,
}: ISongCard) => {
  const dispatch = useDispatch();

  const currentSeed = useSelector(getCurrentSeed);
  const seedText = useSelector(getSeedText(currentSeed));
  const currentSeedType = useSelector(getSeedType(currentSeed));
  const customSong = useSelector(getCustomSongById(song_id));
  const { collectAndSaveAllUnits } = useSongUnitTableContent();

  const newSongStructureHandler = async () => {
    const formattedText = seedText?.split("\n").join("/n");

    const newSongUnit: ICustomSongLyrics = {
      song_chords: "",
      song_lyrics: formattedText || "",
      song_structure: "New Verse",
      position: customSong ? customSong.custom_song_lyrics.length : 0,
    };

    await dispatch(
      updateCustomSong({
        id: song_id,
        custom_song_lyrics: [
          {
            ...newSongUnit,
            type: EnumDragAndDropActions.createStructure,
          },
        ],
      }),
    );
    collectAndSaveAllUnits();
  };

  const openSongBuilderHandler = () => {
    if (song_id) {
      if (purpose === "link") {
        dispatch(setShowSelectSongOverlay(false));
        if (currentSeedType === "song") {
          dispatch(
            updateCustomSong({
              id: song_id,
              note: `${customSong?.note}\n${seedText}`,
            }),
          );
        } else if (currentSeedType === "lyric") {
          newSongStructureHandler();
        }
        dispatch(updateSongSeed({ status: "Linked", song_id }));
      }
      dispatch(setCurrentSongId({ id: song_id }));
      dispatch(openSongbuilderModal());
    }
  };

  return (
    <Card
      style={{
        width: "85vw",
        borderRadius: 15,
        padding: 15,
        cursor: "pointer",
      }}
      onClick={openSongBuilderHandler}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="column" width="80%">
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: "700",
              fontSize: 16,
              color: "#333333",
            }}
            noWrap
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "italic",
              fontWeight: "400",
              fontSize: 14,
              color: "#939191",
            }}
          >
            {lastUpdated}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: "400",
              fontSize: 16,
              color: "#5f6367",
            }}
            noWrap
          >
            {firstLine}
          </Typography>
        </Box>
        {setSongAnchorEl && (
          <IconButton
            onClick={(e) => {
              if (song_id) {
                e.stopPropagation();
                setSongAnchorEl(e.currentTarget);
                dispatch(setCurrentSongId({ id: song_id }));
              }
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
    </Card>
  );
};

export default SongCard;
