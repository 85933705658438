import { useEffect } from "react";
import classnames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getSkillText } from "components/slides/slide_top_popover";
import { getSelectedAssessments } from "redux/assessments/assessments_selectors";
import { updateSelectedAssessments } from "redux/assessments/assessments_slice";
import { usePopover } from "hooks/use_popover";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { FeedbackPopovers } from "./feedback_popovers";
import { AssessmentVideo } from "./assessment_video";
import css from "./assessment_row.module.scss";

interface IAssessmentRow {
  record: IMediaRecord;
}

export const AssessmentRow = ({ record }: IAssessmentRow) => {
  const {
    user: { fname, lname, id: studentId },
    createdAt,
    lesson: {
      song: { song_name: lessonName },
    },
    skill,
    id: mediaRecordId,
  } = record;
  const skillTypeName = skill?.skill?.name || "Song";

  let skillName = "Full Song";

  if (skill) {
    if (skill.skill?.name === "Strumming") {
      [skillName] = skill.name.split(" ");
    } else if (skill.skill?.name === "Melody") {
      switch (skill.difficulty) {
        case 1:
          skillName = "Easy Melody";
          break;
        case 2:
          skillName = "Medium Melody";
          break;
        // eslint-disable-next-line no-magic-numbers
        case 3:
          skillName = "Hard Melody";
          break;
        default:
          skillName = skill.name;
          break;
      }
    } else {
      skillName = skill.name;
    }
  }

  const dispatch = useDispatch();

  const [feedbackPopoverAnchor, openFeedbackPopover, closeFeedbackPopover] =
    usePopover();

  const selectedAssessments = useSelector(getSelectedAssessments);
  const checked = selectedAssessments.includes(mediaRecordId);

  const toggleCheckbox = () => {
    dispatch(
      updateSelectedAssessments({ id: mediaRecordId, status: !checked }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(updateSelectedAssessments({ id: mediaRecordId, status: false }));
    };
  }, [dispatch, mediaRecordId]);

  return (
    <div className={css.container}>
      <Checkbox
        value={checked}
        checked={checked}
        onClick={() => toggleCheckbox()}
        className={css.checkbox}
        style={{ backgroundColor: "transparent" }}
        disableRipple
      />
      <div className={classnames(css.name, css.row_item)}>
        {fname} {lname}
      </div>
      <div className={classnames(css.createdAt, css.row_item)}>
        {format(new Date(createdAt), "MMMM d, yyyy")}
      </div>
      <div className={classnames(css.skillTypeName, css.row_item)}>
        {skillTypeName}
      </div>
      <div className={classnames(css.skillName, css.row_item)}>
        {getSkillText(skillName)}
      </div>
      <div className={classnames(css.lessonName, css.row_item)}>
        {lessonName}
      </div>
      <div className={classnames(css.actions, css.row_item)}>
        <AssessmentVideo
          record={record}
          openFeedbackPopover={openFeedbackPopover}
        />
      </div>
      <FeedbackPopovers
        mediaRecordId={mediaRecordId}
        studentId={studentId}
        closeFeedbackPopover={closeFeedbackPopover}
        feedbackPopoverAnchor={feedbackPopoverAnchor}
      />
    </div>
  );
};
