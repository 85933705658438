import { useState, useEffect } from "react";
import { SideBar } from "components/sidebar/sidebar";
import { Button } from "@material-ui/core";
import { openAddStudentsModal } from "redux/ui/modal/modal_slice";
import { useDispatch, useSelector } from "react-redux";
import { ProfileNav } from "components/reusable/profile_nav/profile_nav";
import {
  getUserSkillsForStudentsTable,
  getCourseStudents,
  getFetchingStudent,
} from "redux/courses/courses_selectors";
import { useParams } from "react-router-dom";
import { useCourse } from "hooks/useCourse";
import { setFetchingStudents } from "redux/courses/courses_slice";
import css from "./course.module.scss";
import { StudentsTable } from "./students_table";
import { TablePreview } from "./table_preview";
import { CourseTop } from "./course_top";

export const Course = () => {
  const courseId = Number(useParams<{ courseId: string }>().courseId);

  const dispatch = useDispatch();

  const isFetching = useSelector(getFetchingStudent);
  const students = useSelector(getCourseStudents(courseId));
  let studentsWithSkills = useSelector(getUserSkillsForStudentsTable(students));

  useEffect(() => {
    dispatch(setFetchingStudents(false));
  }, [dispatch]);

  useCourse(courseId);

  const [searchValue, setSearchValue] = useState("");

  if (searchValue) {
    studentsWithSkills = studentsWithSkills.filter(
      (student) =>
        student.lname.toLowerCase().includes(searchValue.toLowerCase()) ||
        student.fname.toLowerCase().includes(searchValue.toLowerCase()) ||
        student.email.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }

  let table = null;

  if (!isFetching) {
    if (students.length) {
      table = <StudentsTable students={studentsWithSkills} />;
    } else {
      table = <TablePreview />;
    }
  }

  return (
    <div className={css.main}>
      <SideBar />
      <div className={css.container}>
        <ProfileNav />
        <CourseTop
          students={students}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
        {table}
        <Button
          variant="contained"
          onClick={() => dispatch(openAddStudentsModal(courseId))}
          className={css.add_new_student}
        >
          Add Students
        </Button>
      </div>
    </div>
  );
};
