import { LOCATION_CHANGE } from "connected-react-router";

import { analyticUtil } from "util/analytic";

export const historyMiddleware = _store => next => action => {
  const { type } = action;

  next(action);

  if (type === LOCATION_CHANGE) {
    setTimeout(() => {
      analyticUtil.pageView();
    }, 0);
  }
};
