import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getNodeApiBaseUrl } from "api/base_api";
import { ISkill } from "types/models/skill";

export const skillsApi = createApi({
  reducerPath: "skills",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getNodeApiBaseUrl()}/skills`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getMelodies: builder.query<ISkill[], { instrument: string }>({
      query: ({ instrument }) => ({
        url: "melodies",
        params: { instrument },
      }),
    }),
    getStrummings: builder.query<ISkill[], { instrument: string }>({
      query: ({ instrument }) => ({
        url: "strummings",
        params: { instrument },
      }),
    }),
  }),
});

export const { useGetMelodiesQuery, useGetStrummingsQuery } = skillsApi;
