import { useState } from "react";
import { useSelector } from "react-redux";
import { getActualTableData } from "redux/songwriting/songwriting_selectors";
import { promoApi } from "api/promo_api";
import css from "./custom_song_settings/custom_song_settings.module.scss";

// This feature is currently a proof of concept

const GenerateVoice = () => {
  const actualTableData = useSelector(getActualTableData);
  const [audioPath, setAudioPath] = useState();
  const [audioStatus, setAudioStatus] = useState("singIt");

  let lyricsString = "";

  if (actualTableData.length > 0) {
    actualTableData.forEach((actualTableRow) => {
      actualTableRow.data.forEach((value, key) => {
        if (key % 2 !== 0) {
          lyricsString += `${value.c0} `;
        }
      });
    });
  }

  const singIt = async () => {
    if (audioPath) {
      const audio = new Audio(audioPath);
      audio.play();
      setAudioStatus("singIt");
      setAudioPath(undefined);
    } else {
      const timerVal = 2500;
      setAudioStatus("compileIt");

      const speech = {
        speech: lyricsString,
        voicemodel_uuid: "3939fe41-c49f-46fb-91a9-85164a92a604",
        // voicemodel_uuid: "639f5a27-edbc-444f-bfe9-c7b62aa014f8",
      };

      (async () => {
        const duckData = await promoApi.uberDuckTest(speech);

        await new Promise((resolve) => {
          const interval = setInterval(async () => {
            (async () => {
              const ultimateData = await promoApi.uberDuckResult(
                duckData.data.uuid,
              );
              setAudioPath(ultimateData.data.path);
              setAudioStatus("playIt");
            })();

            clearInterval(interval);
            resolve("nuts");
          }, timerVal);
        });
      })();
    }
  };

  const singItPhrase = () => {
    if (audioStatus === "singIt") {
      return "Sing It For Me";
    }
    if (audioStatus === "compileIt") {
      return "Rendering...";
    }
    return "Play It For Me";
  };

  return (
    <div className={css.padding}>
      <button type="button" onClick={singIt}>
        {singItPhrase()}
      </button>
    </div>
  );
};

export default GenerateVoice;
