import styled from "@emotion/styled";
import { ResetPasswordForm } from "components/modal/reset_password_modal/reset_password_form";
import { Container, InputContainer } from "../emotion.styles";

export const StyledContainer = styled(Container)`
  justify-content: center;
`;

export const StyledResetPasswordForm = styled(ResetPasswordForm)`
  justify-content: center;
  margin: 2em 1em 4em;
`;
export const InputContent = styled(InputContainer)`
  width: 420px;
  margin: 2em 1em 4em;

  > form > input {
    padding: 15px 0px 14px 16px;
    border-radius: 4px;
    background: #f3f3f3;
    color: #818181;
  }
`;
