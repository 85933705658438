import { SideBar } from "components/sidebar/sidebar";
import {
  getSongwritingAssessmentsFilteredRecords,
  getDropAssessmentsSelection,
} from "redux/songwriting_assessments/songwriting_assessments_selectors";
import { getOrgSongwritingSongs } from "redux/songwriting/songwriting_selectors";
import {
  setSongwritingAssessmentsClass,
  setSongwritingAssessmentsStudentName,
  setSongwritingAssessmentsDateRange,
  clearSongwritingAssessmentsFilter,
} from "redux/songwriting_assessments/songwriting_assessments_slice";

import { useSelector } from "react-redux";

import { SelectDateRange } from "components/assessment/select_date_range";
import { ClearSelection } from "components/assessment/clear_selection";
import { SelectByName } from "components/assessment/select_by_name";
import { SelectClass } from "components/assessment/select_class";
import { SongwritingAssessmentTableHeader } from "./songwriting_assessment_row/table_header";
import { SongwritingAssessmentSkeletonRow } from "./songwriting_assessment_row/skeleton_row";
import { SongwritingAssessmentRow } from "./songwriting_assessment_row/assessment_row";

import img from "../assessment/images/no_assessments.svg";
import css from "../assessment/assessment.module.scss";

export const SongwritingAssessment = () => {
  const { finalRecords: records, isFiltered } = useSelector(
    getSongwritingAssessmentsFilteredRecords,
  );

  return (
    <div className={css.container}>
      <SideBar />
      <div className={css.organization_container}>
        <h2 className={css.h2}>Songwriting</h2>
        {records.length || isFiltered ? (
          <>
            <div className={css.sort_buttons}>
              <SelectByName
                getRecordsFunction={getOrgSongwritingSongs}
                setNameFunction={setSongwritingAssessmentsStudentName}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <SelectDateRange
                getRecordsFunction={getOrgSongwritingSongs}
                setDateFunction={setSongwritingAssessmentsDateRange}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <SelectClass
                getRecordsFunction={getOrgSongwritingSongs}
                setClassFunction={setSongwritingAssessmentsClass}
                dropSelectionsFunction={getDropAssessmentsSelection}
              />
              <ClearSelection
                clearFunction={clearSongwritingAssessmentsFilter}
              />
            </div>
            <hr className={css.line} />
            <div className={css.table}>
              <SongwritingAssessmentTableHeader />
              {records.map((record: any) => (
                <SongwritingAssessmentRow key={record.id} record={record} />
              ))}
            </div>
          </>
        ) : (
          <div className={css.no_assessments_container}>
            <img
              src={img}
              alt="no assessments"
              className={css.no_assessments_img}
            />
            <div className={css.no_assessments_text}>
              Your Students Songwriting Will Appear Here
            </div>
            <SongwritingAssessmentTableHeader />
            <SongwritingAssessmentSkeletonRow />
            <SongwritingAssessmentSkeletonRow />
          </div>
        )}
      </div>
    </div>
  );
};
