import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { IOrganization } from "redux/organizations/organization_api";
import { IState } from "redux/store";
import { IOrgWithStudents, orgManagementApi } from "./org_management_api";

export const updateOrganization = createAsyncThunk<
  void,
  Partial<IOrganization>,
  { state: IState }
>("orgManagement/updateOrganization", async (org, { dispatch }) => {
  await orgManagementApi.updateOrganization(org);
  toast.success("Organization was updated");
  dispatch(fetchOrganizations());
});

interface IOrgManagementState {
  organizations: IOrgWithStudents[];
}

const initialState: IOrgManagementState = {
  organizations: [],
};

export const fetchOrganizations = createAsyncThunk(
  "orgManagement/fetchOrganizations",
  async () => {
    const {
      data: { organizations },
    } = await orgManagementApi.fetchOrganizations();
    return organizations;
  },
);

const chordsSlice = createSlice({
  name: "orgManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload;
    });
  },
});

export const { reducer: organizationManagementReducer } = chordsSlice;
