import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import cn from "classnames";
import { MOBILE_WIDTH, useWindowWidth } from "hooks/use_width";
import { useDispatch } from "react-redux";
import { onMoosikoBtnClick } from "redux/ui/modal/modal_slice";
import { ICard } from "..";

import css from "../card.module.scss";

export const CardContent = (props: ICard) => {
  const {
    measurementValue,
    text,
    progress,
    active,
    handleClick,
    isOtherActive,
    children,
    line,
    songCard,
    handleClickClose,
    profileLinkText,
    button = true,
  } = props;

  const width = useWindowWidth();
  const dispatch = useDispatch();
  return (
    <div
      className={cn(
        css.card_wrapper,
        active ? css.card_wrapper_active : "",
        isOtherActive ? css.display_none : "",
      )}
    >
      <button
        type="button"
        onClick={handleClick}
        className={cn(css.card, active ? css.card_active : "")}
      >
        <FontAwesomeIcon
          onClick={active ? handleClickClose : undefined}
          className={css.icon}
          icon={active ? "times" : "info-circle"}
          data-btn="close-btn"
          style={{ color: active ? "#808080" : undefined }}
        />
        <div className={css.number}>{measurementValue}</div>
        <div className={css.text}>{text}</div>
        {active && songCard && button && (
          <button
            type="button"
            className={cn(css.learnNew)}
            onClick={() => {
              if (width < MOBILE_WIDTH && handleClickClose) {
                handleClickClose();
              }
              dispatch(onMoosikoBtnClick());
            }}
          >
            Learn a New Song
          </button>
        )}
        {line && (
          <div className={cn(css.progress, active ? css.progress_active : "")}>
            {progress && (
              <div
                className={cn(
                  css.progress_value,
                  active ? css.progress_value_active : "",
                )}
                style={{ width: `${progress}%` }}
              />
            )}
          </div>
        )}
        {active && (
          <Link className={cn(css.update_skills)} to="/profile">
            {profileLinkText}
          </Link>
        )}
      </button>
      <div className={cn(css.add_info, active ? css.add_info_active : "")}>
        {children}
      </div>
    </div>
  );
};
