import { Box, Card, Typography } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

interface ISeedTypeCard {
  id: string;
  label: string;
  count: number;
  launchFunction: (_id: string) => void;
}

const SeedTypeCard = ({ id, label, count, launchFunction }: ISeedTypeCard) => {
  return (
    <Card
      style={{
        width: "85vw",
        borderRadius: 15,
        padding: 12,
        paddingTop: 20,
        paddingBottom: 20,
        cursor: "pointer",
      }}
      onClick={() => launchFunction(id)}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          style={{
            fontFamily: "Raleway",
            fontWeight: 700,
            fontSize: 16,
            color: "#333333",
          }}
        >
          {label}
        </Typography>
        <Box display="flex" style={{ gap: 3 }}>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 700,
              fontSize: 16,
              color: "#333333",
            }}
          >
            {count}
          </Typography>
          <KeyboardArrowRightIcon style={{ color: "#AAB6C4" }} />
        </Box>
      </Box>
    </Card>
  );
};

export default SeedTypeCard;
