import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserFeedback } from "redux/feedback/feedback_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";

/* eslint-disable no-magic-numbers */
const INTERVAL_IN_MS = 1000 * 30;

export const useTimerActions = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserIdSelector);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (userId) {
        dispatch(fetchUserFeedback({ studentId: userId }));
      }
    }, INTERVAL_IN_MS);
    return () => clearInterval(timer);
  }, [userId, dispatch]);
};
