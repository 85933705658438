import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentSongLyricsBucket,
  updateCustomSelectedSongLyrics,
  updateCustomSongLyrics,
  setSelectedSongBucket,
} from "redux/songwriting/songwriting_slice";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";
import { Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { SongUnitMobileMenu } from "./song_unit_mobile_menu/song_unit_mobile_menu";

interface ISongUnitEditableStructureTitle {
  structure: string;
  id?: number;
}

export const SongUnitEditableStructureTitle = ({
  structure,
  id,
}: ISongUnitEditableStructureTitle) => {
  const dispatch = useDispatch();
  const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

  const [isEditUserStructure, setIsEditUserStructure] = useState(false);
  const [currentUserStructure, setCurrentUserStructure] = useState(structure);
  const mobile = useSelector(getUsingMobileAppFlag);

  const handleSetEditUserStructure = () => {
    setIsEditUserStructure(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditUserStructure(false);
    if (id && structure) {
      dispatch(
        setSelectedSongBucket({
          id,
        }),
      );
    }

    setCurrentUserStructure(e.target.value);

    dispatch(
      updateCustomSelectedSongLyrics({
        song_structure: e.target.value,
        song_lyrics_id: id,
      }),
    );
    dispatch(updateCurrentSongLyricsBucket({ song_structure: e.target.value }));
    dispatch(updateCustomSongLyrics());
  };

  const handleCancel = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    setCurrentUserStructure(structure);
    setIsEditUserStructure(false);
  };

  return (
    <Box
      role="button"
      tabIndex={0}
      onClick={handleSetEditUserStructure}
      display="flex"
      alignItems="center"
      style={{ cursor: "pointer" }}
    >
      {isEditUserStructure ? (
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: "10px" }}
          position="relative"
        >
          <Box display="flex">
            <input
              ref={autoFocus}
              onBlur={handleBlur}
              defaultValue={structure}
              maxLength={18}
              enterKeyHint="enter"
              style={
                mobile
                  ? {
                      border: "1px dashed #4f4f4f",
                      padding: "5px",
                      width: "calc(100vw - 45px)",
                      marginLeft: "10px",
                      fontSize: "16px",
                    }
                  : { borderBottom: "2px solid #3f51b5" }
              }
            />
            <Box display={mobile && "none"}>
              <CloseIcon onMouseDown={handleCancel} />
              <CheckIcon />
            </Box>
          </Box>
          <SongUnitMobileMenu songUnitId={id as number} />
        </Box>
      ) : (
        <h3
          style={
            mobile
              ? {
                  whiteSpace: "pre",
                  padding: "5px 15px",
                  border: "1px dashed transparent",
                }
              : { whiteSpace: "pre" }
          }
        >
          {currentUserStructure}
        </h3>
      )}
    </Box>
  );
};
