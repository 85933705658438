import React, { useCallback } from "react";
import { SONG } from "redux/songwriting/songwriting_consts";
import { useFindActiveCellIndex } from "./use_find_active_index_of_inputs";

interface IUseKeyboardHandlers {
  editableCellRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  setIndexOfInputToFocus: (_: number) => void;
  setPositionOfValueToFocus: (_: { start: number; len: number }) => void;
  deleteRow: (_: number) => void;
  createNewRow: (_position?: number) => void;
  updateRowValue: (_value: string, _rowIndex: number) => void;
  syncTableData: () => void;
}

const DIFFERENCE_BETWEEN_TWO_INPUTS = 2;
const INDEX_OF_FIRST_INPUT = 1;
const EMPTY_INPUT = 0;

export const useKeyboardHandlers = ({
  editableCellRefs,
  setIndexOfInputToFocus,
  setPositionOfValueToFocus,
  deleteRow,
  updateRowValue,
  createNewRow,
  syncTableData,
}: IUseKeyboardHandlers) => {
  const { findActiveIndex } = useFindActiveCellIndex({
    cellRefs: editableCellRefs,
  });

  const putInputValueOnPrevInput = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement>,
      index: number,
      updateRowValue: IUseKeyboardHandlers["updateRowValue"],
    ) => {
      const prevInputIndex = index - DIFFERENCE_BETWEEN_TWO_INPUTS;
      const prevInput = editableCellRefs.current[prevInputIndex];

      if (e.currentTarget.value.length === 0 && prevInput) {
        prevInput.focus();
      }
      if (
        prevInput &&
        e.currentTarget.value.length >= 0 &&
        e.currentTarget.selectionStart === 0
      ) {
        e.preventDefault();
        const prevLength = prevInput.value.length;

        const prevValue = prevInput.value + e.currentTarget.value;

        const remainderString = prevValue.slice(SONG.MAX_LENGTH_EDITABLE_CELL);

        updateRowValue(prevValue, prevInputIndex);

        if (remainderString === "") {
          e.preventDefault();
          deleteRow(index);
        } else {
          updateRowValue(remainderString, index);
        }

        prevInput.setSelectionRange(prevLength, prevLength);

        setIndexOfInputToFocus(prevInputIndex);
      }
    },
    [editableCellRefs, setIndexOfInputToFocus, deleteRow],
  );

  const putInputValueOnNextInput = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      const nextInputIndex = index + DIFFERENCE_BETWEEN_TWO_INPUTS;
      const currentValue = e.currentTarget.value;
      const caretPosition = e.currentTarget.selectionStart || 0;

      if (caretPosition !== currentValue.length) {
        const trimStringAfterSelectionCaret =
          caretPosition !== 0
            ? currentValue.slice(caretPosition)
            : currentValue;

        const trimmedStringBeforeSelectionCaret = currentValue.slice(
          0,
          caretPosition,
        );
        updateRowValue(trimStringAfterSelectionCaret, nextInputIndex);
        updateRowValue(trimmedStringBeforeSelectionCaret, index);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editableCellRefs],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const moveCursorToNextLine = (pos: number | null) => {
      const index = findActiveIndex();
      if (index) {
        const nextInputIndex = index + DIFFERENCE_BETWEEN_TWO_INPUTS;
        const nextInputExists = editableCellRefs.current[nextInputIndex];

        if (nextInputExists) {
          setIndexOfInputToFocus(nextInputIndex);
          setPositionOfValueToFocus({ start: pos || 0, len: 0 });
        }
      }
    };

    const moveCursorToPreviousLine = (pos: number | null) => {
      const index = findActiveIndex();
      if (index) {
        const nextInputIndex = index - DIFFERENCE_BETWEEN_TWO_INPUTS;
        const nextInputExists = editableCellRefs.current[nextInputIndex];

        if (nextInputExists) {
          setIndexOfInputToFocus(nextInputIndex);
          setPositionOfValueToFocus({ start: pos || 0, len: 0 });
        }
      }
    };

    switch (e.key) {
      case "Enter": {
        const index = findActiveIndex();
        if (index) {
          const nextInputIndex = index + DIFFERENCE_BETWEEN_TWO_INPUTS;
          createNewRow(index);
          putInputValueOnNextInput(e, index);
          setIndexOfInputToFocus(nextInputIndex);
          setPositionOfValueToFocus({ start: 0, len: 0 });
        }

        break;
      }
      case "Delete":
      case "Backspace": {
        if (document.getSelection()!.toString()) {
          break;
        } else {
          const index = findActiveIndex();
          if (
            index === INDEX_OF_FIRST_INPUT &&
            !editableCellRefs.current[index + DIFFERENCE_BETWEEN_TWO_INPUTS]
          ) {
            return;
          }
          if (
            index &&
            editableCellRefs.current[index]?.value.length === EMPTY_INPUT
          ) {
            e.preventDefault();

            deleteRow(index);
            setIndexOfInputToFocus(index - DIFFERENCE_BETWEEN_TWO_INPUTS);
            setPositionOfValueToFocus({
              start:
                editableCellRefs.current[index - DIFFERENCE_BETWEEN_TWO_INPUTS]
                  ?.value.length || 0,
              len: 0,
            });
            return;
          }
          if (index) {
            putInputValueOnPrevInput(e, index, updateRowValue);
            setPositionOfValueToFocus({
              start:
                editableCellRefs.current[index - DIFFERENCE_BETWEEN_TWO_INPUTS]
                  ?.value.length || 0,
              len: 0,
            });
          }
          break;
        }
      }
      case "ArrowUp": {
        moveCursorToPreviousLine(e.currentTarget.selectionStart);

        break;
      }
      case "ArrowDown": {
        moveCursorToNextLine(e.currentTarget.selectionStart);

        break;
      }
      case "ArrowRight": {
        if (e.currentTarget.value.length === e.currentTarget.selectionStart) {
          e.preventDefault();
          moveCursorToNextLine(0);
        }

        break;
      }

      case "ArrowLeft": {
        if (
          e.currentTarget.selectionStart === 0 &&
          e.currentTarget.selectionEnd === 0
        ) {
          e.preventDefault();
          moveCursorToPreviousLine(SONG.MAX_LENGTH_EDITABLE_CELL);
        }

        break;
      }
      default: {
        const index = findActiveIndex();

        if (index !== null) {
          const fieldLength = e.currentTarget.value.length;
          const nextInputIndex = index + DIFFERENCE_BETWEEN_TWO_INPUTS;
          //  This Ensures that e.key is a single displayable character
          if (e.key.length === 1) {
            if (fieldLength >= SONG.MAX_LENGTH_EDITABLE_CELL) {
              const fieldPosition = e.currentTarget.selectionStart || 0;
              if (fieldPosition >= SONG.MAX_LENGTH_EDITABLE_CELL) {
                createNewRow(index);
                syncTableData();
                setIndexOfInputToFocus(nextInputIndex);
              } else {
                const caretPosition = e.currentTarget.selectionStart || 0;
                const stringStart = e.currentTarget.value.substring(
                  0,
                  caretPosition,
                );
                const stringEnd = e.currentTarget.value.substring(
                  caretPosition,
                  e.currentTarget.value.length - 1,
                );
                const stringLastCharacter = e.currentTarget.value.substring(
                  e.currentTarget.value.length - 1,
                  e.currentTarget.value.length,
                );
                const nextInputValue = !editableCellRefs.current[nextInputIndex]
                  ?.value
                  ? ""
                  : editableCellRefs.current[nextInputIndex]?.value;
                const nextInputNewValue = stringLastCharacter + nextInputValue;

                if (!editableCellRefs.current[nextInputIndex]) {
                  createNewRow(index);
                }
                updateRowValue(stringStart + e.key + stringEnd, index);
                updateRowValue(nextInputNewValue, nextInputIndex);
                setIndexOfInputToFocus(index);
                setPositionOfValueToFocus({ start: caretPosition + 1, len: 0 });
              }
            }
          }
        }
        return null;
      }
    }
  };
  return { handleKeyDown };
};
