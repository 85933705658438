import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLessonsCacheSelector } from "redux/entities/lessons/lesson_selectors";

import { SkillRecorder } from "components/slides/media-recorder/skill_recorder";
import {
  GUITAR_CHORD_LIBRARY_TYPE,
  openChordLibraryModal,
  UKULELE_CHORD_LIBRARY_TYPE,
} from "redux/ui/modal/modal_slice";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { getOrganizationRecordToggle } from "redux/organizations/organization_selectors";

import { SongSheet } from "./song_sheet";
import { AlphaTabSheet } from "./AlphaTabSheet";
import { SongSheetTopBar } from "./song_sheet_top_bar";
import css from "./song_sheet.module.scss";

interface ISongSheetPage {
  match: { params: { id: number } };
}

export const SongSheetPage = (props: ISongSheetPage) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const accessVideoRecording = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_VIDEO_RECORDING),
  );
  const recordToggle = useSelector(getOrganizationRecordToggle);
  const lesson = useSelector(getLessonsCacheSelector)[id];

  const defaultBPM = lesson.song.tempo;
  const [tempo, setTempo] = useState(defaultBPM);

  const isMelodyLesson = !!lesson.alpha_tab_code;
  const dispatch = useDispatch();

  let chordLibraryType = GUITAR_CHORD_LIBRARY_TYPE;

  if (lesson.instrument === "Ukulele") {
    chordLibraryType = UKULELE_CHORD_LIBRARY_TYPE;
  }

  const handleOpen = () => {
    dispatch(openChordLibraryModal(chordLibraryType));
  };
  return (
    <div className={css.song_sheet_page}>
      {accessVideoRecording && recordToggle && (
        <SkillRecorder skill={undefined} songRecord />
      )}

      <SongSheetTopBar
        handleOpenChords={handleOpen}
        lesson={lesson}
        tempo={tempo}
        setTempo={setTempo}
      />

      <div className={css.container}>
        {isMelodyLesson ? (
          <AlphaTabSheet lesson={lesson} />
        ) : (
          <SongSheet lesson={lesson} />
        )}
      </div>
    </div>
  );
};
