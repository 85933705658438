import { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { closeClassModal } from "redux/ui/modal/modal_slice";
import {
  getAddClassModalStatus,
  getClassModalInfoDisabled,
} from "redux/ui/modal/modal_selectors";
import CloseIcon from "@material-ui/icons/Close";
import { ADD_STUDENTS, INFO } from "../organization_constants";
import { CreateClassTab } from "../create_class_tab/create_class_tab";
import css from "./class_actions_modal.module.scss";
import { AddStudentsTab } from "../add_students_tab/add_students_tab";
import { LoseDataWarning } from "./lose_data_warning_modal";
import { ClassActionsTabs } from "./class_actions_tabs";

export const AddClassModal = () => {
  const open = useSelector(getAddClassModalStatus);
  const [loseDataWarningDialog, setLoseDataWarningDialog] = useState(false);
  const infoDisabled = useSelector(getClassModalInfoDisabled);

  const [tab, setTab] = useState(infoDisabled ? ADD_STUDENTS : INFO);
  const dispatch = useDispatch();
  const [selectedStudentsCount, setSelectedStudentsCount] = useState(0);

  useEffect(() => {
    if (infoDisabled) {
      setTab(ADD_STUDENTS);
    } else {
      setTab(INFO);
    }
  }, [infoDisabled]);
  const handleCloseModal = () => {
    if (tab === ADD_STUDENTS && selectedStudentsCount) {
      setLoseDataWarningDialog(true);
    } else {
      setTab(infoDisabled ? ADD_STUDENTS : INFO);
      dispatch(closeClassModal());
    }
  };

  const closeLoseDataWarningDialog = () => {
    setLoseDataWarningDialog(false);
  };

  return (
    <>
      <LoseDataWarning
        loseDataWarningDialog={loseDataWarningDialog}
        closeLoseDataWarningDialog={closeLoseDataWarningDialog}
      />
      <Dialog
        maxWidth="md"
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{
          paper: css.paper,
        }}
        className={css.paper}
        onClose={handleCloseModal}
      >
        <button
          type="button"
          onClick={handleCloseModal}
          className={css.close_icon}
        >
          <CloseIcon />
        </button>
        <div className={css.container}>
          <h2 className={css.h2}>
            {tab === INFO ? "Class info" : "Add Students"}
          </h2>
          <ClassActionsTabs setTab={setTab} tab={tab} />

          {tab === INFO ? (
            <CreateClassTab setTab={setTab} />
          ) : (
            <AddStudentsTab
              setSelectedStudentsCount={setSelectedStudentsCount}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};
