/* eslint-disable no-unused-vars */
import { useState } from "react";
import { TabButton } from "../add_students_tab/tab_button/tab_button";
import css from "../add_students_tab/add_students_tab.module.scss";
import { ImportOptions } from "../add_students_tab/constants";
import { SubTabs } from "../add_students_tab/sub_tabs/sub_tabs";
import GoogleClassroomLogo from "../../sign_up/images/google-classroom.svg";
import MsTeamsLogo from "../../sign_up/images/microsoft-teams.svg";

export enum ExternalResource {
  GOOGLE_CLASSROOM = "GOOGLE_CLASSROOM",
  MS_TEAMS = "MS_TEAMS",
}

export const AddFromExternalResources = () => {
  const { NO_TAB, ADD_FROM_MS_TEAMS, ADD_FROM_GOOGLE_CLASSROOM } =
    ImportOptions;
  const [subTab, setSubTab] = useState<ImportOptions>(NO_TAB);

  return subTab === NO_TAB ? (
    <div className={css.container_external}>
      <TabButton
        imgSource={MsTeamsLogo}
        setSubTab={setSubTab}
        tabTitle={ADD_FROM_MS_TEAMS}
        header="Microsoft Teams"
      />
      <TabButton
        imgSource={GoogleClassroomLogo}
        setSubTab={setSubTab}
        tabTitle={ADD_FROM_GOOGLE_CLASSROOM}
        header="Google Classrooms"
      />
    </div>
  ) : (
    <SubTabs subTab={subTab} />
  );
};
