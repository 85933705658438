import React, { useState } from "react";
import classnames from "classnames";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import {
  IMediaRecord,
  mediaRecordingsApi,
} from "redux/media_recordings/media_recordings_api";
import { updateMediaRecords } from "redux/media_recordings/media_recordings_slice";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useGetVideoWithAccess } from "hooks/use_get_video";
import { Dialog, Tooltip } from "@material-ui/core";
import { Video } from "components/video";
import { FeedbackDialog } from "components/my_journey/feedback/feedback_row/feedback_dialog";
import { UseDialog } from "hooks/use_dialog";
import ShareIcon from "@material-ui/icons/Share";
import { simpleEncrypt } from "helpers/encryption";
import css from "./assessment_video.module.scss";

interface IAssessmentVideo {
  openFeedbackPopover: (e: React.MouseEvent) => void;
  record: IMediaRecord;
}

export const AssessmentVideo = ({
  record,
  openFeedbackPopover,
}: IAssessmentVideo) => {
  const {
    viewed,
    // old link, now all videos stored in video entity
    encoded_video,
    feedbacks,
    id: mediaRecordId,
    video,
    // TODO: in 1 year remove this firstVersionOfVideoURLNotTransformedOnS3, it used only for a couple first recorded videos
    URL: firstVersionOfVideoURLNotTransformedOnS3,
  } = record;
  const dispatch = useDispatch();
  const { encoded_video_url } = video || {};
  const [videoModal, setVideoModal] = useState(false);

  const handleCloseModal = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    event.preventDefault();
    setVideoModal(false);
  };

  const handleModalModal = () => {
    setVideoModal(true);
    mediaRecordingsApi.updateRecordings({ viewed: true, id: mediaRecordId });
    dispatch(updateMediaRecords({ id: mediaRecordId, viewed: true }));
  };

  const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  const videoURLWithAccess = useGetVideoWithAccess(
    encoded_video_url ||
      encoded_video ||
      firstVersionOfVideoURLNotTransformedOnS3,
  );

  const [feedback] = feedbacks;

  const [feedbackDialog, closeFeedbackDialog, openFeedbackDialog] = UseDialog(
    {},
  );

  const showNote = !!feedback?.text;
  const feedbackVideoURL = useGetVideoWithAccess(
    feedback?.video?.encoded_video_url || feedback?.encoded_video || null,
  );

  const [downloadTooltip, closeDownloadTooltip, openDownloadTooltip] =
    UseDialog();

  const copyVideoToClipboard = () => {
    openDownloadTooltip({});
    const encryptedMediaRecordId = simpleEncrypt(mediaRecordId);
    const link = `${window.location.origin}/#/video/${encryptedMediaRecordId}`;

    window.navigator.clipboard.writeText(link);
    setTimeout(closeDownloadTooltip, 1000);
  };

  return (
    <>
      <div className={classnames(css.play_video_container, {})}>
        <button
          onClick={handleModalModal}
          type="button"
          className={classnames(css.inner_container, {
            [css.play_video_hint__unwatched]: !viewed,
          })}
        >
          <PlayCircleOutlineIcon> </PlayCircleOutlineIcon>
          <div
            className={classnames(css.play_video_text, {
              [css.play_video_hint__unwatched]: !viewed,
            })}
          >
            Play Video
          </div>
        </button>
        <div className={css.inner_container}>
          <Tooltip
            open={downloadTooltip}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={closeDownloadTooltip}
            title="Link copied"
            aria-label="Link copied"
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <button
              onClick={copyVideoToClipboard}
              type="button"
              className={css.share_button}
            >
              <ShareIcon />
            </button>
          </Tooltip>
          <a
            href={videoURLWithAccess || undefined}
            onClick={handleDownloadClick}
            type="button"
            target="__blank"
            className={css.download_video}
          >
            <GetAppIcon />
          </a>
        </div>
      </div>
      <Dialog open={videoModal} maxWidth="sm" onClose={handleCloseModal}>
        {videoURLWithAccess && <Video src={videoURLWithAccess} />}
      </Dialog>
      {feedback ? (
        <button
          type="button"
          onClick={openFeedbackDialog}
          className={css.send_feedback}
        >
          {feedback.viewed ? (
            <>
              Viewed on{" "}
              {format(new Date(String(feedback.updatedAt)), "MMM d, h:mma")}
            </>
          ) : (
            <>
              Feedback sent on{" "}
              {format(new Date(String(feedback.updatedAt)), "MMM d, h:mma")}
            </>
          )}
        </button>
      ) : (
        <button
          type="button"
          onClick={openFeedbackPopover}
          className={css.send_feedback}
        >
          Send Feedback
        </button>
      )}
      <FeedbackDialog
        videoURL={feedbackVideoURL}
        closeFeedbackDialog={closeFeedbackDialog}
        feedbackDialog={feedbackDialog}
        feedback={feedback}
        showNote={showNote}
      />
    </>
  );
};
