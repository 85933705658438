import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentCustomSong,
  getReplacedSongUnit,
  getCollapsedStructures,
} from "redux/songwriting/songwriting_selectors";
import { EnumDragAndDropActions } from "redux/songwriting/songwriting_interfaces";
import {
  updateCustomSongUnitPositions,
  setIsReplacingSongStructure,
} from "redux/songwriting/songwriting_slice";
import { DragOverlay, useDndMonitor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";

import {
  getUserEmailSelector,
  getUserIdSelector,
} from "redux/auth/user_selectors";
import { SongLyricsList } from "./song_lyrics_list/song_lyrics_list";
import { SongUnit } from "../song_unit/song_unit";

interface ISongUnitList {
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SongUnitList = ({ setChordAnchorEl }: ISongUnitList) => {
  const currentSong = useSelector(getCurrentCustomSong);
  const replacedSongUnit = useSelector(getReplacedSongUnit);
  const collapsedStructures = useSelector(getCollapsedStructures);
  const userId = useSelector(getUserIdSelector);
  const userEmail = useSelector(getUserEmailSelector);

  const [unitList, setUnitList] = useState(currentSong.custom_song_lyrics);

  const dispatch = useDispatch();

  useEffect(() => {
    setUnitList(currentSong.custom_song_lyrics);
  }, [currentSong.custom_song_lyrics]);

  useDndMonitor({
    onDragEnd(event) {
      const { active, over } = event;
      if (
        active.data.current?.typeDragAndDropAction ===
        EnumDragAndDropActions.replaceStructure
      ) {
        const draggedUnitId = active.data.current?.structureId;
        const overId = over?.data.current?.structureId;
        if (draggedUnitId === overId || !over || !draggedUnitId || !overId) {
          dispatch(setIsReplacingSongStructure(null));
        }
        setUnitList((items) => {
          const oldIndex = items.map((e) => e.id).indexOf(draggedUnitId);
          const newIndex = items.map((e) => e.id).indexOf(overId);

          const unitsArray = arrayMove(items, oldIndex, newIndex);

          const unitPositionsArray = unitsArray.reduce(
            (
              acc: { position: number; song_structure_id: number }[],
              cur,
              index,
            ) => {
              if (cur.id) {
                acc.push({ position: index, song_structure_id: cur.id });
              }

              return acc;
            },
            [],
          );

          userId !== currentSong.user_id && userEmail
            ? dispatch(
                updateCustomSongUnitPositions({
                  order_data: unitPositionsArray,
                  userEmail,
                }),
              )
            : dispatch(
                updateCustomSongUnitPositions({
                  order_data: unitPositionsArray,
                }),
              );
          dispatch(setIsReplacingSongStructure(null));
          return unitsArray;
        });
      }
    },
  });

  return (
    <SortableContext
      strategy={verticalListSortingStrategy}
      items={unitList.map((item, index) => ({ ...item, id: index }))}
    >
      <SongLyricsList
        unitList={unitList}
        collapsedStructures={collapsedStructures}
        setChordAnchorEl={setChordAnchorEl}
      />
      {!!replacedSongUnit && (
        <DragOverlay>
          <SongUnit
            songUnit={replacedSongUnit}
            collapsedStructures={collapsedStructures}
            setChordAnchorEl={setChordAnchorEl}
          />
        </DragOverlay>
      )}
    </SortableContext>
  );
};
