import cn from "classnames";
import styled from "@emotion/styled";
import Switch from "@material-ui/core/Switch";

import Button from "components/reusable/buttons/button";
import { IStripeProduct } from "components/stripe/stripe_checkout/types";
import React, { ChangeEvent } from "react";
import css from "./subscription_plan.module.scss";

interface ISubscriptionPlan {
  title: string;
  submitBtnText?: string;
  classes?: { submit_btn: string };
  priceBlock: React.ReactElement;
  listItems: {
    id: number;
    title: string;
  }[];
  onSubmit: () => void;
  showSubmitBtn?: boolean;
  checked?: boolean;
  handleSwitchChange?: (
    _event: ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    checked: boolean,
  ) => void;
  product?: IStripeProduct;
}
export const SubscriptionPlan = (props: ISubscriptionPlan) => {
  const {
    title,
    submitBtnText = "Choose",
    classes,
    priceBlock,
    listItems = [],
    onSubmit,
    showSubmitBtn = true,
    checked,
    handleSwitchChange,
    product,
  } = props;

  const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");

  return (
    <div className={css.plan}>
      <div className={css.plan_title}>{title}</div>
      {product ? (
        <SwitchText>
          <span>Yearly {!iOSApp && "(save 30%)"}</span>
          <Switch checked={checked} onChange={handleSwitchChange} />
          Monthly
        </SwitchText>
      ) : null}
      <div className={css.plan_price}>{priceBlock}</div>

      {showSubmitBtn && (
        <div className={css.submit_btn_wrapper}>
          <Button
            classes={{ btn: cn(css.submit_btn, classes?.submit_btn) }}
            onClick={onSubmit}
          >
            {submitBtnText}
          </Button>
        </div>
      )}

      <ul className={cn(css.items)}>
        {listItems.map((item) => {
          const { id, title } = item;

          return (
            <li className={css.item} key={id}>
              {title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const SwitchText = styled.section`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #190067;
`;
