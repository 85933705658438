/* eslint-disable no-magic-numbers */
import React from "react";
import classnames from "classnames";
import { ISlide } from "api/lesson_api";
import css from "./slide_types.module.scss";
import { AudioPlayer } from "../../audioplayer/AudioPlayer";

interface ITeach {
  slide: ISlide;
}

const Teach = ({ slide }: ITeach) => {
  let { assets = [] } = slide;
  const { text_bottom, text_top } = slide;

  assets = Object.values(assets);
  // eslint-disable-next-line no-magic-numbers
  const imgs = assets.slice(0, -1);
  let textTopElement: string | React.ReactElement = text_top;

  if (textTopElement.includes(";")) {
    const splitText = textTopElement.split(";");

    textTopElement = (
      <>
        <span>{splitText[0]}</span>
        <span>
          <b>{splitText[1]}</b>
        </span>
        <span>{splitText[2]}</span>
      </>
    );
  }
  return (
    <div className={css.main}>
      <div className={css.text_top}>{textTopElement}</div>
      <div className={classnames(css.mid, css.teach_mid)}>
        <div className={css.t_imgs}>
          {imgs.map((img) => {
            return (
              <img
                key={img.asset_num}
                className={css.img}
                src={img.url.replaceAll("#", "%23")}
                alt=""
              />
            );
          })}
        </div>
        <div className={css.t_audio}>
          <AudioPlayer
            src={assets.slice().reverse()[0].url.replaceAll("#", "%23")}
          />
        </div>
      </div>
      <div className={css.text_bot}>{text_bottom}</div>
    </div>
  );
};

export default Teach;
