import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesEntity,
  getCourses,
  getCourseStudents,
} from "redux/courses/courses_selectors";
import { fetchCourseStudents } from "redux/courses/courses_slice";

export function useCourse(courseId: number) {
  const dispatch = useDispatch();
  const courses = useSelector(getCourses);
  const { startDate, endDate } = useSelector(getCoursesEntity);
  const isCourseFetched = courses.some((course) => course.id === courseId);
  const course = courses.find((course) => course.id === courseId);
  const students = useSelector(getCourseStudents(courseId));

  const usersCount = course?.users?.length;
  useEffect(
    () => {
      if (isCourseFetched) {
        dispatch(
          fetchCourseStudents({ courseId, dateRange: { startDate, endDate } }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      courseId,
      isCourseFetched,
      students.length,
      usersCount,
      // startDate,
      // endDate,
    ],
  );
}
