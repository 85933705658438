import { ROLES, TEACHER_COST } from "redux/auth/roles/roles_consts";
import { DEV } from "util/vars";

const MONTHLY_MEMBER_PRICE = "15";
const YEARLY_MEMBER_PRICE = "119";

const MONTHLY_SONGWRITING_PRICE = "3.99";
const YEARLY_SONGWRITING_PRICE = "39.99";

const testProducts = {
  month: {
    id: "price_1Iwo6dIDGw03DOx53CQgnq5Y",
    price: MONTHLY_MEMBER_PRICE,
    postfix: "mo",
  },
  year: {
    id: "price_1Iwo6VIDGw03DOx5uRCGg3cp",
    price: YEARLY_MEMBER_PRICE,
    postfix: "year",
  },

  [ROLES.SOLO_TEACHER]: {
    id: "price_1Iwo6VIDGw03DOx5uRCGg3cp",
    price: TEACHER_COST[ROLES.SOLO_TEACHER],
    postfix: "year",
  },
  [ROLES.PAID_TEACHER_ACCOUNT]: {
    id: "price_1JpTp7IDGw03DOx583ROuoW7",
    price: TEACHER_COST[ROLES.PAID_TEACHER_ACCOUNT], // per student
    postfix: "year",
  },
  [ROLES.FREE_TEACHER_ACCOUNT]: {
    id: "price_1Iwo6VIDGw03DOx5uRCGg3cp",
    price: TEACHER_COST[ROLES.FREE_TEACHER_ACCOUNT],
    postfix: "year",
  },
  songwritingMonth: {
    id: "price_1P70tBIDGw03DOx5di7VePxG",
    price: MONTHLY_SONGWRITING_PRICE,
    postfix: "mo",
  },
  songwritingYear: {
    id: "price_1P70tBIDGw03DOx57TC5uUXc",
    price: YEARLY_SONGWRITING_PRICE,
    postfix: "year",
  },
};

const prodProducts = {
  month: {
    id: "price_1IlylyIDGw03DOx5CGIB4rIz",
    price: MONTHLY_MEMBER_PRICE,
    postfix: "mo",
  },
  year: {
    id: "price_1IlymuIDGw03DOx5UI6ELkdq",
    price: YEARLY_MEMBER_PRICE,
    postfix: "year",
  },

  [ROLES.SOLO_TEACHER]: {
    id: "price_1JvM3EIDGw03DOx5l7ca7xub",
    price: TEACHER_COST[ROLES.SOLO_TEACHER],
    postfix: "year",
  },
  [ROLES.PAID_TEACHER_ACCOUNT]: {
    id: "price_1JvM3EIDGw03DOx5l7ca7xub",
    price: TEACHER_COST[ROLES.PAID_TEACHER_ACCOUNT], // per student
    postfix: "year",
  },
  [ROLES.FREE_TEACHER_ACCOUNT]: {
    id: "price_1JvM3EIDGw03DOx5l7ca7xub",
    price: TEACHER_COST[ROLES.FREE_TEACHER_ACCOUNT],
    postfix: "year",
  },
  songwritingMonth: {
    id: "price_1OLXtZIDGw03DOx5fEqsmKng",
    price: MONTHLY_SONGWRITING_PRICE,
    postfix: "mo",
  },
  songwritingYear: {
    id: "price_1OLXtZIDGw03DOx5Knm3X3qG",
    price: YEARLY_SONGWRITING_PRICE,
    postfix: "year",
  },
};

const PROD = process.env.REACT_APP_NODE_ENV === "production";

export const stripePubKey = DEV
  ? "pk_test_lhFlVXCzLuRAQBB6tnyilEOM"
  : "pk_live_yqsS3UzqD51m9qYiADf9P8R1";

export const products = PROD ? prodProducts : testProducts;
