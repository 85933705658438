import { ExternalResource } from "components/organization/add_from_external_resources/add_from_external_resources";
import { msalInstance } from "components/sign_up/reusable/SSHLogIn";
/* eslint-disable no-unused-vars */
import { handleClassroomAuthClick } from "helpers/google_classroom_auth_helper";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listGoogleClassroomCourses } from "redux/import_students/google_classroom/google_classroom_students_slice";
import {
  listMSTeamsGroups,
  setMsAccessToken,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_slice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { stepListActions } from "redux/step_list/stepListSlice";
import { AppDispatch } from "types/redux_";

import { stepsId } from "../../../newSignUp";
import { TopLineProgress } from "../../../reusable/top_line_progress";
import { StretchContainer, StyledButtonBack, Top } from "../../emotion.styles";
import { BulkImportClassroomList } from "../bulk_import_classroom_list/bulk_import_classroom_list";
import { StyledBackIcon } from "../bulk_import_from_google_classroom/bulk_import_from_google_classroom.styled";
import { BulkImportStudentList } from "../bulk_import_student_list/bulk_import_student_list";

export enum STEPS {
  CLASSROOM_LIST = "CLASSROOM_LIST",
  STUDENT_LIST = "STUDENT_LIST",
}

export const BulkImportFromMsTeams = () => {
  const [step, setStep] = useState<STEPS>(STEPS.CLASSROOM_LIST);

  const dispatch = useDispatch<AppDispatch>();

  const handleBackStep = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.addStudents,
      }),
    );
  };
  useEffect(() => {
    (async () => {
      const login = await msalInstance.loginPopup({
        scopes: [
          "TeamSettings.Read.All",
          "Group.Read.All",
          "TeamMember.Read.All",
        ],
      });
      dispatch(setMsAccessToken(login.accessToken));
      dispatch(listMSTeamsGroups({}));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={90} />
        <StyledButtonBack type="button" onClick={handleBackStep}>
          <StyledBackIcon />
        </StyledButtonBack>
      </Top>
      {step === STEPS.CLASSROOM_LIST ? (
        <BulkImportClassroomList
          type={ExternalResource.MS_TEAMS}
          setCurrentStep={setStep}
        />
      ) : (
        <BulkImportStudentList type={ExternalResource.MS_TEAMS} />
      )}
    </StretchContainer>
  );
};
