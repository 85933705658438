import { SongContent } from "redux/songwriting/songwriting_interfaces";
import { useSelector } from "react-redux";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";
import { DraggableItem } from "./grid_item/draggable_item/draggable_item";
import css from "./song_items_grid.module.scss";
import { MobileItem } from "./grid_item/mobile_item/mobile_item";

interface ISongItemsGrid {
  content: SongContent[];
  tipText: string;
  closeMenuHandler?: () => void;
}

export const SongItemsGrid = ({
  content,
  tipText,
  closeMenuHandler,
}: ISongItemsGrid) => {
  const mobile = useSelector(getUsingMobileAppFlag);

  return (
    <div className={css.content_wrapper}>
      <div className={css.tip_text}>{tipText}</div>
      <ul>
        {content.map((item) =>
          mobile && closeMenuHandler ? (
            <MobileItem
              key={item.title}
              item={item}
              closeMenuHandler={closeMenuHandler}
            />
          ) : (
            <DraggableItem key={item.title} item={item} />
          ),
        )}
      </ul>
    </div>
  );
};
