import { useCallback } from "react";
import { DragEndEvent } from "@dnd-kit/core";
import {
  setDraggableItem,
  setTypeOfDragAndDropAction,
  setIsDraggedChord,
  updateCustomSong,
  setIdSongStructureWhenReplaceChordInList,
} from "redux/songwriting/songwriting_slice";
import {
  getTypeOfDragAndDropAction,
  getCurrentCustomSong,
  getIdSongStructureWhenReplaceChordInList,
} from "redux/songwriting/songwriting_selectors";
import {
  EnumDragAndDropActions,
  ICustomSongLyrics,
  SongStructureType,
  OverDataChord,
  ActiveDataChord,
} from "redux/songwriting/songwriting_interfaces";
import { removeDraggablePrefix } from "helpers/remove_draggable_prefix";
import { useDispatch, useSelector } from "react-redux";
import { useChordsTable } from "./use_chords_table";
import { useSongUnitTableContent } from "./use_song_unit_table_content";

const MINIMAL_DISTANCE_FOR_REPLACE_CHORD_BY_X = 3;
const MINIMAL_DISTANCE_FOR_REPLACE_CHORD_BY_Y = 40;

export const useHandleOnDragEnd = () => {
  const dispatch = useDispatch();

  const typeOfDraggedElement = useSelector(getTypeOfDragAndDropAction);
  const currentSong = useSelector(getCurrentCustomSong);
  const idSongStructureWhenReplaceChordInList = useSelector(
    getIdSongStructureWhenReplaceChordInList,
  );

  const {
    addNewChordToStructure,
    deleteChordFromStructure,
    replaceChordInTable,
  } = useChordsTable();

  const { collectAndSaveAllUnits } = useSongUnitTableContent();

  const addNewStructureToSong = useCallback(
    (movedItemId: SongStructureType) => {
      const newSongUnit: ICustomSongLyrics = {
        song_chords: "",
        song_lyrics: "",
        song_structure: movedItemId,
        position: currentSong.custom_song_lyrics.length,
      };
      dispatch(
        updateCustomSong({
          custom_song_lyrics: [
            {
              ...newSongUnit,
              type: EnumDragAndDropActions.createStructure,
            },
          ],
        }),
      );
      collectAndSaveAllUnits();
    },
    [collectAndSaveAllUnits, currentSong.custom_song_lyrics.length, dispatch],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      const movedItemId = removeDraggablePrefix(active.id as string);
      switch (typeOfDraggedElement) {
        case EnumDragAndDropActions.addChordToStructure: {
          if (!over || !over.id.toString().startsWith("droppable")) {
            break;
          }
          const { structureId, rowId, columnId } = over.data
            .current as OverDataChord;
          addNewChordToStructure(movedItemId, structureId, rowId, columnId);
          break;
        }
        case EnumDragAndDropActions.createStructure: {
          if (!over) {
            break;
          }

          addNewStructureToSong(movedItemId as SongStructureType);
          break;
        }
        case EnumDragAndDropActions.replaceChordInStructure: {
          if (
            !over ||
            !over.id
              .toString()
              .startsWith(`droppable/-${idSongStructureWhenReplaceChordInList}`)
          ) {
            const { title, structureId, rowId, columnId } = active.data
              .current as ActiveDataChord;
            deleteChordFromStructure({ title, structureId, rowId, columnId });
            break;
          }
          const shouldNotReplaceChord =
            Math.abs(event.delta.x) <=
              MINIMAL_DISTANCE_FOR_REPLACE_CHORD_BY_X &&
            Math.abs(event.delta.y) <= MINIMAL_DISTANCE_FOR_REPLACE_CHORD_BY_Y;

          if (!active || shouldNotReplaceChord) {
            break;
          }

          replaceChordInTable(
            active.data.current as ActiveDataChord,
            over.data.current as OverDataChord,
          );

          break;
        }

        default: {
          break;
        }
      }
      dispatch(setDraggableItem(null));
      dispatch(setTypeOfDragAndDropAction(null));
      dispatch(setIsDraggedChord(false));
      dispatch(setIdSongStructureWhenReplaceChordInList(null));
    },
    [
      addNewChordToStructure,
      addNewStructureToSong,
      deleteChordFromStructure,
      dispatch,
      replaceChordInTable,
      typeOfDraggedElement,
      idSongStructureWhenReplaceChordInList,
    ],
  );
  return { handleDragEnd };
};
