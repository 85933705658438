import React from "react";
import { useSelector } from "react-redux";
import { getCourses } from "redux/courses/courses_selectors";
import { CourseCheckbox } from "../course_checkbox/course_checkbox";
import { Container, Header, ColumnName, Row, Cell } from "../common/common";

export const InstrumentAccess = () => {
  const courses = useSelector(getCourses);

  return (
    <Container>
      <Header>Instrument Access</Header>
      <ColumnName>Ukulele</ColumnName>
      <ColumnName lastCell>Guitar</ColumnName>

      {courses.map((course) => (
        <React.Fragment key={course.name}>
          <Row>
            {course.name} | &nbsp;<span>{course.users.length} students</span>
          </Row>

          <Cell>
            <CourseCheckbox course={course} target="ukulele_songs" />
          </Cell>
          <Cell lastCell>
            <CourseCheckbox course={course} target="guitar_songs" />
          </Cell>
        </React.Fragment>
      ))}
    </Container>
  );
};
