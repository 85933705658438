import cn from "classnames";
import { ISlide } from "api/lesson_api";
import css from "./slide_types.module.scss";
import { AudioPlayer } from "../../audioplayer/AudioPlayer";

interface IBarreChordIntro {
  slide: ISlide;
}

export const BarreChordIntro = ({ slide }: IBarreChordIntro) => {
  const { text_top, text_bottom } = slide;
  let { assets = [] } = slide;
  assets = Object.values(assets);
  const imgURL = assets[0].url;
  const audioURL = assets[1].url;

  return (
    <div className={css.main}>
      <div className={css.text_top}>{text_top}</div>
      <div className={css.mid}>
        <div className={css.t_imgs}>
          <img className={cn(css.barre_intro_image)} src={imgURL} alt="" />
        </div>
        <div className={css.t_audio}>
          <AudioPlayer src={audioURL} />
        </div>
      </div>
      <div className={css.text_bot}>{text_bottom}</div>
    </div>
  );
};
