import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { login } from "redux/auth/auth_slice";
import { clearErrors, receiveErrors } from "./session_errors_actions";

interface ISessionErrorsState {
  login: null | string[];
  signup: null | { email?: string[]; full_messages?: string[] };
  reqPWReset: null | string;
  resetPW: null | { password?: string; password_confirmation?: boolean };
}

const initialState: ISessionErrorsState = {
  login: null,
  signup: null,
  reqPWReset: null,
  resetPW: null,
};

const sessionErrorsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    let errorSet = false;

    builder.addCase(receiveErrors, (state, action) => {
      const { label, errors } = action.payload;
      if (label === "login" && Array.isArray(errors)) {
        state.login = errors;
        errorSet = true;
      } else if (
        label === "signup" &&
        typeof errors === "object" &&
        !Array.isArray(errors)
      ) {
        state.signup = errors;
        errorSet = true;
      } else if (label === "reqPWReset" && typeof errors === "string") {
        state.reqPWReset = errors;
        errorSet = true;
      } else if (
        label === "resetPW" &&
        typeof errors === "object" &&
        !Array.isArray(errors)
      ) {
        state.resetPW = errors;
        errorSet = true;
      }
    });

    builder.addMatcher(isAnyOf(login, clearErrors), () => {
      if (!errorSet) {
        return initialState;
      }
      errorSet = false;
    });
  },
});

export const { reducer: sessionErrorsReducer } = sessionErrorsSlice;
