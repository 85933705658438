import { Box } from "@material-ui/core";
import { NotesAndIdeas } from "../../songbuilder_sidebar/ideas_bar/idea_bar_notes/idea_bar_notes";

export const SongbuilderMobileNotes = () => {
  return (
    <Box height="80vh">
      <NotesAndIdeas />
    </Box>
  );
};
