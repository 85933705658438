import { Popover } from "@material-ui/core";
import { ILesson } from "api/lesson_api";
import { eventHandler } from "hooks/use_popover";
import { calculateCapo } from "util/calculateCapo";
import css from "./song_sheet_lesson_info_popover.module.scss";

interface ISongSheetLessonInfoPopover {
  mobile?: boolean;
  lesson: ILesson;
  anchorElementSong: HTMLElement | null;
  handleCloseSong: eventHandler;
}
export const SongSheetLessonInfoPopover = ({
  lesson,
  mobile,
  anchorElementSong,
  handleCloseSong,
}: ISongSheetLessonInfoPopover) => {
  const { song_key, tuning } = lesson.song;
  const { lesson_key } = lesson;

  const strummingName = lesson.skills.find(
    (skill) => skill.skill?.name === "Strumming",
  );
  // remove all spaces between words(it's pattern link for Driving Folk)
  const strummingNameWithoutSpace = strummingName?.name.replace(/\s/g, "");
  const urlStrummingPattern = `https://s3-us-west-1.amazonaws.com/moosiko-prod/Assets/_Original/Image+Files/strumming/strumPattern_${strummingNameWithoutSpace?.toLocaleLowerCase()}.png`;
  const capo = calculateCapo(lesson_key, song_key);

  return (
    <Popover
      anchorEl={anchorElementSong}
      keepMounted
      open={Boolean(anchorElementSong)}
      onClose={handleCloseSong}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      disableScrollLock
    >
      <div className={css.song_into_container}>
        <div>Tuning: {tuning}</div>
        <div>Lesson Key: {lesson_key}</div>
        <div>Song Key: {song_key}</div>
        {mobile && <div>{capo}</div>}
        {strummingName && (
          <>
            <div>
              {strummingName?.skill?.name}: {strummingName?.name}
            </div>
            <img
              className={css.strumming_pattern}
              src={urlStrummingPattern}
              alt="info"
            />
          </>
        )}
      </div>
    </Popover>
  );
};
