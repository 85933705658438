import { useSelector } from "react-redux";
import {
  getFreeSongsCountSelector,
  maxFreeLessonsCountFetchingSelector,
} from "redux/free_user_lessons/free_user_lessons_selectors";
import { isAuthorizedSelector } from "redux/auth/user_selectors";
import { isUnlimitedLessonSelector } from "redux/entities/lessons/lesson_selectors";
import { isUnlimitedSongwritingUser } from "redux/songwriting/songwriting_selectors";
import css from "./free_songs_remains.module.scss";

export const FreeSongsRemains = () => {
  const freeSongsRemaining = useSelector(getFreeSongsCountSelector);
  const fetching = useSelector(maxFreeLessonsCountFetchingSelector);
  const accessUnlimitedLessons = useSelector(isUnlimitedLessonSelector);
  const writeUnlimitedSongs = useSelector(isUnlimitedSongwritingUser);
  const isAuthorized = useSelector(isAuthorizedSelector);

  if (
    fetching ||
    accessUnlimitedLessons ||
    writeUnlimitedSongs ||
    !isAuthorized
  ) {
    return null;
  }

  return (
    <div className={css.free_songs_wrapper}>
      {freeSongsRemaining > 0 ? freeSongsRemaining : 0} free songs left
    </div>
  );
};
