import { ModalContainer } from "components/modal/modal_container";
import {
  getActiveOrgSongId,
  getOrgSongwritingSongById,
} from "redux/songwriting/songwriting_selectors";
import { useSelector } from "react-redux";
import { ViewSongSettings } from "./view_song_settings";
import { ViewSongSection } from "./view_song_section";

import css from "./view_song_modal.module.scss";

export const ViewSongModal = () => {
  const id = useSelector(getActiveOrgSongId);
  const orgSong = useSelector(getOrgSongwritingSongById(id));

  return (
    <ModalContainer classes={{ main: css.modal }} closeButtonLight>
      <ViewSongSettings
        songKey={orgSong?.custom_song_key}
        songMeter={orgSong?.time_signature}
        songTempo={orgSong?.tempo}
      />
      <div className={css.song_container}>
        <div className={css.title_container}>
          <div className={css.song_title}>{orgSong?.song_name}</div>
        </div>
        <div className={css.body_container}>
          {orgSong?.custom_song_lyrics.map((songSection, key) => {
            const sectionKey = `section${key}`;
            return (
              <ViewSongSection
                songSection={songSection}
                chordIconStyle={orgSong.chord_icon_style}
                key={sectionKey}
              />
            );
          })}
        </div>
      </div>
    </ModalContainer>
  );
};
