import React from "react";
import { TableDataProps } from "redux/songwriting/songwriting_interfaces";
import { useSelector } from "react-redux";
import {
  getIsDraggedChord,
  getIdSongStructureWhenReplaceChordInList,
} from "redux/songwriting/songwriting_selectors";
import {
  useTableData,
  useEditableCellsRefs,
  useChangeFocusInput,
  useRowsActions,
  useKeyboardHandlers,
  useSongUnitTableContent,
  usePasteHandler,
} from "hooks";

import { EditableRowSongLyrics } from "../editable_row_song_lyrics/editable_row_song_lyrics";
import { DroppableRowSongLyrics } from "../droppable_row_song_lyrics/droppable_row_song_lyrics";

interface ISongLyricsTableBody {
  tableData: TableDataProps;
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SongLyricsTableBody = ({
  tableData,
  setChordAnchorEl,
}: ISongLyricsTableBody) => {
  const { getTableBodyProps, prepareRow, rows } = useTableData({
    data: tableData.data,
  });

  const { title, id, songChords } = tableData;
  const chordsData = songChords && JSON.parse(songChords);
  const isDraggedChord = useSelector(getIsDraggedChord);

  const { editableCellRefs } = useEditableCellsRefs({ rows });

  const { createNewRow, deleteRow, pasteRows, syncTableData } = useRowsActions({
    songUnitId: id,
  });

  const { setIndexOfInputToFocus, setPositionOfValueToFocus } =
    useChangeFocusInput({
      editableCellRefs,
      songUnitId: id,
    });

  const { updateRowValue } = useSongUnitTableContent({ id });

  const { handleKeyDown } = useKeyboardHandlers({
    editableCellRefs,
    setIndexOfInputToFocus,
    setPositionOfValueToFocus,
    createNewRow,
    deleteRow,
    updateRowValue,
    syncTableData,
  });

  const handlePaste = usePasteHandler({
    editableCellRefs,
    pasteRows,
  });

  const replacedInternalChordId = useSelector(
    getIdSongStructureWhenReplaceChordInList,
  );

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        const isDroppableRow = index % 2 === 0;
        const activeBorder =
          isDroppableRow && (isDraggedChord || id === replacedInternalChordId);

        return (
          <tr
            style={{
              position: `${index % 2 ? "initial" : "relative"}`,
              border: `${activeBorder ? "1px solid #4c9ffe" : "none"}`,
            }}
            {...row.getRowProps()}
            key={`i-${row.id}`}
          >
            {index % 2 === 0 ? (
              <DroppableRowSongLyrics
                row={row}
                chordsData={chordsData}
                songUnitId={id}
                setChordAnchorEl={setChordAnchorEl}
              />
            ) : (
              <EditableRowSongLyrics
                row={row}
                editableCellRefs={editableCellRefs}
                handleKeyDown={handleKeyDown}
                index={index}
                title={title}
                songUnitId={id}
                handlePaste={handlePaste}
              />
            )}
          </tr>
        );
      })}
    </tbody>
  );
};
