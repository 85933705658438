import { createSelector } from "reselect";
import { IState } from "redux/store";
import {
  getUserChordSkillsSelector,
  getUserTransitionsLikeStringSelector,
  getUserProgressionsLikeStringSelector,
  getUserStrummingSongsSelector,
  getUserTransitionsSelector,
  getUserProgressionsSelector,
  getUserMelodiesSongsSelector,
} from "redux/entities/user_skills/user_skills_selector";
import { getChordsLikeStringSelector } from "redux/entities/chords/chords_selectors";
import { getTransitionsLikeStringSelector } from "redux/entities/transitions/transitions_selectors";
import { getProgressionsLikeStringSelector } from "redux/entities/progressions/progressions_selectors";

/**
 * returns all skills and user skills
 */
export const getSkillsAndUserSkillsSelector = createSelector(
  [
    getUserChordSkillsSelector,
    getUserTransitionsLikeStringSelector,
    getUserProgressionsLikeStringSelector,
    getChordsLikeStringSelector,
    getTransitionsLikeStringSelector,
    getProgressionsLikeStringSelector,
  ],
  (
    userChords,
    userTransitions,
    userProgressions,
    chords,
    transitions,
    progressions,
  ) => ({
    userChords,
    userTransitions,
    userProgressions,
    chords,
    transitions,
    progressions,
  }),
);

export const getUserSkillsCount = (state: IState) => {
  return (
    getUserChordSkillsSelector(state).length +
    (getUserStrummingSongsSelector(state)?.count || 0) +
    getUserProgressionsSelector(state).length +
    getUserTransitionsSelector(state).length +
    getUserMelodiesSongsSelector(state).length
  );
};
