import "./resetSlickSlide.scss";

import classnames from "classnames";
import { CompletionReminderModal } from "components/modal/completion_reminder_modal";
import RouteLeavingGuard from "components/reusable/route_leaving_guard/route_leaving_guard";
import { AudioPlayerContext } from "context/audio_player_context";
import { Location } from "history";
import { ceil } from "lodash";
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import Slider from "react-slick";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { getCarouselTeachPluckModalOpen } from "redux/carousel/carousel_selectors";
import { getLessonsCacheSelector } from "redux/entities/lessons/lesson_selectors";
import {
  getSlideNumber,
  getSlides,
  getSongId,
} from "redux/entities/slides/slides_selectors";
import {
  fetchSlides,
  setCurrentLessonSlide,
} from "redux/entities/slides/slides_slice";
import {
  getUserLessonsArraySelector,
  isLessonFinished,
} from "redux/entities/user_lessons/user_lesson_selectors";
import { finishLesson } from "redux/entities/user_skills/user_skills_slice";
import { getOrganizationRecordToggle } from "redux/organizations/organization_selectors";
import { LESSON } from "routes/route_constants";
import { AppDispatch } from "types/redux_";

import { LinearProgress } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import { getAuthSelector } from "redux/auth/user_selectors";
import { ROLES } from "redux/auth/roles/roles_consts";
import { Loader } from "../loading/loader";
import Spinner from "../loading/spinner";
import { SkillRecorder } from "./media-recorder/skill_recorder";
import { SlideBottomPopover } from "./slide_bottom_popover";
import { SlideTopPopover } from "./slide_top_popover/slide_top_popover";
import { slideTypes } from "./slideTypes";
import css from "./slide.module.scss";

const slidesToIgnore = ["part_begin", "break", "part_end"];

const LESSON_ALMOST_FINISHED = 99;

export const Slides = () => {
  const accessVideoRecording = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_VIDEO_RECORDING),
  );
  const computedMatch = useRouteMatch<{ id: string }>(LESSON);
  const { id: lessonIdString } = computedMatch?.params || { id: "" };
  const lessonId = Number(lessonIdString);
  const lesson = useSelector(getLessonsCacheSelector)[lessonId];
  const { instruments } = useSelector(getSongFilters);

  const userLessons = useSelector(getUserLessonsArraySelector);
  const userLesson = userLessons.find(
    (userLesson) => userLesson.lesson_id === lesson.id,
  );
  const finished = !!userLesson && isLessonFinished(userLesson);
  const user = useSelector(getAuthSelector);

  const slidesObj = useSelector(getSlides);
  const slides = Object.values(slidesObj);
  const { audioRef } = useContext(AudioPlayerContext).audioPlayerState;
  const { resetButtons } = useContext(AudioPlayerContext).audioPlayerState;
  const { progressFilledRef } = useContext(AudioPlayerContext).audioPlayerState;

  const lastLessonId = useSelector(getSongId);
  const lastSlideNumber = useSelector(getSlideNumber);

  const [slidesFetched, setSliderFetched] = useState(false);
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  const sliderRef = createRef<Slider>();

  const history = useHistory();

  const [initialSlide, setInitialSlide] = useState(0);
  const [isProgressSlideSet, isProgressSlide] = useState(false);
  const progress = lastSlideNumber
    ? ceil((lastSlideNumber / (slides.length - 1)) * LESSON_ALMOST_FINISHED, 2)
    : undefined;

  const isReminderModalOpen = useCallback(
    (_location?: Location<unknown>) => {
      // Location prop is needed in case we want to check for certain pathname

      let when = false;
      if (progress === LESSON_ALMOST_FINISHED && !finished) {
        const dismissedLessonNotifications = localStorage.getItem(
          "completionReminderDismissed",
        );
        if (!dismissedLessonNotifications?.includes(lessonId.toString())) {
          when = true;
        }
      }
      return when;
    },
    [finished, lessonId, progress],
  );

  useEffect(() => {
    if (lastLessonId === lessonId && progress) {
      dispatch(
        finishLesson({ lessonId, progress, instrument: instruments[0] }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    if (userLesson?.progress && !isProgressSlideSet && sliderRef.current) {
      const SlideNumber =
        Math.round((slides.length * (userLesson?.progress || 0)) / 100) - 1;
      setCurrentSlideNumber(SlideNumber);
      sliderRef.current?.slickGoTo(SlideNumber);
      dispatch(
        setCurrentLessonSlide({
          lessonId: Number(lessonId),
          slideNumber: SlideNumber,
        }),
      );
      isProgressSlide(true);
    }
  }, [
    lessonId,
    dispatch,
    slides.length,
    userLesson?.progress,
    sliderRef,
    isProgressSlideSet,
  ]);

  useEffect(() => {
    if (lastLessonId === lessonId && lastSlideNumber) {
      setInitialSlide(lastSlideNumber);
      setCurrentSlideNumber(lastSlideNumber);
    } else {
      dispatch(
        setCurrentLessonSlide({
          lessonId: Number(lessonId),
          slideNumber: initialSlide,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordToggle = useSelector(getOrganizationRecordToggle);

  const settings = {
    beforeChange: (_current: number, next: number) => {
      if (audioRef && audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        if (progressFilledRef.current) {
          progressFilledRef.current.style.flexBasis = "0";
        }
        resetButtons();
      }
      if (next >= 0 && next < slides.length) {
        dispatch(setCurrentLessonSlide({ lessonId, slideNumber: next }));
      }
    },
    afterChange: (index: number) => {
      setCurrentSlideNumber(index);
    },
    initialSlide,
    accessibility: false,
    infinite: false,
    arrows: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchSlides(lessonId)).then(() => {
      setSliderFetched(true);
    });
  }, [dispatch, lessonId]);

  const handlePreviousClick = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, [sliderRef]);

  const handleNextClick = useCallback(() => {
    sliderRef.current?.slickNext();
  }, [sliderRef]);

  useEffect(() => {
    const handleArrowKeys = (e: KeyboardEvent) => {
      const LEFT = 37;
      const RIGHT = 39;
      const code = Number(e.which || e.keyCode);
      if (code === LEFT) {
        handlePreviousClick();
      } else if (code === RIGHT) {
        handleNextClick();
      }
    };
    document.addEventListener("keyup", handleArrowKeys);
    return () => document.removeEventListener("keyup", handleArrowKeys);
  }, [handleNextClick, handlePreviousClick]);

  const [bottomPopover, setBottomPopover] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleBottomButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (bottomPopover) {
      setBottomPopover(null);
    } else {
      setBottomPopover(event.currentTarget);
    }
  };

  const [topPopover, setTopPopover] = useState<HTMLButtonElement | null>(null);

  const handleTopButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (bottomPopover) {
      setTopPopover(null);
    } else {
      setTopPopover(event.currentTarget);
    }
  };

  const isCarouselTeachPluckModalOpen = useSelector(
    getCarouselTeachPluckModalOpen,
  );

  if (!slidesFetched) {
    return <Loader spinner={Spinner} />;
  }
  if (slides.length === 0) {
    return null;
  }

  const lessonPercentMultiplier = finished ? 100 : LESSON_ALMOST_FINISHED;

  const lessonProgressPercentage = Math.floor(
    (currentSlideNumber * lessonPercentMultiplier) / (slides.length - 1),
  );

  const isTeacher =
    user.role === ROLES.FREE_TEACHER_ACCOUNT ||
    user.role === ROLES.PAID_TEACHER_ACCOUNT;

  const shallShowRecord = !!(
    !isTeacher &&
    accessVideoRecording &&
    recordToggle &&
    lastSlideNumber &&
    !slidesToIgnore.includes(slidesObj[lastSlideNumber + 1].slide_type)
  );

  return (
    <div className={css.lesson}>
      <div
        className={classnames(css.header, {
          [css.display_none]: isCarouselTeachPluckModalOpen,
        })}
      >
        <div className={css.name}>
          {lesson.song.song_name}
          <Link to={`/lesson/${lesson.id}/song-sheet`} className={css.link}>
            (Song Sheet)
          </Link>
        </div>
        <div className={css.progress}>
          <LinearProgress
            classes={{
              barColorPrimary: css.barColorPrimary,
              colorPrimary: css.colorPrimary,
            }}
            className={css.linear_progress}
            value={lessonProgressPercentage}
            variant="determinate"
          />
          <div className={css.progress_value}>{lessonProgressPercentage}%</div>
          <button
            onClick={handleTopButtonClick}
            className={css.select_slide_button}
            type="button"
          >
            {topPopover ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </button>

          <SlideTopPopover
            selectAnchor={topPopover}
            handleCloseSelect={() => setTopPopover(null)}
            slides={slides}
            currentSlide={lastSlideNumber}
            goToSlide={(number) => sliderRef.current?.slickGoTo(number)}
          />
        </div>
      </div>
      {shallShowRecord && (
        <SkillRecorder
          skill={slides[currentSlideNumber].skill}
          slide={slides[currentSlideNumber]}
        />
      )}
      <div className={css.slider}>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => {
            const Slide = slideTypes[slide.slide_type];
            const isLast = slides.length - 1 === index;

            return (
              <div className={css.slide_wrapper} key={slide.id}>
                <Slide
                  slide={slide}
                  isSlideActive={currentSlideNumber === index}
                  isLast={isLast}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <footer className={css.footer}>
        <button
          onClick={handlePreviousClick}
          className={classnames(css.change_slide_button, {
            [css.visibility_hidden]: currentSlideNumber === 0 && false,
          })}
          type="button"
        >
          <ArrowBackIcon />
          <div className={css.button_text}>previous</div>
        </button>

        <button
          onClick={handleBottomButtonClick}
          className={css.select_slide_button}
          type="button"
        >
          <div>
            {(lastSlideNumber || 0) + 1} of&nbsp;
            {slides.length}
          </div>
          {bottomPopover ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          <SlideBottomPopover
            selectAnchor={bottomPopover}
            handleCloseSelect={() => setBottomPopover(null)}
            slides={slides}
            currentSlide={currentSlideNumber}
            goToSlide={(number) => sliderRef.current?.slickGoTo(number)}
          />
        </button>

        <button
          onClick={handleNextClick}
          className={classnames(
            css.change_slide_button,
            css.change_slide_button__next,
            {
              [css.visibility_hidden]: currentSlideNumber === slides.length - 1,
            },
          )}
          type="button"
        >
          <div className={css.button_text}>next</div>
          <ArrowForwardIcon />
        </button>
      </footer>
      <RouteLeavingGuard
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={isReminderModalOpen}
        dialogWindow={
          <CompletionReminderModal
            lessonId={lessonId}
            songName={lesson.song.song_name}
          />
        }
      />
    </div>
  );
};
