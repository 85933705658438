import { createSelector } from "reselect";
import { IState } from "redux/store";
import { IOrganization } from "./organization_api";

const getOrganizationFromState = (state: IState) => state.entities.organization;

export const getOrganizationsInfo = (state: IState) =>
  state.entities.organization.organizations;

export const getHiddenSongsSelector = (state: IState) =>
  state.entities.organization.hiddenSongs;

export const getSearchSongsToHide = (state: IState) =>
  state.entities.organization.searchSongsToHide;

export const getFirstOrganization = createSelector(
  getOrganizationsInfo,
  (organizations): IOrganization | null => organizations[0] || null,
);

export const getOrganizationId = createSelector(
  [getFirstOrganization],
  (org) => org?.id,
);

export const getOrganizationName = createSelector(
  [getFirstOrganization],
  (org) => org?.name,
);

export const getOrganizationLogoURL = createSelector(
  [getFirstOrganization],
  (org) => org?.logo_url,
);

export const getOrganizationSearchStudents = createSelector(
  [getOrganizationFromState],
  (organization) => organization.searchStudents,
);

export const getOrganizationPasswordToggle = createSelector(
  [getFirstOrganization],
  (organization) => !!organization?.password_toggle,
);

export const getOrganizationRecordToggle = createSelector(
  [getFirstOrganization],
  (organization) => !!organization?.recordings,
);

export const getOrganizationPassword = createSelector(
  [getFirstOrganization, getOrganizationPasswordToggle],
  (organization, passwordActive) => {
    return passwordActive ? organization?.password : null;
  },
);

export const getStudentsCount = createSelector(
  [getFirstOrganization],
  (organization) => !!organization?.studentsCount,
);
export const getStudentsNumber = createSelector(
  [getFirstOrganization],
  (organization) => organization?.studentsCount,
);

export const getOrganizationMaxSeats = createSelector(
  [getFirstOrganization],
  (organization) => organization?.max_seats,
);
