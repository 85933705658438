import { Switch } from "@material-ui/core";
import css from "./profile_instructor_switch.module.scss";

interface IProfileInstructorSwitch {
  handleToggleChange: () => void;
  toggleValue: boolean;
  text: string;
}

export const ProfileInstructorSwitch = ({
  handleToggleChange,
  toggleValue,
  text,
}: IProfileInstructorSwitch) => {
  return (
    <div>
      <h3>{text}</h3>
      <Switch
        name="checkedA"
        onChange={handleToggleChange}
        checked={toggleValue}
        classes={
          toggleValue
            ? {
                track: css.background_color,
                input: css.background_color,
                checked: css.color,
              }
            : undefined
        }
      />
    </div>
  );
};
