import { ILesson } from "api/lesson_api";
import { useState, useCallback, useEffect } from "react";

import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_WIDTH = 900;
function getWidth() {
  return Math.min(document.body.clientWidth, MAX_WIDTH);
}

export const SongSheet = (props: { lesson: ILesson }) => {
  const { lesson } = props;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [width, setWidth] = useState(getWidth());

  const [pagesLoaded, setPagesLoaded] = useState([false]);

  const handlePageLoaded = (pageNumber: number) => {
    const newArray = [...pagesLoaded];
    newArray[pageNumber] = true;
    setPagesLoaded(newArray);
  };

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      setPagesLoaded(new Array(numPages).fill(false));
    },
    [],
  );

  /**
   * Handle resizing
   */
  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;
    const TIMEOUT = 100;
    const resize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setWidth(getWidth());
      }, TIMEOUT);
    };
    window.addEventListener("resize", resize);
    window.addEventListener("orientationchange", resize);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("orientationchange", resize);
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
    };
  }, []);

  return (
    <Document file={lesson.song_sheet} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from({ length: numPages || 0 }, (_, i) => (
        <Page
          onLoadSuccess={() => handlePageLoaded(i)}
          renderMode="svg"
          width={width}
          key={i}
          pageNumber={i + 1}
        />
      ))}
    </Document>
  );
};
