import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import css from "./switch.module.scss";

interface ISwitch {
  isSwitched: boolean;
  setSwitch: () => void;
}

export const Switch = (props: ISwitch) => {
  const { isSwitched, setSwitch } = props;

  const switchClass = `${css.switch_elem} ${
    isSwitched ? "" : css.switch_elem_active
  }`;

  const iconClass = `${css.icon} + ${isSwitched ? "" : css.icon_active}`;
  const icon = isSwitched ? "check" : "times";

  return (
    <button type="button" className={switchClass} onClick={setSwitch}>
      <FontAwesomeIcon
        size="lg"
        title="Loop"
        className={iconClass}
        icon={icon}
      />
    </button>
  );
};
