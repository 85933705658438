import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthLinks } from "components/nav/shared/auth_links";
import DProtectedLinks from "components/nav/desktop/menu";
import { getUserSelector } from "redux/auth/user_selectors";

export { MenuLinks as MNavRoute } from "components/nav/shared/menu_links";

// custom route for nav bar (display menu or login/signup button)
export const DNavRoute = (props: { css?: { [key: string]: string } }) => {
  const loggedIn = useSelector(getUserSelector);
  return (
    <Route
      render={() =>
        !loggedIn ? <AuthLinks {...props} /> : <DProtectedLinks />
      }
    />
  );
};
