export const TEXT_25 = [
  "Basic chords(majors, minors, 7ths)",
  "Basic strumming in time",
  "Simple melodies",
  "Playing basic songs",
];

export const TEXT_50 = [
  "Barre chords",
  "Playing many songs",
  "Flat picking & riffs",
  "Hammer ons, pull offs, slides",
  "Playing with others",
];

export const TEXT_75 = [
  "Advanced chords (sus, dim, 6th, 9th)",
  "Finger picking",
  "Soloing",
  "Palm muting",
];

export const TEXT_100 = [
  "Improvisation",
  "Advanced soloing",
  "Versatile in different genres",
];

export const TEXT_QUESTION = `Estimated progress based on your skills. At the moment, Moosiko has a ceiling of ~25%
  This ceiling will rise as we add more skills so you can help progressing in your guitar journey.
`;
