import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { progressionsApi } from "api/progressions_api";
import { ISkill } from "types/models/skill";

const initialState: IProgressionsState = {
  fetching: true,
  guitar: [],
  ukulele: [],
};

interface IProgressionsState {
  fetching: boolean;
  guitar: ISkill[];
  ukulele: ISkill[];
}

export const fetchProgressions = createAsyncThunk(
  "progressions/fetchProgressions",
  async () => {
    const { data: progressions } = await progressionsApi.fetchProgressions();
    return progressions;
  },
);

const progressionsSlice = createSlice({
  name: "progressions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProgressions.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchProgressions.fulfilled, (state, action) => {
      state.guitar = action.payload.filter(
        (transition) => transition.skill?.skill?.name === "Guitar",
      );
      state.ukulele = action.payload.filter(
        (transition) => transition.skill?.skill?.name === "Ukulele",
      );
      state.fetching = false;
    });
    builder.addCase(fetchProgressions.rejected, (state, action) => {
      state.fetching = false;
      console.error("fetch progressions error", action.error);
    });
  },
});

export const { reducer: progressionsReducer } = progressionsSlice;
