import styled from "@emotion/styled";

export const GetQuoteFormWrapper = styled.div`
  width: 800px;
`;
export const GetQuoteStepWrapper = styled.div`
  padding: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
