import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentSeed,
  getSeedText,
} from "redux/song_seeds/song_seed_selector";
import { updateSongSeed } from "redux/song_seeds/song_seeds_slice";

const EditableSeedNote = () => {
  const [seedText, updateSeedText] = useState<string | undefined>();
  const dispatch = useDispatch();
  const currentSeed = useSelector(getCurrentSeed);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const currentSeedText = useSelector(getSeedText(currentSeed));

  useEffect(() => {
    updateSeedText(currentSeedText);
  }, [currentSeedText]);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value !== currentSeedText) {
      dispatch(updateSongSeed({ text: e.target.value }));
    }
  };

  const handleChange = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    updateSeedText(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box height="80vh">
      <textarea
        style={{ height: "100%", width: "100%" }}
        onBlur={handleBlur}
        value={seedText || ""}
        onChange={handleChange}
        ref={inputRef}
      />
    </Box>
  );
};

export default EditableSeedNote;
