import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";
import { CSSProperties } from "react";

interface ICloseButton {
  root?: CSSProperties;
  onClick?: () => void;
}
export const CloseButton = ({ root, onClick }: ICloseButton) => {
  const StyledButton = withStyles({
    // @ts-ignore
    root: { ...root, color: "#190067", fontSize: "2.5em" },
    label: {
      textTransform: "capitalize",
    },
  })(Button);

  const StyledCloseIcon = withStyles({
    root: { fontSize: "0.9em" },
  })(CloseIcon);

  return (
    <StyledButton onClick={onClick}>
      <StyledCloseIcon />
    </StyledButton>
  );
};
