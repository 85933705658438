import css from "./slide_types.module.scss";

const Generic = () => (
  <div className={css.main}>
    <h1>Progress Bar</h1>
    <h2>Header Text</h2>
    <h2>Text Normal</h2>
    <h2>Image, Audio, Video</h2>
  </div>
);

export default Generic;
