import { View, Text, Image } from "@react-pdf/renderer";
import { protectionBadge } from "../songbuilder_pdf/songbuilder_pdf_styles";
import protectedIcon from "./protected_pdf_icon";
import unProtectedIcon from "./unprotected_pdf_icon";

interface IProtectionBadge {
  protectedStatus: boolean;
}

export const ProtectionBadgePdf = ({ protectedStatus }: IProtectionBadge) => {
  return (
    <View style={protectionBadge.container}>
      <Image
        style={protectionBadge.image}
        src={protectedStatus ? protectedIcon : unProtectedIcon}
      />
      <Text style={protectionBadge.text}>
        This songs is {protectedStatus ? "Protected" : "Unprotected"}
      </Text>
    </View>
  );
};
