import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ILesson } from "api/lesson_api";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { closeModal } from "redux/ui/modal/modal_slice";
import css from "./my_journey_song_card.module.scss";

interface ISongCard {
  lesson: ILesson;
}

export const SongCard = (props: ISongCard) => {
  const dispatch = useDispatch();

  const { lesson } = props;
  const { id } = lesson;
  const song_name = lesson.song?.song_name;
  const artist = lesson.song?.artist;
  return (
    <Link
      to={`/lesson/${id}/song-sheet`}
      onClick={(e) => dispatch(closeModal())}
      className={css.link}
    >
      <div className={css.wrapper}>
        <div className={css.song_info}>
          <div className={css.name}>
            {song_name || props.lesson.song.song_name}
          </div>
          <div className={css.artist}>{artist}</div>
        </div>
        <div className={css.song_sheet_wrapper}>
          <FontAwesomeIcon
            className={css.song_sheet_icon}
            icon="chevron-right"
          />
          <div className={css.song_sheet}>Song Sheet</div>
        </div>
      </div>
    </Link>
  );
};
