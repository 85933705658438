import { Typography } from "@material-ui/core";

interface ILastUpdated {
  lastUpdated?: string;
}

const LastUpdated = ({ lastUpdated }: ILastUpdated) => {
  return (
    <Typography
      style={{
        fontStyle: "italic",
        fontWeight: "400",
        fontSize: 14,
        color: "#939191",
        fontFamily: "Raleway",
      }}
    >
      {lastUpdated}
    </Typography>
  );
};

export default LastUpdated;
