import axios from "axios";
import { StripeCustomer } from "redux/auth/auth_slice";
import Stripe from "stripe";
import { getNodeApiBaseUrl } from "./base_api";

const url = `${getNodeApiBaseUrl()}/stripe`;

export interface IStipeFetchResult {
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: number;
  status: "string";
  plan: Stripe.Plan;
}

export interface IStripeParams {
  amount: string;
  description: string;
  lessonId: number;
  useSavedCard: boolean;
  stripeToken: string;
  saveCard: boolean;
}

export const stripeApi = {
  async sendCheckoutEmail(
    student_first_name: string,
    student_last_name: string,
    parent_first_name: string,
    email: string,
    skills_count: number,
    songs_count: number,
    hours: number,
    minutes: number,
  ) {
    try {
      await axios.post("api/stripe/checkout-email", {
        email,
        student_first_name,
        student_last_name,
        parent_first_name,
        skills_count,
        songs_count,
        hours,
        minutes,
      });
    } catch (err) {
      console.error(err);
    }
  },
  async createCustomer(
    studentEmail: string,
    parentEmail: string,
    nameOnCard: string,
  ) {
    const {
      data: { customer },
    } = await axios.post(`${url}/create-customer`, {
      email: studentEmail,
      parentEmail,
      parentName: nameOnCard,
    });
    return customer;
  },

  async getCustomerData(email: string) {
    const {
      data: { result },
    } = await axios.post(`${url}/get-customer-data`, {
      email,
    });

    return result;
  },

  async createSubscription({
    priceId,
    studentEmail,
    quantity,
  }: {
    priceId: string;
    studentEmail: string;
    quantity: number;
  }) {
    const {
      data: { subscriptionId, clientSecret, subscription },
    } = await axios.post(`${url}/create-subscription`, {
      priceId,
      email: studentEmail,
      quantity,
    });

    return { subscriptionId, clientSecret, subscription };
  },

  async createInvoice({
    priceId,
    studentEmail,
    quantity,
    orgId,
  }: {
    priceId: string;
    studentEmail: string;
    quantity: number;
    orgId?: number;
  }) {
    const { data } = await axios.post(`${url}/create-invoice`, {
      priceId,
      email: studentEmail,
      quantity,
      orgId,
    });

    return data;
  },

  async fetchCustomer() {
    const response = await axios.get<{
      customer: StripeCustomer;
      stripe: IStipeFetchResult;
    }>("/api/stripe");
    return response;
  },

  createCharge(params: IStripeParams) {
    const { amount, description, lessonId, useSavedCard } = params;
    // if user wants to use their saved card
    if (useSavedCard) {
      return axios.post("/api/stripe", {
        amount,
        description,
        lesson_id: lessonId,
        use_saved_card: useSavedCard,
      });
    }
    const { stripeToken, saveCard } = params;
    return axios.post("/api/stripe", {
      stripe_token: stripeToken,
      amount,
      description,
      lesson_id: lessonId,
      save_card: saveCard,
    });
  },

  /**
   * @param {object} [params] - If user is already a customer - token isn't required
   * @param {string} [params.stripeToken] - If user is already a customer - token isn't required
   */
  subscribe(productId: string, options: { stripeToken?: string } = {}) {
    const { stripeToken: stripe_token } = options;
    return axios.post(`/api/stripe/subscribe?plan=${productId}`, {
      stripe_token,
    });
  },

  unsubscribe() {
    return axios.post("/api/stripe/unsubscribe");
  },
};
