import axios from "axios";
import { getNodeApiBaseUrl } from "./base_api";

export const promoApi = {
  fetchRole(promoCode: string | (string | null)[] | null) {
    const url = `${getNodeApiBaseUrl()}/promo/role`;
    return axios.get(url, { params: { promo_code: promoCode } });
  },

  // @ts-ignore
  uberDuckTest(argument) {
    const duckUrl = `${getNodeApiBaseUrl()}/uber-duck/speak`;
    return axios.post(duckUrl, { params: { body: argument } });
  },

  // @ts-ignore
  uberDuckResult(argument) {
    const duckUrl = `${getNodeApiBaseUrl()}/uber-duck/speak-status`;
    return axios.get(duckUrl, { params: { body: argument } });
  },
};
