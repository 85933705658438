import styled from "@emotion/styled";

export const InputContainer = styled.div`
  max-width: 500px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-bottom: 8em;
  margin-top: 20px;
  > form {
    flex-grow: 1;
  }
`;

export const Footer = styled.div`
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

export const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1.3em 0em 1em;
`;

export const Img = styled.img`
  object-fit: none;
`;
