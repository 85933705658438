import { ISlide } from "api/lesson_api";
import { createSelector } from "reselect";
import { IState } from "redux/store";

const getMediaRecordings = (state: IState) => {
  return state.mediaRecordings;
};

export const getMediaRecordingsRecords = createSelector(
  getMediaRecordings,
  (mediaRecordings) => mediaRecordings.records,
);

export const getMediaRecordingState = createSelector(
  getMediaRecordings,
  (mediaRecordings) => mediaRecordings.status,
);

export const getNewMediaRecordingsCount = createSelector(
  getMediaRecordingsRecords,
  (mediaRecordings) =>
    mediaRecordings.filter((record) => !record.viewed).length,
);

export const getUserMediaRecordings = createSelector(
  getMediaRecordings,
  (mediaRecords) => mediaRecords.userRecords,
);

export const getUserMediaRecordingForSlide = (slide: ISlide | undefined) =>
  createSelector(getUserMediaRecordings, (mediaRecordings) => {
    if (!slide) {
      return [];
    }
    // const baseFilterFunction = mediaRecord =>
    //   mediaRecord.skill?.id === slide.skill?.id;
    // const filterFunction = baseFilterFunction;
    // if (slide.slideType === "teach_pluck") {
    //   filterFunction = mediaRecord =>
    //     baseFilterFunction(mediaRecord) &&
    //     mediaRecord.slide_top_text === slide.textTop;
    // }
    // if (slide.slideType === "teach") {
    //   filterFunction = mediaRecord =>
    //     baseFilterFunction(mediaRecord) &&
    //     mediaRecord.slide_top_text === slide.textTop;
    // }
    // Get first 6 filtered recordings
    return (
      mediaRecordings
        // .filter(filterFunction)
        // eslint-disable-next-line no-magic-numbers
        .slice(0, 6)
    );
  });
