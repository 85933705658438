import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const stretch = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.45);
  }

  20% {
    transform: scaleY(1);
  }
`;

const Container = styled.div`
  height: 80px;
  text-align: center;
`;

const Rect = styled.div<{ delay: number }>`
  background-color: #8564f1;
  height: 100%;
  width: 6px;
  margin: 0px 2px;
  display: inline-block;

  animation: ${stretch} 1s infinite ease-in-out;
  animation-delay: ${(props) => props.delay || 0}s;
`;

// eslint-disable-next-line no-magic-numbers
const delays = [0, -0.9, -0.85, -0.8, -0.75, -0.7];

interface ILoader {
  isLoading: boolean;
  className: string;
}

export const Loader = (props: ILoader) => {
  const { isLoading = true, className = undefined } = props;

  return isLoading ? (
    <Container className={className}>
      {delays.map((delay) => (
        <Rect key={delay} delay={delay} />
      ))}
    </Container>
  ) : null;
};
