const BASE_URL = "https://moosiko-prod.s3.us-west-1.amazonaws.com/Images";

const A7_FRONT_CORRECT = `${BASE_URL}/ukulele/A7-front-corr_uke.png`;
const A7_FRONT_INCORRECT = `${BASE_URL}/ukulele/A7-front-incorr_uke.png`;
const A7_TOP_CORRECT = `${BASE_URL}/ukulele/A7-top-corr_uke.png`;
const A7_TOP_INCORRECT = `${BASE_URL}/ukulele/A7-top-incorr_uke.png`;
const A7_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/A7 - Correct_uke.svg`;
const A7_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/A7 - Incorrect_uke.svg`;
const A7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/A7 - Top Level_uke.svg`;

const A_FRONT_CORRECT = `${BASE_URL}/ukulele/A-front-corr_uke.png`;
const A_FRONT_INCORRECT = `${BASE_URL}/ukulele/A-front-incorr_uke.png`;
const A_TOP_CORRECT = `${BASE_URL}/ukulele/A-top-corr_uke.png`;
const A_TOP_INCORRECT = `${BASE_URL}/ukulele/A-top-incorr_uke.png`;
const A_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/A - Correct_uke.svg`;
const A_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/A - Incorrect_uke.svg`;
const A_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/A - Top Level_uke.svg`;

const Am_FRONT_CORRECT = `${BASE_URL}/ukulele/Am-front-corr_uke.png`;
const Am_FRONT_INCORRECT = `${BASE_URL}/ukulele/Am-front-incorr_uke.png`;
const Am_TOP_CORRECT = `${BASE_URL}/ukulele/Am-top-corr_uke.png`;
const Am_TOP_INCORRECT = `${BASE_URL}/ukulele/Am-top-incorr_uke.png`;
const Am_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Am - Correct_uke.svg`;
const Am_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Am - Incorrect_uke.svg`;
const Am_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Am - Top Level_uke.svg`;

const Asus_FRONT_CORRECT = `${BASE_URL}/ukulele/Asus-front-corr_uke.png`;
const Asus_FRONT_INCORRECT = `${BASE_URL}/ukulele/Asus-front-incorr_uke.png`;
const Asus_TOP_CORRECT = `${BASE_URL}/ukulele/Asus-top-corr_uke.png`;
const Asus_TOP_INCORRECT = `${BASE_URL}/ukulele/Asus-top-incorr_uke.png`;
const Asus_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Asus - Correct_uke.svg`;
const Asus_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Asus - Incorrect_uke.svg`;
const Asus_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Asus - Top Level_uke.svg`;

const C_FRONT_CORRECT = `${BASE_URL}/ukulele/C-front-corr_uke.png`;
const C_FRONT_INCORRECT = `${BASE_URL}/ukulele/C-front-incorr_uke.png`;
const C_TOP_CORRECT = `${BASE_URL}/ukulele/C-top-corr_uke.png`;
const C_TOP_INCORRECT = `${BASE_URL}/ukulele/C-top-incorr_uke.png`;
const C_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/C - Correct_uke.svg`;
const C_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/C - Incorrect_uke.svg`;
const C_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/C - Top Level_uke.svg`;

const D7_FRONT_CORRECT = `${BASE_URL}/ukulele/D7-front-corr_uke.png`;
const D7_FRONT_INCORRECT = `${BASE_URL}/ukulele/D7-front-incorr_uke.png`;
const D7_TOP_CORRECT = `${BASE_URL}/ukulele/D7-top-corr_uke.png`;
const D7_TOP_INCORRECT = `${BASE_URL}/ukulele/D7-top-incorr_uke.png`;
const D7_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/D7 - Correct_uke.svg`;
const D7_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/D7 - Incorrect_uke.svg`;
const D7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/D7 - Top Level_uke.svg`;

const D_FRONT_CORRECT = `${BASE_URL}/ukulele/D-front-corr_uke.png`;
const D_FRONT_INCORRECT = `${BASE_URL}/ukulele/D-front-incorr_uke.png`;
const D_TOP_CORRECT = `${BASE_URL}/ukulele/D-top-corr_uke.png`;
const D_TOP_INCORRECT = `${BASE_URL}/ukulele/D-top-incorr_uke.png`;
const D_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/D - Correct_uke.svg`;
const D_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/D - Incorrect_uke.svg`;
const D_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/D - Top Level_uke.svg`;

const Dsus_FRONT_CORRECT = `${BASE_URL}/ukulele/Dsus-front-corr_uke.png`;
const Dsus_FRONT_INCORRECT = `${BASE_URL}/ukulele/Dsus-front-incorr_uke.png`;
const Dsus_TOP_CORRECT = `${BASE_URL}/ukulele/Dsus-top-corr_uke.png`;
const Dsus_TOP_INCORRECT = `${BASE_URL}/ukulele/Dsus-top-incorr_uke.png`;
const Dsus_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Dsus - Correct_uke.svg`;
const Dsus_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Dsus - Incorrect_uke.svg`;
const Dsus_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Dsus - Top Level_uke.svg`;

const E_FRONT_CORRECT = `${BASE_URL}/ukulele/E-front-corr_uke.png`;
const E_FRONT_INCORRECT = `${BASE_URL}/ukulele/E-front-incorr_uke.png`;
const E_TOP_CORRECT = `${BASE_URL}/ukulele/E-top-corr_uke.png`;
const E_TOP_INCORRECT = `${BASE_URL}/ukulele/E-top-incorr_uke.png`;
const E_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/E - Correct_uke.svg`;
const E_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/E - Incorrect_uke.svg`;
const E_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/E - Top Level_uke.svg`;

const Em_FRONT_CORRECT = `${BASE_URL}/ukulele/Em-front-corr_uke.png`;
const Em_FRONT_INCORRECT = `${BASE_URL}/ukulele/Em-front-incorr_uke.png`;
const Em_TOP_CORRECT = `${BASE_URL}/ukulele/Em-top-corr_uke.png`;
const Em_TOP_INCORRECT = `${BASE_URL}/ukulele/Em-top-incorr_uke.png`;
const Em_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Em - Correct_uke.svg`;
const Em_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Em - Incorrect_uke.svg`;
const Em_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Em - Top Level_uke.svg`;

const G_FRONT_CORRECT = `${BASE_URL}/ukulele/G-front-corr_uke.png`;
const G_FRONT_INCORRECT = `${BASE_URL}/ukulele/G-front-incorr_uke.png`;
const G_TOP_CORRECT = `${BASE_URL}/ukulele/G-top-corr_uke.png`;
const G_TOP_INCORRECT = `${BASE_URL}/ukulele/G-top-incorr_uke.png`;
const G_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/G - Correct_uke.svg`;
const G_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/G - Incorrect_uke.svg`;
const G_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/G - Top Level_uke.svg`;

const B7_FRONT_CORRECT = `${BASE_URL}/ukulele/B7-front-corr_uke.png`;
const B7_FRONT_INCORRECT = `${BASE_URL}/ukulele/B7-front-incorr_uke.png`;
const B7_TOP_CORRECT = `${BASE_URL}/ukulele/B7-top-corr_uke.png`;
const B7_TOP_INCORRECT = `${BASE_URL}/ukulele/B7-top-incorr_uke.png`;
const B7_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/B7 - Correct_uke.svg`;
const B7_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/B7 - Incorrect_uke.svg`;
const B7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/B7 - Top Level_uke.svg`;

const Dm_FRONT_CORRECT = `${BASE_URL}/ukulele/Dm-front-corr_uke.png`;
const Dm_FRONT_INCORRECT = `${BASE_URL}/ukulele/Dm-front-incorr_uke.png`;
const Dm_TOP_CORRECT = `${BASE_URL}/ukulele/Dm-top-corr_uke.png`;
const Dm_TOP_INCORRECT = `${BASE_URL}/ukulele/Dm-top-incorr_uke.png`;
const Dm_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Dm - Correct_uke.svg`;
const Dm_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Dm - Incorrect_uke.svg`;
const Dm_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Dm - Top Level_uke.svg`;

const G7_FRONT_CORRECT = `${BASE_URL}/ukulele/G7-front-corr_uke.png`;
const G7_FRONT_INCORRECT = `${BASE_URL}/ukulele/G7-front-incorr_uke.png`;
const G7_TOP_CORRECT = `${BASE_URL}/ukulele/G7-top-corr_uke.png`;
const G7_TOP_INCORRECT = `${BASE_URL}/ukulele/G7-top-incorr_uke.png`;
const G7_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/G7 - Correct_uke.svg`;
const G7_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/G7 - Incorrect_uke.svg`;
const G7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/G7 - Top Level_uke.svg`;

const F_FRONT_CORRECT = `${BASE_URL}/ukulele/F-front-corr_uke.png`;
const F_FRONT_INCORRECT = `${BASE_URL}/ukulele/F-front-incorr_uke.png`;
const F_TOP_CORRECT = `${BASE_URL}/ukulele/F-top-corr_uke.png`;
const F_TOP_INCORRECT = `${BASE_URL}/ukulele/F-top-incorr_uke.png`;
const F_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/F - Correct_uke.svg`;
const F_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/F - Incorrect_uke.svg`;
const F_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/F - Top Level_uke.svg`;

const F_SHARP_M_FRONT_CORRECT = `${BASE_URL}/ukulele/F_SHARP_m-front-corr_uke.png`;
const F_SHARP_M_FRONT_INCORRECT = `${BASE_URL}/ukulele/F_SHARP_m-front-incorr_uke.png`;
const F_SHARP_M_TOP_CORRECT = `${BASE_URL}/ukulele/F_SHARP_m-top-corr_uke.png`;
const F_SHARP_M_TOP_INCORRECT = `${BASE_URL}/ukulele/F_SHARP_m-top-incorr_uke.png`;
const F_SHARP_M_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/F_SHARP_m - Correct_uke.svg`;
const F_SHARP_M_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/F_SHARP_m - Incorrect_uke.svg`;
const F_SHARP_M_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/F_SHARP_m - Top Level_uke.svg`;

const C_SHARP_M_FRONT_CORRECT = `${BASE_URL}/ukulele/C_SHARP_m-front-corr_uke.png`;
const C_SHARP_M_FRONT_INCORRECT = `${BASE_URL}/ukulele/C_SHARP_m-front-incorr_uke.png`;
const C_SHARP_M_TOP_CORRECT = `${BASE_URL}/ukulele/C_SHARP_m-top-corr_uke.png`;
const C_SHARP_M_TOP_INCORRECT = `${BASE_URL}/ukulele/C_SHARP_m-top-incorr_uke.png`;
const C_SHARP_M_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/C_SHARP_m - Correct_uke.svg`;
const C_SHARP_M_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/C_SHARP_m - Incorrect_uke.svg`;
const C_SHARP_M_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/C_SHARP_m - Top Level_uke.svg`;

const Bm_FRONT_CORRECT = `${BASE_URL}/ukulele/Bm-front-corr_uke.png`;
const Bm_FRONT_INCORRECT = `${BASE_URL}/ukulele/Bm-front-incorr_uke.png`;
const Bm_TOP_CORRECT = `${BASE_URL}/ukulele/Bm-top-corr_uke.png`;
const Bm_TOP_INCORRECT = `${BASE_URL}/ukulele/Bm-top-incorr_uke.png`;
const Bm_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Bm - Correct_uke.svg`;
const Bm_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Bm - Incorrect_uke.svg`;
const Bm_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Bm - Top Level_uke.svg`;

const E7_FRONT_CORRECT = `${BASE_URL}/ukulele/E7-front-corr_uke.png`;
const E7_FRONT_INCORRECT = `${BASE_URL}/ukulele/E7-front-incorr_uke.png`;
const E7_TOP_CORRECT = `${BASE_URL}/ukulele/E7-top-corr_uke.png`;
const E7_TOP_INCORRECT = `${BASE_URL}/ukulele/E7-top-incorr_uke.png`;
const E7_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/E7 - Correct_uke.svg`;
const E7_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/E7 - Incorrect_uke.svg`;
const E7_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/E7 - Top Level_uke.svg`;

const C_SHARP_FRONT_CORRECT = `${BASE_URL}/ukulele/C_SHARP-front-corr_uke.png`;
const C_SHARP_FRONT_INCORRECT = `${BASE_URL}/ukulele/C_SHARP-front-incorr_uke.png`;
const C_SHARP_TOP_CORRECT = `${BASE_URL}/ukulele/C_SHARP-top-corr_uke.png`;
const C_SHARP_TOP_INCORRECT = `${BASE_URL}/ukulele/C_SHARP-top-incorr_uke.png`;
const C_SHARP_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/C_SHARP - Correct_uke.svg`;
const C_SHARP_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/C_SHARP - Incorrect_uke.svg`;
const C_SHARP_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/C_SHARP - Top Level_uke.svg`;

const Cm_FRONT_CORRECT = `${BASE_URL}/ukulele/Cm-front-corr_uke.png`;
const Cm_FRONT_INCORRECT = `${BASE_URL}/ukulele/Cm-front-incorr_uke.png`;
const Cm_TOP_CORRECT = `${BASE_URL}/ukulele/Cm-top-corr_uke.png`;
const Cm_TOP_INCORRECT = `${BASE_URL}/ukulele/Cm-top-incorr_uke.png`;
const Cm_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/Cm - Correct_uke.svg`;
const Cm_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/Cm - Incorrect_uke.svg`;
const Cm_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/Cm - Top Level_uke.svg`;

const B_FRONT_CORRECT = `${BASE_URL}/ukulele/B-front-corr_uke.png`;
const B_FRONT_INCORRECT = `${BASE_URL}/ukulele/B-front-incorr_uke.png`;
const B_TOP_CORRECT = `${BASE_URL}/ukulele/B-top-corr_uke.png`;
const B_TOP_INCORRECT = `${BASE_URL}/ukulele/B-top-incorr_uke.png`;
const B_DIAGRAM_CORRECT = `${BASE_URL}/ukulele/B - Correct_uke.svg`;
const B_DIAGRAM_INCORRECT = `${BASE_URL}/ukulele/B - Incorrect_uke.svg`;
const B_DIAGRAM_TOP_LEVEL = `${BASE_URL}/ukulele/B - Top Level_uke.svg`;

export const ukuleleChords: {
  [key: string]: {
    front_correct: string;
    front_incorrect: string;
    top_correct: string;
    top_incorrect: string;
    diagram_correct: string;
    diagram_incorrect: string;
    diagram_top_level: string;
  };
} = {
  A7: {
    front_correct: A7_FRONT_CORRECT,
    front_incorrect: A7_FRONT_INCORRECT,
    top_correct: A7_TOP_CORRECT,
    top_incorrect: A7_TOP_INCORRECT,
    diagram_correct: A7_DIAGRAM_CORRECT,
    diagram_incorrect: A7_DIAGRAM_INCORRECT,
    diagram_top_level: A7_DIAGRAM_TOP_LEVEL,
  },
  A: {
    front_correct: A_FRONT_CORRECT,
    front_incorrect: A_FRONT_INCORRECT,
    top_correct: A_TOP_CORRECT,
    top_incorrect: A_TOP_INCORRECT,
    diagram_correct: A_DIAGRAM_CORRECT,
    diagram_incorrect: A_DIAGRAM_INCORRECT,
    diagram_top_level: A_DIAGRAM_TOP_LEVEL,
  },
  Am: {
    front_correct: Am_FRONT_CORRECT,
    front_incorrect: Am_FRONT_INCORRECT,
    top_correct: Am_TOP_CORRECT,
    top_incorrect: Am_TOP_INCORRECT,
    diagram_correct: Am_DIAGRAM_CORRECT,
    diagram_incorrect: Am_DIAGRAM_INCORRECT,
    diagram_top_level: Am_DIAGRAM_TOP_LEVEL,
  },
  Asus: {
    front_correct: Asus_FRONT_CORRECT,
    front_incorrect: Asus_FRONT_INCORRECT,
    top_correct: Asus_TOP_CORRECT,
    top_incorrect: Asus_TOP_INCORRECT,
    diagram_correct: Asus_DIAGRAM_CORRECT,
    diagram_incorrect: Asus_DIAGRAM_INCORRECT,
    diagram_top_level: Asus_DIAGRAM_TOP_LEVEL,
  },
  B: {
    front_correct: B_FRONT_CORRECT,
    front_incorrect: B_FRONT_INCORRECT,
    top_correct: B_TOP_CORRECT,
    top_incorrect: B_TOP_INCORRECT,
    diagram_correct: B_DIAGRAM_CORRECT,
    diagram_incorrect: B_DIAGRAM_INCORRECT,
    diagram_top_level: B_DIAGRAM_TOP_LEVEL,
  },
  Bm: {
    front_correct: Bm_FRONT_CORRECT,
    front_incorrect: Bm_FRONT_INCORRECT,
    top_correct: Bm_TOP_CORRECT,
    top_incorrect: Bm_TOP_INCORRECT,
    diagram_correct: Bm_DIAGRAM_CORRECT,
    diagram_incorrect: Bm_DIAGRAM_INCORRECT,
    diagram_top_level: Bm_DIAGRAM_TOP_LEVEL,
  },
  B7: {
    front_correct: B7_FRONT_CORRECT,
    front_incorrect: B7_FRONT_INCORRECT,
    top_correct: B7_TOP_CORRECT,
    top_incorrect: B7_TOP_INCORRECT,
    diagram_correct: B7_DIAGRAM_CORRECT,
    diagram_incorrect: B7_DIAGRAM_INCORRECT,
    diagram_top_level: B7_DIAGRAM_TOP_LEVEL,
  },
  C: {
    front_correct: C_FRONT_CORRECT,
    front_incorrect: C_FRONT_INCORRECT,
    top_correct: C_TOP_CORRECT,
    top_incorrect: C_TOP_INCORRECT,
    diagram_correct: C_DIAGRAM_CORRECT,
    diagram_incorrect: C_DIAGRAM_INCORRECT,
    diagram_top_level: C_DIAGRAM_TOP_LEVEL,
  },
  "C#m": {
    front_correct: C_SHARP_M_FRONT_CORRECT,
    front_incorrect: C_SHARP_M_FRONT_INCORRECT,
    top_correct: C_SHARP_M_TOP_CORRECT,
    top_incorrect: C_SHARP_M_TOP_INCORRECT,
    diagram_correct: C_SHARP_M_DIAGRAM_CORRECT,
    diagram_incorrect: C_SHARP_M_DIAGRAM_INCORRECT,
    diagram_top_level: C_SHARP_M_DIAGRAM_TOP_LEVEL,
  },
  "C#": {
    front_correct: C_SHARP_FRONT_CORRECT,
    front_incorrect: C_SHARP_FRONT_INCORRECT,
    top_correct: C_SHARP_TOP_CORRECT,
    top_incorrect: C_SHARP_TOP_INCORRECT,
    diagram_correct: C_SHARP_DIAGRAM_CORRECT,
    diagram_incorrect: C_SHARP_DIAGRAM_INCORRECT,
    diagram_top_level: C_SHARP_DIAGRAM_TOP_LEVEL,
  },
  Cm: {
    front_correct: Cm_FRONT_CORRECT,
    front_incorrect: Cm_FRONT_INCORRECT,
    top_correct: Cm_TOP_CORRECT,
    top_incorrect: Cm_TOP_INCORRECT,
    diagram_correct: Cm_DIAGRAM_CORRECT,
    diagram_incorrect: Cm_DIAGRAM_INCORRECT,
    diagram_top_level: Cm_DIAGRAM_TOP_LEVEL,
  },
  D: {
    front_correct: D_FRONT_CORRECT,
    front_incorrect: D_FRONT_INCORRECT,
    top_correct: D_TOP_CORRECT,
    top_incorrect: D_TOP_INCORRECT,
    diagram_correct: D_DIAGRAM_CORRECT,
    diagram_incorrect: D_DIAGRAM_INCORRECT,
    diagram_top_level: D_DIAGRAM_TOP_LEVEL,
  },

  Dm: {
    front_correct: Dm_FRONT_CORRECT,
    front_incorrect: Dm_FRONT_INCORRECT,
    top_correct: Dm_TOP_CORRECT,
    top_incorrect: Dm_TOP_INCORRECT,
    diagram_correct: Dm_DIAGRAM_CORRECT,
    diagram_incorrect: Dm_DIAGRAM_INCORRECT,
    diagram_top_level: Dm_DIAGRAM_TOP_LEVEL,
  },
  D7: {
    front_correct: D7_FRONT_CORRECT,
    front_incorrect: D7_FRONT_INCORRECT,
    top_correct: D7_TOP_CORRECT,
    top_incorrect: D7_TOP_INCORRECT,
    diagram_correct: D7_DIAGRAM_CORRECT,
    diagram_incorrect: D7_DIAGRAM_INCORRECT,
    diagram_top_level: D7_DIAGRAM_TOP_LEVEL,
  },

  Dsus: {
    front_correct: Dsus_FRONT_CORRECT,
    front_incorrect: Dsus_FRONT_INCORRECT,
    top_correct: Dsus_TOP_CORRECT,
    top_incorrect: Dsus_TOP_INCORRECT,
    diagram_correct: Dsus_DIAGRAM_CORRECT,
    diagram_incorrect: Dsus_DIAGRAM_INCORRECT,
    diagram_top_level: Dsus_DIAGRAM_TOP_LEVEL,
  },
  E: {
    front_correct: E_FRONT_CORRECT,
    front_incorrect: E_FRONT_INCORRECT,
    top_correct: E_TOP_CORRECT,
    top_incorrect: E_TOP_INCORRECT,
    diagram_correct: E_DIAGRAM_CORRECT,
    diagram_incorrect: E_DIAGRAM_INCORRECT,
    diagram_top_level: E_DIAGRAM_TOP_LEVEL,
  },
  Em: {
    front_correct: Em_FRONT_CORRECT,
    front_incorrect: Em_FRONT_INCORRECT,
    top_correct: Em_TOP_CORRECT,
    top_incorrect: Em_TOP_INCORRECT,
    diagram_correct: Em_DIAGRAM_CORRECT,
    diagram_incorrect: Em_DIAGRAM_INCORRECT,
    diagram_top_level: Em_DIAGRAM_TOP_LEVEL,
  },
  E7: {
    front_correct: E7_FRONT_CORRECT,
    front_incorrect: E7_FRONT_INCORRECT,
    top_correct: E7_TOP_CORRECT,
    top_incorrect: E7_TOP_INCORRECT,
    diagram_correct: E7_DIAGRAM_CORRECT,
    diagram_incorrect: E7_DIAGRAM_INCORRECT,
    diagram_top_level: E7_DIAGRAM_TOP_LEVEL,
  },
  F: {
    front_correct: F_FRONT_CORRECT,
    front_incorrect: F_FRONT_INCORRECT,
    top_correct: F_TOP_CORRECT,
    top_incorrect: F_TOP_INCORRECT,
    diagram_correct: F_DIAGRAM_CORRECT,
    diagram_incorrect: F_DIAGRAM_INCORRECT,
    diagram_top_level: F_DIAGRAM_TOP_LEVEL,
  },
  "F#m": {
    front_correct: F_SHARP_M_FRONT_CORRECT,
    front_incorrect: F_SHARP_M_FRONT_INCORRECT,
    top_correct: F_SHARP_M_TOP_CORRECT,
    top_incorrect: F_SHARP_M_TOP_INCORRECT,
    diagram_correct: F_SHARP_M_DIAGRAM_CORRECT,
    diagram_incorrect: F_SHARP_M_DIAGRAM_INCORRECT,
    diagram_top_level: F_SHARP_M_DIAGRAM_TOP_LEVEL,
  },
  G: {
    front_correct: G_FRONT_CORRECT,
    front_incorrect: G_FRONT_INCORRECT,
    top_correct: G_TOP_CORRECT,
    top_incorrect: G_TOP_INCORRECT,
    diagram_correct: G_DIAGRAM_CORRECT,
    diagram_incorrect: G_DIAGRAM_INCORRECT,
    diagram_top_level: G_DIAGRAM_TOP_LEVEL,
  },
  G7: {
    front_correct: G7_FRONT_CORRECT,
    front_incorrect: G7_FRONT_INCORRECT,
    top_correct: G7_TOP_CORRECT,
    top_incorrect: G7_TOP_INCORRECT,
    diagram_correct: G7_DIAGRAM_CORRECT,
    diagram_incorrect: G7_DIAGRAM_INCORRECT,
    diagram_top_level: G7_DIAGRAM_TOP_LEVEL,
  },
};
