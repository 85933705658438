import Button from "components/reusable/buttons/button";
// import { useScroll } from "components/song_sheet/hooks/use_scroll";
import { timeSince, timestampToDate } from "helpers/timestampToSince";
import { useSelector } from "react-redux";
import {
  getCurrentCustomSong,
  getSharedWithMeSongs,
} from "redux/songwriting/songwriting_selectors";
import { Box, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ShareSongsModal } from "components/songwriting/share_songs/share_songs_modal/share_songs_modal";
import { useState } from "react";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { CustomSongSettings } from "./custom_song_settings/custom_song_settings";
import css from "./songbuilder_header.module.scss";

interface ISongBuilderHeader {
  returnHandler: () => void;
}

export const SongBuilderHeader = ({ returnHandler }: ISongBuilderHeader) => {
  // const { scrollActive, toggleScroll, scrollRequested } = useScroll(60, 1);
  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const userId = useSelector(getUserIdSelector);
  const sharedSongs = useSelector(getSharedWithMeSongs);
  const currentSong = useSelector(getCurrentCustomSong);
  const currentUserId = useSelector(getUserIdSelector);
  // const showPlayButton = !scrollActive && !scrollRequested;

  const openShareModalHandler = () => setIsSharingModalOpen(true);
  const sharedSong = sharedSongs?.find(
    (sharedSong) => sharedSong.sharedSong.custom_song_id === currentSong.id,
  );
  const generateEditedSinceSubscription = (editedAt?: string | Date | null) => {
    if (!editedAt) {
      return "This song has never been edited";
    }
    const editedSince =
      typeof editedAt === "string"
        ? timeSince(timestampToDate(editedAt))
        : timeSince(editedAt);
    return `Last edit was ${editedSince} ago`;
  };

  return (
    <Box className={css.header_bar}>
      <Box className={css.container}>
        <Box onClick={returnHandler} className={css.return_button_wrapper}>
          <ChevronLeftIcon />
          <Typography className={css.return_text}>Back to list</Typography>
        </Box>
        {currentSong.id && (
          <Typography className={css.edited_at_info}>
            {userId === currentSong.user_id
              ? generateEditedSinceSubscription(currentSong.edited_at)
              : generateEditedSinceSubscription(sharedSong?.editedAt)}
          </Typography>
        )}
      </Box>
      <Box className={css.song_settings_panel}>
        <CustomSongSettings />
      </Box>
      <Box className={css.additional_options_bar}>
        {currentUserId === currentSong.user_id ? (
          <Button onClick={openShareModalHandler} type="primary">
            Share
          </Button>
        ) : null}
      </Box>
      <ShareSongsModal
        open={isSharingModalOpen}
        setOpen={setIsSharingModalOpen}
      />
    </Box>
  );
};
