import {
  HelpContainer,
  StyledFontAwesomeIcon,
  HelpDescription,
} from "components/sign_up/steps/add_students_bulk/add_students_bulk.styled";

export const HelpAddStudents = () => (
  <HelpContainer>
    <a rel="noreferrer" target="_blank" href="https://youtu.be/RAp2fi9INgI">
      <StyledFontAwesomeIcon icon="play" size="1x" color="grey" />
    </a>
    <HelpDescription>
      <span>Need Help?</span>
      Watch this tutorial that walks you through a step-by-step process on
      uploading your students.
    </HelpDescription>
  </HelpContainer>
);
