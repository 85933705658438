/* eslint-disable no-unused-vars */
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router";
import { stripePubKey } from "redux/entities/stripe/stripe_consts";
import { useStripeOnSubmit } from "./stripe_onsubmit_hook";
import { IStripeProduct, TStripeError } from "../types";

// checkout page
const stripePromise = loadStripe(stripePubKey);
interface ICheckoutForm {
  product: IStripeProduct;
}

interface ICheckoutFormComponent {
  product: IStripeProduct;
}

// todo: refactor component
const CheckoutFormComponent = ({ product }: ICheckoutFormComponent) => {
  const priceId = product.id;
  const stripe = useStripe();
  // todo: any
  const { register, handleSubmit } = useForm<any>();
  const [cardError, setCardError] = useState<TStripeError>(undefined);
  const [cardEmpty, setCardEmpty] = useState(true);
  const [processing, setProcessing] = useState(false);
  const history = useHistory();

  const onSuccess = (studentEmail: string) => {
    toast.success(
      `${studentEmail} is now a Moosiko member and can continue their musical journey with access to all our song lessons.`,
      { autoClose: 10000 },
    );
    history.push("/");
  };
  const onSubmit = useStripeOnSubmit({
    priceId,
    setProcessing,
    onSuccess,
    setCardError,
    cardEmpty,
    cardError,
  });
  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <H2>Who are you paying for?</H2>
        <StudentEmailText>Add student email address below.</StudentEmailText>
        <InputWithLabelContainer>
          <InputLabel>
            Student Email (so we know which account to credit)
          </InputLabel>
          <Input
            {...register("studentEmail")}
            required
            type="email"
            placeholder="student_email@example.com"
          />
        </InputWithLabelContainer>
        <Hr />
        <InputWithLabelContainer>
          <InputLabel>Your Email</InputLabel>
          <Input
            required
            {...register("parentEmail")}
            type="email"
            placeholder="email@example.com"
          />
        </InputWithLabelContainer>
        <InputWithLabelContainer>
          <InputLabel>Card information</InputLabel>
          <CardInput
            onChange={(e) => {
              setCardError(e.error);
              setCardEmpty(e.empty);
            }}
          />
          {cardError && <ErrorMessage>{cardError.message}</ErrorMessage>}
        </InputWithLabelContainer>
        <InputWithLabelContainer>
          <InputLabel>Name on card</InputLabel>
          <Input
            {...register("nameOnCard")}
            required
            placeholder="First name Last name"
          />
        </InputWithLabelContainer>
        <Submit type="submit" disabled={!stripe}>
          {processing ? (
            <CircularProgress size={32} />
          ) : (
            `Pay ${product.price}.00/${product.postfix}`
          )}
        </Submit>
      </Form>
      <NeedHelp>
        Need help? Email
        <HelpLink
          href="mailto:help@moosiko.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;help@moosiko.com&nbsp;
        </HelpLink>
        for support
      </NeedHelp>
    </Container>
  );
};

interface ICheckoutFormProps {
  product: IStripeProduct;
}

// eslint-disable-next-line react/no-multi-comp
export const CheckoutForm = ({ product }: ICheckoutFormProps) => {
  return <CheckoutFormComponent product={product} />;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
  min-height: 100%;
`;

const HelpLink = styled.a`
  color: #6775de;
`;

const NeedHelp = styled.p`
  text-align: center;
  padding: 12px;
`;

const Form = styled.form`
  flex-grow: 1;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #626262;
  font-family: Helvetica;
  box-sizing: border-box;
  padding: 12px;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 434px;
  height: 40px;
  padding: 4px;
  width: 100%;
`;

const CardInput = styled(CardElement)`
  width: 100%;
  max-width: 434px;
  margin: 0;
`;

const Hr = styled.hr`
  width: 100%;
  max-width: 434px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const H2 = styled.h2`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #190067;
`;

const StudentEmailText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-bottom: 4px;
  }
`;

const InputLabel = styled.label`
  margin-bottom: 8px;
  display: inline-block;
`;

const InputWithLabelContainer = styled.div`
  max-width: 434px;
  width: 100%;
  margin: 8px 0;
`;

const Submit = styled.button`
  background: #6775de;
  border-radius: 4px;
  width: 100%;
  max-width: 434px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 48px;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`;
const ErrorMessage = styled.p`
  color: red;
`;
