import axios from "axios";

import { getNodeApiBaseUrl } from "./base_api";

interface IReminderModalProps {
  userId: number;
  modalTitle: string;
}

export const reminderModalApi = {
  getReminderStatus({ userId, modalTitle }: IReminderModalProps) {
    const url = `${getNodeApiBaseUrl()}/check-reminder/${userId}`;

    return axios.get(url, { params: { modalTitle } });
  },
  completeReminder({ userId, modalTitle }: IReminderModalProps) {
    const url = `${getNodeApiBaseUrl()}/check-reminder/${userId}`;

    return axios.post(url, { params: { modalTitle } });
  },
};
