import { Box, Typography } from "@material-ui/core";
import SeedTypeCards from "./seed_type_cards";

const Seeds = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ gap: 18 }}
      alignItems="center"
      marginTop={2}
    >
      <Typography
        style={{
          fontStyle: "italic",
          fontSize: 16,
          color: "#5F6367",
          fontFamily: "Raleway",
        }}
      >
        Quickly capture song ideas, lyrics, and audio
      </Typography>
      <SeedTypeCards />
    </Box>
  );
};

export default Seeds;
