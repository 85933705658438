// @ts-ignore
import S3 from "aws-s3";

export interface IS3Instance {
  config: IConfig;
  uploadFile: (_: File, __?: string) => Promise<UploadResponse>;
  deleteFile: (_: string) => Promise<DeleteResponse>;
}

export interface IConfig {
  bucketName: string;
  dirName?: string;
  region: string;
  accessKeyId: string;
  secretAccessKey: string;
  s3Url?: string;
}

export type UploadResponse = {
  bucket: string;
  key: string;
  location: string;
  status: number;
};

export type DeleteResponse = {
  ok: boolean;
  status: number;
  message: string;
  fileName: string;
};

const config_logos: IConfig = {
  bucketName: "moosiko-staging",
  dirName: "org-logos",
  region: "us-west-1",
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID as string,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY as string,
};

const audioRecordingConfig: IConfig = {
  bucketName: "moosiko-staging",
  dirName: "audioRecords",
  region: "us-west-1",
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID as string,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY as string,
};

export const S3AudioRecord: IS3Instance = new S3(audioRecordingConfig);

export const S3ClientLogos: IS3Instance = new S3(config_logos);

export const S3_VIDEO_LOGO_FOLDER = config_logos;

export async function uploadBlobToS3(
  blob: Blob,
  S3Client: IS3Instance,
  fileName?: string,
): Promise<UploadResponse> {
  const fileFromBlob = new File([blob], fileName || "unnamed.mp3", {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
  const fileNameWithTimeStamp = `${fileName}_${Number(new Date())}`;

  const uploadedFile = await S3Client.uploadFile(
    fileFromBlob,
    fileNameWithTimeStamp,
  );

  return uploadedFile;
}

export async function uploadFileToS3(
  file: File,
  S3Client: any,
): Promise<UploadResponse> {
  const fileNameWithTimeStamp = `${file.name}_${Number(new Date())}`;

  const uploadedFile = await S3Client.uploadFile(file, fileNameWithTimeStamp);

  return uploadedFile;
}

export async function uploadAudioRecordingToS3(
  audio: File,
  S3Client: IS3Instance,
): Promise<UploadResponse> {
  const fileNameWithTimeStamp = `${audio.name}_${Number(new Date())}`;
  const uploadedAudio = await S3Client.uploadFile(audio, fileNameWithTimeStamp);
  return uploadedAudio;
}

export async function deleteAudioRecordingFromS3(
  fileName: string,
  S3Client: IS3Instance,
) {
  const deletedAudio = await S3Client.deleteFile(fileName);
  return deletedAudio;
}
