import React, { useEffect, useState } from "react";

import { last } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { lessonApi, ILesson } from "api/lesson_api";
import { recommendationApi } from "api/recommendation_api";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import {
  getUserSelector,
  isAuthorizedSelector,
} from "redux/auth/user_selectors";
import { openRecommendationModal } from "redux/ui/modal/modal_slice";
import { store } from "redux/store";
import { NO_SKILL } from "constants/skills_constants";
import { SongCard } from "../song_card";
import { SpecificCategories } from "../constants";
import {
  CategoryContainer,
  CategoryName,
  SeeAll,
  LessonsContainer,
  VerticalBorder,
} from "./all_songs_styled_components";

interface ICategory {
  name: string;
  wide: boolean;
  seeAll: boolean;
  lessons?: {
    Guitar?: number[];
    Ukulele?: number[];
  };
}
const DEV_DIFFERENCE = -800;
const STAGING_DIFFERENCE = 400;
export const NUMBER_OF_LESSONS_IN_CATEGORY = 5;

export const Category = (props: ICategory) => {
  const user = useSelector(getUserSelector);

  const dispatch = useDispatch();
  const [categoryLessons, setCategoryLessons] = useState<{
    [key: string]: ILesson;
  }>();
  const { name, wide, seeAll, lessons } = props;
  const { instruments: selectedInstruments } = useSelector(getSongFilters);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const currentInstrument = last(selectedInstruments) as "Guitar" | "Ukulele";
  let lessonsToFetch: number[] | undefined = [];

  const fetchPopularLessons = async () => {
    const ids = await lessonApi.fetchPopularLessons({
      instruments: selectedInstruments,
      limit: NUMBER_OF_LESSONS_IN_CATEGORY,
    });
    const fetchedLessons = await lessonApi.fetchLessonsById({
      id: ids.data.lessons,
      userId: user?.id,
    });
    setCategoryLessons(fetchedLessons.data.lessons);
  };

  const fetchRecentlyAddedLessons = async () => {
    const ids = await lessonApi.fetchRecentlyAddedLessons({
      instruments: selectedInstruments,
      limit: NUMBER_OF_LESSONS_IN_CATEGORY,
    });
    const fetchedLessons = await lessonApi.fetchLessonsById({
      id: ids.data.lessons,
      userId: user?.id,
    });
    setCategoryLessons(fetchedLessons.data.lessons);
  };

  const skillToFetch = currentInstrument
    ? currentInstrument.toUpperCase()
    : NO_SKILL;

  const fetchRecommendedLessons = async () => {
    const isAuth = store.getState().auth.user;
    if (isAuth) {
      const {
        data: { lessons },
      } = await recommendationApi.fetchRecommendedLessons({
        start: 0,
        limit: NUMBER_OF_LESSONS_IN_CATEGORY,
        instrument: skillToFetch,
        skillName: "",
      });
      const idLessons = lessons.map((lesson) => lesson.id);
      const fetchedLessons = await lessonApi.fetchLessonsById({
        id: idLessons,
        userId: user?.id,
      });
      setCategoryLessons(fetchedLessons.data.lessons);
    } else {
      setCategoryLessons(undefined);
    }
  };

  let EnvDifference: number;
  switch (process.env.REACT_APP_NODE_ENV) {
    case "development":
      EnvDifference = DEV_DIFFERENCE;
      break;
    case "staging":
      EnvDifference = STAGING_DIFFERENCE;
      break;
    default:
      EnvDifference = 0;
  }

  const fetchCategoryLessons = async () => {
    if (lessons) {
      lessonsToFetch = lessons[currentInstrument] as number[];

      lessonsToFetch = lessonsToFetch?.map((lessonID) => {
        return lessonID + EnvDifference;
      });

      const fetchedLessons = await lessonApi.fetchLessonsById({
        id: lessonsToFetch,
        userId: user?.id,
      });
      setCategoryLessons(fetchedLessons.data.lessons);
    }
  };

  useEffect(() => {
    if (currentInstrument) {
      switch (name) {
        case SpecificCategories.MostPopular:
          fetchPopularLessons();
          break;
        case SpecificCategories.RecommendedForYou:
          fetchRecommendedLessons();
          break;
        case SpecificCategories.RecentlyAdded:
          fetchRecentlyAddedLessons();
          break;
        default:
          fetchCategoryLessons();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInstrument, isAuthorized]);

  const handleSeeAll = () => {
    dispatch(
      openRecommendationModal({
        title: "Recommended for You Lessons",
        instrument: currentInstrument,
        skillName: undefined,
        skillId: undefined,
      }),
    );
  };

  return (
    <CategoryContainer wide={wide}>
      <CategoryName>{name}</CategoryName>
      {seeAll && <SeeAll onClick={handleSeeAll}>See All</SeeAll>}
      <LessonsContainer wide={wide}>
        {categoryLessons &&
          Object.keys(categoryLessons).map((key, index) => {
            const isLast = index === NUMBER_OF_LESSONS_IN_CATEGORY - 1;
            return (
              <React.Fragment key={key}>
                <SongCard isWide={wide} isNew lesson={categoryLessons[key]} />
                <VerticalBorder isWide={wide} isLast={isLast} />
              </React.Fragment>
            );
          })}
      </LessonsContainer>
    </CategoryContainer>
  );
};
