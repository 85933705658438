/* eslint-disable no-magic-numbers */
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

export const SwitchSettings = withStyles({
  root: {
    width: 33,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 3,
    color: "white",
    "&$checked": {
      transform: "translateX(17px)",
      color: "white",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#190067",
        borderColor: "#190067",
      },
    },
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: "none",
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#ADADAD",
  },
  checked: {},
})(Switch);
