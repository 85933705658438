import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomSongs } from "redux/songwriting/songwriting_selectors";
import { fetchCustomSongs } from "redux/songwriting/songwriting_slice";

import { MemoizedSongCard } from "../song_card/song_card";

export const OwnedSongList = () => {
  const customSongs = useSelector(getCustomSongs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomSongs());
  }, [dispatch]);

  return (
    <div>
      {customSongs &&
        customSongs.map((item) => (
          <MemoizedSongCard
            song_data={item}
            key={item.id}
            src={
              item.custom_song_assets?.length
                ? item.custom_song_assets[0]?.url
                : ""
            }
            isSharedSong={false}
          />
        ))}
    </div>
  );
};
