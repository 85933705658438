/* eslint-disable no-unused-vars */
export enum TAssessmentSortDirections {
  ASC = "asc",
  DESC = "desc",
}

export enum TAssessmentSortTypes {
  NAME = "NAME",
  SUBMISSION_DATE = "SUBMISSION_DATE",
  SKILL_TYPE = "SKILL_TYPE",
  SKILL = "SKILL",
  SONG_LESSON = "SONG_LESSON",
  SUBMISSION = "SUBMISSION",
}
