import { Dialog } from "@material-ui/core";
import SeedNote from "./seed_note";

const SeedNoteModal = () => {
  return (
    <Dialog open fullScreen>
      <SeedNote />
    </Dialog>
  );
};

export default SeedNoteModal;
