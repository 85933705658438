import React, { FormEvent, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { closeClassModal } from "redux/ui/modal/modal_slice";
import { AppDispatch } from "types/redux_";
import { useHistory } from "react-router";
import { isActionRejectedHelper } from "helpers/is_action_rejected_helper";
import { IInviteStudent } from "redux/auth/auth_api";
import { inviteStudents } from "../../../redux/courses/courses_slice";
import { getOrganizationPassword } from "../../../redux/organizations/organization_selectors";
import css from "./add_students_manually_tab.module.scss";
import { AddStudentRow } from "./add_student_row/add_student_row";

interface IAddStudentsManuallyTab {
  setSelectedStudentsCount: React.Dispatch<React.SetStateAction<number>>;
  courseId: number;
  isTeacherSignUp?: boolean;
}

export interface IInviteStudentWithId extends IInviteStudent {
  id: number;
}

export interface IHandleChangeStudent {
  id: number;
  field: keyof IInviteStudent;
  value: string;
}

export const AddStudentsManuallyTab = (props: IAddStudentsManuallyTab) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const defaultPassword = useSelector(getOrganizationPassword);

  const [students, setStudents] = useState<IInviteStudentWithId[]>([]);
  const { setSelectedStudentsCount, courseId, isTeacherSignUp } = props;
  const handleAddNewStudent = () => {
    setStudents((prevState) => [
      ...prevState,
      {
        id: Math.random(),
        firstName: "",
        lastName: "",
        email: "",
      },
    ]);
  };

  useEffect(() => {
    setSelectedStudentsCount(students.length);
  }, [setSelectedStudentsCount, students]);

  const handleChangeStudent = ({ id, field, value }: IHandleChangeStudent) => {
    setStudents((prevState) =>
      prevState.map((student) => {
        if (student.id === id) {
          student[field] = value;
        }
        return student;
      }),
    );
  };

  const removeStudent = (id: number) => {
    setStudents(students.filter((student) => student.id !== id));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const result = await dispatch(inviteStudents({ students, courseId }));

    if (isActionRejectedHelper(result)) {
      return;
    }

    if (isTeacherSignUp) {
      history.push("/");
    } else {
      dispatch(closeClassModal());
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={css.addStudentsContainer}
      id="add_students_to_course"
    >
      <div>
        {students.map((student) => (
          <AddStudentRow
            handleChangeStudent={handleChangeStudent}
            student={student}
            removeStudent={removeStudent}
            key={student.id}
          />
        ))}
        <button
          className={css.addStudentButton}
          type="button"
          onClick={handleAddNewStudent}
        >
          <AddIcon />
          Add Students
        </button>
      </div>
      <div className={css.bottomText}>
        <hr />
        {defaultPassword && (
          <div className={css.globalPasswordText}>
            {`Your global password for students is set to ${defaultPassword}`}
            <br />
            <Link className={css.linkToProfile} to="profile">
              update password in ‘my profile’
            </Link>
          </div>
        )}
        <button
          className={css.sentInviteButton}
          type="submit"
          form="add_students_to_course"
        >
          {defaultPassword ? "Add Students" : "Send Invite"}
        </button>
      </div>
    </form>
  );
};
