import cn from "classnames";

// http://tobiasahlin.com/spinkit/
interface ISpinner {
  spinnerClass?: string;
}
const Spinner = ({ spinnerClass }: ISpinner) => {
  return (
    <div className={cn("spinner", spinnerClass)}>
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
      <div className="rect6" />
    </div>
  );
};

export default Spinner;
