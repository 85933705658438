import { ILesson } from "api/lesson_api";
import axios from "axios";
import { toast } from "react-toastify";
import { ICourse } from "redux/courses/courses_api";
import { IFeedback } from "redux/feedback/feedback_types";
import { ISkill } from "types/models/skill";
import { IVideo } from "types/models/video";
import { IUser } from "redux/auth/auth_api";
import { getNodeApiBaseUrl } from "../../api/base_api";

export interface IUserWithCoursesAndLessons extends IUser {
  courses: Pick<ICourse, "id">[];
  lessons: Pick<ILesson, "id">[];
}

export interface IMediaRecord {
  id: number;
  URL: string;
  user_id: number;
  skill_id: number;
  lesson_id: number;
  viewed: boolean;
  task_id: string;
  encoded_video: string;
  videos_id: string;

  user: IUserWithCoursesAndLessons;
  lesson: ILesson;
  skill: ISkill;
  video: IVideo;
  feedbacks: IFeedback[];

  createdAt: Date;
  updatedAt: Date;
}

export interface ICreateMediaRecord {
  file: File;
  userId: number;
  lessonId: number;
  skillId?: number;
}

export const mediaRecordingsApi = {
  async createMediaRecord(mediaRecord: ICreateMediaRecord) {
    const url = `${getNodeApiBaseUrl()}/media-record`;
    const formData = new FormData();
    for (const [key, value] of Object.entries(mediaRecord)) {
      if (value !== undefined) {
        const finalValue = typeof value === "number" ? String(value) : value;
        formData.append(key, finalValue);
      }
    }
    try {
      const data = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(
        "Video is processing. You can check video status in My Journey",
      );

      return data;
    } catch (e) {
      toast.error(
        "Video failed, no internet connection. Please check internet connection",
      );
      console.error(e);
    }
  },

  fetchMediaRecordings(orgId: number) {
    const url = `${getNodeApiBaseUrl()}/media-record/organization/${orgId}`;
    return axios.get<{ mediaRecords: IMediaRecord[] }>(url);
  },

  async fetchUserMediaRecordings(userId: number) {
    const url = `${getNodeApiBaseUrl()}/media-record/student/${userId}`;
    const {
      data: { mediaRecords },
    } = await axios.get<{ mediaRecords: IMediaRecord[] }>(url);
    return mediaRecords;
  },

  updateRecordings(mediaRecord: { id: number; viewed?: boolean }) {
    const url = `${getNodeApiBaseUrl()}/media-record/${mediaRecord.id}`;
    return axios.post<void>(url, { ...mediaRecord });
  },

  /**
   * @param {number[]} ids
   */
  deleteRecordings(ids: number[]) {
    const url = `${getNodeApiBaseUrl()}/media-record`;
    return axios.delete<void>(url, { data: { ids } });
  },

  fetchMediaRecordById(id: number) {
    const url = `${getNodeApiBaseUrl()}/media-record/${id}`;
    return axios.get<{ mediaRecord: IMediaRecord }>(url);
  },
};
