import { Typography } from "@material-ui/core";
import { ExternalResource } from "components/organization/add_from_external_resources/add_from_external_resources";
import React from "react";
import { useDispatch } from "react-redux";
import { listUsersFromGoogleClassroomCourse } from "redux/import_students/google_classroom/google_classroom_students_slice";
import { listUsersFromGroup } from "redux/import_students/microsoft_teams/microsoft_teams_students_slice";
import { STEPS } from "../../bulk_import_from_google_classroom/bulk_import_from_google_classroom";
import { StyledClassroomCard } from "../../bulk_import_from_google_classroom/bulk_import_from_google_classroom.styled";

interface IClassroomCard {
  type: ExternalResource;
  setCurrentStep: React.Dispatch<React.SetStateAction<STEPS>>;
  title: string;
  courseId: string;
}

export const ClassroomCard = ({
  type,
  title,
  courseId,
  setCurrentStep,
}: IClassroomCard) => {
  const dispatch = useDispatch();

  const handleClassroomClick = () => {
    if (type === ExternalResource.GOOGLE_CLASSROOM) {
      dispatch(listUsersFromGoogleClassroomCourse({ courseId }));
    }
    if (type === ExternalResource.MS_TEAMS) {
      dispatch(listUsersFromGroup({ groupId: courseId }));
    }
    setCurrentStep(STEPS.STUDENT_LIST);
  };
  return (
    <StyledClassroomCard onClick={handleClassroomClick}>
      <Typography>{title}</Typography>
    </StyledClassroomCard>
  );
};
