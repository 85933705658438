import styled from "@emotion/styled";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
`;

export const StyledBackIcon = styled(ArrowDownwardIcon)`
  transform: rotate(90deg);
  padding: 0.3em 0.5em;
`;
