import {
  MY_SONGS,
  ALL_SONGS,
  TUNER,
  MY_JOURNEY,
  ORGANIZATION,
  PROFILE_ROUTE,
  ORG_MANAGEMENT,
} from "routes/route_constants";
import css from "../nav.module.scss";

const active: { [key: string]: string } = {
  "/": "",
  [ALL_SONGS]: "",
  [MY_SONGS]: "",
  [TUNER]: "",
  [MY_JOURNEY]: "",
  [PROFILE_ROUTE]: "",
  [ORGANIZATION]: "",
  [ORG_MANAGEMENT]: "",
};

export const activeLinks = (path: string) => {
  const activeSection = { ...active };
  activeSection[path] = css.link_active;
  return activeSection;
};
