import { useState, useRef, useEffect, MouseEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "hooks/use_width";
import { useHistory } from "react-router";
import { openPayForSubscriptionModal } from "redux/ui/modal/modal_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import { UPGRADE } from "routes/route_constants";
import {
  accessByRoleSelector,
  getRoleSelector,
} from "redux/auth/roles/roles_selector";
import css from "./desktop_menu.module.scss";
import css_nav from "../nav.module.scss";
import { MenuLinks } from "../shared/menu_links";

const DELAY = 300;
const TABLET_WIDTH = 800;

export const Menu = () => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [timerHandle, setTimerHandle] = useState<number | null>(null);

  const desktopMenu = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    const menu = desktopMenu.current;
    if (menu) {
      menu.classList.add("fadeOutUp");
      const timerHandle = window.setTimeout(() => {
        setOpened(false);
        setTimerHandle(null);
      }, DELAY);
      setTimerHandle(timerHandle);
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!opened) {
      setOpened(true);
      const close = handleClose;
      document.addEventListener("click", function handler(e: Event) {
        close();
        e.currentTarget?.removeEventListener(e.type, handler);
      });
    } else {
      setOpened(false);
    }
  };

  useEffect(() => {
    return () => {
      if (timerHandle) {
        clearTimeout(timerHandle);
        setTimerHandle(null);
      }
    };
  });

  const userRole = useSelector(getRoleSelector);
  const dispatch = useDispatch();

  const UpgradeModalCallback = useCallback(() => {
    userRole === ROLES.FREE_USER && dispatch(openPayForSubscriptionModal());
    userRole === ROLES.FREE_SONGWRITING &&
      dispatch(openPayForSubscriptionModal());
    userRole === ROLES.FREE_TEACHER_ACCOUNT && history.push(UPGRADE);
  }, [dispatch, history, userRole]);

  const isFreeUser = useSelector(
    accessByRoleSelector([
      ROLES.FREE_USER,
      ROLES.FREE_TEACHER_ACCOUNT,
      ROLES.FREE_SONGWRITING,
    ]),
  );
  const mobile = useWindowWidth() < TABLET_WIDTH;

  return (
    <>
      <div className={css_nav.links}>
        {!mobile && isFreeUser && (
          <button
            type="button"
            className={css.upgrade_button}
            onClick={UpgradeModalCallback}
          >
            Upgrade
          </button>
        )}
        <button
          type="button"
          className={css_nav.menu_link}
          onClick={handleClick}
        >
          Menu
        </button>
      </div>
      {opened && (
        <div ref={desktopMenu} className={`${css.main} fadeInDown animated2`}>
          <div className={css.container}>
            <MenuLinks css={css} />
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
