import { CancelTokenSource } from "axios";
import { ReactNode, useMemo, useRef } from "react";
import { AudioPlayerContext, useAudioPlayer } from "./audio_player_context";
import { CancelTokenContext } from "./cancel_token";

interface IContextWrapper {
  children: ReactNode;
}
export const ContextWrapper = (props: IContextWrapper) => {
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const { audioPlayerState } = useAudioPlayer();

  const cancelTokenValue = useMemo(() => ({ cancelToken }), [cancelToken]);
  const audioPlayerStateValue = useMemo(
    () => ({ audioPlayerState }),
    [audioPlayerState],
  );

  return (
    <CancelTokenContext.Provider value={cancelTokenValue}>
      <AudioPlayerContext.Provider value={audioPlayerStateValue}>
        {props.children}
      </AudioPlayerContext.Provider>
    </CancelTokenContext.Provider>
  );
};
