import { useDispatch, useSelector } from "react-redux";
import { getShowSelectSongOverlay } from "redux/songwriting_mobile/songwriting_mobile_selectors";
import { setShowSelectSongOverlay } from "redux/songwriting_mobile/songwriting_mobile_slice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Box, IconButton, Typography } from "@material-ui/core";
import SongCards from "../song_cards/song_cards";

const SelectSongOverlay = () => {
  const dispatch = useDispatch();
  const showSelectSongOverlay = useSelector(getShowSelectSongOverlay);
  const displayStyle = showSelectSongOverlay ? "flex" : "none";

  const exitOverlay = () => {
    dispatch(setShowSelectSongOverlay(false));
  };

  return (
    <Box
      style={{
        backgroundColor: "#f2f1f6",
      }}
      display={displayStyle}
      flexDirection="column"
      alignItems="center"
      position="absolute"
      top={0}
      minHeight="100vh"
      width="100%"
    >
      <Box width="100%">
        <IconButton aria-label="back" onClick={exitOverlay}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: 24,
          color: "#190067",
        }}
      >
        Add to Existing Song
      </Typography>
      <SongCards purpose="link" />
    </Box>
  );
};

export default SelectSongOverlay;
