import { useSelector, useDispatch } from "react-redux";

import { Input } from "components/reusable/input/input";
import Button, { BTN_TYPE } from "components/reusable/buttons/button";
import { getUserEmails, getUserSelector } from "redux/auth/user_selectors";
import {
  // openChangeEmailModal,
  openConversionlModal,
  openUpdatePasswordModal,
} from "redux/ui/modal/modal_slice";
import cn from "classnames";
// import { useCallback } from "react";
import css from "./account_info.module.scss";
import { ChangeEmailDialog } from "../change_email_dialog";

export const AccountInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  let userEmails = useSelector(getUserEmails);

  // eslint-disable-next-line no-unused-vars
  // const handleChangePrimaryEmail = useCallback(
  //   (email) => {
  //     dispatch(openChangeEmailModal(email));
  //   },
  //   [dispatch],
  // );
  if (!user) {
    return null;
  }
  userEmails = userEmails.filter((userEmail) => userEmail.email !== user.email);

  return (
    <div className={css.layout}>
      <div className={css.input}>
        <Input
          classes={{ wrapper: css.input_item }}
          name="fname"
          value={user.fname}
          disabled
        />
      </div>

      <div className={cn(css.input, css.email_input)}>
        <Input
          classes={{ wrapper: css.input_item }}
          name="email"
          value={user.email}
          disabled
        />
        {user.provider === "email" && user.email !== "justplay@moosiko.com" && (
          <Button
            type={BTN_TYPE.SECONDARY}
            classes={{ btn: css.change_password_btn }}
            onClick={() =>
              dispatch(openUpdatePasswordModal({ noCloseButton: false }))
            }
          >
            Change Password
          </Button>
        )}
      </div>

      {!!userEmails.length && <div>Alternate Email Addresses: </div>}

      {userEmails.map((userEmail) => {
        return (
          <div className={css.input} key={userEmail.email}>
            <Input
              classes={{ wrapper: css.input_item }}
              name="email"
              value={userEmail.email}
              disabled
            />
            {/* <button
              onClick={() => handleChangePrimaryEmail(userEmail.email)}
              className={css.make_primary}
              type="button"
            >
              Make primary
            </button> */}
          </div>
        );
      })}

      {!userEmails.length && (
        <Button
          type={BTN_TYPE.SECONDARY}
          classes={{ btn: css.change_password_btn }}
          onClick={() => dispatch(openConversionlModal())}
        >
          Add Personal email
        </Button>
      )}
      <p className={css.email_text}>
        Email help@moosiko.com for technical support or life coaching (we'll
        try)
      </p>
      <ChangeEmailDialog />
    </div>
  );
};
