import { useDroppable } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentCustomSong,
  getTypeOfDragAndDropAction,
  getTablesData,
  getActualTableData,
  getUsingMobileAppFlag,
} from "redux/songwriting/songwriting_selectors";
import { EnumDragAndDropActions } from "redux/songwriting/songwriting_interfaces";
import {
  Box,
  Button,
  Dialog,
  Drawer,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  getAddChordClickPosition,
  getAddChordDrawerOpen,
} from "redux/songwriting_mobile/songwriting_mobile_selectors";
import { useChordsTable } from "hooks";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  changeViewedAtStatus,
  setSelectedSongBucket,
} from "redux/songwriting/songwriting_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { setAddChordDrawerOpen } from "redux/songwriting_mobile/songwriting_mobile_slice";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import { StyledMenu } from "components/songwriting_mobile/styled_menu/styled_menu";
import SongbuilderPdf from "./songbuilder_pdf/songbuilder_pdf";
import { ProtectionBadge } from "./protection_badge/protection_badge";
import { ProtectionDialog } from "./protection_dialog/protection_dialog";
import { SongBuilderSideBar } from "../songbuilder_sidebar/song_builder_sidebar";
import { EditableTitle } from "./editable_title/editable_title";
import css from "./songbuilder_main_section.module.scss";
import { SongUnitList } from "../song_unit_list/song_unit_list";
import { SONGBUILDER_DATA } from "../songbuilder_data";
import { ChordAccordion } from "../songbuilder_sidebar/build_song_bar/chord_accordion/chord_accordion";

export const SongBuilderMainSection = () => {
  const { setNodeRef } = useDroppable({
    id: "songUnitDroppable",
    data: {
      accepts: SONGBUILDER_DATA.draggableSongUnitsArray,
    },
  });

  const dispatch = useDispatch();

  const mobile = useSelector(getUsingMobileAppFlag);

  const currentSong = useSelector(getCurrentCustomSong);
  const userId = useSelector(getUserIdSelector);
  const typeOfDraggedElement = useSelector(getTypeOfDragAndDropAction);
  const tablesData = useSelector(getTablesData());
  const actualTableData = useSelector(getActualTableData);

  const [protectionDialogState, setProtectionDialogState] = useState(false);
  const [protectedStatus, setProtectedStatus] = useState(true);

  const [chordAnchorEl, setChordAnchorEl] = useState<null | HTMLElement>(null);
  const selectedChord = useSelector(getAddChordClickPosition);
  const { deleteChordFromStructure } = useChordsTable();
  const menuVerticalOffset = 75;

  const drawerState = useSelector(getAddChordDrawerOpen);
  const [currentChordSearch, setCurrentChordSearch] = useState<string>("");
  const useStyles = makeStyles({
    paper: {
      background: "#F8F9FA",
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
      height: "400px",
    },
  });
  const classes = useStyles();

  const handleCloseChordMenu = () => {
    setChordAnchorEl(null);
  };

  const deleteSelectedChord = () => {
    handleCloseChordMenu();
    selectedChord.existingChord &&
      deleteChordFromStructure({
        title: selectedChord.existingChord,
        structureId: selectedChord.songUnitId,
        rowId: selectedChord.row,
        columnId: selectedChord.column,
      });
  };

  const replaceSelectedChord = () => {
    handleCloseChordMenu();
    dispatch(setAddChordDrawerOpen(true));
  };

  const closeMobileChordDrawer = (shouldResetSearch: boolean = false) => {
    dispatch(setAddChordDrawerOpen(false));
    if (shouldResetSearch) {
      setCurrentChordSearch("");
    }
  };

  useEffect(() => {
    const anyChords =
      tablesData
        .map((songSection) => {
          return songSection.songChords;
        })
        .filter((n) => n).length > 0;

    const anyLyrics =
      actualTableData
        .map((songSection) => {
          return songSection.data.filter(
            (songSectionRow, songSectionRowIndex) => {
              return songSectionRowIndex % 2 === 1 && songSectionRow.c0 !== "";
            },
          );
        })
        .filter((lyricRow) => lyricRow.length > 0).length > 0;

    if (anyChords && anyLyrics) {
      setProtectedStatus(true);
    } else {
      setProtectedStatus(false);
    }
  }, [tablesData, actualTableData, protectedStatus]);

  useEffect(() => {
    function handleUnload() {
      userId &&
        dispatch(
          changeViewedAtStatus({
            user_id: userId,
            viewed_at: new Date(),
          }),
        );
    }

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentSong.custom_song_lyrics.length) {
      dispatch(
        setSelectedSongBucket({
          id: currentSong.custom_song_lyrics[0].id as number,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeBorder =
    typeOfDraggedElement === EnumDragAndDropActions.createStructure;

  const launchSongProtectionDialog = () => {
    setProtectionDialogState(true);
  };

  const closeSongProtectionDialog = () => {
    setProtectionDialogState(false);
  };

  return (
    <div className={css.song_builder_main_wrapper}>
      <Dialog
        open={protectionDialogState}
        onClose={() => closeSongProtectionDialog()}
      >
        <ProtectionDialog
          closeAction={closeSongProtectionDialog}
          protectedStatus={protectedStatus}
        />
      </Dialog>
      <div
        className={
          mobile ? css.song_builder_area_mobile : css.song_builder_area
        }
        id="song_area"
      >
        <div
          className={mobile ? css.title_container_mobile : css.title_container}
        >
          <div
            className={
              mobile ? css.title_left_bumper_mobile : css.title_left_bumper
            }
          />
          <div className={css.song_name_container}>
            <EditableTitle title={currentSong.song_name} />
          </div>
          {!mobile && (
            <div className={css.song_builder_tools_area}>
              <PDFDownloadLink
                document={
                  <Document>
                    <SongbuilderPdf
                      customSong={currentSong}
                      protectedStatus={protectedStatus}
                    />
                  </Document>
                }
                fileName={currentSong.song_name}
              >
                <div className={css.song_builder_tools_area_pdf_container}>
                  <PictureAsPdfIcon
                    className={css.song_builder_tools_area_pdf_icon}
                  />
                </div>
              </PDFDownloadLink>

              <ProtectionBadge
                openAction={launchSongProtectionDialog}
                protectedStatus={protectedStatus}
              />
            </div>
          )}
        </div>
        <div
          ref={setNodeRef}
          className={
            mobile
              ? css.song_structure_wrapper_mobile
              : css.song_structure_wrapper
          }
          style={{
            border: "1px solid",
            borderColor: activeBorder ? "#4c9ffe" : "#EEE",
          }}
        >
          <SongUnitList setChordAnchorEl={setChordAnchorEl} />
        </div>
      </div>

      <div
        className={
          mobile
            ? css.song_builder_sidebar_container_mobile
            : css.song_builder_sidebar_container
        }
      >
        <SongBuilderSideBar />
      </div>
      {mobile && (
        <>
          <StyledMenu
            id="linkSongMenu"
            anchorEl={chordAnchorEl}
            keepMounted
            open={Boolean(chordAnchorEl)}
            onClose={handleCloseChordMenu}
            vertical={menuVerticalOffset}
          >
            <MenuItem onClick={deleteSelectedChord}>
              <Box display="flex" style={{ gap: "10px" }}>
                <Typography>Delete</Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={replaceSelectedChord}>
              <Box display="flex" style={{ gap: "10px" }}>
                <Typography>Change Chord</Typography>
              </Box>
            </MenuItem>
          </StyledMenu>

          <Drawer
            anchor="bottom"
            open={drawerState}
            onClose={() => {
              closeMobileChordDrawer();
            }}
            PaperProps={{ square: false }}
            classes={{ paper: classes.paper }}
          >
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              paddingTop="20px"
            >
              <div />
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "24px",
                }}
              >
                Insert Chord
              </Typography>
              <Button
                onClick={() => {
                  closeMobileChordDrawer();
                }}
                style={{ fontWeight: 700, fontSize: "18px", color: "#76B3F3" }}
              >
                Close
              </Button>
            </Box>
            <Box padding="20px">
              <ChordAccordion
                content={[]}
                setCurrentChordSearch={setCurrentChordSearch}
                currentChordSearch={currentChordSearch}
                closeMenuHandler={() => {
                  closeMobileChordDrawer(true);
                }}
              />
            </Box>
          </Drawer>
        </>
      )}
    </div>
  );
};
