import cn from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { SongKeyPopover } from "./song_key_popover";
import css from "../../custom_song_settings.module.scss";

export const SongKey = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const currentSong = useSelector(getCurrentCustomSong);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        className={cn(css.padding, css.div_button)}
      >
        <div className={css.main_font_styles}>
          {currentSong.custom_song_key}
        </div>
        <p className={css.subtitle_font_styles}>Key</p>
      </div>
      <SongKeyPopover anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};
