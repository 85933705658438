import classNames from "classnames";
import { GUITAR_NOTES, UKULELE_NOTES } from "constants/notes";
import { Instrument } from "constants/skills_constants";
import { LAPTOP_WIDTH, useWindowWidth } from "hooks/use_width";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";

import { TunerDesktopLayout } from "./desktop_layout/tuner_desktop_layout";
import { TunerMobileLayout } from "./mobile_layout/tuner_mobile_layout";
import css from "./tuner_layout.module.scss";

export const TunerLayout = () => {
  const { instruments: currentInstrument } = useSelector(getSongFilters);
  const width = useWindowWidth();

  const [currentLayout, setCurrentLayout] = useState<Instrument>(
    currentInstrument[0].toLocaleUpperCase() as Instrument,
  );
  const [activeNoteFrequency, setActiveNoteFrequency] = useState("");
  const [activeNoteName, setActiveNoteName] = useState("");

  const changeNoteHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveNoteFrequency(e.currentTarget.value);
    setActiveNoteName(e.currentTarget.dataset.notename || "");
  };

  const GuitarNoteList = GUITAR_NOTES.map((note) => (
    <button
      onClick={changeNoteHandler}
      value={note.frequency}
      type="button"
      data-notename={note.note}
      className={classNames(css.rounded_button_lg, {
        [css.active_rounded_button]: activeNoteFrequency === note.frequency,
      })}
      key={note.frequency}
    >
      {note.note}
    </button>
  ));
  const UkuleleNoteList = UKULELE_NOTES.map((note) => (
    <button
      onClick={changeNoteHandler}
      value={note.frequency}
      data-notename={note.note}
      type="button"
      className={classNames(css.rounded_button_lg, {
        [css.active_rounded_button]: activeNoteFrequency === note.frequency,
      })}
      key={note.frequency}
    >
      {note.note}
    </button>
  ));

  return width > LAPTOP_WIDTH ? (
    <TunerDesktopLayout
      GuitarNoteList={GuitarNoteList}
      UkuleleNoteList={UkuleleNoteList}
      activeNoteFrequency={activeNoteFrequency}
      setActiveNoteFrequency={setActiveNoteFrequency}
      setActiveNoteName={setActiveNoteName}
      currentLayout={currentLayout}
      activeNoteName={activeNoteName}
      setCurrentLayout={setCurrentLayout}
    />
  ) : (
    <TunerMobileLayout
      GuitarNoteList={GuitarNoteList}
      UkuleleNoteList={UkuleleNoteList}
      activeNoteName={activeNoteName}
      activeNoteFrequency={activeNoteFrequency}
      setActiveNoteName={setActiveNoteName}
      setActiveNoteFrequency={setActiveNoteFrequency}
      currentLayout={currentLayout}
      setCurrentLayout={setCurrentLayout}
    />
  );
};
