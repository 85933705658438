import { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";
import { SongContent } from "redux/songwriting/songwriting_interfaces";
import { useHandleMobileAdd } from "hooks/use_handle_mobile_add";
import { SONGBUILDER_DATA } from "components/songwriting/songbuilder/songbuilder_data";
import css from "../draggable_item/draggable_item.module.scss";

interface IMobileItem {
  item: SongContent;
  closeMenuHandler: () => void;
}

export const MobileItem = ({ item, closeMenuHandler }: IMobileItem) => {
  const { addMobileSongSectionHandler, addMobileSongChordHandler } =
    useHandleMobileAdd();

  const addAction = SONGBUILDER_DATA.songStructureArray.includes(item.title)
    ? "create-structure"
    : "add-chord";

  return (
    <div
      className={css.list_item_box_mobile}
      onClick={() => {
        if (addAction === "create-structure") {
          addMobileSongSectionHandler({ item });
        } else {
          addMobileSongChordHandler({ chordName: item.title });
        }
        closeMenuHandler && closeMenuHandler();
      }}
      role="button"
      tabIndex={0}
    >
      {item.icon ? (
        <Suspense fallback={<CircularProgress />}>{item.icon}</Suspense>
      ) : (
        <p className={css.list_item_title}>{item.title}</p>
      )}
    </div>
  );
};
