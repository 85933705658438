import { useState } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { ROLES, ROLE_IDS } from "redux/auth/roles/roles_consts";
import { setUserDataSignup } from "redux/sign_up_steps/sign_up_steps_slice";
import { openLoginModal, openSignupModal } from "redux/ui/modal/modal_slice";
import { useHistory } from "react-router";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { promoApi } from "api/promo_api";
import { applyPromoRole } from "redux/auth/auth_slice";
import { Typography } from "@material-ui/core";
import {
  Container,
  Header,
  SignUpCheck,
  StyledParagraph,
  TextLink,
  StretchContainer,
} from "../emotion.styles";
import { SignUpButton } from "../../reusable/button_sign_up";
import { CloseStepsButton } from "../../reusable/close_steps_button";

import individual from "../../images/individual-plan-logo.svg";
import student from "../../images/student-plan-logo.svg";
import teacher from "../../images/teacher-plan-logo.svg";
import admin from "../../images/admin-plan-logo.svg";
import {
  Content,
  Plan,
  PlanName,
  Img,
  ContainerImg,
  StyledButton,
} from "./choose_member.styled";

export const ChooseMember = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [promoRole, setPromoRole] = useState<number>(0);

  const handleIndividual = () => {
    history.push("/");
    dispatch(openSignupModal());
  };
  const handleStudent = () => {
    history.push("/");
    dispatch(openLoginModal());
  };
  const handleTeacher = () => {
    dispatch(
      stepListActions.nextStep({ stepListId: STEP_LIST_IDS.signUpStepList }),
    );
  };
  const handleAdministrator = () => {
    dispatch(setUserDataSignup({ role: ROLES.DISTRICT_ADMINISTRATOR }));
    history.push("/");
    dispatch(openSignupModal());
  };

  const plans = [
    {
      name: "Individual",
      description: "I am making music or learning\n an instrument myself.",
      img: individual,
      buttonText: "Choose Individual",
      handleClick: handleIndividual,
    },
    {
      name: "Student",
      description: "I am learning or writing music \n in a school music class.",
      img: student,
      buttonText: "Choose Student",
      handleClick: handleStudent,
    },
    {
      name: "Teacher",
      description: "I am teaching a school \n music class.",
      img: teacher,
      buttonText: "Choose Teacher",
      handleClick: handleTeacher,
    },
    {
      name: "District Administrator",
      description: "I oversee a music program at \n my school / district.",
      img: admin,
      buttonText: "Choose Administrator",
      handleClick: handleAdministrator,
    },
  ];

  (async () => {
    const { promo } = queryString.parse(window.location.search);
    const promoData = await promoApi.fetchRole(promo);
    promoData && dispatch(applyPromoRole(promoData.data.roles_id));
    promoData && setPromoRole(promoData.data.roles_id);
  })();

  switch (promoRole) {
    case ROLE_IDS.FREE_USER:
    case ROLE_IDS.INDIVIDUAL:
      handleIndividual();
      break;
    case ROLE_IDS.SOLO_TEACHER:
    case ROLE_IDS.FREE_TEACHER_ACCOUNT:
    case ROLE_IDS.PAID_TEACHER_ACCOUNT:
      handleTeacher();
      break;
    default:
  }

  return (
    <StretchContainer>
      <CloseStepsButton />
      <Container>
        <Header>Get Started with Moosiko</Header>
        <SignUpCheck>
          <StyledParagraph> Already have a Moosiko account?</StyledParagraph>
          <TextLink onClick={handleStudent}>Login here</TextLink>
        </SignUpCheck>

        <Content>
          {plans.map((plan) => (
            <Plan key={plan.name + plan.description}>
              <ContainerImg>
                <Img src={plan.img} alt="individual-plan-logo" />
              </ContainerImg>
              <PlanName>{plan.name}</PlanName>
              {plan.description}
              <StyledButton>
                <SignUpButton onClick={plan.handleClick}>
                  {plan.buttonText}
                </SignUpButton>
              </StyledButton>
            </Plan>
          ))}
        </Content>
        <Typography
          style={{
            fontFamily: "Raleway",
            textAlign: "center",
            fontWeight: "400",
            padding: "0px 20px 25px 20px",
          }}
        >
          Need help logging in? Email{" "}
          <a
            href="mailto:help@moosiko.com"
            style={{ textDecoration: "underline" }}
          >
            help@moosiko.com
          </a>
        </Typography>
      </Container>
    </StretchContainer>
  );
};
