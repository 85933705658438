import { IFinishLesson, lessonApi } from "api/lesson_api";
import { IUserSkillsDataApi, userSkillsApi } from "api/user_skills_api";
import axios, { CancelTokenSource } from "axios";
import { CHORD, PROGRESSION, TRANSITION } from "constants/skills_constants";
import { toast } from "react-toastify";
import { ROLES } from "redux/auth/roles/roles_consts";
import { accessByRoleSelector } from "redux/auth/roles/roles_selector";
import { IState } from "redux/store";
import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import { AppDispatch } from "types/redux_";

import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { isAuthorizedSelector } from "../../auth/user_selectors";
import { getModalDataSelector } from "../../ui/modal/modal_selectors";
import {
  onMoosikoBtnClick,
  openChoosePlanModal,
  openRecommendationModal,
  openSignupModal,
} from "../../ui/modal/modal_slice";
import { fetchUserLessons } from "../user_lessons/user_lessons_slice";
import { getSkillId } from "../user_skills/user_skills_selector";

export const receiveUserSkillsAction = createAction<IUserSkillsDataApi>(
  "userSkills/receiveUserSkillsAction",
);

export const fetchUserSkillsAction = createAction<boolean>(
  "userSkills/fetchUserSkillsAction",
);

export const updateUserChordSkillAction = createAction<{ chord: string }>(
  "userSkills/updateUserChordSkillAction",
);

export const updateUserTransitionSkillAction = createAction<{
  transition: string;
}>("userSkills/updateUserTransitionSkillAction");

export const updateUserProgressionSkillAction = createAction<{
  progression: string;
}>("userSkills/updateUserProgressionSkillAction");

// for statistic bar
export const changeCurrentInstrument = createAction<{
  currentInstrument: IInstruments;
}>("userSkills/changeCurrentInstrument");

export const fetchUserChordSkills = createAsyncThunk<
  void,
  void,
  { state: IState }
>("userSkills/updateUserSkill", async (_, { dispatch }) => {
  dispatch(fetchUserSkillsAction(true));
  try {
    const { data: userSkills } = await userSkillsApi.fetchUserSkills();
    dispatch(receiveUserSkillsAction(userSkills));
  } catch (err) {
    console.error("fetchUserChordSkills error", err);
  }
});

/**
 * Selects which skill should be updated
 */
const updateUserSkillAction = (skillType: string, skillName: string) => {
  switch (skillType) {
    case CHORD: {
      return updateUserChordSkillAction({ chord: skillName });
    }
    case TRANSITION: {
      return updateUserTransitionSkillAction({ transition: skillName });
    }
    case PROGRESSION: {
      return updateUserProgressionSkillAction({ progression: skillName });
    }
    default:
      throw new Error("Skill type is wrong");
  }
};

export const finishLesson =
  (params: IFinishLesson) =>
  async (dispatch: AppDispatch, getState: () => IState) => {
    const { lessonId, instrument, progress, undo } = params;
    if (typeof lessonId !== "number") {
      console.error("finishLesson error, incorrect lessonId = ", lessonId);
      return;
    }

    const state = getState();
    const isAuthorized = isAuthorizedSelector(state);

    if (!isAuthorized) {
      dispatch(openSignupModal());
      return;
    }
    try {
      await lessonApi.finishLesson({ lessonId, instrument, progress, undo });
      if (progress === undefined) {
        dispatch(fetchUserLessons());
        const { data: userSkills } = await userSkillsApi.fetchUserSkills();
        dispatch(receiveUserSkillsAction(userSkills));
        if (!undo) {
          dispatch(
            openRecommendationModal({
              title: "New Skills Added. \n Here’s What to Learn Next.",
              instrument,
            }),
          );
        }
      }
    } catch (e) {
      console.info(e);
    }
  };

const cancelToken = axios.CancelToken;
interface IUpdateUserSkill {
  skillType: string;
  skillName: string;
  learned: boolean;
  cancelSrc: CancelTokenSource | null;
}

export const updateUserSkill = createAsyncThunk<
  void | (CancelTokenSource | null),
  IUpdateUserSkill,
  { state: IState }
>(
  "userSkills/updateUserSkill",
  async (
    { skillType, skillName, learned, cancelSrc },
    { dispatch, getState },
  ) => {
    try {
      const state = getState();
      const isFreeUserRole = accessByRoleSelector([ROLES.FREE_USER])(state);
      const currentModal = getModalDataSelector(state);

      if (isFreeUserRole && currentModal.type !== "skillset") {
        dispatch(openChoosePlanModal());
        return;
      }

      if (cancelSrc) {
        cancelSrc.cancel();
      }

      dispatch(updateUserSkillAction(skillType, skillName));
      const skillId = getSkillId(skillType, skillName)(getState());
      if (!skillId) {
        throw skillId;
      }
      await userSkillsApi.updateSkill(skillId, learned);

      const newToken = cancelToken.source();

      const res = await userSkillsApi
        .fetchUserSkills(newToken.token)
        .catch((err) => {
          if (axios.isCancel(err)) {
            throw new Error(err);
          }
        });

      if (res) {
        dispatch(receiveUserSkillsAction(res.data));
      }

      return newToken;
    } catch (err) {
      toast.error("Cannot update skill");
      dispatch(fetchUserSkillsAction(false));
      dispatch(updateUserSkillAction(skillType, skillName));
      console.error("updateUserChordSkill error", err);
    }
  },
);

export const onSkillClick = (
  skillType: string,
  skillName: string,
  instrument: string,
) =>
  onMoosikoBtnClick({
    skillType,
    skillName,
    title: createTitle(skillType, skillName),
    instrument,
  });

const createTitle = (skillType: string, skillName: string) => {
  if (!skillType) {
    return "";
  }
  switch (skillType) {
    case CHORD: {
      return `Learn new chord: ${skillName}`;
    }
    case TRANSITION: {
      return `Learn new chord transition: ${JSON.parse(skillName).join("-")}`;
    }
    case PROGRESSION: {
      return `Learn new chord progression: ${JSON.parse(skillName).join("-")}`;
    }
    default: {
      throw new Error("Wrong skillType");
    }
  }
};

export type IInstruments = "guitar" | "ukulele";
export type ISkillByInstruments = {
  // eslint-disable-next-line no-unused-vars
  [key in IInstruments]: string[];
};

export interface IUserSkillsState {
  fetching: boolean;
  currentInstrument: IInstruments;
  chords: ISkillByInstruments;
  transitions: ISkillByInstruments;
  progressions: ISkillByInstruments;
  strummingSongs: {
    guitar: IUserStrummingLessons | null;
    ukulele: IUserStrummingLessons | null;
  };
}

const initialState: IUserSkillsState = {
  fetching: true,
  currentInstrument: "guitar",
  chords: {
    guitar: [],
    ukulele: [],
  },
  transitions: {
    guitar: [],
    ukulele: [],
  },
  progressions: {
    guitar: [],
    ukulele: [],
  },
  strummingSongs: { guitar: null, ukulele: null },
};

const userSkillsSlice = createSlice({
  name: "userSkills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserChordSkillAction, (state, { payload }) => {
      if (payload.chord) {
        state.chords.guitar.push(payload.chord);
        state.chords.ukulele.push(payload.chord);
      }
    });
    builder.addCase(updateUserTransitionSkillAction, (state, { payload }) => {
      if (payload.transition) {
        state.transitions.guitar.push(payload.transition);
        state.transitions.ukulele.push(payload.transition);
      }
    });
    builder.addCase(updateUserProgressionSkillAction, (state, { payload }) => {
      if (payload.progression) {
        state.progressions.guitar.push(payload.progression);
        state.progressions.ukulele.push(payload.progression);
      }
    });
    builder.addCase(fetchUserSkillsAction, (state, { payload }) => {
      state.fetching = payload;
    });
    builder.addCase(receiveUserSkillsAction, (state, { payload }) => {
      const chords = {
        guitar: payload.chords.guitar.map((chord) => chord.name),
        ukulele: payload.chords.ukulele.map((chord) => chord.name),
      };
      state.chords = {
        guitar: chords.guitar,
        ukulele: chords.ukulele,
      };

      const progressions = {
        guitar: payload.progressions.guitar.map((chord) => chord.name),
        ukulele: payload.progressions.ukulele.map((chord) => chord.name),
      };
      state.progressions = {
        guitar: progressions.guitar,
        ukulele: progressions.ukulele,
      };

      const transitions = {
        guitar: payload.transitions.guitar.map((chord) => chord.name),
        ukulele: payload.transitions.ukulele.map((chord) => chord.name),
      };
      state.transitions = {
        guitar: transitions.guitar,
        ukulele: transitions.ukulele,
      };

      state.strummingSongs = payload.strummingSongs;
    });
    builder.addCase(changeCurrentInstrument, (state, { payload }) => {
      state.currentInstrument = payload.currentInstrument;
    });
  },
});

export const { reducer: userSkillsReducer } = userSkillsSlice;
