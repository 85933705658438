import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Drawer } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GUITAR_CHORD_LIBRARY_TYPE,
  openChordLibraryModal,
  UKULELE_CHORD_LIBRARY_TYPE,
} from "redux/ui/modal/modal_slice";
import { eventHandler } from "hooks/use_popover";
import { ILesson } from "api/lesson_api";
import css from "./song_sheet_top_bar_mobile.module.scss";
import infoIcon from "../info.svg";

interface ISongSheetTopBarMobile {
  handleClickSong: eventHandler;
  slider: React.ReactNode;
  scrollControls: React.ReactNode;
  metronomeControls: React.ReactNode;
  lesson: ILesson;
  tempo: number;
}

export const SongSheetTopBarMobile = ({
  handleClickSong,
  scrollControls,
  slider,
  metronomeControls,
  lesson,
  tempo,
}: ISongSheetTopBarMobile) => {
  const { alpha_tab_code, instrument } = lesson;
  const { song_name, artist } = lesson.song;

  const { goBack } = useHistory();

  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  const dispatch = useDispatch();

  let chordLibraryType = GUITAR_CHORD_LIBRARY_TYPE;

  if (instrument === "Ukulele") {
    chordLibraryType = UKULELE_CHORD_LIBRARY_TYPE;
  }

  const handleOpenChords = () => {
    dispatch(openChordLibraryModal(chordLibraryType));
  };

  return (
    <section className={css.mobile_container}>
      <div className={css.top}>
        <button type="button" className={css.go_back} onClick={goBack}>
          <ArrowBackIcon />
        </button>
        <button type="button" onClick={handleClickSong}>
          <div className={css.top_text}>
            <span className={css.mobile_song_name}>{song_name}</span>
            <img className={css.info_img} src={infoIcon} alt="info" />
          </div>
          <div className={css.bottom_text}>{artist}</div>
        </button>

        {!alpha_tab_code && (
          <button
            type="button"
            className={css.more_button}
            onClick={toggleDrawer}
          >
            <MoreVertIcon />
          </button>
        )}
      </div>
      {!alpha_tab_code && (
        <>
          {scrollControls}
          <Drawer anchor="bottom" open={drawer} onClose={toggleDrawer}>
            <section className={css.drawer_container}>
              <div className={css.bpm}>
                <div className={css.bpm_value}>{tempo} bpm</div>
                <div className={css.setting_tempo}>tempo</div>
              </div>
              {slider}
              {metronomeControls}
              <button
                className={css.openChordButton}
                type="button"
                onClick={handleOpenChords}
              >
                Chords
              </button>
            </section>
          </Drawer>
        </>
      )}
    </section>
  );
};
