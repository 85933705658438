import { Dispatch, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropdown } from "hooks/use_dropdown";
import cn from "classnames";
import css from "./line_dropdown.module.scss";
import { DropDown } from "./drop_down/drop_down";

const TAB_100 = 100;

const CARET_UP = "caret-up";
const CARET_DOWN = "caret-down";

interface ILineDropDown {
  header: string;
  main: string[];
  tabNumber: number;
  isOpened: boolean;
  setTab: Dispatch<number>;
}

export const LineDropDown = (props: ILineDropDown) => {
  const { header, main, tabNumber, isOpened, setTab } = props;

  const dropDown = useRef<HTMLButtonElement>(null);

  const handleTabClick = useDropdown(isOpened, setTab, tabNumber, dropDown);

  return (
    <button
      type="button"
      className={css.wrapper}
      ref={dropDown}
      onClick={handleTabClick}
      onMouseLeave={() => setTab(0)}
      onMouseEnter={handleTabClick}
    >
      <div className={cn(css.progress_text, css[`progress_text_${tabNumber}`])}>
        <span>{header}</span>
        <FontAwesomeIcon
          className={cn(css.icon, tabNumber === TAB_100 ? css.icon_100 : 0)}
          icon={isOpened ? CARET_UP : CARET_DOWN}
        />
      </div>
      {isOpened ? <DropDown text={main} tabNumber={tabNumber} /> : null}
    </button>
  );
};
