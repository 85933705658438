import React, { useState } from "react";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@material-ui/core";
import { moveStudent } from "redux/courses/courses_slice";
import { getCourses } from "redux/courses/courses_selectors";
import CloseIcon from "@material-ui/icons/Close";
import { CourseBlock } from "components/organization/course_block/course_block";
import { useHistory } from "react-router-dom";
import { IStudentWithSkills } from "types/models/student_with_skills";
import css from "./move_student_dialog.module.scss";

interface IMoveStudentDialog {
  student: IStudentWithSkills;
  moveStudentDialogOpen: boolean;
  setMoveStudentDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MoveStudentDialog = (props: IMoveStudentDialog) => {
  const {
    moveStudentDialogOpen,
    setMoveStudentDialogOpen,
    student: { courseId, fname: firstName, id: studentId },
  } = props;
  const history = useHistory();

  const courses = useSelector(getCourses);
  const dispatch = useDispatch();
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);

  const handleMoveStudent = async () => {
    if (courseId && selectedCourseId) {
      await dispatch(
        moveStudent({
          courseId,
          userId: studentId,
          newCourseId: selectedCourseId,
        }),
      );
      history.push(`/student/${selectedCourseId}/${studentId}`);
    }
  };

  return (
    <Dialog
      onClose={() => setMoveStudentDialogOpen(false)}
      open={moveStudentDialogOpen}
    >
      <div className={css.move_student_dialog}>
        <h2 className={css.move_student_header}>Move student</h2>
        <span className={css.move_student_text}>
          {`Which class would you like to move ${firstName} to?`}
        </span>
        <div>
          <div className={css.move_students_courses}>
            {courses
              .filter((course) => course.id !== courseId)
              .map((course) => {
                return (
                  <CourseBlock
                    course={course}
                    key={course.id}
                    showMenu={false}
                    icon={
                      selectedCourseId === course.id ? (
                        <div className={css.selected_icon}>
                          <CheckCircleOutlineIcon />
                        </div>
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )
                    }
                    onClick={(event) => {
                      setSelectedCourseId(course.id);
                      event.preventDefault();
                    }}
                  />
                );
              })}
          </div>
        </div>
        <button
          onClick={handleMoveStudent}
          className={css.move_student_button}
          type="submit"
        >
          Move Student
        </button>
      </div>
      <button
        type="button"
        onClick={() => setMoveStudentDialogOpen(false)}
        className={css.close_icon}
      >
        <CloseIcon />
      </button>
    </Dialog>
  );
};
