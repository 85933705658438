const testProducts = {
  month: {
    id: "price_1IrrFtIDGw03DOx5LTOVvkbm",
    price: "7",
    postfix: "mo",
  },
  year: {
    id: "price_1Is6MSIDGw03DOx5SPcDvneP",
    price: "59",
    postfix: "year",
  },
  songwritingMonth: {
    id: "",
    price: "",
    postfix: "mo",
  },
  songwritingYear: {
    id: "",
    price: "",
    postfix: "year",
  },
};

const prodProducts = {
  month: {
    id: "price_1IlynVIDGw03DOx5S0usOWpo",
    price: "7",
    postfix: "mo",
  },
  year: {
    id: "price_1IlyoBIDGw03DOx5gTatUisV",
    price: "59",
    postfix: "year",
  },
  songwritingMonth: {
    id: "",
    price: "",
    postfix: "mo",
  },
  songwritingYear: {
    id: "",
    price: "",
    postfix: "year",
  },
};

export const studentProducts =
  process.env.REACT_APP_NODE_ENV === "production" ? prodProducts : testProducts;
