import axios from "axios";
import { getNodeApiBaseUrl } from "./base_api";

export interface IDecade {
  decade: string;
}

export const decadesApi = {
  fetchDecades() {
    const url = `${getNodeApiBaseUrl()}/songs/decades`;
    return axios.get<IDecade[]>(url);
  },
};
