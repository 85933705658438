/* eslint-disable @typescript-eslint/ban-types */
import React, { useRef } from "react";
import cn from "classnames";
import { IInputClasses, Input } from "../input/input";
import { ReactComponent as SearchIcon } from "../../../img/search_icon.svg";
import css from "./input_search.module.scss";

interface IInputSearch {
  inputClasses: IInputClasses;
  classes: { wrapper?: string };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const InputSearch = (props: IInputSearch) => {
  const { classes, inputClasses, onChange, ...otherProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearchIconClick = () => {
    inputRef?.current?.focus();
  };

  return (
    <div className={cn(css.wrapper, classes.wrapper)}>
      <Input
        {...otherProps}
        onChange={onChange}
        classes={{
          ...inputClasses,
          input: cn(inputClasses.input, css.input),
        }}
        ref={inputRef}
      />

      <button
        type="button"
        onClick={handleSearchIconClick}
        className={css.icon_wrapper}
      >
        <SearchIcon className={css.search_icon} />
      </button>
    </div>
  );
};
