/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { isAuthorizedSelector } from "redux/auth/user_selectors";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { closeModal } from "redux/ui/modal/modal_slice";
import { applyLoginType } from "redux/auth/auth_slice";
import { SSHLogIn } from "components/sign_up/reusable/SSHLogIn";
import { SIGNUP, MOBILE_SIGNUP } from "routes/route_constants";
import { Typography } from "@material-ui/core";
import { ModalContainer } from "../modal_container";
import { ButtonAuth } from "./buttonAuth";
import { Form } from "./form";

import css from "./login_modal.module.scss";

export enum AvailableProvidersEnum {
  google = "Google",
  microsoft = "Microsoft",
  email = "Email",
}

export const LoginModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mobile = Boolean(
    navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/),
  );
  const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");

  const isAuthorized = useSelector(isAuthorizedSelector);

  const [isShowForm, setIsShowFrom] = useState(false);

  useEffect(() => {
    isAuthorized && dispatch(closeModal());
  }, [dispatch, isAuthorized]);

  dispatch(applyLoginType("LogIn"));

  const handleSignUp = () => {
    dispatch(closeModal());
    history.push(mobile ? MOBILE_SIGNUP : SIGNUP);
  };

  const handleShowForm = () => {
    setIsShowFrom(!isShowForm);
  };

  return (
    <ModalContainer noCloseButton={mobile}>
      <div className={css.title}>Log In</div>
      <div className={css.description}>Log in to your Moosiko account.</div>
      {isShowForm && <Form />}

      <div className={css.button_container}>
        <SSHLogIn provider={AvailableProvidersEnum.google}>
          <ButtonAuth provider={AvailableProvidersEnum.google} />
        </SSHLogIn>
      </div>

      {!iOSApp && (
        <div className={css.button_container}>
          <SSHLogIn provider={AvailableProvidersEnum.microsoft}>
            <ButtonAuth provider={AvailableProvidersEnum.microsoft} />
          </SSHLogIn>
        </div>
      )}

      {!isShowForm && (
        <div className={css.button_container}>
          <ButtonAuth
            provider={AvailableProvidersEnum.email}
            handleClick={handleShowForm}
          />
        </div>
      )}
      <div className={css.footer}>
        <div className={css.description_bottom}>Don't have an account?</div>
        <button type="button" className={css.link} onClick={handleSignUp}>
          Sign Up
        </button>
      </div>
      <Typography
        style={{
          fontFamily: "Raleway",
          textAlign: "center",
          color: "grey",
          fontWeight: "400",
          padding: "0px 20px 25px 20px",
        }}
      >
        Need help logging in? Email{" "}
        <a
          href="mailto:help@moosiko.com"
          style={{ textDecoration: "underline", color: "inherit" }}
        >
          help@moosiko.com
        </a>
      </Typography>
    </ModalContainer>
  );
};
