import axios from "axios";
import { IRoles } from "./roles/roles_consts";

export interface IUser {
  admin?: boolean;
  allow_password_change?: boolean;
  customer_id?: string;
  email: string;
  fname: string;
  id: number;
  instructor?: boolean;
  lname: string;
  oauth_id?: string;
  parent_email?: string;
  parent_name?: string;
  provider?: "email" | "microsoft_oauth2" | "google_oauth2";
  subscribed?: boolean;
  subscription_end?: string;
  uid?: string;
  image?: string;
  promo?: string | null;
  cancelAtPeriodEnd?: boolean;
  last_sign_in_at?: Date;
  confirmed_at?: Date;
  lastSignUpAt?: Date;
  role_id?: number;
}

export interface IInviteStudent {
  firstName: string;
  lastName: string;
  email: string;
}

interface IRegisterUser {
  firstName: string;
  lastName: string;
  email: string;
  role: IRoles;
  promo?: string | null;
}

export const authApi = {
  registerUser: ({
    firstName,
    lastName,
    email,
    role,
    promo,
  }: IRegisterUser) => {
    return axios.post<{ data: IUser }>("/api/auth", {
      fname: firstName,
      lname: lastName,
      email,
      role,
      promo,
    });
  },

  inviteStudentsApi: (
    students: IInviteStudent[],
    courseId: number,
    subscribed: boolean,
  ) => {
    return axios.post<unknown>("/api/students/bulk", {
      students,
      course_id: courseId,
      subscribed,
    });
  },

  loginUser: ({ email, password }: { email: string; password: string }) => {
    return axios.post<{ data: IUser }>("/api/auth/sign_in", {
      email,
      password,
    });
  },

  loginOauthUser: ({
    token,
    provider,
    page,
    promo,
    default_role,
  }: {
    provider: string;
    token: string;
    page?: string;
    promo?: string | null;
    default_role?: number | null;
  }) => {
    return axios.post<{ data: IUser }>(`/api/oauth/${provider}`, {
      token_id: token,
      page,
      promo,
      role: default_role,
    });
  },

  logoutUser: () => {
    return axios.delete<{ success: boolean }>("/api/auth/sign_out");
  },

  validateToken: () => {
    return axios.get<{ data: IUser }>("/api/auth/validate_token");
  },

  requestPasswordReset: (email: string) => {
    return axios.post<{ data: IUser; message?: string }>("/api/auth/password", {
      email,
    });
  },

  resetPassword: (password: string, passwordConfirmation: string) => {
    return axios.put<{ data: IUser; message?: string }>("/api/auth/password", {
      password,
      password_confirmation: passwordConfirmation,
    });
  },
};
