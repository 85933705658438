import classnames from "classnames";
import {
  setMediaRecordsStateWaiting,
  TMediaRecordingStatuses,
} from "redux/media_recordings/media_recordings_slice";
import { useDispatch, useSelector } from "react-redux";
import { usePopover } from "hooks/use_popover";
import { getMediaRecordingState } from "redux/media_recordings/media_recordings_selectors";
import { getUserIdSelector } from "redux/auth/user_selectors";
import React from "react";
import css from "./feedback_popovers.module.scss";
import { NoteFeedbackPopover } from "../note_feedback_popover";
import { SelectFeedbackPopover } from "../select_feedback_popover";

interface IFeedbackPopovers {
  mediaRecordId: number;
  studentId: number;
  closeFeedbackPopover: (e: React.MouseEvent) => void;
  feedbackPopoverAnchor: Element | null;
}

export const FeedbackPopovers = ({
  mediaRecordId,
  studentId,
  closeFeedbackPopover,
  feedbackPopoverAnchor,
}: IFeedbackPopovers) => {
  const dispatch = useDispatch();

  const teacherId = useSelector(getUserIdSelector);
  const recordingState = useSelector(getMediaRecordingState);

  const handleStopRecord = () => {
    dispatch(setMediaRecordsStateWaiting());
  };

  const openNoteFeedbackPopoverCallback = (e: React.MouseEvent) => {
    closeFeedbackPopover(e);
  };

  const [
    noteFeedbackPopoverAnchor,
    openNoteFeedbackPopover,
    closeNoteFeedbackPopover,
  ] = usePopover({ onOpen: openNoteFeedbackPopoverCallback });

  if (!studentId || !teacherId || !mediaRecordId) {
    console.error({
      studentId,
      teacherId,
      mediaRecordId,
    });
    return null;
  }

  const feedbackBase = {
    studentId,
    teacherId,
    mediaRecordId,
  };
  return (
    <>
      <NoteFeedbackPopover
        closeNoteFeedbackPopover={closeNoteFeedbackPopover}
        noteFeedbackPopoverAnchor={noteFeedbackPopoverAnchor}
        feedbackBase={feedbackBase}
      />
      <SelectFeedbackPopover
        feedbackPopoverAnchor={feedbackPopoverAnchor}
        closeFeedbackPopover={closeFeedbackPopover}
        openNoteFeedbackPopover={openNoteFeedbackPopover}
        feedbackBase={feedbackBase}
      />
      <button
        aria-label="Record"
        type="button"
        className={classnames(css.record_button, {
          [css.record_button_active]:
            recordingState === TMediaRecordingStatuses.RECORDING,
        })}
        onClick={handleStopRecord}
      />
    </>
  );
};
