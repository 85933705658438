import { ChangeEvent, useEffect, useState } from "react";
import { Box, Fab, Tab, Tabs } from "@material-ui/core";
// import SearchBar from "components/reusable/search_bar/search_bar";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { setSongSeedTab } from "redux/songwriting_mobile/songwriting_mobile_slice";
import { getSongSeedTabValue } from "redux/songwriting_mobile/songwriting_mobile_selectors";
import { setUsingMobileAppFlag } from "redux/songwriting/songwriting_slice";
import {
  getCustomSongs,
  getSharedWithMeSongs,
} from "redux/songwriting/songwriting_selectors";
import {
  fetchAudioSeeds,
  fetchSongSeeds,
} from "redux/song_seeds/song_seeds_slice";
import {
  getActiveLyricSeeds,
  getActiveSongSeeds,
  getAudioSeeds,
} from "redux/song_seeds/song_seed_selector";
import { getUserSelector } from "redux/auth/user_selectors";
import { openSignupModal } from "redux/ui/modal/modal_slice";
import { useCreateNewSong } from "hooks/use_create_new_song";
import { getModalType } from "redux/ui/modal/modal_selectors";
import SongCards from "./song_cards/song_cards";
import Seeds from "./seeds/seeds";
import { SongMenu } from "./song_menu/song_menu";

export const MobileSongWriting = () => {
  // const setCurrentSearch = () => {};
  // const onCancelSearch = () => {};
  const dispatch = useDispatch();

  const [songAnchorEl, setSongAnchorEl] = useState<null | HTMLElement>(null);

  const { createSongHandler } = useCreateNewSong();
  const loggedIn = useSelector(getUserSelector);

  dispatch(setUsingMobileAppFlag(true));

  const a11yProps = (index: Number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const songSeedTab = useSelector(getSongSeedTabValue);
  const handleTabChange = (_: ChangeEvent<{}>, newTabValue: Number) => {
    dispatch(setSongSeedTab(newTabValue));
  };

  const createSongClickHandler = async () => {
    createSongHandler();
  };

  const customSongs = useSelector(getCustomSongs);
  const sharedCustomSongs = useSelector(getSharedWithMeSongs);
  const songSeeds = useSelector(getActiveSongSeeds);
  const lyricSeeds = useSelector(getActiveLyricSeeds);
  const audioSeeds = useSelector(getAudioSeeds);
  const openModal = useSelector(getModalType) === "resetPW";

  useEffect(() => {
    dispatch(fetchSongSeeds());
    dispatch(fetchAudioSeeds());
  }, [dispatch]);

  useEffect(() => {
    !loggedIn && !openModal && dispatch(openSignupModal());
  }, [dispatch, loggedIn, openModal]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <SearchBar
        style={{
          borderRadius: "100px",
          fontFamily: "Raleway",
          color: "#5F6367",
          marginBottom: "10px",
          marginTop: "10px",
          width: "90%",
        }}
        placeholder="Search..."
        value=""
        onChange={setCurrentSearch}
        onCancelSearch={onCancelSearch}
      /> */}
      <Tabs
        value={songSeedTab}
        onChange={handleTabChange}
        style={{
          width: "100%",
          fontSize: 18,
          fontWeight: "700",
          color: "#190067",
        }}
      >
        <Tab
          label={`My Songs (${
            (customSongs ? customSongs.length : 0) +
            (sharedCustomSongs ? sharedCustomSongs.length : 0)
          })`}
          {...a11yProps(0)}
          style={{ width: "50%", fontSize: "inherit", fontWeight: "inherit" }}
        />
        <Tab
          label={`My Seeds (${
            (songSeeds ? songSeeds.length : 0) +
            (lyricSeeds ? lyricSeeds.length : 0) +
            (audioSeeds ? audioSeeds.length : 0)
          })`}
          {...a11yProps(1)}
          style={{ width: "50%", fontSize: "inherit", fontWeight: "inherit" }}
        />
      </Tabs>

      {songSeedTab === 1 && <Seeds />}
      {songSeedTab === 0 && (
        <Box>
          <SongCards setSongAnchorEl={setSongAnchorEl} />
          <Fab
            size="large"
            color="primary"
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
            }}
            onClick={createSongClickHandler}
          >
            <AddIcon />
          </Fab>
          <SongMenu
            songAnchorEl={songAnchorEl}
            setSongAnchorEl={setSongAnchorEl}
          />
        </Box>
      )}
    </Box>
  );
};
