import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { createSelector } from "reselect";
import { IState } from "redux/store";
import { ILesson } from "api/lesson_api";
import _ from "lodash";
import {
  getUserLessonsObjSelector,
  getUserLessonsArraySelector,
} from "../user_lessons/user_lesson_selectors";
import { IUserLesson } from "../user_lessons/user_lesson_api";
import { IInstruments } from "../user_skills/user_skills_slice";

const getLessons = (state: IState) => state.entities.lessons;

export const lessonsFetchingSelector = createSelector(
  getLessons,
  (lessons) => lessons.fetching,
);

export const getLessonsOffsetSelector = createSelector(
  getLessons,
  (lessons) => lessons.offset,
);

export const getLessonsHasNextPageSelector = createSelector(
  getLessons,
  (lessons) => lessons.hasNextPage,
);

export const getLessonsCacheSelector = createSelector(
  getLessons,
  (lessons) => lessons.lessonsCache,
);

export const getLessonsArraySelector = createSelector(
  [getLessonsCacheSelector],
  (lessons) => Object.values(lessons),
);

export const getLessonsIdArraySelector = createSelector(
  [getLessonsArraySelector],
  (lessons) => lessons.map((lesson) => lesson.id),
);

export const getLessonsCountByInstrument = (instrument: IInstruments) =>
  createSelector(
    [getLessons],
    (lessons) => lessons.amountLessons[instrument].amount,
  );
export const getLessonsCountSelector = createSelector(
  [getLessons],
  (lessons) =>
    (lessons.amountLessons.guitar.amount || 0) +
    (lessons.amountLessons.ukulele.amount || 0),
);

export const isUnlimitedLessonSelector = createSelector(
  [(state: IState) => state.auth.permissions],
  (userPermissions) => {
    return userPermissions.includes(PERMISSIONS.UNLIMITED_SONG_LESSONS);
  },
);

export const getFilteredLessonsSelector = createSelector(
  [
    getLessonsCacheSelector,
    (state: IState) => state.entities.lessons.filteredLessons,
    getUserLessonsObjSelector,
    isUnlimitedLessonSelector,
  ],
  (lessons, filteredLessons) => {
    const result = filteredLessons || lessons;

    return result;
  },
);

export const getLessonSelector = createSelector(
  [getLessonsCacheSelector, (state: IState, lessonId: number) => lessonId],
  (lessons, lessonId) => {
    return lessons[lessonId];
  },
);

export const getLessonFromLocationSelector = createSelector(
  [
    getLessonsCacheSelector,
    (_: IState, path: string) => path.split("/lesson/")[1],
  ],
  (lessons, lessonId) => lessons[Number(lessonId)],
);

interface IAvailability {
  available: boolean;
  isPurchased: boolean;
  isUsedForFree: boolean;
}
/**
 * Returns lesson availability info

 * @param {Object} lesson
 * @param {Array} userLessonsArr
 * @param {boolean} accessUnlimitedLessons
 * @returns {Object} result
 * @returns {boolean} result.available - returns true if lesson can be started
 * @returns {boolean} result.isPurchased - returns true if lesson purchased (for money, or price was 0)
 * @returns {boolean} result.isUsedForFree - returns true if access is taken with 'use free' functionality
 *                    that allow user to pick 3 any lesson and 'purchase' them
 */
export const isLessonAvailable = (
  lesson: ILesson,
  userLessonsArr: IUserLesson[],
  accessUnlimitedLessons: boolean,
): IAvailability => {
  const foundUserLesson = userLessonsArr.find((userLesson) => {
    if (!userLesson) {
      return false;
    }
    if (!lesson) {
      return false;
    }
    return userLesson.lesson_id === lesson.id;
  });
  const isPurchased =
    !!foundUserLesson && !foundUserLesson.free && !foundUserLesson.subscription;
  const isUsedForFree = !!foundUserLesson && foundUserLesson.free;

  const available =
    accessUnlimitedLessons ||
    isLessonFree(lesson) ||
    userLessonsArr
      .filter((userLesson) => {
        if (userLesson.free) {
          return true;
        }
        return !userLesson.subscription;
      })
      .some((userLesson) => {
        return userLesson.lesson_id === lesson.id;
      });
  return {
    available,
    isPurchased,
    isUsedForFree,
  };
};

// interface ILessonWithAvailability extends IAvailability, ILesson {}

export const isLessonAvailableSelector = (lessonId: number) =>
  createSelector(
    getUserLessonsArraySelector,
    (state: IState) => state,
    isUnlimitedLessonSelector,
    (userLessonsArr, state, accessUnlimitedLessons) => {
      const lesson = getLessonSelector(state, lessonId);
      return isLessonAvailable(lesson, userLessonsArr, accessUnlimitedLessons)
        .available;
    },
  );

/**
 * Returns lessons that user purchased for free instead of paying.
 * Every user can select any 3 free lessons to buy for free.
 */
export const getPurchasedForFreeLessonsSelectors = createSelector(
  [getLessonsCacheSelector, getUserLessonsObjSelector],
  (lessons, userLessons) => {
    const userLessonsArr = Object.values(userLessons);

    return userLessonsArr
      .filter((userLesson) => userLesson.free)
      .map((userLesson) => {
        return lessons[userLesson.lesson_id];
      });
  },
);

/**
 * Returns lessons that cost 0
 */
export const getFreeLessonSelectors = createSelector(
  [getLessonsCacheSelector],
  (lessons) => {
    return Object.values(lessons).filter((lesson) => {
      return parseFloat(lesson.price) === 0;
    });
  },
);

const isLessonFree = (lesson: ILesson) => {
  return parseFloat(lesson?.price) === 0;
};

export const getLessonById = (id: number) =>
  createSelector(getLessonsCacheSelector, (lessons) => lessons[id]);

export const getLessonKeys = createSelector(
  getLessons,
  (lessons) => lessons.lessonKeys,
);

export const getSongKeys = createSelector(
  getLessons,
  (lessons) => lessons.songKeys,
);

export const getMelodyLessonsCountByInstrument = (instrument: IInstruments) =>
  createSelector(
    getLessons,
    (lessons) =>
      lessons.amountLessons[instrument].skills?.find(
        (skill) => skill.name === "Melody",
      )?.amountLessons || 0,
  );

export const getStrummingLessonsCountByInstrument = (
  instrument: IInstruments,
) =>
  createSelector(
    getLessons,
    (lessons) =>
      lessons.amountLessons[instrument].skills?.find(
        (skill) => skill.name === "Strumming",
      )?.amountLessons || 0,
  );

/*
 * Responsible to return lessons that should be displayed on `My Songs` page. Criterias:
 *  1) Purchased (for money or for free)
 *  2) Finished (no matter are they still available or not)
 */
export const getMyLessonsSelector = createSelector(
  [
    getLessonsCacheSelector,
    getUserLessonsObjSelector,
    getFreeLessonSelectors,
    isUnlimitedLessonSelector,
  ],
  (lessons, userLessons, freeLessons, accessUnlimitedLessons) => {
    const userLessonsArr = Object.values(userLessons);
    const myLessons = userLessonsArr
      .map((userLesson) => {
        const lesson = lessons[userLesson.lesson_id];
        const availability = isLessonAvailable(
          lesson,
          userLessonsArr,
          accessUnlimitedLessons,
        );

        return {
          ...lesson,
          ...availability,
        };
      })
      .filter((i) => !!i);

    return _.uniqBy(freeLessons.concat(myLessons), (lesson) => lesson.id);
  },
);

/**
 * returns array with completed users lessons
 */
export const getUserCompletedLessons = createSelector(
  [getMyLessonsSelector, getUserLessonsArraySelector],
  (userSongs, userSongsCompleted) => {
    const userCompletedLessonIds = userSongsCompleted
      .filter((song) => song.progress === 100)
      .map((song) => song.lesson_id);

    return userSongs.filter((song) => userCompletedLessonIds.includes(song.id));
  },
);

export const getUserCompletedLessonsByInstrument = (instrument: IInstruments) =>
  createSelector(
    [getMyLessonsSelector, getUserLessonsArraySelector],
    (userSongs, userSongsCompleted) => {
      const userCompletedLessonIds = userSongsCompleted
        .filter((song) => song.progress === 100)
        .map((song) => song.lesson_id);

      return userSongs.filter(
        (song) =>
          userCompletedLessonIds.includes(song.id) &&
          song.instrument.toLocaleLowerCase() === instrument,
      );
    },
  );
