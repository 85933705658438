import { Popover } from "@material-ui/core";
import cn from "classnames";
import { getUserAllSkills } from "redux/entities/user_skills/user_skills_selector";
import { useSelector } from "react-redux";
import { ISlide } from "api/lesson_api";
import css from "./slide_top_popover.module.scss";

interface ISlideTopPopover {
  slides: ISlide[];
  goToSlide: (_: number) => void;
  currentSlide: number | null;
  handleCloseSelect: () => void;
  selectAnchor: HTMLButtonElement | null;
}

export const SlideTopPopover = ({
  selectAnchor,
  handleCloseSelect,
  slides = [],
  currentSlide,
  goToSlide,
}: ISlideTopPopover) => {
  let slidesByPart: ISlide[][] = [];
  let partSliders: ISlide[] = [];
  // eslint-disable-next-line array-callback-return
  slides.some((slide, index) => {
    if (slide.slide_type === "part_begin" && index !== 0) {
      slidesByPart = [...slidesByPart, partSliders];
      partSliders = [];
    } else if (slides.length === index + 1) {
      const lastPart = [...partSliders, slide];
      slidesByPart = [...slidesByPart, lastPart];
    }
    partSliders = [...partSliders, slide];
  });

  const topPosition =
    Number(selectAnchor?.offsetTop) + Number(selectAnchor?.clientHeight);
  const leftPosition = window.innerWidth / 2;

  const userAllSkills = useSelector(getUserAllSkills);
  const doesUserKnowSkill = (skill: string) => userAllSkills.includes(skill);

  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: topPosition, left: leftPosition }}
      keepMounted
      open={Boolean(selectAnchor)}
      onClose={handleCloseSelect}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      disableScrollLock={false}
    >
      <div className={css.container}>
        <div className={css.header}>
          <div className={css.header_content}>
            <div className={css.green_dot} />
            Known Skills
          </div>
          <div className={css.header_content}>
            <div className={css.gray_dot} />
            Unknown Skills
          </div>
        </div>
        <hr />
        {slidesByPart.map((slides, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={css.part_container}>
              <button
                type="button"
                onClick={() => {
                  goToSlide(slides[0].slide_num - 1);
                }}
                className={css.part_header}
              >
                Part {i + 1}
              </button>
              <div className={css.subtext}>{slides[0].text_middle}</div>
              <div>
                {slides
                  .filter(
                    (slide) =>
                      (slide.slide_type === "teach" && slide.chord) ||
                      slide.slide_type === "teach_pluck",
                  )
                  .map((slide) => {
                    return (
                      <button
                        type="button"
                        onClick={() => goToSlide(slide.slide_num - 1)}
                        key={slide.slide_num}
                        className={cn(css.skill_button, {
                          [css.skill_button_completed]: doesUserKnowSkill(
                            slide.chord,
                          ),
                          [css.skill_button_active]:
                            slide.slide_num - 1 === currentSlide,
                        })}
                      >
                        {getSkillText(slide.chord)}
                      </button>
                    );
                  })}
              </div>
            </div>
          );
        })}
        <div className={css.part_container}>
          <button
            type="button"
            onClick={() => {
              const lastSlidesPart = slidesByPart[slidesByPart.length - 1];
              const lastSlide = lastSlidesPart[lastSlidesPart.length - 1];
              goToSlide(lastSlide.slide_num);
            }}
            className={css.part_header}
          >
            End
          </button>
        </div>
      </div>
    </Popover>
  );
};

export function getSkillText(text: string) {
  try {
    const skills = JSON.parse(text);
    return skills.join("-");
  } catch (e) {
    return text;
  }
}
