import { PersistState, createMigrate } from "redux-persist";
import {
  ISongwritingState,
  initialState,
} from "./songwriting/songwriting_slice";

type Root = {
  songwriting: ISongwritingState;
} & {
  _persist: PersistState;
};

const migrate = createMigrate(
  {
    2: (state): Root | undefined => {
      if (!state) {
        return state;
      }
      const { ...others } = state as Root;
      return {
        ...others,
        songwriting: initialState,
      };
    },
  },
  { debug: true },
);

const VERSION = 2;

export { migrate, VERSION };
