// when change PERMISSIONS & ROLES, you will need also change const PERMISSIONS & ROLES in rails

export const ROLES = {
  FREE_USER: "FREE_USER",
  INDIVIDUAL: "INDIVIDUAL",

  FREE_TEACHER_STUDENT: "FREE_TEACHER_STUDENT",
  PAID_TEACHER_STUDENT: "PAID_TEACHER_STUDENT",

  SOLO_TEACHER: "SOLO_TEACHER",
  FREE_TEACHER_ACCOUNT: "FREE_TEACHER_ACCOUNT",
  PAID_TEACHER_ACCOUNT: "PAID_TEACHER_ACCOUNT",

  DISTRICT_ADMINISTRATOR: "DISTRICT_ADMINISTRATOR",
  ADMIN: "ADMIN",

  FREE_SONGWRITING: "FREE_SONGWRITING",
  PAID_SONGWRITING: "PAID_SONGWRITING",
} as const;

export const ROLE_IDS = {
  FREE_USER: 1,
  INDIVIDUAL: 2,

  FREE_TEACHER_STUDENT: 3,
  PAID_TEACHER_STUDENT: 4,

  SOLO_TEACHER: 5,
  FREE_TEACHER_ACCOUNT: 6,
  PAID_TEACHER_ACCOUNT: 7,

  DISTRICT_ADMINISTRATOR: 8,
  ADMIN: 9,

  FREE_SONGWRITING: 10,
  PAID_SONGWRITING: 11,
};

export const TEACHER_COST = {
  [ROLES.SOLO_TEACHER]: "179",
  [ROLES.PAID_TEACHER_ACCOUNT]: "17", // per student
  [ROLES.FREE_TEACHER_ACCOUNT]: "0",
} as const;

const testData = {
  COST_0_99: {
    LIMIT: 100,
    PRICE: 19,
    PRICE_ID: "price_1JpTp7IDGw03DOx583ROuoW7",
  },
  COST_100_499: {
    LIMIT: 500,
    PRICE: 15.5,
    PRICE_ID: "price_1JpTp7IDGw03DOx5aisByzN5",
  },
  COST_MORE_500: {
    PRICE: 14,
    PRICE_ID: "price_1JpTp7IDGw03DOx57PpK7ht3",
  },
};

const prodData = {
  COST_0_99: {
    LIMIT: 100,
    PRICE: 19,
    PRICE_ID: "price_1JvLOPIDGw03DOx59ktyfq9s",
  },
  COST_100_499: {
    LIMIT: 500,
    PRICE: 15.5,
    PRICE_ID: "price_1JvLOPIDGw03DOx5Uh6CXARW",
  },
  COST_MORE_500: {
    PRICE: 14,
    PRICE_ID: "price_1JvLOPIDGw03DOx5DmDIDrqK",
  },
};

export const PAID_TEACHER_SUBSCRIPTION =
  process.env.REACT_APP_NODE_ENV === "production" ? prodData : testData;

export const TEACHER_ROLES: string[] = [
  ROLES.SOLO_TEACHER,
  ROLES.FREE_TEACHER_ACCOUNT,
  ROLES.PAID_TEACHER_ACCOUNT,
];

export type ITeacherRoles = keyof typeof TEACHER_COST;
export type IRoles = keyof typeof ROLES;
