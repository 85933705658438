import { userTimeApi } from "../api/user_session_api";

const intervalMs = 10000;
const USER_TIME = "USER_TIME";

const maxInactivityInMinutes = 8;
class Timer {
  timerId = 0;

  maxInactivityInSeconds = 60 * maxInactivityInMinutes;

  secondsSinceLastActivity = 0;

  constructor() {
    const timerObj = this;
    setInterval(() => {
      timerObj.secondsSinceLastActivity++;
    }, 1000);

    const activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];

    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, resetActivityTimer);
    });
    function resetActivityTimer() {
      timerObj.secondsSinceLastActivity = 0;
    }
  }

  createTimer = (userId: number) => {
    clearInterval(this.timerId);

    this.timerId = window.setInterval(async () => {
      if (this.secondsSinceLastActivity > this.maxInactivityInSeconds) {
        return;
      }

      const currentTime = Number(localStorage.getItem(USER_TIME)) || 0;

      let newTime = currentTime + 10;
      if (newTime >= 60) {
        try {
          const minutes = Math.floor(newTime / 60);
          await userTimeApi.addTodayTime(userId, new Date(), minutes);
          newTime = 0;
        } catch (_err) {
          console.error(_err);
        }
      }

      localStorage.setItem(USER_TIME, `${newTime}`);
    }, intervalMs);
  };

  clearTimer = () => {
    clearInterval(this.timerId);
    this.timerId = 0;
    return this.timerId;
  };
}

export const timer = new Timer();

function activityWatcher() {}

activityWatcher();
