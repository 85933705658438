import { useDispatch } from "react-redux";
// import { openUserInfoModal } from "actions/modal_actions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { totalTimeInHours } from "components/my_journey/my_journey";
import { IUserSession } from "api/user_session_api";
import { openUserInfoModal } from "redux/ui/modal/modal_slice";
import { getThisWeekMinutes } from "../helpers/get_this_week_minutes";
import css from "./student_row_mobile.module.scss";

interface IStudentRowMobile {
  student: {
    fname: string;
    lname: string;
    songsLearned?: number;
    skillsLearnedPercentage?: number;
    user_sessions: IUserSession[];
  };
}

export const StudentRowMobile = (props: IStudentRowMobile) => {
  const dispatch = useDispatch();
  const {
    student: {
      fname: firstName,
      lname: lastName,
      songsLearned,
      skillsLearnedPercentage,
      user_sessions: userSessions,
    },
  } = props;

  const thisWeekMinutes = getThisWeekMinutes(userSessions);

  return (
    <button
      className={css.row_mobile}
      onClick={() => dispatch(openUserInfoModal(props.student))}
      type="button"
    >
      <div>
        <strong className={css.strong}>
          {firstName} {lastName}
        </strong>
      </div>
      <em className={css.student_skills}>
        <strong className={css.strong}> {skillsLearnedPercentage}% </strong>
        skills learned,
        <strong className={css.strong}> {songsLearned} </strong> songs learned,
        <strong className={css.strong}>
          {totalTimeInHours(thisWeekMinutes)}
        </strong>
        practice time
      </em>
      <div className={css.open_arrow_mobile}>
        <ArrowForwardIosIcon fontSize="small" />
      </div>
    </button>
  );
};
