const DISMISSED_LESSONS_STORAGE_KEY = "completionReminderDismissed";

export const isLessonDismissed = (lessonId: number): boolean => {
  const dismissedLessons = localStorage.getItem(DISMISSED_LESSONS_STORAGE_KEY);

  const dismissedLessonsArray: string[] = dismissedLessons
    ? JSON.parse(dismissedLessons)
    : [];
  return dismissedLessonsArray.includes(String(lessonId));
};

export const dismissLesson = (lessonId: number): void => {
  const dismissedLessons = localStorage.getItem(DISMISSED_LESSONS_STORAGE_KEY);

  if (dismissedLessons) {
    const dismissedLessonsArray = JSON.parse(dismissedLessons);
    dismissedLessonsArray.push(String(lessonId));
    localStorage.setItem(
      DISMISSED_LESSONS_STORAGE_KEY,
      JSON.stringify(dismissedLessonsArray),
    );
  } else {
    const newDismissedArray: string[] = [];
    newDismissedArray.push(String(lessonId));
    localStorage.setItem(
      DISMISSED_LESSONS_STORAGE_KEY,
      JSON.stringify(newDismissedArray),
    );
  }
};
