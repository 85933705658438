import { Dialog } from "@material-ui/core";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { format, addMinutes } from "date-fns";
import { getUserSkillsCount } from "redux/general_selectors/skills_selector";
import { totalTimeSelector } from "redux/entities/my_journey/my_journey_selectors";
import { getUserCompletedLessons } from "redux/entities/lessons/lesson_selectors";
import thumbImg from "./thumb.svg";
import timeImg from "./time.svg";
import noteImg from "./note.svg";
import guitarImg from "./guitar.svg";
import { StudentConversationDialogStat } from "./student_conversation_dialog_stat";
import { StudentConversationEmail } from "./student_conversation_email";
import { useStudentConversationDialog } from "./use_student_conversation_dialog";

export const StudentConversationDialog = () => {
  const skillsCount = useSelector(getUserSkillsCount);
  const songsLearned = useSelector(getUserCompletedLessons).length;
  const totalTime = useSelector(totalTimeSelector);

  const {
    // conversationDialog,
    closeStudentConversationDialog,
  } = useStudentConversationDialog();
  let date = new Date(0);
  date.setHours(0);

  date = addMinutes(date, totalTime);
  const timeValue = format(date, "H 'hrs &' m 'min'");
  // todo: enable when next school year ends
  const open = false; // conversationDialog
  return (
    <Dialog open={open} onClose={closeStudentConversationDialog}>
      <Container>
        <MainImgContainer>
          <MainImg src={thumbImg} />
        </MainImgContainer>
        <Header>Save your skills!</Header>
        <Text>
          You’ve learned so much! Don’t lose what you started and save your data
          by entering your personal email. We’re required to remove data linked
          to school email addresses at the end of the school year.
        </Text>
        <Stats>
          <StudentConversationDialogStat
            imgSrc={guitarImg}
            title={`${skillsCount}`}
            text="Skills You Achieved"
          />
          <StudentConversationDialogStat
            imgSrc={noteImg}
            title={`${songsLearned}`}
            text="Songs Learned"
          />
          <StudentConversationDialogStat
            imgSrc={timeImg}
            title={timeValue}
            text="Practice Time"
          />
        </Stats>
        <StudentConversationEmail
          buttonText="Save my Skills"
          closeDialog={closeStudentConversationDialog}
        />
      </Container>
    </Dialog>
  );
};

const Container = styled.section`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainImgContainer = styled.div`
  background: rgba(103, 117, 222, 0.26);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainImg = styled.img``;

const Header = styled.h1`
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #190067;
  margin-top: 24px;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  text-align: center;
  margin-top: 12px;
`;

const Stats = styled.ul`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
