import { useState } from "react";
import useRecorder from "hooks/use_recorder";
import { RecordButton } from "../record_button/record_button";

import css from "./record_controls.module.scss";

export const RecordControls = () => {
  const { recorderState, startRecording, saveRecording } = useRecorder();
  const { initRecording, recordingMinutes, recordingSeconds } = recorderState;
  const [recordButtonActive, setRecorderButtonActive] =
    useState<boolean>(false);

  const processClick = () => {
    setRecorderButtonActive(!recordButtonActive);
    if (initRecording) {
      saveRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className={css.record_wrapper}>
      {initRecording && (
        <>
          <p className={css.default_title_recording}>New Recording</p>
          <RecordButton
            recorderState={recorderState}
            recordButtonActive={recordButtonActive}
            processClick={processClick}
          />
          <div className={css.recording_time}>
            <span>{recordingMinutes}</span>
            <span>:</span>
            <span>
              {recordingSeconds < 10
                ? `0${recordingSeconds}`
                : recordingSeconds}
            </span>
          </div>
        </>
      )}

      {!initRecording && (
        <>
          <p className={css.title_start_recording}>
            Tap the button to start recording
          </p>
          <RecordButton
            recorderState={recorderState}
            recordButtonActive={recordButtonActive}
            processClick={processClick}
          />
          <p style={{ visibility: "hidden" }}>Hidden Placeholder Text</p>
        </>
      )}
    </div>
  );
};
