import { useDispatch } from "react-redux";
import cn from "classnames";
import { onMoosikoBtnClick } from "redux/ui/modal/modal_slice";
import { onSkillClick } from "redux/entities/user_skills/user_skills_slice";
import css from "./cards_content.module.scss";
import { createSkills } from "../../reusable/skill";

interface IUserSkills {
  userSkills: string[];
  allSkills: string[];
  topText?: string;
  bottomText?: string;
  learnText?: string;
  skillType: string;
  clickable?: boolean;
}

export const UserSkills = (props: IUserSkills) => {
  const {
    userSkills,
    allSkills,
    topText,
    bottomText,
    learnText,
    skillType,
    clickable = true,
  } = props;
  const notLearned = allSkills.filter((skill) => !userSkills.includes(skill));
  const dispatch = useDispatch();

  return (
    <div className={cn(css.wrapper)}>
      <div className={css.text}>{topText}</div>
      <div className={cn(css.skills_wrapper)}>
        {createSkills(
          userSkills,
          "#00ba9b",
          clickable,
          skillType,
          onSkillClick,
        )}
      </div>
      {notLearned.length && (
        <>
          <div className={css.text}>{bottomText}</div>
          <div className={cn(css.skills_wrapper)}>
            {createSkills(
              notLearned,
              "#d9d9d9",
              clickable,
              skillType,
              onSkillClick,
            )}
          </div>
        </>
      )}
      {learnText && (
        <button
          className={cn(css.learnNew)}
          type="button"
          onClick={() => {
            dispatch(onMoosikoBtnClick());
          }}
        >
          {learnText}
        </button>
      )}
    </div>
  );
};
