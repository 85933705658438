import classNames from "classnames";
import { format } from "date-fns";
import { IOrgSong } from "redux/songwriting/songwriting_interfaces";
import { AssessmentSong } from "./songwriting_assessment_song";
import css from "../../assessment/assessment_row/assessment_row.module.scss";

interface ISongwritingAssessmentRow {
  record: IOrgSong;
}

const protectedStatus = (orgSong: IOrgSong) => {
  const anyLyrics = orgSong.custom_song_lyrics.some((songSection) => {
    return songSection.song_lyrics;
  });

  const anyChords = orgSong.custom_song_lyrics.some((songSection) => {
    return songSection.song_chords;
  });

  if (anyChords && anyLyrics) {
    return true;
  }
  return false;
};

export const SongwritingAssessmentRow = ({
  record,
}: ISongwritingAssessmentRow) => {
  return (
    <div className={css.container}>
      <div className={classNames(css.name, css.row_item)}>
        {record.user && record.user.fname} {record.user && record.user.lname}
      </div>
      <div className={css.row_item}>{record.song_name}</div>
      <div className={css.row_item}>
        {format(new Date(record.createdAt), "MMMM d, yyyy")}
      </div>
      <div className={css.row_item}>
        <AssessmentSong
          song={record.id}
          protectedSong={protectedStatus(record)}
        />
      </div>
    </div>
  );
};
