import { RefObject, ReactNode, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import { closeModal as closeModalAction } from "redux/ui/modal/modal_slice";
import css from "./modal.module.scss";

interface IModalContainerComponent {
  closeAction?: (event: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  mainRef?: RefObject<HTMLDivElement>;
  classBG?: string;
  classMain?: string;
  classContainer?: string;
  noCloseButton?: boolean;
  propagate?: boolean;
  children?: ReactNode;
  classes?: { main: string };
  closeButtonLight?: boolean;
}

export const ModalContainer = (props: IModalContainerComponent) => {
  const {
    closeAction,
    mainRef,
    classBG,
    classMain,
    classContainer,
    noCloseButton,
    propagate,
    children,
    classes,
    closeButtonLight,
  } = props;

  const dispatch = useDispatch();
  const closeModal = () => dispatch(closeModalAction());
  return (
    <div
      role="button"
      tabIndex={0}
      className={classBG || css.background}
      onClick={closeAction || closeModal}
    >
      <div
        ref={mainRef}
        className={
          classMain
            ? classnames(classMain, classes?.main)
            : classnames(css.main, classes?.main)
        }
        onClick={propagate ? undefined : (e) => e.stopPropagation()}
      >
        {noCloseButton ? null : (
          <button
            type="button"
            onClick={closeAction || closeModal}
            className={closeButtonLight ? css.closeLight : css.close}
          >
            &times;
          </button>
        )}
        <div id="modalScrollLock" className={classContainer || css.container}>
          {children}
        </div>
      </div>
    </div>
  );
};
