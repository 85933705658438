import { ISlide } from "api/lesson_api";
import { AlphaTab } from "components/reusable/alpha_tab/AlphaTab";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLessonsCacheSelector } from "redux/entities/lessons/lesson_selectors";
import css from "./slide_types.module.scss";

interface IMelody {
  slide: ISlide;
  isSlideActive: boolean;
}

export const ukuleleMelodyTuning = "A4 E4 C4 G4";

export const Melody = ({
  slide: { alpha_tab_code },
  isSlideActive,
}: IMelody) => {
  const { id } = useParams<{ id: string }>();

  const lesson = useSelector(getLessonsCacheSelector)[id];

  let tuning = "";

  lesson.instrument === "Ukulele" ? (tuning = ukuleleMelodyTuning) : "";

  return (
    <div className={css.main}>
      <div className={css.text_top}>
        Using a pick, play the following melody
      </div>
      <div className={css.mid}>
        <AlphaTab
          condition={isSlideActive}
          textCode={alpha_tab_code}
          tempo={lesson.song.tempo}
          timeSig={lesson.time_signature}
          tuning={tuning}
        />
      </div>
      <div className={css.text_bot}>
        Play 10 times, take a 1 minute break, play another 10 times
      </div>
    </div>
  );
};
