import cn from "classnames";

import { ISlide } from "api/lesson_api";
import css from "./slide_types.module.scss";
import { AudioPlayer } from "../../audioplayer/AudioPlayer";

const FIRST_AUDIO_ASSET_NUMBER = 2;
const SECOND_AUDIO_ASSET_NUMBER = 2;

interface IStrummingStatic2Audios {
  slide: ISlide;
}

export const StrummingStatic2Audios = ({ slide }: IStrummingStatic2Audios) => {
  const { text_top, text_bottom, assets = [] } = slide;
  return (
    <div className={css.main}>
      <div className={css.text_top}>{text_top}</div>
      <div className={css.mid_audios}>
        <div className={css.img_audio}>
          <img
            src={assets[1].url}
            className={css.strumming_img}
            alt="Strumming pattern"
          />
        </div>
        <div className={css.tp_audio}>
          <div className={css.audio_player}>
            <div className={cn(css.audio_text)}>
              Downstroke – Play all strings in the chord on downstroke
            </div>
            <AudioPlayer src={assets[FIRST_AUDIO_ASSET_NUMBER].url} />
            <div className={css.audio_text}>
              Upstroke – Ok to play just bottom 3 or 4 strings on upstroke
            </div>
            <AudioPlayer src={assets[SECOND_AUDIO_ASSET_NUMBER].url} />
          </div>
        </div>
      </div>
      <div className={css.text_bot}>{text_bottom}</div>
    </div>
  );
};
