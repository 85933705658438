/* eslint-disable no-unused-vars */
import { useContext } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { getChordsSelector } from "redux/entities/chords/chords_selectors";
import { CancelTokenSource } from "axios";
import { getUserChordSkillsSelector } from "redux/entities/user_skills/user_skills_selector";
import { CancelTokenContext } from "context/cancel_token";
import { CHORD } from "constants/skills_constants";
import songCSS from "../../songs/song.module.scss";
import css from "./chords_skillset.module.scss";

interface IChordsSkillset {
  classes?: {
    chord: string;
  };
  onChordClick: ({
    skillType,
    skillName,
    learned,
    cancelToken,
  }: {
    skillType: string;
    skillName: string;
    learned: boolean;
    cancelToken?: CancelTokenSource | null;
  }) => void;
}
/**
 *  Renders an element with all chords, on which user can select what chords he already know
 */
export const ChordsSkillset = (props: IChordsSkillset) => {
  const { onChordClick, classes } = props;
  const chords = useSelector(getChordsSelector);
  const userChords = useSelector(getUserChordSkillsSelector);
  const { cancelToken } = useContext(CancelTokenContext);
  return (
    <div className={css.chord_skill_list}>
      {chords.map((chord) => {
        const { id, name } = chord;
        const learned = userChords.includes(name);
        return (
          <button
            type="button"
            className={classnames(
              songCSS.card,
              css.chord_skill,
              classes?.chord,
              {
                [css.chord_skill_unknown]: !learned,
              },
            )}
            key={id}
            onClick={async () => {
              if (onChordClick) {
                const result = await onChordClick({
                  skillType: CHORD,
                  skillName: name,
                  learned: !learned,
                  cancelToken: cancelToken.current,
                });

                cancelToken.current = (
                  result as unknown as {
                    payload: CancelTokenSource | null;
                  }
                ).payload;
              }
            }}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
};
