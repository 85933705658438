import { useMemo } from "react";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { getLessonUrl } from "util/url_util";
import { ReactComponent as Chord } from "img/songs/chord.svg";
import { ReactComponent as Strum } from "img/songs/strum.svg";
import { ReactComponent as Melody } from "img/songs/melody.svg";
import { ReactComponent as NewChord } from "img/songs/newChord.svg";
import { ReactComponent as NewMelody } from "img/songs/newMelody.svg";
import { ReactComponent as Completed } from "img/songs/completed.svg";
import { isFree } from "util/songs_helpers";
import { ILesson } from "api/lesson_api";
import { uniq } from "lodash";
import {
  isLessonAvailable,
  isUnlimitedLessonSelector,
} from "redux/entities/lessons/lesson_selectors";
import { useSelector } from "react-redux";
import {
  getUserLessonsArraySelector,
  isLessonFinished,
  getSongProgress,
} from "redux/entities/user_lessons/user_lesson_selectors";
import {
  SongCardContent,
  SongTextInfo,
  SongName,
  SongArtist,
  SkillTypes,
  SkillType,
  SongProgressInfo,
  SongProgressBackLine,
  SongProgressFrontLine,
  SongProgressStatus,
  SongCardWrapper,
} from "./new_all_songs_page/all_songs_styled_components";
import { CardHover } from "./CardHover/CardHover";
import css from "./song.module.scss";

const svgMap = {
  "Basic Chords": Chord,
  Strumming: Strum,
  Melody,
};

const newSvgMap = {
  "Basic Chords": NewChord,
  Melody: NewMelody,
};

interface ISongCard {
  lesson: ILesson;
  isNew?: boolean;
  isWide?: boolean;
}

export const SongCard = (props: ISongCard) => {
  const { lesson, isNew, isWide } = props;
  const { id, skills, instrument } = lesson;
  const { song_name, artist } = lesson.song;
  const skillTypes = useMemo(() => uniq(skills.map((st) => st.name)), [skills]);

  let isLessonChecked = false;
  const userLessons = useSelector(getUserLessonsArraySelector);
  const accessUnlimitedLessons = useSelector(isUnlimitedLessonSelector);

  const { isPurchased, isUsedForFree } = isLessonAvailable(
    lesson,
    userLessons,
    accessUnlimitedLessons,
  );

  const userLesson = userLessons.find(
    (userLesson) => userLesson.lesson_id === lesson.id,
  );

  const finished = !!userLesson && isLessonFinished(userLesson);
  const lessonProgress =
    !!userLesson && Math.floor(getSongProgress(userLesson));

  if (isPurchased || isUsedForFree || finished || isFree(lesson)) {
    isLessonChecked = true;
  }

  return (
    <Link to={getLessonUrl(id)} className={isNew ? css.newcard : css.card}>
      {isNew ? (
        <>
          {" "}
          <CardHover lesson={lesson} isNew />
          <SongCardWrapper isWide={isWide}>
            <SongCardContent isWide={isWide}>
              <SongTextInfo>
                <SongName>{song_name}</SongName>
                <SongArtist>{artist}</SongArtist>
              </SongTextInfo>

              <SkillTypes isFinished={finished} isWide={isWide}>
                {skillTypes.map((st, index) => {
                  // todo: revisit what we have in skill_type
                  // @ts-ignore
                  const key = st + index;
                  const Svg: any = newSvgMap[st];
                  if (!Svg) {
                    return null;
                  }
                  return (
                    <SkillType key={key}>
                      <Svg />
                    </SkillType>
                  );
                })}
              </SkillTypes>
            </SongCardContent>
            {lessonProgress && (
              <SongProgressInfo>
                <SongProgressBackLine>
                  <SongProgressFrontLine progress={lessonProgress.toString()} />
                </SongProgressBackLine>
                <SongProgressStatus isFinished={finished}>
                  {`${lessonProgress}%`}
                </SongProgressStatus>
                {finished && <Completed />}
              </SongProgressInfo>
            )}
          </SongCardWrapper>
        </>
      ) : (
        <>
          <CardHover lesson={lesson} />
          <div>
            <div className={css.card_top}>
              <div className={css.name}>
                {song_name} | <p className={css.instrument}> {instrument} </p>
              </div>
            </div>
            <div className={css.artist}>{artist}</div>
          </div>
          <div className={css.skill_types}>
            {skillTypes.map((st, index) => {
              // todo: revisit what we have in skill_type
              // @ts-ignore
              const key = st + index;
              const Svg: any = svgMap[st];
              if (!Svg) {
                return null;
              }
              return (
                <div key={key} className={css.skill_type}>
                  <Svg />
                </div>
              );
            })}
          </div>

          {isLessonChecked && (
            <CheckIcon style={{ position: "absolute", right: 16 }} />
          )}
        </>
      )}
    </Link>
  );
};
