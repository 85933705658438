import { useDispatch, useSelector } from "react-redux";
import {
  totalTimeSelector,
  thisWeekMinutesSelector,
  minutesPerDaySelector,
} from "redux/entities/my_journey/my_journey_selectors";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { getUserCourses } from "redux/user_courses/user_courses_selectors";
import {
  getCurrentInstrument,
  getUserChordsByInstrument,
  getUserMelodiesSongsByInstrument,
  getUserProgressionsByInstrument,
  getUserStrummingSongsByInstrument,
  getUserTransitionsByInstrument,
} from "redux/entities/user_skills/user_skills_selector";
import { getChordsByInstrumentLikeString } from "redux/entities/chords/chords_selectors";
import { getTransitionsLikeStringByInstrument } from "redux/entities/transitions/transitions_selectors";
import { getProgressionsByInstrumentLikeString } from "redux/entities/progressions/progressions_selectors";
import {
  getLessonsCountByInstrument,
  getUserCompletedLessonsByInstrument,
} from "redux/entities/lessons/lesson_selectors";
import { fetchMyJourneyData } from "redux/entities/my_journey/my_journey_slice";
import { getUserMediaRecordsWithFeedback } from "redux/feedback/feedback_selectors";
import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import { useEffect } from "react";
import { strummingCount, melodiesCount } from "./skill_helpers";
import { UserView } from "./user_view/user_view";
import { IChartWithAside } from "./i_chart_with_aside";
import { IUserSkillsData } from "./user_skills";

export const MyJourney = () => {
  const totalTime = useSelector(totalTimeSelector);
  const thisWeekMinutes = useSelector(thisWeekMinutesSelector);

  const userId = useSelector(getUserIdSelector);
  const minutesPerDay = useSelector(minutesPerDaySelector);

  const userCourses = useSelector(getUserCourses);
  const students = (userCourses.length && userCourses[0].users) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(fetchMyJourneyData({ userId }));
    }
  }, [dispatch, userId]);

  const mediaRecordsWithFeedbacks = useSelector(
    getUserMediaRecordsWithFeedback,
  );

  const currentInstrument = useSelector(getCurrentInstrument);

  const userChords = Object.values(
    useSelector(getUserChordsByInstrument(currentInstrument)),
  ).sort();

  const chords = useSelector(
    getChordsByInstrumentLikeString(currentInstrument),
  ).sort();

  const userProgressions = useSelector(
    getUserProgressionsByInstrument(currentInstrument),
  )
    .map((progression) => JSON.parse(progression).join("-"))
    .sort();
  const progressions = useSelector(
    getProgressionsByInstrumentLikeString(currentInstrument),
  );

  const userTransitions = useSelector(
    getUserTransitionsByInstrument(currentInstrument),
  )
    .map((progression) => JSON.parse(progression).join("-"))
    .sort();
  const transitions = useSelector(
    getTransitionsLikeStringByInstrument(currentInstrument),
  );

  const userStrummingSongs = useSelector(
    getUserStrummingSongsByInstrument(currentInstrument),
  ) as IUserStrummingLessons;

  const strummingSkillsCount = strummingCount(userStrummingSongs);

  const userMelodiesSongs = useSelector(
    getUserMelodiesSongsByInstrument(currentInstrument),
  );
  const melodySkillsCount = melodiesCount(userMelodiesSongs);

  const userCompletedSongs = useSelector(
    getUserCompletedLessonsByInstrument(currentInstrument),
  );
  const songsCount = useSelector(
    getLessonsCountByInstrument(currentInstrument),
  );

  if (!userId || !userStrummingSongs || !songsCount) {
    return null;
  }

  const skillsData: IUserSkillsData = {
    chords,
    progressions,
    transitions,
    userChords,
    userStrummingSongs,
    userProgressions,
    userTransitions,
    melodySkillsCount,
    strummingSkillsCount,
    userCompletedSongs,
    userMelodiesSongs,
    songsCount,
  };

  const chartData: IChartWithAside = {
    minutesPerDay,
    totalTime,
    thisWeekMinutes,
    userId,
    students,
  };

  return (
    <UserView
      mediaRecordsWithFeedbacks={mediaRecordsWithFeedbacks}
      skills={skillsData}
      chart={chartData}
      showProgress
      clickable
    />
  );
};

const MINUTES_IN_HOUR = 60;
/**
 * transforms time in `#Hours #Minutes`
 * @param {number} minutes
 */
export const totalTimeInHours = (minutes: number) => {
  if (!minutes) {
    return "0 Minutes";
  }
  const hours = Math.floor(minutes / MINUTES_IN_HOUR);
  const hoursText = hours ? `${hours} Hours` : "";
  return `${hoursText} ${minutes - hours * MINUTES_IN_HOUR} Minutes `;
};
