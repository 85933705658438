import React from "react";
import { ROLES } from "redux/auth/roles/roles_consts";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { getAuthSelector } from "redux/auth/user_selectors";
import {
  clearCurrentSong,
  deleteCustomSong,
} from "redux/songwriting/songwriting_slice";
import { openModal } from "redux/ui/modal/modal_slice";
import { Box, MenuItem, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { StyledMenu } from "../styled_menu/styled_menu";

interface ISongMenu {
  songAnchorEl: null | HTMLElement;
  setSongAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SongMenu = ({ songAnchorEl, setSongAnchorEl }: ISongMenu) => {
  const dispatch = useDispatch();
  const currentSong = useSelector(getCurrentCustomSong);
  const song_id = currentSong.id;

  const { role } = useSelector(getAuthSelector);

  const freeUserPlan = [
    ROLES.FREE_USER,
    ROLES.FREE_TEACHER_STUDENT,
    ROLES.FREE_TEACHER_ACCOUNT,
    ROLES.FREE_SONGWRITING,
  ].includes(role as any);

  const menuVerticalOffset = 75;

  const deleteSong = () => {
    if (freeUserPlan) {
      dispatch(openModal({ type: "payForSubscription" }));
    } else {
      song_id && dispatch(deleteCustomSong(song_id));
    }
    setSongAnchorEl(null);
    dispatch(clearCurrentSong());
  };

  return (
    <StyledMenu
      id="songMenu"
      anchorEl={songAnchorEl}
      keepMounted
      open={Boolean(songAnchorEl)}
      onClose={() => {
        setSongAnchorEl(null);
        dispatch(clearCurrentSong());
      }}
      vertical={menuVerticalOffset}
    >
      <MenuItem onClick={deleteSong}>
        <Box display="flex" style={{ gap: "10px" }}>
          <DeleteOutlineIcon />
          <Typography>Delete</Typography>
        </Box>
      </MenuItem>
    </StyledMenu>
  );
};
