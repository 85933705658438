import React from "react";
import { timestampToDate } from "helpers/timestampToSince";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  getCustomSongs,
  getSharedWithMeSongs,
} from "redux/songwriting/songwriting_selectors";
import {
  ICustomSong,
  ISongwriting,
} from "redux/songwriting/songwriting_interfaces";
import upArrow from "../assets/sketchArrowUp.png";
import downArrow from "../assets/sketchArrowDown.png";
import SongCard from "./song_card";

interface ISongCards {
  purpose?: string;
  setSongAnchorEl?: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}
interface IGroupedSongs {
  period: String;
  periodSongs: (ISongwriting | ICustomSong)[];
}

const SongCards = ({ purpose, setSongAnchorEl }: ISongCards) => {
  const customSongs = useSelector(getCustomSongs);
  const sharedSongs = useSelector(getSharedWithMeSongs);
  const sharedCustomSongs = sharedSongs?.map(
    (sharedSong) => sharedSong.sharedSong.custom_song,
  );
  let totalCustomSongs: (ISongwriting | ICustomSong)[] = customSongs;
  if (sharedCustomSongs) {
    totalCustomSongs = [...customSongs, ...sharedCustomSongs];
  }

  const sortedCustomSongs = [...totalCustomSongs].sort((a, b) => {
    const date1 = new Date(a.edited_at);
    const date2 = new Date(b.edited_at);
    return date2.getTime() - date1.getTime();
  });

  const currentDate = new Date();
  const today = new Date(currentDate);
  today.setHours(0, 0, 0, 0);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const millisecondsForDays = (days: number) => days * millisecondsPerDay;
  const periodSeven = 7;
  const periodThirty = 30;
  const last7Days = new Date(
    today.getTime() - millisecondsForDays(periodSeven),
  );
  const last30Days = new Date(
    today.getTime() - millisecondsForDays(periodThirty),
  );

  const includedSongs = new Set();

  const groupedSongs = sortedCustomSongs.reduce(
    (acc: IGroupedSongs[], song) => {
      const songDate = new Date(song.edited_at);
      const year = songDate.getFullYear();

      let addedToPeriod = false;

      if (songDate > today && !addedToPeriod) {
        addToPeriod("Today");
      } else if (songDate > last7Days && !addedToPeriod) {
        addToPeriod("Last 7 Days");
      } else if (songDate > last30Days && !addedToPeriod) {
        addToPeriod("Last 30 Days");
      } else if (!addedToPeriod) {
        addToPeriod(year.toString());
      }

      function addToPeriod(period: String) {
        const existingPeriod = acc.find((item) => item.period === period);
        if (existingPeriod) {
          existingPeriod.periodSongs.push(song);
        } else {
          acc.push({
            period,
            periodSongs: [song],
          });
        }
        includedSongs.add(song);
        addedToPeriod = true;
      }

      return acc;
    },
    [],
  );

  const textStyle = {
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: "18px",
    color: "#5F6367",
  };

  const formatDate = (editedAt: Date | string) => {
    if (typeof editedAt === "string") {
      editedAt = timestampToDate(editedAt);
    }

    const month = editedAt.getMonth() + 1; // Months are zero-indexed, so we add 1
    const day = editedAt.getDate();
    const year = editedAt.getFullYear() % 100; // Get last two digits of the year

    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 15,
        marginTop: 20,
      }}
      width="100%"
    >
      {groupedSongs.length === 0 ? (
        <Box>
          <Box position="absolute" left="200px">
            <img src={upArrow} alt="pointer" width="100px" />
          </Box>
          <Box
            position="absolute"
            left="100px"
            top="250px"
            display="flex"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <Typography style={textStyle}>
              Suddenly struck by inspiration?
            </Typography>

            <Typography style={textStyle}>Capture your ideas here</Typography>
          </Box>
          <Box
            position="absolute"
            right="65px"
            bottom="45px"
            style={{ transform: "rotate(-15deg)" }}
          >
            <img src={downArrow} alt="pointer" width="200px" />
          </Box>
          <Box
            position="absolute"
            bottom="225px"
            right="150px"
            display="flex"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <Typography style={textStyle}>Writing the next big hit?</Typography>

            <Typography style={textStyle}>Create a new song here</Typography>
          </Box>
        </Box>
      ) : (
        groupedSongs.map((value, index) => {
          const groupedSongKey = index;
          return (
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: 10 }}
              key={groupedSongKey}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#6b6868",
                }}
              >
                {value.period}
              </Typography>
              {value.periodSongs.map((value, index) => {
                const periodSongsKey = index;
                return (
                  <SongCard
                    key={periodSongsKey}
                    song_id={value.id}
                    title={value.song_name}
                    lastUpdated={formatDate(value.edited_at)}
                    firstLine={
                      value.custom_song_lyrics[0]
                        ? value.custom_song_lyrics[0].song_lyrics
                        : "  "
                    }
                    purpose={purpose}
                    setSongAnchorEl={setSongAnchorEl}
                  />
                );
              })}
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default SongCards;
