import React, { Dispatch, SetStateAction, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ILesson } from "api/lesson_api";
import { last } from "lodash";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import { isAuthorizedSelector } from "redux/auth/user_selectors";
import { fetchUserLessons } from "redux/entities/user_lessons/user_lessons_slice";
import { Category } from "./category";
import { CATEOGIRES_DATA } from "./categories_data";
import {
  MainContainer,
  InstrumentName,
  CategoriesContainer,
  BrowseAllSongs,
} from "./all_songs_styled_components";

interface INewAllSongsPage {
  lessons: ILesson[];
  setShowGrid: Dispatch<SetStateAction<boolean>>;
}

export const NewAllSongsPage = (props: INewAllSongsPage) => {
  const dispatch = useDispatch();
  const { setShowGrid } = props;
  const { instruments: selectedInstruments } = useSelector(getSongFilters);
  const isAuth = useSelector(isAuthorizedSelector);
  useEffect(() => {
    dispatch(fetchUserLessons());
  }, [dispatch]);
  const filteredCategories = useMemo(
    () =>
      CATEOGIRES_DATA.filter((category) => {
        if (category.name === "Recommended for You" && !isAuth) {
          return false;
        }
        if (
          category.lessons &&
          Object.keys(category.lessons).includes(
            last(selectedInstruments) as string,
          )
        ) {
          return true;
        }
        if (category.lessons === undefined) {
          return true;
        }
        return false;
      }),
    [isAuth, selectedInstruments],
  );

  const onBrowseAllSongsHandler = () => {
    setShowGrid(true);
    window.scrollTo(0, 0);
  };

  return (
    <MainContainer isAuth={isAuth}>
      <InstrumentName>{last(selectedInstruments)}</InstrumentName>
      <CategoriesContainer>
        {filteredCategories.map((category) => {
          return <Category key={category.name} {...category} />;
        })}
      </CategoriesContainer>
      <BrowseAllSongs onClick={onBrowseAllSongsHandler}>
        Browse All Songs
      </BrowseAllSongs>
    </MainContainer>
  );
};
