import { useCallback, useEffect } from "react";

/**
 * Stops scrolling when user scrolls page with mouse wheel
 * @param {function} disableScroll
 */
export const useStopOnScroll = disableScroll => {
  const stopOnWheelScroll = useCallback(() => {
    disableScroll();
  }, [disableScroll]);

  useEffect(() => {
    window.addEventListener("wheel", stopOnWheelScroll);
    return () => window.removeEventListener("scroll", stopOnWheelScroll);
  }, [stopOnWheelScroll]);
};
