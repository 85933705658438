// when change PERMISSIONS & ROLES, you will need also change const PERMISSIONS & ROLES in rails
export const PERMISSIONS = {
  UNLIMITED_SONG_LESSONS: "UNLIMITED_SONG_LESSONS",
  ACCESS_TO_GUITAR: "ACCESS_TO_GUITAR",
  ACCESS_TO_UKULELE: "ACCESS_TO_UKULELE",
  REQUEST_NEW_SONG_LESSONS: "REQUEST_NEW_SONG_LESSONS",
  SONG_RECOMMENDATIONS: "SONG_RECOMMENDATIONS",
  SONGWRITING: "SONGWRITING",
  UNLIMITED_SONGWRITING: "UNLIMITED_SONGWRITING",

  // teacher permissions
  STUDENT_ACCESS: "STUDENT_ACCESS",
  CREATE_UNLIMITED_CLASSES: "CREATE_UNLIMITED_CLASSES",
  ADD_UNLIMITED_STUDENTS: "ADD_UNLIMITED_STUDENTS",
  TRACK_STUDENT_SKILLS: "TRACK_STUDENT_SKILLS",
  TRACK_PRACTICE_TIME: "TRACK_PRACTICE_TIME",
  STUDENT_VIDEO_RECORDING: "STUDENT_VIDEO_RECORDING",
  EDIT_FEEDBACK: "EDIT_FEEDBACK",

  // admin permissions
  MANAGE_ORGANIZATIONS: "MANAGE_ORGANIZATIONS",
  CREATE_ORGANIZATIONS: "CREATE_ORGANIZATIONS",
} as const;

export type IPermissions = keyof typeof PERMISSIONS;
