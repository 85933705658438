import React from "react";
import { useDispatch } from "react-redux";
import { setStaffTabStatusThunk } from "redux/organizations/organization_slice";
import { StyledCheckbox } from "../common/common";

interface ILessonCheckbox {
  modeStatus: {
    tab?: boolean;
    staff?: boolean;
  };
  target: "tab" | "staff";
}

export const LessonCheckbox = ({ modeStatus, target }: ILessonCheckbox) => {
  const dispatch = useDispatch();
  const modes = ["tab", "staff"] as const;
  // This checkbox disabled, if only this one activated
  let disabled = true;

  if (modeStatus[target]) {
    const restModes = modes.filter((mode) => target !== mode);

    restModes.map((restMode) => {
      if (modeStatus[restMode]) {
        disabled = false;
      }
      return null;
    });
  } else {
    disabled = false;
  }

  const handleToggleChange = () => {
    dispatch(setStaffTabStatusThunk({ mode: target }));
  };
  return (
    <StyledCheckbox
      disabled={disabled}
      checked={modeStatus[target]}
      onChange={handleToggleChange}
    />
  );
};
