import React, { useEffect, useState } from "react";
import { getLazyImage } from "components/songwriting/songbuilder/songbuilder_sidebar/build_song_bar/chords_list/chords_list";
import { ViewSongRow } from "./view_song_row";
import css from "../view_song_modal.module.scss";

interface IViewSongSectionProps {
  songSection: {
    song_chords: string;
    song_lyrics: string;
    song_structure: any;
  };
  chordIconStyle: string;
}

interface IChordInfo {
  title: string;
  icon: React.ReactElement;
}

export const ViewSongSection: React.FC<IViewSongSectionProps> = ({
  songSection,
  chordIconStyle,
}) => {
  const songChords = songSection.song_chords
    ? JSON.parse(songSection.song_chords)
    : [];
  const [chordList, updateChordList] = useState<string[] | undefined>(
    undefined,
  );
  const [chordLazyImages, updateChordLazyImages] = useState<any>([]);

  //  Use Effect, get a list of all chords in this song
  useEffect(() => {
    const songChordArray: string[] = [];
    if (songSection.song_chords) {
      JSON.parse(songSection.song_chords).forEach(
        (chord: { chord: string }) => {
          if (!songChordArray.includes(chord.chord)) {
            songChordArray.push(chord.chord);
          }
        },
      );
    }
    updateChordList(songChordArray);
  }, [songSection]);

  useEffect(() => {
    // build chordLazyImages each time chordList changes = [{title: chord, icon.props.src: svg image path }]
    chordList?.forEach((chord) => {
      (async () => {
        const svg = await getLazyImage(chord, chordIconStyle);

        updateChordLazyImages((prior: IChordInfo[] | undefined) => {
          if (!prior?.some((thisSvg) => thisSvg.title === svg.title)) {
            return [...(prior || []), svg];
          }
          return prior || [];
        });
      })();
    });
  }, [chordList, songSection, chordIconStyle]);

  return (
    <div className={css.section_container}>
      <h3 className={css.verse_title}>{songSection.song_structure}</h3>
      <div className={css.lyrics_table}>
        {songSection.song_lyrics.split("/n").map((song_line, row) => {
          const rowKey = `songSection${row}`;
          return (
            <ViewSongRow
              song_line={song_line}
              row={row}
              songChords={songChords}
              chordLazyImages={chordLazyImages}
              key={rowKey}
            />
          );
        })}
      </div>
    </div>
  );
};
