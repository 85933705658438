import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFreePlanDescriptionItems } from "redux/general_selectors/subscription_plan_selectors";
import { chooseFreePlan } from "redux/sign_up_steps/sign_up_steps_slice";
import { SubscriptionPlan } from "./subscription_plan";
import { FreePlanPrice } from "./free_plan_price";

interface IFreePlan {
  rewriteOnSubmit?: () => void;
}

export const FreePlan = ({ rewriteOnSubmit }: IFreePlan) => {
  const dispatch = useDispatch();

  const freePlanListItems = useSelector(getFreePlanDescriptionItems);

  const onSubmit = useCallback(() => {
    dispatch(chooseFreePlan());
  }, [dispatch]);

  return (
    <SubscriptionPlan
      title="FREE PLAN"
      priceBlock={<FreePlanPrice />}
      listItems={freePlanListItems}
      submitBtnText="Choose Free"
      onSubmit={rewriteOnSubmit || onSubmit}
    />
  );
};

FreePlan.propTypes = {};
FreePlan.defaultProps = {};
