import { useSongUnitTableContent } from "hooks";
import React from "react";
import { useSelector } from "react-redux";
import {
  getTableData,
  getUsingMobileAppFlag,
} from "redux/songwriting/songwriting_selectors";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { SongLyricsTableBody } from "./song_lyrics_table_body/song_lyrics_table_body";

interface ITable {
  songUnitId: number;
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SongLyricsTable = React.memo(
  ({ songUnitId, setChordAnchorEl }: ITable) => {
    const tableData = useSelector(getTableData(songUnitId));
    const { collectChanges } = useSongUnitTableContent({
      id: songUnitId,
    });
    const mobile = useSelector(getUsingMobileAppFlag);

    const handleBlur = () => {
      collectChanges();
    };

    return (
      <ClickAwayListener onClickAway={handleBlur}>
        <table
          style={{
            width: "64ch",
            marginBottom: mobile ? "0px" : "22px",
            borderCollapse: "collapse",
            fontSize: "1.25rem",
            fontFamily: "'Courier New', Courier, monospace",
          }}
        >
          {tableData && (
            <SongLyricsTableBody
              tableData={tableData}
              setChordAnchorEl={setChordAnchorEl}
            />
          )}
        </table>
      </ClickAwayListener>
    );
  },
);
