import { OauthButton } from "./OauthButton";

export const MicrosoftButton = () => {
  return (
    <OauthButton provider="Microsoft" width={205}>
      <path fill="#f25022" d="M1 1h9v9H1z" />
      <path fill="#00a4ef" d="M1 11h9v9H1z" />
      <path fill="#7fba00" d="M11 1h9v9h-9z" />
      <path fill="#ffb900" d="M11 11h9v9h-9z" />
    </OauthButton>
  );
};

// Example user request:
// curl -H "Authorization: <token>" https://graph.microsoft.com/oidc/userinfo
