import { Popover, Typography } from "@material-ui/core";
import cn from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import css from "../../custom_song_settings.module.scss";
import {
  SongSettingsList,
  SONG_SETTINGS_LISTS,
} from "../../../../song_settings_list/song_settings_list";

const METER_LIST = ["2/4", "3/4", "4/4", "6/8"];

export const SongMeter = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const openPopover = !!anchorEl;

  const customSong = useSelector(getCurrentCustomSong);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = openPopover ? "song-meter-popover" : undefined;

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        className={cn(css.padding, css.div_button)}
      >
        <div className={css.main_font_styles}>{customSong.time_signature}</div>
        <p className={css.subtitle_font_styles}>Meter</p>
      </div>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={css.padding}>
          <Typography gutterBottom>Meter</Typography>
          <SongSettingsList
            list_key={SONG_SETTINGS_LISTS.time_signature}
            dataArray={METER_LIST}
            handleClose={handleClose}
          />
        </div>
      </Popover>
    </>
  );
};
