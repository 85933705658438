/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getErrors } from "redux/errors/errors_selectors";
import {
  registerTeacherSignUp,
  registerUser,
  updateUserRole,
} from "redux/auth/auth_slice";
import classnames from "classnames";
import { clearErrors } from "redux/errors/session/session_errors_actions";
import { IRoles } from "redux/auth/roles/roles_consts";
import { isTeacherRole } from "redux/auth/roles/roles_helpers";

import css from "../modal.module.scss";

type TInputNameKey = keyof typeof idToErr;
type TErrorInputNameKey = typeof idToErr[keyof typeof idToErr];
type TInputName = { [key in TInputNameKey]: string };
type TErrorInputName = { [key in TErrorInputNameKey]: string | null };

const idToErr = {
  firstName: "firstNameErrors",
  lastName: "lastNameErrors",
  email: "emailErrors",
} as const;

const idToMessage = {
  firstName: "First name can't be blank",
  lastName: "Last name can't be blank",
  email: "Email can't be blank",
};
interface ISignUpForm {
  role: IRoles;
}

export const SignUpForm = ({ role }: ISignUpForm) => {
  const dispatch = useDispatch();

  const [userCredentials, setUserCredentials] = useState<
    TInputName & TErrorInputName
  >({
    email: "",
    firstName: "",
    lastName: "",
    firstNameErrors: null,
    lastNameErrors: null,
    emailErrors: null,
  });
  const [currentInput, setCurrentInput] = useState<TInputNameKey>("email");

  const skipFirstRender = useRef(false);
  const errors = useSelector(getErrors).session.signup;

  const handleClearErrors = useCallback(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (errors) {
        handleClearErrors();
      }
    };
  }, [errors, handleClearErrors]);

  const anyBlank = () => {
    const { firstName, lastName, email } = userCredentials;
    return firstName === "" || lastName === "" || email === "";
  };

  const setError = (key: TInputNameKey) => {
    if (userCredentials[key] === "") {
      setUserCredentials((prevState) => ({
        ...prevState,
        [idToErr[key]]: idToMessage[key],
      }));
    } else {
      setUserCredentials((prevState) => ({
        ...prevState,
        [idToErr[key]]: null,
      }));
    }
  };

  const setErrors = () => {
    Object.keys(idToErr).forEach((key) => {
      setError(key as TInputNameKey);
    });
  };

  useEffect(() => {
    if (skipFirstRender.current) {
      setError(currentInput);
    } else {
      skipFirstRender.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userCredentials.email,
    userCredentials.firstName,
    userCredentials.lastName,
  ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUserCredentials((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setCurrentInput(id as TInputNameKey);
  };

  const isTeacherSignUp = isTeacherRole(role);

  function delay(time: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (anyBlank()) {
      setErrors();
    } else if (isTeacherSignUp) {
      dispatch(registerTeacherSignUp({ ...userCredentials, role }));
    } else {
      dispatch(registerUser({ ...userCredentials, role }));

      //  This makes sure the role is being set correctly
      delay(1000).then(() => {
        dispatch(
          updateUserRole({
            email: userCredentials.email.toLowerCase(),
            roleName: role,
          }),
        );
      });
    }
  };

  let emailErrors;

  if (errors?.email) {
    if (errors.email[0] === "is not an email") {
      emailErrors = "Not a valid Email";
    } else {
      emailErrors = `Email ${errors.email[0]}`;
    }
  }

  if (userCredentials.email === "") {
    emailErrors = userCredentials.emailErrors;
  }
  return (
    <form onSubmit={handleSubmit} className={css.form} id="signUp_default_form">
      <input
        onChange={handleChange}
        className={classnames(css.input, {
          [css.input_error]: userCredentials.firstNameErrors,
        })}
        value={userCredentials.firstName}
        id="firstName"
        type="text"
        placeholder="First Name"
        autoComplete="given-name"
      />
      <div
        className={
          userCredentials.firstNameErrors
            ? `${css.error} fadeIn animated2`
            : undefined
        }
      >
        {userCredentials.firstNameErrors}
      </div>
      <input
        onChange={handleChange}
        className={classnames(css.input, {
          [css.input_error]: userCredentials.lastNameErrors,
        })}
        value={userCredentials.lastName}
        id="lastName"
        type="text"
        placeholder="Last Name"
        autoComplete="family-name"
      />
      <div
        className={
          userCredentials.lastNameErrors
            ? `${css.error} fadeIn animated2`
            : undefined
        }
      >
        {userCredentials.lastNameErrors}
      </div>
      <input
        onChange={handleChange}
        className={classnames(css.input, {
          [css.input_error]: emailErrors,
        })}
        value={userCredentials.email}
        id="email"
        type="email"
        placeholder="Email"
        autoComplete="email"
      />
      <div
        className={emailErrors ? `${css.error} fadeIn animated2` : undefined}
      >
        {emailErrors}
      </div>
    </form>
  );
};
