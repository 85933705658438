import { ILesson } from "api/lesson_api";
import { push } from "connected-react-router";
import { ROLES } from "redux/auth/roles/roles_consts";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { isAuthorizedSelector } from "redux/auth/user_selectors";
import { IState } from "redux/store";
import { UPGRADE } from "routes/route_constants";
import { AppDispatch } from "types/redux_";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// 'url' will be the link the user tried to click on before getting redirected to the modal, so when they login, they will get rerouted to the link they were trying to go to in the first place. Don't redirect if url is null.
export const openModalWithOptions =
  (type: string) =>
  (options = {}) => {
    const defaults = { type, url: null };
    const modal = { ...defaults, ...options };

    return openModal(modal);
  };

export const openRemoveStudentModal =
  openModalWithOptions("removeStudentModal");
export const openUserInfoModal = openModalWithOptions("userInfoModal");
export const openLoginModal = openModalWithOptions("login");
export const openChoosePlanModal = openModalWithOptions("choosePlan");
export const openViewSongModal = openModalWithOptions("viewSong");
export const openSignupModal = openModalWithOptions("signup");
export const openRecommendationModal = openModalWithOptions("recommendation");
export const openReqPWResetModal = openModalWithOptions("reqPWReset");
export const openUpdatePasswordModal = openModalWithOptions("resetPW");
export const openSkillsetModal = openModalWithOptions("skillset");
export const openCompletionReminderModal = openModalWithOptions(
  "completionReminderModal",
);
export const openTeacherConfirmationModal = openModalWithOptions(
  "teacherConfirmationReminder",
);
export const openSongbuilderModal = openModalWithOptions("songbuilder");
export const openOrgNameModal = openModalWithOptions("orgName");
export const openSeedNoteModal = openModalWithOptions("seedNote");
export const deleteAccountModal = openModalWithOptions("deleteProfile");

export const openPayForSubscriptionModal =
  () => (dispatch: AppDispatch, getState: () => IState) => {
    dispatch(closeModal());
    const userRole = getRoleSelector(getState());
    userRole === ROLES.FREE_USER &&
      dispatch(openModalWithOptions("payForSubscription")());
    userRole === ROLES.FREE_SONGWRITING &&
      dispatch(openModalWithOptions("payForSubscription")());
    userRole === ROLES.FREE_TEACHER_ACCOUNT && dispatch(push(UPGRADE));
  };

export const onMoosikoBtnClick =
  ({
    skillType,
    skillName,
    title,
    skillId,
    instrument,
  }: {
    skillType?: string;
    skillName?: string;
    title?: string;
    skillId?: number;
    instrument?: string;
  } = {}) =>
  (dispatch: AppDispatch, getState: () => IState) => {
    const isAuthorized = isAuthorizedSelector(getState());

    if (!isAuthorized) {
      return;
    }

    dispatch(
      openRecommendationModal({
        skillType,
        skillName,
        title,
        skillId,
        instrument,
      }),
    );
  };

export interface IModalState {
  type: string | null;
  addClassModalOpened: boolean;
  chordLibrary: boolean;
  changeEmail: boolean;
  studentConversion: boolean;
  studentPlans: boolean;
  studentOnboard: boolean;
  studentPay: boolean;
  studentParentEmail: boolean;

  headerText?: string;
  infoText?: string;
  infoDisabled?: boolean;
  courseId?: number;
  skillType?: string;
  open?: boolean;
  songName?: string;
  skillName?: string;
  instrument?: string;
  skillId?: number;
  lesson?: ILesson;
  songwriting?: boolean;

  signUpState?: boolean;
}

const initialState: IModalState = {
  type: null,
  addClassModalOpened: false,
  chordLibrary: false,
  changeEmail: false,
  studentConversion: false,
  studentPlans: false,
  studentOnboard: false,
  studentPay: false,
  studentParentEmail: false,
};

export const GUITAR_CHORD_LIBRARY_TYPE = "GUITAR_CHORD_LIBRARY_TYPE";
export const UKULELE_CHORD_LIBRARY_TYPE = "UKULELE_CHORD_LIBRARY_TYPE";

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeChangeEmailModal: (state) => {
      state.changeEmail = false;
    },
    openChangeEmailModal: (state, action) => {
      state.changeEmail = action.payload;
    },
    openChordLibraryModal: (state, action) => {
      state.chordLibrary = action.payload;
    },
    closeChordLibraryModal: (state) => {
      state.chordLibrary = false;
    },
    closeConversionlModal: (state) => {
      state.studentConversion = false;
    },
    openConversionlModal: (state) => {
      state.studentConversion = true;
    },
    openStudentParentEmailModal: (state) => {
      state.studentParentEmail = true;
    },
    closeStudentParentEmailModal: (state) => {
      state.studentParentEmail = false;
    },
    closeStudentPayModal: (state) => {
      state.studentPay = false;
    },
    openStudentPayModal: (state) => {
      state.studentPay = false;
    },
    closeStudentOnboardModal: (state) => {
      state.studentOnboard = false;
    },
    openStudentOnboardModal: (
      state,
      { payload }: PayloadAction<{ headerText: string; infoText: string }>,
    ) => {
      state.studentOnboard = true;
      state.headerText = payload.headerText;
      state.infoText = payload.infoText;
    },
    closeStudentPlansModal: (state) => {
      state.studentPlans = false;
    },
    openStudentPlansModal: (state) => {
      state.studentPlans = true;
    },
    closeClassModal: (state) => {
      state.addClassModalOpened = false;
    },
    openClassModal: (state) => {
      state.infoDisabled = false;
      state.addClassModalOpened = true;
    },
    openAddStudentsModal: (state, { payload }: PayloadAction<number>) => {
      state.infoDisabled = true;
      state.courseId = payload;
      state.addClassModalOpened = true;
    },
    openModal: (state, action: PayloadAction<IModal>) => {
      return { ...state, ...action.payload };
    },
    closeModal: (state) => {
      state.type = null;
      state.lesson = undefined;
      state.songwriting = false;
    },
  },
});

interface IModal {
  type: string;
  url?: null | string;
  lesson?: ILesson;
  songwriting?: boolean;
  template?: number;
}

export const {
  reducer: modalReducer,
  actions: {
    closeChangeEmailModal,
    openChangeEmailModal,
    openChordLibraryModal,
    closeChordLibraryModal,
    closeConversionlModal,
    openConversionlModal,
    openStudentParentEmailModal,
    closeStudentParentEmailModal,
    closeStudentPayModal,
    openStudentPayModal,
    closeStudentOnboardModal,
    closeStudentPlansModal,
    openStudentPlansModal,
    closeClassModal,
    openClassModal,
    openAddStudentsModal,
    openModal,
    closeModal,
    openStudentOnboardModal,
  },
} = modalSlice;
