import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import React, { useState } from "react";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { Feedback } from "../feedback";
import { ChartWithAside, IChartWithAside } from "../i_chart_with_aside";
import { IUserSkillsData, UserSkills } from "../user_skills";
import { YouVsBestPlayer } from "../you_vs_best_player/you_vs_best_player";
import css from "./user_view.module.scss";

export interface IUserView {
  chart: IChartWithAside;
  showProgress?: boolean;
  skills: IUserSkillsData;
  mediaRecordsWithFeedbacks: IMediaRecord[];
  studentName?: string;
  clickable?: boolean;
}

export const UserView = ({
  chart,
  showProgress,
  skills,
  mediaRecordsWithFeedbacks,
  studentName,
  clickable = false,
}: IUserView) => {
  const [content, setContent] = useState<React.ReactNode>(null);

  const { minutesPerDay, totalTime, thisWeekMinutes, userId, students } = chart;

  return (
    <div className={css.main}>
      <div
        className={classnames(css.top_container, {
          [css.content_active]: !content,
        })}
      >
        {!content ? (
          <>
            <UserSkills
              studentName={studentName}
              skills={skills}
              setContent={setContent}
              clickable={clickable}
            />

            <ChartWithAside
              minutesPerDay={minutesPerDay}
              totalTime={totalTime}
              thisWeekMinutes={thisWeekMinutes}
              userId={userId}
              students={students}
            />
          </>
        ) : (
          <div className={css.skill_content}>
            <button
              type="button"
              onClick={() => setContent(null)}
              className={css.close_button}
            >
              <CloseIcon />
            </button>
            {content}
          </div>
        )}
      </div>

      <Feedback mediaRecordsWithFeedbacks={mediaRecordsWithFeedbacks} />

      {showProgress && <YouVsBestPlayer />}
    </div>
  );
};
