import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Box, IconButton, Typography } from "@material-ui/core";
import {
  getMobileSidebarContent,
  getMobileSidebarState,
} from "redux/songwriting/songwriting_selectors";
import { toggleMobileSidebar } from "redux/songwriting/songwriting_slice";
import { RecordBar } from "../songbuilder_sidebar/record_bar/record_bar";
import { SongbuilderMobileNotes } from "./songbuilder_mobile_notes/songbuilder_mobile_notes";

import css from "./songbuilder_mobile_sidebar.module.scss";

export const SongBuilderMobileSidebar = () => {
  const sidebarState = useSelector(getMobileSidebarState);
  const sidebarContent = useSelector(getMobileSidebarContent);
  const dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch(toggleMobileSidebar());
  };

  return sidebarState === "none" ? null : (
    <div className={css.song_builder_sidebar_mobile}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={closeSidebar}
        style={{ cursor: "pointer" }}
        height="45px"
      >
        <IconButton aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: "#6b6868",
            fontFamily: "Raleway",
          }}
        >
          Song Builder
        </Typography>
      </Box>
      {sidebarContent === "Audio" ? <RecordBar /> : <SongbuilderMobileNotes />}
    </div>
  );
};
