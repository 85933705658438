import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { IState } from "redux/store";

import { coursesApi, ICourse } from "redux/courses/courses_api";
import { IOrganization, organizationApi } from "./organization_api";
import { getOrganizationId } from "./organization_selectors";

interface IFetchUserOrganization {
  userId: number;
}
interface IFetchOrganizationCourses {
  orgId: number;
}

export const fetchOrganizationCourses = createAsyncThunk<
  ICourse[],
  IFetchOrganizationCourses,
  { state: IState }
>("courses/fetchOrganizationCourses", async ({ orgId }) => {
  const {
    data: { courses },
  } = await coursesApi.fetchOrganizationCourses(orgId);
  return courses;
});

export const fetchUserOrganizations = createAsyncThunk<
  void,
  IFetchUserOrganization,
  { state: IState }
>(
  "organization/fetchUserOrganizations",
  async ({ userId }, { dispatch, getState }) => {
    const {
      data: { organizations },
    } = await organizationApi.fetchUserOrganizations(userId);

    dispatch(setUsersOrganizations(organizations));
    const orgId = getOrganizationId(getState());
    if (orgId) {
      dispatch(fetchOrganizationCourses({ orgId }));
    }
  },
);
export const setUsersOrganizations = createAction<IOrganization[]>(
  "organization/setUsersOrganizations",
);

interface IDeleteCourse {
  courseId: number;
  orgId: number;
}
export const deleteCourse = createAsyncThunk<
  void,
  IDeleteCourse,
  { state: IState }
>(
  "courses/deleteCourse",
  async ({ orgId, courseId }, { dispatch, getState }) => {
    try {
      await coursesApi.deleteCourse(courseId);
      toast.success("Class deleted");
      const userId = getUserIdSelector(getState());
      if (userId) {
        dispatch(fetchUserOrganizations({ userId }));
      }
    } catch (e) {
      toast.error((e as any).response.data.message);
      dispatch(fetchOrganizationCourses({ orgId }));
    }
  },
);
