import { stepsId } from "components/sign_up/newSignUp";
import { TopLineProgress } from "components/sign_up/reusable/top_line_progress";
import { useDispatch } from "react-redux";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { stepListActions } from "redux/step_list/stepListSlice";
import {
  Card,
  Cards,
  Img,
  ImgContainer,
} from "../add_students/add_students.styled";

import { StyledBackIcon } from "../add_students_bulk/add_students_bulk.styled";
import { StretchContainer, StyledButtonBack, Top } from "../emotion.styles";
import GoogleClassroomLogo from "../../images/google-classroom.svg";
import MsTeamsLogo from "../../images/microsoft-teams.svg";

export const BulkImportFromExternalResources = () => {
  const dispatch = useDispatch();

  const handleBackStep = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.addStudents,
      }),
    );
  };

  const handleNextStep = (stepId: number) => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId,
      }),
    );
  };

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={90} />
        <StyledButtonBack type="button" onClick={handleBackStep}>
          <StyledBackIcon />
        </StyledButtonBack>
      </Top>
      <Cards>
        <Card
          onClick={() => handleNextStep(stepsId.bulkImportFromMsTeams)}
          type="button"
        >
          {"Import From \n"}
          <span>Microsoft Teams</span>
          <ImgContainer>
            <Img src={MsTeamsLogo} alt="add-msteams-logo" />
          </ImgContainer>
        </Card>

        <Card
          onClick={() => handleNextStep(stepsId.bulkImportFromGoogleClassroom)}
          type="button"
        >
          {"Import From\n"}
          <span>Google Classroom</span>
          <ImgContainer>
            <Img src={GoogleClassroomLogo} alt="add-gclasroom-logo" />
          </ImgContainer>
        </Card>
      </Cards>
    </StretchContainer>
  );
};
