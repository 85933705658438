import { useDispatch } from "react-redux";
import {
  createNewRowTable,
  deleteRowTable,
  pasteIntoTable,
  syncTableDataRows,
} from "redux/songwriting/songwriting_slice";

interface IUseRowsActions {
  songUnitId: number;
}

export const useRowsActions = ({ songUnitId }: IUseRowsActions) => {
  const dispatch = useDispatch();

  const createNewRow = (position?: number) => {
    dispatch(createNewRowTable({ songUnitId, position }));
  };

  const deleteRow = (indexOfInputToDelete: number) => {
    if (indexOfInputToDelete) {
      dispatch(deleteRowTable({ index: indexOfInputToDelete, songUnitId }));
    }
  };

  const pasteRows = (pastePosition: number, pasteArray: [string]) => {
    dispatch(pasteIntoTable({ songUnitId, pasteArray, pastePosition }));
  };

  const syncTableData = () => {
    dispatch(syncTableDataRows({ songUnitId }));
  };

  return { createNewRow, deleteRow, pasteRows, syncTableData };
};
