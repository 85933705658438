import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  IMsTeamsParticipantType,
  IMsTeamsGroupType,
  IMsTeamsGroup,
  IMsTeamsParticipant,
} from "../../../types/helpers/msTeamsTypes";
import { msTeamsApi } from "./microsoft_teams_api";

import { IState } from "../../store";

interface IMsTeamsImportState {
  groups: IMsTeamsGroup[];
  participants: IMsTeamsParticipant[];
  isFetchingData: boolean;
  msAccessToken: string;
}

const initialState: IMsTeamsImportState = {
  groups: [],
  participants: [],
  isFetchingData: false,
  msAccessToken: "",
};

export const fetchingData = createAction<boolean>("msTeams/fetchingData");

export const listUsersFromGroup = createAsyncThunk<
  { participants: IMsTeamsParticipantType },
  { groupId: string },
  { state: IState }
>("msTeams/listUsersFromGroup", async ({ groupId }, { dispatch, getState }) => {
  dispatch(fetchingData(true));
  const data = await msTeamsApi.getParticipants(
    getState().msTeamsImport.msAccessToken,
    groupId,
  );
  dispatch(fetchingData(false));

  return { participants: data };
});

export const listMSTeamsGroups = createAsyncThunk<
  { groups: IMsTeamsGroupType },
  { pageSize?: number },
  { state: IState }
>("msTeams/listMSTeamsGroups", async (_, { dispatch, getState }) => {
  dispatch(fetchingData(true));
  const data = await msTeamsApi.getGroups(
    getState().msTeamsImport.msAccessToken,
  );
  dispatch(fetchingData(false));

  return { groups: data };
});

const msTeamsImportSlice = createSlice({
  name: "msTeams",
  initialState,
  reducers: {
    setMsAccessToken: (state, action: PayloadAction<string>) => {
      // @ts-ignore
      state.msAccessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchingData, (state, action: PayloadAction<boolean>) => {
      state.isFetchingData = action.payload;
    });
    builder.addCase(
      listMSTeamsGroups.fulfilled,
      (state, action: PayloadAction<{ groups: IMsTeamsGroupType }>) => {
        state.groups = action.payload.groups.value;
      },
    );
    builder.addCase(
      listUsersFromGroup.fulfilled,
      (
        state,
        action: PayloadAction<{ participants: IMsTeamsParticipantType }>,
      ) => {
        state.participants = action.payload.participants.value;
      },
    );
  },
});

export const {
  reducer: msTeamsImportReducer,
  actions: { setMsAccessToken },
} = msTeamsImportSlice;
