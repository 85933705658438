import React from "react";
import styled from "@emotion/styled";
import { eventHandler } from "hooks/use_popover";
import { userEmailApi } from "redux/user_email/user_email_api";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "redux/auth/user_selectors";
import { toast } from "react-toastify";
import { fetchUserEmails } from "redux/auth/auth_slice";

interface IStudentConversationEmail {
  closeDialog: eventHandler;
  buttonText: string;
}

interface Form {
  email: string;
}

const FORBIDDEN_EMAIL_DOMAINS = [".org", ".edu", "and", ".net"];

export const StudentConversationEmail = ({
  closeDialog,
  buttonText,
}: IStudentConversationEmail) => {
  const { register, handleSubmit } = useForm<Form>();
  const dispatch = useDispatch();

  const user = useSelector(getUserSelector);
  if (!user) {
    return null;
  }
  const userId = user.id;
  const userEmail = user.email;

  const onSubmit = async (data: Form) => {
    const enteredEmail = data.email.toLowerCase();
    if (enteredEmail === userEmail.toLowerCase()) {
      toast.error(
        "That is your current school email. Please enter a personal email to save your skills.",
      );
    } else if (
      FORBIDDEN_EMAIL_DOMAINS.some((domain) => enteredEmail.includes(domain))
    ) {
      toast.error(
        "This appears to be a school email address. Please enter a personal email address (@gmail, @yahoo, @icloud, @outlook...etc) to save skills.",
      );
    } else {
      closeDialog({} as React.MouseEvent);
      await userEmailApi.addEmail(userId, data.email);
      dispatch(fetchUserEmails({ userId }));
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register("email")}
        placeholder="Personal Email Address"
        type="email"
        required
      />
      <Submit type="submit">{buttonText}</Submit>
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  height: 48px;
  margin-top: 60px;
  width: 100%;
  max-width: 600px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  background: #f3f3f3;
  color: black;
  padding: 14px 18px;
  font-size: 16px;
  line-height: 19px;
  flex-grow: 1;
`;

const Submit = styled.button`
  width: 160px;
  color: white;
  background: #6775de;
  display: flex;
  justify-content: center;
  align-items: center;
`;
