import axios from "axios";
import { IInstruments } from "redux/entities/user_skills/user_skills_slice";
import { ISkill } from "types/models/skill";
import { getNodeApiBaseUrl } from "./base_api";

export interface ISong {
  id: number;
  song_name: string;
  tag: string;
  artist: string;
  genre: string;
  unique_id: string;
  release_date: Date;
  spotify_id: string;
  spotify_sync: boolean;
  explicit: boolean;
  tuning: string;
  song_key: string;
  tempo: number;
  length: number;
  time_signature: string;

  createdAt: Date;
  updatedAt: Date;
}

export interface ILesson {
  id: number;
  difficulty_level: string;
  price: string;
  song_sheet: string;
  unique_id: string;
  song_id: number;
  instrument: "Guitar" | "Ukulele";
  lesson_key: string;
  alpha_tab_code: string;
  createdAt: Date;
  updatedAt: Date;
  skills: ISkill[];
  time_signature: string;
  song: ISong;
}

export interface ILessonSearchOptions {
  userId?: number; // userId for handling userOrganization settings if such exists
  decade?: string;
  skillTypes?: string[];
  search?: string;
  numberOfChords?: string;
  selectedSongKeys?: string[];
  selectedLessonKeys?: string[];
  selectedChords?: string[];
  selectedInstruments?: string[];
  chordsExactMatch?: boolean;
  id?: number[];
}

interface IPopularLessons {
  instruments: string[];
  limit: number;
}
interface IAsset {
  id: number;
  file_type: string;
  name: string;
  url: string;
  asset_num: number;
}

type TSlideType =
  | "break"
  | "generic"
  | "part_begin"
  | "part_end"
  | "teach"
  | "teach_pluck"
  | "strumming_static_1_audio"
  | "strumming_static_2_audios"
  | "strumming_dynamic"
  | "barre_chord_intro"
  | "melody";
export interface ISlide {
  id: number;
  text_top: string;
  text_middle: string;
  text_bottom: string;
  lesson_id: number;
  slide_num: number;
  slide_type: TSlideType;
  chord: string;
  skill?: ISkill;
  assets?: IAsset[];
  alpha_tab_code: string;
}

export type IStatisticLessons = {
  // eslint-disable-next-line no-unused-vars
  [key in IInstruments]: {
    amount?: number;
    skills?: {
      instrument: "Guitar" | "Ukulele";
      name: "Melody" | "Strumming";
      amountLessons: number;
    }[];
  };
};
export interface IFinishLesson {
  lessonId: number | string;
  instrument: string;
  progress?: number;
  undo?: boolean;
}

export const lessonApi = {
  finishLesson(params: IFinishLesson) {
    const { lessonId, progress, undo } = params;

    const url = `${getNodeApiBaseUrl()}/lesson/${lessonId}/finish`;
    return axios.post(url, { params: { progress, undo } });
  },

  fetchSlides(id: number | string) {
    return axios.get<{ slides: { [key: string]: ISlide } }>(
      `/api/lessons/${id}`,
    );
  },

  fetchLessonsById({ id = [], userId }: { id: number[]; userId?: number }) {
    const url = `${getNodeApiBaseUrl()}/lesson`;
    return axios.get<{
      lessons: { [key: string]: ILesson };
    }>(url, { params: { id, userId } });
  },

  fetchLessons(params: ILessonSearchOptions & { offset?: number } = {}) {
    const url = `${getNodeApiBaseUrl()}/lesson/search`;
    return axios.get<{
      lessons: { [key: string]: ILesson };
      hasNextPage: boolean;
    }>(url, {
      params,
    });
  },

  fetchRecentlyAddedLessons(params: IPopularLessons) {
    const url = `${getNodeApiBaseUrl()}/lesson/recently`;
    return axios.get<{
      lessons: number[];
    }>(url, { params });
  },

  fetchPopularLessons(params: IPopularLessons) {
    const url = `${getNodeApiBaseUrl()}/lesson/popular`;
    return axios.get<{
      lessons: number[];
    }>(url, { params });
  },

  fetchMaxFreeLessons() {
    const url = `${getNodeApiBaseUrl()}/lesson/free/max`;
    return axios.get(url);
  },

  purchaseLessonForFree(lessonId: number) {
    const url = `${getNodeApiBaseUrl()}/lesson/${lessonId}/free`;
    return axios.post(url);
  },

  fetchLessonKeys() {
    const url = `${getNodeApiBaseUrl()}/lesson/keys/lesson`;
    return axios.get<{ lessonKeys: string[] }>(url);
  },

  fetchSongKeys() {
    const url = `${getNodeApiBaseUrl()}/lesson/keys/song`;
    return axios.get<{ songKeys: string[] }>(url);
  },

  fetchAmountLessons() {
    const url = `${getNodeApiBaseUrl()}/lesson/amountLessons`;
    return axios.get<IStatisticLessons>(url);
  },
};
