/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { updateSongSeed } from "redux/song_seeds/song_seeds_slice";
import {
  getCurrentSeed,
  getNewSeedFlag,
  getSeedTitle,
} from "redux/song_seeds/song_seed_selector";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import css from "./editable_title.module.scss";

const EditableTitle = () => {
  const dispatch = useDispatch();
  const currentSeed = useSelector(getCurrentSeed);
  const currentSeedTitle = useSelector(getSeedTitle(currentSeed));
  const newSeedFlag = useSelector(getNewSeedFlag);
  const [isEditing, setIsEditing] = useState(newSeedFlag);
  const [currentTitle, setCurrentTitle] = useState(currentSeedTitle);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current && newSeedFlag) {
      inputRef.current.select();
    }
  }, [isEditing, newSeedFlag]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== currentSeedTitle) {
      setCurrentTitle(e.target.value);
      dispatch(updateSongSeed({ title: e.target.value }));
    }
    setIsEditing(false);
  };

  const handleCancel = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    setCurrentTitle(currentSeedTitle);
    setIsEditing(false);
  };

  return isEditing ? (
    <Box display="flex">
      <input
        autoFocus
        onBlur={handleBlur}
        defaultValue={currentTitle}
        className={css.song_title_input_mobile}
        ref={inputRef}
        enterKeyHint="enter"
      />
      <div className={css.icons_wrapper}>
        <CheckIcon className={css.icon} />
        <CloseIcon className={css.icon} onMouseDown={handleCancel} />
      </div>
    </Box>
  ) : (
    <h2
      role="presentation"
      className={css.song_title_mobile}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      {currentSeedTitle}
    </h2>
  );
};

export default EditableTitle;
