import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { IState } from "redux/store";
import css from "./select_by_name.module.scss";

interface ISelectName {
  // getRecordsFunction will return either IMediaRecord[] or IOrgSongs[]
  getRecordsFunction: (_: IState) => any[];
  setNameFunction: (_: { active: boolean; filteredRecords: any[] }) => void;
  dropSelectionsFunction: (_: IState) => void;
}

export const SelectByName: React.FC<ISelectName> = ({
  getRecordsFunction,
  setNameFunction,
  dropSelectionsFunction,
}) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const records = useSelector(getRecordsFunction);

  useEffect(() => {
    const active = !!name;
    const nameLowerCase = name.toLocaleLowerCase();
    const filteredRecords = active
      ? records.filter((record) => {
          return (
            record.user.fname.toLocaleLowerCase().includes(nameLowerCase) ||
            record.user.lname.toLocaleLowerCase().includes(nameLowerCase) ||
            `${record.user.fname.toLocaleLowerCase()} ${record.user.lname.toLocaleLowerCase()}`.includes(
              nameLowerCase,
            )
          );
        })
      : [];

    dispatch(setNameFunction({ active, filteredRecords }));
  }, [dispatch, name, records, setNameFunction]);

  const shouldDropSelection = useSelector(dropSelectionsFunction);
  useEffect(() => {
    setName("");
  }, [shouldDropSelection]);

  return (
    <div className={css.container}>
      <TextField
        id="outlined-textarea"
        label="Search by name..."
        placeholder="Placeholder"
        variant="outlined"
        size="small"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </div>
  );
};
