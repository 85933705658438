import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getStripeDataSelector = (state: IState) => state.entities.stripe;

export const getCustomerSelector = createSelector(
  (state: IState) => state.auth.customer,
  (customer) => customer,
);

export const getCustomerCardSelector = createSelector(
  [getCustomerSelector],
  (customer) => {
    if (!customer) {
      return null;
    }

    return customer.sources?.data[0] || null;
  },
);

export const isCustomerFetchedSelector = createSelector(
  (state: IState) => state.ui.filters.fetchedCustomer,
  (fetchedCustomer) => fetchedCustomer,
);

export const getStripeProduct = (state: IState) =>
  state.entities.stripe.product;

export const getProductQuantity = (state: IState) =>
  state.entities.stripe.quantity;
export const getPricePerStudent = (state: IState) =>
  state.entities.stripe.pricePerStudent;
