import { ISkill } from "types/models/skill";
import { useDispatch, useSelector } from "react-redux";
import { onMoosikoBtnClick } from "redux/ui/modal/modal_slice";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import css from "./progress_section.module.scss";
import img from "./img/skill_check.png";
import { SkillItem } from "./skill_item";

interface IProgressSection {
  lessonsNumber: number;
  skillsList: { name: string; learnedCount: number; skill?: ISkill }[];
}

export const ProgressSection = (props: IProgressSection) => {
  const { skillsList, lessonsNumber } = props;
  const skillsAmountArray = new Array(lessonsNumber).fill(null);
  const { instruments } = useSelector(getSongFilters);
  const dispatch = useDispatch();

  const handleLearnNew = (skill?: ISkill) => {
    if (skill) {
      dispatch(
        onMoosikoBtnClick({
          skillId: skill.id,
          instrument: instruments[0],
          title: `${mapSkillNameToHeader(skill.name)} Songs to Learn Next`,
        }),
      );
    } else {
      console.error("Skill not found");
    }
  };

  return (
    <div className={css.progress_section_container}>
      {skillsList.map(({ name: typeName, skill }, typeNameIndex) => (
        <div key={typeName} className={css.progress_section_container}>
          <div className={css.progress_section_name}>{typeName}</div>
          <div className={css.progress_section_boxes}>
            {skillsAmountArray.map((_, typeCountIndex) => (
              <SkillItem
                learned={
                  typeCountIndex < skillsList[typeNameIndex].learnedCount
                }
                text={`${typeCountIndex + 1}`}
                key={`${typeCountIndex + 1}`}
              />
            ))}
            {skillsList[typeNameIndex].learnedCount >= lessonsNumber ? (
              <img
                className={css.progress_section_image}
                src={img}
                alt="completed"
              />
            ) : (
              <button
                onClick={() => handleLearnNew(skill)}
                type="button"
                className={css.learn_new_button}
              >
                Learn More
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const mapSkillNameToHeader = (title: string) => {
  switch (title) {
    case "34folk":
      return "3/4 Folk";
    case "34backbeat":
      return "3/4 Backbeat";
    default:
      return title;
  }
};
