import cn from "classnames";
import React from "react";
import css from "./note_text_area.module.scss";

interface NoteTextAreaProps {
  value: string;
  setValue: (_: string) => void;
  onSubmit: (e: React.MouseEvent) => void;
  headerText: string;
  submitText: string;
}

export const NoteTextArea = React.forwardRef<
  HTMLTextAreaElement,
  NoteTextAreaProps
>(({ value, setValue, onSubmit, headerText, submitText }, ref) => {
  return (
    <div className={cn(css.container, css.note_feedback_note)}>
      <div>{headerText}</div>
      <textarea
        ref={ref}
        className={css.note_feedback_textarea}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      <hr className={css.note_feedback_line} />
      <button
        onClick={onSubmit}
        className={css.note_feedback_send_button}
        type="button"
      >
        {submitText}
      </button>
    </div>
  );
});
