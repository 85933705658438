import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ISharedSong } from "redux/songwriting/songwriting_interfaces";
import {
  setCurrentSong,
  setCurrentSongId,
  updateCustomSong,
} from "redux/songwriting/songwriting_slice";
import { openSongbuilderModal } from "redux/ui/modal/modal_slice";

import { TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import css from "../song_card.module.scss";

interface IEditableSongTitle {
  isEditingTitle: boolean;
  song_title: string;
  sharedSong?: ISharedSong;
  song_id?: number;
  setIsEditingTitle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditableSongTitle = ({
  isEditingTitle,
  setIsEditingTitle,
  sharedSong,
  song_title,
  song_id,
}: IEditableSongTitle) => {
  const [title, setTitle] = useState(song_title);
  const dispatch = useDispatch();

  const blurHandler = (e: React.SyntheticEvent) => {
    setIsEditingTitle(false);
    if (song_title === title || title.trim() === "") {
      setTitle(song_title);
      return;
    }
    dispatch(updateCustomSong({ id: song_id, song_name: title }));
  };

  const openSongBuilderHandler = () => {
    song_id && dispatch(setCurrentSongId({ id: song_id }));
    sharedSong &&
      dispatch(
        setCurrentSong({ custom_song: sharedSong.sharedSong.custom_song }),
      );
    dispatch(openSongbuilderModal());
  };

  const handleCancel = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    setTitle(song_title);
    setIsEditingTitle(false);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value);
  };

  return isEditingTitle ? (
    <div className={css.input_wrapper}>
      <TextField
        variant="outlined"
        value={title}
        InputProps={{
          style: {
            maxHeight: "40px",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: 500,
            padding: "8.5px 4px",
          },
        }}
        onBlur={blurHandler}
        onChange={changeHandler}
      />
      <CheckIcon className={css.icon} />
      <CloseIcon className={css.icon} onMouseDown={handleCancel} />
    </div>
  ) : (
    <h3
      role="presentation"
      onClick={openSongBuilderHandler}
      className={css.song_title}
    >
      {song_title}
    </h3>
  );
};
