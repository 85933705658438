import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { applyPromoRole } from "redux/auth/auth_slice";
import { promoApi } from "api/promo_api";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { stepListActions } from "redux/step_list/stepListSlice";
import { useHistory, useLocation } from "react-router";
import { UPGRADE } from "routes/route_constants";
import { stepsId } from "components/sign_up/newSignUp";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { CloseStepsButton } from "components/sign_up/reusable/close_steps_button";
import { setUserDataSignup } from "redux/sign_up_steps/sign_up_steps_slice";
import {
  ROLES,
  TEACHER_COST,
  ITeacherRoles,
  ROLE_IDS,
} from "redux/auth/roles/roles_consts";
import { Container, Header, StretchContainer, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { DescriptionTeacherPlan } from "../../reusable/description_teacher_plan";
import {
  TopBackground,
  Feature,
  Cell,
  CellDescription,
  StyledCheckIcon,
  Content,
} from "./teacher_plans.styled";

export const TeacherPlans = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const userRole = useSelector(getRoleSelector);
  const [promoRole, setPromoRole] = useState<number>(0);

  const dispatch = useDispatch();

  interface IHandlePay {
    selectPlan: ITeacherRoles;
  }

  const setPlan = ({ selectPlan }: IHandlePay) => {
    dispatch(setUserDataSignup({ role: selectPlan }));
    dispatch(
      stepListActions.nextStep({ stepListId: STEP_LIST_IDS.signUpStepList }),
    );
  };

  const plansDescription = [
    {
      header: "SOLO TEACHER ACCOUNT",
      description: `For teachers that want a single license to use Moosiko in their class. 
        Best for elementary music programs without individual student access.`,
      costValue: TEACHER_COST.SOLO_TEACHER,
      costDescription: "/teacher per year",
      onClick: () => setPlan({ selectPlan: ROLES.SOLO_TEACHER }),
    },
    {
      header: "FREE SCHOOL ACCOUNT",
      description: `Best for teachers that want to try Moosiko and provide a
      personalized learning experience for each student. Limit of 3 song
      lessons.`,
      costValue: TEACHER_COST.FREE_TEACHER_ACCOUNT,
      costDescription: "Free for teachers\n& Students",
      onClick: () => {
        if (pathname === UPGRADE) {
          history.push("/");
        } else {
          setPlan({ selectPlan: ROLES.FREE_TEACHER_ACCOUNT });
        }
      },
    },
    {
      header: "PAID SCHOOL ACCOUNT",
      description: `Provide a personalized learning path for each student, track
      skills/practice time, and use video assessment tools. Access to full
      song lesson library of 400+ songs.`,
      costValue: TEACHER_COST.PAID_TEACHER_ACCOUNT,
      costDescription: "/student per year",
      onClick: () => {
        if (pathname === UPGRADE) {
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.signUpStepList,
              stepId: stepsId.createOrg,
            }),
          );
        } else {
          setPlan({ selectPlan: ROLES.PAID_TEACHER_ACCOUNT });
        }
      },
    },
  ];

  const signUpColumnNumber = 4;
  const upgradeColumnNumber = 3;

  pathname === UPGRADE && plansDescription.splice(0, 1);

  const colNumber =
    pathname === UPGRADE ? upgradeColumnNumber : signUpColumnNumber;

  (async () => {
    const { promo } = queryString.parse(window.location.search);
    const promoData = await promoApi.fetchRole(promo);
    promoData && dispatch(applyPromoRole(promoData.data.roles_id));
    promoData && setPromoRole(promoData.data.roles_id);
  })();

  if (promoRole) {
    switch (promoRole) {
      case ROLE_IDS.SOLO_TEACHER:
        setPlan({ selectPlan: ROLES.SOLO_TEACHER });
        break;
      case ROLE_IDS.FREE_TEACHER_ACCOUNT:
        setPlan({ selectPlan: ROLES.FREE_TEACHER_ACCOUNT });
        break;
      case ROLE_IDS.PAID_TEACHER_ACCOUNT:
        setPlan({ selectPlan: ROLES.PAID_TEACHER_ACCOUNT });
        break;
      default:
    }
  }

  return (
    <StretchContainer>
      <TopBackground />
      <Top>
        <TopLineProgress percent={9} />
      </Top>
      <Container>
        {pathname === UPGRADE && <CloseStepsButton />}
        <Header>
          {userRole === ROLES.FREE_TEACHER_ACCOUNT
            ? "Upgrade for Full Teacher & Student Access"
            : "Choose a Plan That’s Best For You"}
        </Header>

        <Content colNumber={colNumber}>
          <Feature>Features</Feature>
          {plansDescription.map((plan) => (
            <DescriptionTeacherPlan
              header={plan.header}
              description={plan.description}
              costValue={plan.costValue}
              costDescription={plan.costDescription}
              onClick={plan.onClick}
              key={plan.header}
            />
          ))}
          {permissions.map(
            ({ feature, soloTeacher, freeSchool, paidSchool }) => (
              <React.Fragment key={feature}>
                <CellDescription>{feature}</CellDescription>
                {pathname !== UPGRADE && <Cell>{soloTeacher}</Cell>}
                <Cell>{freeSchool}</Cell>
                <Cell>{paidSchool}</Cell>
              </React.Fragment>
            ),
          )}
        </Content>
      </Container>
    </StretchContainer>
  );
};

const permissions = [
  {
    feature: "Unlimited Song Lessons",
    soloTeacher: <StyledCheckIcon />,
    freeSchool: <div>Limit of 3</div>,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Access to guitar & ukulele",
    soloTeacher: <StyledCheckIcon />,
    freeSchool: <StyledCheckIcon />,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Student access",
    soloTeacher: null,
    freeSchool: <StyledCheckIcon />,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Create unlimited classes",
    soloTeacher: null,
    freeSchool: <StyledCheckIcon />,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Add unlimited students",
    soloTeacher: null,
    freeSchool: <StyledCheckIcon />,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Track student skills",
    soloTeacher: null,
    freeSchool: null,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Track practice time",
    soloTeacher: null,
    freeSchool: null,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Student video recording",
    soloTeacher: null,
    freeSchool: null,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Request new song lessons",
    soloTeacher: null,
    freeSchool: null,
    paidSchool: <StyledCheckIcon />,
  },
  {
    feature: "Song recommendations",
    soloTeacher: null,
    freeSchool: null,
    paidSchool: <StyledCheckIcon />,
  },
];
