// Middleware to help with header management when using Redux, Devise Token Auth, and Axios
// Source: https://github.com/wdjungst/redux-devise-axios
import axios from "axios";
// import { formSelector } from "redux/ui/form/form_selectors";
import { fetchUserLessons } from "redux/entities/user_lessons/user_lessons_slice";
import { fetchCustomSongs } from "redux/songwriting/songwriting_slice";
import { login, validateToken } from "redux/auth/auth_slice";
import { logout } from "redux/auth/auth_actions";
import { fetchUserChordSkills } from "redux/entities/user_skills/user_skills_slice";
import {
  fetchSignUpSteps,
  showSignUpStepIfNeeded,
} from "redux/sign_up_steps/sign_up_steps_slice";
import { IState } from "redux/store";
import { AppDispatch } from "types/redux_";
import { Middleware } from "redux";
import { fetchCustomer } from "redux/entities/stripe/stripe_slice";

const onAfterLogin = async (dispatch: AppDispatch, getState: () => IState) => {
  // const fromResetPWModal = Boolean(formSelector(getState()).resetPW);

  try {
    await Promise.all([
      dispatch(fetchUserChordSkills()),
      dispatch(fetchSignUpSteps()),
      dispatch(fetchCustomer()),
      dispatch(fetchUserLessons()),
    ]);
    await dispatch(fetchCustomSongs());

    // Ignore if resetting password:
    // if (!fromResetPWModal) {
    dispatch(showSignUpStepIfNeeded());
    // }
  } catch (err) {
    console.error("onAfterLogin error", err);
  }
};

const HEADERS = ["access-token", "token-type", "client", "expiry", "uid"];

export const authMiddleware: Middleware<unknown, IState> =
  (api) =>
  (next) =>
  (action = { type: "" }) => {
    if (action.type === validateToken.pending.type) {
      HEADERS.forEach((token) => {
        if (localStorage.getItem(token)) {
          axios.defaults.headers.common[token] = localStorage.getItem(token);
        }
      });
    } else if (action.type === logout.toString()) {
      HEADERS.forEach((token) => localStorage.removeItem(token));
    } else if (action.type === login.toString()) {
      const {
        payload: { _headers: headers },
      } = action;
      if (headers) {
        if (headers["access-token"]) {
          HEADERS.forEach((token) => {
            axios.defaults.headers.common[token] = headers[token];
            localStorage.setItem(token, headers[token]);
          });
        }
      }

      onAfterLogin(api.dispatch, api.getState);
    }

    next(action);
  };
