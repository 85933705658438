import { Font } from "@react-pdf/renderer";
import FontRalewayRegular from "./pdf_fonts/Raleway-Regular.ttf";
import fontRalewaySemiBold from "./pdf_fonts/Raleway-SemiBold.ttf";
import FontRalewayBold from "./pdf_fonts/Raleway-Bold.ttf";
import FontRalewayBoldItalic from "./pdf_fonts/Raleway-BoldItalic.ttf";
import FontRalewayBlack from "./pdf_fonts/Raleway-Black.ttf";
import FontCourierNewRegular from "./pdf_fonts/Courier New Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [
    { src: FontRalewayRegular },
    { src: fontRalewaySemiBold, fontWeight: "semibold" },
    { src: FontRalewayBold, fontWeight: "bold" },
    { src: FontRalewayBoldItalic, fontStyle: "italic", fontWeight: "bold" },
    { src: FontRalewayBlack, fontWeight: "heavy" },
  ],
});
Font.register({ family: "Courier New", src: FontCourierNewRegular });
