import CheckIcon from "@material-ui/icons/Check";
import styled from "@emotion/styled";

interface IContact {
  colNumber: number;
}

export const TopBackground = styled.div`
  background: rgba(103, 117, 222, 0.1);
  position: absolute;
  width: 100vw;
  height: 30vh;
  z-index: -1;
`;

export const Feature = styled.div`
  padding: 2em 1em 3em;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-end;
  font-size: 1.25em;
  font-weight: 700;
`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.4em 0;
  align-items: center;
`;

export const CellDescription = styled(Cell)`
  justify-content: start;
  padding-left: 1em;
  color: #333333;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  background-color: #190067;
  padding: 3px 3px;
  border-radius: 15px;
  color: white;
`;

export const Content = styled.div<IContact>`
  display: grid;
  grid-template-columns: repeat(${(p) => p.colNumber}, 1fr);
  margin: 3em 2em 2em;
  border-radius: 6px;
  background-color: white;

  border-top: 1px solid rgb(0 0 0 / 20%);
  border-right: 1px solid rgb(0 0 0 / 20%);
  border-left: 1px solid rgb(0 0 0 / 20%);

  > div:nth-of-type(${(p) => p.colNumber}n + ${(p) => p.colNumber - 1}) {
    border-right: 1px solid rgb(0 0 0 / 20%);
    border-left: 1px solid rgb(0 0 0 / 20%);
  }
  > div {
    border-bottom: 1px solid rgb(0 0 0 / 20%);
  }
`;
