import React from "react";
// @ts-ignore
import { Cell, Row } from "react-table";
import { RowType } from "redux/songwriting/songwriting_interfaces";
import { DroppableCell } from "../droppable_cell/droppable_cell";

interface IDroppableRowSongLyrics {
  row: Row<RowType>;
  chordsData: {
    chord: string;
    row: string;
    column: string;
  }[];
  songUnitId: number;
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const DroppableRowSongLyrics = ({
  row,
  chordsData,
  songUnitId,
  setChordAnchorEl,
}: IDroppableRowSongLyrics) => {
  return (
    <>
      {row.cells.map((cell: Cell<any>, index) => (
        <DroppableCell
          cell={cell}
          key={`key-${songUnitId}-${cell.value}-${cell.row}`}
          chordsData={chordsData}
          songUnitId={songUnitId}
          setChordAnchorEl={setChordAnchorEl}
          padding={index === 0}
        />
      ))}
    </>
  );
};
