// apply some workarounds for opera browser

export function applyVideoWorkaround(options) {
  // use correct video mimetype for opera
  // eslint-disable-next-line no-magic-numbers
  if (!!window.opera || navigator.userAgent.indexOf("OPR/") !== -1) {
    // eslint-disable-next-line no-param-reassign
    options.plugins.record.videoMimeType = "video/webm;codecs=vp8"; // or vp9
  }
}
