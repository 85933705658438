import styled from "@emotion/styled";
import { StyledInput } from "components/sign_up/reusable/styled_input";
import { Container, InputContainer } from "../emotion.styles";

export const ErrorContainer = styled.div`
  position: relative;
`;

export const AmountInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInputContainer = styled(InputContainer)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
  align-items: stretch;
  text-align: start;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-self: center;
  text-align: start;
`;

export const AmountStudentsHeader = styled.div`
  margin-top: 1em;
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
  align-self: start;
  color: #6772e5;
`;

export const ContainerAmountStudents = styled.div`
  display: flex;
  align-items: baseline;
  align-self: stretch;
`;

export const AmountStudents = styled.div`
  margin-left: 2em;
  white-space: break-spaces;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  color: #6772e5;
  justify-content: flex-start;
  flex-grow: 1;
  font-size: 0.9em;
  align-items: baseline;
  > div > span {
    font-size: 2.5em;
  }
`;

export const AmountStudentsInput = styled(StyledInput)<{ errMessage?: string }>`
  padding: 6px;
  width: initial;
  font-size: 1.5em;
  width: 50px;
  min-width: initial;
  text-align: center;
  margin: initial;
  font-family: Arial, Helvetica, sans-serif;

  @media (max-width: 900px) {
    width: 50px;
  }
`;

export const StyledContainer = styled(Container)`
  justify-content: center;
  max-width: 400px;
`;

export const Example = styled.div`
  white-space: break-spaces;
  align-self: flex-start;
  font-size: 0.75em;
  color: #818181;
  padding-top: 1em;
`;
