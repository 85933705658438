import React from "react";
import { ICustomSongLyrics } from "redux/songwriting/songwriting_interfaces";
import { useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { SongUnit } from "../../song_unit/song_unit";

interface IList {
  unitList: ICustomSongLyrics[];
  collapsedStructures: number[];
  setChordAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SongLyricsList = ({
  unitList,
  collapsedStructures,
  setChordAnchorEl,
}: IList) => {
  const currentSong = useSelector(getCurrentCustomSong);

  return (
    currentSong && (
      <>
        {unitList.map((songUnit) => (
          <SongUnit
            key={`${songUnit.song_structure}-${songUnit.id}`}
            songUnit={songUnit}
            collapsedStructures={collapsedStructures}
            setChordAnchorEl={setChordAnchorEl}
          />
        ))}
      </>
    )
  );
};
