import SearchBar from "components/reusable/search_bar/search_bar";
import { useEffect, useState } from "react";

import { API_KEY_PF } from "constants/keys";
import css from "./rhymes_and_thesaurus.module.scss";
import {
  NoResultsSection,
  ResultsSection,
} from "./results_section/results_section";

const datamuseApiUrl = "https://api.datamuse.com";
const profanityFilterUrl = "https://api.api-ninjas.com/v1/profanityfilter";
const MAX_RESULT_LENGTH = 15;

interface IDatamuseResponse {
  word: string;
  score: number;
}

interface IFetchResponse {
  rhymes: string[];
  thesaurus: string[];
}

interface IProfanityFilterResult {
  censored: string;
  has_profanity: boolean;
  original: string;
}

const fetchRhymesAndThesaurus = async (
  queryString: string,
): Promise<IFetchResponse> => {
  const [rhymes, thesaurus] = await Promise.all([
    fetch(
      `${datamuseApiUrl}/words?rel_rhy=${queryString}&max=${MAX_RESULT_LENGTH}`,
    ).then((res) => res.json()),
    fetch(
      `${datamuseApiUrl}/words?rel_syn=${queryString}&max=${MAX_RESULT_LENGTH}`,
    ).then((res) => res.json()),
  ]);
  const preparedRhymes = rhymes.map((rhyme: IDatamuseResponse) => rhyme.word);
  const preparedThesaurus = thesaurus.map(
    (thesauri: IDatamuseResponse) => thesauri.word,
  );
  let resultRhymes: string[] = preparedRhymes;
  let resultThesaurus: string[] = preparedThesaurus;

  const [filteredRhymes, filteredThesaurus]: IProfanityFilterResult[] =
    await Promise.all([
      fetch(`${profanityFilterUrl}?text=${preparedRhymes.toString()}`, {
        headers: { "X-Api-Key": API_KEY_PF },
      }).then((res) => res.json()),
      fetch(`${profanityFilterUrl}?text=${preparedThesaurus.toString()}`, {
        headers: { "X-Api-Key": API_KEY_PF },
      }).then((res) => res.json()),
    ]);

  if (filteredRhymes.has_profanity) {
    const filteredRhymesArray = filteredRhymes.censored.split(",");
    resultRhymes = filteredRhymesArray.filter((word) => !word.includes("*"));
  }
  if (filteredThesaurus.has_profanity) {
    const filteredThesaurusArray = filteredThesaurus.censored.split(",");
    resultThesaurus = filteredThesaurusArray.filter(
      (word) => !word.includes("*"),
    );
  }
  return { rhymes: resultRhymes, thesaurus: resultThesaurus };
};

export const RhymesAndThesaurus = () => {
  const [rhymes, setRhymes] = useState<string[]>([]);
  const [thesaurus, setThesaurus] = useState<string[]>([]);
  const [currentSearch, setCurrentSearch] = useState<string>("");

  const onCancelSearch = () => {
    setRhymes([]);
    setThesaurus([]);
  };

  const displaySearchResults = (section: string, words: string[]) => {
    if (words.length > 0) {
      return <ResultsSection title={section} words={words} css={css} />;
    }
    return <NoResultsSection title={`No ${section} Found.`} css={css} />;
  };

  useEffect(() => {
    const keyboardSearchDelay = 300;
    const delayFn = setTimeout(() => {
      if (currentSearch.trim() === "") {
        setRhymes([]);
        setThesaurus([]);
      } else {
        (async () => {
          const { rhymes, thesaurus } = await fetchRhymesAndThesaurus(
            currentSearch,
          );
          setRhymes(rhymes);
          setThesaurus(thesaurus);
        })();
      }
    }, keyboardSearchDelay);

    return () => clearTimeout(delayFn);
  }, [currentSearch]);

  return (
    <div className={css.main_wrapper}>
      <SearchBar
        style={{
          borderRadius: "100px",
          fontFamily: "Raleway",
          color: "#5F6367",
          marginBottom: "10px",
        }}
        placeholder="Search a word..."
        value={currentSearch}
        onChange={setCurrentSearch}
        onCancelSearch={onCancelSearch}
      />
      {displaySearchResults("Rhymes", rhymes)}
      {displaySearchResults("Thesaurus", thesaurus)}
    </div>
  );
};
