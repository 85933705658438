import { createSelector } from "reselect";
import { parse, startOfWeek } from "date-fns";
import { TODAY, MONDAY } from "constants/date_fns_constants";
import { IState } from "redux/store";
import { IUserSession } from "api/user_session_api";
import { getProgressionsAmountByInstrument } from "../progressions/progressions_selectors";
import { getTransitionsAmountByInstrument } from "../transitions/transitions_selectors";
import {
  getUserTransitionsAmountByInstrument,
  getUserProgressionsAmountByInstrument,
  getUserChordsAmountByInstrument,
} from "../user_skills/user_skills_selector";
import { getChordsAmountByInstrument } from "../chords/chords_selectors";
import { IInstruments } from "../user_skills/user_skills_slice";

export const minutesPerDaySelector = (state: IState): IUserSession[] =>
  state.myJourney.minutesPerDay;

export const timerSelector = (state: IState) => state.myJourney.timer;

export const ZERO_TIME = 0;
export const totalTimeSelector = createSelector(
  [minutesPerDaySelector],
  (minutesPerDay) =>
    minutesPerDay.reduce((prev, current) => current.minutes + prev, ZERO_TIME),
);

export const progressPercentageByInstrument = (
  instrument: IInstruments,
) =>
  createSelector(
    [
      getChordsAmountByInstrument(instrument),
      getTransitionsAmountByInstrument(instrument),
      getProgressionsAmountByInstrument(instrument),
      getUserChordsAmountByInstrument(instrument),
      getUserTransitionsAmountByInstrument(instrument),
      getUserProgressionsAmountByInstrument(instrument),
    ],
    (...args) => calcProgressPercentage(...args),
  );

const CHORD_PERCENTAGE = 5;
const TRANSITIONS_PERCENTAGE = 10;
const PROGRESSIONS_PERCENTAGE = 10;
const ROUND_TO = 1;

export const calcProgressPercentage = (
  chordsCount: number,
  transitionsCount: number,
  progressionsCount: number,
  userChordsCount: number,
  userTransitionsCount: number,
  userProgressionsCount: number,
) => {
  return Number(
    (
      (userChordsCount / chordsCount) * CHORD_PERCENTAGE +
      (userTransitionsCount / transitionsCount) * TRANSITIONS_PERCENTAGE +
      (userProgressionsCount / progressionsCount) * PROGRESSIONS_PERCENTAGE
    ).toFixed(ROUND_TO),
  );
};

export const reduceThisWeekMinutes = (minutesPerDay: IUserSession[]) => {
  const thisWeekMonday = startOfWeek(TODAY, { weekStartsOn: MONDAY });
  return minutesPerDay
    .filter((date) => {
      const dateValue = parse(date.day, "yyyy-M-d", new Date());
      dateValue.setHours(0, 0, 0, 0);
      return dateValue >= thisWeekMonday;
    })
    .reduce((acc, date) => acc + date.minutes, ZERO_TIME);
};

export const thisWeekMinutesSelector = createSelector(
  [minutesPerDaySelector],
  reduceThisWeekMinutes,
);

export const skillsTo25PercentageByInstrument = (instrument: IInstruments) =>
  createSelector(
    [
      getChordsAmountByInstrument(instrument),
      getTransitionsAmountByInstrument(instrument),
      getProgressionsAmountByInstrument(instrument),
      getUserChordsAmountByInstrument(instrument),
      getUserTransitionsAmountByInstrument(instrument),
      getUserProgressionsAmountByInstrument(instrument),
    ],
    (
      chordsCount,
      transitionsCount,
      progressionsCount,
      userChordsCount,
      userTransitionsCount,
      userProgressionsCount,
    ) =>
      chordsCount -
      userChordsCount +
      transitionsCount -
      userTransitionsCount +
      progressionsCount -
      userProgressionsCount,
  );
