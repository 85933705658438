import { useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { onMoosikoBtnClick } from "redux/ui/modal/modal_slice";
import { ILesson } from "api/lesson_api";
import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import _ from "lodash";
import { SongCard } from "../my_journey_song_card/my_journey_song_card";
import { STRUMMING } from "../../../constants/skills_constants";
import css from "./cards_content.module.scss";

const FIRST_STRUMMING_PATTERN = 0;

interface IStrummingSongs {
  songs: IUserStrummingLessons;
}

export const StrummingSongs = (props: IStrummingSongs) => {
  const { songs } = props;
  const [selectedStrumming, setSelectedStrumming] = useState<number | null>(
    null,
  );
  const [tabNumberToStrumming, setTabNumberToStrumming] = useState<
    (keyof IUserStrummingLessons)[]
  >([]);
  const handleChange = (_: unknown, newValue: number) => {
    setSelectedStrumming(newValue);
  };
  const dispatch = useDispatch();
  const [atLeastOneStrummingSong, setAtLeastOneStrummingSong] = useState(false);

  const createTabs = () => {
    const tabNumberToStrummingOldLength = tabNumberToStrumming.length;

    const newTabNumberToStrumming = [...tabNumberToStrumming];

    const tabs = Object.keys(songs).map((strummingPattern) => {
      const typedStrummingPattern =
        strummingPattern as unknown as keyof IUserStrummingLessons;
      if (typedStrummingPattern === "count") {
        return null;
      }
      if (songs[typedStrummingPattern].length) {
        if (!newTabNumberToStrumming.includes(typedStrummingPattern)) {
          newTabNumberToStrumming.push(typedStrummingPattern);
        }
        if (!atLeastOneStrummingSong) {
          setSelectedStrumming(FIRST_STRUMMING_PATTERN);
          setAtLeastOneStrummingSong(true);
        }
        return <Tab key={strummingPattern} label={strummingPattern} />;
      }
      return null;
    });
    if (newTabNumberToStrumming.length !== tabNumberToStrummingOldLength) {
      setTabNumberToStrumming([...newTabNumberToStrumming]);
    }
    return tabs;
  };

  const songCards =
    !_.isNil(selectedStrumming) &&
    (
      songs[tabNumberToStrumming[selectedStrumming as number]] as ILesson[]
    )?.map((lesson) => {
      return <SongCard key={lesson.song.song_name} lesson={lesson} />;
    });

  return (
    <div className={cn(css.wrapper)}>
      <div className={cn(css.skills_wrapper)}>
        <div className={cn(css.strumming_wrapper)}>
          <Tabs
            value={selectedStrumming}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="simple tabs example"
          >
            {createTabs()}
          </Tabs>
          {atLeastOneStrummingSong ? null : "No strumming skills"}
          <div className={css.strumming_songs}>{songCards}</div>
          <button
            className={cn(css.learnNew)}
            type="button"
            onClick={() => {
              dispatch(onMoosikoBtnClick({ skillType: STRUMMING }));
            }}
          >
            Learn New Strumming Pattern
          </button>
        </div>
      </div>
    </div>
  );
};
