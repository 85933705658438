/* eslint-disable no-unused-vars */
export enum TSongwritingAssessmentSortDirections {
  ASC = "asc",
  DESC = "desc",
}

export enum TSongwritingAssessmentSortTypes {
  STUDENT_NAME = "STUDENT_NAME",
  SONG_NAME = "SONG_NAME",
  CREATE_DATE = "CREATE_DATE",
  VIEW_SONG = "VIEW_SONG",
}
