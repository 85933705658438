import { TEACHER_ROLES, ROLE_IDS, ROLES } from "./roles_consts";

export const isTeacherRole = (role: string) => {
  return TEACHER_ROLES.includes(role);
};

export const roleFromRoleId = (
  role: number | null,
  mobile?: boolean | null,
) => {
  switch (role) {
    case ROLE_IDS.FREE_USER:
      return ROLES.FREE_USER;
    case ROLE_IDS.INDIVIDUAL:
      return ROLES.INDIVIDUAL;
    case ROLE_IDS.FREE_TEACHER_STUDENT:
      return ROLES.FREE_TEACHER_STUDENT;
    case ROLE_IDS.PAID_TEACHER_STUDENT:
      return ROLES.PAID_TEACHER_STUDENT;
    case ROLE_IDS.SOLO_TEACHER:
      return ROLES.SOLO_TEACHER;
    case ROLE_IDS.FREE_TEACHER_ACCOUNT:
      return ROLES.FREE_TEACHER_ACCOUNT;
    case ROLE_IDS.PAID_TEACHER_ACCOUNT:
      return ROLES.PAID_TEACHER_ACCOUNT;
    case ROLE_IDS.DISTRICT_ADMINISTRATOR:
      return ROLES.DISTRICT_ADMINISTRATOR;
    case ROLE_IDS.ADMIN:
      return ROLES.ADMIN;
    case ROLE_IDS.FREE_SONGWRITING:
      return ROLES.FREE_SONGWRITING;
    case ROLE_IDS.PAID_SONGWRITING:
      return ROLES.PAID_SONGWRITING;
    default:
      return mobile ? ROLES.FREE_SONGWRITING : ROLES.FREE_USER;
  }
};
