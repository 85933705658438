import { Box, Button, Typography } from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { useCreateNewSong } from "hooks/use_create_new_song";
import { IAiResultSong } from "../seed_note";

interface IAiExpandedNoteCardProps {
  song: IAiResultSong;
  songCount: number;
}

const AiExpandedNoteCard = ({ song, songCount }: IAiExpandedNoteCardProps) => {
  const { createAiSongHandler } = useCreateNewSong();

  const createSongFromAiSeed = () => {
    createAiSongHandler({ title: song.title, description: song.description });
  };

  return (
    <Box>
      <Box
        display="grid"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ gap: 10, margin: 20 }}
      >
        <Typography
          style={{
            gridColumn: 1,
            fontSize: "16px",
            color: "#190067",
            fontWeight: "600",
            fontFamily: "Raleway",
          }}
        >{`${songCount}.`}</Typography>
        <Typography
          style={{
            gridColumn: 2,
            fontSize: "16px",
            color: "#190067",
            fontWeight: "600",
            fontFamily: "Raleway",
          }}
        >
          {song.title}
        </Typography>

        <Typography
          style={{
            gridColumn: 2,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
            fontFamily: "Raleway",
          }}
        >
          {song.description}
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Button
          variant="contained"
          onClick={createSongFromAiSeed}
          startIcon={<ControlPointIcon />}
          style={{
            borderRadius: 35,
            backgroundColor: "#190067",
            color: "#FFFFFF",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            textTransform: "none",
            fontFamily: "Raleway",
          }}
        >
          Create New Song
        </Button>
      </Box>
    </Box>
  );
};

export default AiExpandedNoteCard;
