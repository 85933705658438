import { timeSince } from "helpers/timestampToSince";
import React, { MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import {
  ICustomSong,
  ISharedSong,
} from "redux/songwriting/songwriting_interfaces";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";

import { Box, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { SongCardOptions } from "./song_card_options/song_card_options";
import { EditableSongTitle } from "./edtiable_song_title/editable_song_title";
import { MainPageSongPlayer } from "./main_page_song_player/main_page_song_player";

import css from "./song_card.module.scss";

interface ISongCard {
  song_data: ICustomSong | null;
  src: string;
  isSharedSong: boolean;
  sharedSong?: ISharedSong;
}

export const SongCard = ({
  src,
  song_data,
  isSharedSong = false,
  sharedSong,
}: ISongCard) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const mobile = useSelector(getUsingMobileAppFlag);

  const openOptionsHandler = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeOptionsHandler = () => {
    setMenuAnchor(null);
  };

  const generateSubscription = (
    editedAt?: string | Date | null,
    viewedAt?: string | Date | null,
  ) => {
    const editedSubscription = editedAt
      ? `Edited ${timeSince(editedAt)} ago`
      : "Never edited";
    const viewedSubscription = viewedAt
      ? `You viewed ${timeSince(viewedAt)} ago`
      : "Never viewed";
    return `${viewedSubscription} - ${editedSubscription}`;
  };

  const subscription = isSharedSong
    ? generateSubscription(sharedSong?.editedAt, sharedSong?.viewedAt)
    : generateSubscription(song_data?.edited_at, song_data?.viewed_at);

  const current_song_id = sharedSong
    ? sharedSong.sharedSong.custom_song_id
    : song_data?.id;
  const current_song_title = sharedSong
    ? sharedSong.sharedSong.custom_song.song_name
    : song_data?.song_name;

  return (
    <>
      <Box className={css.card_wrapper}>
        <Box className={css.title_wrapper}>
          <EditableSongTitle
            song_id={current_song_id}
            isEditingTitle={isEditingTitle}
            sharedSong={sharedSong}
            setIsEditingTitle={setIsEditingTitle}
            song_title={current_song_title || ""}
          />
          <p className={css.subtext}>{subscription}</p>
          {src && mobile && <MainPageSongPlayer src={src} />}
        </Box>
        {src && !mobile && <MainPageSongPlayer src={src} />}
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={openOptionsHandler}
        >
          <MoreVertIcon />
        </IconButton>
        <SongCardOptions
          song_id={song_data?.id || sharedSong?.sharedSong.custom_song_id}
          setIsEditingTitle={setIsEditingTitle}
          menuAnchor={menuAnchor}
          closeOptionsHandler={closeOptionsHandler}
          isSharedSong={isSharedSong}
        />
      </Box>
      <hr />
    </>
  );
};

export const MemoizedSongCard = React.memo(SongCard);
