/* eslint-disable jsx-a11y/no-autofocus */
import { useDispatch, useSelector } from "react-redux";
import { updateCustomSong } from "redux/songwriting/songwriting_slice";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import css from "./editable_title.module.scss";

interface IEditableTitle {
  title: string;
}

export const EditableTitle = ({ title }: IEditableTitle) => {
  const mobile = useSelector(getUsingMobileAppFlag);
  const [currentTitle, setCurrentTitle] = useState(title);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    setIsEditing(true);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setCurrentTitle(e.target.value);
    setIsEditing(false);
    if (title === e.target.value) {
      return;
    }
    if (e.target.value.length === 0) {
      setCurrentTitle(title);
      return;
    }
    dispatch(updateCustomSong({ song_name: e.target.value }));
  };

  const handleCancel = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    setCurrentTitle(title);
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && title === "New Song Title") {
      inputRef.current?.select();
    }
  }, [isEditing, title]);

  return isEditing ? (
    <>
      <input
        autoFocus
        onBlur={handleBlur}
        defaultValue={currentTitle}
        className={mobile ? css.song_title_input_mobile : css.song_title_input}
        enterKeyHint="enter"
        ref={inputRef}
      />
      <div className={css.icons_wrapper}>
        <CheckIcon className={css.icon} />
        <CloseIcon className={css.icon} onMouseDown={handleCancel} />
      </div>
    </>
  ) : (
    <h2
      role="presentation"
      onClick={handleClick}
      className={mobile ? css.song_title_mobile : css.song_title}
    >
      {currentTitle}
    </h2>
  );
};
