import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { openLoginModal } from "redux/ui/modal/modal_slice";

import { MOBILE_SIGNUP, SIGNUP } from "routes/route_constants";

interface IAuthLinks {
  css?: { [key: string]: string };
}
export const AuthLinks = (props: IAuthLinks) => {
  const { css } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);
  return (
    <div className={css?.links}>
      <button
        type="button"
        onClick={() => dispatch(openLoginModal())}
        className={css?.link}
      >
        Log In
      </button>
      <button
        type="button"
        onClick={() => history.push(mobile ? MOBILE_SIGNUP : SIGNUP)}
        className={css?.link}
      >
        Sign Up
      </button>
    </div>
  );
};
