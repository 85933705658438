import axios from "axios";
import { getNodeApiBaseUrl } from "../../api/base_api";

export const videoApi = {
  // async uploadVideo(video) {
  //   const url = `${getNodeApiBaseUrl()}/video/`;
  //   const formData = new FormData();
  //   formData.append("video", video);
  //   const { data } = await axios.post(url, formData, {
  //     headers: { "Content-Type": "multipart/form-data" },
  //   });
  //   return data.VIDEO_KEY;
  // },

  async getVideoFromS3(videoUrl) {
    if (!videoUrl) {
      return "";
    }
    // eslint-disable-next-line no-magic-numbers
    if (videoUrl.slice(0, 5) === "https") {
      // if it is old public video
      return videoUrl;
    }
    const url = `${getNodeApiBaseUrl()}/video`;
    const { data } = await axios.get(url, { params: { videoUrl } });
    return data.VIDEO_URL;
  },
};
