import { createSelector } from "reselect";
import { IState } from "redux/store";

const getOrgManagement = (state: IState) => {
  return state.entities.orgManagement;
};

export const getOrgManagementOrganizations = createSelector(
  getOrgManagement,
  (orgManagement) => orgManagement.organizations,
);
