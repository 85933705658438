import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TStepListIds } from "./stepListConstants";

export type TStepId = string | number;

const initialState: IInitialStateStepList = {};

interface IInitialStateStepList {
  [key: string]: IStepList;
}

export interface IInitStepList {
  stepListId: TStepListIds;
  items: {
    id: TStepId;
  }[];
  setStartStep: TStepId;
}

interface IStepList {
  stepListId: TStepListIds;
  items: {
    id: TStepId;
  }[];
  currentStep: TStepId;
}

const stepListSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    initStepList(state, action: PayloadAction<IInitStepList>) {
      const { stepListId, items, setStartStep } = action.payload;
      const currentStep = setStartStep || items[0].id;
      return {
        ...state,
        [stepListId]: { stepListId, items, currentStep },
      };
    },
    nextStep(state, action: PayloadAction<{ stepListId: TStepListIds }>) {
      const { stepListId } = action.payload;

      const stepListState = state[stepListId];
      const { items, currentStep } = stepListState;
      const currentStepIndex = items.findIndex((i) => i.id === currentStep);
      const nextIndex = currentStepIndex + 1;

      if (!items[nextIndex]) {
        return state;
      }

      return {
        ...state,
        [stepListId]: {
          ...stepListState,
          currentStep: items[nextIndex].id,
        },
      };
    },
    prevStep(state, action: PayloadAction<{ stepListId: TStepListIds }>) {
      const { stepListId } = action.payload;
      const stepListState = state[stepListId];
      const { items, currentStep } = stepListState;

      const currentStepIndex = items.findIndex((i) => i.id === currentStep);
      const prevIndex = currentStepIndex - 1;

      if (!items[prevIndex]) {
        return state;
      }

      return {
        ...state,
        [stepListId]: {
          ...stepListState,
          currentStep: items[prevIndex].id,
        },
      };
    },
    setStep(
      state,
      action: PayloadAction<{ stepListId: TStepListIds; stepId: TStepId }>,
    ) {
      const { stepListId, stepId } = action.payload;
      const stepListState = state[stepListId];
      return {
        ...state,
        [stepListId]: {
          ...stepListState,
          currentStep: stepId,
        },
      };
    },
    resetStepList() {
      return { ...initialState };
    },
  },
});
export const stepListActions = stepListSlice.actions;

export const { reducer: stepListReducer } = stepListSlice;
