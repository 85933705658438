import { createSelector } from "reselect";
import { IState } from "redux/store";

const songwritingMobileState = (state: IState) => state.songwritingMobile;

export const getSongSeedTabValue = createSelector(
  songwritingMobileState,
  (songwritingMobile) => songwritingMobile.songSeedTab,
);

export const getShowSelectSongOverlay = createSelector(
  songwritingMobileState,
  (songwritingMobile) => songwritingMobile.showSelectSongOverlay,
);

export const getAddChordPosition = createSelector(
  songwritingMobileState,
  (songwritingMobile) => songwritingMobile.addChordPosition,
);

export const getAddChordClickPosition = createSelector(
  songwritingMobileState,
  (songwritingMobile) => songwritingMobile.addChordClickPosition,
);

export const getAddChordMethod = createSelector(
  songwritingMobileState,
  (songwritingMobile) => songwritingMobile.addChordMethod,
);
export const getAddChordDrawerOpen = createSelector(
  songwritingMobileState,
  (songwritingMobile) => songwritingMobile.addChordDrawerOpen,
);
