// @ts-nocheck

class GAUtil {
  /**
   * PageView could be setup in GTM but our urls with hash prevent it.
   * This is why we track PageView from project codebase
   */
  pageView() {
    const split = window.location.href.split("#");
    window.gtag("set", {
      page_path: split[1],
      page_location: split[0],
    });
    window.gtag("event", "page_view", {});
  }
}

export const gaUtil = new GAUtil();
