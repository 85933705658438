import { ISlide } from "api/lesson_api";
import css from "./slide_types.module.scss";

interface IPartBegin {
  slide: ISlide;
}

export const PartBegin = ({ slide }: IPartBegin) => {
  const { text_top, text_middle, text_bottom } = slide;
  return (
    <div className={css.main}>
      <h1 className={`${css.text_top} ${css.top_begin_end}`}>{text_top}</h1>
      <p className={css.part_begin}>{text_middle}</p>
      <p
        className={css.part_begin}
        style={{ textAlign: "center", marginTop: "10px" }}
      >
        {text_bottom}
      </p>
    </div>
  );
};
