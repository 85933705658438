import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";
import {
  getCurrentSeed,
  getSeedTitle,
} from "redux/song_seeds/song_seed_selector";

import { IAiResults, IAiResultSong } from "../seed_note";
import AiExpandedNoteCard from "./ai_expanded_note_card";

interface IAiExpandSeedNoteProps {
  aiResults: IAiResults | null;
}

const AiExpandSeedNote = ({ aiResults }: IAiExpandSeedNoteProps) => {
  const currentSeed = useSelector(getCurrentSeed);
  const currentSeedTitle = useSelector(getSeedTitle(currentSeed));
  const numberOfSkeletons = 3;

  return (
    <Box style={{ marginBottom: 100 }}>
      <Box id="AiExpandSeedNoteTitle">
        <Typography
          style={{
            textAlign: "center",
            margin: 20,
            fontSize: "18px",
            color: "#190067",
            fontWeight: "700",
            fontFamily: "Raleway",
          }}
        >{`Expand ${currentSeedTitle} Song Idea`}</Typography>
      </Box>
      {aiResults ? (
        aiResults.Songs.map((song: IAiResultSong, index) => (
          <AiExpandedNoteCard
            key={uuidv4()}
            song={song}
            songCount={index + 1}
          />
        ))
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ margin: 20 }}
        >
          {[...Array(numberOfSkeletons)].map(() => (
            <React.Fragment key={uuidv4()}>
              <Skeleton width="90vw" height="20px" />
              <Skeleton width="90vw" height="200px" />
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AiExpandSeedNote;
