export const UKULELE_NOTES = [
  { note: "G", frequency: "392" },
  { note: "C", frequency: "261.63" },
  { note: "E", frequency: "329.63" },
  { note: "A", frequency: "440" },
];
export const GUITAR_NOTES = [
  { note: "E", frequency: "82.41" },
  { note: "A", frequency: "110" },
  { note: "D", frequency: "146.83" },
  { note: "G", frequency: "196.00" },
  { note: "B", frequency: "246.94" },
  { note: "e", frequency: "329.63" },
];
