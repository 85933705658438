import axios from "axios";
import { IOrganization } from "redux/organizations/organization_api";
import { IUser } from "redux/auth/auth_api";
import { getNodeApiBaseUrl } from "../../api/base_api";

export interface IOrgWithStudents extends IOrganization {
  users?: IUser[];
  students?: number;
  teacher: IUser | undefined;
}

export const orgManagementApi = {
  fetchOrganizations() {
    const url = `${getNodeApiBaseUrl()}/organizations/`;
    return axios.get<{ organizations: IOrgWithStudents[] }>(url, {});
  },
  updateOrganization(org: Partial<IOrganization>) {
    const url = `${getNodeApiBaseUrl()}/organizations/${org.id}`;
    return axios.patch<void>(url, org);
  },
};
