/* eslint-disable no-magic-numbers */
import Tuner from "./tuner";
import Notes from "./notes";
import Meter from "./meter";
import FrequencyBars from "./frequency-bars";

const Application = function () {
  this.tuner = new Tuner();
  this.notes = new Notes(".notes", this.tuner);
  this.meter = new Meter(".meter");
  this.frequencyBars = new FrequencyBars(".frequency-bars");
  this.update({ name: "A", frequency: 440, octave: 4, value: 69, cents: 0 });
};

Application.prototype.start = function () {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  this.tuner.onNoteDetected = function (note) {
    if (self.notes.isAutoMode) {
      if (self.lastNote === note.name) {
        self.update(note);
      } else {
        self.lastNote = note.name;
      }
    }
  };

  self.tuner.init();

  self.frequencyData = new Uint8Array(self.tuner.analyser.frequencyBinCount);

  // if (!/Android/i.test(navigator.userAgent)) {
  this.updateFrequencyBars();
  // }
};

Application.prototype.updateFrequencyBars = function () {
  if (this.tuner.analyser) {
    this.tuner.analyser.getByteFrequencyData(this.frequencyData);
    this.frequencyBars.update(this.frequencyData);
  }
  this.animationFrame = requestAnimationFrame(
    this.updateFrequencyBars.bind(this),
  );
};

Application.prototype.update = function (note) {
  this.notes.update(note);
  this.meter.update((note.cents / 50) * 45);
};

// noinspection JSUnusedGlobalSymbols
Application.prototype.toggleAutoMode = function () {
  this.notes.toggleAutoMode();
};

export default Application;
