import styled from "@emotion/styled";
import { LogoWithBackground } from "components/sign_up/reusable/background_image";
import { DESKTOP } from "scss/emotion_consts";
import { Container, Header } from "../emotion.styles";

export const StyledHeader = styled(Header)`
  margin: 1em;

  @media ${DESKTOP} {
    margin-top: 2em;
  }
`;

export const StyledLogoWithBackground = styled(LogoWithBackground)`
  padding: 33px 29px;
  margin: 0.8em 0 1em;

  @media ${DESKTOP} {
    margin: 1em 0 3em;
  }
`;

export const StyledContainer = styled(Container)`
  justify-content: flex-start;
`;

export const StyledButton = styled.div`
  margin: 1.3em 0em 1em;
  align-self: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em;

  @media ${DESKTOP} {
    margin: 2em;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 240px;
  margin: 1em;
  padding: 1.4em 1.6em;
  border-radius: 4px;
  border: 0.5px solid #c4c4c4;
  box-shadow: 0px 2px 6px rgba(182, 182, 182, 0.24);

  > span {
    margin-bottom: 2em;
  }
`;
export const CardHeader = styled.div`
  color: #190067;
  font-weight: 600;
  margin-bottom: 3em;
`;

export const HowPay = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;

  span {
    color: #6772e5;
    font-size: 2.5em;
  }
`;

export const Dollar = styled.div`
  color: #6772e5;
`;

export const Description = styled.div`
  color: rgba(51, 51, 51, 0.42);
  padding-bottom: 1em;
  font-weight: 600;
  margin-bottom: 1em;
`;
