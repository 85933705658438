import classNames from "classnames";

import css from "./feedback_header.module.scss";

export const FeedBackHeader = () => {
  return (
    <header className={css.container}>
      <div className={classNames(css.row_item, css.createdAt)}>
        Submission Date
      </div>
      <div className={classNames(css.row_item, css.skillTypeName)}>
        Skill Type
      </div>
      <div className={css.row_item}>Skill </div>
      <div className={css.row_item}>Song Lesson</div>
      <div className={classNames(css.row_item, css.play_video)}>Video File</div>
      <div className={css.row_item}>Feedback</div>
    </header>
  );
};
