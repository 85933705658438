import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IChangeToggle } from "redux/organizations/organization_slice";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { IState } from "redux/store";

export const useProfileSwitch = (
  changeToggleAction: (_: IChangeToggle) => void,
  selector: (_: IState) => boolean,
): [() => void, boolean] => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrganizationId);
  const userId = useSelector(getUserIdSelector);
  const reduxToggleValue = useSelector(selector);
  const [toggle, setToggle] = useState(reduxToggleValue);

  useEffect(() => {
    setToggle(reduxToggleValue);
  }, [setToggle, reduxToggleValue]);

  if (!userId || !orgId) {
    toast.error("User is not found");
    throw userId;
  }

  const handleToggleChange = () => {
    setToggle(!toggle);
    dispatch(changeToggleAction({ userId, orgId, value: !toggle }));
  };

  return [handleToggleChange, toggle];
};
