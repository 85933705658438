import styled from "@emotion/styled";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
`;
export const Plan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2.5em;
  width: 250px;

  white-space: break-spaces;
  text-align: center;
`;
export const PlanName = styled.div`
  font-size: 1.2em;
  color: #190067;
  font-weight: 600;
  margin-top: 2em;
  margin-bottom: 1.5em;
`;

export const Img = styled.img`
  object-fit: none;
`;
export const ContainerImg = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledButton = styled.div`
  margin: 1.3em 1em 1em;
`;
