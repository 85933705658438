import React from "react";
import { Dialog } from "@material-ui/core";
import { Video } from "components/video";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { setMediaRecordsStateStart } from "redux/media_recordings/media_recordings_slice";
import css from "./send_video_dialog.module.scss";

interface ISendVideoDialog {
  closeSendVideoDialog: (_: React.MouseEvent<HTMLButtonElement>) => void;
  sendVideoDialog: boolean;
  handleSubmitVideo: () => void;
  recorderVideoSrc: string;
}

export const SendVideoDialog = ({
  closeSendVideoDialog,
  sendVideoDialog,
  handleSubmitVideo,
  recorderVideoSrc,
}: ISendVideoDialog) => {
  const dispatch = useDispatch();

  const resetVideo = (e: React.MouseEvent<HTMLButtonElement>) => {
    closeSendVideoDialog(e);
    dispatch(setMediaRecordsStateStart());
  };

  return (
    <Dialog
      PaperProps={{ classes: { root: css.paper } }}
      open={sendVideoDialog}
      onClose={closeSendVideoDialog}
    >
      <div className={css.send_video_dialog}>
        <Video src={recorderVideoSrc} />
        <div className={css.buttons_container}>
          <button
            className={css.redo_button}
            onClick={resetVideo}
            type="button"
          >
            Redo
          </button>
          <button
            onClick={handleSubmitVideo}
            className={css.submit_button}
            type="button"
          >
            Submit
          </button>
        </div>
      </div>
      <button
        onClick={closeSendVideoDialog}
        type="button"
        className={css.close_dialog_button}
      >
        <CloseIcon fontSize="inherit" />
      </button>
    </Dialog>
  );
};
