import React from "react";
import { timestampToDate } from "helpers/timestampToSince";
import { useSelector } from "react-redux";
import {
  getActiveLyricSeeds,
  getActiveSongSeeds,
} from "redux/song_seeds/song_seed_selector";
import { Box, Typography } from "@material-ui/core";
import { ISongSeed } from "redux/song_seeds/song_seed_interfaces";
import downArrow from "../assets/sketchArrowDown.png";
import { SeedCard } from "./seed_card";

interface ISeedCards {
  type: string;
  setSeedAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

interface IGroupedSeeds {
  period: String;
  periodSeeds: ISongSeed[];
}

export const SeedCards = ({ type, setSeedAnchorEl }: ISeedCards) => {
  const songSeeds = useSelector(getActiveSongSeeds);
  const lyricSeeds = useSelector(getActiveLyricSeeds);

  const seedList = type === "song" ? songSeeds : lyricSeeds;

  const sortedSeeds = [...seedList].sort((a, b) => {
    const date1 = new Date(a.updatedAt);
    const date2 = new Date(b.updatedAt);
    return date2.getTime() - date1.getTime();
  });

  const currentDate = new Date();
  const today = new Date(currentDate);
  today.setHours(0, 0, 0, 0);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const millisecondsForDays = (days: number) => days * millisecondsPerDay;
  const periodSeven = 7;
  const periodThirty = 30;
  const last7Days = new Date(
    today.getTime() - millisecondsForDays(periodSeven),
  );
  const last30Days = new Date(
    today.getTime() - millisecondsForDays(periodThirty),
  );

  const includedSeeds = new Set();

  const groupedSongs = sortedSeeds.reduce((acc: IGroupedSeeds[], song) => {
    const songDate = new Date(song.updatedAt);
    const year = songDate.getFullYear();

    let addedToPeriod = false;

    if (songDate > today && !addedToPeriod) {
      addToPeriod("Today");
    } else if (songDate > last7Days && !addedToPeriod) {
      addToPeriod("Last 7 Days");
    } else if (songDate > last30Days && !addedToPeriod) {
      addToPeriod("Last 30 Days");
    } else if (!addedToPeriod) {
      addToPeriod(year.toString());
    }

    function addToPeriod(period: String) {
      const existingPeriod = acc.find((item) => item.period === period);
      if (existingPeriod) {
        existingPeriod.periodSeeds.push(song);
      } else {
        acc.push({
          period,
          periodSeeds: [song],
        });
      }
      includedSeeds.add(song);
      addedToPeriod = true;
    }

    return acc;
  }, []);

  const formatDate = (editedAt: Date | string) => {
    if (typeof editedAt === "string") {
      editedAt = timestampToDate(editedAt);
    }

    const month = editedAt.getMonth() + 1; // Months are zero-indexed, so we add 1
    const day = editedAt.getDate();
    const year = editedAt.getFullYear() % 100; // Get last two digits of the year

    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
  };

  const textStyle = {
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: "18px",
    color: "#5F6367",
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 15,
        marginTop: 20,
      }}
    >
      {groupedSongs.length === 0 ? (
        <Box>
          <Box
            position="absolute"
            right="65px"
            bottom="45px"
            style={{ transform: "rotate(-15deg)" }}
          >
            <img src={downArrow} alt="pointer" width="200px" />
          </Box>
          <Box
            position="absolute"
            bottom="225px"
            right="150px"
            display="flex"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <Typography style={textStyle}>
              {type === "song"
                ? "Have an idea for a new song?"
                : "Just think of a great lyric?"}
            </Typography>
            <Typography style={textStyle}>Click here to capture it</Typography>
          </Box>
        </Box>
      ) : (
        groupedSongs.map((value, id) => {
          const groupKey = id;
          return (
            <Box
              key={groupKey}
              display="flex"
              flexDirection="column"
              style={{ gap: 10 }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#6b6868",
                  fontFamily: "Raleway",
                }}
              >
                {value.period}
              </Typography>
              {value.periodSeeds.map((value) => {
                return (
                  <SeedCard
                    key={value.id}
                    seed_id={value.id}
                    lastUpdated={formatDate(value.updatedAt)}
                    firstLine={value.text || ""}
                    title={value.title || ""}
                    type={type}
                    setSeedAnchorEl={setSeedAnchorEl}
                  />
                );
              })}
            </Box>
          );
        })
      )}
    </Box>
  );
};
