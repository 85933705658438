import React from "react";
// @ts-ignore
import { MarginProperty, PaddingProperty } from "csstype";
import styled from "@emotion/styled";

interface IBackgroundImage extends IContainerImg {
  alt?: string;
  src: string;
}
type IContainerImg = {
  margin?: MarginProperty<"">;
  padding?: PaddingProperty<"">;
};

export const LogoWithBackground = ({
  src,
  alt,
  margin,
  padding,
  ...props
}: IBackgroundImage) => {
  return (
    <ContainerImg margin={margin} padding={padding} {...props}>
      <Img src={src} alt={alt} />
    </ContainerImg>
  );
};

const Img = styled.img`
  object-fit: none;
`;

const ContainerImg = styled.div<IContainerImg>`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(103, 117, 222, 0.26);
  border-radius: 50px;
  margin: ${({ margin = "0" }) => margin};
  padding: ${({ padding = "0" }) => padding};
`;
