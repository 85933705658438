import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentCustomSongLyrics,
  getActualTableDataById,
  getActualTableData,
} from "redux/songwriting/songwriting_selectors";
import {
  updateCurrentSongLyricsBucket,
  updateCustomSongLyricsById,
  updateTableDataRows,
} from "redux/songwriting/songwriting_slice";
import { RowType } from "redux/songwriting/songwriting_interfaces";

interface IUseCollectionChanged {
  id?: number;
}

const SEPARATOR = "/n";

export const useSongUnitTableContent = ({ id }: IUseCollectionChanged = {}) => {
  const dispatch = useDispatch();

  const songLyrics = useSelector(getCurrentCustomSongLyrics(id as number));
  const tableData = useSelector(getActualTableDataById(id as number));

  const tableDataArray = useSelector(getActualTableData);

  const getPreparedSongLyricsData = (lyrics: RowType[]) => {
    return lyrics
      .filter((item, index) => index % 2 !== 0)
      .map((item) => item.c0)
      .join(SEPARATOR);
  };

  const updateRowValue = (value: string, rowIndex: number) => {
    if (id) {
      dispatch(
        updateTableDataRows({
          songUnitId: id,
          index: rowIndex,
          inputValue: value,
        }),
      );
    }
  };

  const collectAndSaveAllUnits = useCallback(() => {
    const preparedBucketLyrics = tableDataArray.map(({ data, id }) => {
      return { id, song_lyrics: getPreparedSongLyricsData(data) };
    });

    preparedBucketLyrics.forEach((lyricsItem) => {
      dispatch(updateCustomSongLyricsById(lyricsItem));
    });
  }, [dispatch, tableDataArray]);

  const collectChanges = useCallback(
    ({ lyricsData = tableData }: { lyricsData?: RowType[] } = {}) => {
      if (lyricsData) {
        const currentSongLyrics = getPreparedSongLyricsData(lyricsData);

        dispatch(
          updateCurrentSongLyricsBucket({
            song_lyrics: currentSongLyrics,
          }),
        );

        if (songLyrics !== currentSongLyrics) {
          collectAndSaveAllUnits();
        }
        return currentSongLyrics;
      }
    },
    [tableData, dispatch, songLyrics, collectAndSaveAllUnits],
  );

  return {
    collectChanges,
    collectAndSaveAllUnits,
    getPreparedSongLyricsData,
    updateRowValue,
  };
};
