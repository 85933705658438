import React from "react";
import { CardContent } from "./card_content/card_content";

export const NO_CARD = 0;
export const CHORDS_CARD = 1;
export const TRANSITIONS_CARD = 2;
export const PROGRESSIONS_CARD = 3;
export const SONGS_CARD = 4;
export const STRUMMING_GUITAR_CARD = 5;
export const STRUMMING_UKULELE_CARD = 6;

export interface ICard {
  measurementValue: number;
  text: string;
  progress?: number;
  handleClick: () => void;
  active: boolean;
  isOtherActive: boolean;
  children: React.ReactNode;
  line: boolean;
  songCard?: boolean;
  handleClickClose?: () => void;
  profileLinkText?: string;
  button?: boolean;
}

export const Card = (props: ICard) => {
  const { children } = props;

  return <CardContent {...props}>{children}</CardContent>;
};
