import { useState, useEffect } from "react";
import { Spinner } from "../../loading/spinner_circle";

const TIMEOUT_TIME = 200;

interface ILazyImg {
  src: string;
  imgClassName?: string;
  wrapperClassName?: string;
}

export const LazyImg = (props: ILazyImg) => {
  const { src, imgClassName, wrapperClassName } = props;
  const [srcArr, setArr] = useState<string[]>([]);
  const [loading, setLoad] = useState(true);

  // We don't want to show spinner if img already in cache, so we have an array with cached srcs
  useEffect(() => {
    if (!srcArr.includes(src)) {
      setLoad(true);
    }
    srcArr.push(src);
    setArr(srcArr);
  }, [src, srcArr]);

  // on load event can start before useEffect hook
  const handleLoad = () => {
    setTimeout(() => setLoad(false), TIMEOUT_TIME);
  };

  const img = (
    <img
      className={imgClassName}
      src={src}
      style={loading ? { display: "none" } : {}}
      onLoad={handleLoad}
      alt="how to play guitar"
    />
  );

  return (
    <div
      style={loading ? { alignSelf: "center" } : {}}
      className={wrapperClassName}
      key={src}
    >
      {loading ? (
        <Spinner
          skChildClassName="sk-child-purple"
          skCircleClassName="sk-circle-purple"
        />
      ) : null}
      {img}
    </div>
  );
};
