import styled from "@emotion/styled";
import { LogoWithBackground } from "../../reusable/background_image";
import { Container } from "../emotion.styles";

export const StyledContainer = styled(Container)`
  justify-content: center;
`;

export const StyledLogo = styled(LogoWithBackground)`
  > img {
    position: relative;
    top: 7px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
`;

export const SkipButton = styled.button`
  font-weight: 600;
  color: #6775de;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-bottom: 1em;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.3em 0em 1em;
  width: 100%;
`;
