import { useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { pushTipsList } from "redux/songwriting/songwriting_slice";
import {
  getTipsList,
  getCustomSongTipNote,
  getCustomSongId,
} from "redux/songwriting/songwriting_selectors";
import { songwritingApi } from "redux/songwriting/songwriting_api";

/* below necessary because of the use of enum https://github.com/typescript-eslint/typescript-eslint/issues/2619 */
/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShuffleIcon from "@material-ui/icons/Shuffle";

import BackIcon from "./ideas_bar_back_icon";
import css from "./ideas_bar.module.scss";

import { IdeaResource } from "./idea_bar_resource/ideas_bar_resource";
import { NotesAndIdeas } from "./idea_bar_notes/idea_bar_notes";

import Guide_to_Songwriting_Icon from "./images/Guide_to_Songwriting_Icon.png";
import Melody_maker_icon from "./images/Melody_maker_icon.jpeg";
import Object_Writing_Icon from "./images/Object_Writing_Icon.png";
import Scales_Icon from "./images/Scales_Icon.jpeg";
import Songwriting_Strategies from "./images/Songwriting_Strategies_Icon.png";

enum AccordionTab {
  SONG_PROMPTS,
  NOTES_AND_IDEAS,
  QUICK_TIPS,
  RESOURCES,
}

const accordions = [
  {
    summary: "Song Prompts",
    expanded: true,
    tab: AccordionTab.SONG_PROMPTS,
  },
  {
    summary: "Notes & Ideas",
    expanded: true,
    tab: AccordionTab.NOTES_AND_IDEAS,
  },
  {
    summary: "Quick Tips",
    expanded: true,
    tab: AccordionTab.QUICK_TIPS,
  },
  {
    summary: "Resources",
    expanded: true,
    tab: AccordionTab.RESOURCES,
  },
];

export const IdeasBar = () => {
  const [activeAccordion, setActiveAccordion] = useState(accordions);
  const dispatch = useDispatch();
  const tipsList = useSelector(getTipsList);
  const songId = useSelector(getCustomSongId);
  let tipNote = useSelector(getCustomSongTipNote(songId));

  if (!tipNote) {
    tipNote = " ";
  }

  const expandHandler = (event: ChangeEvent<{ id: unknown }>) => {
    const selectedAcc = accordions[event.currentTarget.id as number];

    setActiveAccordion((prev) => {
      selectedAcc.expanded = !selectedAcc.expanded;
      const updatedArray = prev.map((item) => {
        if (item.tab === selectedAcc.tab) {
          return selectedAcc;
        }
        return item;
      });
      return [...updatedArray];
    });
  };

  const getNextTip = async () => {
    const { data } = await songwritingApi.fetchSongTip();
    dispatch(pushTipsList(data[0].tip_description));
  };

  const accordionQuickTip = (quickTipText: String) => {
    return (
      <ListItem>
        <ListItemText>
          <div>{quickTipText}</div>
        </ListItemText>
      </ListItem>
    );
  };

  const getAccordionDetails = (tab: AccordionTab) => {
    switch (tab) {
      case AccordionTab.SONG_PROMPTS:
        return (
          <AccordionDetails style={{ backgroundColor: "#f8f9fa" }}>
            <div className={css.tips_accordion_content}>
              <div className={css.tip_description}>
                {tipsList[tipsList.length - 1]
                  ? tipsList[tipsList.length - 1].replace(/^"(.*)"$/, "$1")
                  : "Click the Shuffle button to get a Song Prompt"}
              </div>

              <Button
                variant="outlined"
                onClick={getNextTip}
                startIcon={<ShuffleIcon />}
                style={{
                  borderRadius: 35,
                  color: "#76b3f3",
                  borderColor: "#76b3f3",
                }}
              >
                Shuffle
              </Button>
              <IconButton ari-label="previous">
                <BackIcon />
              </IconButton>
            </div>
          </AccordionDetails>
        );
      case AccordionTab.NOTES_AND_IDEAS:
        return (
          <AccordionDetails
            style={{
              backgroundColor: "#f8f9fa",
              color: "#5F6367",
              justifyContent: "center",
            }}
          >
            <NotesAndIdeas />
          </AccordionDetails>
        );
      case AccordionTab.QUICK_TIPS:
        return (
          <AccordionDetails
            style={{ backgroundColor: "#f8f9fa", color: "#5F6367" }}
          >
            <List>
              {accordionQuickTip("Try ABAB rhyming scheme")}
              {accordionQuickTip(
                "Get new chord ideas by switching key at the top",
              )}
              {accordionQuickTip(
                "Write music and lyrics separately, then combine",
              )}
              {accordionQuickTip(
                "Use the common song structure: verse, chorus, verse, chorus, bridge, chorus",
              )}
              {accordionQuickTip(
                "Share a half completed song with a friend to help you finish it",
              )}
            </List>
          </AccordionDetails>
        );
      case AccordionTab.RESOURCES:
        return (
          <AccordionDetails
            style={{ backgroundColor: "#f8f9fa", color: "#5F6367" }}
          >
            <div className={css.tips_accordion_content}>
              <IdeaResource
                description="A Guide for Beginner Songwriters"
                image={Guide_to_Songwriting_Icon}
                url="https://moosiko.com/blog/how-to-write-a-song-guide-for-beginner-songwriters/"
              />
              <IdeaResource
                description="Melody Maker"
                image={Melody_maker_icon}
                url="https://musiclab.chromeexperiments.com/Melody-Maker/"
              />
              <IdeaResource
                description="Object Writing"
                image={Object_Writing_Icon}
                url="https://objectwriting.com/"
              />
              <IdeaResource
                description="Scales Player"
                image={Scales_Icon}
                url="https://musiclab.chromeexperiments.com/Arpeggios/"
              />
              <IdeaResource
                description="Creative Songwriting Strategies with Sarah and Kat"
                image={Songwriting_Strategies}
                url="https://www.shedthemusic.net/creative-songwriting"
              />
            </div>
          </AccordionDetails>
        );

      default:
        return (
          <AccordionDetails
            style={{ backgroundColor: "#f8f9fa", color: "lightgray" }}
          >
            {`${accordions[tab].summary} details to go here`}
          </AccordionDetails>
        );
    }
  };

  return (
    <div>
      {activeAccordion.map(({ summary, expanded, tab }) => (
        <Accordion
          elevation={0}
          key={summary}
          expanded={expanded}
          onChange={expandHandler as any}
        >
          <AccordionSummary
            style={{
              backgroundColor: "#f8f9fa",
              fontWeight: "600",
              color: "#5F6367",
            }}
            id={`${tab}`}
            expandIcon={<ExpandMoreIcon />}
          >
            {summary}
          </AccordionSummary>
          {getAccordionDetails(tab)}
        </Accordion>
      ))}
    </div>
  );
};
