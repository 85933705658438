import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IState } from "redux/store";
import { Subset } from "types/redux_";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import mixpanel from "mixpanel-browser";
import { songwritingApi } from "redux/songwriting/songwriting_api";
import { songSeedApi } from "./song_seed_api";
import { IAudioSeed, ISongSeed, ISongSeedState } from "./song_seed_interfaces";
import { getCurrentSeed } from "./song_seed_selector";

export const initialState: ISongSeedState = {
  songSeeds: [],
  lyricSeeds: [],
  audioSeeds: [],
  currentSeed: null,
  currentSeedType: null,
  newSeed: false,
};

export const createSongSeed = createAsyncThunk<
  void,
  { seed_type: string; user_id: number },
  { state: IState }
>("songSeeds/createSongSeed", async (songSeedData, { getState, dispatch }) => {
  try {
    if (songSeedData.user_id === null) {
      throw new Error("No user was found");
    }

    const seedTitle = songSeedData.seed_type === "song" ? "Song Idea" : null;

    const capitalizeFirstLetter = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const songSeedTemplate = {
      seed_type: songSeedData.seed_type,
      user_id: songSeedData.user_id,
      status: "Active",
      title: seedTitle,
    };

    const { data } = await songSeedApi.createSongSeed({ ...songSeedTemplate });

    if (data) {
      dispatch(fetchSongSeeds());
      dispatch(setCurrentSeed({ id: data.id }));
      mixpanel.track(
        `Create ${capitalizeFirstLetter(songSeedData.seed_type)} Idea`,
      );
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

export const fetchSongSeeds = createAsyncThunk<
  { songSeeds: ISongSeed[] } | null,
  void,
  { state: IState }
>("songSeeds/fetchSongSeeds", async (_, { getState, dispatch }) => {
  const userId = getUserIdSelector(getState());
  if (userId) {
    const { data } = await songSeedApi.fetchUsersSongSeeds(userId);
    return { songSeeds: data };
  }

  return { songSeeds: [] };
});

export const updateSongSeed = createAsyncThunk<
  void,
  Subset<ISongSeed>,
  { state: IState }
>("songSeeds/updateSongSeed", async (songSeedData, { getState, dispatch }) => {
  try {
    const id = getCurrentSeed(getState());
    if (id) {
      const { data } = await songSeedApi.updateSongSeed(id, songSeedData);

      if (data) {
        dispatch(fetchSongSeeds());
      }
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

export const addAudioSeed = createAsyncThunk<
  void,
  {
    location: string;
    rec_seconds: number;
    rec_minutes: number;
    user_id: number;
    title: string;
  },
  { state: IState }
>("songwriting/addAudioSeed", async (audioData, { getState, dispatch }) => {
  try {
    await songwritingApi.addAudioSeed(audioData);
    dispatch(fetchAudioSeeds());
    mixpanel.track("Create Audio Idea");
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

export const fetchAudioSeeds = createAsyncThunk<
  { audioSeeds: IAudioSeed[] } | null,
  void,
  { state: IState }
>("songSeeds/fetchAudioSeeds", async (_, { getState, dispatch }) => {
  const userId = getUserIdSelector(getState());
  if (userId) {
    const { data } = await songwritingApi.fetchAudioSeeds(userId);
    return { audioSeeds: data };
  }
  return { audioSeeds: [] };
});

export const changeAudioSeedTitle = createAsyncThunk<
  void,
  { id: number; title: string },
  { state: IState }
>(
  "songSeeds/changeAudioSeedTitle",
  async ({ id, title }, { getState, dispatch }) => {
    try {
      const { data } = await songwritingApi.changeAssetTitle(id, title);

      if (data) {
        dispatch(fetchAudioSeeds());
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const deleteAudioSeed = createAsyncThunk<
  void,
  string,
  { state: IState }
>("songSeeds/deleteAudioSeed", async (url, { getState, dispatch }) => {
  try {
    await songwritingApi.deleteAudioSeed(url);

    dispatch(fetchAudioSeeds());
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

const songSeedsSlice = createSlice({
  name: "songSeeds",
  initialState,
  reducers: {
    setCurrentSeed: (state, action: PayloadAction<{ id: number | null }>) => {
      state.currentSeed = action.payload.id;
    },
    setCurrentSeedType: (
      state,
      action: PayloadAction<{ type: string | null }>,
    ) => {
      state.currentSeedType = action.payload.type;
    },
    setNewSeedFlag: (state, action: PayloadAction<{ newSeed: boolean }>) => {
      state.newSeed = action.payload.newSeed;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSongSeeds.fulfilled, (state, action) => {
      if (action.payload) {
        state.songSeeds = action.payload.songSeeds.filter((songSeed) => {
          return songSeed.seed_type === "song";
        });

        state.lyricSeeds = action.payload.songSeeds.filter((lyricSeed) => {
          return lyricSeed.seed_type === "lyric";
        });
      }
    });
    builder.addCase(fetchAudioSeeds.fulfilled, (state, action) => {
      if (action.payload) {
        state.audioSeeds = action.payload.audioSeeds;
      }
    });
  },
});

export const {
  actions: { setCurrentSeed, setCurrentSeedType, setNewSeedFlag },
  reducer: songSeedReducer,
} = songSeedsSlice;
