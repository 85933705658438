import { useSelector } from "react-redux";
// import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
// import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { getRecommendedLessonsSelector } from "redux/entities/recommendation/recommendation_selectors";
import { RecommendationModalBase } from "./recommendation_modal_base";

export const RecommendationModal = () => {
  const lessons = useSelector(getRecommendedLessonsSelector);
  // const accessSongRecommendation = useSelector(
  //   accessByPermissionSelector(PERMISSIONS.SONG_RECOMMENDATIONS),
  // );

  return (
    // accessSongRecommendation && <RecommendationModalBase lessons={lessons} />
    <RecommendationModalBase lessons={lessons} />
  );
};
