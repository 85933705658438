import React from "react";
import { Popover, Typography } from "@material-ui/core";
import {
  SONG_SETTINGS_LISTS,
  SongSettingsList,
} from "components/songwriting/songbuilder/song_settings_list/song_settings_list";
import css from "../../custom_song_settings.module.scss";

interface ISongKeyPopoverProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

export const SongKeyPopover: React.FC<ISongKeyPopoverProps> = ({
  anchorEl,
  handleClose,
}) => {
  const KEY_TABLE = [
    "A",
    "A#/Bb",
    "B",
    "C",
    "C#/Db",
    "D",
    "D#/Eb",
    "E",
    "F",
    "F#/Gb",
    "G",
    "G#/Ab",
  ];

  const openPopover = !!anchorEl;
  const id = openPopover ? "song-key-popover" : undefined;

  return (
    <Popover
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <div className={css.list_padding}>
        <Typography gutterBottom>Key</Typography>
        <SongSettingsList
          list_key={SONG_SETTINGS_LISTS.custom_song_key}
          dataArray={KEY_TABLE}
          handleClose={handleClose}
        />
      </div>
    </Popover>
  );
};
