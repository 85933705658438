import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import {
  getActiveSongSeeds,
  getActiveLyricSeeds,
  getAudioSeeds,
} from "redux/song_seeds/song_seed_selector";
import { setCurrentSeedType } from "redux/song_seeds/song_seeds_slice";
import SeedTypeCard from "./seed_type_card";

const SeedTypeCards = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const launchSeedPage = (launchId: string) => {
    switch (launchId) {
      case "songIdeas":
        dispatch(setCurrentSeedType({ type: "song" }));
        history.push("/songwriting-song-ideas");
        break;
      case "lyricIdeas":
        dispatch(setCurrentSeedType({ type: "lyric" }));
        history.push("/songwriting-lyric-ideas");
        break;
      case "audioIdeas":
        dispatch(setCurrentSeedType({ type: "audio" }));
        history.push("/songwriting-audio-ideas");
        break;
      default:
        history.push("/");
    }
  };

  const songSeeds = useSelector(getActiveSongSeeds);
  const lyricSeeds = useSelector(getActiveLyricSeeds);
  const audioSeeds = useSelector(getAudioSeeds);

  return (
    <Box display="flex" flexDirection="column" style={{ gap: 25 }}>
      <SeedTypeCard
        id="songIdeas"
        label="Song Ideas & Themes"
        count={songSeeds.length}
        launchFunction={launchSeedPage}
      />
      <SeedTypeCard
        id="lyricIdeas"
        label="Lyric Ideas"
        count={lyricSeeds.length}
        launchFunction={launchSeedPage}
      />
      <SeedTypeCard
        id="audioIdeas"
        label="Audio Ideas"
        count={audioSeeds.length}
        launchFunction={launchSeedPage}
      />
    </Box>
  );
};

export default SeedTypeCards;
