import axios, { CancelToken } from "axios";
import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import { ISkill } from "types/models/skill";
import { getNodeApiBaseUrl } from "./base_api";

export interface IUserSkillsDataApi {
  chords: {
    guitar: ISkill[];
    ukulele: ISkill[];
  };
  transitions: {
    guitar: ISkill[];
    ukulele: ISkill[];
  };
  progressions: {
    guitar: ISkill[];
    ukulele: ISkill[];
  };
  strummingSongs: {
    guitar: IUserStrummingLessons;
    ukulele: IUserStrummingLessons;
  };
}

export const userSkillsApi = {
  fetchUserSkills(token?: CancelToken) {
    const url = `${getNodeApiBaseUrl()}/user-skills`;
    return axios.get<IUserSkillsDataApi>(url, {
      cancelToken: token,
    });
  },

  updateSkill(skillId: number, learned: boolean) {
    const url = `${getNodeApiBaseUrl()}/user-skills`;
    return axios.patch<void>(url, {
      skillId,
      learned,
    });
  },
};
