import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFormState {
  buttonDisabled: boolean;
  reqPWReset: null | string;
  resetPW: null | string;
}

const initialState: IFormState = {
  buttonDisabled: false,
  reqPWReset: null,
  resetPW: null,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    resetFormReducerAction: () => {
      return initialState;
    },
    disableButton: (state, action: PayloadAction<boolean>) => {
      state.buttonDisabled = action.payload;
    },
    reqPWResetMessage: (state, action: PayloadAction<string | null>) => {
      state.reqPWReset = action.payload;
    },
    resetPWMessage: (state, action: PayloadAction<string | null>) => {
      state.resetPW = action.payload;
    },
  },
});

export const {
  reducer: formReducer,
  actions: {
    resetFormReducerAction,
    disableButton,
    reqPWResetMessage,
    resetPWMessage,
  },
} = formSlice;
