import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { NoteTextArea } from "components/assessment/assessment_row/note_feedback_popover/note_text_area";
import { useEffect, useRef, useState } from "react";
import { IFeedback } from "redux/feedback/feedback_types";
import { updateUserFeedback } from "redux/feedback/feedback_slice";
import { useDispatch, useSelector } from "react-redux";
import { updateMediaRecordFeedback } from "redux/media_recordings/media_recordings_slice";
import { toast } from "react-toastify";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import css from "./text_feedback.module.scss";

interface TextFeedbackProps {
  feedback: IFeedback;
}

export const TextFeedback = ({
  feedback: { id, text = "" },
}: TextFeedbackProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const [value, setValue] = useState(text);

  const replaceLinkPattern =
    /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)/gi;
  const finalFeedbackText = text.replaceAll(
    replaceLinkPattern,
    // eslint-disable-next-line quotes
    '<a class="moosiko_link" href="$1" target="_blank">$1</a>',
  );

  const textareaRef = useRef<null | HTMLTextAreaElement>(null);

  // focus on textarea when popover opens
  useEffect(() => {
    const DELAY_MS = 200;
    setTimeout(() => textareaRef.current?.focus(), DELAY_MS);
  }, [isEditing]);

  const dispatch = useDispatch();

  const onUpdate = async () => {
    setIsEditing(false);
    dispatch(updateMediaRecordFeedback({ id, text: value }));
    await dispatch(updateUserFeedback({ id, text: value }));
    toast.success("Feedback Updated");
  };

  const hasEditPermission = useSelector(
    accessByPermissionSelector(PERMISSIONS.EDIT_FEEDBACK),
  );

  return isEditing ? (
    <>
      <NoteTextArea
        headerText="Feedback"
        onSubmit={onUpdate}
        setValue={setValue}
        value={value}
        submitText="Update feedback"
      />
      <button
        type="button"
        onClick={() => setIsEditing(false)}
        className={css.icon}
      >
        <CloseIcon />
      </button>
    </>
  ) : (
    <div className={css.feedback_text_container}>
      <h3 className={css.h3}>Feedback</h3>
      <div
        className={css.feedback_text}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: finalFeedbackText }}
      />
      {hasEditPermission && (
        <button
          type="button"
          onClick={() => setIsEditing(true)}
          className={css.icon}
        >
          <EditIcon />
        </button>
      )}
    </div>
  );
};
