import { Location } from "history";
import React, { ReactElement, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

interface IRouteLeavingGuard {
  navigate: (_path: string) => void;
  shouldBlockNavigation: (_location?: Location) => boolean;
  dialogWindow: ReactElement;
}
const RouteLeavingGuard = ({
  navigate,
  shouldBlockNavigation,
  dialogWindow,
}: IRouteLeavingGuard) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const when = shouldBlockNavigation();

  const closeModal = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const preventDefaultBeforeUnload = (e: BeforeUnloadEvent) =>
    e.preventDefault();

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", preventDefaultBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", preventDefaultBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // TODO: Prevent page from being reloaded if user clicks browser's back button
    return () => {
      if (history.action === "POP" || history.action === "PUSH") {
        setConfirmedNavigation(false);
        setModalVisible(true);
      }
    };
  }, [history.action, history.location, history]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {React.cloneElement(dialogWindow, {
        onClose: closeModal,
        onConfirm: handleConfirmNavigationClick,
        open: modalVisible,
      })}
    </>
  );
};
export default RouteLeavingGuard;
