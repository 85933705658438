import React, { useState } from "react";
import { AddStudentsManuallyTab } from "components/organization/add_students_manually_tab";
import { useDispatch, useSelector } from "react-redux";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { getCourses } from "redux/courses/courses_selectors";
import {
  Container,
  Header,
  StretchContainer,
  StyledButtonBack,
  Top,
} from "../emotion.styles";
import { FooterSignUp } from "../../reusable/footer_sign_up";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { stepsId } from "../../newSignUp";
import { StyledBackIcon, Content } from "./add_students_manually.style";

import addStudent from "../../images/add-student-logo.svg";

export const AddStudentsManually = () => {
  const [selectedStudentsCount, setSelectedStudentsCount] = useState(0);
  const dispatch = useDispatch();
  const handleBackStep = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.addStudents,
      }),
    );
  };
  // get id first class
  const courseId = useSelector(getCourses)[0].id;

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={90} />
        <StyledButtonBack type="button" onClick={handleBackStep}>
          <StyledBackIcon />
        </StyledButtonBack>
      </Top>
      <Container>
        <Content>
          <Header>Add Students</Header>
          <LogoWithBackground src={addStudent} padding="28px" margin="2em" />
          <AddStudentsManuallyTab
            courseId={courseId}
            setSelectedStudentsCount={setSelectedStudentsCount}
            isTeacherSignUp
          />
        </Content>
      </Container>
      <FooterSignUp
        textButton={`Send Invite(${selectedStudentsCount})`}
        type="submit"
        form="add_students_to_course"
      >
        Any Questions? Email
        <a
          href="mailto:help@moosiko.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          help@moosiko.com
        </a>
      </FooterSignUp>
    </StretchContainer>
  );
};
