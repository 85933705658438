import { getOrgSongwritingSongs } from "redux/songwriting/songwriting_selectors";
import { createSelector } from "reselect";
import _ from "lodash";
import { IState } from "redux/store";
import { TSongwritingAssessmentSortTypes } from "./songwriting_constants";

const getSongwritingAssessmentsSelector = (state: IState) =>
  state.songwritingAssessments;

const getSongwritingAssessmentsDateRange = createSelector(
  getSongwritingAssessmentsSelector,
  (songwritingAssessments) => songwritingAssessments.dateRange,
);

const getSongwritingAssessmentsClass = createSelector(
  getSongwritingAssessmentsSelector,
  (songwritingAssessments) => songwritingAssessments.class,
);

const getSongwritingAssessmentsStudentName = createSelector(
  getSongwritingAssessmentsSelector,
  (songwritingAssessments) => songwritingAssessments.name,
);

export const getSongwritingAssessmentsSort = createSelector(
  getSongwritingAssessmentsSelector,
  (songwritingAssessments) => songwritingAssessments.sort,
);

export const getSongwritingAssessmentsFilteredRecords = createSelector(
  [
    getOrgSongwritingSongs,
    getSongwritingAssessmentsDateRange,
    getSongwritingAssessmentsClass,
    getSongwritingAssessmentsStudentName,
    getSongwritingAssessmentsSort,
  ],
  (records, dateRangeFilter, classFilter, nameFilter, sort) => {
    if (records.length !== 0) {
      let finalRecords = [...records];
      if (dateRangeFilter.active) {
        finalRecords = finalRecords.filter((record) =>
          dateRangeFilter.filteredRecords.some(
            (filteredRecord) => filteredRecord.id === record.id,
          ),
        );
      }
      if (classFilter.active) {
        finalRecords = finalRecords.filter((record) =>
          classFilter.filteredRecords.some(
            (filteredRecord) => filteredRecord.id === record.id,
          ),
        );
      }
      if (nameFilter.active) {
        finalRecords = finalRecords.filter((record) =>
          nameFilter.filteredRecords.some(
            (filteredRecord) => filteredRecord.id === record.id,
          ),
        );
      }
      let sortBy = null;
      switch (sort.type) {
        case TSongwritingAssessmentSortTypes.STUDENT_NAME:
          sortBy = "user.fname";
          break;
        case TSongwritingAssessmentSortTypes.SONG_NAME:
          sortBy = "song_name";
          break;
        case TSongwritingAssessmentSortTypes.CREATE_DATE:
          sortBy = "createdAt";
          break;
        case TSongwritingAssessmentSortTypes.VIEW_SONG:
          sortBy = "createdAt";
          break;
        default:
          sortBy = "user.fname";
          break;
      }

      finalRecords = _.orderBy(finalRecords, sortBy, sort.direction);
      return {
        finalRecords,
        isFiltered:
          dateRangeFilter.active || classFilter.active || nameFilter.active,
      };
    }
    return { finalRecords: [], isFiltered: false };
  },
);

export const getDropAssessmentsSelection = createSelector(
  getSongwritingAssessmentsSelector,
  (songwritingAssessments) => songwritingAssessments.dropSelection,
);
