import { CheckoutSubscriptionForm } from "components/stripe/checkout_subscription_form";

import { useDispatch, useSelector } from "react-redux";
import { getStripeProduct } from "redux/entities/stripe/stripe_selectors";
import { Spinner } from "components/loading/spinner_circle";
import { isButtonDisabledSelector } from "redux/ui/form/form_selectors";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { FORM_STRIPE_PAY } from "components/stripe/checkout_form_base";
import { useLocation, useHistory } from "react-router";
import { UPGRADE } from "routes/route_constants";

import { stepsId } from "../../newSignUp";
import { Container, Header, StretchContainer, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { SignUpButton } from "../../reusable/button_sign_up";

import creditCard from "../../images/credit-card-logo.svg";
import sslLogo from "../../images/ssl-logo.svg";
import {
  InputContainer,
  Footer,
  StyledButton,
  Img,
} from "./pay_by_card.styled";

export const PayByCard = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const disabled = useSelector(isButtonDisabledSelector);
  const dispatch = useDispatch();
  const handleNext = () => {
    if (pathname === UPGRADE) {
      history.push("/");
    }
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.createClass,
      }),
    );
  };
  const product = useSelector(getStripeProduct);

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={63} />
      </Top>
      <Container>
        <Header>How Would You Like To Pay?</Header>
        <LogoWithBackground src={creditCard} padding="32px 25px" margin="2em" />
        Pay online with credit card, we will send receipt for reimburesment.
        <InputContainer>
          <CheckoutSubscriptionForm addAction={handleNext} />
        </InputContainer>
      </Container>
      <Footer>
        <StyledButton>
          <SignUpButton type="submit" formId={FORM_STRIPE_PAY}>
            {disabled ? <Spinner /> : `Pay $${product.price}`}
          </SignUpButton>
          <Img src={sslLogo} />
        </StyledButton>
      </Footer>
    </StretchContainer>
  );
};
