import styled from "@emotion/styled/macro";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { usePopover } from "hooks/use_popover";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ILesson } from "api/lesson_api";
import { CardHoverPopover } from "../CardHoverPopover";
import songSheetImage from "./song_sheet.svg";

interface ICardHover {
  lesson: ILesson;
  isNew?: boolean;
}

export const CardHover = (props: ICardHover) => {
  const { lesson, isNew } = props;
  const onOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onClose = (event: React.MouseEvent) => {
    // event.stopPropagation();
    event?.preventDefault();
  };

  const [songInfoPopover, openSongInfoPopover, closeSongInfoPopover] =
    usePopover({ onOpen, onClose });

  useEffect(() => {
    const closeHandler = (e: any) => closeSongInfoPopover(e);
    document.addEventListener("scroll", closeHandler);
    return () => document.removeEventListener("scroll", closeHandler);
  }, [closeSongInfoPopover]);

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    history.push(`/lesson/${lesson.id}/song-sheet`);
    event.preventDefault();
  };

  return (
    <Container isNew={isNew}>
      <Learn>
        <button type="button" onClick={handleClick}>
          <SongSheetImage src={songSheetImage} alt="note" />
        </button>
        <Text>Learn</Text>
        <ArrowForwardIcon />
        <Info onClick={openSongInfoPopover}>
          <InfoOutlinedIcon />
        </Info>
      </Learn>
      <CardHoverPopover
        songInfoPopover={songInfoPopover}
        lesson={lesson}
        closeSongInfoPopover={closeSongInfoPopover}
      />
    </Container>
  );
};

const SongSheetImage = styled.img`
  height: 28px;
  width: 28px;
  position: absolute;
  right: 80px;
  top: 16px;
`;

const Container = styled.div<{ isNew: boolean | undefined }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* opacity: 1; */
  background: ${(props) =>
    props.isNew
      ? "linear-gradient(360deg, #6775DE -246.43%, rgba(103, 117, 222, 0) 570.71%)"
      : "rgba(0, 0, 0, 0.5)"};
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const Learn = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border: 3px solid #ffffff;
  box-sizing: border-box;
  height: 68px;
  width: 68px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;
`;

const Info = styled.button`
  height: 28px;
  width: 28px;
  box-sizing: border-box;
  position: absolute;
  border-radius: 60px;
  left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
`;
