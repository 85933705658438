import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrgSong } from "redux/songwriting/songwriting_interfaces";
import {
  TSongwritingAssessmentSortDirections,
  TSongwritingAssessmentSortTypes,
} from "./songwriting_constants";

interface ISongwritingAssessmentFilteredRecords {
  active: boolean;
  filteredRecords: IOrgSong[];
}

interface ISongwritingAssessmentsState {
  sort: {
    type: TSongwritingAssessmentSortTypes;
    direction: TSongwritingAssessmentSortDirections;
  };
  dateRange: ISongwritingAssessmentFilteredRecords;
  class: ISongwritingAssessmentFilteredRecords;
  name: ISongwritingAssessmentFilteredRecords;
  dropSelection: number;
}

const initialState: ISongwritingAssessmentsState = {
  sort: {
    type: TSongwritingAssessmentSortTypes.STUDENT_NAME,
    direction: TSongwritingAssessmentSortDirections.DESC,
  },
  dateRange: {
    active: false,
    filteredRecords: [],
  },
  class: {
    active: false,
    filteredRecords: [],
  },
  name: {
    active: false,
    filteredRecords: [],
  },
  // used to drop selections by updating ref
  dropSelection: Math.random(),
};

const songwritingAssessmentsSlice = createSlice({
  name: "songwritingAssessments",
  initialState,
  reducers: {
    setSongwritingAssessmentsSort: (
      state,
      action: PayloadAction<{
        type: TSongwritingAssessmentSortTypes;
        direction: TSongwritingAssessmentSortDirections;
      }>,
    ) => {
      state.sort = action.payload;
    },
    clearSongwritingAssessmentsFilter: (state) => {
      state.dropSelection = Math.random();
    },
    setSongwritingAssessmentsStudentName: (
      state,
      action: PayloadAction<ISongwritingAssessmentFilteredRecords>,
    ) => {
      state.name = action.payload;
    },
    setSongwritingAssessmentsClass: (
      state,
      action: PayloadAction<ISongwritingAssessmentFilteredRecords>,
    ) => {
      state.class = action.payload;
    },
    setSongwritingAssessmentsDateRange: (
      state,
      action: PayloadAction<ISongwritingAssessmentFilteredRecords>,
    ) => {
      state.dateRange = action.payload;
    },
  },
});

export const {
  reducer: songwritingAssessmentsReducer,
  actions: {
    setSongwritingAssessmentsSort,
    clearSongwritingAssessmentsFilter,
    setSongwritingAssessmentsStudentName,
    setSongwritingAssessmentsClass,
    setSongwritingAssessmentsDateRange,
  },
} = songwritingAssessmentsSlice;
