import { useState, useEffect } from "react";
import { videoApi } from "../redux/video/video_api";

// eslint-disable-next-line no-magic-numbers
const LINK_EXPIRATION_TIME_IN_MILLISECONDS = 1000 * 60 * 29;

/**
 * Makes api call to get temporary public link from private video
 * Updates every N minutes
 */
export const useGetVideoWithAccess = (videoKey: string | null) => {
  const [videoURLWithAccess, setVideoURLWithAccess] = useState<string | null>(
    null,
  );
  useEffect(() => {
    videoApi.getVideoFromS3(videoKey).then((newUrl) => {
      setVideoURLWithAccess(newUrl);
    });
    // update link each 25 minutes because it expires every 30 minutes
    const timerId = setInterval(() => {
      videoApi.getVideoFromS3(videoKey).then((newUrl) => {
        setVideoURLWithAccess(newUrl);
      });
    }, LINK_EXPIRATION_TIME_IN_MILLISECONDS);
    return () => clearInterval(timerId);
  }, [videoKey]);
  return videoURLWithAccess;
};
