import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  progressPercentageByInstrument,
  skillsTo25PercentageByInstrument,
} from "redux/entities/my_journey/my_journey_selectors";
import { onMoosikoBtnClick } from "redux/ui/modal/modal_slice";
import { IInstruments } from "redux/entities/user_skills/user_skills_slice";
import { useWindowWidth, MOBILE_WIDTH } from "../../../hooks/use_width";
import css from "./progress_line.module.scss";
import { LineDropDown } from "../line_dropdown/line_dropdown";
import {
  TEXT_25,
  TEXT_50,
  TEXT_75,
  TEXT_100,
} from "../line_dropdown/dropdown_texts";

export const TAB_25 = 25;
export const TAB_50 = 50;
export const TAB_75 = 75;
export const TAB_100 = 100;

type IProgressLine = {
  instrument: IInstruments;
};

export const ProgressLine = ({ instrument }: IProgressLine) => {
  const progress = useSelector(progressPercentageByInstrument(instrument));
  const skillsTo25 = useSelector(skillsTo25PercentageByInstrument(instrument));
  const width = useWindowWidth();

  const dispatch = useDispatch();

  const [openedTab, setOpenedTab] = useState(0);
  return (
    <div className={css.line_container}>
      <div className={css.main_line}>
        <div className={css.progress_line} style={{ width: `${progress}%` }} />
      </div>
      <FontAwesomeIcon
        className={css.user_icon}
        icon="user"
        style={{ left: `calc(${progress}% - 1em)` }}
      />
      <div
        className={cn(css.user_upper_text)}
        style={{
          // eslint-disable-next-line no-magic-numbers
          left: `calc(${progress}% - ${width > MOBILE_WIDTH ? 5 : 3}em)`,
        }}
      >
        {skillsTo25} skill
        {skillsTo25 === 1 ? "" : "s"} to go 25%
      </div>
      <div
        className={cn(css.user_text)}
        style={{
          // eslint-disable-next-line no-magic-numbers
          left: `calc(${progress}% - ${width > MOBILE_WIDTH ? 5 : 3}em)`,
        }}
      >
        {progress}%
      </div>
      <div className={cn(css.vertical_line, css.line_1, css.line_1_active)} />
      <LineDropDown
        header="Reach 25%"
        isOpened={openedTab === TAB_25}
        tabNumber={TAB_25}
        main={TEXT_25}
        setTab={setOpenedTab}
      />
      <button
        type="button"
        className={cn(css.learnNew)}
        onClick={() => {
          dispatch(onMoosikoBtnClick());
        }}
      >
        Learn New Skills
      </button>
      <div className={cn(css.vertical_line, css.line_2, css.line_2_active)} />
      <LineDropDown
        header="Reach 50%"
        isOpened={openedTab === TAB_50}
        tabNumber={TAB_50}
        setTab={setOpenedTab}
        main={TEXT_50}
      />
      <div className={cn(css.vertical_line, css.line_3, css.line_3_active)} />
      <LineDropDown
        header="Reach 75%"
        isOpened={openedTab === TAB_75}
        tabNumber={TAB_75}
        setTab={setOpenedTab}
        main={TEXT_75}
      />
      <div className={cn(css.vertical_line, css.line_4, css.line_4_active)} />
      <LineDropDown
        header="Reach 100%"
        isOpened={openedTab === TAB_100}
        tabNumber={TAB_100}
        setTab={setOpenedTab}
        main={TEXT_100}
      />
      <div className={cn(css.vertical_line, css.line_5, css.line_5_active)} />
    </div>
  );
};
