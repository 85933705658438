import ReactDOM from "react-dom";
import { store } from "redux/store";
import * as serviceWorker from "./serviceWorker";
import "./scss/Master.scss";
import { Root } from "./components/Root";

const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);
if (mobile) {
  document.documentElement.classList.add("mobile");
}

const render = () => {
  ReactDOM.render(<Root store={store} />, document.getElementById("root"));
};

render();

// Hot module replacement
if (module.hot) {
  module.hot.accept("./components/Root", () => {
    render();
  });
}

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      registration.waiting.addEventListener("statechange", (event) => {
        if (
          event.target &&
          (event.target as ServiceWorker).state === "activated"
        ) {
          window.location.reload();
        }
      });
    }
  },
});
