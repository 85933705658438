import { useCallback, useEffect } from "react";
import { products } from "redux/entities/stripe/stripe_consts";
import { storeKitProducts } from "redux/entities/store_kit/store_kit_consts";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { ModalContainer } from "components/modal/modal_container";
import {
  getStripeDataSelector,
  getStripeProduct,
} from "redux/entities/stripe/stripe_selectors";
import { getStoreKitProduct } from "redux/entities/store_kit/store_kit_selectors";

import { closeModal } from "redux/ui/modal/modal_slice";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { MemberPlan } from "components/modal/choose_plan_modal/subscription_plan/member_plan";

import modal_sss from "components/modal/modal.module.scss";
import Button from "components/reusable/buttons/button";
import sslImg from "img/ssl.png";
import { isButtonDisabledSelector } from "redux/ui/form/form_selectors";
import { getAuthSelector } from "redux/auth/user_selectors";
import mixpanel from "mixpanel-browser";
import { CheckoutSubscriptionForm } from "../checkout_subscription_form";
import css from "./pay_for_subscription_modal.module.scss";
import { FORM_STRIPE_PAY } from "../checkout_form_base";

export const PayForSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { onClose, onSuccess } = useSelector(getModalDataSelector);
  const stripe = useSelector(getStripeDataSelector);

  const { role } = useSelector(getAuthSelector);

  const songwriting = role === "FREE_SONGWRITING";
  const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");

  const termsOfServiceLink = new URL("https://moosiko.com/app-privacy-policy/");
  const appleEULA = new URL(
    "https://www.apple.com/legal/internet-services/itunes/dev/stdeula/",
  );

  const closeModalCallback = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      dispatch(closeModal());
    }
  }, [dispatch, onClose]);

  useEffect(() => {
    if (!stripe.paid) {
      return;
    }

    if (onSuccess) {
      onSuccess();
    } else {
      dispatch(closeModal());
    }
  }, [closeModalCallback, dispatch, onSuccess, stripe.paid]);

  useEffect(() => {
    mixpanel.track("Paywall View");
  }, []);
  const stripeProduct = useSelector(getStripeProduct);
  const storeKitProduct = useSelector(getStoreKitProduct);
  const disabled = useSelector(isButtonDisabledSelector);

  // handle iOS in app purchase, this event will be handled by the iOS app
  const handleInAppPurchase = async () => {
    if ((window as any).webkit) {
      await (window as any).webkit.messageHandlers.inAppPurchase.postMessage(
        storeKitProduct.postfix === "month"
          ? "monthly_subscription"
          : "yearly_subscription",
      );
    }
    mixpanel.track("Purchase Attempted", {
      product: storeKitProduct.id,
      price: storeKitProduct.price,
    });
  };

  return (
    <ModalContainer
      closeAction={closeModalCallback}
      classes={{ main: css.modal }}
    >
      <div className={modal_sss.title}>
        {songwriting ? "You've Hit a Premium Feature" : "Confirm your upgrade"}
      </div>

      <div className={css.wrapper}>
        {songwriting && (
          <div className={cn(css.section_item, css.plan_info_section)}>
            <MemberPlan
              products={iOSApp ? storeKitProducts : products}
              showSubmitBtn={false}
            />
          </div>
        )}
        <div className={cn(css.section_item, css.form_section)}>
          {!iOSApp && <CheckoutSubscriptionForm />}
          <div className={css.pay_btn_wrapper}>
            <div className={css.pay_btn_inner_wrapper}>
              {!iOSApp ? (
                <Button
                  showLoader={disabled}
                  form={FORM_STRIPE_PAY}
                  classes={{ btn: css.pay_btn }}
                >
                  Pay ${stripeProduct.price}
                </Button>
              ) : (
                <Button
                  onClick={handleInAppPurchase}
                  classes={{ btn: css.pay_btn }}
                >
                  Pay ${storeKitProduct.price}
                </Button>
              )}
              <div className={css.cancel_text_wrapper}>Cancel Anytime</div>

              {iOSApp && (
                <div className={css.terms}>
                  <a
                    href={termsOfServiceLink.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  |{" "}
                  <a
                    href={appleEULA.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EULA
                  </a>
                </div>
              )}
            </div>
            {!iOSApp && (
              <img className={css.ssl_img} src={sslImg} alt="SSL verified" />
            )}
          </div>
        </div>
        {!songwriting && (
          <div className={cn(css.section_item, css.plan_info_section)}>
            <MemberPlan
              products={iOSApp ? storeKitProducts : products}
              showSubmitBtn={false}
            />
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
