import React from "react";
import { useSelector } from "react-redux";

import { getCustomSongTemplate } from "redux/songwriting/songwriting_selectors";

import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import css from "../../../start_new_song.module.scss";

interface ICreateSongCardNote {
  songTemplateId?: number;
}

export const CreateSongCardNote = ({ songTemplateId }: ICreateSongCardNote) => {
  const customSongTemplate = useSelector(getCustomSongTemplate(songTemplateId));

  if (customSongTemplate?.note) {
    return <p className={css.card_description}>{customSongTemplate.note}</p>;
  }
  return (
    <IconButton>
      <AddIcon />
    </IconButton>
  );
};
