import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/reusable/buttons/button";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import {
  isButtonDisabledSelector,
  formSelector,
} from "redux/ui/form/form_selectors";
import { closeModal } from "redux/ui/modal/modal_slice";
import { ModalContainer } from "../modal_container";
import { FORM_RESET_PASSWORD, ResetPasswordForm } from "./reset_password_form";

import css from "../modal.module.scss";

export const ResetPWModal = () => {
  const dispatch = useDispatch();
  const confirm = useSelector(formSelector).resetPW;
  const disabled = useSelector(isButtonDisabledSelector);
  const { noCloseButton } = useSelector(getModalDataSelector);
  const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

  useEffect(() => {
    if (confirm && mobile) {
      dispatch(closeModal());
    }
  }, [confirm, mobile, dispatch]);

  return (
    <ModalContainer
      closeAction={noCloseButton ? (e) => e.preventDefault() : undefined}
      noCloseButton={noCloseButton}
    >
      <div className={css.title}>Set Password</div>
      <div className={css.description}>Enter a new password.</div>
      <ResetPasswordForm />
      <div className={css.button_container}>
        <Button showLoader={disabled} form={FORM_RESET_PASSWORD}>
          Submit
        </Button>
      </div>
    </ModalContainer>
  );
};
