/* eslint-disable @typescript-eslint/ban-types */
import React, { MouseEvent, MouseEventHandler, useState } from "react";

export type mouseEventHandler = MouseEventHandler;
interface IUsePopover {
  onOpen?: (e: React.MouseEvent) => void;
  onClose?: (e: React.MouseEvent) => void;
}

export type eventHandler = (e: React.MouseEvent) => void; // (event?: Event | {}) => void;

type IUsePopoverReturn = [HTMLElement | null, MouseEventHandler, eventHandler];

/**
 * Helps with Material Ui Popovers
 */
export const usePopover = ({
  onOpen,
  onClose,
}: IUsePopover = {}): IUsePopoverReturn => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
    if (typeof onOpen === "function") {
      onOpen(event);
    }
  };

  const handleClose: eventHandler = (event) => {
    setAnchor(null);
    if (typeof onClose === "function") {
      onClose(event);
    }
  };

  return [anchor, handleOpen, handleClose];
};
