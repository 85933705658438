import { useCallback } from "react";
import { DragStartEvent } from "@dnd-kit/core";
import { useDispatch } from "react-redux";
import {
  setIsDraggedChord,
  setDraggableItem,
  setTypeOfDragAndDropAction,
  setIdSongStructureWhenReplaceChordInList,
  setIsReplacingSongStructure,
} from "redux/songwriting/songwriting_slice";

import { EnumDragAndDropActions } from "redux/songwriting/songwriting_interfaces";

export const useHandleOnDragStart = () => {
  const dispatch = useDispatch();
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { data } = event.active;

      const typeAction = data.current?.typeDragAndDropAction;
      const isAddNewItemToSong =
        typeAction === EnumDragAndDropActions.createStructure ||
        typeAction === EnumDragAndDropActions.addChordToStructure;

      if (isAddNewItemToSong) {
        dispatch(setDraggableItem(data.current?.item));
      }
      switch (typeAction) {
        case EnumDragAndDropActions.addChordToStructure: {
          dispatch(setIsDraggedChord(true));
          break;
        }
        case EnumDragAndDropActions.replaceChordInStructure: {
          dispatch(
            setIdSongStructureWhenReplaceChordInList(data.current?.structureId),
          );
          break;
        }
        case EnumDragAndDropActions.replaceStructure: {
          dispatch(setIsReplacingSongStructure(data.current?.songUnit));
          break;
        }
        default: {
          break;
        }
      }

      dispatch(setTypeOfDragAndDropAction(typeAction));
    },
    [dispatch],
  );
  return { handleDragStart };
};
