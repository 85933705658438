import {
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import css from "./video.module.scss";

export const Video = ({ src }: { src?: string | null }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const percentage = Math.ceil((currentTime / duration) * 100);

  const handleTimeUpdate = (
    event: SyntheticEvent<HTMLVideoElement> & {
      target: { currentTime: number; duration: number };
    },
  ): void => {
    setCurrentTime(event.target.currentTime);
    if (
      duration !== event.target.duration &&
      !Number.isNaN(event.target.duration)
    ) {
      setDuration(event.target.duration);
    }
  };

  useEffect(() => {
    videoRef.current?.load();
  }, [src]);

  const handlePlayButton = () => {
    if (!duration) {
      return;
    }
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current?.pause();
    } else {
      setIsPlaying(true);
      videoRef.current?.play();
    }
  };

  const handleOnEnded = () => {
    setIsPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  const progressRef = useRef<HTMLButtonElement | null>(null);

  const handleProgressClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (progressRef.current) {
      const offsetX = Math.ceil(
        event.clientX - progressRef.current.getBoundingClientRect().left,
      );

      const skipTo =
        Math.round(
          (offsetX / progressRef.current.clientWidth) * duration * 100,
        ) / 100;

      if (videoRef.current) {
        videoRef.current.currentTime = skipTo;
      }
    }
  };

  const handleDataLoaded = () => {
    if (
      !videoRef?.current?.duration ||
      !Number.isFinite(videoRef.current.duration) ||
      Number.isNaN(videoRef.current.duration)
    ) {
      if (videoRef.current) {
        videoRef.current.currentTime = 100000;
      }
    }
  };

  const handleDurationChange = (
    event: SyntheticEvent<HTMLVideoElement> & { target: { duration: number } },
  ) => {
    if (event.target.duration !== duration) {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
      }
      setDuration(event.target.duration);
    }
  };

  return (
    <div className={css.container}>
      <video
        onTimeUpdate={handleTimeUpdate}
        ref={videoRef}
        controls={false}
        className={css.video}
        onEnded={handleOnEnded}
        onLoadedData={handleDataLoaded}
        preload="auto"
        onDurationChange={handleDurationChange}
        onClick={handlePlayButton}
      >
        <source src={src || undefined} type="video/webm" />
      </video>
      <div className={css.controls}>
        <button
          onClick={handlePlayButton}
          type="button"
          className={css.play_button}
        >
          {isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
        </button>
        <div className={css.time_text}>{formatTime(currentTime)}</div>
        <button
          onClick={handleProgressClick}
          type="button"
          className={css.progress}
          ref={progressRef}
        >
          <div
            style={{ width: `${percentage}%` }}
            className={css.progress_left}
          />
        </button>
      </div>
    </div>
  );
};

const TEN = 10;
const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const allSeconds = time - minutes;
  const tens = Math.floor(allSeconds / TEN);
  const seconds = Math.floor(allSeconds % TEN);
  return `${minutes}:${tens}${seconds}`;
};
