import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getLessonFromLocationSelector } from "redux/entities/lessons/lesson_selectors";
import { IChordText } from "api/chords_api";
import { IState } from "redux/store";
import css from "./more_info_slide.module.scss";
import { Tabs } from "../../../reusable/tabs";
import { chords } from "../../../../resources/chords";
import { useWindowWidth } from "../../../../hooks/use_width";
import { LazyImg } from "../../../reusable/lazy_img";
import { SwitchElem } from "./switch_elem/switch_elem";

const DIAGRAM_TAB = 0;
const TOP_TAB = 1;
const FRONT_TAB = 2;
const MOBILE_WIDTH = 700;
const EMPTY = 0;

interface IMoreInfoSlide {
  isSwitched: boolean;
  setSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  closeInfoSlide: () => void;
  chord: { name: string; chordText: IChordText | null };
}

// todo: Move to different files
export const MoreInfoSlide = (props: IMoreInfoSlide) => {
  const [selectedTab, setTab] = useState(DIAGRAM_TAB);
  const { pathname } = useLocation();
  const { instrument } = useSelector((state: IState) =>
    getLessonFromLocationSelector(state, pathname),
  );

  const { isSwitched, setSwitch, closeInfoSlide, chord } = props;
  const chordImages = chords[instrument][chord.name];

  const imageSrc = selectImage(chordImages, selectedTab, isSwitched);
  const text = selectText(chord.chordText, selectedTab, isSwitched);
  const textArr = text ? text.split("\n").map((i) => i.trim()) : [];

  const handleClick = () => {
    setSwitch(!isSwitched);
  };

  const screenWidth = useWindowWidth();
  const topSwitch =
    screenWidth <= MOBILE_WIDTH ? SwitchElem(isSwitched, handleClick) : null;
  const bottomSwitch =
    screenWidth <= MOBILE_WIDTH ? null : SwitchElem(isSwitched, handleClick);

  const handleChangeTab = (tabNumber: number) => {
    setTab(tabNumber);
  };

  const mainRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (mainRef.current) {
      (mainRef.current?.parentNode as HTMLDivElement | null)?.classList.add(
        css.display_center,
        css.height_100,
        css.slide_wrapper,
        css.min_height_60_vh,
      );
      (
        mainRef.current?.parentNode?.parentNode as HTMLDivElement | null
      )?.classList.add(css.display_center, css.height_100);
    }
  }, []);

  let additionText;
  if (selectedTab === DIAGRAM_TAB) {
    additionText = isSwitched ? "Clean Sound: " : "Muffled or Ringing: ";
  }

  return (
    <div className={css.wrapper} ref={mainRef}>
      <div className={css.header}>
        <div className={css.chord}>
          <div className={css.chord_text}>{chord.name} CHORD</div>
          <div className={css.close_icon}>
            <FontAwesomeIcon
              className={css.close_icon_svg}
              title="Loop"
              icon="times"
              onClick={closeInfoSlide}
            />
          </div>
        </div>
        <Tabs setTab={handleChangeTab} selectedTab={selectedTab}>
          <div>DIAGRAM</div>
          <div>TOP</div>
          <div>FRONT</div>
        </Tabs>
      </div>
      <div className={css.main}>
        <div className={css.help_content}>
          {topSwitch}
          <LazyImg
            src={imageSrc}
            imgClassName={css.img}
            wrapperClassName={css.img_wrapper}
          />
          <div className={css.info}>
            {bottomSwitch}
            {textArr.length > EMPTY ? (
              <ul className={css.ul}>
                <strong className={css.strong}>{additionText}</strong>
                {textArr.map((textItem) => (
                  <li key={textItem} className={css.li}>
                    {textItem}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

function selectText(
  chordTexts: IChordText | null,
  selectedTab: number,
  isSwitched: boolean,
) {
  let text;
  if (!chordTexts) {
    return "";
  }
  switch (selectedTab) {
    case DIAGRAM_TAB:
      text = isSwitched ? chordTexts.text_correct : chordTexts.text_incorrect;
      break;
    case FRONT_TAB:
      text = isSwitched
        ? chordTexts.front_text_correct
        : chordTexts.front_text_incorrect;
      break;
    case TOP_TAB:
      text = isSwitched
        ? chordTexts.top_text_correct
        : chordTexts.top_text_incorrect;
      break;
    default:
      text = chordTexts.text_correct;
  }
  return text;
}

function selectImage(
  chordImages: { [key: string]: string },
  selectedTab: number,
  isSwitched: boolean,
) {
  let imageSrc;
  switch (selectedTab) {
    case DIAGRAM_TAB:
      imageSrc = isSwitched
        ? chordImages.diagram_correct
        : chordImages.diagram_incorrect;
      break;
    case TOP_TAB:
      imageSrc = isSwitched
        ? chordImages.top_correct
        : chordImages.top_incorrect;
      break;
    case FRONT_TAB:
      imageSrc = isSwitched
        ? chordImages.front_correct
        : chordImages.front_incorrect;
      break;
    default:
      imageSrc = chordImages.diagram_correct;
  }
  return imageSrc;
}
