import { UserView } from "components/my_journey/user_view/user_view";
import { useCourse } from "hooks/useCourse";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { findCourseStudent, getCourses } from "redux/courses/courses_selectors";
import { getChordsByInstrumentLikeString } from "redux/entities/chords/chords_selectors";
import { getLessonsCountByInstrument } from "redux/entities/lessons/lesson_selectors";
import { getProgressionsByInstrumentLikeString } from "redux/entities/progressions/progressions_selectors";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { feedbackApi } from "redux/feedback/feedback_api";
import { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import { userApi } from "api/user_api";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import {
  strummingCount,
  melodiesCount,
} from "components/my_journey/skill_helpers";
import { IUserSession } from "api/user_session_api";
import { IChartWithAside } from "components/my_journey/i_chart_with_aside";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import { ISkill } from "types/models/skill";
import { getCurrentInstrument } from "redux/entities/user_skills/user_skills_selector";
import { getTransitionsLikeStringByInstrument } from "redux/entities/transitions/transitions_selectors";
import { IUserSkillsData } from "components/my_journey/user_skills";
import { getThisWeekMinutes } from "../helpers/get_this_week_minutes";
import { StudentActions } from "../student_row/student_actions";
import css from "./student_info.module.scss";

// todo: refactor file
export const StudentInfo = () => {
  const { courseId, userId } = useParams<{
    userId: string;
    courseId: string;
  }>();
  useCourse(Number(courseId));
  const courses = useSelector(getCourses);

  const currentInstrument = useSelector(getCurrentInstrument);
  const chords = useSelector(
    getChordsByInstrumentLikeString(currentInstrument),
  );
  const progressions = useSelector(
    getProgressionsByInstrumentLikeString(currentInstrument),
  );
  const transitions = useSelector(
    getTransitionsLikeStringByInstrument(currentInstrument),
  );

  const songsCount =
    useSelector(getLessonsCountByInstrument(currentInstrument)) || 0;

  const student = useSelector(
    findCourseStudent(Number(courseId), Number(userId)),
  );

  const userCompletedSongs =
    student?.user_lessons.filter(
      (song) =>
        song.skills[0].skill?.skill?.name.toLocaleLowerCase() ===
          currentInstrument ||
        // some songs  exist with only skilltype, without chords(it's very strange)
        song.skills[0].skill?.name.toLocaleLowerCase() === currentInstrument,
    ) || [];
  const userMelodiesSongs = userCompletedSongs.filter((lesson) =>
    lesson.skills.some(
      (skillType: ISkill) =>
        skillType.name === "Melody" &&
        skillType.skill?.name.toLocaleLowerCase() === currentInstrument,
    ),
  );

  const [userMediaRecords, setUserMediaRecords] = useState<IMediaRecord[]>([]);
  useEffect(() => {
    feedbackApi.fetchFeedback(Number(userId)).then(({ data: { feedback } }) => {
      setUserMediaRecords(feedback);
    });
  }, [userId]);

  const mediaRecordsWithFeedbacks = userMediaRecords;

  const { goBack } = useHistory();

  const handleEdit = () => {
    setEdit(true);
  };

  const [edit, setEdit] = useState(false);

  const [userFirstName, setUserFirstName] = useState(student?.fname || "");
  const [userLastName, setUserLastName] = useState(student?.lname || "");
  const [userEmail, setUserEmail] = useState(student?.email || "");
  useEffect(() => {
    if (student) {
      setUserFirstName(student.fname);
      setUserLastName(student.lname);
      setUserEmail(student.email);
    }
  }, [student]);

  if (!student) {
    return null;
  }
  const onlineUserSessions = student.user_sessions || [];

  const totalTime = onlineUserSessions.reduce(
    (prev: number, current: IUserSession) => current.minutes + prev,
    0,
  );
  const thisWeekMinutes = getThisWeekMinutes(onlineUserSessions);

  const course = courses.find((course) => course.id === Number(courseId));
  if (!course) {
    throw new Error("course not found");
  }
  const students = course.users.slice();

  const chartData: IChartWithAside = {
    minutesPerDay: onlineUserSessions,
    totalTime,
    thisWeekMinutes,
    userId: Number(userId),
    students,
  };

  const { studentsWithSkills = [] } = course;

  // calculate user rank
  // todo: refactor
  students.sort((student1, student2) => {
    const student1WithTime = studentsWithSkills.find(
      (student) => student.id === student1.id,
    );
    const student2WithTime = studentsWithSkills.find(
      (student) => student.id === student2.id,
    );
    if (!student1WithTime || !student2WithTime) {
      throw new Error("Student not found");
    }
    const totalTime1 = student1WithTime.user_sessions.reduce(
      (acc: number, session: IUserSession) => acc + session.minutes,
      0,
    );
    const totalTime2 = student2WithTime.user_sessions.reduce(
      (acc: number, session: IUserSession) => acc + session.minutes,
      0,
    );
    return totalTime2 - totalTime1;
  });

  const strummingSkillsCount = strummingCount(
    student.user_strumming_lessons[currentInstrument],
  );

  const melodySkillsCount = melodiesCount(userMelodiesSongs);

  const skillsData: IUserSkillsData = {
    chords,
    progressions,
    transitions,
    userChords: (student.chords[currentInstrument] || [])
      .map((chord) => chord.name)
      .sort(),
    userStrummingSongs: student.user_strumming_lessons[currentInstrument] || [],
    userProgressions: (student.progressions[currentInstrument] || [])
      .map((progression) => JSON.parse(progression.name).join("-"))
      .sort(),
    userTransitions: (student.transitions[currentInstrument] || [])
      .map((transition) => JSON.parse(transition.name).join("-"))
      .sort(),
    melodySkillsCount,
    strummingSkillsCount,
    userCompletedSongs,
    userMelodiesSongs,
    songsCount,
  };

  const studentName = (
    <button type="button" className={css.name} onClick={handleEdit}>
      {userFirstName} {userLastName} {userEmail}
      <div className={css.edit_icon}>
        <EditIcon fontSize="small" />
      </div>
    </button>
  );

  const handleSave = () => {
    userApi.updateName(Number(userId), userFirstName, userLastName, userEmail);
    setEdit(false);
  };

  const editStudentName = (
    <div className={css.edit}>
      <form onSubmit={handleSave}>
        <input
          placeholder="First Name"
          className={css.input}
          onChange={(event) => setUserFirstName(event.target.value)}
          value={userFirstName}
        />
        <input
          className={css.input}
          placeholder="Last Name"
          value={userLastName}
          onChange={(event) => setUserLastName(event.target.value)}
        />
        <input
          className={classNames(css.input, css.emailInput)}
          placeholder="email"
          value={userEmail}
          type="email"
          onChange={(event) => setUserEmail(event.target.value)}
        />
        <button type="submit">
          <CheckIcon />
        </button>

        <button type="button" onClick={() => setEdit(false)}>
          <CloseIcon />
        </button>
      </form>
    </div>
  );

  return (
    <div>
      <div className={css.header}>
        <div className={css.header_names}>
          <button
            className={css.back}
            type="button"
            onClick={() => {
              goBack();
            }}
          >
            <ArrowBackIcon />
            Back
          </button>
          {edit ? editStudentName : studentName}
        </div>
        <StudentActions student={student} />
      </div>
      <UserView
        mediaRecordsWithFeedbacks={mediaRecordsWithFeedbacks}
        skills={skillsData}
        chart={chartData}
        studentName={student.fname}
        clickable={false}
      />
    </div>
  );
};
