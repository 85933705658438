import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IState } from "redux/store";
import { logout } from "../../auth/auth_actions";
import { getLacksLessonsId } from "../lessons/lessons_helpers";
import { getLessonsById, searchLessons } from "../lessons/lessons_slice";
import { getLessonsIdArraySelector } from "../lessons/lesson_selectors";
import { receiveUserLesson } from "./user_lessons_actions";
import { IUserLesson, userLessonApi } from "./user_lesson_api";

export const fetchUserLessons = createAsyncThunk<
  { [key: string]: IUserLesson },
  void,
  { state: IState }
  // @ts-ignore
>("userLessons/fetchUserLessons", async (_, { dispatch, getState }) => {
  const {
    data: { lessons },
  } = await userLessonApi.fetchUserLessons();

  dispatch(searchLessons({ resetLessons: true }));

  const userLessonsId = Object.values(lessons).map(
    (lesson) => lesson.lesson_id,
  );
  const cacheLessonsId = getLessonsIdArraySelector(getState());
  const lacksLessonsId = getLacksLessonsId({
    userLessonsId,
    availableLessonsId: cacheLessonsId,
  });

  if (lacksLessonsId) {
    await dispatch(getLessonsById({ id: lacksLessonsId }));
  }

  return lessons;
});

export const createUserLesson = createAsyncThunk(
  "userLessons/fetchUserLesson",
  async ({ userId, lessonId }: { userId: number; lessonId: number }) => {
    const { data: lesson } = await userLessonApi.createUserLesson({
      userId,
      lessonId,
    });
    return lesson;
  },
);

interface IUserLessonState {
  [key: string]: IUserLesson;
}

const initialState: IUserLessonState = {};
const chordsSlice = createSlice({
  name: "userLessons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return {};
    });
    builder.addCase(fetchUserLessons.fulfilled, (_state, action) => {
      return action.payload;
    });
    builder.addCase(createUserLesson.fulfilled, (state, action) => {
      state[action.payload.id] = action.payload;
    });
    builder.addCase(
      receiveUserLesson,
      (state, action: PayloadAction<IUserLesson>) => {
        state[action.payload.id] = action.payload;
      },
    );
  },
});

export const { reducer: userLessonsReducer } = chordsSlice;
