const CHORDS_TABLE = [
  { key: "C", chords: ["C", "F", "G", "Am", "Dm", "Em"] },
  { key: "Db", chords: ["Db", "F#", "Ab", "Bbm", "Ebm", "Fm"] },
  { key: "D", chords: ["D", "G", "A", "Bm", "Em", "F#m"] },
  { key: "Eb", chords: ["Eb", "Ab", "Bb", "Cm", "Fm", "Gm"] },
  { key: "E", chords: ["E", "A", "B", "C#m", "F#m", "G#m"] },
  { key: "F", chords: ["F", "Bb", "C", "Dm", "Gm", "Am"] },
  { key: "Gb", chords: ["F#", "B", "Db", "Ebm", "Abm", "Bbm"] },
  { key: "G", chords: ["G", "C", "D", "Em", "Am", "Bm"] },
  { key: "Ab", chords: ["Ab", "Db", "Eb", "Fm", "Bbm", "Cm"] },
  { key: "A", chords: ["A", "D", "E", "F#m", "Bm", "C#m"] },
  { key: "Bb", chords: ["Bb", "Eb", "F", "Gm", "Cm", "Dm"] },
  { key: "B", chords: ["B", "E", "F#", "G#m", "C#m", "D#m"] },
];
const COMPLETE_CHORDS_TABLE = [
  { key: "C", chords: ["C", "Dm", "Em", "F", "G", "Am", "Bdim"] },
  { key: "Db", chords: ["Db", "Ebm", "Fm", "Gb", "Ab", "Bbm", "Cdim"] },
  { key: "D", chords: ["D", "Em", "F#m", "G", "A", "Bm", "C#dim"] },
  { key: "Eb", chords: ["Eb", "Fm", "Gm", "Ab", "Bb", "Cm", "Ddim"] },
  { key: "E", chords: ["E", "F#m", "G#m", "A", "B", "C#m", "D#dim"] },
  { key: "F", chords: ["F", "Gm", "Am", "Bb", "C", "Dm", "Edim"] },
  { key: "Gb", chords: ["Gb", "Abm", "Bbm", "Cb", "Db", "Ebm", "Fdim"] },
  { key: "G", chords: ["G", "Am", "Bm", "C", "D", "Em", "F#dim"] },
  { key: "Ab", chords: ["Ab", "Bbm", "Cm", "Db", "Eb", "Fm", "Gdim"] },
  { key: "A", chords: ["A", "Bm", "C#m", "D", "E", "F#m", "G#dim"] },
  { key: "Bb", chords: ["Bb", "Cm", "Dm", "Eb", "F", "Gm", "Adim"] },
  { key: "B", chords: ["B", "C#m", "D#m", "E", "F#", "G#m", "A#dim"] },
];

const COMPLETE_CHORDS_LIST = [
  "A",
  "A6",
  "A7",
  "Ab",
  "Ab6",
  "Ab7",
  "Abm",
  "Abmaj7",
  "Absus",
  "Adim",
  "Am",
  "Am6",
  "Am7",
  "Amaj7",
  "Asus",
  "B",
  "B6",
  "B7",
  "Bb",
  "Bb6",
  "Bb7",
  "Bbm",
  "Bbm6",
  "Bbm7",
  "Bbmaj7",
  "Bbsus",
  "Bdim",
  "Bm",
  "Bm6",
  "Bm7",
  "Bmaj7",
  "Bsus",
  "C",
  "C6",
  "C7",
  "Cm",
  "Cm6",
  "Cm7",
  "Cmaj7",
  "C#m",
  "C#m6",
  "C#m7",
  "Csus",
  "D",
  "D6",
  "D7",
  "Db",
  "Db6",
  "Db7",
  "Dbmaj7",
  "Dbsus",
  "Dm",
  "Dm6",
  "Dm7",
  "Dmaj7",
  "D#dim",
  "D#m",
  "Dsus",
  "E",
  "E6",
  "E7",
  "Eb",
  "Eb6",
  "Eb7",
  "Ebm",
  "Ebm6",
  "Ebm7",
  "Ebmaj7",
  "Ebsus",
  "Edim",
  "Em",
  "Em6",
  "Em7",
  "Emaj7",
  "Esus",
  "F",
  "F6",
  "F7",
  "Fm",
  "Fm6",
  "Fm7",
  "Fmaj7",
  "F#",
  "F#7",
  "F#dim",
  "F#m",
  "F#m6",
  "F#m7",
  "F#sus",
  "Fsus",
  "G",
  "G6",
  "G7",
  "Gb6",
  "Gbmaj7",
  "Gdim",
  "Gm",
  "Gm6",
  "Gm7",
  "Gmaj7",
  "G#dim",
  "G#m",
  "G#m6",
  "G#m7",
  "Gsus",
];

const chordNameWithoutSharp = (chordName: string) =>
  chordName.replace("#", "sharp");

const chordIconNameSuffix = (chord_icon_style: string) => {
  return chord_icon_style === "Guitar" ? "_guitar.svg" : ".svg";
};

const getLazyImage = async (chordName: string, chord_icon_style: string) => {
  // Default value for songs that existed before the addition of chord_icon_style
  chord_icon_style = chord_icon_style || "Guitar";

  try {
    const chordNameModified =
      chordNameWithoutSharp(chordName) + chordIconNameSuffix(chord_icon_style);
    const { default: imgSrc } = await import(
      `./assets/svg/${chord_icon_style.toLowerCase()}/${chordNameModified}`
    );
    return { title: chordName, icon: <img src={imgSrc} alt={chordName} /> };
  } catch (e) {
    return { title: chordName, icon: chordName };
  }
};

const getChordsByKey = async (key: string, chord_icon_style: string) => {
  const chordKey = key.includes("/") ? key.split("/")[1] : key;
  const chordsTable = CHORDS_TABLE.find((item) => item.key === chordKey);
  if (!chordsTable) {
    return [];
  }
  const titles = chordsTable.chords.map((val) => ({ title: val }));
  const result = await Promise.all(
    titles.map(async (val) => {
      const modifiedTitle = chordNameWithoutSharp(val.title);
      const lazyImage = await getLazyImage(modifiedTitle, chord_icon_style);
      return lazyImage;
    }),
  );
  return result;
};

const getAllChords = async (chord_icon_style: string) => {
  const chordsTable = COMPLETE_CHORDS_LIST;
  const titles = chordsTable.map((val) => ({ title: val }));
  const result = await Promise.all(
    titles.map(async (val) => {
      const lazyImage = await getLazyImage(val.title, chord_icon_style);
      return lazyImage;
    }),
  );
  return result;
};

export {
  CHORDS_TABLE,
  COMPLETE_CHORDS_TABLE,
  getLazyImage,
  getChordsByKey,
  getAllChords,
};
