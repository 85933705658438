import React from "react";

export interface IAudioPlayerState {
  audioRef: { current: null | HTMLAudioElement };
  resetButtons: () => void;
  progressFilledRef: { current: null | HTMLDivElement };
}

interface IAudioPlayerContext {
  audioPlayerState: IAudioPlayerState;
}

const initialState = {
  audioRef: { current: null },
  resetButtons: () => {},
  progressFilledRef: { current: null },
};

export const AudioPlayerContext = React.createContext<IAudioPlayerContext>({
  audioPlayerState: initialState,
});

export const useAudioPlayer = () => {
  return { audioPlayerState: initialState };
};
