import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCourseTeacherSignUp } from "redux/courses/courses_slice";
import { ONE_YEAR_LIMIT } from "components/organization/create_class_tab";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { toast } from "react-toastify";
import { ROLES } from "redux/auth/roles/roles_consts";
import { Redirect, useHistory } from "react-router";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { Header, StretchContainer, Top } from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { CreateClassInputsContainer } from "../../reusable/create_class_inputs_container";
import { courseCompletionDateDefault } from "../../reusable/helper/input_add_class_helper";
import { SignUpButton } from "../../reusable/button_sign_up";

import createClass from "../../images/create-class-logo.svg";
import {
  StyledContainer,
  StyledLogo,
  StyledForm,
  SkipButton,
  Footer,
  StyledButton,
} from "./create_class.styled";

export const CreateClass = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = useSelector(getOrganizationId);
  const isSoloTeacher = useSelector(getRoleSelector) === ROLES.SOLO_TEACHER;
  const [courseName, setCourseName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(
    courseCompletionDateDefault(),
  );

  const handleCreateClass = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!endDate || !startDate || endDate <= startDate || !orgId) {
      toast.error("Choose correct dates");
    }
    // @ts-ignore
    else if (endDate - startDate > ONE_YEAR_LIMIT) {
      toast.error("Class subscriptions cannot be longer than 12 months");
    } else {
      dispatch(
        createCourseTeacherSignUp({
          orgId,
          name: courseName,
          startDate,
          endDate,
        }),
      );
    }
  };

  const handleSkip = () => {
    history.push("/");
  };

  const inputValue = {
    courseName,
    setCourseName,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
  };

  return isSoloTeacher ? (
    <Redirect to="/" />
  ) : (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={72} />
      </Top>
      <StyledContainer>
        <Header>Let’s Set Up Your First Class</Header>
        <StyledLogo
          src={createClass}
          padding="14px 21.5px"
          margin="1em 1em 3em"
        />
        <StyledForm onSubmit={handleCreateClass} id="create_class_signUp">
          <CreateClassInputsContainer input={inputValue} />
        </StyledForm>
      </StyledContainer>
      <Footer>
        <StyledButton>
          <SignUpButton type="submit" formId="create_class_signUp">
            Continue
          </SignUpButton>
        </StyledButton>
        <SkipButton type="button" onClick={handleSkip}>
          Skip and Do This Later
        </SkipButton>
      </Footer>
    </StretchContainer>
  );
};
