import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import { IStudentLesson } from "types/models/student_with_skills";
import { MelodyDifficulties } from "./skill_view";
import { MIN_NUMBER_OF_LESSONS } from "./user_skills";

export const strummingCount = (lessons: IUserStrummingLessons) => {
  let skillsNumber = 0;
  // eslint-disable-next-line guard-for-in
  for (const prop in lessons) {
    lessons[prop].length >= MIN_NUMBER_OF_LESSONS && skillsNumber++;
  }
  return skillsNumber;
};

export const melodiesCount = (lessons: IStudentLesson[]) => {
  let skillsNumber = 0;
  MelodyDifficulties.forEach((difficulty) => {
    let difficultyNumber = 0;
    lessons.forEach((lesson) => {
      if (lesson.difficulty_level === difficulty.toLowerCase()) {
        difficultyNumber++;
        difficultyNumber >= MIN_NUMBER_OF_LESSONS && skillsNumber++;
      }
    });
  });
  return skillsNumber;
};
