import React, { useEffect, useState } from "react";
import { Box, Divider, Modal, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { shareSongByEmails } from "redux/songwriting/songwriting_slice";
import { ISharedUserSong } from "redux/songwriting/songwriting_interfaces";
import { getUserEmailSelector } from "redux/auth/user_selectors";
import { toast } from "react-toastify";
import { SongInviteesList } from "../song_invitees_list/song_invitees_list";
import { ShareSongsOptions } from "../share_songs_options/share_songs_options";
import classes from "./share_songs_modal.module.scss";

interface IShareSongsModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShareSongsModal = ({ open, setOpen }: IShareSongsModal) => {
  const currentSong = useSelector(getCurrentCustomSong);
  const userEmail = useSelector(getUserEmailSelector);
  const [recipientEmails, setRecipientEmails] = useState<string[]>([]);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isEmailNotUnique, setIsEmailNotUnique] = useState(false);
  const [notUniqueEmail, setNotUniqueEmail] = useState<string>("");
  const dispatch = useDispatch();

  const handleShare = () => {
    if (currentSong.id && currentSong.user_id) {
      if (userEmail && recipientEmails.includes(userEmail)) {
        toast.error("You can't invite yourself!");
        return;
      }

      dispatch(
        shareSongByEmails({
          song_id: currentSong.id,
          sender_id: currentSong.user_id,
          recipient_emails: recipientEmails,
        }),
      );
      setRecipientEmails([]);
    }
  };

  // @ts-ignore
  const handleKeyDown = (event) => {
    switch (event.key) {
      case ",": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          setRecipientEmails([...recipientEmails, event.target.value]);
        }
        break;
      }
      case "Enter": {
        break;
      }
      default:
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const checkEmailUniqueness = (recipientEmails: string[]) => {
    const currentSharedSong = currentSong.shared_songs?.find(
      (sharedSong: ISharedUserSong) =>
        sharedSong.custom_song_id === currentSong.id,
    );
    const isEmailNotUnique = currentSharedSong?.invitees.some((invite) =>
      recipientEmails.includes(invite.recipient_email),
    );
    const sharedUserWithNotUniqueEmail = currentSharedSong?.invitees.find(
      (invite) => recipientEmails.includes(invite.recipient_email),
    );
    if (sharedUserWithNotUniqueEmail) {
      setNotUniqueEmail(sharedUserWithNotUniqueEmail.recipient_email);
    }
    return (
      isEmailNotUnique !== undefined && setIsEmailNotUnique(isEmailNotUnique)
    );
  };

  const handleCancel = () => setRecipientEmails([]);

  useEffect(() => {
    setIsEmailInvalid(false);
    checkEmailUniqueness(recipientEmails);
    recipientEmails.map((email) => {
      if (!validateEmail(email)) {
        setIsEmailInvalid(true);
      }
      return email;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientEmails, isEmailInvalid]);

  const generateHelperText = () => {
    let helperText = "";
    if (!isEmailInvalid && isEmailNotUnique && notUniqueEmail) {
      helperText = `You have already shared this song with ${notUniqueEmail}`;
    } else if (isEmailInvalid && isEmailNotUnique) {
      helperText = "Check correctness of email";
    } else if (isEmailInvalid) {
      helperText = "At least one email is invalid.";
    }
    return helperText;
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={(e) => setOpen(false)}
    >
      <Box className={classes.wrapper}>
        <Typography variant="h5" className={classes.highlighted_text}>
          Share {currentSong.song_name}
        </Typography>
        <button
          type="button"
          onClick={(e) => setOpen(false)}
          className={classes.close}
        >
          &times;
        </button>
        <Box className={classes.padding_top}>
          <Typography>
            <span className={classes.highlighted_text}>Add People</span>{" "}
            <em>(Users will have edit access)</em>
          </Typography>
          <Autocomplete
            multiple
            id="tags-filled"
            options={recipientEmails}
            defaultValue={[]}
            onChange={(event, newValue) => setRecipientEmails(newValue)}
            freeSolo
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  style={{ paddingTop: "1rem" }}
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                  helperText={generateHelperText()}
                  placeholder="Enter email address, comma separated"
                />
              );
            }}
          />
        </Box>
        <Box className={classes.padding_top}>
          <Typography style={{ fontWeight: "500" }}>
            Shared with{" "}
            {currentSong.shared_songs
              ?.map((song) => song.invitees.length)
              .reduce((partialSum, a) => partialSum + a, 0) || 0}
          </Typography>
        </Box>
        <Box className={classes.padding_top}>
          <SongInviteesList sharedSongs={currentSong.shared_songs} />
        </Box>
        {recipientEmails.length > 0 && (
          <Box className={classes.padding_top}>
            <Divider />
            <ShareSongsOptions
              handleCancel={handleCancel}
              isEmailInvalid={isEmailInvalid}
              handleShare={handleShare}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};
