import { Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { ICourse } from "redux/courses/courses_api";
import { deleteCourse } from "redux/organizations/organization_courses_common_actions";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import css from "./delete_course_dialog.module.scss";

interface IDeleteCourseDialog {
  course: ICourse;
  closeDeleteCourseDialog: (e: React.MouseEvent) => void;
  handleCloseMenu: (e: React.MouseEvent) => void;
  deleteCourseDialog: boolean;
}

export const DeleteCourseDialog = ({
  course,
  closeDeleteCourseDialog,
  handleCloseMenu,
  deleteCourseDialog,
}: IDeleteCourseDialog) => {
  const studentsCount = course.users.length;
  const orgId = useSelector(getOrganizationId);
  const dispatch = useDispatch();

  const handleDeleteCourse = (e: React.MouseEvent) => {
    if (orgId) {
      dispatch(deleteCourse({ courseId: course.id, orgId }));
      closeDeleteCourseDialog(e);
      handleCloseMenu(e);
    }
  };

  const handleCloseModal = (e: React.MouseEvent) => {
    closeDeleteCourseDialog(e);
  };

  return (
    <Dialog
      open={deleteCourseDialog}
      onClose={handleCloseModal}
      aria-labelledby="max-width-dialog-title"
      PaperProps={{ classes: { root: css.paper } }}
    >
      <button
        type="button"
        onClick={handleCloseModal}
        className={css.close_icon}
      >
        <CloseIcon />
      </button>
      <section className={css.remove_class_dialog}>
        <h2 className={css.remove_class_header}>
          {`Are you sure you want to remove ${course.name}?`}
        </h2>
        <p className={css.subtitle}>
          {`If ‘yes, remove’ you will be deleting this class and will free up ${studentsCount}
          seats. All students in this class will still have access to their
          account but will no longer have free membership to all song lessons.`}
        </p>
        <div className={css.buttons_container}>
          <button
            type="button"
            className={css.no_keep}
            onClick={handleCloseModal}
          >
            No, keep
          </button>
          <button
            onClick={handleDeleteCourse}
            type="button"
            className={css.yes_remove}
          >
            Yes, Remove
          </button>
        </div>
      </section>
    </Dialog>
  );
};
