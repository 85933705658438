import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getStoreKitDataSelector = (state: IState) =>
  state.entities.storeKit;

export const getStoreKitProduct = createSelector(
  (state: IState) => state.entities.storeKit.product,
  (product) => product,
);
