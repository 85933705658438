import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrganizationPassword } from "redux/organizations/organization_selectors";
import { IStudents } from "../add_students_bulk_tab";
import css from "./add_students_bulk_bottom.module.scss";

interface IAddStudentsBulkBottom {
  students: IStudents[];
}

export const AddStudentsBulkBottom = ({ students }: IAddStudentsBulkBottom) => {
  const defaultPassword = useSelector(getOrganizationPassword);

  return (
    <div className={css.bottomText}>
      <hr className={css.hr} />
      {defaultPassword ? (
        <div className={css.globalPasswordText}>
          {`Your global password for students is set to ${defaultPassword}`}
          <br />
          <Link className={css.linkToProfile} to="profile">
            update password in ‘my profile’
          </Link>
        </div>
      ) : null}
      <button
        disabled={!students.length}
        className={css.sentInviteButton}
        type="submit"
      >
        {defaultPassword ? "Add Students" : "Send Invite"}
      </button>
    </div>
  );
};
