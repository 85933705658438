import { useRef, Dispatch } from "react";
import { usePopover } from "hooks/use_popover";
import { ILesson } from "api/lesson_api";
import { useWindowWidth, MOBILE_WIDTH } from "../../hooks/use_width";
import { SongSheetDesktop } from "./song_sheet_top_bar_desktop";
import { useScroll } from "./hooks/use_scroll";
import { SongSheetLessonInfoPopover } from "./song_sheet_lesson_info_popover";
import { SongSheetTopBarMobile } from "./song_sheet_top_bar_mobile";
import { MetronomeControls } from "./metronome_controls";
import { BPMSlider } from "./BPM_slider";
import { SoundSelect } from "./metronome_sound_select";
import { ScrollControls } from "./scroll_controls";
import { useStopOnScroll } from "./hooks/use_stop_on_scroll";
import { useMetronome } from "./hooks/use_metronome";

interface ISongSheetTopBar {
  lesson: ILesson;
  setTempo: Dispatch<number>;
  tempo: number;
  handleOpenChords: () => void;
}
export const SongSheetTopBar = ({
  lesson,
  setTempo,
  tempo,
  handleOpenChords,
}: ISongSheetTopBar) => {
  const { tempo: lessonBPM = DEFAULT_BPM, length } = lesson.song;

  const speedModifier = lessonBPM / tempo;
  const lessonDuration = lesson.song.length;

  const { disableScroll, scrollActive, toggleScroll, scrollRequested } =
    useScroll(lessonDuration, speedModifier);

  useStopOnScroll(disableScroll);

  const audioRef = useRef<HTMLAudioElement>(null);

  const {
    toggleMetronome,
    metronomeTimerId,
    turnOffMetronome,
    refreshMetronome,
  } = useMetronome(disableScroll, scrollActive, tempo, audioRef);

  const scrollControls = (
    <ScrollControls
      length={length}
      scrollActive={scrollActive}
      scrollRequested={scrollRequested}
      toggleScroll={toggleScroll}
    />
  );

  const slider = (
    <BPMSlider setTempo={setTempo} defaultBPM={lessonBPM} tempo={tempo} />
  );

  const metronomeControls = (
    <MetronomeControls
      soundSelect={
        <SoundSelect
          refreshMetronome={refreshMetronome}
          turnOffMetronome={turnOffMetronome}
          ref={audioRef}
        />
      }
    />
  );
  const mobileMetronomeControls = (
    <MetronomeControls
      mobile
      metronome={metronomeTimerId}
      toggleMetronome={toggleMetronome}
      soundSelect={<SoundSelect mobile ref={audioRef} />}
    />
  );

  const width = useWindowWidth();
  const [anchorElementSong, handleClickSong, handleCloseSong] = usePopover();

  return width > MOBILE_WIDTH ? (
    <>
      <SongSheetDesktop
        handleClickSong={handleClickSong}
        slider={slider}
        lesson={lesson}
        disableScroll={disableScroll}
        scrollControls={scrollControls}
        metronomeControls={metronomeControls}
        tempo={tempo}
        handleOpenChords={handleOpenChords}
      />
      <SongSheetLessonInfoPopover
        lesson={lesson}
        anchorElementSong={anchorElementSong}
        handleCloseSong={handleCloseSong}
      />
    </>
  ) : (
    <>
      <SongSheetTopBarMobile
        handleClickSong={handleClickSong}
        scrollControls={scrollControls}
        slider={slider}
        metronomeControls={mobileMetronomeControls}
        lesson={lesson}
        tempo={tempo}
      />
      <SongSheetLessonInfoPopover
        mobile
        lesson={lesson}
        anchorElementSong={anchorElementSong}
        handleCloseSong={handleCloseSong}
      />
    </>
  );
};

const DEFAULT_BPM = 120;
