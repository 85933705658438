import { createSelector } from "reselect";
import { IState } from "redux/store";
import { ISkill } from "types/models/skill";
import { IInstruments } from "../user_skills/user_skills_slice";

export const getProgressionsSelector = (state: IState) => [
  ...state.entities.progressions.guitar,
  ...state.entities.progressions.ukulele,
];

export const getProgressionsLikeStringSelector = createSelector(
  [getProgressionsSelector],
  (progressions) =>
    progressions
      .map((progression: ISkill) => JSON.parse(progression.name).join("-"))
      .sort(),
);

export const getProgressionsByInstrument = (instrument: IInstruments) =>
  createSelector(
    (state: IState) => state.entities.progressions[instrument],
    (progressions) => progressions,
  );
export const getProgressionsAmountByInstrument = (instrument: IInstruments) =>
  createSelector(getProgressionsByInstrument(instrument), (progressions) =>
    progressions ? progressions.length : 0,
  );
export const getProgressionsByInstrumentLikeString = (
  instrument: IInstruments,
) =>
  createSelector(getProgressionsByInstrument(instrument), (progressions) =>
    progressions
      .map((progression: ISkill) => JSON.parse(progression.name).join("-"))
      .sort(),
  );

export const getProgressionsCountSelector = createSelector(
  [getProgressionsSelector],
  (progressions) => (progressions ? progressions.length : 0),
);
