import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import {
  TUNER,
  ORGANIZATION,
  PROFILE_ROUTE,
  ORG_MANAGEMENT,
  MOBILE_SIGNUP,
  SIGNUP,
} from "routes/route_constants";
import { useWindowWidth } from "hooks/use_width";
import {
  GUITAR_CHORD_LIBRARY_TYPE,
  openChordLibraryModal,
  openLoginModal,
  openPayForSubscriptionModal,
} from "redux/ui/modal/modal_slice";
import { handleLogout } from "redux/auth/auth_slice";
import { isAuthorizedSelector } from "redux/auth/user_selectors";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { ROLES } from "redux/auth/roles/roles_consts";
import { activeLinks } from "./active_links";

interface IMenuLinks {
  close?: () => void;
  css: { [key: string]: string };
}

// Menu links for both mobile and desktop when user is logged in
// css is taken from props, depending if from mobile or desktop menu
export const MenuLinks = (props: IMenuLinks) => {
  const isAuth = useSelector(isAuthorizedSelector);
  const userRole = useSelector(getRoleSelector);
  const { close } = props;
  const { pathname } = useLocation();
  const active = activeLinks(pathname);
  const { css } = props;
  const tablet_width = 800;
  const mobile = useWindowWidth() < tablet_width;
  const isFreeUser =
    userRole === ROLES.FREE_USER ||
    userRole === ROLES.FREE_TEACHER_ACCOUNT ||
    userRole === ROLES.FREE_SONGWRITING;
  const accessStudent = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_ACCESS),
  );
  const isAdmin = useSelector(
    accessByPermissionSelector(PERMISSIONS.MANAGE_ORGANIZATIONS),
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const mobileDevice = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

  return (
    <div className={css.links}>
      {isAuth && (
        <Link
          to={PROFILE_ROUTE}
          onClick={close}
          className={`${css.link} ${active[PROFILE_ROUTE]}`}
        >
          Profile
        </Link>
      )}
      {isAuth && (
        <Link
          to={TUNER}
          onClick={close}
          className={`${css.link} ${active[TUNER]}`}
        >
          Tuner
        </Link>
      )}

      {accessStudent && (
        <Link
          to={ORGANIZATION}
          onClick={close}
          className={`${css.link} ${active[ORGANIZATION]}`}
        >
          My Students
        </Link>
      )}
      {isAdmin && (
        <Link
          to={ORG_MANAGEMENT}
          onClick={close}
          className={`${css.link} ${active[ORG_MANAGEMENT]}`}
        >
          Management
        </Link>
      )}
      <button
        onClick={() =>
          dispatch(openChordLibraryModal(GUITAR_CHORD_LIBRARY_TYPE))
        }
        type="button"
        className={`${css.link} ${active[ORG_MANAGEMENT]}`}
      >
        Chords
      </button>
      {isAuth && (
        <a
          href="mailto:help@moosiko.com"
          target="_blank"
          rel="noopener noreferrer"
          className={css.link}
        >
          Help
        </a>
      )}
      {isAuth && mobile && isFreeUser && (
        <button
          type="button"
          className={css.link}
          onClick={() => dispatch(openPayForSubscriptionModal())}
        >
          Upgrade
        </button>
      )}
      {!isAuth && (
        <button
          type="button"
          onClick={() => dispatch(openLoginModal())}
          className={css.link}
        >
          Log in
        </button>
      )}
      {!isAuth && (
        <button
          type="button"
          onClick={() => {
            close && !mobileDevice && close();
            history.push(mobileDevice ? MOBILE_SIGNUP : SIGNUP);
          }}
          className={css.link}
        >
          Sign up
        </button>
      )}
      {isAuth && (
        <button
          type="button"
          onClick={() => {
            dispatch(handleLogout({ close }));
          }}
          className={css.link}
        >
          Log Out
        </button>
      )}
    </div>
  );
};
