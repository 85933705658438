import { reminderModalApi } from "api/reminder_modal_api";
import { REMINDER_MODAL_NAMES } from "constants/reminder_modal_names";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserRole } from "redux/auth/auth_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import {
  getUserEmailSelector,
  getUserIdSelector,
} from "redux/auth/user_selectors";
import {
  closeModal,
  openChoosePlanModal,
  openOrgNameModal,
} from "redux/ui/modal/modal_slice";

import Button from "../../reusable/buttons/button";
import { ReactComponent as UpgradeIcon } from "../../sign_up/images/upgrade-to-free-teacher.svg";
import css from "../modal.module.scss";
import { ModalContainer } from "../modal_container";
import modalCss from "./teacher_account_confirmation_modal.module.scss";

export const TeacherAccountConfirmationModal = () => {
  const userEmail = useSelector(getUserEmailSelector);
  const userId = useSelector(getUserIdSelector);
  const dispatch = useDispatch();

  const acceptHandler = async () => {
    if (userEmail && userId) {
      dispatch(
        updateUserRole({
          email: userEmail,
          roleName: ROLES.FREE_TEACHER_ACCOUNT,
        }),
      );
      await reminderModalApi.completeReminder({
        userId,
        modalTitle: REMINDER_MODAL_NAMES.TEACHER_CONFIRMATION,
      });
      dispatch(closeModal());
      dispatch(openOrgNameModal());
    }
  };

  const dismissHandler = async () => {
    // Just adding reminder completion to dismiss the modal
    if (userId) {
      await reminderModalApi.completeReminder({
        userId,
        modalTitle: REMINDER_MODAL_NAMES.TEACHER_CONFIRMATION,
      });

      dispatch(closeModal());
      dispatch(openChoosePlanModal());
    }
  };

  return (
    <ModalContainer classContainer={modalCss.wrapper}>
      <h2 className={css.title}>Upgrade to a Free Teacher Account?</h2>
      <UpgradeIcon />
      <span className={css.description}>
        Looks like you signed up with a school email address. Do you want to
        upgrade to a free teacher account so you can add students and track
        skills?
      </span>
      <div className={modalCss.button_container}>
        <Button type="dark" onClick={dismissHandler}>
          No
        </Button>
        <Button onClick={acceptHandler}>Yes</Button>
      </div>
    </ModalContainer>
  );
};
