import { PAID_TEACHER_SUBSCRIPTION } from "redux/auth/roles/roles_consts";
import { toast } from "react-toastify";
import { DEV } from "util/vars";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constants/message_constants";
import { analyticUtil } from "util/analytic";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "redux/auth/auth_api";
import { AppDispatch } from "types/redux_";
import { IStripeProduct } from "components/stripe/stripe_checkout/types";
import { changeFilter } from "redux/ui/filters/filters_slice";
import { StripeCustomer } from "../../auth/auth_slice";
import { disableButton } from "../../ui/form/form_slice";
import { receiveUserLesson } from "../user_lessons/user_lessons_actions";
import {
  IStipeFetchResult,
  IStripeParams,
  stripeApi,
} from "../../../api/stripe_api";

const receiveStripeAction = createAction<{ paid: boolean }>(
  "stripe/receiveStripe",
);

export const receiveCustomerAction = createAction<StripeCustomer>(
  "stripe/receiveCustomer",
);

export const receiveSubscription = createAction(
  "stripe/receiveSubscription",
  (user: IUser, sub: IStipeFetchResult) => ({
    type: "stripe/receiveSubscription",
    payload: {
      subscribed: true,
      subscription_end: user.subscription_end,
      cancelAtPeriodEnd: sub.cancelAtPeriodEnd,
    },
  }),
);

export const receiveCancelAtPeriod = createAction(
  "stripe/receiveCancelAtPeriod",
  (sub: IStipeFetchResult) => ({
    type: "stripe/receiveCancelAtPeriod",
    payload: { cancelAtPeriodEnd: sub.cancelAtPeriodEnd },
  }),
);

export const fetchCustomer = () => (dispatch: AppDispatch) => {
  return stripeApi
    .fetchCustomer()
    .then((payload) => {
      const { data } = payload;
      dispatch(receiveCustomerAction(data.customer));

      if (data.stripe) {
        dispatch(receiveCancelAtPeriod(data.stripe));
      }
      dispatch(changeFilter({ filter: "fetchedCustomer", value: true }));
    })
    .catch((err) => {
      if (DEV) {
        console.error(err.response);
      }
    });
};

export const createCharge = (
  stripeParams: IStripeParams,
  handleError: (_: unknown) => void,
) => (dispatch: AppDispatch) => {
  return stripeApi
    .createCharge(stripeParams)
    .then((payload) => {
      const { data } = payload;
      dispatch(receiveUserLesson(data.userLesson));
      dispatch(receiveStripeAction(data.stripe));
      if (data.customer) {
        dispatch(receiveCustomerAction(data.customer));
        dispatch(changeFilter({ filter: "fetchedCustomer", value: true }));
      }
    })
    .catch((err) => {
      if (DEV) {
        console.info(err.response);
      }
      handleError(err.response.data.error.message);
    })
    .then(() => {
      dispatch(disableButton(false));
    });
};

export const subscribe = (
  stripeToken: string,
  handleError: (_: unknown) => void,
  productId: string,
) => (dispatch: AppDispatch) => {
  return stripeApi
    .subscribe(productId, { stripeToken })
    .then((payload) => {
      const { data } = payload;
      if (data.stripe.status === "active") {
        dispatch(receiveStripeAction({ paid: true }));
        dispatch(receiveSubscription(data.user, data.stripe));
        analyticUtil.sendSubscribeEvent();
      }
      if (data.customer) {
        dispatch(receiveCustomerAction(data.customer));
        dispatch(changeFilter({ filter: "fetchedCustomer", value: true }));
      }
    })
    .catch((err) => {
      if (DEV) {
        console.info(err.response);
      }
      const errMessage =
        err.response && err.response.data.error
          ? err.response.data.error.message
          : SOMETHING_WENT_WRONG_MESSAGE;
      handleError(errMessage);
      throw err;
    })
    .finally(() => {
      dispatch(disableButton(false));
    });
};

export const unsubscribe = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(disableButton(true));
    const { data } = await stripeApi.unsubscribe();

    dispatch(receiveSubscription(data.user, data.stripe));
  } catch (err: any) {
    if (DEV) {
      console.info(err.response);
    }

    const errMessage =
      err.response && err.response.data.error
        ? err.response.data.error.message
        : SOMETHING_WENT_WRONG_MESSAGE;
    toast.error(errMessage);
  } finally {
    dispatch(disableButton(false));
  }
};

interface IStripeSlice {
  paid: boolean;
  product: IStripeProduct;
  quantity?: number;
  pricePerStudent?: number;
}

const initialState: IStripeSlice = {
  paid: false,
  product: {
    id: "",
    price: "",
    postfix: "",
  },
  quantity: 0.5,
  pricePerStudent: PAID_TEACHER_SUBSCRIPTION.COST_0_99.PRICE,
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setStripeProduct: (state, action: PayloadAction<IStripeProduct>) => {
      state.product = action.payload;
    },
    setStripeProductQuantity: (state, action: PayloadAction<number>) => {
      state.quantity = action.payload;
    },
    setPricePerStudent: (state, action: PayloadAction<number>) => {
      state.pricePerStudent = action.payload;
    },
    setProductId: (state, action: PayloadAction<string>) => {
      state.product.id = action.payload;
    },
    setProductPrice: (state, action: PayloadAction<string>) => {
      state.product.price = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(closeModal, () => {
    //   return initialState;
    // });
    builder.addCase(receiveStripeAction, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export const {
  reducer: stripeReducer,
  actions: {
    setStripeProduct,
    setStripeProductQuantity,
    setPricePerStudent,
    setProductId,
    setProductPrice,
  },
} = stripeSlice;
