import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INSTRUMENTS } from "components/songs/constants";

interface IChordsFilter {
  name: "chords";
  data: string[];
}
interface IDecadesFilter {
  name: "decade";
  data: string;
}
interface ISearchFilter {
  name: "search";
  data: string;
}
interface ISkillTypesFilter {
  name: "skillTypes";
  data: string[];
}
interface ILessonKeysFilter {
  name: "lessonKeys";
  data: string[];
}
interface ISongKeysFilter {
  name: "songKeys";
  data: string[];
}
interface INumberOfChordsFilter {
  name: "numberOfChords";
  data: string;
}
interface IInstrumentsFilter {
  name: "instruments";
  data: string[];
}

interface IChordsExactMatchFilter {
  name: "chordsExactMatch";
  data: boolean;
}

type TSongFilter =
  | IChordsFilter
  | IDecadesFilter
  | ISearchFilter
  | ISkillTypesFilter
  | ILessonKeysFilter
  | ISongKeysFilter
  | INumberOfChordsFilter
  | IInstrumentsFilter
  | IChordsExactMatchFilter;

export interface ISongFilterState {
  chords: IChordsFilter["data"];
  decade: IDecadesFilter["data"];
  search: ISearchFilter["data"];
  skillTypes: ISkillTypesFilter["data"];
  lessonKeys: ILessonKeysFilter["data"];
  songKeys: ISongKeysFilter["data"];
  numberOfChords: INumberOfChordsFilter["data"];
  instruments: IInstrumentsFilter["data"];
  chordsExactMatch: IChordsExactMatchFilter["data"];
}

const initialState: ISongFilterState = {
  decade: "",
  search: "",
  numberOfChords: "",
  chords: [],
  skillTypes: [],
  lessonKeys: [],
  songKeys: [],
  instruments: [INSTRUMENTS[0]],
  chordsExactMatch: false,
};

const counterSlice = createSlice({
  name: "songFilter",
  initialState,
  reducers: {
    setSongFilter: (state, action: PayloadAction<TSongFilter>) => {
      // @ts-ignore
      state[action.payload.name] = action.payload.data;
    },
    dropSongFilter: () => ({
      ...initialState,
    }),
  },
});

export const {
  reducer: songFilterReducer,
  actions: { setSongFilter, dropSongFilter },
} = counterSlice;
