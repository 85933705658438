import React from "react";
import { useDispatch } from "react-redux";
import css from "./clear_selection.module.scss";

interface IClearSelection {
  clearFunction: () => void;
}

export const ClearSelection: React.FC<IClearSelection> = ({
  clearFunction,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(clearFunction());
  };
  return (
    <button onClick={handleClick} type="button" className={css.clear_button}>
      Clear
    </button>
  );
};
