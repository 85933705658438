import classnames from "classnames";
import { products } from "redux/entities/stripe/stripe_consts";
import css from "./choose_plan_modal.module.scss";
import modalCSS from "../modal.module.scss";
import { ModalContainer } from "../modal_container";
import { FreePlan } from "./subscription_plan/free_plan";
import { MemberPlan } from "./subscription_plan/member_plan";

export const ChoosePlanModal = () => {
  return (
    <ModalContainer classes={{ main: css.modal }}>
      <div className={modalCSS.title}>Choose the right plan for you</div>

      <div
        className={classnames(modalCSS.content_center, modalCSS.side_padding)}
      >
        <div className={css.choose_plan}>
          <div className={css.plan}>
            <FreePlan />
          </div>

          <div className={css.plan}>
            <MemberPlan products={products} />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
