/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable  no-unused-vars */
import { loadStripe } from "@stripe/stripe-js";
import { stripePubKey } from "redux/entities/stripe/stripe_consts";
import React, {
  useState,
  useCallback,
  useEffect,
  ReactNode,
  Dispatch,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardElement } from "@stripe/react-stripe-js";
import { Radio, Checkbox } from "@material-ui/core";

import Spinner from "components/loading/spinner";
import {
  getCustomerSelector,
  getCustomerCardSelector,
  isCustomerFetchedSelector,
} from "redux/entities/stripe/stripe_selectors";

import { isButtonDisabledSelector } from "redux/ui/form/form_selectors";
import { useResetForm } from "hooks/use_reset_form";
import { disableButton } from "redux/ui/form/form_slice";
import { fetchCustomer } from "redux/entities/stripe/stripe_slice";
import mixpanel from "mixpanel-browser";
import css from "./stripe.module.scss";

export const FORM_STRIPE_PAY = "form-stripe-pay-subscribe";
const SMALL_MOBILE_DEVICE_WIDTH = 365;
// handle small mobile devices
const fontSize =
  window.screen.width < SMALL_MOBILE_DEVICE_WIDTH ? "16px" : "18px";

const style = {
  base: {
    fontSize,
    color: "#424770",
    fontFamily: "Source Code Pro, monospace",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#9e2146",
  },
};
interface ICheckoutFormBaseComponent {
  price: string;
  onSubmit: (
    error: any,
    options: {
      stripeToken?: string;
      amount?: string;
      saveCard?: boolean;
      useSavedCard?: boolean;
    },
  ) => Promise<void>;
  error?: string | null;
  onChange?: () => void;
  stripe?: any;
}

export const CheckoutFormBase = (props: ICheckoutFormBaseComponent) => {
  const { price, error: errorFromProps, onSubmit, onChange, stripe } = props;

  const dispatch = useDispatch();
  const stripePromise = loadStripe(stripePubKey);
  const [error, setError] = useState<string | null>(null);
  const [saveCard, setSaveCard] = useState(true);
  const [useSavedCard, setUseSavedCard] = useState(true);
  const [cardEnterFinished, setCardEnterFinished] = useState(false);

  const customer = useSelector(getCustomerSelector);
  const card = useSelector(getCustomerCardSelector);
  const fetchedCustomer = useSelector(isCustomerFetchedSelector);

  const haveSavedCard = customer && !!card;

  useFetchCustomer(dispatch, fetchedCustomer);
  useResetForm(dispatch);

  const onCardChangeCallback = useCallback(
    (e) => {
      setCardEnterFinished(e.complete);
      if (onChange) {
        onChange();
      }

      if (e.error) {
        setError(e.error.message);
      } else {
        setError(null);
      }
    },
    [onChange],
  );

  const onSaveCardChangeCallback = useCallback(
    (e) => {
      const useSaved = e.target.value === "true";
      setUseSavedCard(useSaved);
    },
    [setUseSavedCard],
  );

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (e: React.FormEvent) => {
      e.preventDefault();

      const priceNoDot = `${price}`.split(".").join("");
      dispatch(disableButton(true));
      if (haveSavedCard && useSavedCard) {
        return onSubmit(null, {
          amount: priceNoDot,
          useSavedCard,
        });
      }
      const response = await stripe?.createToken();

      if (response?.token) {
        return onSubmit(null, {
          stripeToken: response.token.id,
          amount: priceNoDot,
          saveCard,
        });
      }
      if (response?.error) {
        dispatch(disableButton(false));
        setError(response?.error?.message || null);
        return onSubmit(response.error, {});
      }
      onSubmit(null, {});

      mixpanel.track("Purchase Attempted", {
        product: "stripe",
        price,
      });
    },
    [dispatch, haveSavedCard, onSubmit, price, saveCard, stripe, useSavedCard],
  );

  if (!fetchedCustomer) {
    return <Spinner spinnerClass="spinner4" />;
  }

  return (
    <form onSubmit={handleSubmit} className={css.checkout} id={FORM_STRIPE_PAY}>
      {haveSavedCard ? (
        <div onChange={onSaveCardChangeCallback} className={css.select}>
          <label>
            <Radio value checked={useSavedCard} color="primary" />
            Use card ending in {card?.last4} ({card?.brand})
          </label>
          <label>
            <Radio value={false} checked={!useSavedCard} color="primary" />
            Use a different card
          </label>
        </div>
      ) : null}

      {haveSavedCard && useSavedCard ? null : (
        <>
          <label className={css.label}>
            Credit or debit card
            <CardElement
              onChange={onCardChangeCallback}
              onReady={(el) => el.focus()}
              // @ts-ignore
              style={style}
              hidePostalCode
            />
          </label>
          {cardEnterFinished ? (
            <label className={css.save}>
              <Checkbox
                onChange={() => setSaveCard(!saveCard)}
                checked={saveCard}
                color="primary"
              />
              <span>Save card details</span>
            </label>
          ) : null}
        </>
      )}

      <div className={css.error}>{errorFromProps || error}</div>
    </form>
  );
};

const useFetchCustomer = (
  dispatch: Dispatch<any>,
  fetchedCustomer: boolean,
) => {
  useEffect(() => {
    if (!fetchedCustomer) {
      dispatch(fetchCustomer());
    }
  }, [dispatch, fetchedCustomer]);
};
