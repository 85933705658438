import React, { FormEvent, useEffect, useState } from "react";
import { HelpAddStudents } from "components/common/learn_new_button/help_add_students/help_add_students";
import { useSelector, useDispatch } from "react-redux";
import { getClassModalCourseId } from "redux/ui/modal/modal_selectors";
import { closeClassModal } from "redux/ui/modal/modal_slice";
import { inviteStudents } from "../../../redux/courses/courses_slice";
import css from "./add_students_bulk_tab.module.scss";
import { AddStudentsBulkTop } from "./add_students_bulk_top";
import { AddStudentsBulkBottom } from "./add_students_bulk_bottom";
import { AddStudentsBulkUpload } from "./add_students_bulk_upload";

interface IAddStudentsBulkTab {
  setSelectedStudentsCount: React.Dispatch<React.SetStateAction<number>>;
}

export interface IStudents {
  firstName: string;
  lastName: string;
  email: string;
}

export const AddStudentsBulkTab = (props: IAddStudentsBulkTab) => {
  const { setSelectedStudentsCount } = props;
  const dispatch = useDispatch();
  const courseId = useSelector(getClassModalCourseId);
  const [students, setStudents] = useState<IStudents[]>([]);

  useEffect(() => {
    setSelectedStudentsCount(students.length);
  }, [setSelectedStudentsCount, students]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(inviteStudents({ students, courseId }));
    dispatch(closeClassModal());
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={css.addStudentsContainer}
      id="add_student_bulk_upload_form"
    >
      <HelpAddStudents />
      <AddStudentsBulkTop />
      <AddStudentsBulkUpload students={students} setStudents={setStudents} />
      <AddStudentsBulkBottom students={students} />
    </form>
  );
};
