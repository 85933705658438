import { createSelector } from "reselect";
import { IState } from "redux/store";
import { ILessonWithUnknownSkills } from "../../../api/recommendation_api";
import { getLessonsCacheSelector } from "../lessons/lesson_selectors";

interface RecommendedLessons extends ILessonWithUnknownSkills {
  artist: string;
  song_name: string;
}

export const getRecommendedLessonsSelector = createSelector(
  (state: IState) => state.entities.recommendation.recommendedLessons,
  getLessonsCacheSelector,
  (recommendedLessons, lessons) => {
    return recommendedLessons.reduce((result: RecommendedLessons[], rl) => {
      const lesson = lessons[rl.id];
      if (lesson) {
        result.push({
          ...rl,
          artist: lesson.song.artist,
          song_name: lesson.song.song_name,
        });
      }
      return result;
    }, []);
  },
);

export const fetchingMoreSelector = (state: IState) =>
  state.entities.recommendation.fetchingMore;

export const getRecommendedLessonsPaginationSelector = createSelector(
  (state: IState) => state.entities.recommendation,
  (recommendationReducer) => {
    return {
      start: recommendationReducer.start,
      limit: recommendationReducer.limit,
    };
  },
);

export const getSkillTypeSelector = (state: IState) => state.ui.modal.skillType;
export const getSkillInstrumentSelector = (state: IState) =>
  state.ui.modal.instrument;
export const getSkillNameSelector = (state: IState) => state.ui.modal.skillName;
export const getSkillIdSelector = (state: IState) => state.ui.modal.skillId;

export const getRecommendedLessonsCountSelector = (state: IState) => ({
  start: state.entities.recommendation.start,
  lessonsCount: state.entities.recommendation.lessonsCount,
});
