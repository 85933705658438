import css from "./add_students_bulk_top.module.scss";

export const AddStudentsBulkTop = () => {
  return (
    <>
      <ul className={css.ul}>
        <li>
          1) Download{" "}
          <a
            className={css.template}
            href="https://moosiko-prod.s3-us-west-1.amazonaws.com/MyStudents.csv"
          >
            this template{" "}
          </a>
          MyStudents.csv
        </li>
        <li>
          2) Add your students to the template file; First Name, Last Name,
          Email
        </li>
        <li> 3) Upload the CSV below by dragging/dropping OR click ‘select’</li>
      </ul>
      <div className={css.email}>
        Need a hand? Send an email to
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto: help@moosiko.com"
          className={css.email_link}
        >
          &nbsp;help@moosiko.com
        </a>
      </div>
    </>
  );
};
