import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserSelector } from "redux/auth/user_selectors";
import { useGapi } from "hooks/useGapi";
import * as msal from "@azure/msal-browser";
import { oauthLogin } from "redux/auth/auth_slice";

interface ISSHLogIn {
  children: React.ReactNode;
  provider: "Google" | "Microsoft";
  // eslint-disable-next-line
  additionalAction?: ({ email }: { email?: string }) => void;
}

const msalConfig = {
  auth: {
    clientId: "15ed0bd4-4290-42a8-aceb-76bbecdd77d3",
  },
};

const loginRequest = {
  scopes: ["user.read", "openid", "profile", "email"], // optional Array<string>
};
const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");
export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const SSHLogIn = ({
  children,
  provider,
  additionalAction,
}: ISSHLogIn) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const gapi = useGapi();

  const handleGoogleLogIn = async () => {
    //  handle iOS authentication, this event will be handled by the iOS app
    if (iOSApp) {
      await (window as any).webkit.messageHandlers.socialAuth.postMessage(
        "google",
      );
    } else if (gapi) {
      const GoogleAuth = gapi.auth2.getAuthInstance();
      // if they still have valid Google auth tokens stored in cookies, but aren't logged in with us anymore,
      // then log them out of Google (invalidate their google auth cookies)
      if (!user && GoogleAuth.isSignedIn.get()) {
        GoogleAuth.signOut();
      }
      // Log them in and catch error if they close the popup.
      try {
        const data = await GoogleAuth.signIn();

        if (additionalAction) {
          await additionalAction({ email: data.getBasicProfile().getEmail() });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleMicrosoftLogIn = async () => {
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      const { accessToken } = loginResponse;
      await dispatch(oauthLogin({ token: accessToken, provider: "microsoft" }));
      if (additionalAction) {
        additionalAction({ email: loginResponse.account?.username });
      }
    } catch (err) {
      console.error(err);
    }
  };

  let onClick;
  if (provider === "Google") {
    onClick = handleGoogleLogIn;
  } else if (provider === "Microsoft") {
    onClick = handleMicrosoftLogIn;
  }

  return (
    <button type="button" onClick={onClick}>
      {children}
    </button>
  );
};
