import { decadesApi, IDecade } from "api/decades_api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IDecadesState {
  decades: IDecade[];
  fetching: boolean;
}

const initialState: IDecadesState = {
  decades: [],
  fetching: true,
};

export const fetchDecades = createAsyncThunk(
  "decades/fetchDecades",
  async () => {
    const { data: decades } = await decadesApi.fetchDecades();
    return decades;
  },
);

export const decadesSlice = createSlice({
  name: "decades",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDecades.fulfilled, (state, action) => {
      state.decades = action.payload;
    });
    builder.addCase(fetchDecades.rejected, (state, action) => {
      console.error("fetchDecades error", action.error);
    });
  },
});

export const { reducer: decadesReducer } = decadesSlice;
