import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export const RecordItemSkeleton = () => {
  return (
    <Box
      padding="12px 20px"
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <Skeleton
              variant="text"
              width={400}
              height={30}
              style={{ borderRadius: "5px" }}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          style={{
            flexDirection: "column",
            alignItems: "left",
            gap: "0.3ren",
          }}
        >
          <Skeleton
            variant="text"
            width={200}
            height={30}
            style={{ borderRadius: "5px" }}
          />
          <Box
            display="flex"
            style={{ gap: "0.3rem" }}
            width="90%"
            justifyContent="space-between"
          >
            <Box />
            <Skeleton variant="circle" width={30} height={30} />
            <Skeleton variant="circle" width={30} height={30} />
          </Box>
          <Skeleton
            variant="text"
            width={400}
            height={30}
            style={{ borderRadius: "5px" }}
          />
          <Skeleton
            variant="text"
            width={400}
            height={30}
            style={{ borderRadius: "5px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
