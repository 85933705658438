import React from "react";
import { calculatePercentage } from "util/math_util";
import { CHORDS_CARD, NO_CARD } from "..";
import { Card } from "../card";

export interface ISkillsCard {
  active: boolean;
  isOtherActive: boolean;
  handleClickOpen: (_: number) => void;
  handleClickClose: () => void;
  children: React.ReactNode;
  profileLinkText?: string;
}

interface IChordsCard extends ISkillsCard {
  chordsCount: number;
  userChordsCount: number;
}

export const ChordsCard = (props: IChordsCard) => {
  const {
    active,
    isOtherActive,
    handleClickOpen,
    handleClickClose,
    children,
    chordsCount,
    userChordsCount,
    profileLinkText = "Manually Adjust Skills",
  } = props;

  return (
    <Card
      measurementValue={userChordsCount}
      progress={calculatePercentage(userChordsCount, chordsCount)}
      text="Chords"
      active={active}
      handleClick={() => handleClickOpen(CHORDS_CARD)}
      handleClickClose={() => handleClickClose()}
      isOtherActive={isOtherActive}
      line
      profileLinkText={profileLinkText}
    >
      {children}
    </Card>
  );
};

export const isOtherActive = (currentActiveCard: number, cardNumber: number) =>
  currentActiveCard !== cardNumber && currentActiveCard !== NO_CARD;
