import styled from "@emotion/styled";

interface IStudentConversationDialogStat {
  imgSrc: string;
  title: string;
  text: string;
}

export const StudentConversationDialogStat = ({
  imgSrc,
  title,
  text,
}: IStudentConversationDialogStat) => {
  return (
    <StatContainer>
      <StatImg src={imgSrc} />
      <StatValue>{title}</StatValue>
      <StatText>{text}</StatText>
    </StatContainer>
  );
};

const StatContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
`;

const StatImg = styled.img`
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
`;

const StatValue = styled.h2`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #6775de;
`;

const StatText = styled.p`
  font-size: 16px;
  line-height: 19px;
`;
