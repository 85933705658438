import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getSongSeedsState = (state: IState) => state.songSeeds;

export const getActiveSongSeeds = createSelector(
  [getSongSeedsState],
  (songSeeds) => songSeeds.songSeeds.filter((seed) => seed.status === "Active"),
);

export const getActiveLyricSeeds = createSelector(
  [getSongSeedsState],
  (songSeeds) =>
    songSeeds.lyricSeeds.filter((seed) => seed.status === "Active"),
);

export const getCurrentSeed = createSelector(
  [getSongSeedsState],
  (songSeeds) => songSeeds.currentSeed,
);

export const getSeedType = (id?: number | null) =>
  createSelector([getSongSeedsState], (songSeeds) => {
    const songSeed = songSeeds.songSeeds.find((item) => {
      return item.id === id;
    });
    const lyricSeed = songSeeds.lyricSeeds.find((item) => {
      return item.id === id;
    });
    if (songSeed) {
      return songSeed.seed_type;
    }
    if (lyricSeed) {
      return lyricSeed.seed_type;
    }
  });

export const getSeedTitle = (id?: number | null) =>
  createSelector([getSongSeedsState], (songSeeds) => {
    const songSeed = songSeeds.songSeeds.find((item) => {
      return item.id === id;
    });
    if (songSeed) {
      return songSeed.title;
    }
  });

export const getSeedText = (id?: number | null) =>
  createSelector([getSongSeedsState], (songSeeds) => {
    const songSeed = songSeeds.songSeeds.find((item) => {
      return item.id === id;
    });
    const lyricSeed = songSeeds.lyricSeeds.find((item) => {
      return item.id === id;
    });
    if (songSeed) {
      return songSeed.text;
    }

    if (lyricSeed) {
      return lyricSeed.text;
    }
  });

export const getNewSeedFlag = createSelector(
  [getSongSeedsState],
  (songSeeds) => songSeeds.newSeed,
);

export const getCurrentSeedType = createSelector(
  [getSongSeedsState],
  (songSeeds) => songSeeds.currentSeedType,
);

export const getAudioSeeds = createSelector(
  [getSongSeedsState],
  (songSeeds) => songSeeds.audioSeeds,
);
