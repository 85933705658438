import React from "react";
import classNames from "classnames";
import { GUITAR_NOTES } from "constants/notes";
import { Box } from "@material-ui/core";
import css from "../tuner_layout.module.scss";
import Guitar from "../assets/guitar.png";

interface IGuitarLayout {
  activeNoteFrequency: string;
  setActiveNoteFrequency: React.Dispatch<React.SetStateAction<string>>;
  setActiveNoteName: React.Dispatch<React.SetStateAction<string>>;
}

export const GuitarGrid = ({
  activeNoteFrequency,
  setActiveNoteFrequency,
  setActiveNoteName,
}: IGuitarLayout) => {
  const changeNoteHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveNoteFrequency(e.currentTarget.value);
    setActiveNoteName(e.currentTarget.dataset.notename || "");
  };
  // Important! If 1st element is "E" we swap "left-sided" notes cause of the guitar img layout
  const shuffledNotes = [...GUITAR_NOTES];

  shuffledNotes[0].note === "E" &&
    ([shuffledNotes[0], shuffledNotes[2]] = [
      shuffledNotes[2],
      shuffledNotes[0],
    ]);

  return (
    <Box className={css.grid_guitar}>
      {shuffledNotes.map((note, index) => {
        return (
          <button
            onClick={changeNoteHandler}
            value={note.frequency}
            type="button"
            data-notename={note.note}
            className={classNames(
              css[`grid_pos-guitar-${index + 1}`],
              css.rounded_button,
              {
                [css.active_rounded_button]:
                  activeNoteFrequency === note.frequency,
              },
            )}
            key={note.frequency}
          >
            {note.note}
          </button>
        );
      })}
      <img className={css.img_box} src={Guitar} alt="Guitar head" />
    </Box>
  );
};
