import { Box, Input, Popover, Slider, Typography } from "@material-ui/core";
import React from "react";

import css from "../../custom_song_settings.module.scss";

interface ISongTempoPopoverProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  sliderValue: Number | null;
  setSliderValue: (_value: number | null) => void;
}

export const SongTempoPopover: React.FC<ISongTempoPopoverProps> = ({
  anchorEl,
  handleClose,
  sliderValue,
  setSliderValue,
}) => {
  const openPopover = !!anchorEl;
  const id = openPopover ? "song-key-popover" : undefined;

  const min = 40;
  const max = 220;

  const sliderChangeHandler = (
    _event: React.ChangeEvent<{}>,
    newValue: number | number[],
  ) => {
    setSliderValue(newValue as number);
  };

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);

    if (value === 0) {
      event.target.value = "";
      setSliderValue(null);
      return;
    }
    if (Number.isNaN(value)) {
      return;
    }
    setSliderValue(value);
  };

  const inputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    let value = Number(event.target.value);
    if (Number.isNaN(value)) {
      value = sliderValue as number;
    } else if (value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }

    setSliderValue(value);
  };

  return (
    <Popover
      PaperProps={{ className: css.popover }}
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box display="flex" flexDirection="Column" style={{ gap: "35px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Tempo</Typography>
          <Input
            style={{ width: "50px" }}
            value={sliderValue}
            onChange={inputChangeHandler}
            onBlur={inputBlurHandler}
            type="number"
          />
        </Box>
        <Slider
          value={Number(sliderValue)}
          onChange={sliderChangeHandler}
          min={min}
          max={max}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
      </Box>
    </Popover>
  );
};
