import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IStoreKitProduct {
  id: string;
  price: string;
  postfix: string;
}

interface IStoreKitSlice {
  product: IStoreKitProduct;
}

const initialState: IStoreKitSlice = {
  product: {
    id: "",
    price: "",
    postfix: "",
  },
};

const storeKitSlice = createSlice({
  name: "storeKit",
  initialState,
  reducers: {
    setStoreKitProduct: (state, action: PayloadAction<IStoreKitProduct>) => {
      state.product = action.payload;
    },
  },
});

export const {
  reducer: storeKitReducer,
  actions: { setStoreKitProduct },
} = storeKitSlice;
