import React from "react";
import { Box } from "@material-ui/core";
import { AvailableProvidersEnum } from "../login_modal";
import { ICONS } from "./icons";

import css from "./button_auth.module.scss";

interface IButtonAuth {
  provider: AvailableProvidersEnum;
  handleClick?: () => void;
  overideLabel?: string;
}

export const ButtonAuth = ({
  provider,
  handleClick,
  overideLabel,
}: IButtonAuth) => {
  const buttonLabel = () => {
    if (overideLabel) {
      return `${overideLabel} ${provider}`;
    }
    return `Log In with ${provider}`;
  };

  return (
    <Box className={css.button_auth} onClick={handleClick}>
      <i className={css.icon}>
        <svg
          width={provider === AvailableProvidersEnum.email ? "27" : "36"}
          height={provider === AvailableProvidersEnum.email ? "27" : "30"}
          viewBox="0 0 36 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {ICONS[provider]}
        </svg>
      </i>
      {buttonLabel()}
    </Box>
  );
};
