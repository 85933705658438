import { Paper } from "@material-ui/core";
import { BusinessCardEdit } from "components/settings/business_card_edit";
import { SideBar } from "components/sidebar/sidebar";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getHiddenSongs } from "redux/organizations/organization_slice";
import { getHiddenSongsSelector } from "redux/organizations/organization_selectors";
import { ProfileInstructorControls } from "components/layouts/profile/profile_instructor_controls/profile_instructor_controls";
import { InstrumentAccess } from "./instrument_access/instrument_access";
import { LessonSettings } from "./lesson_settings/lesson_settings";
import { LessonPrivacy } from "./lesson_privacy/lesson_privacy";
import { HiddenLessons } from "./hidden_lessons/hidden_lessons";

type IPage = "main" | "hiddenSongs";
interface ISettings {
  page: IPage;
}

export const Settings = ({ page }: ISettings) => {
  const dispatch = useDispatch();
  const hiddenSongs = useSelector(getHiddenSongsSelector);

  useEffect(() => {
    dispatch(getHiddenSongs());
  }, [dispatch]);

  const renderPage = (page: IPage) => {
    switch (page) {
      case "main":
        return (
          <>
            <BusinessCardEdit />
            <LessonPrivacy amountHiddenSongs={hiddenSongs.length} />
            <InstrumentAccess />
            <LessonSettings />
            <ProfileInstructorControls />
          </>
        );
      case "hiddenSongs":
        return <HiddenLessons />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <SideBar />
      <PageContent>
        <StyledPaper>{renderPage(page)}</StyledPaper>
      </PageContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 32px;
  font-size: 16px;

  @media (min-width: 350px) and (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70vw;
  max-width: 90em;
`;

const StyledPaper = styled(Paper)`
  padding: 2em 0 2em 2em;
  margin-bottom: 20px;
`;
