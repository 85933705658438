// @ts-nocheck

class GoogleTagManagerUtil {
  pageView() {
    // handled automatically in google tag manager
  }

  sendSignUpEvent() {
    window.dataLayer.push({ event: "SignUp" });
  }

  sendSubscribeEvent() {
    window.dataLayer.push({ event: "Subscribe" });
  }
}

export const googleTagManagerUtil = new GoogleTagManagerUtil();
