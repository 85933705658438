import { Box, Button } from "@material-ui/core";
import classes from "./share_songs_options.module.scss";

interface IShareSongsOptions {
  handleCancel: () => void;
  isEmailInvalid: boolean;
  handleShare: () => void;
}

export const ShareSongsOptions = ({
  handleCancel,
  isEmailInvalid,
  handleShare,
}: IShareSongsOptions) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "1rem",
      }}
    >
      <Button className={classes.button} onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        className={classes.button_primary}
        variant="contained"
        color="primary"
        disabled={isEmailInvalid}
        onClick={handleShare}
      >
        Send invite
      </Button>
    </Box>
  );
};
