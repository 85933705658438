import React, { useEffect, useRef, useState } from "react";
import { Popover } from "@material-ui/core";
import { getOrganizationId } from "redux/organizations/organization_selectors";
import { useDispatch, useSelector } from "react-redux";
import { feedbackApi } from "redux/feedback/feedback_api";
import { fetchMediaRecordings } from "redux/media_recordings/media_recordings_slice";
import { IFeedbackBase } from "redux/feedback/feedback_types";
import CloseIcon from "@material-ui/icons/Close";
import { NoteTextArea } from "./note_text_area";
import css from "./note_feedback_popover.module.scss";

interface INoteFeedbackPopover {
  closeNoteFeedbackPopover: (e: React.MouseEvent) => void;
  noteFeedbackPopoverAnchor: Element | null;
  feedbackBase: IFeedbackBase;
}

export const NoteFeedbackPopover = ({
  closeNoteFeedbackPopover,
  noteFeedbackPopoverAnchor,
  feedbackBase,
}: INoteFeedbackPopover) => {
  const [noteTextValue, setNoteTextValue] = useState("");
  const orgId = useSelector(getOrganizationId);

  const dispatch = useDispatch();

  const sendNoteFeedback = async (e: React.MouseEvent) => {
    const feedback = { ...feedbackBase, text: noteTextValue };
    await feedbackApi.createFeedback(feedback);
    if (orgId) {
      dispatch(fetchMediaRecordings({ orgId }));
    }
    closeNoteFeedbackPopover(e);
  };

  const textareaRef = useRef<null | HTMLTextAreaElement>(null);
  // focus on textarea when popover opens
  useEffect(() => {
    const DELAY_MS = 200;
    setTimeout(() => textareaRef.current?.focus(), DELAY_MS);
  }, [noteFeedbackPopoverAnchor]);

  return (
    <Popover
      anchorEl={noteFeedbackPopoverAnchor}
      keepMounted
      open={Boolean(noteFeedbackPopoverAnchor)}
      onClose={closeNoteFeedbackPopover}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <NoteTextArea
        ref={textareaRef}
        value={noteTextValue}
        setValue={setNoteTextValue}
        onSubmit={sendNoteFeedback}
        headerText="Add Note"
        submitText="Send Feedback"
      />
      <button
        type="button"
        className={css.close_note_icon}
        onClick={closeNoteFeedbackPopover}
      >
        <CloseIcon />
      </button>
    </Popover>
  );
};
