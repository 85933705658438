import { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import useRecorder from "hooks/use_recorder";

import css from "./record_button_display.module.scss";

interface IRecordButtonDisplayProps {
  onRecordComplete: () => void;
}

export const RecordButtonDisplay = ({
  onRecordComplete,
}: IRecordButtonDisplayProps) => {
  const { recorderState, ...handlers } = useRecorder();
  const { startRecording, saveRecording } = handlers;
  const { initRecording, recordingMinutes, recordingSeconds } = recorderState;

  const [recordButtonActive, setRecorderButtonActive] =
    useState<boolean>(false);

  const recordButtonClick = () => {
    setRecorderButtonActive(!recordButtonActive);
    if (initRecording) {
      saveRecording();
      onRecordComplete();
    } else {
      startRecording();
    }
  };

  const getRecordButtonClassName = () => {
    if (recordButtonActive && !initRecording) {
      return css.icon_recording_inner_loading;
    }
    if (recordButtonActive) {
      return css.icon_recording_inner_active;
    }
    return css.icon_recording_inner_inactive;
  };

  const getRecordingTimeDisplay = () => {
    return `${recordingMinutes}:${
      recordingSeconds < 10 ? `0${recordingSeconds}` : recordingSeconds
    }`;
  };

  const getLabel = () => {
    return initRecording ? getRecordingTimeDisplay() : "Record";
  };

  return (
    <Button
      onClick={recordButtonClick}
      disabled={recordButtonActive && !initRecording}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        color="#fff"
        width="65px"
      >
        <Box className={getRecordButtonClassName()} />
        <Typography
          style={{
            textTransform: "none",
            fontFamily: "Raleway",
          }}
        >
          {getLabel()}
        </Typography>
      </Box>
    </Button>
  );
};
