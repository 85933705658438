import { useDispatch } from "react-redux";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { useLocation, useHistory } from "react-router";
import { UPGRADE } from "routes/route_constants";
import {
  CircleBackground,
  Container,
  Header,
  StretchContainer,
  Top,
} from "../emotion.styles";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { FooterSignUp } from "../../reusable/footer_sign_up";
import { stepsId } from "../../newSignUp";
import {
  Paragraph,
  ContentHeader,
  Description,
  Content,
} from "./pay_by_check_next_step.styled";

import checkboxList from "../../images/checkbox-list-logo.svg";

export const PayByCheckNextSteps = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleNextAction = () => {
    if (pathname === UPGRADE) {
      history.push("/");
    } else {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.signUpStepList,
          stepId: stepsId.createClass,
        }),
      );
    }
  };

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={68} />
      </Top>
      <Container>
        <Header>What’s Next?</Header>
        <LogoWithBackground
          src={checkboxList}
          padding="25px 31px"
          margin="2em"
        />
        <Content>
          <Paragraph>
            <CircleBackground>1</CircleBackground>
            <Description>
              <ContentHeader>Check Your Inbox</ContentHeader>
              We will email you a quote within 24 hours based on the information
              you provided. Email
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:help@moosiko.com"
              >
                {" "}
                help@moosiko.com
              </a>{" "}
              if you have any questions.
            </Description>
          </Paragraph>
          <Paragraph>
            <CircleBackground>2</CircleBackground>
            <Description>
              <ContentHeader>Review with Your Administration</ContentHeader>
              Review quote and get approval from your administration. Need
              additional info? Email{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:help@moosiko.com"
              >
                {" "}
                help@moosiko.com{" "}
              </a>
              and we’ll get what you need.
            </Description>
          </Paragraph>
          <Paragraph>
            <CircleBackground>3</CircleBackground>
            <Description>
              <ContentHeader>Send us a Purchase Order</ContentHeader>
              Have your admin or procurement department send purchase orders to
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:sales@moosiko.com"
              >
                {" "}
                sales@moosiko.com
              </a>
              . Once we receive the purchase order, we'll flip your account to a
              paid account within a few hours so you and your students get
              access to all our song lessons and assessment features.
            </Description>
          </Paragraph>
          <Paragraph>
            <CircleBackground>4</CircleBackground>
            <Description>
              <ContentHeader>Training & Resources</ContentHeader>
              Once we receive the PO, we'll send you a bunch of training videos
              and resources. We're happy to schedule virtual training for
              individual or group teachers as necessary.
            </Description>
          </Paragraph>
        </Content>
      </Container>
      <FooterSignUp
        textButton={pathname === UPGRADE ? "Done" : "Create Your First Class"}
        paddingButton="0.5em 1.3em"
        handleClick={handleNextAction}
      >
        Any Questions? Email{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:help@moosiko.com"
        >
          help@moosiko.com
        </a>
      </FooterSignUp>
    </StretchContainer>
  );
};
