import React from "react";
import styled from "@emotion/styled";

interface ITopLineProgress {
  percent?: number;
}
export const TopLineProgress = ({ percent }: ITopLineProgress) => {
  return <TopLine percent={percent} />;
};

const TopLine = styled.div<ITopLineProgress>`
  height: 1em;
  background-color: #6775de;
  width: ${({ percent = "100" }) => `${percent}%`};
`;
