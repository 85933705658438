import { ILesson } from "api/lesson_api";
import { AlphaTab } from "components/reusable/alpha_tab/AlphaTab";
import { ukuleleMelodyTuning } from "components/slides/slide_types/Melody";

export const AlphaTabSheet = ({ lesson }: { lesson: ILesson }) => {
  let tuning = "";

  lesson.instrument === "Ukulele" ? (tuning = ukuleleMelodyTuning) : "";
  return (
    <div style={{ marginTop: "40px" }}>
      <AlphaTab
        textCode={lesson.alpha_tab_code}
        tempo={lesson.song.tempo}
        timeSig={lesson.time_signature}
        tuning={tuning}
      />
    </div>
  );
};
