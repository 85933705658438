import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as Hamburger } from "img/hamburger_icon.svg";
import { openModal } from "redux/ui/modal/modal_slice";
import { FreeSongsRemains } from "components/free_songs_remains";
import { Logo } from "components/reusable/logo/Logo";
import css from "../nav.module.scss";

export const NavMobile = () => {
  const dispatch = useDispatch();
  const openMobileMenu = () => dispatch(openModal({ type: "mobileMenu" }));

  return (
    <div className={css.mobile}>
      <Link to="/" className={css.icon}>
        <Logo />
      </Link>

      <FreeSongsRemains />

      <Hamburger onClick={openMobileMenu} className={css.hamburger} />
    </div>
  );
};
