import { ChangeEvent, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
  Button,
  Popover,
  Typography,
  Theme,
  createStyles,
} from "@material-ui/core";
import {
  addHiddenSongs,
  deleteHiddenSongs,
  searchSongsToHide,
} from "redux/organizations/organization_slice";
import {
  getHiddenSongsSelector,
  getSearchSongsToHide,
} from "redux/organizations/organization_selectors";
import { IHiddenSongsApi } from "redux/organizations/organization_api";
import cn from "classnames";
import _ from "lodash";
import { SETTINGS } from "routes/route_constants";
import { Link } from "react-router-dom";

const useStylesForm = makeStyles(() => ({
  margin: {
    margin: "4px",
  },
  textField: {
    width: "480px",
    marginLeft: "10px",
  },
  "@media (max-width: 600px)": {
    textField: {
      width: 280,
    },
  },
}));

const useStylesPopover = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    popover: {
      overflow: "hidden",
    },
    addButton: {
      color: "#2F80ED",
      textDecorationLine: "underline",
      fontWeight: "bold",
      textTransform: "initial",
    },
    deleteButton: {
      marginRight: "1em",
    },
  }),
);

export const MIN_INPUT_FOR_HELP = 3;

export const HiddenLessons = () => {
  const dispatch = useDispatch();
  const classesForm = useStylesForm();
  const classesPopover = useStylesPopover();
  const hiddenSongs = useSelector(getHiddenSongsSelector);
  const searchSongs = useSelector(getSearchSongsToHide);
  const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const delayDebounce = 500;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce_fun = useCallback(
    _.debounce((searchSong: string, event: HTMLInputElement) => {
      dispatch(searchSongsToHide(searchSong));
      setAnchorEl(event);
    }, delayDebounce),
    [],
  );

  useEffect(() => {
    if (!searchSongs.length) {
      handleClosePopover();
    }
  }, [searchSongs]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);

    if (value.length < MIN_INPUT_FOR_HELP) {
      handleClosePopover();
    } else {
      debounce_fun(value, event.currentTarget);
    }
  };

  const handleDeleteHiddenSong = (songId: number) => {
    dispatch(deleteHiddenSongs({ songId }));
  };

  const handleAddHiddenSong = (params: IHiddenSongsApi) => {
    dispatch(addHiddenSongs(params));
    setValue("");
    handleClosePopover();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Container>
      <Link to={SETTINGS}>
        <Header>
          <StyledArrowDownwardIcon />
          <HeaderTittle>Lesson Privacy - Hidden Songs</HeaderTittle>
        </Header>
      </Link>

      <FormContainer>
        <FormControl
          className={cn(classesForm.margin, classesForm.textField)}
          variant="outlined"
        >
          <InputLabel htmlFor="search-songs-for-hide">
            Search song...
          </InputLabel>
          <OutlinedInput
            id="search-songs-for-hide"
            type="text"
            aria-describedby={id}
            onChange={onSearchChange}
            value={value}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={125}
          />
        </FormControl>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          classes={{ paper: classesPopover.popover }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableAutoFocus
          disableEnforceFocus
        >
          <Typography className={classesPopover.typography} component="span">
            {searchSongs.map((song) => (
              <InputTooltip key={song.song_name}>
                <DescriptionSong>
                  <div>{song.song_name}</div>
                  <div>{song.artist}</div>
                </DescriptionSong>

                <Button
                  classes={{
                    label: classesPopover.addButton,
                  }}
                  onClick={() =>
                    handleAddHiddenSong({
                      id: song.id,
                      song_name: song.song_name,
                      artist: song.artist,
                    })
                  }
                >
                  Hide
                </Button>
              </InputTooltip>
            ))}
          </Typography>
        </Popover>
      </FormContainer>

      <HeaderHiddenSongs>
        These songs are hidden globally for all classes and your teacher
        account.
      </HeaderHiddenSongs>
      {hiddenSongs.map(
        (song) =>
          song.visibility && (
            <ListHiddenSongs key={song.id}>
              {song.song_name}
              <Button
                classes={{
                  root: classesPopover.deleteButton,
                }}
                type="button"
                onClick={() => handleDeleteHiddenSong(song.id)}
              >
                <CloseIcon />
              </Button>
            </ListHiddenSongs>
          ),
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
  transform: rotate(90deg);
  padding: 0 0.5em;
`;

const HeaderTittle = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: #818181;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  margin-top: 3em;
`;

const ListHiddenSongs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8em 0 1.4em;
  font-weight: bold;
  color: #818181;
  border-bottom: 1px solid #cccccc;
`;

const InputTooltip = styled(ListHiddenSongs)`
  align-items: center;
  padding: 1em 15px 0.5em;
  width: 450px;

  @media (max-width: 600px) {
    width: 250px;
  }
`;

const HeaderHiddenSongs = styled(ListHiddenSongs)`
  font-size: 1.2em;
  font-style: italic;
  font-weight: normal;
  padding: 1.8em 0 0.5em;
`;

const DescriptionSong = styled.div`
  display: flex;
  flex-direction: column;

  div:nth-of-type(1) {
    font-weight: 500;
  }
  div:nth-of-type(2) {
    font-size: 0.75em;
    font-weight: normal;
  }
`;
