import cn from "classnames";

import chordImg from "img/chord_icon.svg";
import chordTransitionImg from "img/chord_transition_icon.svg";
import chordProgressionImg from "img/Icon - Progression.svg";
import strummingProgressingImg from "img/Strumming.svg";
import css from "../recommendation_modal.module.scss";

interface IRecommendedLesson {
  song: string;
  artist: string;
  newChords: string[];
  instrument: string;
  newTransitions: string[];
  newProgressions: string[];
  newStrummings: string[];
  onClick: () => void;
  teaser: boolean | null;
}
export const RecommendedLesson = (props: IRecommendedLesson) => {
  const {
    song,
    artist,
    newChords,
    newTransitions,
    newProgressions,
    newStrummings,
    onClick,
    teaser,
    instrument,
  } = props;
  return (
    <button type="button" className={css.song} onClick={onClick}>
      <div className={css.song_header}>
        <div className={cn(css.song_name, { [css.blur]: teaser })}>
          {song} | <p className={cn(css.song_instrument)}>{instrument}</p>
        </div>
        <div className={css.artist}>{artist}</div>
      </div>
      <div className={css.song_description}>
        {newChords && newChords.length !== 0 && (
          <div className={css.chords}>
            <img
              className={cn(css.chords_img, css.skill_img)}
              src={chordImg}
              alt="chords"
            />
            <div className={cn(css.chords_text, { [css.blur]: teaser })}>
              {newChords.length}
              {` new chord${newChords.length !== 1 ? "s" : ""}: `}
              {newChords.join(", ")}
            </div>
          </div>
        )}

        {newTransitions && newTransitions.length !== 0 && (
          <div className={css.transitions}>
            <img
              className={cn(css.chord_transition_img, css.skill_img)}
              src={chordTransitionImg}
              alt="chord transitions"
            />
            <div className={css.chords_text}>
              <span className={css.skill}>{newTransitions.length}</span>
              {` new transition${newTransitions.length !== 1 ? "s" : ""} `}
            </div>
          </div>
        )}

        {newProgressions && newProgressions.length !== 0 && (
          <div className={css.transitions}>
            <img
              className={cn(css.chord_transition_img, css.skill_img)}
              src={chordProgressionImg}
              alt="chord progressions"
            />
            <div className={css.chords_text}>
              <span className={css.skill}>{newProgressions.length}</span>
              {` new progression${newProgressions.length !== 1 ? "s" : ""} `}
            </div>
          </div>
        )}

        {newStrummings && newStrummings.length !== 0 && (
          <div className={css.transitions}>
            <img
              className={cn(css.chord_transition_img, css.skill_img)}
              src={strummingProgressingImg}
              alt="strumming progressions"
            />
            <div className={css.chords_text}>
              <span className={css.skill}>{newStrummings.length}</span>
              {` new strumming pattern${
                newStrummings.length !== 1 ? "s" : ""
              } `}
            </div>
          </div>
        )}
      </div>
    </button>
  );
};
