import React, { forwardRef, InputHTMLAttributes } from "react";
import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";

interface IStyledInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  customErr?: string;
  hookFormErr?: any;
  className?: string;
}

export const EmotionInput = forwardRef<HTMLInputElement, IStyledInput>(
  ({ name, customErr, hookFormErr, className, ...props }, ref) => {
    return (
      <Container className={className}>
        <StyledInput {...props} name={name} ref={ref} />
        {hookFormErr && Object.keys(hookFormErr).length !== 0 ? (
          <ErrorContainer>
            <ErrorMessage
              errors={hookFormErr}
              name={name}
              render={({ message }) => <InputError>{message}</InputError>}
            />
          </ErrorContainer>
        ) : (
          customErr && (
            <ErrorContainer>
              {customErr && <InputError>{customErr}</InputError>}
            </ErrorContainer>
          )
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
  width: 400px;
  text-align: start;
`;

export const StyledInput = styled.input`
  padding: 15px 0px 14px 16px;
  border-radius: 4px;
  background: #f3f3f3;
  color: #818181;
  font-size: 16px;
  line-height: 19px;
  min-width: 100px;
  text-align: start;
`;

export const ErrorContainer = styled.div`
  position: relative;
`;

export const InputError = styled.div<{ width?: string }>`
  position: absolute;
  color: white;
  background: #6775de;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 5px;
  box-sizing: border-box;
  padding: 2px 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  width: ${({ width = "100%" }) => width};
`;
