import React from "react";
import { useDispatch } from "react-redux";
import { Box, Card, IconButton, Typography } from "@material-ui/core";
import { openSeedNoteModal } from "redux/ui/modal/modal_slice";
import {
  setCurrentSeed,
  setNewSeedFlag,
} from "redux/song_seeds/song_seeds_slice";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FirstLine from "./first_line";
import LastUpdated from "./last_updated";

interface ISeedCard {
  seed_id?: number;
  title: string;
  lastUpdated: string;
  firstLine: string;
  type: string;
  setSeedAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export const SeedCard = ({
  seed_id,
  title,
  lastUpdated,
  firstLine,
  type,
  setSeedAnchorEl,
}: ISeedCard) => {
  const dispatch = useDispatch();

  const launchNote = () => {
    if (seed_id) {
      dispatch(setCurrentSeed({ id: seed_id }));
      dispatch(setNewSeedFlag({ newSeed: false }));
      dispatch(openSeedNoteModal());
    }
  };

  return (
    <Card
      onClick={launchNote}
      style={{
        width: "85vw",
        borderRadius: 15,
        padding: 15,
        cursor: "pointer",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {type === "song" && (
          <Box display="flex" flexDirection="column" width="80%">
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 16,
                color: "#333333",
                fontFamily: "Raleway",
              }}
            >
              {title}
            </Typography>
            <LastUpdated lastUpdated={lastUpdated} />
            <FirstLine firstLine={firstLine} />
          </Box>
        )}
        {type === "lyrics" && (
          <Box display="flex" flexDirection="column" width="80%">
            <FirstLine firstLine={firstLine} />
            <LastUpdated lastUpdated={lastUpdated} />
          </Box>
        )}
        <IconButton
          onClick={(e) => {
            if (seed_id) {
              e.stopPropagation();
              setSeedAnchorEl(e.currentTarget);
              dispatch(setCurrentSeed({ id: seed_id }));
            }
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
    </Card>
  );
};
