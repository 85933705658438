import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTablesData,
  getCurrentCustomSong,
  getActualTablesData,
} from "redux/songwriting/songwriting_selectors";
import {
  updateChordsInTable,
  updateCurrentSongLyricsBucket,
  setSelectedSongBucket,
  updateCustomSongChords,
} from "redux/songwriting/songwriting_slice";
import _ from "lodash";
import {
  ActiveDataChord,
  OverDataChord,
} from "redux/songwriting/songwriting_interfaces";
import { toast } from "react-toastify";
import { useSongUnitTableContent } from "./use_song_unit_table_content";

interface IDataChord {
  chord: string;
  row: number;
  column: number;
}

export const useChordsTable = () => {
  const dispatch = useDispatch();

  const tablesData = useSelector(getTablesData());
  const currentSong = useSelector(getCurrentCustomSong);
  const actualTablesData = useSelector(getActualTablesData);

  const { collectChanges } = useSongUnitTableContent();

  const findStructureToUpdate = useCallback(
    (structureId: number) => {
      return currentSong.custom_song_lyrics.find(
        (item) => item.id === structureId,
      );
    },
    [currentSong.custom_song_lyrics],
  );

  const validateChordLocation = (
    posData: { row: number; col: number },
    parsedChords: IDataChord[],
  ) => {
    //  This function is used to prevent dragged or added chords from overwriting each other
    const startColumn = posData.col;
    const incrementColumn = (currentColumn: any) => {
      currentColumn++;
      return currentColumn.toString();
    };
    const decrementColumn = (currentColumn: any) => {
      currentColumn--;
      return currentColumn.toString();
    };
    const numberOfColumns = 49;
    let direction = "increment";
    while (
      parsedChords.find(
        (item) => item.row === posData.row && item.column === posData.col,
      )
    ) {
      if (direction === "increment") {
        posData.col = incrementColumn(posData.col);
      } else {
        posData.col = decrementColumn(posData.col);
      }

      if (posData.col > numberOfColumns) {
        direction = "decrement";
        posData.col = startColumn;
      }
    }
    return posData;
  };

  const addNewChordToStructure = useCallback(
    (chordId: string, structureId: number, rowId: number, columnId: number) => {
      dispatch(setSelectedSongBucket({ id: structureId }));

      const tableData = tablesData.find((item) => item.id === structureId);
      const actualTableData = actualTablesData.find(
        (item) => item.id === structureId,
      )?.data;

      const dataChord: IDataChord = {
        chord: chordId,
        row: rowId,
        column: columnId,
      };

      const song_lyrics = collectChanges({ lyricsData: actualTableData });
      const oldChords = tableData?.songChords;
      let data;

      if (oldChords) {
        const parsedChords = JSON.parse(oldChords);
        const rowChords = parsedChords.filter(
          (c: IDataChord) => c.row === rowId,
        );
        if (rowChords.length >= 10) {
          toast.info("You can't place more than 10 chords on this row.");
          return;
        }

        const validPosition = validateChordLocation(
          { row: dataChord.row, col: dataChord.column },
          parsedChords,
        );
        dataChord.column = validPosition.col;
        dataChord.row = validPosition.row;

        parsedChords.push(dataChord);
        data = parsedChords;
      } else {
        data = [dataChord];
      }
      dispatch(
        updateCurrentSongLyricsBucket({
          song_chords: JSON.stringify(data),
          song_lyrics,
        }),
      );

      dispatch(
        updateCustomSongChords({
          id: structureId,
          song_chords: JSON.stringify(data),
        }),
      );
      dispatch(
        updateChordsInTable({
          id: structureId,
          songChords: JSON.stringify(data),
        }),
      );
    },
    [actualTablesData, tablesData, collectChanges, dispatch],
  );

  const deleteChordFromStructure = useCallback(
    ({ title, structureId, rowId, columnId }: ActiveDataChord) => {
      dispatch(setSelectedSongBucket({ id: structureId }));
      const structureToUpdate = findStructureToUpdate(structureId);
      const tableData = tablesData.find(
        (data) => data.id === structureToUpdate?.id,
      );

      if (structureToUpdate && tableData) {
        const parsedChords = JSON.parse(tableData.songChords);
        _.remove(parsedChords, {
          row: rowId,
          column: columnId,
          chord: title,
        });

        dispatch(
          updateCurrentSongLyricsBucket({
            song_chords: JSON.stringify(parsedChords),
          }),
        );

        dispatch(
          updateCustomSongChords({
            id: structureId,
            song_chords: JSON.stringify(parsedChords),
          }),
        );

        dispatch(
          updateChordsInTable({
            id: structureId,
            songChords: JSON.stringify(parsedChords),
          }),
        );
      }
    },
    [dispatch, findStructureToUpdate, tablesData],
  );

  const replaceChordInTable = useCallback(
    (
      startData: ActiveDataChord,
      endData: OverDataChord,
      mobileFlag?: boolean,
    ) => {
      const { structureId } = startData;
      dispatch(setSelectedSongBucket({ id: structureId }));
      const structure = findStructureToUpdate(structureId);
      if (!structure) {
        return;
      }
      const tableData = tablesData.find((item) => item.id === structureId);
      // @ts-ignore
      const parsedChords: IDataChord[] = JSON.parse(tableData.songChords);
      const startingPoint = parsedChords.filter(
        (c: IDataChord) => c.row === startData.rowId,
      );
      const recipientUnitChords = parsedChords.filter(
        (c: IDataChord) => c.row === endData.rowId,
      );

      if (
        recipientUnitChords.length >= 10 &&
        startingPoint[0].row !== recipientUnitChords[0].row
      ) {
        toast.info("Cannot add more than 10 chords in a row");
        return;
      }

      if (!mobileFlag) {
        const validPosition = validateChordLocation(
          { row: endData.rowId, col: endData.columnId },
          parsedChords,
        );
        endData.rowId = validPosition.row;
        endData.columnId = validPosition.col;
      }

      _.remove(parsedChords, {
        row: startData.rowId,
        column: startData.columnId,
      });

      parsedChords.push({
        chord: startData.title,
        row: endData.rowId,
        column: endData.columnId,
      });

      dispatch(
        updateCurrentSongLyricsBucket({
          song_chords: JSON.stringify(parsedChords),
        }),
      );

      dispatch(
        updateCustomSongChords({
          id: structureId,
          song_chords: JSON.stringify(parsedChords),
        }),
      );

      dispatch(
        updateChordsInTable({
          id: structureId,
          songChords: JSON.stringify(parsedChords),
        }),
      );
    },
    [dispatch, findStructureToUpdate, tablesData],
  );
  const getChordInventory = useCallback(() => {
    let allChords: IDataChord[] = [];

    currentSong.custom_song_lyrics.forEach((structure) => {
      if (structure.song_chords) {
        const parsedChords: IDataChord[] = JSON.parse(structure.song_chords);
        allChords = allChords.concat(parsedChords);
      }
    });

    const chordInventory = _.chain(allChords)
      .countBy("chord")
      .map((count, chord) => ({ chord, count }))
      .sortBy("count")
      .reverse()
      .value();

    return chordInventory;
  }, [currentSong]);

  return {
    addNewChordToStructure,
    deleteChordFromStructure,
    replaceChordInTable,
    getChordInventory,
  };
};
