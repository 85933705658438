/* eslint-disable no-magic-numbers */
import { ISlide } from "api/lesson_api";
import classnames from "classnames";
import { AudioPlayer } from "../../audioplayer/AudioPlayer";
import css from "./slide_types.module.scss";

interface IStrummingStatic {
  slide: ISlide;
}

const StrummingStatic = ({ slide }: IStrummingStatic) => {
  const { text_top, text_bottom, assets = [] } = slide;
  return (
    <div className={css.main}>
      <div
        className={css.text_top}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text_top }}
      />
      <div className={classnames(css.img_audio, css.strumming_static_main)}>
        <img
          src={assets[1].url}
          alt="Strumming pattern"
          className={classnames(css.strumming_img, css.strumming_static_img)}
        />
        <div className={css.tp_audio}>
          <div className={css.audio_player}>
            <AudioPlayer src={assets[2].url} />
          </div>
        </div>
      </div>
      <div className={css.text_bot}>{text_bottom}</div>
    </div>
  );
};

export default StrummingStatic;
