import { createSelector } from "reselect";
import { IState } from "redux/store";

const getDecades = (state: IState) => state.entities.decades;

export const getDecadesSelector = createSelector(
  [(state: IState) => getDecades(state).decades],
  (decades) => {
    return decades.map((decade) => {
      return {
        ...decade,
        value: decade.decade,
        label: `${decade.decade}s`,
      };
    });
  },
);
