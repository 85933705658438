import _ from "lodash";
import { getLessonsCountSelector } from "redux/entities/lessons/lesson_selectors";
import { IState } from "redux/store";
import { createSelector } from "reselect";
import { IStudentWithSkills } from "types/models/student_with_skills";

import { getChordsCountSelector } from "../entities/chords/chords_selectors";
import { calcProgressPercentage } from "../entities/my_journey/my_journey_selectors";
import { getProgressionsCountSelector } from "../entities/progressions/progressions_selectors";
import { getTransitionsCountSelector } from "../entities/transitions/transitions_selectors";
import { ICourse } from "./courses_api";

export const getCoursesEntity = (state: IState) => state.entities.courses;

export const getCourses = (state: IState): ICourse[] =>
  state.entities.courses.courses;

export const getActiveCourses = (state: IState) =>
  state.entities.courses.courses.filter(
    (course) => new Date(course.end_date) > new Date(),
  );
export const getExpiredCourses = (state: IState) =>
  state.entities.courses.courses.filter(
    (course) => new Date(course.end_date) < new Date(),
  );

export const getSortedCoursesByStudents = createSelector(
  getCourses,
  (courses) => {
    return _.orderBy(courses, ["users"], "desc");
  },
);

export const getCourseById = (courseId: number) =>
  createSelector(getCourses, (courses) =>
    courses.find((course) => course.id === courseId),
  );

export const getCourseStudents = (courseId: number) =>
  createSelector(
    getCourseById(courseId),
    (course) => course?.studentsWithSkills || [],
  );

export const findCourseStudent = (courseId: number, userId: number) =>
  createSelector(getCourseById(courseId), (course) => {
    const students = course?.studentsWithSkills || [];

    const student = students.find((student) => student.id === userId);
    return student;
  });

export const getCourseName = (courseId: number) =>
  createSelector([getCourseById(courseId)], (course) => course?.name);

export const getCourseStudentsEmails = (courseId: number) =>
  createSelector([getCourseStudents(courseId)], (students) =>
    students.map((student) => student.uid),
  );

export const getSummarizedData = (courseId: number) =>
  createSelector([getCourseById(courseId)], (course) => {
    return course?.summarizedData;
  });

export const getFetchingStudent = createSelector(
  [getCoursesEntity],
  (courses) => courses.fetchingStudent,
);

export const getCourseCSVData = createSelector(
  [getCoursesEntity],
  (courses) => courses.CSVData,
);

const PERCENTAGE = 100;
const ROUND_VALUE = 1;
const EQUAL = 0;
const GREATER = 1;
const LOWER = -1;

export const getUserSkillsForStudentsTable = (
  usersSkills: IStudentWithSkills[],
) =>
  createSelector(
    [
      getChordsCountSelector,
      getTransitionsCountSelector,
      getProgressionsCountSelector,
      getLessonsCountSelector,
    ],
    (
      chordsCount,
      transitionsCount,
      progressionsCount,
      songsCount,
    ): IStudentWithSkills[] => {
      const result = usersSkills.map((user) => ({
        id: user.id,
        fname: user.fname,
        last_sign_in_at: user.last_sign_in_at,
        lname: user.lname,
        email: user.email,
        chords: user.chords,
        transitions: user.transitions,
        progressions: user.progressions,
        lessons: user.user_lessons,
        user_lessons: user.user_lessons,
        user_lessons_count: user.user_lessons_count,
        songsLearned: user.user_lessons.length,
        courseId: user.courseId,
        songsLearnedPercentage: (
          (user.user_lessons_count / songsCount) *
          PERCENTAGE
        ).toFixed(ROUND_VALUE),
        user_strumming_lessons: user.user_strumming_lessons,
        skillsLearnedPercentage: calcProgressPercentage(
          chordsCount,
          transitionsCount,
          progressionsCount,
          user.chords.guitar.length + user.chords.ukulele.length,
          user.transitions.guitar.length + user.transitions.ukulele.length,
          user.progressions.guitar.length + user.progressions.ukulele.length,
        ),
        user_sessions: user.user_sessions,
        confirmed_at: user.confirmed_at,
      }));
      result.sort((studentOne, studentTwo) => {
        // Stable sort insensitive to undefined
        if (studentOne.lname === undefined) {
          return GREATER;
        }
        if (studentTwo.lname === undefined) {
          return LOWER;
        }
        if (
          studentOne.lname?.toLowerCase() === studentTwo.lname?.toLowerCase()
        ) {
          return EQUAL;
        }
        return studentOne.lname?.toLowerCase() < studentTwo.lname?.toLowerCase()
          ? LOWER
          : GREATER;
      });
      return result;
    },
  );
