import axios from "axios";
import { ISkill } from "types/models/skill";

import { getNodeApiBaseUrl } from "./base_api";

export const transitionsApi = {
  fetchTransitions() {
    const url = `${getNodeApiBaseUrl()}/skills/chord-transitions`;
    return axios.get<ISkill[]>(url);
  },
};
