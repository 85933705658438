import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { ICourseUsers } from "redux/courses/courses_api";
import { IUserSession } from "api/user_session_api";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { ROLES } from "redux/auth/roles/roles_consts";
import { Unlock } from "./unlock/unlock";
import css from "./my_journey.module.scss";
import { ChartResponsiveBar } from "./chart_responsive_bar";

export interface IChartWithAside {
  totalTime: number;
  thisWeekMinutes: number;
  minutesPerDay: IUserSession[];
  topText?: string;
  userId?: number;
  students?: ICourseUsers[];
}

export const ChartWithAside = ({
  totalTime,
  thisWeekMinutes,
  minutesPerDay,
  topText = "Practice Time",
  userId,
  students = [],
}: IChartWithAside) => {
  const userRole = useSelector(getRoleSelector);
  const shallBlock = !(
    userRole === ROLES.FREE_TEACHER_ACCOUNT ||
    userRole === ROLES.FREE_TEACHER_STUDENT
  );
  const userPlace = students.findIndex((student) => student.id === userId) + 1;

  return (
    <div className={css.my_sessions}>
      <h2 className={css.h2}>{topText}</h2>
      {!shallBlock && <Unlock text="Unlock Student Practice Time" />}
      <SessionsInfo shallBlock={shallBlock}>
        <div className={css.charts}>
          <div className={css.info}>
            <div className={css.practice_time}>
              <div className={css.this_week_minutes}>
                {thisWeekMinutes} Minutes
              </div>
              <div>Practice time</div>
              <div className={css.gray_text}>(this week)</div>
            </div>
            <div className={css.practice_total_time}>
              <div className={css.minutes}>{totalTime} Minutes</div>
              <div>Total practice time</div>
            </div>
            {Boolean(students.length) && (
              <div className={css.practice_rank}>
                <div className={css.rank}>
                  {userPlace} of
                  {students.length}
                </div>
                <div className={css.tooltip_container}>
                  Practice Rank
                  <Tooltip title="Your practice time rank compared to fellow students for the current week">
                    <div className={css.tooltip}>
                      <HelpIcon />
                    </div>
                  </Tooltip>
                  <div className={css.gray_text}>(this week)</div>
                </div>
              </div>
            )}
          </div>
          <ChartResponsiveBar minutesPerDay={minutesPerDay} />
        </div>
      </SessionsInfo>
    </div>
  );
};

const SessionsInfo = styled.div<{ shallBlock: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  filter: blur(${(props) => !props.shallBlock && "8px"});
  user-select: ${(props) => !props.shallBlock && "none"};
  pointer-events: ${(props) => !props.shallBlock && "none"};
  align-self: center;
  justify-content: center;
  margin: 8px 0;
  height: 25em;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;
