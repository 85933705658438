import { createSelector } from "reselect";
import { getAuthSelector } from "redux/auth/user_selectors";
import { IPermissions } from "./permissions_consts";

export const getPermissionsSelector = createSelector(
  [getAuthSelector],
  (user) => user.permissions,
);

export const accessByPermissionSelector = (permission: IPermissions) =>
  createSelector(
    [getPermissionsSelector],
    (userPermissions) => !!userPermissions.includes(permission),
  );
