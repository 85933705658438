export const SONG_STRUCTURE_LIST = [
  {
    title: "Intro",
  },
  {
    title: "Verse",
  },
  {
    title: "Pre-Chorus",
  },
  {
    title: "Chorus / Refrain",
  },
  {
    title: "Post-Chorus",
  },
  {
    title: "Bridge",
  },
  {
    title: "Outro",
  },
  {
    title: "Instrumental",
  },
  {
    title: "Other",
  },
];
