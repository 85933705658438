import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { isActionRejectedHelper } from "helpers/is_action_rejected_helper";
import { AxiosError } from "axios";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteCourse,
  fetchOrganizationCourses,
  fetchUserOrganizations,
} from "redux/organizations/organization_courses_common_actions";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { IState } from "redux/store";
import { IStudentWithSkills } from "types/models/student_with_skills";
import { authApi, IInviteStudent } from "redux/auth/auth_api";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { IAxiosErr } from "redux/organizations/organization_slice";
import { getPermissionsSelector } from "redux/auth/permissions/permissions_selector";
import {
  coursesApi,
  ICourse,
  ISummarizedStudentData,
  ICourseStats,
} from "./courses_api";

interface ICreateCourse {
  orgId: number;
  name: string;
  startDate: Date;
  endDate: Date;
  // goToCreatedCourse: (_: number) => void;
}

export const createCourse = createAsyncThunk<
  { id: number } | undefined,
  ICreateCourse,
  { state: IState }
>(
  "courses/createCourse",
  async ({ orgId, name, startDate, endDate }, { dispatch }) => {
    try {
      const { data } = await coursesApi.createCourse(
        orgId,
        name,
        startDate,
        endDate,
      );
      toast.success("Class created");
      dispatch(fetchOrganizationCourses({ orgId }));
      return data.course;
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);
export const createCourseTeacherSignUp = createAsyncThunk<
  void,
  ICreateCourse,
  { state: IState }
>(
  "courses/createCourseTeacherSignUp",
  async ({ orgId, name, startDate, endDate }, { dispatch }) => {
    try {
      const result = await dispatch(
        createCourse({ orgId, name, startDate, endDate }),
      );

      if (isActionRejectedHelper(result)) {
        throw result;
      }

      dispatch(
        stepListActions.nextStep({
          stepListId: STEP_LIST_IDS.signUpStepList,
        }),
      );
    } catch (err: any) {
      toast.error((err as AxiosError).response?.data.message || err);
    }
  },
);

interface IResendInvitation {
  userId: number;
  orgId: number;
  instructorId: number;
}
export const resendInvitation = createAsyncThunk<
  void,
  IResendInvitation,
  { state: IState }
>(
  "courses/resendInvitation",
  async ({ userId, orgId, instructorId }, { dispatch }) => {
    try {
      await coursesApi.resendInvitation(userId, orgId, instructorId);
      toast.success("Invitation sent");
    } catch (e) {
      toast.error(e as string);
    }
  },
);

interface IMoveStudent {
  courseId: number;
  userId: number;
  newCourseId: number;
}
export const moveStudent = createAsyncThunk<
  void,
  IMoveStudent,
  { state: IState }
>(
  "courses/moveStudent",
  async ({ courseId, userId, newCourseId }, { dispatch }) => {
    try {
      await coursesApi.moveStudent(courseId, userId, newCourseId);
      dispatch(fetchCourseStudents({ courseId }));
      toast.success("Moved");
    } catch (e) {
      toast.error(e as string);
    }
  },
);

interface IUpdateCourseName {
  name: string;
  courseId: number;
}
export const updateCourseName = createAsyncThunk<
  { courseId: number; name: string },
  IUpdateCourseName,
  { state: IState }
>("courses/updateCourseName", async ({ name, courseId }) => {
  await coursesApi.updateCourse(courseId, { name });
  toast.success("Renamed");
  return { courseId, name };
});

export interface IUpdateCourseExplicit {
  courseId: number;
  explicit?: boolean;
  guitar_songs?: boolean;
  ukulele_songs?: boolean;
}

export const updateCourse = createAsyncThunk<
  IUpdateCourseExplicit,
  IUpdateCourseExplicit,
  { state: IState }
>(
  "courses/updateCourseExplicit",
  async ({ explicit, guitar_songs, ukulele_songs, courseId }) => {
    await coursesApi.updateCourse(courseId, {
      guitar_songs,
      ukulele_songs,
      explicit,
    });
    return { courseId, explicit, guitar_songs, ukulele_songs };
  },
);

interface IInviteStudents {
  students: IInviteStudent[];
  courseId: number;
}
const SERVER_ERROR = 500;
export const inviteStudents = createAsyncThunk<
  void,
  IInviteStudents,
  { state: IState }
>(
  "courses/inviteStudents",
  async ({ students, courseId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userPermissions = getPermissionsSelector(getState());

      const subscribed = !!userPermissions.includes(
        PERMISSIONS.ADD_UNLIMITED_STUDENTS,
      );

      const userId = getUserIdSelector(getState());
      if (userId) {
        await authApi.inviteStudentsApi(students, courseId, subscribed);
        dispatch(fetchUserOrganizations({ userId }));
        toast.success("Students added");
      }
    } catch (err) {
      if ((err as IAxiosErr).response.status === SERVER_ERROR) {
        toast.error("Server error");
      } else {
        toast.error((err as IAxiosErr).response.data.error);
      }
      return rejectWithValue((err as IAxiosErr).response.data);
    }
  },
);

export const changeStudentPassword = createAsyncThunk<
  void,
  { userId: number; password: string },
  { state: IState }
>("courses/changeStudentPassword", async ({ userId, password }) => {
  try {
    await coursesApi.resetPassword(userId, password);
    toast.success("Password Updated");
  } catch (e) {
    toast.error(e as string);
  }
});

export const setFetchingStudents = createAction<boolean>(
  "courses/setFetchingStudents",
);

interface IFetchCourseStudents {
  courseId: number;
  dateRange?: ICourseDateFilter;
}
export const fetchCourseStudents = createAsyncThunk<
  {
    courseId: number;
    usersWithSkills: IStudentWithSkills[];
  },
  IFetchCourseStudents,
  { state: IState }
>(
  "courses/fetchCourseStudents",
  async ({ courseId, dateRange }, { dispatch }) => {
    dispatch(setFetchingStudents(true));
    const {
      data: { usersWithSkills },
    } = await coursesApi.fetchCourseStudents(courseId, dateRange);
    dispatch(setFetchingStudents(false));
    return { courseId, usersWithSkills };
  },
);

interface ICoursesState {
  courses: ICourse[];
  fetchingStudent: boolean;
  startDate: string | null;
  endDate: string | null;
  CSVData: ICourseStats[] | [];
}

export type ICourseDateFilter = Partial<
  Pick<ICoursesState, "startDate" | "endDate">
>;

export interface IFetchSummarizedStudentsData {
  courseId: number;
  dateRange: ICourseDateFilter;
}

export const fetchSummarizedStudentsData = createAsyncThunk<
  { courseId: number; summarizedStudentsData: ISummarizedStudentData },
  IFetchSummarizedStudentsData,
  { state: IState }
>("courses/fetchSummarizedStudentsData", async ({ courseId, dateRange }) => {
  const response = await coursesApi.fetchSummarizedStudentsData(
    courseId,
    dateRange,
  );
  const { summarizedStudentsData } = response.data;
  return { courseId, summarizedStudentsData };
});

const initialState: ICoursesState = {
  courses: [],
  fetchingStudent: true,
  startDate: null,
  endDate: null,
  CSVData: [],
};

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCourseDateFilter: (state, action: PayloadAction<ICourseDateFilter>) => {
      const { startDate = null, endDate = null } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
    },
    setCourseCSVData: (state, action: PayloadAction<ICourseStats[]>) => {
      state.CSVData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationCourses.fulfilled, (state, action) => {
      action.payload.sort((courseA, courseB) => courseA.id - courseB.id);
      state.courses = action.payload;
    });
    builder.addCase(
      fetchSummarizedStudentsData.fulfilled,
      (state, { payload }) => {
        const selectedCourse = state.courses.find(
          (course) => course.id === Number(payload.courseId),
        );
        if (selectedCourse) {
          selectedCourse.summarizedData = payload.summarizedStudentsData;
        }
      },
    );
    builder.addCase(setFetchingStudents, (state, action) => {
      state.fetchingStudent = action.payload;
    });
    builder.addCase(fetchCourseStudents.fulfilled, (state, { payload }) => {
      const selectedCourse = state.courses.find(
        (course) => course.id === Number(payload.courseId),
      );
      if (selectedCourse) {
        selectedCourse.studentsWithSkills = payload.usersWithSkills;
      }
    });
    builder.addCase(updateCourseName.fulfilled, (state, { payload }) => {
      const selectedCourse = state.courses.find(
        (course) => course.id === Number(payload.courseId),
      );
      if (selectedCourse) {
        selectedCourse.name = payload.name;
      }
    });
    builder.addCase(updateCourse.pending, (state, { meta }) => {
      state.courses.map((course) => {
        if (course.id === Number(meta.arg.courseId)) {
          if (meta.arg.explicit !== undefined) {
            course.explicit = meta.arg.explicit;
          }
          if (meta.arg.guitar_songs !== undefined) {
            course.guitar_songs = meta.arg.guitar_songs;
          }
          if (meta.arg.ukulele_songs !== undefined) {
            course.ukulele_songs = meta.arg.ukulele_songs;
          }
        }
        return course;
      });
    });
    builder.addCase(updateCourse.rejected, (state, { meta }) => {
      state.courses.map((course) => {
        if (course.id === Number(meta.arg.courseId)) {
          if (meta.arg.explicit !== undefined) {
            course.explicit = !meta.arg.explicit;
          }
          if (meta.arg.guitar_songs !== undefined) {
            course.guitar_songs = !meta.arg.guitar_songs;
          }
          if (meta.arg.ukulele_songs !== undefined) {
            course.ukulele_songs = !meta.arg.ukulele_songs;
          }
        }
        return course;
      });
    });
    builder.addCase(deleteCourse.pending, (state, { payload }) => {
      const newCourses = state.courses.filter(
        (course) => course.id !== payload,
      );
      return { ...state, courses: newCourses };
    });
  },
});

export const {
  reducer: coursesReducer,
  actions: { setCourseDateFilter, setCourseCSVData },
} = coursesSlice;
