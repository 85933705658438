export const castDateToLocal = (date: Date | string, dateOffset: number) => {
  return dateOffset > 0
    ? `${new Date(new Date(date).getTime() + dateOffset * 60 * 1000)}`
    : `${new Date(new Date(date).getTime() - dateOffset * 60 * 1000)}`;
};

export function timestampToDate(date: string) {
  return new Date(Date.parse(date));
}

export function timeSince(date: Date | string) {
  if (typeof date === "string") {
    date = timestampToDate(date);
  }
  // @ts-ignore
  const seconds = Math.floor((new Date() - date) / 1000);

  const SEC_IN_YEAR = 31536000;
  const SEC_IN_MONTH = 2592000;
  const SEC_IN_DAY = 86400;
  const SEC_IN_HOUR = 3600;
  const SEC_IN_MINUTE = 60;

  let interval = seconds / SEC_IN_YEAR;

  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }
  interval = seconds / SEC_IN_MONTH;
  if (interval > 1) {
    return `${Math.floor(interval)} months`;
  }
  interval = seconds / SEC_IN_DAY;
  if (interval > 1) {
    return `${Math.floor(interval)} days`;
  }
  interval = seconds / SEC_IN_HOUR;
  if (interval > 1) {
    return `${Math.floor(interval)} hours`;
  }
  interval = seconds / SEC_IN_MINUTE;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes`;
  }
  return "a few seconds";
}
