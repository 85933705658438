import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import css from "./course_block_menu.module.scss";

interface ICourseBlockMenu {
  menuAnchor: HTMLElement | null;
  handleCloseMenu: (e: React.MouseEvent) => void;
  openDeleteCourseDialog: (e: React.MouseEvent) => void;
  openRenameCourseDialog: (e: React.MouseEvent) => void;
}

export const CourseBlockMenu = ({
  menuAnchor,
  handleCloseMenu,
  openDeleteCourseDialog,
  openRenameCourseDialog,
}: ICourseBlockMenu) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={menuAnchor}
      keepMounted
      open={Boolean(menuAnchor)}
      getContentAnchorEl={null}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem onClick={handleCloseMenu} className={css.dropdown_add_students}>
        <AddIcon style={{ color: "#190067" }} /> Add Students
      </MenuItem>
      <hr />
      <MenuItem
        onClick={(e) => {
          openDeleteCourseDialog(e);
          handleCloseMenu(e);
        }}
      >
        Remove
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          openRenameCourseDialog(e);
          handleCloseMenu(e);
        }}
      >
        Rename
      </MenuItem>
    </Menu>
  );
};
