import React from "react";
import { CHORD, TRANSITION, PROGRESSION } from "constants/skills_constants";
import styled from "@emotion/styled";
import {
  changeCurrentInstrument,
  IInstruments,
} from "redux/entities/user_skills/user_skills_slice";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { ROLES } from "redux/auth/roles/roles_consts";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentInstrument } from "redux/entities/user_skills/user_skills_selector";
import { IStudentLesson } from "types/models/student_with_skills";
import { IUserStrummingLessons } from "types/helpers/strummingLessonSkills";
import { UserSkill } from "../user_skill/user_skill";
import { Unlock } from "../unlock/unlock";
import css from "./user_skills.module.scss";
import chordsIcon from "./icons/chords_icon.svg";
import chordProgressionIcon from "./icons/Chord_progressions.svg";
import chordTransitionIcon from "./icons/Chord_transitions.svg";
import melodiesIcon from "./icons/Melodies.svg";
import songLessonIcon from "./icons/Song_Lessons.svg";
import strummingIcon from "./icons/Strumming.svg";
import { MelodyView } from "../skill_view";
import { ChordView } from "../skill_view/chord_view";
import { StrummingView } from "../skill_view/strumming_view/strumming_view";
import { SongView } from "../skill_view/song_view";

export interface IUserSkillsData {
  chords: string[];
  userChords: string[];
  transitions: string[];
  userTransitions: string[];
  progressions: string[];
  userProgressions: string[];
  userMelodiesSongs: IStudentLesson[];
  userStrummingSongs: IUserStrummingLessons;
  userCompletedSongs: IStudentLesson[];
  strummingSkillsCount: number;
  melodySkillsCount: number;
  songsCount: number;
}

export const MIN_NUMBER_OF_LESSONS = 5;
export const NUMBER_OF_STRUMMING_SKILLS = 5;
export const NUMBER_OF_MELODIES_SKILLS = 3;

interface IUserSkills {
  setContent: (_: React.ReactNode) => void;
  skills: IUserSkillsData;
  studentName?: string;
  clickable?: boolean;
}

export const UserSkills = ({
  setContent,
  skills,
  studentName,
  clickable = false,
}: IUserSkills) => {
  const userRole = useSelector(getRoleSelector);

  const shallBlock = !(
    userRole === ROLES.FREE_TEACHER_ACCOUNT ||
    userRole === ROLES.FREE_TEACHER_STUDENT
  );
  const {
    chords,
    userChords,
    transitions,
    userTransitions,
    progressions,
    userProgressions,
    userMelodiesSongs,
    userStrummingSongs,
    userCompletedSongs,
    strummingSkillsCount,
    melodySkillsCount,
    songsCount,
  } = skills;
  const dispatch = useDispatch();
  const positiveText = !studentName ? "I know" : `${studentName} knows`;
  const negativeText = !studentName
    ? "I don't know"
    : `${studentName} doesn't know`;

  const currentInstrument = useSelector(getCurrentInstrument);

  const chordsContent = (
    <ChordView
      topText={`Chords ${positiveText}  how to play`}
      bottomText={`Chords ${negativeText} how to play`}
      allSkills={chords}
      skillType={CHORD}
      userSkills={userChords}
      learnText={!studentName ? "Learn new chords" : undefined}
      clickable={clickable}
    />
  );
  const transitionContent = (
    <ChordView
      topText={`Transitions ${positiveText}  how to play`}
      bottomText={`Transitions ${negativeText} how to play`}
      allSkills={transitions}
      skillType={TRANSITION}
      userSkills={userTransitions}
      learnText={!studentName ? "Learn new transitions" : undefined}
      clickable={clickable}
    />
  );
  const progressionContent = (
    <ChordView
      topText={`Progressions ${positiveText}  how to play`}
      bottomText={`Progressions ${negativeText} how to play`}
      allSkills={progressions}
      skillType={PROGRESSION}
      userSkills={userProgressions}
      learnText={!studentName ? "Learn new progressions" : undefined}
      clickable={clickable}
    />
  );

  const melodyContent = (
    <MelodyView
      songs={userMelodiesSongs}
      progress={Math.ceil(
        (melodySkillsCount / NUMBER_OF_MELODIES_SKILLS) * 100,
      )}
      skills={melodySkillsCount}
    />
  );

  const strummingContent = (
    <StrummingView
      songs={userStrummingSongs}
      progress={Math.ceil(
        (strummingSkillsCount / NUMBER_OF_STRUMMING_SKILLS) * 100,
      )}
      skills={strummingSkillsCount}
    />
  );
  // @ts-ignore
  const songContent = <SongView userCompletedSongs={userCompletedSongs} />;

  const handleChangeInstrument = (instrument: IInstruments) => {
    dispatch(changeCurrentInstrument({ currentInstrument: instrument }));
  };

  const currentInstrumentToView =
    currentInstrument[0].toUpperCase() + currentInstrument.slice(1);
  return (
    <Container>
      <h2 className={css.header}>{currentInstrumentToView} Skills</h2>
      {!shallBlock && <Unlock text="Unlock Student Skills" />}
      <Instruments>
        <InstrumentGuitar
          instrument={currentInstrument}
          onClick={() => handleChangeInstrument("guitar")}
        >
          Guitar
        </InstrumentGuitar>
        <InstrumentUkulele
          instrument={currentInstrument}
          onClick={() => handleChangeInstrument("ukulele")}
        >
          Ukulele
        </InstrumentUkulele>
      </Instruments>
      <div className={css.skills_container}>
        <UserSkill
          onClick={() => setContent(chordsContent)}
          icon={chordsIcon}
          name="Chords"
          progress={Math.ceil((userChords.length / chords.length) * 100)}
          skills={userChords.length}
        />
        <UserSkill
          icon={chordTransitionIcon}
          name="Chord Transitions"
          onClick={() => setContent(transitionContent)}
          progress={Math.ceil(
            (userTransitions.length / transitions.length) * 100,
          )}
          skills={userTransitions.length}
        />
        <UserSkill
          icon={chordProgressionIcon}
          name="Chord Progressions"
          onClick={() => setContent(progressionContent)}
          progress={Math.ceil(
            (userProgressions.length / progressions.length) * 100,
          )}
          skills={userProgressions.length}
        />
        <UserSkill
          icon={strummingIcon}
          name="Strumming"
          onClick={() => setContent(strummingContent)}
          progress={Math.ceil(
            (strummingSkillsCount / NUMBER_OF_STRUMMING_SKILLS) * 100,
          )}
          skills={strummingSkillsCount}
        />
        <UserSkill
          icon={melodiesIcon}
          name="Melodies"
          onClick={() => setContent(melodyContent)}
          progress={Math.ceil(
            (melodySkillsCount / NUMBER_OF_MELODIES_SKILLS) * 100,
          )}
          skills={melodySkillsCount}
        />
        <UserSkill
          icon={songLessonIcon}
          name="Song Lessons"
          onClick={() => setContent(songContent)}
          progress={Math.ceil((userCompletedSongs.length / songsCount) * 100)}
          skills={userCompletedSongs.length}
        />
      </div>
    </Container>
  );
};

const Instruments = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.5em 0;
`;

const Instrument = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const InstrumentGuitar = styled(Instrument)<{ instrument: IInstruments }>`
  color: ${(props) => props.instrument === "guitar" && "#6775DE"};
  text-decoration: ${(props) => props.instrument === "guitar" && "underline"};
`;
const InstrumentUkulele = styled(Instrument)<{ instrument: IInstruments }>`
  color: ${(props) => props.instrument === "ukulele" && "#6775DE"};
  text-decoration: ${(props) => props.instrument === "ukulele" && "underline"};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 24px;
  margin-right: 8px;
  @media (max-width: 600px) {
    margin-right: 0px;
    border: none;
  }
`;
