import React, { SyntheticEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "redux/store";
import { getUserSelector } from "redux/auth/user_selectors";
import { TABS } from "redux/songwriting/songwriting_interfaces";
import {
  getActiveTab,
  getCustomSongTemplate,
} from "redux/songwriting/songwriting_selectors";
import { createCustomSong, setTabs } from "redux/songwriting/songwriting_slice";
import { openModal, openSongbuilderModal } from "redux/ui/modal/modal_slice";
import mixpanel from "mixpanel-browser";

import { Card, CardActions } from "@material-ui/core";
import { CreateSongCardNote } from "./create_song_card_note/create_song_card_note";

import css from "../../start_new_song.module.scss";

interface ICreateSongCard {
  freeUserPlan: boolean;
  areFreeCreditsAvailable: boolean;
  songTemplateId?: number;
}

export const CreateSongCard = React.forwardRef(
  (
    { freeUserPlan, areFreeCreditsAvailable, songTemplateId }: ICreateSongCard,
    ref,
  ) => {
    const dispatch = useDispatch();

    const user = useSelector(getUserSelector);
    const activeTab = useSelector(getActiveTab);
    const customSongTemplate = useSelector(
      getCustomSongTemplate(songTemplateId),
    );

    const currentSongId = useSelector(
      (store: IState) => store.songwriting.selectedSongId,
    );

    const userName = `${user?.fname} ${user?.lname}`;

    useEffect(() => {
      currentSongId && dispatch(openSongbuilderModal());
    }, [dispatch, currentSongId]);

    const handleCreateSong = async () => {
      dispatch(
        createCustomSong({
          user_id: user!.id,
          artist: userName,
          template: customSongTemplate,
        }),
      );
      dispatch(openSongbuilderModal());
      if (activeTab === TABS.SHARED) {
        dispatch(setTabs(TABS.OWNED));
      }

      mixpanel.track("Create Song");
    };

    const clickHandler = async (e: SyntheticEvent) => {
      if (freeUserPlan) {
        if (areFreeCreditsAvailable) {
          handleCreateSong();
        } else {
          dispatch(openModal({ type: "payForSubscription" }));
        }
      } else {
        handleCreateSong();
      }
    };

    return (
      <Card ref={ref} elevation={0} className={css.card} onClick={clickHandler}>
        <div className={css.card_content}>
          <p className={css.card_title}>
            {customSongTemplate?.song_name || "New Song from Scratch"}
          </p>
          <CardActions>
            <CreateSongCardNote songTemplateId={songTemplateId} />
          </CardActions>
        </div>
      </Card>
    );
  },
);
