import css from "./no_lessons_found.module.scss";

export const NoLessonsFound = () => {
  return (
    <div className={css.container}>
      <h2 className={css.header}>No Results</h2>
      <div className={css.text}>
        Try Adjusting your search by removing filters
      </div>
    </div>
  );
};
