import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUserLessons } from "redux/entities/user_lessons/user_lessons_slice";

interface IChordsState {
  fetchedCustomer: boolean;
  fetchedULs: boolean;
  userFetched: boolean;
  userClicked: boolean;
}

const initialState: IChordsState = {
  fetchedCustomer: false,
  fetchedULs: false,
  userFetched: false,
  userClicked: false,
};

interface IFilterValue {
  filter: "fetchedCustomer" | "fetchedULs" | "userFetched" | "userClicked";
  value: boolean;
}

const filtersSlice = createSlice({
  name: "chords",
  initialState,
  reducers: {
    changeFilter: (state, { payload }: PayloadAction<IFilterValue>) => {
      return { ...state, [payload.filter]: payload.value };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserLessons.fulfilled.type, (state) => {
      state.fetchedULs = true;
    });
  },
});

export const {
  reducer: filtersReducer,
  actions: { changeFilter },
} = filtersSlice;
