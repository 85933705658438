import { LogoWithBackground } from "components/sign_up/reusable/background_image";
import { EmotionInput } from "components/sign_up/reusable/styled_input";
import {
  Example,
  StyledForm,
} from "components/sign_up/steps/create_organization/create_organization.styled";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserEmailSelector } from "redux/auth/user_selectors";
import { createOrganizationForTeacher } from "redux/organizations/organization_slice";

import Button from "../../reusable/buttons/button";
import defaultAvatar from "../../sign_up/images/default-avatar.svg";
import { ModalContainer } from "../modal_container";
import css from "../modal.module.scss";
import modalCss from "./org_name_modal.module.scss";

interface IFormInputs {
  orgName: string;
}

const FREE_TEACHER_AMOUNT_STUDENTS = 100;

export const OrgNamePromptModal = () => {
  const userEmail = useSelector(getUserEmailSelector);
  const idFormCreateOrg = "create_organization_teacher_update_form";
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (userEmail) {
      dispatch(
        createOrganizationForTeacher({
          email: userEmail,
          orgName: data.orgName,
          max_seats: FREE_TEACHER_AMOUNT_STUDENTS,
        }),
      );
    }
  };

  return (
    <ModalContainer classContainer={modalCss.modal}>
      <h2 className={css.title}>Name Your Teacher Account</h2>
      <LogoWithBackground
        src={defaultAvatar}
        padding="33px"
        margin="2em 1em 4em"
      />
      <StyledForm onSubmit={handleSubmit(onSubmit)} id={idFormCreateOrg}>
        <EmotionInput
          hookFormErr={errors}
          placeholder="School Account Name"
          {...register("orgName", {
            required: "Enter organization name",
            minLength: {
              value: 6,
              message: "Organization name must contain more than 6 symbols",
            },
          })}
        />

        <Example>
          {"i.e. Exeter High Guitar, Mrs. Green Ukulele, Blue Hawk \n"}
          Music We’ll set up your classes / periods later.
        </Example>
      </StyledForm>
      <div className={modalCss.button_container}>
        <Button form={idFormCreateOrg}>Submit</Button>
      </div>
    </ModalContainer>
  );
};
