/* eslint-disable no-unused-vars */
import { Row } from "react-table";
import { ICourse } from "redux/courses/courses_api";

export enum SongStructure {
  INTRO = "Intro",
  VERSE = "Verse",
  PRE_CHORUS = "Pre-Chorus",
  CHORUS = "Chorus / Refrain",
  POST_CHORUS = "Post-Chorus",
  BRIDGE = "Bridge",
  OUTRO = "Outro",
  INSTRUMENTAL = "Instrumental",
  MISC = "Other",
}

type SongStructureKeys = keyof typeof SongStructure;
export type SongStructureType = Capitalize<Lowercase<SongStructureKeys>>;

export interface ICustomSongLyrics {
  id?: number;
  song_structure: SongStructureType | string;
  song_lyrics: string;
  song_chords: string;
  position: number;
  type?: string;
}

export interface ICustomSongAsset {
  id: number;
  url: string;
  rec_minutes: number;
  rec_seconds: number;
  title?: string;
  createdAt: Date | string;
}

export interface ISharedSongInvitees {
  shared_song_id: number;
  recipient_email: string;
  updated_at: string;
  invitation_status: string;
  editedAt: Date | string;
  viewedAt: Date | string;
  // TODO: add user's data
}

export interface ISharedUserSong {
  id: number;
  user_id: number;
  sender_id: number;
  custom_song_id: number;
  status: string;
  invitees: ISharedSongInvitees[];
  owner: {
    email: string;
    updated_at: string;
  }[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ICustomSong {
  id?: number;
  song_name: string;
  artist: string;
  explicit: boolean;
  tuning: string;
  custom_song_key: string;
  time_signature: string;
  tempo: number;
  length: number;
  user_id: number | null;
  shared_songs: ISharedUserSong[] | null;
  custom_song_assets: ICustomSongAsset[];
  custom_song_lyrics: ICustomSongLyrics[];
  note: string | null;
  chord_icon_style: string;
  cert_number: number | null;

  edited_at: Date | string;
  viewed_at: Date | string;
}

export interface ICustomSongTemplate extends ICustomSong {
  customSongTemplates?: ICustomSong[];
}

export interface ISongwriting extends ICustomSong {
  id: number;

  updatedAt: Date;
  deletedAt: Date;
  createdAt: Date;
}

export interface IOrgSong extends ISongwriting {
  user: {
    fname: string;
    lname: string;
    courses: ICourse[];
  };
}

export interface ISharedSong {
  id: number;
  recipient_email: string;
  invitation_status: string;
  shared_song_id: number;
  url_token: string;
  editedAt: Date | string;
  viewedAt: Date | string;
  sharedSong: {
    id: number;
    user_id: number;
    sender_id: number;
    custom_song_id: number;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    custom_song: ICustomSong;
  };
  createdAt: Date;
  updatedAt: Date;
}

export interface IMobileSidebar {
  show: string;
  content: string | null;
}

export enum TABS {
  OWNED,
  SHARED,
}

export type SongContent = {
  title: string;
  icon?: any;
  draggable: boolean;
  header?: string;
};

export type RowType = {
  c0: string;
  [key: string]: string | undefined;
};

export type TableDataProps = {
  id: number;
  data: RowType[];
  songChords: string;
  title: string;
  songLyrics: string;
};

export type ActualTableDataProps = {
  id: number;
  data: RowType[];
};

export type TableRows = Array<Row<RowType>>;

export enum EnumDragAndDropActions {
  createStructure = "create-structure",
  addChordToStructure = "add-chord",
  replaceChordInStructure = "replace-chord",
  replaceStructure = "replace-structure",
  deleteChord = "delete-chord",
}

export type OverDataChord = {
  structureId: number;
  rowId: number;
  columnId: number;
};

export type ActiveDataChord = {
  title: string;
  structureId: number;
  rowId: number;
  columnId: number;
};

export type ChordsData = {
  chord: string;
  row: string;
  column: string;
}[];
