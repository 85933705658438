import { ChangeEvent, Dispatch } from "react";
import { Slider } from "@material-ui/core";

import css from "./BPM_slider.module.scss";

interface IBPMSlider {
  setTempo: Dispatch<number>;
  defaultBPM: number;
  tempo: number;
}

export const BPMSlider = ({ setTempo, defaultBPM, tempo }: IBPMSlider) => {
  const setDefaultBPM = () => {
    setTempo(defaultBPM);
  };

  const handleSliderChange = (
    event: ChangeEvent<Record<string, never>>,
    newValue: number | number[],
  ) => {
    setTempo(newValue as number);
  };

  return (
    <div className={css.slider_container}>
      <Slider
        className={css.slider}
        step={1}
        min={60}
        onChange={handleSliderChange}
        max={220}
        defaultValue={defaultBPM}
        value={tempo}
      />
      <button onClick={setDefaultBPM} type="button">
        Original
      </button>
    </div>
  );
};
