/* eslint-disable no-magic-numbers */

const A = 0;
const A_SHARP = 1;
const B = 2;
const C = 3;
const C_SHARP = 4;
const D = 5;
const D_SHARP = 6;
const E = 7;
const F = 8;
const F_SHARP = 9;
const G = 10;
const G_SHARP = 11;

const transpositionMatrix = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8],
  [8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7],
  [7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6],
  [6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5],
  [5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4],
  [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0],
];

export function calculateCapo(lessonKey: string, songKey: string) {
  const X = getArrayNumber(lessonKey);
  const Y = getArrayNumber(songKey);
  const capo = transpositionMatrix[X][Y];
  switch (capo) {
    case 0:
      return "No Capo";
    case 1:
      return "Capo on 1st Fret";
    case 2:
      return "Capo on 2nd Fret";
    case 3:
      return "Capo on 3rd Fret";
    default:
      return `Capo on ${capo}th Fret`;
  }
}

function getArrayNumber(key: string) {
  // eslint-disable-next-line prefer-regex-literals
  const keyWithoutMinor = key.replace(new RegExp("m", "g"), "");
  switch (keyWithoutMinor) {
    case "A":
      return A;
    case "A#/Bb":
      return A_SHARP;
    case "B":
      return B;
    case "C":
      return C;
    case "C#/Db":
      return C_SHARP;
    case "D":
      return D;
    case "D#/Eb":
      return D_SHARP;
    case "E":
      return E;
    case "F":
      return F;
    case "F#/Gb":
      return F_SHARP;
    case "G":
      return G;
    case "G#/Ab":
      return G_SHARP;
    default:
      return 0;
  }
}
