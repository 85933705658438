import { ILesson } from "api/lesson_api";
import { useSelector } from "react-redux";
import { getCurrentInstrument } from "redux/entities/user_skills/user_skills_selector";
import { useGetMelodiesQuery } from "redux/skills/skills_service";
import { capitalize } from "lodash";
import { TopSkillInfo } from "./top_skill_info/top_skill_info";
import { ProgressSection } from "./progress_section/progress_section";
import { MIN_NUMBER_OF_LESSONS } from "../user_skills";
import css from "./skill_view.module.scss";

interface IMelodyView {
  songs: ILesson[];
  progress: number;
  skills: number;
}
export const MelodyDifficulties = ["EASY", "MEDIUM", "HARD"];

export const MelodyView = (props: IMelodyView) => {
  const { songs, progress, skills } = props;

  const instrument = useSelector(getCurrentInstrument);

  const { data: melodies = [] } = useGetMelodiesQuery({
    instrument: capitalize(instrument),
  });

  const easySongs = songs.filter((song) => song.difficulty_level === "easy");

  const mediumSongs = songs.filter(
    (song) => song.difficulty_level === "medium",
  );

  const hardSongs = songs.filter((song) => song.difficulty_level === "hard");

  const skillsList = [
    {
      name: "EASY",
      learnedCount: easySongs.length,
      skill: melodies.find(
        // Right now we don't have easy/medium/hard melodies, so just put all melodies into easy one
        (melody) => melody.name === "Easy Melody" || melody.name === "Melody",
      ),
    },
    {
      name: "MEDIUM",
      learnedCount: mediumSongs.length,
      skill: melodies.find((melody) => melody.name === "Medium Melody"),
    },
    {
      name: "HARD",
      learnedCount: hardSongs.length,
      skill: melodies.find((melody) => melody.name === "Hard Melody"),
    },
  ];

  return (
    <div className={css.skill_container}>
      <TopSkillInfo
        skillName="Melodу"
        skillsNumber={skills}
        progress={progress}
        skillsMinNumber={MIN_NUMBER_OF_LESSONS}
      />
      <ProgressSection
        skillsList={skillsList}
        lessonsNumber={MIN_NUMBER_OF_LESSONS}
      />
    </div>
  );
};
