import axios from "axios";
import { ISkill } from "types/models/skill";

import { getNodeApiBaseUrl } from "./base_api";

export const progressionsApi = {
  fetchProgressions() {
    const url = `${getNodeApiBaseUrl()}/skills/chord-progressions`;
    return axios.get<ISkill[]>(url);
  },
};
