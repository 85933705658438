import { IState } from "redux/store";
import { getLessonsIdArraySelector } from "./lesson_selectors";

// if availableLessonsId undefined, lessonsCache are used
export const getLacksLessonsId = ({
  userLessonsId,
  availableLessonsId,
  getState,
}: {
  userLessonsId: number[];
  availableLessonsId?: number[];
  getState?: () => IState;
}) => {
  let lessonsId: number[] = [];

  if (availableLessonsId) {
    lessonsId = availableLessonsId;
  } else if (getState) {
    lessonsId = getLessonsIdArraySelector(getState());
  }

  const lacksLessonsId = lessonsId.length
    ? userLessonsId.filter((userLessonId) => !lessonsId.includes(userLessonId))
    : userLessonsId;

  return lacksLessonsId.length ? lacksLessonsId : null;
};
