import { useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { closeModal } from "redux/ui/modal/modal_slice";
import { ModalContainer } from "components/modal/modal_container";
import { ReactComponent as MoosikoLogo } from "img/moosiko_logo_white.svg";
import { MNavRoute } from "routes/d_nav_route";
import { SongLinks } from "../shared/song_links";

import css from "./mobile_menu.module.scss";

export const MobileMenuModal = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const handleClose = () => {
    const menu = mainRef.current;

    menu?.classList.add("slideOutRight");
    dispatch(closeModal());
  };

  return (
    <ModalContainer
      mainRef={mainRef}
      closeAction={handleClose}
      classMain={`${css.main} slideInRight animated2`}
      noCloseButton
    >
      <Link onClick={handleClose} to="/" className={css.logo}>
        <MoosikoLogo />
      </Link>
      <div className={css.links}>
        <SongLinks close={handleClose} cssLink={css.link} />
      </div>

      <MNavRoute close={handleClose} css={css} />
    </ModalContainer>
  );
};
