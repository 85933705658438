import { IState } from "redux/store";
import { createSelector } from "reselect";
import { TStepListIds } from "./stepListConstants";

export const currentStepSelector = (stepListId: TStepListIds) =>
  createSelector([(state: IState) => state.steps], (steps) => {
    if (steps[stepListId]) {
      return steps[stepListId].currentStep;
    }
    return 1;
  });
