import { Dialog } from "@material-ui/core";
import { Video } from "components/video";
import { IFeedback } from "redux/feedback/feedback_types";
import { TextFeedback } from "./text_feedback";

interface IFeedbackDialog {
  feedback: IFeedback;
  feedbackDialog: boolean;
  closeFeedbackDialog: (_: MouseEvent) => void;
  showNote: boolean;
  videoURL: string | null;
}

export const FeedbackDialog = ({
  feedbackDialog,
  closeFeedbackDialog,
  showNote,
  feedback,
  videoURL,
}: IFeedbackDialog) => {
  return (
    <Dialog
      open={feedbackDialog}
      maxWidth="sm"
      onClose={closeFeedbackDialog}
      aria-labelledby="max-width-dialog-title"
    >
      {!showNote && <Video src={videoURL} />}
      {showNote && <TextFeedback feedback={feedback} />}
    </Dialog>
  );
};
