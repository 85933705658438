import { Typography } from "@material-ui/core";

interface IFirstLine {
  firstLine?: string;
}

const FirstLine = ({ firstLine }: IFirstLine) => {
  return (
    <Typography
      style={{
        fontWeight: "400",
        fontSize: 16,
        color: "#5f6367",
        fontFamily: "Raleway",
      }}
      noWrap
    >
      {firstLine}
    </Typography>
  );
};

export default FirstLine;
